import React, { Component } from 'react';
import {
    Layout,
    Toast,
    Frame
} from '@shopify/polaris';
import { connect } from 'react-redux';
import SettingsNotifications from '../components/settings-notifications/settings-notifications';
import { updateEmailPref } from '../store/actions/action_updateAffiliateEmailPref';
import { getEmailPreference } from '../store/actions/action_getEmailPreference';

import './styles/notifications-settings.css';

const defaultNotifications = {
    application_general: true,
    application_alerts: true,
    application_advertiser_messages: true,
    application_weekly_summary: true,
    newsletter_general: true,
    newsletter_marketing: true,
    newsletter_updates: true,
    email_unsubscribe: false,
};

class NotificationsSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            showToast: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        if (this.props.affiliate.email_preference) {
            this.setState({ isLoading: false, ...this.props.affiliate.email_preference });
        } else if (this.props.affiliate.cognito_id) {
            this.props.get_email_pref(this.props.affiliate.cognito_id)
                .then((result) => {
                    this.setState({ isLoading: false, ...result.value.data.affiliateById.email_preference });
                })
        } else if (sessionStorage.getItem('username')) {
            this.props.get_email_pref(sessionStorage.getItem('username'))
                .then((result) => {
                    this.setState({ isLoading: false, ...result.value.data.affiliateById.email_preference });
                })
        }
    }

    handleChange = (event) => {

        const checkApplicationForFalse = () => {
            if (
                !this.state.application_alerts
                && !this.state.application_advertiser_messages
                && !this.state.application_weekly_summary
            ) {
                this.setState({ application_general: false });
            }
        }

        const checkNewsletterForFalse = () => {
            if (
                !this.state.newsletter_marketing
                && !this.state.newsletter_updates
            ) {
                this.setState({ newsletter_general: false });
            }
        }

        switch (event.target.id) {
            case "application_general":
                this.state.application_general
                    ? this.setState({
                        application_general: false,
                        application_alerts: false,
                        application_advertiser_messages: false,
                        application_weekly_summary: false
                    })
                    : this.setState({
                        application_general: true,
                        application_alerts: true,
                        application_advertiser_messages: true,
                        application_weekly_summary: true,
                        email_unsubscribe: false
                    })
                break;
            case "application_alerts":
                this.state.application_alerts
                    ? this.setState({ application_alerts: false }, checkApplicationForFalse)
                    : this.setState({
                        application_general: true,
                        application_alerts: true,
                        email_unsubscribe: false
                    })
                break;
            case "application_advertiser_messages":
                this.state.application_advertiser_messages
                    ? this.setState({ application_advertiser_messages: false }, checkApplicationForFalse)
                    : this.setState({
                        application_general: true,
                        application_advertiser_messages: true,
                        email_unsubscribe: false
                    })
                break;
            case "application_weekly_summary":
                this.state.application_weekly_summary
                    ? this.setState({ application_weekly_summary: false }, checkApplicationForFalse)
                    : this.setState({
                        application_general: true,
                        application_weekly_summary: true,
                        email_unsubscribe: false,
                    })
                break;
            case "newsletter_general":
                this.state.newsletter_general
                    ? this.setState({
                        newsletter_general: false,
                        newsletter_marketing: false,
                        newsletter_updates: false,
                    })
                    : this.setState({
                        newsletter_general: true,
                        newsletter_marketing: true,
                        newsletter_updates: true,
                        email_unsubscribe: false
                    })
                break;
            case "newsletter_marketing":
                this.state.newsletter_marketing
                    ? this.setState({ newsletter_marketing: false }, checkNewsletterForFalse)
                    : this.setState({
                        newsletter_general: true,
                        newsletter_marketing: true,
                        email_unsubscribe: false,
                    })
                break;
            case "newsletter_updates":
                this.state.newsletter_updates
                    ? this.setState({ newsletter_updates: false }, checkNewsletterForFalse)
                    : this.setState({
                        newsletter_general: true,
                        newsletter_updates: true,
                        email_unsubscribe: false,
                    })
                break;
            case "email_unsubscribe":
                if (this.state.email_unsubscribe) {
                    this.setState(defaultNotifications)
                } else {
                    this.setState({
                        application_general: false,
                        application_alerts: false,
                        application_advertiser_messages: false,
                        application_weekly_summary: false,
                        newsletter_general: false,
                        newsletter_marketing: false,
                        newsletter_updates: false,
                        email_unsubscribe: true,
                    })
                }
                break;
            default:
                break;

        }
    }

    toggleToast = () => {
        this.setState(({ showToast }) => ({ showToast: !showToast }));
    };

    updateEmailPreference = () => {

        const emailFields = Object.keys(defaultNotifications);
        const emailData = Object.entries(this.state)
            .filter(([key]) => emailFields.includes(key))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        this.setState({ isLoading: true });
        this.props.update_email_pref(emailData, this.props.affiliate.cognito_id)
            .then(() => {
                this.setState({ isLoading: false, showToast: true })
            })
            .catch(() => this.setState({ isLoading: false }));
        this.props.get_email_pref(this.props.affiliate.cognito_id);
    }

    render() {

        const { showToast } = this.state;
        const toastMarkup = showToast ? (
            <Toast className="toasty" content="Notifications Saved" onDismiss={this.toggleToast} />) : null;

        return (
            <div className="notifications-settings__wrapper">
                <Frame>
                    <Layout>
                        <Layout.Section>
                            <SettingsNotifications {...this.props} isLoading={this.state.isLoading} email={this.state}
                                submitChanges={this.updateEmailPreference}
                                handleChange={this.handleChange} />
                        </Layout.Section>
                    </Layout>
                    {toastMarkup}
                </Frame>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        affiliate: { ...state.affiliate }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        update_email_pref: (data, id) => dispatch(updateEmailPref(data, id)),
        get_email_pref: (id) => dispatch(getEmailPreference(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSettings);