import { useState, useEffect } from 'react';

/**
 * @function usePagesMerchantOffers
 * Custom React hook that takes the following params, calls searchAdvertiserOffers action
 * to fetch advertiser's offers, and returns fetched offers and pagination values
 *
 * @param {Object} props - { initialPage: Int, offersPerPage: Int, offers: [Object] }
 * @param {Int} offersPerPage - Count of merchant's total offers
 * @param {function} setIsOfferLoaded - Hook that manages state of isOfferLoaded
 *
 * @returns {Object} - { offers: [Offer], ...paginationVariables }
 */
const usePagesMerchantOffers = (initialPage, offersPerPage, props, setIsOfferLoaded) => {
    const { advertiser_id } = props;
    const defaultPageOptions = {
        skip: props.skip,
        limit: offersPerPage
    };

    // Page Options
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [maxPages, setMaxPages] = useState(null);
    const [pageOptions, setPageOptions] = useState(defaultPageOptions);
    const [pageNavigation, setPageNavigation] = useState(false);

    // Offers
    const [offers, setOffers] = useState([]);

    // Loading
    const [offersLoading, setOffersLoading] = useState(false); // used to prevent duplicate api calls during latency

    const makeAPICall = (advertiser_idRec, pageOptionsRec) => {
        setIsOfferLoaded(false);
        setOffersLoading(true);
        // Call api to get offers for merchants using advertiser_id url param
        props.searchAdvertiserOffers(advertiser_idRec, pageOptionsRec)
            .then((result) => {
                const fetchedOffers = result.value.data.searchAdvertiserOffers.offers;
                setOffers(fetchedOffers);
                setPageNavigation(false);
                setOffersLoading(false);
                setIsOfferLoaded(true);
                const pageCount = result.value.data.searchAdvertiserOffers.pageInfo.count % offersPerPage === 0
                    ? (result.value.data.searchAdvertiserOffers.pageInfo.count / offersPerPage) - 1
                    : Math.ceil(result.value.data.searchAdvertiserOffers.pageInfo.count / offersPerPage);
                setMaxPages(pageCount);
            })
            .catch((error) => {
                setOffersLoading(false);
                setIsOfferLoaded(true);
            });
    };

    useEffect(() => {
        setPageNavigation(true);
    }, [pageOptions]);

    // Make API Call on initial load and if the value of advertiser_id changes
    useEffect(() => {
        if (!offersLoading) {
            if (advertiser_id || pageNavigation) {
                makeAPICall(advertiser_id, pageOptions);
            }
        }
    }, [advertiser_id, pageNavigation]);

    // Pagination variables & handlers
    let hasNext = true;
    let hasPrevious = true;

    const nextPage = () => {
        if (currentPage >= maxPages) {
            setCurrentPage(maxPages);
        } else {
            const skip = currentPage * offersPerPage;
            setCurrentPage(currentPage + 1);
            setPageOptions({ ...pageOptions, skip });
            props.setSkip(skip);
        }
    };

    const previousPage = () => {
        if (currentPage <= 1) {
            setCurrentPage(1);
        } else {
            const skip = (currentPage - 2) * offersPerPage;
            setCurrentPage(currentPage - 1);
            setPageOptions({ ...pageOptions, skip });
            props.setSkip(skip);
        }
    };

    if (currentPage <= 1) {
        hasPrevious = false;
    }

    if (currentPage >= maxPages) {
        hasNext = false;
    }

    return {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        offers
    };
};

export default usePagesMerchantOffers;
