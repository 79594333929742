// Used to reflect customization changes in the preview to avoid excessive state passing since the component tree gets pretty deep

import { themes } from '../../utils/smart-link-themes';

const initialState = {
    customizations: {
        ...themes['theme-default'],
        theme: 'theme-default'
    },
    headline: '',
    bio: '',
    socialProfiles: {
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        tiktok: '',
        soundcloud: ''
    },
    nsfwType: null,
    gaTrackingId: '',
    utm: {
        source: '',
        medium: '',
        campaign: ''
    },
    fbPixelId: '',
    mailingList: {
        platform: '',
        listId: '',
        key: '',
        formHeading: ''
    },
    preview: null
};

const smartlinksReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SMARTLINKS_PREVIEW':
            if (action.payload) {
                return {
                    ...state,
                    preview: {
                        ...action.payload
                    }
                };
            }

            return {
                ...state,
                preview: null
            };
        case 'GET_SMARTLINKS_FULFILLED':
            return {
                ...state,
                ...action.payload.data.getSmartLinks,
                loaded: true,
                preview: null
            };
        case 'UPDATE_SMARTLINK_FULFILLED':
            return {
                ...state,
                ...action.payload.data.updateSmartLink,
                preview: null
            };
        case 'UPDATE_SMARTLINK_CUSTOMIZATION_FULFILLED':
            return {
                ...state,
                customizations: {
                    ...state.customizations,
                    ...action.payload.data.updateSmartlinkCustomization
                },
                preview: null
            };
        case 'UPDATE_SMARTLINK_SOCIAL_PROFILE_FULFILLED':
            return {
                ...state,
                socialProfiles: {
                    ...state.socialProfiles,
                    ...action.payload.data.updateSmartlinkSocialProfile
                },
                preview: null
            };
        case 'CHANGE_ONBOARDING_STATUS':
            return {
                ...state,
                firstInstall: action.payload
            };
        default:
            return state;
    }
};

export default smartlinksReducer;
