import React, { useState, useEffect, useRef } from 'react';
import { Heading, TextStyle, Badge, SkeletonBodyText, SkeletonDisplayText, Modal } from '@shopify/polaris';
import format from 'date-fns/format'

import { getReferrals } from '../../store/actions/get-referrals';
import { updateReferralAffiliate } from '../../store/actions/action_updateReferralAffiliate';
import { moneyFormat } from '../../utils/number-format';
import { LlamaPagination, EmptyState, LlamaButton } from 'llama-library/components';
import { ReferralTargetTypes } from 'llama-library/types';
import { usePages } from 'llama-library/hooks';
import './offer-referrals.css';

const LoadingStateReferralTable = () => {

    const dummyPosts = new Array(5);
    dummyPosts.fill('', 0, 5);

    return (
        <>
            <div className="OfferReferrals__Totals">
                <div className="OfferReferrals__TotalStat">
                    <div className="OfferReferrals__TotalKey">Total Referred</div>
                    <div className="OfferReferrals__TotalValue"><SkeletonDisplayText /></div>
                </div>
                <div className="OfferReferrals__TotalStat">
                    <div className="OfferReferrals__TotalKey">Total Earned</div>
                    <div className="OfferReferrals__TotalValue"><SkeletonDisplayText /></div>
                </div>
            </div>
            <table className="OfferReferralsTable">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date Joined</th>
                        <th>Amount Earned</th>
                        <th>Payout Status</th>
                    </tr>
                </thead>
                <tbody>
                    {dummyPosts.map((_, index) => {
                        return (
                            <tr key={index}>
                                <td className="name"><SkeletonBodyText lines={1} /></td>
                                <td className="date"><SkeletonBodyText lines={1} /></td>
                                <td className="progress">
                                    <label htmlFor={`commission-progress-${index}`}>Commission Progress</label>
                                    <progress value={0} max="100">0%</progress> <SkeletonBodyText lines={1} />
                                </td>
                                <td className="status"><SkeletonBodyText lines={1} /></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )

}

//Calculate progress based on referral target type
const progressCalculators = {
    //Pulling total_commissions property off of commission_stats
    //Passing the function total_commissions and referral_target amount
    [ReferralTargetTypes.COMMISSION]: ({ total_commissions }, target) => {
        if (total_commissions >= target) {
            return 100;
        }
        return Math.round((total_commissions / target) * 100);
    },
    //Pulling total_conversions off of commission_stats
    //Passing the function total_conversions and referral_target amount
    [ReferralTargetTypes.ORDERS]: ({ total_conversions }, target) => {
        if (total_conversions >= target) {
            return 100;
        }
        return Math.round((total_conversions / target) * 100);
    }
}

//Calculate percentage of referral fulfillment
const calculateReferalPercentage = (referral_target, commission_stats) => {
    if(commission_stats === null) {
        commission_stats = {
            total_rev: 0,
            total_commissions: 0,
            total_conversions: 0
        } 
    }
    const { type, amount } = referral_target;

    if (typeof progressCalculators[type] !== 'function') {
        return null;
    }

    const progressCalculatorFactory = progressCalculators[type];
    return progressCalculatorFactory(commission_stats, amount);
}

const OfferReferralTable = ({ tracking_id, referrals = [], offer }) => {

    const initialRun = useRef(true);
    const [isRedeemed, setIsRedeemed] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [redeemReferral, setRedeemReferral] = useState({});
    
    const totalReferred = referrals.length;
    const totalEarned = referrals.filter((item) => item.payout_status).length * offer.rate.amount;

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, 10, referrals);

    let referralPosts = posts
    
    useEffect(() => {
        // Run 2nd time and onwards; skip first run
        if (!initialRun.current) {
            //If Redeem button is clicked then display the Modal
            isRedeemed && setDisplayModal(true);
        } else
            initialRun.current = false;
    }, [isRedeemed]);

    //Handler for Redeem button
    const redeemHandler = (referral) => {
        setIsRedeemed(!isRedeemed);
        setRedeemReferral(referral);
    }

    const closeModal = () => {
        setIsRedeemed(false);
        setDisplayModal(false);
        setRedeemReferral({});
    }

    const redeemReferralCommission = () => {
        let payload = {
            "reference": {
                "referrer": tracking_id,
                "redeem_commission": true
            }
        };

        updateReferralAffiliate(redeemReferral.cognito_id, payload)
            .then((result) => {
                if (result.data.updateAffiliateReferral.status === 'SUCCESS') {
                    referralPosts.forEach((ref) => {
                        if (ref.cognito_id === redeemReferral.cognito_id) {
                            ref.reference.redeem_commission = true;
                        }
                    });
                } else {
                    console.log('Redeem action has failed! Please try again');
                }

                closeModal();
            })
            .catch((error) => {
                console.log('updateReferralAffiliate ERROR:', error);
            })
    }

    // Redeem confirmation modal
    const redeemConfirmationModal = () => {
        return (
            <Modal
                open={displayModal}
                onClose={closeModal}
                primaryAction={{
                    content: 'Yes',
                    onAction: redeemReferralCommission
                }}
                secondaryActions={{
                    content: 'Later',
                    onAction: closeModal
                }}
                title="Are you sure you want to redeem the commission?"
            >
                <Modal.Section>
                    <p>This commission will be added to your next regular monthly commission payout</p>
                </Modal.Section>
            </Modal>
        );
    }

    return (
        <>
            {displayModal && redeemConfirmationModal()}
            <div className="OfferReferrals__Totals">
                <div className="OfferReferrals__TotalStat">
                    <div className="OfferReferrals__TotalKey">Total Referred</div>
                    <div className="OfferReferrals__TotalValue">{totalReferred}</div>
                </div>
                <div className="OfferReferrals__TotalStat">
                    <div className="OfferReferrals__TotalKey">Total Earned</div>
                    <div className="OfferReferrals__TotalValue">{moneyFormat(totalEarned, true)}</div>
                </div>
            </div>
            <table className="OfferReferralsTable">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date Joined</th>
                        <th>Amount Earned</th>
                        <th>Payout Status</th>
                    </tr>
                </thead>
                <tbody>
                    {referralPosts.map((item, index) => {
                        const progress = calculateReferalPercentage(offer.referral_target, item.commission_stats);

                        // Setup status cell
                        let statusBadge = null;
                        let commission = item.commission_stats ? (item.commission_stats.total_commissions ? item.commission_stats.total_commissions : 0) : 0;
                        let redeem_commission = item.reference.redeem_commission ? item.reference.redeem_commission : false;
                        let invoice = item.reference.invoice ? item.reference.invoice : "";
                        let invoice_status = item.reference.invoice_status;

                        if (progress >= 100 && redeem_commission && invoice && invoice_status === 'PAID') {
                            statusBadge = <Badge status="success">PAID</Badge>;
                        } else if (progress >= 100 && !redeem_commission) {
                            statusBadge = <LlamaButton
                                disabled={isRedeemed}
                                onClick={() => redeemHandler(item)}
                            >Redeem</LlamaButton>
                        } else if (progress >= 100 && (redeem_commission || invoice_status === 'SENT' || invoice_status === 'DRAFT')) {
                            statusBadge = <Badge status="attention">PROCESSING</Badge>;
                        } else if (progress < 100 && !redeem_commission) {
                            statusBadge = <Badge status="attention">PENDING</Badge>;
                        }

                        // If commission is more than $50, we want to display as $50 only
                        if (commission >= 50) {
                            commission = 50;
                        }

                        return (
                            <tr key={item.cognito_id}>
                                <td className="name" data-has-name={item.name !== null}>{item.name ? item.name : 'not provided yet'}</td>
                                <td className="date">{item.account && item.account.signUpDate ? format(item.account.signUpDate, 'MMM DD, YYYY') : 'Unknown'}</td>
                                <td className="progress">
                                    <label htmlFor={`commission-progress-${index}`}>Commission Progress</label>
                                    <progress value={progress < 3 ? 3 : progress} max="100">{progress}%</progress> <span className="commission-amount">{moneyFormat(commission)}</span>
                                </td>
                                <td className="status">{statusBadge}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {maxPages > 1 &&
                <LlamaPagination 
                    onNext={nextPage}
                    onPrevious={previousPage}
                    hasNext={hasNext}
                    hasPrevious={hasPrevious}
                    currentPage={currentPage}
                    maxPages={maxPages}
                />
            }
        </>
    )

}

const OfferReferrals = (props) => {

    const { offer, affiliate } = props;
    const [referrals, setReferrals] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        if (!affiliate.cognito_id || !offer.offer_id) {
            return null;
        }

        setIsLoading(true);
        getReferrals(affiliate.cognito_id, offer.offer_id)
            .then(({ affiliateById }) => {
                const referrals = affiliateById.referrals.map((referral) => {
                    if (!referral.reference || !Array.isArray(referral.reference)) {
                        return null;
                    }

                    const reference = referral.reference.find((item) => item.referrer_offer === offer.offer_id);
                    referral.reference = reference;

                    return referral;
                })
                .filter((item) => item);

                setReferrals(referrals);
                setIsLoaded(true);
            })

    }, [affiliate.cognito_id, offer.offer_id])

    const emptyStateVerbiage = (
        <>
            <div className="OfferReferrals__EmptyStateHead">Tell your friends to join Llama as an ambassador, and you'll earn $50 when they earn their first $50!</div>
            <ul className="OfferReferrals__EmptyStateSteps">
                <li><span>Step #1:</span> Copy your referral link</li>
                <li><span>Step #2:</span> Send it to friends, family, or anyone else who wants to join Llama</li>
                <li><span>Step #3:</span> Profit</li>
            </ul>
        </>
    )

    return (
        <div className="OfferReferrals__Wrapper" data-test="component-offer-referrals">
            <div className="OfferReferrals__Heading">
                <Heading>AMBASSADOR REFERRALS</Heading>
                <TextStyle variation="subdued">
                    <span className="subtitle">
                        {offer.description}
                    </span>
                </TextStyle>
            </div>

            {/* LOADING STATE */}
            {isLoading && !isLoaded &&
                <LoadingStateReferralTable />
            }

            {/* EMPTY STATE */}
            {referrals.length === 0 && isLoaded &&
                <EmptyState message={emptyStateVerbiage} />
            }

            {/* ACTIVE STATE */}
            {referrals.length > 0 && isLoaded &&
                <OfferReferralTable tracking_id={affiliate.tracking_id} referrals={referrals} offer={offer} />
            }

        </div>
    )

}

export default OfferReferrals;