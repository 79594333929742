import React from 'react';
import { I18n, Auth } from 'aws-amplify';
import { LlamaButton } from 'llama-library/components';
import {
    SignIn,
    ForgotPassword,
    FederatedButtons,
    AuthPiece,
    SectionBody,
    InputRow,
    Link
} from 'aws-amplify-react';

import SocialAuthButtons from './social-auth-buttons';

import './amplify.css';

import llamaHead from '../assets/llama-head.png';
import loginGraphic from '../assets/ambassador-login-graphic.svg';
import llamaBgMobile from '../assets/login-graphic-mobile.png';

const logger = {
    debug: (msg) => {
        if (process.env.NODE_ENV === "development") {
            console.log(msg)
        }
    }
};

export default class LlamaSignIn extends AuthPiece {
    constructor(props) {
        super(props);

        this.checkContact = this.checkContact.bind(this);
        this.signIn = this.signIn.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this._validAuthStates = ['signIn', 'signedOut'];
        this.state = {
            showEmailForm: false
        };
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.keyCode !== 13) return;

        const { hide = [] } = this.props;
        if (this.props.authState === 'signIn' && hide.includes(SignIn)) {
            this.signIn();
        }
    }

    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user)
            .then(data => {
                if (!data.verified || data.verified === "") {
                    this.changeState('signedIn', user);
                } else {
                    user = Object.assign(user, data);
                    this.changeState('signedIn', user);
                    // this.changeState('verifyContact', user);
                }
            });
    }

    async signIn() {
        try {
            sessionStorage.removeItem('shop');
            sessionStorage.removeItem('promo');
            sessionStorage.removeItem('offer_id');
            sessionStorage.removeItem('aff_id');
            sessionStorage.removeItem('from');
            sessionStorage.removeItem('socialUser');
        } catch (error) {
            console.log(error);
        }

        if(!this.inputs.username && !this.inputs.password){
            return this.error('Email and Password are required.');
        }
        if(this.inputs.username && !this.inputs.password){
            return this.error('Password is required.');
        }
        if(!this.inputs.username && this.inputs.password){
            return this.error('Email is required.');
        }
        const { username, password } = this.inputs;
        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.setState({ loading: true });
        try {
            let user;

            if (/Facebook_/g.test(username) || /Google_/g.test(username)) {
                debugger
                user = await Auth.signIn(username, password);
            } else {
                user = await Auth.signIn(username.toLowerCase(), password);
            }

            logger.debug(user);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                logger.debug('confirm user with ' + user.challengeName);
                this.changeState('confirmSignIn', user);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                logger.debug('require new password', user.challengeParam);
                this.changeState('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', user.challengeParam);
                this.changeState('TOTPSetup', user);
            } else {
                this.checkContact(user);
            }
        } catch (err) {
            if (err.code === 'NotAuthorizedException') {
                return this.error('Incorrect email or password.')
            } else if (err.code === "InvalidParameterException" && err.message.indexOf('userName') !== -1) {
                return this.error('Invalid email address format')
            }
            if (err.code === 'UserNotConfirmedException') {
                logger.debug('the user is not confirmed');
                this.changeState('confirmSignUp', { username });
            } else if (err.code === 'PasswordResetRequiredException') {
                logger.debug('the user requires a new password');
                this.changeState('forgotPassword', { username });
            } else {
                this.error(err);
            }
        } finally {
            this.setState({ loading: false })
        }
    }

    toggleEmailForm = () => {
        this.setState({ showEmailForm: !this.state.showEmailForm });
    }

    showComponent(theme) {
        const { authState, hide = [], federated, onStateChange, onAuthEvent, override = [], promo } = this.props;
        const hideForgotPassword = !override.includes('ForgotPassword') && hide.some(component => component === ForgotPassword);
        const applyButtonClass = ['amplify-button'];

        return (
            <div className="signin-amplify__wrapper">
                <div className="signin-amplify__form-wrapper">
                    <div className="signin-amplify__signin-form">
                        <div className="signin-amplify__mobile-wrapper">
                            <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                            <div className="signin-amplify__mobile-text">
                                <h1 className="signin-amplify__header">Llama {(this.props.params?.from === 'smartlinks') ? 'SmartLinks' : 'Ambassador'} Sign In</h1>
                                <p className="signin-amplify__subheader">Sign in to your account.</p>
                            </div>
                        </div>
                        <SectionBody theme={theme}>
                            {!this.state.showEmailForm
                                && (
                                    <>
                                        <SocialAuthButtons
                                            authType="SIGNIN"
                                            params={{ promo }}
                                        />
                                        <SocialAuthButtons.Separator />
                                        <SocialAuthButtons.EmailToggle
                                            authType="SIGNIN"
                                            onClick={this.toggleEmailForm}
                                        />
                                    </>
                                )
                            }
                            {this.state.showEmailForm
                                && (
                                    <>
                                        <SocialAuthButtons.Breadcrumb
                                            onClick={this.toggleEmailForm}
                                        />
                                        <div className="amplify-form-item">
                                            <InputRow
                                                autoFocus
                                                placeholder={I18n.get('Email')}
                                                theme={theme}
                                                key="username"
                                                name="username"
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div className="amplify-form-item">
                                            <InputRow
                                                placeholder={I18n.get('Password')}
                                                theme={theme}
                                                key="password"
                                                type="password"
                                                name="password"
                                                onChange={this.handleInputChange}
                                            />
                                            {
                                                !hideForgotPassword && (
                                                    <div className="amplify-hint">
                                                        {I18n.get('Forgot your password? ')}
                                                        <Link theme={theme} onClick={() => this.changeState('forgotPassword')}>
                                                            {I18n.get('Reset password')}
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                        </SectionBody>
                        <div className="amplify__custom-footer">
                            {this.state.showEmailForm
                                && (
                                    <div className="amplify__button-wrapper">
                                        <LlamaButton
                                            classes={applyButtonClass}
                                            theme={theme}
                                            onClick={this.signIn}
                                            loading={this.state.loading}
                                        >
                                            {I18n.get('Sign In')}
                                        </LlamaButton>
                                    </div>
                                )
                            }
                            <div className="amplify-section-footer_links">
                                {I18n.get('Don\'t have an account? ')}
                                <div className="amplify__custom-link">
                                    <Link theme={theme} onClick={() => this.changeState('signUp')}>
                                        {I18n.get('Sign Up')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signin-amplify__graphic-outer">
                    <img src={llamaBgMobile} className="signin-amplify__graphic-mobile" /> 
                    <img alt="Leo the Llama Ambassador and his network" src={loginGraphic} className="signin-amplify__login-graphic" />
                </div>
            </div>
        );
    }
}
