/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import {sendGraphQL} from './sendRequest';

export const getReferrals = (cognito_id, offer_id) => {

    const query = `
        query getReferrals($cognito_id: String, $offer_id: String) {
            affiliateById(cognito_id: $cognito_id) {
                referrals(offer_id: $offer_id) {
                    name
                    cognito_id
                    email
                    account {
                        signUpDate
                    }
                    commission_stats {
                        total_rev
                        total_commissions
                        total_conversions
                    }
                    reference {
                        referrer
                        referrer_payout
                        referrer_type
                        referrer_offer
                        commission
                        redeem_commission
                        invoice
                        invoice_status
                    }
                }
            }
        }
    `

    const variables = {
        cognito_id,
        offer_id,
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data)

   /*  let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, { query, variables }, { headers })
        .then(response => response.data.data) */

}