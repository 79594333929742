import React, { Component } from 'react';
import { connect } from 'react-redux';

import { NotificationViewStatus, AchievementViewStatus } from '../../utils/types';
import notificationBell from '../../assets/bell-gray.svg';
import achievementCrown from '../../assets/crown-gray.svg';
import achievementCrownActive from '../../assets/crown-gold.svg';
import AchievementNotificationMenu from '../achievement-notifications/menu/achievement-notification-menu';

import './header.css';

export class Header extends Component {

    constructor(props) {
        super(props);

        this.state = { showFlyoutMenu: false };
    }

    flyOutToggleHandler = (newState) => {
        if (newState !== undefined && newState !== null) {
            this.setState({ showFlyoutMenu: newState });
        }
        this.setState({ showFlyoutMenu: !this.state.showFlyoutMenu });
    }

    render() {

        const notificationCount = this.props.affiliate && Array.isArray(this.props.affiliate.notifications)
            ? this.props.affiliate.notifications
                .filter((item) => item.view_status === NotificationViewStatus.UNSEEN)
                .length
            : null;

        const achievementNotificationCount = this.props.affiliate && Array.isArray(this.props.achievementNotifications)
            ? this.props.achievementNotifications
                .filter((item) => item.view_status === AchievementViewStatus.UNSEEN)
                .length
            : null;

        return (
            <div className="header" data-test="component-header">
                <div className="header__title">
                    {this.props.title}
                </div>

                <div className="notification_container">

                    <div className="signout_container">
                        <span> {this.props.affiliate.email ? this.props.affiliate.email : 'You\'re logged in'} </span>
                    </div>

                    <div className="header__achievements-menu">
                        <div className="header__achievements" onClick={this.flyOutToggleHandler} data-test="header-achievements">
                            {achievementNotificationCount !== 0 && achievementNotificationCount && (
                                <div className="header__achievements-indicator">{achievementNotificationCount}</div>
                            )}
                            <span className="header__achievement">
                                <img src={achievementNotificationCount !== 0 && achievementNotificationCount ? achievementCrownActive : achievementCrown} alt="crown" />
                            </span>
                        </div>
                        {this.state.showFlyoutMenu && <div className="header__achievements-carot"></div>}
                        <AchievementNotificationMenu
                            {...this.props}
                            {...this.state}
                            open={this.state.showFlyoutMenu}
                            closed={() => this.flyOutToggleHandler(false)}
                        />
                    </div>

                    <div className="header__notifications" data-test="header-notifications" onClick={this.props.toggleSideDrawer}>
                        {notificationCount !== 0 && notificationCount && (
                            <div className="header__notifications-indicator">
                                {notificationCount > 10 ? '10+' : notificationCount}
                            </div>
                        )}
                        <span className="header__notification">
                            <img
                                src={notificationBell}
                                className="header__notification-icon"
                                alt="bell"
                            />
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        title: state.app.headerTitle,
        achievementNotifications: state.achievementNotifications.achievementNotifications
    };
};

export default connect(mapStateToProps)(Header);
