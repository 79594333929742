
export const CHANGE_HEADER_TITLE = 'CHANGE_HEADER_TITLE';

export const changeHeaderTitle = (newTitle) => {

    return {
        type: CHANGE_HEADER_TITLE,
        payload: newTitle
    }

}