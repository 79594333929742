import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Tooltip } from '@shopify/polaris';

import { LlamaButton, PlaceholderPhoto } from 'llama-library/components';
import { defaultBanners } from 'llama-library/utils';

import ApplyToOfferButton from '../OfferDetail/apply-to-offer-button';
import DiscoverMerchantsLink from '../DiscoverMerchants/DiscoverMerchantsLink';

import { RateTypes } from '../../utils/types';
import { moneyFormat } from '../../utils/number-format';

import './offer-preview-card.css';

const OfferPreviewCard = ({ offer, application, history, setToastVerbiage, applicationsLoaded }) => {
    const advStatus = (application && application.advertiser_status) ? application.advertiser_status : 'NEVER_APPLIED';
    const [applicationStatus, setApplicationStatus] = useState(advStatus);

    const monthDiff = moment().diff(moment(offer.create_date_utc), 'months');
    const isNew = (monthDiff < 1);
    const isProductGiveaway = offer.offer_type === 'product_giveaway';
    const isPinned = offer.pinned === true;

    const navigateToOffer = () => {
        if (applicationStatus === 'approved') {
            history.push(`/offer/${offer.offer_id}`);
        } else {
            history.push(`/discover/offer/${offer.offer_id}`);
        }
    };

    const formatRate = ({ type, amount }) => {
        switch (type) {
            case RateTypes.FLAT_ITEM:
                return `${moneyFormat(amount, true)} per item`;
            case RateTypes.FLAT_ORDER:
                return `${moneyFormat(amount, true)} per order`;
            case RateTypes.PERCENT_REVENUE:
            default:
                return `${amount}% of revenue`;
        }
    };

    const formatStatus = () => {
        if (applicationStatus === 'APPROVED' || applicationStatus === 'PENDING') {
            return applicationStatus;
        }
        if (isNew) {
            return 'NEW!';
        }
        return null;
    };

    const formatAriaStatus = () => {
        switch (applicationStatus) {
            case 'APPROVED':
                return 'You’re approved to promote this offer';
            case 'PENDING':
                return 'Your application is pending approval';
            default:
                break;
        }
        if (isNew) {
            return 'This is a new offer';
        }
        return null;
    };

    const formatDescription = () => {
        if (offer.description) {
            return offer.description;
        }

        // if no description, generate one based on commission
        let description = '';
        if (offer.offer_type === 'product_giveaway') {
            description = `This is a product giveaway offer. Instead of earning commissions, you’ll get a coupon code (at ${offer.advertiser.company}’s discretion) for one or more eligible free products.`;
        } else {
            description = 'Earn ';
            switch (offer.rate.type) {
                case RateTypes.FLAT_ITEM:
                    description += `${moneyFormat(offer.rate.amount, true)} per item in your customer’s first order`;
                    break;
                case RateTypes.FLAT_ORDER:
                    description += `${moneyFormat(offer.rate.amount, true)} from your customer’s first order`;
                    break;
                case RateTypes.PERCENT_REVENUE:
                default:
                    description += `${offer.rate.amount}% of your customer’s first order total`;
                    break;
            }
            description += ' and ';
            switch (offer.longterm_rate.type) {
                case RateTypes.FLAT_ITEM:
                    description += `${moneyFormat(offer.longterm_rate.amount, true)} per item in their future orders.`;
                    break;
                case RateTypes.FLAT_ORDER:
                    description += `${moneyFormat(offer.longterm_rate.amount, true)} from their future orders.`;
                    break;
                case RateTypes.PERCENT_REVENUE:
                default:
                    description += `${offer.longterm_rate.amount}% of their future order totals.`;
                    break;
            }
        }

        return description;
    };

    const renderBadges = () => {
        const badges = [];

        if (isPinned) {
            badges.push({
                type: 'pinned',
                ariaLabel: 'This is a pinned offer.',
                text: 'Pinned'
            });
        }

        if (isProductGiveaway) {
            badges.push({
                type: 'product-giveaway',
                ariaLabel: 'This is a product giveaway offer.',
                text: 'Product Giveaway'
            });
        }

        console.log(applicationStatus);

        if (applicationStatus !== 'NEVER_APPLIED' || isNew) {
            badges.push({
                type: 'application-status',
                ariaLabel: formatAriaStatus(),
                text: formatStatus(),
                status: (isNew) ? 'new' : applicationStatus.toLowerCase()
            });
        }

        if (badges.length > 0) {
            return (
                <ul className="badges">
                    {badges.map((badge) => {
                        return <li className={badge.type} data-status={badge.status} aria-label={badge.ariaLabel}><span>{badge.text}</span></li>;
                    })}
                </ul>
            );
        }

        return null;
    };

    let bannerImage = (defaultBanners[offer.category]) ? defaultBanners[offer.category][0] : defaultBanners.default[0];
    let bannerImage2x = (defaultBanners[offer.category]) ? defaultBanners[offer.category][1] : defaultBanners.default[1];
    if (offer.bannerImage) {
        bannerImage = offer.bannerImage;
        bannerImage2x = null;
    }

    let epc = 0.00;
    let volume = 0.00;
    if (offer.stats) {
        epc = offer.stats.epc;
        volume = offer.stats.revenue;
    }

    let ltv = 0.00;
    if (offer.long_term_rate && offer.long_term_rate.ltv) {
        ltv = parseFloat(offer.long_term_rate.ltv) / 100;
    }

    let avgCart = 0.00;
    if (offer.long_term_rate && offer.long_term_rate.avg_cart_value) {
        avgCart = parseFloat(offer.long_term_rate.avg_cart_value) / 100;
    }

    const isMerchantOfferPage = history.location.pathname.includes('brand');

    return (
        <div className="offer-preview-card-wrapper" data-is-multi={!isMerchantOfferPage ? offer.advertiser.totalOffers > 1 : null}>
            <div className="offer-preview-card">
                {!/Trident\/|MSIE /.test(window.navigator.userAgent)
                    ? (
                        <p onClick={navigateToOffer} className="offer-preview__cover">
                            <img src={bannerImage} srcSet={bannerImage2x ? `${bannerImage} 1x, ${bannerImage2x} 2x` : null} alt="" />
                        </p>
                    ) // favor img + object-fit for everything that's not IE
                    : (
                        <div onClick={navigateToOffer} className="offer-preview__cover" style={{
                            backgroundImage: `url(${bannerImage})`
                        }} />
                    )
                }

                <p className="category">{offer.category}</p>
                <div className="title-brand-wrapper">
                    <h3 onClick={navigateToOffer} data-test="offer-name">{offer.name}</h3>
                    <div className="brand">
                        <PlaceholderPhoto photo={offer.advertiser.avatar_image} width="20" height="20" />
                        <p data-test="brand">{offer.advertiser.company}</p>
                    </div>
                    {!isMerchantOfferPage
                        && (
                            <div className="merchants-link">
                                <DiscoverMerchantsLink
                                    totalOffers={offer.advertiser.totalOffers}
                                    history={history}
                                    advertiser_id={offer.advertiser_id}
                                />
                            </div>
                        )
                    }

                    {renderBadges()}
                </div>
                <p className="description">{formatDescription()}</p>

                {isProductGiveaway
                    && offer.eligibleProducts
                    && offer.eligibleProducts.products
                    && Array.isArray(offer.eligibleProducts.products)
                    && offer.eligibleProducts.products.length > 0
                    ? (
                        <>
                            <h4>Eligible Products</h4>
                            <ul className="eligible-products" data-num-products={offer.eligibleProducts.products.length}>
                                {offer.eligibleProducts.products.map((product, index) => {
                                    if (offer.eligibleProducts.numExtraProducts === 0 || (offer.eligibleProducts.numExtraProducts > 0 && index < 7)) {
                                        return <li key={`${product.title}`}><Tooltip content={product.title}><img src={product.image.src} alt={product.title} /></Tooltip></li>;
                                    }
                                    return null;
                                })}
                                {offer.eligibleProducts.numExtraProducts > 0
                                    && <li className="more" onClick={navigateToOffer}><b>+{offer.eligibleProducts.numExtraProducts + 1}</b> more</li>
                                }
                            </ul>
                        </>
                    )
                    : (
                        <>
                            <dl className="commission-rate">
                                <dt>Initial Commission</dt>
                                <dd data-test="initial-commission">{formatRate(offer.rate)}</dd>
                                <dt>Long-Term Commission</dt>
                                <dd data-test="longterm-commission">{formatRate(offer.longterm_rate)}</dd>
                            </dl>

                            <h4>Current Llama Stats</h4>
                            <dl className="stats">
                                <dt>LTV</dt>
                                <dd data-test="ltv">{moneyFormat(ltv, true)}</dd>
                                <dt>EPC</dt>
                                <dd data-test="epc">{moneyFormat(epc, true)}</dd>
                                <dt>Avg. Cart</dt>
                                <dd data-test="avg-cart">{moneyFormat(avgCart, true)}</dd>
                                <dt>Revenue</dt>
                                <dd data-test="volume">{moneyFormat(volume, true)}</dd>
                            </dl>
                        </>
                    )
                }

                <div className="button-group">
                    <LlamaButton onClick={navigateToOffer} secondary>View Details</LlamaButton>
                    {applicationsLoaded && applicationStatus !== 'APPROVED' && applicationStatus !== 'PENDING'
                        && (
                            <ApplyToOfferButton
                                offer={offer}
                                setToastVerbiage={setToastVerbiage}
                                fromOfferCard
                                applicationStatus={applicationStatus}
                                setApplicationStatus={setApplicationStatus}
                                data-test="apply-to-offer-button"
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

OfferPreviewCard.propTypes = {
    offer: PropTypes.shape({
        advertiser_id: PropTypes.string.isRequired,
        create_date_utc: PropTypes.string.isRequired,
        offer_id: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        bannerImage: PropTypes.string,
        creatives: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string
        })),
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        rate: PropTypes.shape({
            type: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired
        }).isRequired,
        longterm_rate: PropTypes.shape({
            type: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired
        }).isRequired,
        advertiser: PropTypes.shape({
            company: PropTypes.string.isRequired,
            avatar_image: PropTypes.string,
            totalOffers: PropTypes.number.isRequired
        }).isRequired,
        tracking: PropTypes.shape({
            Stat: PropTypes.shape({
                epc: PropTypes.string,
                revenue: PropTypes.string
            })
        }),
        long_term_rate: PropTypes.shape({
            ltv: PropTypes.string,
            avg_cart_value: PropTypes.string
        }),
        offer_type: PropTypes.string
    }).isRequired,
    application: PropTypes.shape({
        advertiser_status: PropTypes.string
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        })
    }).isRequired,
    setToastVerbiage: PropTypes.func.isRequired,
    applicationsLoaded: PropTypes.bool.isRequired
};

OfferPreviewCard.defaultProps = {
    application: null
};

export default OfferPreviewCard;
