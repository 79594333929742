import { GET_ACHIEVEMENT_NOTIFICATIONS_FULFILLED, UPDATE_ACHIEVEMENTS_VIEW_STATUS } from '../actions/action_achievement-notifications';

const defaultState = { achievementNotifications: [] };

const achievementNotificationsReducer = (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ACHIEVEMENT_NOTIFICATIONS_FULFILLED: {
            if (
                !payload
                || !payload.data
                || !payload.data.getAchievementNotifications
                || !Array.isArray(payload.data.getAchievementNotifications)
            ) {
                return state;
            }
            return {
                ...state,
                achievementNotifications: payload.data.getAchievementNotifications
            };
        }

        case UPDATE_ACHIEVEMENTS_VIEW_STATUS: {
            return {
                ...state,
                achievementNotifications: payload
            };
        }

        default: {
            return state;
        }
    }
};

export default achievementNotificationsReducer;
