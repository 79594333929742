/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */

import { sendGraphQL } from "./sendRequest"

export const generateTrackingLink = (offer_id, affiliate_id) => {

    const query = `
        query getLinks($offer_id: String!, $affiliate_id: String!) {
            generateTrackingLink(offer_id: $offer_id, affiliate_id: $affiliate_id) {
                affiliate_id
                offer_id
                click_url
                impression_pixel 
            }
        }
    `

    const variables = {
        offer_id,
        affiliate_id
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data)


    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, { query, variables }, { headers })
        .then(response => response.data.data) */

}
