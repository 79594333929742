import { GET_AUTH_DATA_FULFILLED, UPDATE_AUTH_DATA } from '../actions/auth-data';

const defaultState = null;

const authDataReducer = (state = defaultState, action) => {

    const { type } = action;

    switch (type) {
        case GET_AUTH_DATA_FULFILLED:
        case UPDATE_AUTH_DATA: {
            const { payload } = action;
            const authData = { ...payload };
            return { ...state, ...authData };
        }
        default: {
            return state;
        }

    }

}

export default authDataReducer;