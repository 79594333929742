import React from 'react';

import { SkeletonBodyText, Thumbnail } from '@shopify/polaris';
import { LlamaPagination } from 'llama-library/components';

import numberFormat, { moneyFormat } from '../../utils/number-format';

const ProductSales = (props) => {
    const { numSales, curPage, perPage, loading, sales, loadSales, goToOffer } = props;
    const numPages = Math.ceil(numSales / perPage);
    const loadingRows = [];
    for (let i = 0; i < perPage; i++) {
        loadingRows.push(i);
    }

    return (
        <section className="dashboard--product-sales" data-test="product-sales-container">
            <h2 data-test="product-sales-title">Recent Product Sales</h2>
            <table data-test="product-sales-table">
                <thead>
                    <tr>
                        <th className="product">Product</th>
                        <th className="offer">Offer</th>
                        <th className="count">Qty</th>
                        <th className="amount">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {loading
                        ? loadingRows.map((item) => {
                            return (
                                <tr key={item}>
                                    <td className="product">
                                        <span>
                                            <span className="img-wrap"><Thumbnail size="small" /></span>
                                            <span className="meta">
                                                <span className="product-name"><SkeletonBodyText lines="1" /></span>
                                                <span className="subcell"><SkeletonBodyText lines="1" /></span>
                                            </span>
                                        </span>
                                    </td>
                                    <td className="offer"><SkeletonBodyText lines="1" /></td>
                                    <td className="count qty"><SkeletonBodyText lines="1" /></td>
                                    <td className="amount total"><SkeletonBodyText lines="1" /></td>
                                </tr>
                            );
                        })
                        : sales.map((product) => {
                            return (
                                <tr key={product.product_id}>
                                    <td className="product">
                                        <span>
                                            <span className="img-wrap"><img src={product.product_image ? product.product_image : 'https://dummyimage.com/38x38/fff/000?text=no+image'} alt="" /></span>
                                            <span className="meta">
                                                <span className="product-name">{product.product_name}</span>
                                                <span className="subcell">{product.company}</span>
                                            </span>
                                        </span>
                                    </td>
                                    <td className="offer" onClick={() => goToOffer(product.offer_id)}>{product.offer_name}</td>
                                    <td className="count qty">{numberFormat(product.quantity)}</td>
                                    <td className="amount total">{moneyFormat(product.total_price)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {numPages > 1
                && (
                    <LlamaPagination
                        hasNext={curPage !== numPages}
                        hasPrevious={curPage !== 1}
                        onNext={() => { return loadSales(curPage + 1); }}
                        onPrevious={() => { return loadSales(curPage - 1); }}
                        currentPage={curPage - 1}
                        maxPages={numPages - 1}
                    />
                )
            }
        </section>
    );
};

export default ProductSales;
