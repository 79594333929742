import { sendGraphQL } from '../sendRequest';
import { UPDATE_AFFILIATE } from '../types';

// facebook
export const setFeaturedPage = (cognito_id, featuredPageId) => {
    const query = `
        mutation setFeaturedPage($cognito_id: String, $featuredPageId: String){
            setFeaturedPage(cognito_id: $cognito_id, featuredPageId: $featuredPageId)
        }
    `;

    const variables = {
        cognito_id,
        featuredPageId
    };

    const payload = {
        query,
        variables
    };

    return {
        type: UPDATE_AFFILIATE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

// twitter
export const setFeaturedAccount = (cognito_id, featuredAccountId) => {
    const query = `
        mutation setFeaturedAccount($cognito_id: String, $featuredAccountId: String){
            setFeaturedAccount(cognito_id: $cognito_id, featuredAccountId: $featuredAccountId)
        }
    `;

    const variables = {
        cognito_id,
        featuredAccountId
    };

    const payload = {
        query,
        variables
    };

    return {
        type: UPDATE_AFFILIATE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

// youtube
export const setFeaturedChannel = (cognito_id, featuredChannelId) => {
    const query = `
        mutation setFeaturedChannel($cognito_id: String, $featuredChannelId: String){
            setFeaturedChannel(cognito_id: $cognito_id, featuredChannelId: $featuredChannelId)
        }
    `;

    const variables = {
        cognito_id,
        featuredChannelId
    };

    const payload = {
        query,
        variables
    };

    return {
        type: UPDATE_AFFILIATE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};
