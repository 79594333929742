/* import axios from 'axios';
import {NEXT_APP_API_URL} from '../../../config'; */
import {sendGraphQL} from '../sendRequest';

export const getBillingInfo = (cognito_id) => {
    const query = `
        query getBillingInfo($cognito_id: String){
            getBillingInfo(cognito_id: $cognito_id){
                card_data{
                    brand,
                    last4,
                    exp_month,
                    exp_year
                }
                invoiceGroups{
                    status,
                    created,
                    number,
                    amount_paid,
                    description
                }
            }
        }
    `;

    const variables = {
        cognito_id
    }

    const payload = {
        query,
        variables
    }

    return {
        type: 'GET_SMARTLINK_BILLING_HISTORY',
        payload: sendGraphQL(payload)
            .then(response => response.data)
            .catch(err => console.error(err))
    }

    /* const headers = {
        'Content-Type': 'application/json'
    }

    return {
        type: 'GET_SMARTLINK_BILLING_HISTORY',
        payload: axios.post(NEXT_APP_API_URL, params, {headers})
            .then(response => response.data)
            .catch(err => console.error(err))
    } */
}

export const updateCard = (cognito_id, card_token, last4) => {
    const query = `
        mutation updateCard($cognito_id: String!, $card_token: String!, $last4: String){
            updateCard(cognito_id: $cognito_id, card_token: $card_token, last4: $last4){
                brand,
                last4,
                exp_month,
                exp_year
            }
        }
    `;

    const variables = {
        cognito_id,
        card_token,
        last4
    }

    const payload = {
        query,
        variables
    }

    return {
        type: 'SMARTLINKS_UPDATE_CARD',
        payload: sendGraphQL(payload)
            .then(response => response.data)
            .catch(err => console.error(err))
    }

    /* const headers = {
        'Content-Type': 'application/json'
    }

    return {
        type: 'SMARTLINKS_UPDATE_CARD',
        payload: axios.post(NEXT_APP_API_URL, params, {headers})
            .then(response => response.data)
            .catch(err => console.error(err))
    } */
}