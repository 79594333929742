/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React, { useState, useEffect } from 'react';
import { Page, Toast, Frame } from '@shopify/polaris';

import './styles/discover.css';
import { connect } from 'react-redux';
import { searchOffers } from '../store/actions/action_getOffers';
import { getApplications } from '../store/actions/action_getApplications';
import { changeHeaderTitle } from '../store/actions/header';
import { DiscoverOffersSidebar, DiscoverOffers } from '../components/DiscoverOffers/discover-offers';
import useApplications from '../hooks/use-applications';
import { discoverFilters } from '../store/actions/action_updateDiscoverFilters';
import { isEqual } from 'lodash';

const Discover = (props) => {
    let tagFromUrl = window.location.search.match(/tagged=([\w\d\+]+)\&?/);
    if(tagFromUrl && tagFromUrl[1]){
        tagFromUrl = tagFromUrl[1].replace(/\+/g, ' ')
    }else{
        tagFromUrl = '';
    }

    const initialDiscoverFilterValues = {
        searchTerm: '',
        selectedCategories : [],
        commissionRange: [0, 100],
        selectedCommissionType: ['PERCENT_REVENUE'],
        selectedSort: 'ltv',
        offerType: ['commission'],
        initialPage: 1,
        skip: 0
    }

    let discoverFilterValues = {...initialDiscoverFilterValues}

    if(Object.keys(props.preservDiscoverFilters).length > 0 && !isEqual(discoverFilterValues, props.preservDiscoverFilters)) {
        discoverFilterValues = props.preservDiscoverFilters;
    }

    if(tagFromUrl){
        discoverFilterValues.searchTerm = tagFromUrl
    }

    const [toastVerbiage, setToastVerbiage] = useState(false);
    const [offers, setOffers] = useState(null);

    const [searchTerm, setSearchTerm] = useState(discoverFilterValues.searchTerm);
    const [selectedCategories, setSelectedCategories] = useState(discoverFilterValues.selectedCategories);
    const [commissionRange, setCommissionRange] = useState(discoverFilterValues.commissionRange);
    const [selectedCommissionType, setCommissionType] = useState(discoverFilterValues.selectedCommissionType);
    const [selectedSort, setSelectedSort] = useState(discoverFilterValues.selectedSort);
    const [offerType, setOfferType] = useState(discoverFilterValues.offerType);
    const [initialPage, setInitialPage] = useState(discoverFilterValues.initialPage);
    const [skip, setSkip] = useState(discoverFilterValues.skip);

    const [applyFiltersBtnEnabled, enableApplyFiltersBtn] = useState(false);
    const [resetFiltersBtnEnabled, enableResetFiltersBtn] = useState(false);
    const [searchButton, setSearchButton] = useState(false);

    useEffect(() => {
        props.changeHeaderTitle("Discover Offers");
        if(tagFromUrl){
            setSearchTerm(tagFromUrl)
        }
    }, [])

    useEffect( () => {
        props.discoverFilters({ 
            searchTerm,
            selectedSort,
            selectedCommissionType,
            commissionRange,
            selectedCategories,
            initialPage,
            skip,
            offerType
        })
    }, [searchTerm, selectedSort, selectedCommissionType, commissionRange, selectedCategories, initialPage, skip, offerType] )

    //toggle reset and apply filters buttons based on whether the values match the initial values
    useEffect(() => {
        //if current filters = initial filters, disable the reset and apply filters buttons
        if(
            JSON.stringify(discoverFilterValues.selectedCategories) === JSON.stringify(initialDiscoverFilterValues.selectedCategories) &&
            JSON.stringify(discoverFilterValues.commissionRange) === JSON.stringify(initialDiscoverFilterValues.commissionRange) &&
            JSON.stringify(discoverFilterValues.selectedCommissionType) === JSON.stringify(initialDiscoverFilterValues.selectedCommissionType) &&
            JSON.stringify(discoverFilterValues.offerType) === JSON.stringify(initialDiscoverFilterValues.offerType)
        ){
            enableResetFiltersBtn(false)
            enableApplyFiltersBtn(false)
        }else{
            //if current filters have changed and reset button is disabled, enable the reset button
            if(resetFiltersBtnEnabled === false){
                enableResetFiltersBtn(true)
            }
            //if current filters have changed and apply filters button is disabled, enable the apply filters button
            if(applyFiltersBtnEnabled === false){
                enableApplyFiltersBtn(true)
            }
        }
    }, [discoverFilterValues.selectedCategories, discoverFilterValues.commissionRange, discoverFilterValues.selectedCommissionType, discoverFilterValues.offerType])

    const { applicationsLoaded } = useApplications(props.affiliate.cognito_id, props.getApplications);

    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)}/> : null;

    /* const navigateToOffer = (id) => ()  => {
        props.history.push("/offer/"+ id)
    } */

    return (
        <div className="discover-container__wrapper">
            <Frame>
                <Page separator={true} fullWidth>
                    <div className="discover-offers__layout">
                        <DiscoverOffersSidebar
                            {...props}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                            selectedCommissionType={selectedCommissionType}
                            setCommissionType={setCommissionType}
                            commissionRange={commissionRange}
                            setCommissionRange={setCommissionRange}
                            selectedSort={selectedSort}
                            setSelectedSort={setSelectedSort}
                            applyFiltersBtnEnabled={applyFiltersBtnEnabled}
                            enableApplyFiltersBtn={enableApplyFiltersBtn}
                            resetFiltersBtnEnabled={resetFiltersBtnEnabled}
                            /* searchButton={searchButton} */
                            setSearchButton={setSearchButton}
                            offerType={offerType}
                            setOfferType={setOfferType}
                            /* setSkip={setSkip} */
                        />
                        <div className="discover-offers__main-section">
                            <DiscoverOffers
                                {...props}
                                /* navigateToOffer={navigateToOffer} */
                                applicationsLoaded={applicationsLoaded}
                                offers={offers}
                                setOffers={setOffers}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                selectedCommissionType={selectedCommissionType}
                                setCommissionType={setCommissionType}
                                commissionRange={commissionRange}
                                setCommissionRange={setCommissionRange}
                                selectedSort={selectedSort}
                                setSelectedSort={setSelectedSort}
                                applyFiltersBtnEnabled={applyFiltersBtnEnabled}
                                enableApplyFiltersBtn={enableApplyFiltersBtn}
                                resetFiltersBtnEnabled={resetFiltersBtnEnabled}
                                searchButton={searchButton}
                                setSearchButton={setSearchButton}
                                initialPage={initialPage}
                                setInitialPage={setInitialPage}
                                skip={skip}
                                setSkip={setSkip}
                                offerType={offerType}
                                setOfferType={setOfferType}
                            />
                        </div>
                    </div>
                    {toastMarkup}
                </Page>
            </Frame>
        </div>
    )

}

const mapStateToProps = (state) => {
    console.log("state", state)
    return {
        affiliate: state.affiliate,
        categories: state.app.categories,
        preservDiscoverFilters: state.filtersReducer.discoverFilters,
        offers: state.offers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApplications: (id) => dispatch(getApplications(id)),
        getOffers: (term, categories, options, affiliate_id) => dispatch(searchOffers(term, categories, options, affiliate_id)),
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
        discoverFilters: (discoverFilterValues) => dispatch(discoverFilters(discoverFilterValues))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Discover);

