/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from './sendRequest';
import { UPDATE_AFFILIATE } from './types';

export function updateReferralAffiliate(cognitoId, affiliateData) {

    const query = `
        mutation updateAffiliateReferral($cognito_id: String, $data: AffiliateInput!) {
            updateAffiliateReferral(cognito_id: $cognito_id, data: $data) {
                status
            }
        }
    `

    const variables = {
        cognito_id: cognitoId,
        data: affiliateData
    }

    let payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data) */
}