import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    DropZone,
    Stack,
    Thumbnail,
    Caption,
    Banner
} from '@shopify/polaris';

import { uploadFileToS3 } from '../../store/actions/upload-s3-file';

import formatFileSize from '../../utils/format-file-size';

import closeIcon from '../../assets/x-black.svg';
import './image-drop.css';

/**
 * Handles uploading an image or images through the Polaris DropZone component, type validation, cropping (only square for now), and showing image data preview(s)
 * @param {Boolean} multi Optional, set to true if you want the DropZone to handle multiple images
 * @param {String} buttonText The action text on the button inside the DropZone
 * @param {String} ButtonHint Optional, the text displayed under the button. If omitted, this will default to "or drop an image file to upload" (if multi is true, the default will be pluralized)
 * @param {Function} setImage The image data of the valid image(s) will be sent to this function when the user uploads them
 * @param {String} currentImage Optional, if you need an image to display (such as in a form like offer edit) inside the DropZone set this to the image's url (only works with one image for now)
 * @param {String} label Optional, text to add as a form label if needed
 * @param {String} crop Optional, the type of crop to perform. Currently only "square" is accepted, but more customization may be added in the future
 * @param {Int} resize Optional, the max width or height to resize to
 * @param {Enum} constrain (max|min|width|height) Required if resize is set, which dimension to apply the resize to. Max applies to the larger dimension, min applies to the smaller dimension. If cropping to a square, set to min.
 */
const ImageDrop = ({ multi, buttonText, buttonHint, setImage, currentImage, label, crop, resize, constrain }) => {
    const [uploadedFiles, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const hasError = rejectedFiles.length > 0;

    // crop on upload
    const cropImage = (image) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                const tempImg = new Image();
                tempImg.src = fileReader.result;
                tempImg.onload = function () {
                    if (crop || (resize && resize.width) || (resize && resize.height)) {
                        let canvasWidth;
                        let canvasHeight;

                        let resizeWidth = tempImg.width;
                        let resizeHeight = tempImg.height;

                        const imageWidth = tempImg.width;
                        const imageHeight = tempImg.height;

                        let offsetX = 0;
                        let offsetY = 0;

                        let constrainTo = constrain;

                        if (constrain === 'max') {
                            constrainTo = (imageWidth > imageHeight) ? 'width' : 'height';
                        }
                        if (constrain === 'min') {
                            constrainTo = (imageWidth < imageHeight) ? 'width' : 'height';
                        }

                        if (constrainTo === 'width' && resize !== imageWidth) {
                            resizeWidth = resize;
                            resizeHeight = (imageHeight / imageWidth) * resize;
                        } else if (constrainTo === 'height' && resize !== imageHeight) {
                            resizeWidth = (imageWidth / imageHeight) * resize;
                            resizeHeight = resize;
                        }

                        canvasWidth = resizeWidth;
                        canvasHeight = resizeHeight;

                        if (crop === 'square' && tempImg.width !== tempImg.height) {
                            if (tempImg.width > tempImg.height) {
                                canvasWidth = resizeHeight;
                                offsetX = (imageWidth / 2) - (imageHeight / 2);
                            } else {
                                canvasHeight = resizeWidth;
                                offsetY = (imageHeight / 2) - (imageWidth / 2);
                            }
                        }

                        const canvas = document.createElement('canvas');
                        canvas.width = canvasWidth;
                        canvas.height = canvasHeight;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(this, offsetX, offsetY, imageWidth, imageHeight, 0, 0, resizeWidth, resizeHeight);

                        canvas.toBlob((blob) => {
                            const blobData = blob;
                            blobData.name = image.name;
                            resolve(blobData);
                        }, image.type, 1);
                    } else {
                        resolve(image);
                    }
                };
            };
            fileReader.readAsDataURL(image);
        });
    };

    const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
    let defaultButtonHint = 'or drop ';
    defaultButtonHint += (!multi) ? 'an image file' : 'image files';
    defaultButtonHint += ' to upload';
    const fileUpload = (uploadedFiles.length === 0 && !currentImage) && <DropZone.FileUpload actionTitle={buttonText} actionHint={buttonHint ? buttonHint : defaultButtonHint} />;

    const handleFileUpload = async (files, acceptedFiles, _rejectedFiles) => {
        if (!multi && acceptedFiles[0] && validImageTypes.indexOf(acceptedFiles[0].type) !== -1) {
            // crop if needed
            const acceptedImage = (crop)
                ? await cropImage(acceptedFiles[0])
                : acceptedFiles[0];

            // set image file data internally
            setFiles([acceptedImage]);

            // sends the image file data and a blob url for previews to the parent component to deal with
            setImage({
                imageData: acceptedImage,
                url: window.URL.createObjectURL(acceptedImage)
            });
        } else if (multi) {
            const acceptedImages = acceptedFiles.reduce((imagesAcc, file) => {
                if (validImageTypes.indexOf(file.type) !== -1) {
                    imagesAcc.push(file);
                }
                return imagesAcc;
            }, []);

            if (acceptedImages.length > 0) {
                // add new images to the current images array
                setFiles([...uploadedFiles, ...acceptedImages]); // internal
                setImage([...uploadedFiles, ...acceptedImages]); // parent component
            }
        }
        setRejectedFiles(_rejectedFiles);
    };

    // remove image previews (if multi)
    const removeFile = (e, file) => {
        e.stopPropagation();
        let newFiles = [...uploadedFiles];
        newFiles = newFiles.filter((currentFile) => {
            return currentFile.name !== file.name;
        });
        setFiles(newFiles); // internal
        setImage(newFiles); // parent component
    };

    // show preview
    const renderUploadedFile = () => {
        if (uploadedFiles.length > 0) {
            return (
                <ul className="dropzone-files">
                    {uploadedFiles.map((file) => {
                        let tempImage = '';
                        if (validImageTypes.indexOf(file.type) !== -1) {
                            tempImage = window.URL.createObjectURL(file);
                        }
                        return (
                            <li key={`${file.lastModifiedDate}.${file.name}`}>
                                <Thumbnail size={multi ? 'small' : 'large'} alt={file.name} source={tempImage} />
                                <div>
                                    <span>{file.name}</span>
                                    <Caption>{formatFileSize(file.size)}</Caption>
                                </div>
                                {multi
                                    && (
                                        <button type="button" onClick={(event) => { return removeFile(event, file); }}>
                                            <img src={closeIcon} className="dropzone-delete" alt="Remove File" />
                                        </button>
                                    )}
                            </li>
                        );
                    })}
                    {/* <Stack vertical>
                    <Stack alignment="center">
                        <Thumbnail
                            size="large"
                            alt={uploadedFile.name}
                            source={
                                validImageTypes.indexOf(uploadedFile.type) !== -1
                                    ? window.URL.createObjectURL(uploadedFile)
                                    : ''
                            }
                        />
                        <div>
                            {uploadedFile.name} <Caption>{formatFileSize(uploadedFile.size)}</Caption>
                        </div>
                    </Stack>
                </Stack> */}
                </ul>
            );
        }

        if (currentImage) {
            return (
                <Stack vertical>
                    <Stack alignment="center">
                        <Thumbnail
                            size="large"
                            source={currentImage}
                        />
                    </Stack>
                </Stack>
            );
        }

        return null;
    };

    // show filetype error
    const fileErrorMessage = hasError && (
        <Banner title="The following images couldn't be uploaded:" status="critical">
            <ul>
                {rejectedFiles.map((file) => {
                    return (
                        <li key={`${file.lastModifiedDate}.${file.name}`}>
                            {`"${file.name}" is not supported. File type must be .jpg, .png, or .gif`}
                        </li>
                    );
                })}
            </ul>
        </Banner>
    );

    return (
        <>
            {fileErrorMessage}
            <DropZone
                onDrop={handleFileUpload}
                dropOnPage
                type="image"
                accept="image/png,image/jpg,image/jpeg,image/gif"
                label={label}
            >
                {renderUploadedFile()}
                {fileUpload}
            </DropZone>
        </>
    );
};

ImageDrop.propTypes = {
    multi: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    buttonHint: PropTypes.string,
    setImage: PropTypes.func.isRequired,
    currentImage: PropTypes.string,
    label: PropTypes.string,
    crop: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        // these might change once cropping is actually implelemented:
        x: PropTypes.number,
        y: PropTypes.number
    })])
};

ImageDrop.defaultProps = {
    multi: false,
    // default buttonHint will get pluralized if multi is true
    buttonHint: 'or drop an image file to upload',
    currentImage: null,
    label: null,
    crop: null
};

/**
 * Saves an image uploaded from the ImageDrop component to s3
 * @param {Object} image The image file data. If imageData is a child of image, image will be automatically set to that
 * @param {String} ambassador_id The id of the current ambassador
 * @param {String} path The s3 filepath fragment after ambassador/${ambassador_id}/. Do not include leading or trailing slashes
 * @param {Function} setUploading Optional. If you need to handle a loading state between start to finish of this promise, it will send true to the function before the promise is run and send false when the promise has resolved
 * @param {Boolean} isSmartlinks Optional. Whether to use the smartlinks path instead of the affiliates one
 */
export const saveImage = (image, ambassador_id, path, setUploading, isSmartlinks = false) => {
    const imageFileInfo = (image.imageData) ? image.imageData : image;
    if (setUploading) {
        setUploading(true);
    }

    const imageFields = [
        'name',
        'size',
        'type',
        'url'
    ];

    return new Promise((resolve) => {
        uploadFileToS3(imageFileInfo, `${isSmartlinks ? 'smartlink' : 'affiliates'}/${ambassador_id}/${path}`)
            .then((result) => {
                const url = new URL(result);
                imageFileInfo.url = url.origin + url.pathname;
                // save only the necessary fields to the image object
                const imageData = imageFields.reduce((acc, key) => {
                    if (imageFields.includes(key)) {
                        acc[key] = imageFileInfo[key];
                    }
                    return acc;
                }, {});
                if (setUploading) {
                    setUploading(false);
                }
                resolve(imageData);
            });
    });
};

export default ImageDrop;
