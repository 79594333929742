import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Banner } from '@shopify/polaris';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { LlamaButton } from 'llama-library/components';
import llamaHead from '../assets/llama-head-01.png';
import checkIcon from '../assets/check-pink.svg';

import { changeHeaderTitle } from '../store/actions/header';
import TikTokUpSell from '../components/TikTok-Upsell/tiktok-upsell';
import HypeAuditorTikTok from '../components/HypeAuditor/hype-auditor-tiktok';
import TikTokLocked from '../components/TikTokLocked/tiktok-locked';
import HypeLoading from '../components/HypeLoading/hype-loading';
import {
    getTikTokData,
    initiateTikTokTrial,
    upgradeTikTokUser,
    cancelTikTokUser,
    verifyTikTokUser
} from '../store/actions/tiktok';
import {
    HAPaymentModal,
    HASettingsModal,
    HACancelModal,
    VerifyAccountModal
} from '../components/HypeModal/hype-modal';

import './styles/hype-auditor.css';

// Social Platform
const socialPlatform = 'tiktok';
const pricing = {
    yearly: {
        perMonth: '$0.60',
        billed: '$7.20 yearly'
    },
    monthly: {
        perMonth: '$0.99',
        billed: '$0.99 monthly'
    }
};

export const TikTokContainer = (props) => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [currentModal, setCurrentModal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerifyUserClicked, setIsVerifyUserClicked] = useState(false);
    const [isAddUserClicked, setIsAddUserClicked] = useState(false);
    const [nextUserAccount, setNextUserAccount] = useState(false);
    const [banner, setBanner] = useState(null);

    // Update TikToK data on ComponentDidMount
    // Doesn't get called again unless the value cognito_id changes
    useEffect(() => {
        if (!props.affiliate.cognito_id) {
            return;
        }

        props.getTikTokData(props.affiliate.cognito_id)
            .then(() => {
                if (Array.isArray(props.affiliate.tikTokData)
                    && props.affiliate.tikTokData.length > 0) {
                    setSelectedAccount(props.affiliate.tikTokData[0].username);
                    setIsLoading(false);
                } else if (props.affiliate.tikTokData.length === 0) {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [props.affiliate.cognito_id]);

    // Update header title.
    useEffect(() => {
        const title = props.title ? props.title : 'TikTokAnalytics';
        props.changeHeaderTitle(title);
    }, []);

    // Loading State
    if (!props.affiliate || !props.affiliate.cognito_id
        || !props.affiliate.tikTokData || isLoading) {
        return (
            <div data-test="component-hypeLoading">
                <HypeLoading />
            </div>
        );
    }

    const handleAccountChange = (value) => {
        setSelectedAccount(value);
    };

    const handleVerifyAccount = () => {
        setIsVerifyUserClicked(true);
        toggleModal('verify');
    };

    const handleAddAccount = () => {
        setIsAddUserClicked(true);
    };

    const toggleModal = (value, username) => {

        if (_.isPlainObject(value)) {
            setBanner(value.message);
            setCurrentModal(null);
        }

        if (!value) {
            setCurrentModal(null);
        }

        if (username) {
            setSelectedAccount(username);
        }

        // Handle state if modals are manually closed
        if (isAddUserClicked && currentModal === 'upgrade') {
            setIsAddUserClicked(false);
        }
        if (isVerifyUserClicked && currentModal === 'verify') {
            setIsVerifyUserClicked(false);
        }

        setCurrentModal(value);
    };

    const now = new Date();

    // Determine if user is in the signup flow
    const isOnboarding = props.location.pathname.includes('signUp');

    // ACTIVE/TRIAL
    // Get ambassador's tiktok accounts
    const tikTokAccounts = Array.isArray(props.affiliate.tikTokData)
        ? props.affiliate.tikTokData.filter((item) => {
            return item.account_type === 'ACTIVE' || item.account_type === 'TRIAL';
        })
        : [];

    // Determine there is an array of at least one tiktok account for the ambassador
    const hasActiveTikTok = tikTokAccounts && Array.isArray(tikTokAccounts)
        && tikTokAccounts.length > 0;

    // INACTIVE
    // Get ambassador's tiktok accounts
    const tikTokAccountsInactive = Array.isArray(props.affiliate.tikTokData)
        ? props.affiliate.tikTokData.filter((item) => {
            return item.account_type === 'INACTIVE';
        })
        : [];

    // Determine there is an array of at least one inactive tiktok account for the ambassador
    const hasInactiveTikTok = tikTokAccountsInactive && Array.isArray(tikTokAccountsInactive)
        && tikTokAccountsInactive.length > 0;

    // Creating new affiliate object with the existing props
    // Setting tikTokData prop to tikTokAccounts array from previous step.
    const updatedAffiliate = { ...props.affiliate, tikTokData: tikTokAccounts };

    // Searching tikTokAccounts array for the username that matches selectedAccount value
    const activeAccount = tikTokAccounts && tikTokAccounts.find((item) => item.username === selectedAccount);

    // Determine if the activeAccount account_type is 'TRIAL'
    const isTrial = activeAccount && activeAccount.account_type === 'TRIAL';

    // Get trial_expiration from activeAccount and format the date
    const expyDate = activeAccount && activeAccount.trial_expiration;
    const formattedExpy = new Date(expyDate);

    // Determine if activeAccount is expired
    const isExpired = activeAccount && activeAccount.account_type === 'TRIAL' && (formattedExpy <= now);

    // On payment modal close
    const handleModalClose = res => {
        if (res && res.value && res.value.data && res.value.data.createTikTokUser && res.value.data.createTikTokUser.error
            && res.value.data.createTikTokUser.error.message.length > 0) {
            return toggleModal({ error: true, message: res.value.data.createTikTokUser.error.message });
        }
        return toggleModal(null);
    }

    const renderTrialBanner = () => {

        // TRIAL BANNER
        if (!isLoading && isTrial && expyDate && !isExpired) {
            return (
                <div className="HypeAuditor__TrialBanner">
                    <Banner
                        title="You are currently on a trial membership."
                    >
                        <p className="HypeAuditor__TrialParagraph">
                            Always show prospective clients that your audience is organic and engaged. Get more collaborations with top brands.
                        </p>
                        <LlamaButton onClick={() => toggleModal('upgrade')}>Upgrade Now</LlamaButton>
                    </Banner>
                </div>
            );
        }

        // EXPIRED TRIAL BANNER
        if (!isLoading && isTrial && expyDate && isExpired) {
            return (
                <div className="HypeAuditor__TrialBanner">
                    <Banner
                        title="Your trial has expired."
                    >
                        <p className="HypeAuditor__TrialParagraph">
                            You must upgrade your account to access your HypeAuditor profile.
                        </p>
                        <LlamaButton onClick={() => toggleModal('upgrade')}>Upgrade Now</LlamaButton>
                    </Banner>
                </div>
            );
        }

        return null;
    };

    const renderHypeAuditorTrialSuccess = () => {
        return (
            <div className="HypeUpsell__Success">
                <img src={llamaHead} className="HypeUpsell__SuccessLlama" />
                <div className="HypeUpsell__SuccessRow">
                    <p className="HypeUpsell__SuccessMessage">Success! Your free trial has been created</p>
                    <img src={checkIcon} className="HypeUpsell__SuccessCheck" />
                </div>
            </div>
        );
    };

    const renderHypeAuditor = () => {
        return (
            <div data-test="component-hypeAccount">
                <div className="tiktok-account__add-account">
                    <p className="tiktok-account__add-text">
                        Analyze your competition
                    </p>
                    <LlamaButton loading={isAddUserClicked} onClick={handleAddAccount}>Get Another Report</LlamaButton>
                </div>
                <HypeAuditorTikTok
                    {...props}
                    affiliate={updatedAffiliate}
                    isVerifyUserClicked={isVerifyUserClicked}
                    isAddUserClicked={isAddUserClicked}
                    handleVerifyAccount={handleVerifyAccount}
                    handleAccountChange={handleAccountChange}
                    viewSettings={() => toggleModal('settings')}
                />
            </div>
        );
    };

    return (
        <div data-test="component-hypeContainer">
            {/* Error Banner */}
            {
                banner && banner.length > 0 && (
                    <Banner title="Transaction Terminated" onDismiss={() => { setBanner(null) }} status="critical">
                        <p>{banner}</p>
                    </Banner>
                )
            }
            {/* RENDER BANNER */}
            {renderTrialBanner()}

            {/* EXPIRED TRIAL STATE */}
            {!isLoading && hasActiveTikTok && isExpired && isTrial && (
                <div data-test="component-hypeExpired">
                    <TikTokLocked
                        {...props}
                        hasActiveTikTok={hasActiveTikTok}
                        isExpired={isExpired}
                    />
                </div>
            )}

            {/* TRIAL STATE */}
            {isOnboarding && hasActiveTikTok && renderHypeAuditorTrialSuccess()}

            {/* SHOW HYPE AUDITOR DATA */}
            {!isLoading && hasActiveTikTok && !isExpired && !isAddUserClicked && renderHypeAuditor()}

            {/* UPSELL STATE */}
            {
                !isLoading
                && (isAddUserClicked || (!hasActiveTikTok && !isTrial))
                && (
                    <div data-test="component-hypeUpsell">
                        <TikTokUpSell
                            {...props}
                            addUser={isAddUserClicked}
                            cognito_id={props.affiliate && props.affiliate.cognito_id}
                            hasInactiveTikTok={hasInactiveTikTok}
                            setIsAddUserClicked={setIsAddUserClicked}
                            setSelectedAccount={setSelectedAccount}
                            setNextUserAccount={setNextUserAccount}
                            initiateTikTokTrial={props.initiateTikTokTrial}
                            toggleUpgrade={(username) => toggleModal('upgrade', username)}
                        />
                    </div>
                )
            }

            {/* UPGRADE STATE */}
            {currentModal === 'upgrade' && (
                <HAPaymentModal
                    socialPlatform={socialPlatform}
                    close={handleModalClose}
                    username={(isAddUserClicked) ? nextUserAccount : selectedAccount}
                    cognito_id={props.affiliate && props.affiliate.cognito_id}
                    paymentHandler={(isAddUserClicked) ? props.initiateTikTokTrial : props.upgradeTikTokUser}
                />
            )}

            {/* SETTINGS STATE */}
            {currentModal === 'settings' && (
                <HASettingsModal
                    socialPlatform={socialPlatform}
                    close={() => toggleModal(null)}
                    data={props.affiliate.tikTokData}
                    toggleUpgrade={(username) => toggleModal('upgrade', username)}
                    toggleCancel={(username) => toggleModal('cancel', username)}
                />
            )}

            {/* CANCEL STATE */}
            {currentModal === 'cancel' && (
                <HACancelModal
                    socialPlatform={socialPlatform}
                    close={() => toggleModal(null)}
                    username={selectedAccount}
                    cognito_id={props.affiliate && props.affiliate.cognito_id}
                    cancelUserAccount={props.cancelTikTokUser}
                />
            )}

            {/* VERIFY ACCOUNT STATE */}
            {currentModal === 'verify' && (
                <VerifyAccountModal
                    socialPlatform={socialPlatform}
                    close={() => toggleModal(null)}
                    username={selectedAccount}
                    cognito_id={props.affiliate && props.affiliate.cognito_id}
                    hashCode={props.affiliate && props.affiliate.tikTokData[0]
                        && props.affiliate.tikTokData[0].account_hash}
                    verifyUserAccount={props.verifyTikTokUser}
                    setIsVerifyUserClicked={setIsVerifyUserClicked}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { affiliate: state.affiliate };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
        getTikTokData: (cognito_id) => dispatch(getTikTokData(cognito_id)),
        initiateTikTokTrial: (username, cognito_id, stripe_token, interval) => dispatch(initiateTikTokTrial(username, cognito_id, stripe_token, interval)),
        upgradeTikTokUser: (username, cognito_id, stripe_token, interval) => dispatch(upgradeTikTokUser(username, cognito_id, stripe_token, interval)),
        cancelTikTokUser: (username, cognito_id, reason) => dispatch(cancelTikTokUser(username, cognito_id, reason)),
        verifyTikTokUser: (username, cognito_id) => dispatch(verifyTikTokUser(username, cognito_id))
    };
};

TikTokContainer.propTypes = {
    affiliate: PropTypes.object.isRequired,
    changeHeaderTitle: PropTypes.func.isRequired,
    initiateTikTokTrial: PropTypes.func.isRequired,
    getTikTokData: PropTypes.func.isRequired,
    upgradeTikTokUser: PropTypes.func.isRequired,
    verifyTikTokUser: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(TikTokContainer);
