import React, {useState, useEffect} from 'react';

import { connect } from 'react-redux';
import {Banner, Spinner, Toast, Frame} from '@shopify/polaris';

import {EmptyState} from 'llama-library/components';
import {getBillingInfo, updateCard} from '../../store/actions/smartlinks/action_getSmartLinksBilling';
import LlamaCreditCardDetails from '../llama/credit-card-details';
import LlamaBillingHistory from '../llama/billing-history';

import './smart-link-billing.css';

const SmartLinkBilling = (props) => {
    const [loading, setLoading] = useState(true);
    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [billingInfo, setBillingInfo] = useState({
        card_data: {},
        invoiceGroups: []
    });

    useEffect(() => {
        props.getBillingInfo(props.affiliate.cognito_id)
            .then(result => {
                setLoading(false)
                if(result.value && result.value.data && result.value.data.getBillingInfo){
                    setBillingInfo(result.value.data.getBillingInfo)
                }
            })
    }, [])

    const updateCard = (tokenData) => {
        const last4 = (tokenData.card && tokenData.card.last4) ? tokenData.card.last4 : '';
        return props.updateCard(props.affiliate.cognito_id, tokenData.id, last4)
            .then((result) => {
                if(result.value.data.updateCard){
                    setToastVerbiage('Payment method updated successfully.');
                    return {success: true, cardData: result.value.data.updateCard}
                }else if(result.value.errors && result.value.errors[0] && result.value.errors[0].message){
                    return {succes: false, error: result.value.errors[0].message};
                }
            })
    }

    //checking for linksLoading too in case the plan gets set after the billing info finishes loading
    if(loading || props.linksLoading){
        return <Spinner />
    }

    if(props.plan === 'free' && billingInfo.invoiceGroups.length === 0 && !billingInfo.card_data.last4){
        return <EmptyState 
            message="You don't have any billing history" 
            ctaText="Upgrade to PRO"
            ctaAction={() => props.history.push("/smartlinks/upgrade/pro")}
        />
    }

    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => {setToastVerbiage(null)}} /> : ''

    return (
        <>
            <p className="heading">Billing</p>
            <p className="info">Update your credit card information or view your billing history.</p>

            <section className="smartlinks--setting smartlinks--card">
                <h3>Current Payment Method</h3>
                {(props.plan === 'free' || !billingInfo.card_data) 
                    ?
                    <p className="cc-info-wrapper">No credit card on file</p>
                    :
                    <LlamaCreditCardDetails cardData={billingInfo.card_data} updateCard={updateCard} />
                }
            </section>

            <section className="smartlinks--setting smartlinks--billing-history">
                <h3>Billing History</h3>
                <LlamaBillingHistory invoiceGroups={billingInfo.invoiceGroups} />
            </section>
            <Frame>{toastMarkup}</Frame>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBillingInfo: (cognito_id) => dispatch(getBillingInfo(cognito_id)),
        updateCard: (cognito_id, tokenData, last4) => dispatch(updateCard(cognito_id, tokenData, last4))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartLinkBilling);