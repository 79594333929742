import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, TextField, TextStyle, Select, Icon, Spinner } from '@shopify/polaris';
import { ClipboardMinor } from '@shopify/polaris-icons';
import { LlamaButton } from 'llama-library/components';

import copyToClipboard from '../../utils/copy-to-clipboard';
import { HAS_OFFERS_URL } from '../../config';

import { getLinkParams, addLinkParam, editLinkParam, deleteLinkParam } from '../../store/actions/action_offerLinkParams';

import './offer-link-parameters.css';

const sources = [
    { label: 'Select a source', value: '' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'TikTok', value: 'TikTok' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'YouTube', value: 'YouTube' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Blog', value: 'Blog' },
    { label: 'Podcast', value: 'Podcast' },
    { label: 'Custom...', value: 'custom' }
];

const descriptors = [
    { label: 'None', value: '' },
    { label: 'Primary', value: 'Primary' },
    { label: 'Secondary', value: 'Secondary' },
    { label: 'Custom...', value: 'custom' }
];

const OfferLinkParamsModal = ({ modalOpen, onClose, currentLink, offerURL, setToastVerbiage, offer_id, cognito_id }) => {
    const isCancelled = useRef(false);
    const rawOfferURL = offerURL.replace(/[\w\W]+(?=\?)\?{1}/i, HAS_OFFERS_URL);

    const [source, setSource] = useState('');
    const [descriptor, setDescriptor] = useState('');
    const [customSource, setCustomSource] = useState('');
    const [customDescriptor, setCustomDescriptor] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [pixelOfferURL, setPixelOfferURL] = useState(`${rawOfferURL}&aff_sub5=`);
    const [showOfferURL, setShowOfferURL] = useState(false);

    const [linkParams, setLinkParams] = useState([]);
    const [expandedLinkId, setExpandedLinkId] = useState('');
    const [expandedLinkName, setExpandedLinkName] = useState('');
    const [expandedLink, setExpandedLink] = useState('');

    useEffect(() => {
        if (linkParams.length === 0) {
            if (!isCancelled.current) {
                setLoading(true);
            }
            getLinkParams(offer_id, cognito_id)
                .then((result) => {
                    if (!isCancelled.current) {
                        if (result.data.getOfferLinkParams) {
                            setLinkParams(result.data.getOfferLinkParams);
                        }
                        setLoading(false);
                    }
                });
        }
        return () => {
            isCancelled.current = true;
        };
    }, []);

    const buildLink = (linkSource, linkDescriptor) => {
        let newLink = currentLink;
        if (currentLink.indexOf('?') === -1) {
            newLink += '?';
        } else {
            newLink += '&';
        }
        newLink += `source=${linkSource.toLowerCase()}`;
        if (linkDescriptor) {
            newLink += `&aff_sub=${linkDescriptor.toLowerCase()}`;
        }

        return newLink;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (showOfferURL) {
            copyToClipboard(pixelOfferURL, setToastVerbiage, 'Link');
            setShowOfferURL(false);
            setShowForm(false);

            return;
        }

        const currentSource = (source !== 'custom') ? source : customSource.replace(/\s/g, '-');
        const currentDescriptor = (descriptor !== 'custom') ? descriptor : customDescriptor.replace(/\s/g, '-');
        const newLink = buildLink(currentSource, currentDescriptor);

        setSaving(true);

        const paramsExist = linkParams.find((link) => {
            return link.source === currentSource && link.descriptor === currentDescriptor;
        });

        if (editMode) {
            // if source and descriptor did not change but the user pressed save, don't waste a request
            if (!paramsExist) {
                editLinkParam(offer_id, cognito_id, expandedLinkId, currentSource, currentDescriptor)
                    .then((result) => {
                        if (result.data && result.data.editOfferLinkParam) {
                            copyToClipboard(newLink, setToastVerbiage, 'Link');
                            setShowForm(false);
                            setLinkParams(result.data.editOfferLinkParam);
                            setExpandedLink(newLink);
                        } else {
                            setToastVerbiage('Oops, something went wrong. Unable to save link source');
                        }
                        setSaving(false);
                    });
            } else {
                copyToClipboard(newLink, setToastVerbiage, 'Link');
                setSaving(false);
                setShowForm(false);
            }
        } else {
            // if source and descriptor did not change but the user pressed save, don't add an extra
            // eslint-disable-next-line no-lonely-if
            if (!paramsExist) {
                addLinkParam(offer_id, cognito_id, currentSource, currentDescriptor)
                    .then((result) => {
                        if (result.data && result.data.addOfferLinkParam) {
                            copyToClipboard(newLink, setToastVerbiage, 'Link');
                            setShowForm(false);
                            setLinkParams([
                                ...linkParams,
                                result.data.addOfferLinkParam
                            ]);
                        } else {
                            setToastVerbiage('Oops, something went wrong. Unable to save link source');
                        }
                        setSaving(false);
                    });
            } else {
                setToastVerbiage('Hmm, looks like you’ve already added that link source');
                setSaving(false);
            }
        }
    };

    const deleteLink = () => {
        setDeleting(true);
        deleteLinkParam(offer_id, cognito_id, expandedLinkId)
            .then((result) => {
                if (result.data && result.data.deleteOfferLinkParam !== undefined) {
                    setLinkParams(result.data.deleteOfferLinkParam);
                    setEditMode(false);
                    setShowForm(false);
                } else {
                    setToastVerbiage('Oops, something went wrong. Unable to delete link source');
                }
                setDeleting(false);
            });
    };

    const handleSourceChange = (value, isCustom) => {
        if (!isCustom) {
            setSource(value);
        } else {
            const safeValue = value.replace(/[^\w\d\s*-_.$]+/g, '');
            setCustomSource(safeValue);
        }
    };

    const handleOfferURLChange = (value) => {
        const offerURLValue = (value === '') ? `${rawOfferURL}&aff_sub5=` : value;
        setPixelOfferURL(offerURLValue);
    };

    const handleDescriptorChange = (value, isCustom) => {
        if (!isCustom) {
            setDescriptor(value);
        } else {
            const safeValue = value.replace(/[^\w\d\s*-_.$]+/g, '');
            setCustomDescriptor(safeValue);
        }
    };

    const toggleExpandedLink = (param) => {
        if (param.id === expandedLinkId) {
            setExpandedLinkId('');
            setExpandedLinkName('');
            setEditMode(false);
            setShowForm(false);
        } else {
            setExpandedLinkId(param.id);
            let paramName = param.source;
            if (param.descriptor) {
                paramName += `: ${param.descriptor}`;
            }
            setExpandedLinkName(paramName);
            const newLink = buildLink(param.source, param.descriptor);
            setExpandedLink(newLink);
        }
    };

    const switchToEditMode = () => {
        setEditMode(true);
        setShowForm(true);
        const expandedLinkParam = linkParams.find((link) => {
            return link.id === expandedLinkId;
        });

        const expandedLinkSourceOption = sources.find((option) => {
            return option.value === expandedLinkParam.source;
        });
        if (expandedLinkSourceOption) {
            setSource(expandedLinkSourceOption.value);
        } else {
            setSource('custom');
            setCustomSource(expandedLinkParam.source);
        }

        const expandedLinkDescriptorOption = descriptors.find((option) => {
            return option.value === expandedLinkParam.descriptor;
        });
        if (expandedLinkDescriptorOption) {
            setDescriptor(expandedLinkDescriptorOption.value);
        } else {
            setDescriptor('custom');
            setCustomDescriptor(expandedLinkParam.descriptor);
        }
    };

    const showAddForm = () => {
        setShowForm(true);
        setExpandedLinkId('');
        setExpandedLinkName('');
        setEditMode(false);
    };

    const showAddRawURLForm = () => {
        handleOfferURLChange(pixelOfferURL);
        setShowOfferURL(true);
        setShowForm(true);
        setEditMode(false);
    };

    return (
        <Modal
            open={modalOpen}
            title="Referral Link Options"
            onClose={onClose}
        >
            <Modal.Section>
                <p>Posting your link in multiple places? Gain more insight into how your links perform on different platforms by adding sources to your link.</p>
            </Modal.Section>
            <Modal.Section>
                <div className="offer-link-parameters">
                    {loading && <Spinner />}
                    {linkParams.length > 0 && !showOfferURL
                        && (
                            <>
                                <h2>Current Link Sources</h2>
                                <ul className="offer-link-parameters-list">
                                    {linkParams.map((param) => {
                                        return (
                                            <li key={param.id} data-expanded={param.id === expandedLinkId}>
                                                <button className="link-name" onClick={() => { return toggleExpandedLink(param); }}><b>{param.source}</b>{param.descriptor && `: ${param.descriptor}`}</button>
                                                {param.id === expandedLinkId
                                                    && (
                                                        <>
                                                            <span className="link">{expandedLink}</span>
                                                            <span className="actions">
                                                                <LlamaButton disabled={deleting} onClick={() => { return copyToClipboard(expandedLink, setToastVerbiage, 'Link'); }}><Icon source={ClipboardMinor} color="white" /> Copy</LlamaButton>
                                                                <button type="button" disabled={deleting} className="edit" onClick={switchToEditMode}>Edit</button>
                                                                <button type="button" disabled={deleting} className="remove" onClick={deleteLink}>{deleting ? <Spinner size="small" color="inkLightest" /> : 'Delete'}</button>
                                                            </span>
                                                        </>
                                                    )
                                                }
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        )
                    }

                    {!showForm && !loading
                        && (
                            <div className="add-link-options">
                                <LlamaButton onClick={showAddForm}>Add New Link Source</LlamaButton>
                                <LlamaButton onClick={showAddRawURLForm}>Add Manual Source</LlamaButton>
                            </div>
                        )
                    }

                    {showForm && !showOfferURL
                        && (
                            <form onSubmit={handleSubmit}>
                                <h2>
                                    {editMode
                                        ? `Edit ${expandedLinkName}`
                                        : 'Add New Link Source'
                                    }
                                </h2>
                                <ul>
                                    <li>
                                        {source !== 'custom'
                                            ? (
                                                <Select
                                                    label="Source"
                                                    options={sources}
                                                    value={source}
                                                    onChange={(value) => { return handleSourceChange(value, false); }}
                                                    required
                                                    helpText="Source refers to where you’ll be posting your link."
                                                />
                                            )
                                            : (
                                                <>
                                                    <TextField
                                                        autoFocus
                                                        label="Source"
                                                        value={customSource}
                                                        onChange={(value) => { return handleSourceChange(value, true); }}
                                                        required
                                                        helpText="Source refers to where you’ll be posting your link."
                                                    />
                                                    <button type="button" className="cancel" onClick={() => { return handleSourceChange('', false); }}>Cancel</button>
                                                </>
                                            )
                                        }
                                    </li>
                                    <li>
                                        {descriptor !== 'custom'
                                            ? (
                                                <Select
                                                    label="Descriptor"
                                                    options={descriptors}
                                                    value={descriptor}
                                                    onChange={(value) => { return handleDescriptorChange(value, false); }}
                                                    helpText="Add a descriptor if you want to track a specific source."
                                                />
                                            )
                                            : (
                                                <>
                                                    <TextField
                                                        autoFocus
                                                        label="Descriptor"
                                                        value={customDescriptor}
                                                        onChange={(value) => { return handleDescriptorChange(value, true); }}
                                                        helpText="Add a descriptor if you want to track a specific source."
                                                    />
                                                    <button type="button" className="cancel" onClick={() => { return handleDescriptorChange('', false); }}>Cancel</button>
                                                </>
                                            )
                                        }
                                    </li>
                                </ul>
                                <LlamaButton disabled={saving} loading={saving}>{editMode ? 'Edit' : 'Save'} &amp; Copy Link</LlamaButton>
                            </form>
                        )
                    }

                    {showForm && showOfferURL
                        && (
                            <form onSubmit={handleSubmit}>
                                <h2>Build Custom URL</h2>
                                <div className="custom-link-preview">
                                    <p>Add the custom parameters as key value pairs to the aff_sub5 field separate by &apos;&amp;&apos;.</p>
                                    <p>
                                        <TextStyle variation="code">{`${rawOfferURL}&aff_sub5=`}
                                            <TextStyle variation="positive">key1=value1&amp;key2=value2</TextStyle>
                                        </TextStyle>
                                    </p>
                                </div>
                                <div className="custom-link-preview">
                                    <TextField
                                        autoFocus
                                        label="URL"
                                        value={pixelOfferURL}
                                        onChange={(value) => { return handleOfferURLChange(value); }}
                                        required
                                        helpText="Add the pixel custom URL parameters."
                                        labelAction={{
                                            content: <TextStyle variation="negative">Cancel</TextStyle>,
                                            onAction: () => { return handleOfferURLChange('') }
                                        }}
                                    />
                                </div>
                                <LlamaButton disabled={saving} loading={saving}>Copy Link</LlamaButton>
                            </form>
                        )
                    }

                </div>
            </Modal.Section>
        </Modal>
    );
};

OfferLinkParamsModal.propTypes = {
    offer_id: PropTypes.string.isRequired,
    cognito_id: PropTypes.string.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    currentLink: PropTypes.string.isRequired,
    offerURL: PropTypes.string.isRequired,
    setToastVerbiage: PropTypes.func.isRequired
};

export default OfferLinkParamsModal;
