import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextField, Select, Checkbox, Spinner } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { updateSmartLink, updateSmartLinkPreview } from '../../../store/actions/action_updateSmartlink';
import { checkSmartLinkCustomId } from '../../../store/actions/action_checkSmartLinkCustomId';

import SmartLinkProfilePhoto from './smart-link-profile-photo';
import SmartlinksGeneralSkeleton from './smart-link-general-skeleton';

import './smart-link-general.css';

const SmartLinkGeneral = (props) => {
    const { smartlinks: smartlinkProps, affiliate, dispatchUpdateSmartLink, dispatchUpdateSmartLinkPreview, setToastVerbiage, attemptUpgradeModal } = props;

    console.log('PROPS', props);

    const [fieldLoading, setFieldLoading] = useState('');
    const [saveButton, setSaveButton] = useState('');

    const [headline, setHeadline] = useState('');
    const [bio, setBio] = useState('');
    const [customId, setCustomId] = useState('');
    const [nsfwEnabled, setNsfwEnabled] = useState(false);
    const [nsfwType, setNsfwType] = useState(null);

    const [call, setCall] = useState(null);
    const [customIdWait, setCustomIdWait] = useState('');
    const [customIdAvailability, setCustomIdAvailability] = useState(null);

    useEffect(() => {
        setHeadline(smartlinkProps.headline);
        setBio(smartlinkProps.bio);
        setCustomId(smartlinkProps.current_handle);
        setNsfwType(smartlinkProps.nsfwType);
        if (smartlinkProps.nsfwType) {
            setNsfwEnabled(true);
        }
    }, [smartlinkProps.loaded]);

    useEffect(() => {
        if (!saveButton) {
            dispatchUpdateSmartLinkPreview(null);
        }
    }, [saveButton]);

    const saveChanges = (field, payload) => {
        setFieldLoading(field);
        dispatchUpdateSmartLink(affiliate.cognito_id, payload)
            .then(() => {
                setFieldLoading(null);
                setSaveButton('');
                setToastVerbiage('Saved');
            })
            .catch((err) => { console.error(err); });
    };

    /* headline */
    const handleHeadlineChange = (value) => {
        if (saveButton !== 'headline') {
            setSaveButton('headline');
        }

        // don't allow angle brackets or braces to prevent html/jsx injection
        const cleanValue = value.replace(/[<>{}]+/g, '');
        dispatchUpdateSmartLinkPreview({ headline: cleanValue });
        setHeadline(cleanValue);
    };

    const saveHeadline = (e) => {
        e.preventDefault();

        if (smartlinkProps.headline !== headline) {
            return saveChanges('headline', { headline });
        }

        setSaveButton('');
        return null;
    };

    /* bio */
    const handleBioChange = (value) => {
        if (saveButton !== 'bio') {
            setSaveButton('bio');
        }

        // don't allow angle brackets or braces to prevent html/jsx injection
        const cleanValue = value.replace(/[<>{}]+/g, '');
        dispatchUpdateSmartLinkPreview({ bio: cleanValue });
        setBio(cleanValue);
    };

    const saveBio = (e) => {
        e.preventDefault();

        if (smartlinkProps.bio !== bio) {
            return saveChanges('bio', { bio });
        }

        setSaveButton('');
        return null;
    };

    /* custom id */
    // https://stackoverflow.com/a/56474891
    const makeCancelable = (promise) => {
        let rejectFn;

        const wrappedPromise = new Promise((resolve, reject) => {
            rejectFn = reject;

            Promise.resolve(promise)
                .then(resolve)
                .catch(reject);
        });

        wrappedPromise.cancel = () => {
            rejectFn({ canceled: true });
        };

        return wrappedPromise;
    };

    const handleCustomIdChange = (value) => {
        if (smartlinkProps.settings.subscription.plan === 'plan_pro') {
            if (saveButton !== 'customId') {
                setSaveButton('customId');
            }
            const cleanValue = value.replace(/[^\w-]+/g, '');
            setCustomId(cleanValue);

            // if the user presses too slowly and a database promise was attempted, cancel it
            if (call != null) {
                call.cancel();
            }
            if (customIdWait !== '') {
                clearTimeout(customIdWait);
            }

            // give the user half a second to finish typing before checking the database for availability
            setCustomIdWait(setTimeout(() => {
                setCustomIdWait('');
                setCustomIdAvailability('loading');

                setCall(makeCancelable(
                    checkSmartLinkCustomId(affiliate.cognito_id, value).payload
                        .then((result) => {
                            console.log('CHECKER', result);
                            setCustomIdAvailability(result.data.checkCustomId.is_available);
                            clearTimeout(customIdWait);
                        })
                ));
            }, 500));
        }
    };

    const saveCustomId = (e) => {
        e.preventDefault();
        if (smartlinkProps.settings.subscription.plan === 'plan_pro' && customIdAvailability !== false && smartlinkProps.current_handle !== customId) {
            saveChanges('customId', { custom_id: customId });
        }
        return null;
    };

    /* nsfw */
    const handleNsfwEnabledChange = (isChecked) => {
        // if the user unchecked it, hide the type field, remove from preview, and set type to null
        if (!isChecked) {
            setNsfwEnabled(false);
            dispatchUpdateSmartLinkPreview(null);
            setNsfwType(null);
            // if a type was previously saved, save as empty string
            if (smartlinkProps.nsfwType) {
                return saveChanges('nsfwType', { nsfwType: '' });
            }
            setSaveButton('');
        // if the user checked it, show the type field, set type to banner, show save button, and update the preview
        } else {
            setNsfwEnabled(true);
            setNsfwType('banner');
            dispatchUpdateSmartLinkPreview({ nsfwType: 'banner' });
            setSaveButton('nsfwType');
        }

        return null;
    };

    const handleNsfwTypeChange = (value) => {
        if (saveButton !== 'nsfwType') {
            setSaveButton('nsfwType');
        }
        setNsfwType(value);
        dispatchUpdateSmartLinkPreview({ nsfwType: value });
    };

    const saveNsfwType = (e) => {
        e.preventDefault();

        console.log(smartlinkProps.nsfwType, nsfwType, smartlinkProps.settings);
        if (smartlinkProps.nsfwType !== nsfwType && smartlinkProps.settings.subscription.plan === 'plan_pro') {
            return saveChanges('nsfwType', { nsfwType });
        }

        setSaveButton('');
        return null;
    };

    const cancelChanges = (field) => {
        switch (field) {
            case 'headline':
                setHeadline(smartlinkProps.headline);
                break;
            case 'bio':
                setBio(smartlinkProps.bio);
                break;
            case 'customId':
                setCustomId(smartlinkProps.current_handle);
                break;
            case 'nsfwType':
                setNsfwType(smartlinkProps.nsfwType);
                if (!smartlinkProps.nsfwType) {
                    setNsfwEnabled(false);
                }
                break;
            default:
                break;
        }
        setSaveButton('');
    };

    // cancel unsaved changes if focus changes, attempt upgrade modal for pro features
    const handleFocus = (field) => {
        if (saveButton !== field) {
            cancelChanges(saveButton);
        }
        if (field === 'headline' || field === 'customId' || field === 'nsfwType') {
            attemptUpgradeModal(field);
        }
    };

    if (!smartlinkProps.loaded) {
        return <SmartlinksGeneralSkeleton />;
    }

    return (
        <>
            <fieldset>
                <legend>Profile</legend>
                <ul>
                    <li>
                        <SmartLinkProfilePhoto { ...props } />
                    </li>
                    <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => { return attemptUpgradeModal('headline'); }}>
                        <form onSubmit={saveHeadline}>
                            <TextField
                                label="Name/Headline"
                                value={headline}
                                onChange={handleHeadlineChange}
                                onFocus={() => { handleFocus('headline'); }}
                                helpText={(affiliate.account_type === 'smartlinks' && !headline) ? <>Add your name in <Link to="/settings">Account Settings</Link></> : ''}
                                disabled={fieldLoading === 'headline' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                            />
                            {saveButton === 'headline'
                                && (
                                    <div className="button-group">
                                        <button type="button" className="cancel" onClick={() => { return cancelChanges('headline'); }}>Cancel</button>
                                        <LlamaButton
                                            type="submit"
                                            loading={fieldLoading === 'headline'}
                                            disabled={fieldLoading === 'headline'}
                                            onClick={saveHeadline}
                                        >
                                            Save
                                        </LlamaButton>
                                    </div>
                                )
                            }
                        </form>
                    </li>
                    <li>
                        <form onSubmit={saveBio}>
                            <TextField
                                label="Bio"
                                multiline={4}
                                value={bio}
                                onFocus={() => { handleFocus('bio'); }}
                                onChange={handleBioChange}
                                disabled={fieldLoading === 'bio'}
                            />
                            {saveButton === 'bio'
                                && (
                                    <div className="button-group">
                                        <button type="button" className="cancel" onClick={() => { return cancelChanges('bio'); }}>Cancel</button>
                                        <LlamaButton
                                            type="submit"
                                            loading={fieldLoading === 'bio'}
                                            disabled={fieldLoading === 'bio'}
                                            onClick={saveBio}
                                        >
                                            Save
                                        </LlamaButton>
                                    </div>
                                )
                            }
                        </form>
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>Page Settings</legend>
                <ul>
                    <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => { return attemptUpgradeModal('nsfwType'); }}>
                        <Checkbox
                            label="Display an NSFW content warning"
                            checked={nsfwEnabled}
                            onChange={handleNsfwEnabledChange}
                            onFocus={() => { handleFocus('nsfwType'); }}
                            disabled={fieldLoading === 'nsfwType' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                        />
                    </li>
                    {nsfwEnabled
                        && (
                            <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => { return attemptUpgradeModal('nsfwType'); }}>
                                <Select
                                    label="NSFW Warning Type"
                                    options={[
                                        { label: 'Banner', value: 'banner' },
                                        { label: 'Overlay', value: 'overlay' }
                                    ]}
                                    value={nsfwType}
                                    helpText="Banner will appear as a small, full width alert box at the top of the screen. Overlay will cover the entire screen with a blur and requires the viewer to click to see your page."
                                    onChange={handleNsfwTypeChange}
                                    disabled={fieldLoading === 'nsfwType'}
                                />
                                {saveButton === 'nsfwType'
                                    && (
                                        <div className="button-group">
                                            <button type="button" className="cancel" onClick={() => { return cancelChanges('nsfwType'); }}>Cancel</button>
                                            <LlamaButton
                                                type="submit"
                                                loading={fieldLoading === 'nsfwType'}
                                                disabled={fieldLoading === 'nsfwType'}
                                                onClick={saveNsfwType}
                                            >
                                                Save
                                            </LlamaButton>
                                        </div>
                                    )
                                }
                            </li>
                        )
                    }
                </ul>
            </fieldset>
        </>
    );
};

SmartLinkGeneral.propTypes = {

}

const mapStateToProps = (state) => {
    console.log('STATE', state);
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchUpdateSmartLink: (id, data) => { return dispatch(updateSmartLink(id, data)); },
        dispatchUpdateSmartLinkPreview: (data) => { return dispatch(updateSmartLinkPreview(data)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartLinkGeneral);
