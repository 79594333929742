/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../../config'; */
import { sendGraphQL } from '../sendRequest';

const TWITTER = 'CREATE_TWITTER_USER';

export const createTwitterAffData = (code, cognito_id) => {
    const query = `mutation createTwitterUser( $code: TwitterAuthCode, $cognito_id: String ){
        createTwitterUser( code: $code, cognito_id: $cognito_id ){
            id
            cognito_id
            user{
                id
                name
                screenName
            }
        }
    }`;

    const variables = {
        cognito_id,
        code
    };

    const payload = {
        query,
        variables
    };

    return {
        type : TWITTER,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type : TWITTER,
        payload: axios.post( NEXT_APP_API_URL, payload, {headers})
        .then(response => response.data)
    } */
}

export default createTwitterAffData;
