import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@shopify/polaris';

import { updateCustomization } from '../../../store/actions/action_updateSmartlinkCustomization';

import ImageDrop, { saveImage } from '../../llama/image-drop';

import defaultAvatar from '../../../assets/smartlink-img/user-icon2.png';
import './smart-link-profile-photo.css';

const SmartLinkProfilePhoto = ({ smartlinks: smartlinkProps, affiliate, dispatchUpdateCustomization }) => {
    const currentPhoto = smartlinkProps.customizations.avatarUrl || defaultAvatar;

    const [modalOpen, setModalOpen] = useState(false);
    const [uploadedImage, setImage] = useState(null);
    const [uploading, setUploading] = useState(false);

    const [socialPhotos, setSocialPhotos] = useState([]);

    const [uploadMode, setUploadMode] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState({
        type: 'current',
        url: currentPhoto
    });
    const [profileImages, setProfileImages] = useState([]);
    const smaertlinksImages = smartlinkProps.images;
    
    useEffect(() => {
        let photos= [];
        
        if (smaertlinksImages && smaertlinksImages.length > 0) {
            const custPhotos = smaertlinksImages.reduce((acc, account) => {
                acc.push({
                    type: 'custProfile',
                    url: account
                });
                return acc;
            }, []);
            photos = [
                ...photos,
                ...custPhotos
            ];

            // don't include the current photo
            const usedPhotoIndex = photos.findIndex((photo) => {
                return photo.url === currentPhoto;
            });

            if (usedPhotoIndex !== -1) {
                photos.splice(usedPhotoIndex, 1);
            }

            setProfileImages(photos);
        }
    }, [modalOpen])

    useEffect(() => {
        let photos = [];

        // instagram
        if (affiliate.instagramDataV2 && affiliate.instagramDataV2.length > 0) {
            const igPhotos = affiliate.instagramDataV2.reduce((acc, account) => {
                if (account.photo_url) {
                    acc.push({
                        type: 'instagram',
                        url: account.photo_url
                    });
                }
                return acc;
            }, []);
            photos = [
                ...photos,
                ...igPhotos
            ];
        }

        // facebook
        if (affiliate.facebookData && affiliate.facebookData.length > 0) {
            const fbPhotos = affiliate.facebookData.reduce((acc, account) => {
                if (account.fb_general_info && Array.isArray(account.fb_general_info)) {
                    account.fb_general_info.forEach((accountInfo) => {
                        acc.push({
                            type: 'facebook',
                            url: accountInfo.profilePhoto
                        });
                    });
                }
                if (account.pageStats && Array.isArray(account.pageStats)) {
                    account.pageStats.forEach((pageInfo) => {
                        acc.push({
                            type: 'facebook',
                            url: pageInfo.profilePhoto
                        });
                    });
                }
                return acc;
            }, []);
            photos = [
                ...photos,
                ...fbPhotos
            ];
        }

        // twitter
        if (affiliate.twitterData && affiliate.twitterData.length > 0) {
            const twitterPhotos = affiliate.twitterData.reduce((acc, account) => {
                if (account.user.profilePicture) {
                    acc.push({
                        type: 'twitter',
                        url: account.user.profilePicture.replace('_normal', '')
                    });
                }
                return acc;
            }, []);
            photos = [
                ...photos,
                ...twitterPhotos
            ];
        }

        // youtube
        if (affiliate.youTubeData && affiliate.youTubeData.length > 0) {
            const ytPhotos = affiliate.youTubeData.reduce((acc, account) => {
                if (account.channelStats && Array.isArray(account.channelStats)) {
                    account.channelStats.forEach((channelInfo) => {
                        acc.push({
                            type: 'youtube',
                            url: channelInfo.channelPhoto
                        });
                    });
                }
                return acc;
            }, []);
            photos = [
                ...photos,
                ...ytPhotos
            ];
        }

        // tiktok
        if (affiliate.tikTokData && affiliate.tikTokData.length > 0) {
            const tiktokPhotos = affiliate.tikTokData.reduce((acc, account) => {
                if (account.basic.avatar_url) {
                    acc.push({
                        type: 'tiktok',
                        url: account.basic.avatar_url
                    });
                }
                return acc;
            }, []);
            photos = [
                ...photos,
                ...tiktokPhotos
            ];
        }

        // don't include the current photo
        const usedPhotoIndex = photos.findIndex((photo) => {
            return photo.url === currentPhoto;
        });

        if (usedPhotoIndex !== -1) {
            photos.splice(usedPhotoIndex, 1);
        }

        setSocialPhotos(photos);
    }, [modalOpen]);

    const closeModal = () => {
        setModalOpen(false);
        setUploadMode(false);
    };

    const saveProfilePhoto = async () => {
        if (uploadMode || selectedPhoto.url !== currentPhoto) {
            setUploading(true);

            const customizations = { ...smartlinkProps.customizations };
            customizations.avatarUrl = selectedPhoto.url;

            if (uploadMode) {
                const saveResult = await saveImage(uploadedImage, smartlinkProps.unique_id, 'avatars', null, true);
                customizations.avatarUrl = saveResult.url;
                smaertlinksImages.indexOf(saveResult.url) === -1 && smaertlinksImages.push(saveResult.url);
            }

            await dispatchUpdateCustomization(affiliate.cognito_id, customizations);
            setUploading(false);
            closeModal();
        } else {
            closeModal();
        }
    };

    const selectPhoto = (type, photoUrl) => {
        let url;
        switch (type) {
            case 'current':
                url = currentPhoto;
                break;
            case 'profile':
                url = affiliate.photo;
                break;
            case 'custom':
                url = null;
                break;
            default:
                url = photoUrl;
                break;
        }

        setSelectedPhoto({
            type,
            url
        });

        if (type === 'custom') {
            setUploadMode(true);
        } else {
            setUploadMode(false);
        }
    };

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={closeModal}
                title="Select or Upload a Profile Picture"
                primaryAction={{
                    content: (!uploadMode) ? 'Save' : 'Save and Upload',
                    onAction: saveProfilePhoto,
                    loading: uploading,
                    disabled: ((!uploadMode && !selectedPhoto.url) || uploading)
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: closeModal
                    }
                ]}
            >
                <Modal.Section>
                    Choose an existing photo
                    <ul id="smartlink-customize-photos-list">
                        {/* current smartlink photo */}
                        {currentPhoto
                            && (
                                <li data-selected={selectedPhoto.url === currentPhoto}>
                                    <button data-selected={selectedPhoto.type === 'current'} type="button" onClick={() => { return selectPhoto('current'); }}>
                                        <img src={currentPhoto} alt="current" />
                                    </button>
                                </li>
                            )
                        }

                        {/* photos from Smartlinks S3 */}
                        {profileImages && profileImages.length > 0
                            && profileImages.map((image) => {
                                return (
                                    <li key={image.url} data-selected={selectedPhoto.url === image.url} className={image.type}>
                                        <button type="button" onClick={() => { return selectPhoto(image.type, image.url); }}>
                                            <img src={image.url} alt={image.type} />
                                        </button>
                                    </li>
                                );
                            })
                        }

                        {/* current profile photo */}
                        {affiliate.photo && currentPhoto !== affiliate.photo
                            && (
                                <li data-selected={selectedPhoto.url === affiliate.photo}>
                                    <button type="button" onClick={() => { return selectPhoto('profile'); }}>
                                        <img src={affiliate.photo} alt="profile" />
                                    </button>
                                </li>
                            )
                        }

                        {/* photos from connected social media */}
                        {socialPhotos && socialPhotos.length > 0
                            && socialPhotos.map((photo) => {
                                return (
                                    <li key={photo.url} data-selected={selectedPhoto.url === photo.url} className={photo.type}>
                                        <button type="button" onClick={() => { return selectPhoto(photo.type, photo.url); }}>
                                            <img src={photo.url} alt={photo.type} />
                                        </button>
                                    </li>
                                );
                            })
                        }

                        {/* upload new */}
                        <li className="custom">
                            <button type="button" onClick={() => { return selectPhoto('custom'); }}>Upload New Photo</button>
                        </li>
                    </ul>
                </Modal.Section>
                {uploadMode
                    && (
                        <Modal.Section>
                            <ImageDrop
                                buttonText="Upload a photo"
                                setImage={setImage}
                                crop="square"
                                resize={300}
                                constrain="min"
                            />
                        </Modal.Section>
                    )
                }
            </Modal>
            <p className="photo">
                <img src={currentPhoto} alt="" />
                <button type="button" onClick={() => { return setModalOpen(true); }}>Edit</button>
            </p>
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchtoProps = (dispatch) => {
    return {
        dispatchUpdateCustomization: (id, data) => { return dispatch(updateCustomization(id, data)); }
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLinkProfilePhoto);
