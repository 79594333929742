import { sendGraphQL } from './sendRequest';

export const getLinkParams = (offer_id, affiliate_id) => {
    const query = `
        query getOfferLinkParams($offer_id: String!, $affiliate_id: String!){
            getOfferLinkParams(offer_id: $offer_id, affiliate_id: $affiliate_id){
                id
                source
                descriptor
            }
        }
    `;

    const variables = {
        offer_id,
        affiliate_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; });
};

export const addLinkParam = (offer_id, affiliate_id, source, descriptor) => {
    const query = `
        mutation addOfferLinkParam($offer_id: String!, $affiliate_id: String!, $source: String!, $descriptor: String!){
            addOfferLinkParam(offer_id: $offer_id, affiliate_id: $affiliate_id, source: $source, descriptor: $descriptor){
                id
                source
                descriptor
            }
        }
    `;

    const variables = {
        offer_id,
        affiliate_id,
        source,
        descriptor
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; });
};

export const editLinkParam = (offer_id, affiliate_id, linkId, source, descriptor) => {
    const query = `
        mutation editOfferLinkParam($offer_id: String!, $affiliate_id: String!, $id: String! $source: String!, $descriptor: String!){
            editOfferLinkParam(offer_id: $offer_id, affiliate_id: $affiliate_id, id: $id, source: $source, descriptor: $descriptor){
                id
                source
                descriptor
            }
        }
    `;

    const variables = {
        offer_id,
        affiliate_id,
        id: linkId,
        source,
        descriptor
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; });
};

export const deleteLinkParam = (offer_id, affiliate_id, linkId) => {
    const query = `
        mutation deleteOfferLinkParam($offer_id: String!, $affiliate_id: String!, $id: String!){
            deleteOfferLinkParam(offer_id: $offer_id, affiliate_id: $affiliate_id, id: $id){
                id
                source
                descriptor
            }
        }
    `;

    const variables = {
        offer_id,
        affiliate_id,
        id: linkId
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload, false, true)
        .then((response) => { return response.data; });
};
