/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import {sendGraphQL} from './sendRequest';

export const addTrackingLink = (application_id, tracking_link) => {

    const query = `
        mutation addTrackingLink($application_id: String!, $tracking_link: String!) {
            addTrackingLink(application_id: $application_id, tracking_link: $tracking_link) {
                tracking_link 
            }
        }
    `

    const variables = {
        application_id,
        tracking_link
    }

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then(response => response.data.data)

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, { query, variables }, { headers })
        .then(response => response.data.data) */

}
