import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RadioButton, TextField, Select } from '@shopify/polaris';

import { updateSmartLinkPreview } from '../../../store/actions/action_updateSmartlink';

const SmartLinkOfferForm = ({ affiliate, updateLinks, onLinkSubmit, links, manualLinks, offers, action, currentLinkId, setSaving, submitRef, dispatchUpdateSmartLinkPreview }) => {
    const [manualLinkInfo, setManualLinkInfo] = useState({
        id: null,
        title: '',
        url: '',
        offerId: '',
        offerName: '',
        company: ''
    });
    const [offerOptions, setOfferOptions] = useState([]);
    const [formErrors, setFormErrors] = useState({
        url: null,
        title: null
    });
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [manualLinkType, setManualLinkType] = useState('custom');

    // prevent offers the user already chose from being chooseable for another link
    const disableDuplicateOffers = (linkInfo) => {
        // if we pass link info (due to the new state not being immediately avaialable), use the passed in value, otherwise use the state
        if (!linkInfo) {
            linkInfo = manualLinkInfo;
        }

        // get an array of the offer ids that are already in their list
        const usedOffers = links.reduce((acc, link) => {
            if (link.offerId && link.offerId !== linkInfo.offerId) {
                acc.push(link.offerId);
            }
            return acc;
        }, []);

        // disable offers that are in the usedOffers array
        const offerOptionsWithDisabled = offers.map((link) => {
            const offerOption = { label: link.offerName, value: link.offerId };
            if (usedOffers.indexOf(link.offerId) !== -1) {
                offerOption.disabled = true;
            }
            return offerOption;
        });
        setOfferOptions(offerOptionsWithDisabled);
    };

    useEffect(() => {
        if (action === 'add') {
            disableDuplicateOffers();
        } else {
            const newLinkList = links.slice();
            const linkInfo = newLinkList.find((link) => {
                return link.id === currentLinkId;
            });
            setManualLinkInfo(linkInfo);
            if (linkInfo.offerId && linkInfo.offerId !== '') {
                setManualLinkType('offer');
            }
            disableDuplicateOffers(linkInfo);
        }

        const formattedOfferOptions = offers.map((offer) => {
            return { label: offer.offerName, value: offer.offerId };
        });
        setOfferOptions(formattedOfferOptions);
    }, []);

    // @return true if errors or false if no errors
    const validateLinkInfo = (linkInfo) => {
        // if we pass link info (due to the new state not being immediately avaialable), use the passed in value, otherwise use the state
        if (!linkInfo) {
            linkInfo = manualLinkInfo;
        }
        let errors = { title: null, url: null, offer: null };

        if (manualLinkType === 'custom') {
            if (!linkInfo.title || linkInfo.title.trim().length === 0) {
                errors = { ...errors, title: 'Name is required' };
            } else {
                // prevent duplicates
                const enteredName = linkInfo.title.toLowerCase().trim().replace(/\s{2,}/g, ' ');
                const namesUsed = links.reduce((acc, link) => {
                    if (linkInfo.id !== link.id) {
                        acc.push(link.title.toLowerCase().trim().replace(/\s{2,}/g, ' '));
                    }
                    return acc;
                }, []);
                if (namesUsed.indexOf(enteredName) !== -1) {
                    errors = { ...errors, title: 'You already have a link with that name. Try using a different one.' };
                }
            }

            if (!linkInfo.url || linkInfo.url.trim().length === 0) {
                errors = { ...errors, url: 'Referral Link is required' };
            } else {
                const urlPieces = linkInfo.url.split('?');
                let validUrl = true;
                // validate domain and url path
                validUrl = /^(http:\/\/www\.|http:\/\/|https:\/\/www\.|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?\/?([a-zA-Z0-9_+\-./]+)?$/.test(urlPieces[0]);
                // validate query string
                if (urlPieces[1] && validUrl) {
                    validUrl = /^([0-9A-Za-z\-[\]._+[\]:]+=[0-9A-Za-z\-._+]+(&)?)+$/.test(urlPieces[1]);
                }
                if (!validUrl) {
                    errors = { ...errors, url: 'Please enter a valid URL' };
                }
            }

            setFormErrors(errors);
            return errors.title || errors.url;
        }
        if (!linkInfo.offerId || linkInfo.offerId === null) {
            errors = { ...errors, offer: 'Offer is required' };
        }

        setFormErrors(errors);
        return errors.offer;
    };

    const handleTextChange = (value, field) => {
        console.log(value);
        const linkInfo = { ...manualLinkInfo, [field]: value };
        setManualLinkInfo(linkInfo);
        dispatchUpdateSmartLinkPreview({ newLink: linkInfo });
        // if the user submitted and is trying to clear the error, run validation to let them know as soon as they've typed what we need
        if (submitAttempted) {
            validateLinkInfo(linkInfo);
        }
    };

    const handleTypeChange = (checked, value) => {
        setManualLinkType(value);
    };

    const handleOfferChange = (value) => {
        const offer = offers.find((link) => {
            return link.offerId === value;
        });

        const linkInfo = {
            ...offer,
            id: manualLinkInfo.id
        };
        setManualLinkInfo(linkInfo);
        // if the user submitted and is trying to clear the error, run validation to let them know as soon as they've typed what we need
        if (submitAttempted) {
            validateLinkInfo(linkInfo);
        }
    };

    const submitLink = () => {
        setSubmitAttempted(true);
        const hasFormErrors = validateLinkInfo();

        if (!hasFormErrors) {
            setSaving(true);
            let newLinkList = links.slice();
            let allManualLinks = manualLinks.slice();

            // format link info
            if (manualLinkType === 'custom') {
                const linkInfo = { ...manualLinkInfo };

                // if they didn't add a protocol, add it for them
                if (!/^https?:\/\//.test(linkInfo.url)) {
                    linkInfo.url = `https://${linkInfo.url}`;
                }

                if (action === 'add') {
                    linkInfo.id = uuidv4();
                    newLinkList.push(linkInfo);
                    allManualLinks.push(linkInfo);
                } else {
                    const linkIndex = newLinkList.findIndex((link) => {
                        return link.id === linkInfo.id;
                    });
                    const manualLinkIndex = newLinkList.findIndex((link) => {
                        return link.id === linkInfo.id;
                    });
                    newLinkList[linkIndex] = linkInfo;
                    allManualLinks[manualLinkIndex] = linkInfo;
                }
            } else {
                // find matching offer
                const offerInfo = offers.find((offer) => {
                    return offer.offerId === manualLinkInfo.offerId;
                });

                offerInfo.title = manualLinkInfo.title;

                if (action === 'add') {
                    newLinkList.push(offerInfo);
                    allManualLinks.push(offerInfo);
                } else {
                    newLinkList = newLinkList.map((link) => {
                        if (link.id === manualLinkInfo.id) {
                            offerInfo.id = link.id;
                            return offerInfo;
                        }
                        return link;
                    });
                    allManualLinks = allManualLinks.map((link) => {
                        if (link.id === manualLinkInfo.id) {
                            offerInfo.id = link.id;
                            return offerInfo;
                        }
                        return link;
                    });
                }
            }

            // save and close modal
            updateLinks(newLinkList, allManualLinks).then(() => {
                setSaving(false);
                if (onLinkSubmit) {
                    onLinkSubmit(action);
                }
            });
        }
    };

    // let the enter key trigger the modal's primary action
    const onSubmit = (e) => {
        e.preventDefault();
        submitLink();
    };

    // allow the parent component to trigger the form submission
    submitRef.current = submitLink;

    return (
        <form onSubmit={onSubmit}>
            <ul className="smartlinks--link-form">
                {affiliate.account_type !== 'smartlinks'
                    && (
                        <li className="smartlinks--link-type">
                            <RadioButton label="Custom Link" checked={manualLinkType === 'custom'} onChange={handleTypeChange} name="link-type" id="custom" />
                            <RadioButton label="Offer" checked={manualLinkType === 'offer'} onChange={handleTypeChange} name="link-type" id="offer" />
                        </li>
                    )
                }
                {manualLinkType === 'custom'
                    ? (
                        <>
                            <li>
                                <TextField
                                    label="Company Name"
                                    placeholder="Example Co."
                                    value={manualLinkInfo.company}
                                    onChange={(value) => { return handleTextChange(value, 'company'); }}
                                    helpText="Optional. If you&rsquo;re promoting a product or service, you can enter the name of its company here. This will appear above your link name in uppercase letters."
                                />
                            </li>
                            <li>
                                <TextField
                                    label="Link Name"
                                    placeholder="Get 10% off your first month of Example"
                                    value={manualLinkInfo.title}
                                    onChange={(value) => { return handleTextChange(value, 'title'); }}
                                    error={formErrors.title}
                                    helpText="Briefly describe what you&rsquo;re promoting. If it&rsquo;s a sponsored link, you could enter what your person will receive. If it&rsquo;s for a specific blog, video, podcast, etc, you could enter its title. It&rsquo;s all up to you."
                                />
                            </li>
                            <li>
                                <TextField
                                    label="Link URL"
                                    placeholder={`https://example.com/${affiliate.account?.personal?.first_name}`}
                                    value={manualLinkInfo.url}
                                    onChange={(value) => { return handleTextChange(value, 'url'); }}
                                    error={formErrors.url}
                                />
                            </li>
                        </>
                    )
                    : (
                        <>
                            <li data-offer-id={manualLinkInfo.offerId}>
                                <Select
                                    label="Offer"
                                    options={offerOptions}
                                    placeholder="Select Offer"
                                    value={manualLinkInfo.offerId}
                                    onChange={handleOfferChange}
                                    error={formErrors.offer}
                                />
                            </li>
                            <li>
                                <TextField
                                    label="Link Name"
                                    value={manualLinkInfo.title}
                                    onChange={(value) => { return handleTextChange(value, 'title'); }}
                                    error={formErrors.title}
                                    helpText="Briefly describe the offer you&rsquo;re promoting. You could enter a specific product to buy, a category, or whatever you like."
                                />
                            </li>
                        </>
                    )}
            </ul>
            <button style={{ display: 'none' }} type="submit">Save</button>
        </form>
    );
};

SmartLinkOfferForm.propTypes = {
    affiliate: PropTypes.shape({
        account_type: PropTypes.string
    }).isRequired,
    updateLinks: PropTypes.func.isRequired,
    onLinkSubmit: PropTypes.func.isRequired,
    links: PropTypes.arrayOf().isRequired,
    manualLinks: PropTypes.arrayOf().isRequired,
    offers: PropTypes.arrayOf().isRequired,
    action: PropTypes.string.isRequired,
    currentLinkId: PropTypes.string
};

SmartLinkOfferForm.defaultProps = {
    currentLinkId: null
};

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate
    }
};

const mapDispatchtoProps = (dispatch) => {
    return {
        dispatchUpdateSmartLinkPreview: (data) => { return dispatch(updateSmartLinkPreview(data)); }
    }
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLinkOfferForm);
