import React from 'react';
import { Card, Icon } from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import PropTypes from 'prop-types';

import lockIcon from '../../assets/lock-icon.svg';

import './tiktok-locked.css';

const TikTokLockedDetail = (props) => {
    const { history: { push } } = props;

    const navigateToUpsell = () => {
        push('/tiktokAnalytics');
    };

    const renderBreadcrumbs = () => {
        const { hasActiveTikTok, isExpired } = props;
        // Only display breadcrumbs for users who haven't
        // connected an TikTok account to Hype Auditor
        if (!hasActiveTikTok && !isExpired) {
            return (
                <div className="hype-locked__breadcrumbs-wrapper">
                    <Icon source={ChevronLeftMinor} />
                    <p onClick={navigateToUpsell}>Back</p>
                </div>
            );
        }
        return null;
    };

    const renderReportPreview = () => {
        const { hasActiveTikTok, isExpired } = props;
        // Only display Hype Auditor preview link for users who
        // haven't connected an TikTok account to Hype Auditor
        if (!hasActiveTikTok && !isExpired) {
            return (
                <Card.Section>
                    <div className="hype-locked__header-wrapper">
                        <h3>Report Preview</h3>
                        <p onClick={navigateToUpsell}>
                            Unlock for <b>FREE</b> to view the full report
                        </p>
                    </div>
                </Card.Section>
            );
        }
        return null;
    };

    return (
        <div>
            {renderBreadcrumbs()}
            <Card.Section>
                <div className="hype-locked__affiliate-stats">
                    <div className="hype-locked__stat-item">
                        <h2>50K</h2>
                        <p>Subscribers</p>
                    </div>
                    <div className="hype-locked__stat-item">
                        <h2>720</h2>
                        <p>Avg. Likes</p>
                    </div>
                    <div className="hype-locked__stat-item">
                        <h2>92</h2>
                        <p>Avg. Comment</p>
                    </div>
                </div>
            </Card.Section>
            {renderReportPreview()}
            <div className="hype-locked__wrapper">
                <div className="hype-locked__section">
                    <h3>Check audience demography</h3>
                    <div className="hype-locked__section-content">
                        <div className="hype-locked__section-top">
                            <h3>Audience Demography</h3>
                        </div>
                        <div className="hype-locked__section-items">
                            <div className="hype-locked__locked-item">
                                Full audience age-gender split <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Influencers true reach <img src={lockIcon} alt="Locked" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hype-locked__section-content">
                    <div className="hype-locked__section-top">
                        <h3>Audience Interests</h3>
                    </div>
                    <div className="hype-locked__section-items">
                        <div className="hype-locked__locked-item">
                            Full audience interests list <img src={lockIcon} alt="Locked" />
                        </div>
                    </div>
                </div>

                <div className="hype-locked__section">
                    <h3>Check advertising performance</h3>
                    <div className="hype-locked__section-content">
                        <div className="hype-locked__section-top">
                            <h3>Brand Mentions</h3>
                        </div>
                        <div className="hype-locked__section-items">
                            <div className="hype-locked__locked-item">
                                Estimated post price <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Advertising post freq <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Non-ad post freq <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Native ad performance <img src={lockIcon} alt="Locked" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hype-locked__section">
                    <h3>Check for fake Subscribers and engagement</h3>
                    <div className="hype-locked__section-content">
                        <div className="hype-locked__section-top">
                            <h3>Subscriber Growth</h3>
                        </div>
                        <div className="hype-locked__section-items">
                            <div className="hype-locked__locked-item">
                                Subscribe/unsubscribe patterns analysis <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Subscribers graph <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Organic growth check <img src={lockIcon} alt="Locked" />
                            </div>
                        </div>
                    </div>

                    <div className="hype-locked__section-content">
                        <div className="hype-locked__section-top">
                            <h3>Account Activity</h3>
                        </div>
                        <div className="hype-locked__section-items">
                            <div className="hype-locked__locked-item">
                                Engagement analysis <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Engagement authenticity check <img src={lockIcon} alt="Locked" />
                            </div>
                            <div className="hype-locked__locked-item">
                                Comment pods check <img src={lockIcon} alt="Locked" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TikTokLockedDetail;

TikTokLockedDetail.propTypes = {
    hasActiveTikTok: PropTypes.bool.isRequired,
    isExpired: PropTypes.bool.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};
