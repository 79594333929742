import React from 'react';
import { TextField, SkeletonDisplayText, RangeSlider, Select } from '@shopify/polaris';

const colorFields = [
    { label: 'Page Background Color', value: 'pageBg' },
    { label: 'Headline/Bio Color', value: 'headlineText' },
    { label: 'Button Background Color', value: 'buttonBg' },
    { label: 'Button Border Color', value: 'buttonBorder' },
    { label: 'Button Text Color', value: 'buttonText' }
];

const SmartlinksThemesSkeleton = () => {
    return (
        <>
            <fieldset>
                <legend>Choose a theme</legend>
                <ul id="smartlink-themes-list">
                    {[1, 2, 3, 4, 5, 6].map((key) => {
                        return (
                            <li key={key}>
                                <span className="theme-wrapper">
                                    <SkeletonDisplayText size="large" />
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </fieldset>
            <fieldset className="smartlinks--custom-theme">
                <div className="custom-theme-heading">
                    <legend>Customize Theme</legend>
                </div>

                <ul>
                    <li>
                        <label htmlFor="fonts">Font</label>
                        <Select
                            value="loading"
                            options={[{ value: 'loading', label: 'loading...' }]}
                            disabled
                            id="fonts"
                        />
                    </li>
                    {colorFields.map((field) => {
                        return (
                            <li key={field.value}>
                                <label htmlFor={field.value}>{field.label}</label>
                                <div className="color-picker-wrap">
                                    <span className="color-swatch" style={{ backgroundColor: '#fff' }} />
                                    <TextField
                                        value="#loading..."
                                        pattern="[abcdef0-9]{0,6}"
                                        id={field.value}
                                        disabled
                                    />
                                </div>
                            </li>
                        );
                    })}
                    <li>
                        <label htmlFor="button-roundness">Button Roundness</label>
                        <RangeSlider
                            min="0"
                            max="30"
                            value="1"
                            disabled
                            id="button-roundness"
                        />
                    </li>
                </ul>
            </fieldset>
        </>
    );
};

export default SmartlinksThemesSkeleton;
