import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@shopify/polaris';

import defaultImage from '../../assets/smartlink-img/user-icon2.png';

import './smart-link-mobile.css';

const SmartLinkMobile = (props) => {
    const { smartlinks: smartlinkProps, affiliate } = props;
    const { preview } = props.smartlinks;

    const [showReadMore, setShowReadMore] = useState(false);
    const [showNsfw, setShowNsfw] = useState(true);
    const [bioOpen, setBioOpen] = useState(false);
    const [options, setOptions] = useState({});

    // runs when bio is rendered on the page
    const bioElement = useCallback(() => {
        toggleBioReadMore();
    }, []);

    // conditionally show read more when content is more than 3 lines
    const toggleBioReadMore = () => {
        const $bio = document.querySelector('.bio');
        let cutoff = 0;
        if ($bio) {
            cutoff = parseInt(window.getComputedStyle($bio).lineHeight, 10) * 3;
        }
        if ($bio && $bio.scrollHeight > cutoff && !showReadMore) {
            setShowReadMore(true);
        } else if ($bio && $bio.scrollHeight <= cutoff && showReadMore) {
            setShowReadMore(false);
        }
    };

    useEffect(() => {
        if (options.bio) {
            toggleBioReadMore();
        }

        // if email form options are not in preview, don't scroll down
        if (preview && preview.emailForm) {
            document.querySelector('.preview-area-scroll').scrollTop = 500;
        }

        if (options.nsfwType) {
            setShowNsfw(true);
        }
    }, [options.bio, options.emailForm, options.nsfwType]);

    // merge the preview object with the currently saved settings so that the preview setting overrides the saved setting and nothing is undefined
    useEffect(() => {
        const savedOpts = {
            customTheme: (smartlinkProps.customizations.theme === 'custom'),
            nsfwType: smartlinkProps.nsfwType,
            headline: smartlinkProps.headline,
            full_name: smartlinkProps.full_name || affiliate.name,
            bio: smartlinkProps.bio,
            instagram: (smartlinkProps.socialProfiles) ? smartlinkProps.socialProfiles.instagram : null,
            facebook: (smartlinkProps.socialProfiles) ? smartlinkProps.socialProfiles.facebook : null,
            twitter: (smartlinkProps.socialProfiles) ? smartlinkProps.socialProfiles.twitter : null,
            youtube: (smartlinkProps.socialProfiles) ? smartlinkProps.socialProfiles.youtube : null,
            tiktok: (smartlinkProps.socialProfiles) ? smartlinkProps.socialProfiles.tiktok : null,
            soundcloud: (smartlinkProps.socialProfiles) ? smartlinkProps.socialProfiles.soundcloud : null,
            emailForm: {
                heading: (smartlinkProps.mailingList) ? smartlinkProps.mailingList.formHeading : null,
                buttonText: (smartlinkProps.mailingList) ? smartlinkProps.mailingList.formButtonText : null
            },
            photo: smartlinkProps.customizations.avatarUrl || null
        };
        const opts = {
            ...savedOpts,
            ...preview
        };

        if (smartlinkProps && smartlinkProps.settings && smartlinkProps.settings.subscription.plan !== 'plan_pro') {
            opts.emailForm = null;
            opts.nsfwType = null;
            opts.headline = opts.full_name;
        }

        setOptions(opts);
    }, [preview, smartlinkProps]);

    const renderCustomThemeStyle = () => {
        if (smartlinkProps.settings.subscription.plan === 'plan_pro' && options.customTheme) {
            const themeStyles = {
                ...smartlinkProps.customizations,
                ...preview
            };

            let styles = '';
            let btnRoundness = '';
            if (themeStyles.font) {
                styles += `font-family: ${themeStyles.font} !important;`;
            }
            if (themeStyles.pageBg) {
                styles += `background: ${themeStyles.pageBg} !important;`;
            }
            if (themeStyles.headlineText) {
                styles += `--sl-preview-headline: ${themeStyles.headlineText} !important;`;
            }
            if (themeStyles.buttonBg) {
                styles += `--sl-preview-link-bg: ${themeStyles.buttonBg} !important;`;
            }
            if (themeStyles.buttonBorder) {
                styles += `--sl-preview-link-border: ${themeStyles.buttonBorder} !important;`;
            }
            if (themeStyles.buttonText) {
                styles += `--sl-preview-link-text: ${themeStyles.buttonText} !important;`;
            }
            if (themeStyles.buttonRoundness || themeStyles.buttonRoundness === 0) {
                btnRoundness = `--sl-preview-link-border-radius: ${themeStyles.buttonRoundness}px !important;`;
                styles += btnRoundness;
            }

            return (
                <>
                    {themeStyles.font && <link href={`https://fonts.googleapis.com/css?family=${themeStyles.font}:400,700&display=swap`} rel="stylesheet" />}
                    <style>
                        {`.preview-area-scroll{ ${styles} }`}
                    </style>
                    <style>
                        {`.nsfw-banner.overlay{ ${btnRoundness} }`}
                    </style>
                </>
            );
        }
        return null;
    };

    if (!smartlinkProps.loaded || props.linksLoading) {
        return (
            <div className="customTheme-live-preview" data-visible={props.visible}>
                <div className="previewTheme loading">
                    <Spinner color="inkLightest" />
                </div>
            </div>
        );
    }

    if (smartlinkProps.loaded && props.linksLoading && options.bio) {
        toggleBioReadMore();
    }

    return (
        <div className="customTheme-live-preview" onClick={props.togglePreview} data-visible={props.visible}>
            <div className="previewTheme">
                {renderCustomThemeStyle()}

                {smartlinkProps.settings.subscription.plan === 'plan_pro' && options.nsfwType && showNsfw
                    && (
                        <aside className={`nsfw-banner${(options.nsfwType === 'overlay') ? ' overlay' : ''}`}>
                            <p className="nsfw-heading">NSFW Content Warning</p>
                            <p>The links on this page may not be suitable for some audiences. Viewer discretion is advised</p>
                            {(options.nsfwType === 'overlay') && <p className="close"><button type="button" onClick={() => { return setShowNsfw(false); }}>Show Content</button></p>}
                        </aside>
                    )
                }

                <div className="preview-area-scroll mobile" data-theme={smartlinkProps.customizations.theme}>
                    <div className="content">
                        <p className="profile-picture"><img src={options.photo || defaultImage} alt="Profile" /></p>

                        <p className="headline">{options.headline}</p>

                        <ul className="social">
                            {options.facebook
                                && (<li><a href={`https://facebook.com/${options.facebook}`} className="facebook" target="_blank" rel="nofollow noopener noreferrer">Like me on Facebook</a></li>)
                            }
                            {options.twitter
                                && (<li><a href={`https://twitter.com/${options.twitter}`} className="twitter" target="_blank" rel="nofollow noopener noreferrer">Follow me on Twitter</a></li>)
                            }
                            {options.instagram
                                && (<li><a href={`https://instagram.com/${options.instagram}`} className="instagram" target="_blank" rel="nofollow noopener noreferrer">Follow me on Instagram</a></li>)
                            }
                            {options.tiktok
                                && (<li><a href={`https://tiktok.com/@${options.tiktok}`} className="tiktok" target="_blank" rel="nofollow noopener noreferrer">Follow me on TikTok</a></li>)
                            }
                            {options.youtube
                                && (<li><a href={`https://youtube.com/${options.youtube}?sub_confirmation=1`} className="youtube" target="_blank" rel="nofollow noopener noreferrer">Subscribe to me on YouTube</a></li>)
                            }
                            {options.soundcloud
                                && (<li><a href={`https://soundcloud.com/${options.soundcloud}`} className="soundcloud" target="_blank" rel="nofollow noopener noreferrer">Follow me on SoundCloud</a></li>)
                            }
                        </ul>

                        {options.bio
                            && (
                                <div className="bio-wrapper">
                                    <p className="bio" ref={bioElement} data-open={bioOpen}>{options.bio}</p>
                                    {showReadMore && <p className="read-more"><button type="button" onClick={() => { return setBioOpen(!bioOpen); }}>Read {bioOpen ? 'less' : 'more'}</button></p>}
                                </div>
                            )
                        }

                        <ul className="preview-links">
                            {props.links.length > 0
                                ? props.links.map((link) => {
                                    return (
                                        <li key={link.id}>
                                            <a href={link.url} target="_blank" rel="nofollow noopener noreferrer">
                                                {link.company && <span className="company">{link.company}</span>}
                                                {link.title}
                                            </a>
                                        </li>
                                    );
                                })
                                : <>{!preview?.newLink && <li className="empty">No links to display</li>}</>
                            }
                            {preview?.newLink
                                && (
                                    <li>
                                        <a href={preview?.newLink.url} target="_blank" rel="nofollow noopener noreferrer">
                                            {preview?.newLink.company && <span className="company">{preview?.newLink.company}</span>}
                                            {preview?.newLink.title}
                                        </a>
                                    </li>
                                )
                            }
                        </ul>

                        {smartlinkProps.settings.subscription.plan === 'plan_pro' && options.emailForm && (options.emailForm.heading || options.emailForm.buttonText)
                            && (
                                <form action="#">
                                    <p className="form-heading">{options.emailForm.heading}</p>
                                    <p>
                                        <input type="text" placeholder="Enter your email" />
                                        <button type="submit">{options.emailForm.buttonText}</button>
                                    </p>
                                </form>
                            )
                        }
                    </div>
                </div>

                {smartlinkProps.settings.subscription.plan !== 'plan_pro'
                    && <p className="attribution"><a href={`https://ambassadors.llama.app/?aff_id=${props.affiliateId}&offer_id=LLAMA_001`}>Powered by Llama.app</a></p>
                }
            </div>
        </div>
    );
};

SmartLinkMobile.propTypes = {

};

const mapStateToProps = (state) => {
    return {
        smartlinks: state.smartlinks,
        affiliate: state.affiliate
    };
};

export default connect(mapStateToProps, null)(SmartLinkMobile);
