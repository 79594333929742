import React, {useState, useEffect} from 'react';

import { CardElement, injectStripe } from 'react-stripe-elements';
import {Modal, TextField, InlineError, Banner} from '@shopify/polaris';

import './card-details.css';

/**
 * Reusable component to show the user the credit card info on file (card type, last 4 digits, and expiration date). Loads a Stripe credit card form in a modal when the edit button is clicked, action should be handled in parent component via props.updateCard
 * Parent needs to be wrapped in a StripeProvider in App.js
 * 
 * Props:
 * @param {Object}   cardData {brand: String!, last4: Int!, exp_month: Int!, exp_year: Int!} The user's card info
 * @param {Function} updateCard(stripeToken) function should return a promise that returns an object with success status and either the new card data or an error message {success: Boolean!, cardData: Object (same structure as props), error: String}
 */
const LlamaCreditCardDetails = (props) => {
    const [cardData, setCardData] = useState({});

    const [modalOpen, setModalOpen] = useState(false);
    const [ccDetails, setCcDetails] = useState({
        nameOnCard: ''
    })
    const [ccErrors, setCcErrors] = useState({
        nameOnCard: null,
        card: null
    })
    const [generalError, setGeneralError] = useState('')

    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [saving, setSaving] = useState(false)

    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }

    //set cardData state when props are available
    useEffect(() => {
        setCardData(props.cardData)
    }, [props.cardData])

    //if previously submitted, validate while typing
    useEffect(() => {
        if(submitAttempted){
            validateForm()
        }
    }, [ccDetails.nameOnCard])

    const handleNameChange = (value) => {
        setCcDetails({
            ...ccDetails,
            nameOnCard: value
        })
    }

    const validateForm = () => {
        let isFormValid = true;
        let errors = {};
        const nameOnCard = ccDetails.nameOnCard;

        if (!nameOnCard || nameOnCard.trim().length === 0) {
            isFormValid = false;
            errors.nameOnCard = "Name is required.";
        }else{
            const pattern = new RegExp(/^[A-Za-z '\-\.]+$/);
            if (!pattern.test(nameOnCard)) {
                isFormValid = false;
                errors.nameOnCard = "Please enter valid name.";
            }
        }

        setCcErrors(errors)

        return !isFormValid;
    }

    const updateCardEnter = (e) => {
        e.preventDefault();
        updateCard()
    }

    const updateCard = async () => {
        setSubmitAttempted(true);
        const hasFormErrors = validateForm();

        if(!hasFormErrors){
            setSaving(true)

            const stripeTokenData = await props.stripe.createToken({});

            if (stripeTokenData.error && (!stripeTokenData.token || !stripeTokenData.token.id)) {
                setCcErrors({
                    ...ccErrors,
                    card: stripeTokenData.error.message
                })
                setSaving(false)
                return;
            }

            props.updateCard(stripeTokenData.token)
                .then((cardDataResponse) => {
                    if(cardDataResponse.success){
                        setCardData(cardDataResponse.cardData)
                        setSaving(false)
                        toggleModal()
                    }else{
                        setSaving(false)
                        setGeneralError(cardDataResponse.error)
                    }
                })
        }
    }

    return (
        <>
            <Modal
                title="Update Card Information"
                primaryAction={{
                    content: "Save",
                    onAction: updateCard,
                    loading: saving
                }}
                secondaryActions={[{
                    content: 'Cancel',
                    onAction: toggleModal
                }]}
                onClose={toggleModal}
                open={modalOpen}
            >
                <Modal.Section>
                    <form onSubmit={updateCardEnter} className="cc-update-form">
                        {generalError && !saving &&
                            <Banner status="critical" title={generalError} />
                        }
                        <ul>
                            <li>
                                <TextField
                                    value={ccDetails.nameOnCard}
                                    label="Cardholder Name"
                                    type="text"
                                    onChange={handleNameChange}
                                    error={ccErrors.nameOnCard}
                                />
                            </li>
                            <li>
                                <label htmlFor="card-details">Card Number and Expiration Date</label>
                                <CardElement id="card-details" />
                                {ccErrors.card && <InlineError message={ccErrors.card} fieldID="card-details" />}
                            </li>
                        </ul>
                    </form>
                </Modal.Section>
            </Modal>

            <div className="cc-info-wrapper">
                {(cardData.brand && cardData.last4 && cardData.exp_month && cardData.exp_year) 
                    ?
                    <>
                        <p className={'card-info ' + cardData.brand.toLowerCase().replace(/\s/g, '-')}>
                            {cardData.brand} ending in {cardData.last4}
                            <span className="expiration-date">Expires on {cardData.exp_month}/{cardData.exp_year}</span>
                        </p>
                        <p className="actions">
                            <button className="edit" onClick={toggleModal}>Edit</button>
                        </p>
                    </>
                    :
                    <p className="card-info">Unable to load card details</p>            
                }
            </div>
        </>
    )
}

export default injectStripe(LlamaCreditCardDetails);