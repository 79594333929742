const formatPercent = (value, moveDecimal) => {

    const float = value !== Infinity
        ? parseFloat(value)
        : 0.00;

    let fixedFloat = float.toFixed(2);

    if (isNaN(fixedFloat)) {
        return '0%';
    }

    if (moveDecimal) {
        fixedFloat = fixedFloat * 100; 
    }

    return `${fixedFloat}%`;

}

export default formatPercent;