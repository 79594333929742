/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 */

// REACT AND NPM IMPORTS
import React from 'react';
import {
    Route,
    Switch,
    withRouter,
    Redirect
} from 'react-router-dom';

import { connect } from 'react-redux';
import { AppProvider, Banner } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AuthPiece } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { STRIPE_KEY } from './config';

// REDUX STORE IMPORTS
import { getAffiliateData } from './store/actions/action_getAffiliate';
import { updateAffiliate } from './store/actions/action_updateAffiliate';
import { cognitoUserValidation } from './store/actions/get_cognito_user_validation';
import { getAuthData, updateAuthData } from './store/actions/auth-data';
import { getAppData } from './store/actions/action_getAppData';
import { setOpenSocket } from './store/actions/websocket';
import { getUnreadChatMessages, addMessageIdToUnread } from './store/actions/chat/action_chatMessages';

// UTIL IMPORTS
import { startPages } from './utils/dropdown-options';

// COMPONENT AND CONTAINER IMPORTS
// import OfferDetail from './containers/offer-detail';
import Dashboard from './containers/dashboard';
/* import Profile from './containers/profile';
import SocialNetworks from './containers/socialNetworks';
import Categories from './containers/categories';
import Payment from './containers/payment'; */
import Settings from './containers/settings';
// import Account from './containers/account';
// import Help from './containers/help';
/* import NotificationsSettings from './containers/notifications-settings';
import PixelSettings from './containers/pixel-settings';
import Social from './containers/social'; */
import Discover from './containers/discover';
// import ActiveOffers from './containers/active-offers';
import OfferDetailDiscover from './containers/offer-detail-discover';
import OfferDetail from './containers/offer-detail';
// import OfferView from './containers/offer-view';
import Onboarding from './containers/onboarding';
import Applications from './containers/applications';
import Invoices from './containers/invoices';
import Layout from './containers/layout';
import LlamaDmca from './components/llama-dmca/llama-dmca';
import LlamaTOS from './components/llama-tos/llama-tos';
import LlamaPrivacy from './components/llama-privacy/llama-privacy';
import SmartLink from './containers/smart-link';
import SmartLinkSubscription from './components/SmartLink/smart-link-subscription';
import SocialAuthComponent from './components/affiliate-auth/social-auth';
// import Notifications from './components/notifications/menu/notifications-sideMenu';
import HypeAuditorContainer from './containers/hype-auditor';
import TikTokContainer from './containers/hype-auditor-tiktok';
import HypeLocked from './components/HypeLocked/hype-locked';
import InitialPreloader from './components/initial-preloader/initial-preloader';
import PaymentPayPal from './components/affiliate-payment/paypal-payment';
import LeoHelpWidget from './components/help-widget/leo-help-widget';
import TikTokLocked from './components/TikTokLocked/tiktok-locked';
import DiscoverMerchantsContainer from './containers/discover-merchants';
import Chat from './containers/chat';
import BecomeAmbassador from './containers/become-ambassador';
import Achievements from './containers/achievements';

import './App.css';
import '@shopify/polaris/styles.css';

class App extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['signedIn'];

        this.state = {
            userAuthDataLoading: true,
            userAuthDataLoadingOnMount: false,

            authData: props.authData,
            showToast: false,
            showContainerOnly: false,
            driftOpen: false,
            redirectsChecked: false,

            showPreloader: true
        };
        this.getAuthData = this.getAuthData.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    getStartPageFromSession = () => {
        // if they signed up from a landing page reference, get it
        let landingPageRef = sessionStorage.getItem('landingPageRef');
        // change dash case to camel case
        if (landingPageRef) {
            const landingPageRefArr = landingPageRef.split('-');
            if (landingPageRefArr.length > 1) {
                landingPageRef = landingPageRefArr.reduce((acc, word, index) => {
                    if (index > 0) {
                        return `${acc}${word.substr(0, 1).toUpperCase()}${word.substr(1)}`;
                    }
                    return word;
                }, '');
            }
        }

        // match the landingPageRef with our predefined list
        return startPages.find((page) => {
            return landingPageRef === page.value;
        });
    }

    checkOnboardingRedirect = (affiliate) => {
        console.log(affiliate);
        if (affiliate.value.data.affiliateById.account_type === 'smartlinks') {
            return null;
        }

        let redirectsChecked = false;
        const signupStep = affiliate.value.data.affiliateById.sign_up_flow;
        const onboardingStep = parseInt(signupStep, 10) + 1;
        if ([false, 'false', null].includes(signupStep)) {
            this.props.history.push('/signUp/1');
            redirectsChecked = true;
        // Check for onboarding_complete status instead of step number on affiliate
        // If not complete, redirect to their current step
        } else if (affiliate.value.data.affiliateById.onboarding_complete !== true) {
            this.props.history.push(`/signUp/${onboardingStep}`);
            redirectsChecked = true;
        }
        return this.setState({ redirectsChecked });
    }

    checkStartPageRedirect = (affiliate, startPage) => {
        // Check if we should redirect them to a different start page
        if (!this.state.redirectsChecked) {
            const startPageValue = startPage || affiliate.value.data.affiliateById?.start_page;

            if (startPageValue && this.props.history.location.pathname === '/') {
                const startPageUrl = startPages.find((page) => {
                    return startPageValue === page.value;
                });
                if (startPageUrl) {
                    this.props.history.push(`/${startPageUrl.value}`);
                }
            }
            this.setState({ redirectsChecked: true });
        }

        if (!this.state.redirectsChecked && affiliate.value.data.affiliateById?.start_page && this.props.history.location.pathname === '/') {
            const startPageUrl = startPages.find((page) => {
                return affiliate.value.data.affiliateById.start_page === page.value;
            });
            if (startPageUrl) {
                this.props.history.push(`/${startPageUrl.value}`);
            }
        }
        this.setState({ redirectsChecked: true });
    }

    setupWebsocket = () => {
        return new Promise((resolve, reject) => {
            // start a websocket connection for chat online status and in app notifications
            if (this.props.affiliate._id && !this.props.openSocket) {
                this.props.dispatchSetOpenSocket(this.props.affiliate._id);

                this.props.openSocket.addEventListener('message', (response) => {
                    const reply = JSON.parse(response.data);
                    if (reply.action === 'newMessage' && reply.sender === 'advertiser') {
                        this.props.dispatchAddMessageIdToUnread(reply.advertiser_id, reply._id);

                        // if not on chat page, spawn a notification toast
                        /* TODO if (!this.props.history.location.pathname.includes('/chat')) {
                            alert('new message');
                        } */
                    }
                });
            }
            return this.props.dispatchGetUnreadChatMessages(this.props.affiliate._id)
                .then(() => {
                    return resolve('');
                })
                .catch(() => {
                    return reject(new Error('unable to get unread chat messages'));
                });
        });
    }

    async getAuthData() {
        this.setState({ userAuthDataLoading: true });
        
        try {
            const { value: user } = await this.props.getAuthData();
            
            // if cognito group in session is not affiliates, log the user out
            if (!user.signInUserSession.accessToken.payload['cognito:groups'].includes('Affiliates')) {
                Auth.signOut();
                window.location.reload();
            }
            
            this.setState({ /* userAuthDataLoaded: true, */ userAuthDataLoading: false, authData: user });
            
            if (user.username) {
                // get affiliate details (2nd argument is for promotion validation)
                const affiliate = await this.props.get_affiliate(user.username, true);
                this.setState({ affiliateDataLoaded: true, getAffiliateLoading: false });
                
                // if there's no affiliate data found, show an error
                if (!affiliate?.value?.data?.affiliateById) {
                    this.toggleToast();
                    throw new Error('affiliate not found');
                }

                const startPage = this.getStartPageFromSession();
                
                // Update affiliate data with new jwtToken.
                const data = {
                    account: {
                        cognito: {
                            accessToken: user.signInUserSession.accessToken.jwtToken,
                            idToken: user.signInUserSession.idToken.jwtToken,
                            refreshToken: user.signInUserSession.refreshToken.token
                        }
                    }
                };
                
                // set default account type
                if (!affiliate.value.data.affiliateById.account_type) {
                    data.account_type = 'ambassador';
                }
                
                // add landing page related values to data
                if (startPage?.value) {
                    data.start_page = startPage.value;
                    data.signup_lp = startPage.value;
                    if (startPage.value === 'smartlinks') {
                        data.account_type = 'smartlinks';
                    }
                    sessionStorage.removeItem('landingPageRef');
                }
                
                await this.props.update_affiliate(data, affiliate.value.data.affiliateById.cognito_id);
                
                // Check if we should redirect to onboarding
                if (startPage?.value !== 'smartlinks') {
                    this.checkOnboardingRedirect(affiliate);
                }
                // Check if we should redirect to their saved page
                this.checkStartPageRedirect(affiliate, startPage?.value);

                // connect to the websocket
                await this.setupWebsocket();
                return this.setState({ showPreloader: false, userAuthDataLoadingOnMount: false });
            }
            return this.setState({ showPreloader: false, userAuthDataLoadingOnMount: false });
        } catch (error) {
            return this.setState({ userAuthDataLoading: false, userAuthDataLoadingOnMount: false });
        }
    }

    /**
    * @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
    * @function getDerivedStateFromProps
    * @description Check if new coming props have different authData compared to the previous state then update it with new one
    * @param {Object} nextProps new props receving by component
    * @param {Object} prevState previous state of component
    * @returns {Object} null or updated state
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.authData !== prevState.authData) {
            return { authData: nextProps };
        }

        return null;
    }

    componentDidMount() {
        // ignore api calls when loading PayPal child window at /payment/paypal/ app route
        if (this.props.location.pathname !== '/payment/paypal/') {
            this.setState({ userAuthDataLoadingOnMount: true });
            this.getAuthData();
            this.checkAuthData(this.props);
        } else {
            // showContainerOnly:true will not load header bar, side-navigation bar and app notification drawer
            // refer the code in the render() of src/containers/layout.js
            this.setState({ showContainerOnly: true, showPreloader: false });
        }

        // Add function to execute on every route change.
        this.unlisten = this.props.history.listen(() => {
            this.onRouteChanged();
        });

        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(`${STRIPE_KEY}`) });
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({ stripe: window.Stripe(`${STRIPE_KEY}`) });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.history.action === 'PUSH' && this.props.location.pathname !== prevProps.location.pathname) {
            const scrollTop = (this.props.history.location?.state?.scrollTop)
                ? this.props.history.location.state.scrollTop
                : 0;
            window.scrollTo(0, scrollTop);
        }

        if (prevProps.authData !== this.props.authData && !this.state.userAuthDataLoadingOnMount) {
            this.getAuthData();
            this.checkAuthData(this.props);
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    checkAuthData(props) {
        if (!this.props.storedAuthData && props.authData) {
            this.props.updateAuthData(props.authData);
        }
        if (props.authState === 'signedIn') {
            this.props.getAppData();
        }
    }

    onRouteChanged() {
        if (this.state.userAuthDataLoading) {
            return;
        }

        if (
            !this.props.storedAuthData
            || !this.props.storedAuthData.signInUserSession
            || !this.props.storedAuthData.signInUserSession.accessToken
            || !this.props.storedAuthData.signInUserSession.accessToken.jwtToken
        ) {
            this.signOut();
        }

        if (process.env.NODE_ENV === 'development') {
            return;
        }

        this.props.get_cognito_User_Validation(this.props.storedAuthData.signInUserSession.accessToken.jwtToken)
            .then((result) => {
                if (result.action.payload.data.Username === this.props.storedAuthData.username) {
                    return;
                }
                this.signOut();
            });
    }

    toggleToast = () => {
        this.setState({ showToast: true }, () => {
            setInterval(this.signOut, 4000);
        });
    }

    signOut() {
        // disconnect websocket connection
        if (this.props.openSocket) {
            this.props.openSocket.close();
        }
        Auth.signOut();
        sessionStorage.removeItem('username');
    }

    showComponent() {
        // show the user a preloader until we've determined their onboarding status

        // showContainerOnly:true will not load header bar, side-navigation bar and app notification drawer
        // refer the code in the render() of src/containers/layout.js
        if (this.state.showPreloader) {
            return <InitialPreloader />;
        }
        // const { authData } = this.state;
        // const email = authData && authData.attributes && authData.attributes.email;

        const { showToast } = this.state;
        const toastMarkup = showToast
            ? (<Banner title="System Error" status="critical">Missing information caused an error in our system. Our support team has been notified.</Banner>)
            : null;

        const firstName = (this.props.affiliate && this.props.affiliate.account && this.props.affiliate.account.personal && this.props.affiliate.account.personal.first_name)
            ? this.props.affiliate.account.personal.first_name
            : null;
        const cognito_id = (this.props.affiliate && this.props.affiliate.cognito_id)
            ? this.props.affiliate.cognito_id
            : null;

        return (
            <AppProvider i18n={enTranslations}>
                <Layout {...this.state} history={this.props.history} signOut={this.signOut}>
                    {toastMarkup}
                    <div className="App">
                        <Helmet>
                            <script>
                                {`
                                    var driftApi = null;
                                    !function () {
                                    var t;
                                    if (t = window.driftt = window.drift = window.driftt || [], !t.init) return t.invoked ? void (window.console && console.error && console.error("Drift snippet included twice.")) : (t.invoked = !0,
                                        t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"],
                                        t.factory = function (e) {
                                        return function () {
                                            var n;
                                            return n = Array.prototype.slice.call(arguments), n.unshift(e), t.push(n), t;
                                        };
                                        }, t.methods.forEach(function (e) {
                                        t[e] = t.factory(e);
                                        }), t.load = function (t) {
                                        var e, n, o, i;
                                        e = 3e5, i = Math.ceil(new Date() / e) * e, o = document.createElement("script"),
                                            o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + i + "/" + t + ".js",
                                            n = document.getElementsByTagName("script")[0], n.parentNode.insertBefore(o, n);
                                        });
                                    }();
                                    drift.SNIPPET_VERSION = '0.3.1';
                                    drift.reset();
                                    drift.load('5uggc9iy5imi');
                                    drift.on('ready', (driftObj) => {
                                        if('${firstName}' !== 'null'){
                                            drift.identify('${cognito_id}', {
                                                email: '${cognito_id}',
                                                first_name: '${firstName}'
                                            })
                                        }
                                    })
                                `}
                            </script>
                        </Helmet>
                        <div className="App__Main">
                            <Switch>
                                <Route path="/" exact component={Discover} />
                                <Route path="/settings" component={Settings} />
                                <Route path="/dashboard" exact component={Dashboard} />
                                {/* this route is in Settings <Route path="/account" exact component={Account} /> */}
                                {/* this route is in Settings <Route path='/account/social/:type' component={Social} /> */}
                                {/* this route is in Settings <Route path="/payment" exact component={Payment} /> */}
                                {/* this route is in Settings <Route path="/payment/auth/" component={Payment} exact /> */}
                                <Route path="/payment/paypal/" component={PaymentPayPal} exact />
                                {/* component is empty <Route path="/profile" exact component={Profile} /> */}
                                {/* component is empty <Route path="/categories" exact component={Categories} /> */}
                                {/* this route is in Settings <Route path="/social" exact component={SocialNetworks} /> */}
                                {/* applications and offers have been merged together <Route path="/offers" exact component={ActiveOffers} /> */}
                                {/* <Route path="/offer/:id" component={OfferView} /> */}
                                <Route path="/discover" exact component={Discover} />
                                {/* <Route path="/discover/offer/:id" exact component={OfferDetail} /> */}
                                <Route path="/discover/offer/:id" exact component={OfferDetailDiscover} />
                                <Route path="/discover/brand/:id" exact component={DiscoverMerchantsContainer} />
                                <Route path="/offer/:id" component={OfferDetail} />
                                <Redirect from="/offer/" to="/offers" exact />
                                <Redirect from="/discover/offer/" to="/discover" exact />
                                {/* this route contains lipsum text and is not needed because we have the help popout <Route path="/help" exact component={Help} /> */}
                                {/* this route is in Settings <Route path="/pixels" exact component={PixelSettings} /> */}
                                {/* this route is in Settings <Route path="/notifications" component={NotificationsSettings} exact /> */}
                                <Route path="/offers" exact component={Applications} />
                                <Redirect from="/applications" to="/offers" exact />
                                {/* <Route path="/alerts" render={(props) => <Notifications {...props} open={true} isMobile={true} />} exact /> */}
                                <Route path="/invoices" exact component={Invoices} />
                                <Route path="/signUp/:id" exact component={Onboarding} />
                                <Route path="/dmca" component={LlamaDmca} exact />
                                <Route path="/tos" exact component={LlamaTOS} />
                                <Route path="/privacy" exact component={LlamaPrivacy} />
                                <Route path="/social/callback/:type" exact component={SocialAuthComponent} />
                                <Route path="/instaAnalytics" exact component={HypeAuditorContainer} />
                                <Route path="/tiktokAnalytics" exact component={TikTokContainer} />
                                <Route path="/instaAnalyticslocked" exact component={HypeLocked} />
                                <Route path="/tiktoklocked" exact component={TikTokLocked} />
                                <Redirect from="/hype" to="/instaAnalytics" exact />
                                <Redirect from="/hypelocked" to="/instaAnalyticslocked" exact />
                                <Route path="/become-an-ambassador" exact component={BecomeAmbassador} />
                                <Route path="/chat" exact component={Chat} />
                                <Route path="/chat/:advertiser_id" exact component={Chat} />
                                <Route path="/achievements" exact component={Achievements} />
                                <StripeProvider stripe={this.state.stripe}>
                                    <Elements>
                                        <>
                                            <Route path="/smartlinks" exact component={SmartLink} />
                                            <Route path="/smartlinks/customize" render={(props) => { return <SmartLink {...props} currentTab="CUSTOMIZE" />; }} />
                                            <Route path="/smartlinks/plans" exact render={(props) => { return <SmartLink {...props} currentTab="PLANS" />; }} />
                                            <Route path="/smartlinks/billing" exact render={(props) => { return <SmartLink {...props} currentTab="BILLING" />; }} />
                                            <Route path="/smartlinks/analytics" exact render={(props) => { return <SmartLink {...props} currentTab="ANALYTICS" />; }} />
                                            <Route path="/smartlinks/upgrade/pro" exact component={SmartLinkSubscription} />
                                        </>
                                    </Elements>
                                </StripeProvider>
                            </Switch>
                        </div>
                    </div>
                    <LeoHelpWidget
                        currentPath={this.props.location.pathname}
                        name={firstName}
                    />
                </Layout>
            </AppProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate,
        status: state.affiliate.status,
        storedAuthData: state.authData,
        openSocket: state.app.openSocket
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_affiliate: (id, promoStatus) => { return dispatch(getAffiliateData(id, promoStatus)); },
        update_affiliate: (data, id) => { return dispatch(updateAffiliate(data, id)); },
        get_cognito_User_Validation: (token) => { return dispatch(cognitoUserValidation(token)); },
        getAuthData: () => { return dispatch(getAuthData()); },
        updateAuthData: (authData) => { return dispatch(updateAuthData(authData)); },
        getAppData: () => { return dispatch(getAppData()); },
        dispatchSetOpenSocket: (affiliate_id) => { return dispatch(setOpenSocket(affiliate_id)); },
        dispatchGetUnreadChatMessages: (affiliate_id) => { return dispatch(getUnreadChatMessages(affiliate_id)); },
        dispatchAddMessageIdToUnread: (senderId, messageId) => { return dispatch(addMessageIdToUnread(senderId, messageId)); }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
