/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import { combineReducers } from 'redux';

import getAffiliateReducer from './reducer_getAffiliate';
import authDataReducer from './reducer_auth';
import appReducer from './reducer_app';
import offerReducer from './reducer_offers';
import updateTheme from './reducer_smartlink_theme';
import updateOffers from './reducer_updated_offer';
import filtersReducer from './reducer_filters';
import smartlinksReducer from './reducer_smartlinks';
import chatReducer from './reducer_chat';
import achievementsReducer from './reducer_achievements';
import achievementNotificationsReducer from './reducer_achievement-notifications';

const rootReducer = combineReducers({
    affiliate: getAffiliateReducer,
    app: appReducer,
    authData: authDataReducer,
    offers: offerReducer,
    themeConfig: updateTheme,
    updatedoffer: updateOffers,
    filtersReducer,
    smartlinks: smartlinksReducer,
    chat: chatReducer,
    achievements: achievementsReducer,
    achievementNotifications: achievementNotificationsReducer
});

export default rootReducer;
