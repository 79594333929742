import { DISCOVER_FILTERS } from '../actions/action_updateDiscoverFilters';

const defaultState = {
    discoverFilters: {}
}

const filtersReducer = (state = defaultState, action) => {

    const { type } = action;

    switch (type) {

        case DISCOVER_FILTERS: {
            if (!(typeof action.payload === 'object')) {
                return state;
            }
            return { ...state, discoverFilters: action.payload }
        }

        default: {
            return state;
        }
    }

}

export default filtersReducer;