import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Select, TextField } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { updateSmartLinkPreview } from '../../../store/actions/action_updateSmartlink';
import { updateSocialProfile } from '../../../store/actions/action_updateSmartlinkSocialProfile';

import SmartlinksSocialSkeleton from './smart-link-social-skeleton';

import './smart-link-social.css';

const platforms = [
    {
        type: 'instagram',
        name: 'Instagram',
        helpPhrase: 'Instagram account'
    },
    {
        type: 'facebook',
        name: 'Facebook Page',
        helpPhrase: 'Facebook Page'
    },
    {
        type: 'twitter',
        name: 'Twitter',
        helpPhrase: 'Twitter account'
    },
    {
        type: 'youtube',
        name: 'YouTube Channel',
        helpPhrase: 'YouTube channel'
    },
    {
        type: 'tiktok',
        name: 'TikTok',
        helpPhrase: 'TikTok account'
    }
];

const SmartLinkSocial = (props) => {
    const { smartlinks: smartlinkProps, affiliate, dispatchUpdateSocialProfile, dispatchUpdateSmartLinkPreview, setToastVerbiage } = props;

    const [accountOptions, setAccountOptions] = useState({
        instagram: [{ label: 'Select an Instagram account...', value: '' }],
        facebook: [{ label: 'Select a Facebook Page...', value: '' }],
        twitter: [{ label: 'Select a Twitter account...', value: '' }],
        youtube: [{ label: 'Select a YouTube channel...', value: '' }],
        tiktok: [{ label: 'Select a TikTok account...', value: '' }]
    });
    const [accounts, setAccounts] = useState({
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        tiktok: '',
        soundcloud: ''
    });

    const [fieldLoading, setFieldLoading] = useState('');
    const [saveButton, setSaveButton] = useState('');

    useEffect(() => {
        if (smartlinkProps.loaded) {
            let igAccounts = [{ label: 'Select an Instagram account...', value: '' }];
            let fbAccounts = [{ label: 'Select a Facebook Page...', value: '' }];
            let twitterAccounts = [{ label: 'Select a Twitter account...', value: '' }];
            let ytAccounts = [{ label: 'Select a YouTube channel...', value: '' }];
            let tiktokAccounts = [{ label: 'Select a TikTok account...', value: '' }];

            // instagram
            if (affiliate.instagramDataV2 && affiliate.instagramDataV2.length > 0) {
                igAccounts = affiliate.instagramDataV2.reduce((acc, account) => {
                    acc.push({
                        label: account.full_name,
                        value: account.username
                    });
                    return acc;
                }, igAccounts);
            }

            // facebook
            if (affiliate.facebookData && affiliate.facebookData.length > 0) {
                fbAccounts = affiliate.facebookData.reduce((acc, account) => {
                    account.pageStats.forEach((pageInfo) => {
                        acc.push({
                            label: pageInfo.name,
                            value: pageInfo.id
                        });
                    });
                    return acc;
                }, fbAccounts);
            }

            // twitter
            if (affiliate.twitterData && affiliate.twitterData.length > 0) {
                twitterAccounts = affiliate.twitterData.reduce((acc, account) => {
                    acc.push({
                        label: account.user.name,
                        value: account.user.screenName
                    });
                    return acc;
                }, twitterAccounts);
            }

            // youtube
            if (affiliate.youTubeData && affiliate.youTubeData.length > 0) {
                ytAccounts = affiliate.youTubeData.reduce((acc, account) => {
                    account.channelStats.forEach((channelInfo) => {
                        acc.push({
                            label: channelInfo.name,
                            value: `channel/${channelInfo.id}`
                        });
                    });
                    return acc;
                }, ytAccounts);
            }

            // tiktok
            if (affiliate.tikTokData && affiliate.tikTokData.length > 0) {
                tiktokAccounts = affiliate.tikTokData.reduce((acc, account) => {
                    acc.push({
                        label: account.basic.title,
                        value: account.basic.username
                    });
                    return acc;
                }, tiktokAccounts);
            }

            setAccountOptions({
                instagram: igAccounts,
                facebook: fbAccounts,
                twitter: twitterAccounts,
                youtube: ytAccounts,
                tiktok: tiktokAccounts
            });

            console.log('SETTING STUFF...');

            setAccounts({ ...smartlinkProps.socialProfiles });
        }
    }, [smartlinkProps.loaded]);

    const cancelAccountChange = (platform) => {
        console.log(platform)
        setAccounts({
            ...accounts,
            [platform]: smartlinkProps.socialProfiles[platform]
        });
        setSaveButton('');
        dispatchUpdateSmartLinkPreview(null);
    };

    const handleAccountChange = (value, platform) => {
        if (saveButton !== platform) {
            setSaveButton(platform);
            setAccounts({
                ...accounts,
                [saveButton]: smartlinkProps.socialProfiles[saveButton],
                [platform]: value
            });
        } else {
            setAccounts({
                ...accounts,
                [platform]: value
            });
        }
        dispatchUpdateSmartLinkPreview({ [platform]: value });
    };

    const saveAccount = (platform, e) => {
        if (e) {
            e.preventDefault();
        }

        if (accounts[platform] !== smartlinkProps.socialProfiles[platform]) {
            setFieldLoading(platform);

            const profile = {
                network: platform,
                handle: accounts[platform]
            };
            return dispatchUpdateSocialProfile(affiliate.cognito_id, profile)
                .then(() => {
                    setFieldLoading(null);
                    setSaveButton('');
                    dispatchUpdateSmartLinkPreview(null);
                    setToastVerbiage('Saved');
                });
        }
        return null;
    };

    if (!smartlinkProps.loaded) {
        return <SmartlinksSocialSkeleton />;
    }

    return (
        <>
            <fieldset>
                <legend>Social Accounts</legend>
                <ul id="smartlink-customize-social-list">
                    {platforms.map((platform) => {
                        return (
                            <li key={platform.type} className={platform.type}>
                                <Select
                                    label={platform.name}
                                    options={accountOptions[platform.type]}
                                    value={(accounts[platform.type]) ? accounts[platform.type] : ''}
                                    onChange={(value) => { return handleAccountChange(value, platform.type); }}
                                    disabled={fieldLoading === platform.type}
                                    helpText={<>To connect a new {platform.helpPhrase}, go to your <Link to="/settings/social">Settings</Link></>}
                                />
                                {saveButton === platform.type
                                    && (
                                        <div className="button-group">
                                            <button type="button" className="cancel" onClick={() => { return cancelAccountChange(platform.type); }}>Cancel</button>
                                            <LlamaButton
                                                type="submit"
                                                loading={fieldLoading === platform.type}
                                                disabled={fieldLoading === platform.type}
                                                onClick={() => { return saveAccount(platform.type); }}
                                            >
                                                Save
                                            </LlamaButton>
                                        </div>
                                    )
                                }
                            </li>
                        );
                    })}

                    <li className="soundcloud">
                        <form onSubmit={(e) => { return saveAccount('soundcloud', e); }}>
                            <TextField
                                label="SoundCloud"
                                prefix="https://soundcloud.com/"
                                value={accounts.soundcloud}
                                onChange={(value) => { return handleAccountChange(value, 'soundcloud'); }}
                                disabled={fieldLoading === 'soundcloud'}
                            />
                            {saveButton === 'soundcloud'
                                && (
                                    <div className="button-group">
                                        <button type="button" className="cancel" onClick={() => { return cancelAccountChange('soundcloud'); }}>Cancel</button>
                                        <LlamaButton
                                            type="submit"
                                            loading={fieldLoading === 'soundcloud'}
                                            disabled={fieldLoading === 'soundcloud'}
                                            onClick={() => { return saveAccount('soundcloud'); }}
                                        >
                                            Save
                                        </LlamaButton>
                                    </div>
                                )
                            }
                        </form>
                    </li>
                </ul>
            </fieldset>
        </>
    );
};

SmartLinkSocial.propTypes = {
    
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchUpdateSmartLinkPreview: (data) => { return dispatch(updateSmartLinkPreview(data)); },
        dispatchUpdateSocialProfile: (id, profile) => { return dispatch(updateSocialProfile(id, profile)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartLinkSocial);
