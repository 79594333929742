import React from 'react';

import { ResponsivePie } from '@nivo/pie';

import numberFormat, {moneyFormat} from '../../utils/number-format';

const LTVStats = (props) => {
    const showPie = (props.chartData[0].value > 0 || props.chartData[1].value > 0)

    return (
        <section className="dashboard--ltv" data-test="ltv-stats-container">
            <h2 data-test="ltv-stats-title">Long Term Value (LTV) Stats</h2>
            <p className="info" data-test="ltv-stats-info">See how well you&rsquo;ve nurtured your referred customers for each brand you&rsquo;re working with.</p>
            {showPie && (
                <div className="nivo-pie" data-test="ltv-stats-graph-container">
                    <ResponsivePie
                        data={props.chartData}
                        margin={props.chartOptions.margin}
                        innerRadius={.5}
                        borderWidth={0}
                        enableRadialLabels={false}
                        colors={['#fd2a54', '#fda63d']}
                        slicesLabelsTextColor='#000'
                        sliceLabel={d => numberFormat(d.value)}
                        tooltip={(datumProp) => {
                            const { datum } = datumProp;
                            const { data, color } = datum;
                            const { value, label } = data;
                            return (<span style={{ display: 'flex', whiteSpace: 'pre', alignItems: 'center' }}><span style={{ display: 'block', width: 12, height: 12, marginRight: 7, background: color }}>{' '}</span>{label}: {numberFormat(value)}</span>)
                        }}
                        legends={[
                            {
                                anchor: props.chartOptions.legendAnchor,
                                direction: props.chartOptions.legendDirection,
                                symbolShape: 'circle',
                                itemWidth: 130,
                                itemHeight: props.chartOptions.legendItemHeight,
                                symbolSize: 15,
                                translateY: props.chartOptions.legendHeight,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
            )}
            <table data-has-pie={showPie} data-test="ltv-stats-table">
                <thead>
                    <tr>
                        <th>Brand</th>
                        <th className="count">One-Time Customers</th>
                        <th className="count">Repeat Customers</th>
                        <th className="amount">LTV</th>
                    </tr>
                </thead>
                <tbody>
                    {props.stats.map(stat => {
                        return (
                            <tr key={stat.advertiser_id}>
                                <td className="merchant">
                                    {stat.name}
                                    <span className="subcell">{stat.numOffers} offer{stat.numOffers !== 1 ? 's' : ''}</span>
                                </td>
                                <td className="count one-time">{numberFormat(stat.oneTimeCustomers)}</td>
                                <td className="count repeat">{numberFormat(stat.repeatedCustomers)}</td>
                                <td className="amount total">{moneyFormat(stat.ltv)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </section>
    )
}

export default LTVStats;