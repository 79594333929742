import axios from 'axios';

import { NEXT_APP_API_URL } from '../../config';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_FULFILLED = `${GET_NOTIFICATIONS}_FULFILLED`;
export const GET_NOTIFICATIONS_PENDING = `${GET_NOTIFICATIONS}_PENDING`;
export const GET_NOTIFICATIONS_REJECTED = `${GET_NOTIFICATIONS}_REJECTED`;
export const UPDATE_NOTIFICATIONS_VIEW_STATUS = 'UPDATE_NOTIFICATIONS_VIEW_STATUS'

const markNotificationsAsSeen = (notification_ids, recipient) => {

    if (!notification_ids || !recipient) {
        return null;
    }

    const query = `
        mutation markNotificationAsSeen($notification_ids: [String!]!, $recipient: NotificationRecipientInput) {
            markNotificationsAsSeen(notification_ids: $notification_ids, recipient: $recipient) {
                notification_id
                view_status
            }
        }
    `

    const variables = {
        notification_ids,
        recipient
    }

    const payload = {
        query,
        variables
    }

    const headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data)
    
}

const updateNotificationsViewStatus = (notifications) => {
    return {
        type: UPDATE_NOTIFICATIONS_VIEW_STATUS,
        payload: notifications
    }
}

const getNotifications = (affiliate_id) => {

    if (!affiliate_id) {
        return Promise.reject(new Error('No affiliate_id.'));
    }

    const query = `
        query getNotifications($affiliate_id: String) {
            advertiserById(affiliate_id: $affiliate_id) {
                notifications {
                    _id
                    notification_type
                    recipient_type
                    recipient_id
                    view_status
                    title
                    note
                    created_at
                    last_modified
                    ttl {
                        start
                        end
                    }
                    targets {
                        target_type
                        target_id
                    }
                    target_data {
                        offer {
                            name
                        }
                        application {
                            application_id
                        }
                        affiliate {
                            name
                        }
                    }
                }
            }
        }
    `

    const variables = {
        affiliate_id
    }

    const payload = {
        query,
        variables
    }

    const headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: GET_NOTIFICATIONS,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data)
    }

}

export {
    markNotificationsAsSeen,
    getNotifications,
    updateNotificationsViewStatus
}