/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React, {Component} from 'react';
import './affiliate-payment.css';
import {connect} from 'react-redux';
import {
    Heading,
    Spinner
} from '@shopify/polaris';
import { isEqual } from 'lodash';
import { withRouter } from "react-router";
import { getPaypalAuthUrl } from '../../store/actions/payments/payment-url';
import { updateAffiliatePayments } from '../../store/actions/payments/payment-update';
import { getAffiliateData } from "../../store/actions/action_getAffiliate";

export class Payment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            savedAffiliate: props.affiliate,
            paypalClicked: false,
            connectedToPaypal: false,
            paypalEmail: null,
            authUrl: '',
            code: '',
            loggedIn: false
        }

        this.paypalLogin = this.paypalLogin.bind(this);

        this.props.getPaypalAuthUrl().then((result) => {
            this.setState({authUrl: result})
        });
    }

    componentWillMount() {
        if(sessionStorage.getItem('username')) {
            this.props.getAffiliate(sessionStorage.getItem('username'))
                .then((response) => {
                    this.getPaypalEmail();
                });
        }
    }

    /**
	* @author Harshal Mahajan <Harshal.Mahajan@harbingergroup.com>
	* @function getDerivedStateFromProps
	* @description Check if new coming props have different affiliate compared to the previous state then update it with new one 
	* @param {Object} nextProps new props receving by component
	* @param {Object} prevState previous state of component
	* @returns {Object} null or updated state 
	*/
	static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.affiliate && !isEqual(nextProps.affiliate, prevState.savedAffiliate)) {
            return {
                savedAffiliate: nextProps.affiliate
            };
        }

        return null;
    }

    componentDidMount() {
        if (this.props.affiliate.cognito_id) {
            this.props.getAffiliate(this.props.affiliate.cognito_id);
            this.getPaypalEmail();
            sessionStorage.setItem('username', this.props.affiliate.cognito_id);
        }
    }

    componentDidUpdate(prevProps) {
        if(!isEqual(prevProps.affiliate, this.props.affiliate)) {
            this.getPaypalEmail();
            sessionStorage.setItem('username', this.props.affiliate.cognito_id);
        }
    }

    componentWillUnmount() {
        //remove the username data from sessionstorage
        sessionStorage.removeItem('username');
    }

    paypalLogin() {
        var l = (window.screen.width / 2) - 200;
        var t = (window.screen.height / 2) - 275;

        //change the client_id and make sure the redirect_uri is defined in your paypal app settings
        var paypal_auth = window.open(this.state.authUrl.value.url, 'Login with Paypal', 'width=600,height=750,left=' + l + ',top=' + t);
        this.setState({
            paypalClicked: true
        })

        var checkWindow = setInterval(() => {
            if (paypal_auth.closed) {
                clearInterval(checkWindow);
                console.log('Paypal window close called');
                this.props.getAffiliate(sessionStorage.getItem('username'))
                .then((response) => {
                    console.log('RESPONSE::', response)
                    this.setState({
                        ...this.state,
                        savedAffiliate: response.value.data.affiliateById
                    });
                    this.getPaypalEmail();
                })
            }
        }, 1000)
    }

    getPaypalEmail() {
        console.log('this.state.savedAffiliate', this.state.savedAffiliate);
        //connect to db here - function should return whether they have a connected paypal and their email address
        if (this.state.savedAffiliate && this.state.savedAffiliate.account && this.state.savedAffiliate.account.paypal && this.state.savedAffiliate.account.paypal.refresh) {
            this.setState({
                connectedToPaypal: true,
                paypalEmail: this.state.savedAffiliate.account.paypal.user
            })
        } else {
            this.setState({
                connectedToPaypal: false,
                paypalEmail: null,
                paypalClicked: false
            })
        }
    }

    revokePaypal(e) {
        e.preventDefault();
        let payload = {
            account: {
                paypal: {}
            }
        };

        this.props.updateAffiliatePayments(payload, this.props.affiliate.cognito_id).then((res) => {
            this.setState({
                connectedToPaypal: false,
                paypalEmail: null,
                paypalClicked: false
            })
        });
    }

    renderButtonContainer = () => {
        let renderContainerHtml = null;

        if (this.props.location.pathname === '/settings/payment') {
            renderContainerHtml = (
                <div className="settings-payment__wrapper">
                    <Heading>Paypal Settings</Heading>
                    <div className="paypal-button-container">
                        {this.state.connectedToPaypal
                            ? <p>Connected to PayPal as {this.state.paypalEmail} <span onClick={this.revokePaypal.bind(this)} className="settings-payment__disconnect">Disconnect</span></p>
                            : (
                                <p>
                                    {!this.state.paypalClicked
                                        ? <img src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/lipp/loginwithpaypalbutton.png" alt="Login with PayPal" onClick={this.paypalLogin.bind(this)} />
                                        : <Spinner size="small" />
                                    }
                                </p>
                            )
                        }
                    </div>
                </div>
            );
        } else {
            renderContainerHtml = (
                <div>
                    {this.state.connectedToPaypal
                        ? <p className="onboarding--paypal-connected">Connected to PayPal as {this.state.paypalEmail} <span className="settings-payment__disconnect" onClick={this.revokePaypal.bind(this)}>Disconnect</span></p>
                        : (
                            <p className="pplogin">
                                {this.state.authUrl
                                    ? <img src="https://www.paypalobjects.com/webstatic/en_US/developer/docs/lipp/loginwithpaypalbutton.png" alt="Login with PayPal" onClick={this.paypalLogin.bind(this)} />
                                    : <Spinner size="small" />
                                }
                            </p>
                        )
                    }
                </div>
            );
        }

        return renderContainerHtml;
    }

    render() {
        return this.renderButtonContainer();
    }
}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAffiliate: (id) => dispatch(getAffiliateData(id)),
        getPaypalAuthUrl: () => dispatch(getPaypalAuthUrl()),
        updateAffiliatePayments: (payload, affiliate) => dispatch(updateAffiliatePayments(payload, affiliate))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));