import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Frame,
    Toast,
    Banner,
    Modal,
    ChoiceList,
    Select
} from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';
import { connect } from 'react-redux';

import { createYoutubeAffData } from '../../../store/actions/social/create-youtube';
import { unlinkYouTubeAffiliate } from '../../../store/actions/action_unlinkYoutubeAffiliate';
import { updateAffiliate } from '../../../store/actions/action_updateAffiliate';
import { getSocialRedirectUrl } from '../../../store/actions/social/getSocialRedirect';
import { setFeaturedChannel } from '../../../store/actions/social/set-featured-page';

import CenteredSpinner from '../../llama/centered-spinner';
import EmptyComponentState from '../../EmptyComponent/emptyState';
import SocialAuthComponent from '../../affiliate-auth/social-auth';
import { getGradeValues } from '../engagement-score/engagement-score';

import numberFormat from '../../../utils/number-format';

import '../affiliate-social.css';
import './youtube.css';

class YoutubeComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accounts: this.props.affiliate.youTubeData,
            refreshingAccount: false,
            isLoading: false,
            socialAuthData: {},
            isAuthWindowOpen: false,
            engagementScore: 0,

            accountToUnlink: null,
            unlinkConfirmationOpen: false,
            unlinking: false,

            setFeaturedChannelOpen: false,
            featuredChannelChoices: [],
            featuredChannelSelection: '',
            featuredChannel: '',
            savingFeaturedChannel: false,

            // defines the id of which channel is currently being viewed in the top performing videos section
            performanceChannelChoices: [],
            performanceChannel: ''
        };

        this.emptyStateConfig = {
            platform: 'youtube',
            heading: 'Link Your YouTube Channel',
            description: 'Find out which videos are your most commented, most liked, and most watched ones for each channel you connect.',
            linkSocialAccount: true
        };

        this.errMsg = 'Oops, something went wrong. Please try again later.';
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.getSocialRedirectUrl('youtube')
            .then((result) => {
                this.setState({
                    socialAuthData: {
                        redirectUrl: result.value.data.location
                    }
                });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                console.log('Error: Unable to get redirect URL for youtube', error);
            });

        this.setState({
            featuredChannelSelection: this.state.featuredChannel
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let engagementScore = 0;
        const featuredChannelChoices = [];
        const performanceChannelChoices = [];

        if (nextProps.affiliate && nextProps.affiliate.youTubeData && nextProps.affiliate.youTubeData[0] && nextProps.affiliate.youTubeData[0].score) {
            engagementScore = nextProps.affiliate.youTubeData[0].score;
            if (nextProps.affiliate.youTubeData.length > 1) {
                nextProps.affiliate.youTubeData.forEach((account) => {
                    engagementScore += account.score;
                });
                engagementScore = (engagementScore / nextProps.affiliate.youTubeData.length);
            }

            nextProps.affiliate.youTubeData.forEach((account) => {
                account.channelStats.forEach((channel) => {
                    featuredChannelChoices.push({
                        label: channel.name,
                        value: channel.id
                    });
                    performanceChannelChoices.push({
                        label: channel.name,
                        value: channel.id
                    });
                });
            });
        }

        const newState = {
            accounts: (nextProps.affiliate.youTubeData) ? nextProps.affiliate.youTubeData : [],
            refreshingAccount: nextProps.gettingAccounts,
            engagementScore,
            featuredChannelChoices,
            featuredChannel: nextProps.affiliate.youtube,
            performanceChannelChoices
        };

        if (!prevState.performanceChannel) {
            newState.performanceChannel = nextProps.affiliate.youtube
        } else {
            newState.performanceChannel = prevState.performanceChannel
        }

        return newState;
    }

    // runs when oauth flow completes
    responseParentSocialAccounts = (response) => {
        this.setState({ isAuthWindowOpen: false });
        let isResponseEmpty = true;

        if (response.code) {
            this.props.createYoutubeAffData(response.code, this.props.affiliate.cognito_id)
                .then((result) => {
                    if (result && result.value && result.value.data && result.value.data && result.value.data.createYouTubeUser && result.value.data.createYouTubeUser.channels && result.value.data.createYouTubeUser.channels.length > 0) {
                        return this.props.refreshParentComponent();
                    }
                    this.setState({
                        socialError: 'Something went wrong. We were unable to connect your YouTube channel.'
                    });
                    return '';
                });
        } else {
            isResponseEmpty = false;
        }

        if (isResponseEmpty) {
            this.setState({ linkingAccount: false });
        }
    }

    // open unlink account confirmation modal and remember id to unlink
    unlinkAccountConfirm = (accountId) => {
        this.setState({
            accountToUnlink: accountId,
            unlinkConfirmationOpen: true
        });
    }

    // unlink the account
    unlinkAccount = (accountId) => {
        this.setState({ unlinking: true });
        this.props.unlinkYoutubeAffiliate(this.props.affiliate.cognito_id, accountId)
            .then((result) => {
                if (result && result.value && result.value.data && result.value.data.unlinkYoutubeAccount && result.value.data.unlinkYoutubeAccount.youtube_id) {
                    this.props.refreshParentComponent();
                    this.setState({
                        unlinkConfirmationOpen: false,
                        accountToUnlink: null,
                        unlinking: false
                    });
                } else {
                    this.setState({
                        unlinking: false,
                        toastVerbiage: this.errMsg,
                        unlinkConfirmationOpen: false,
                        accountToUnlink: null
                    });
                }
            })
            .catch(() => {
                this.setState({
                    unlinking: false,
                    toastVerbiage: this.errMsg,
                    unlinkConfirmationOpen: false,
                    accountToUnlink: null
                });
            });
    }

    // add new account, manage channels
    startOauthFlow = () => {
        this.setState({ isAuthWindowOpen: true });
    }

    // select featured channel in the modal
    handleFeaturedChannelChange = (value) => {
        this.setState({ featuredChannelSelection: value[0] });
    }

    // change channel view for performance stats
    handlePerformanceChannelChange = (value) => {
        this.setState({ performanceChannel: value });
    }

    // change the featured channel
    setFeaturedChannel = () => {
        this.setState({ savingFeaturedChannel: true });
        // save this.state.featuredChannel to mongo
        this.props.setFeaturedChannel(this.props.affiliate.cognito_id, this.state.featuredChannelSelection)
            .then((result) => {
                this.props.refreshParentComponent();
                this.setState({
                    setFeaturedChannelOpen: false
                });
            })
            .catch(() => {
                this.setState({
                    savingFeaturedChannel: false,
                    toastVerbiage: this.errMsg,
                    setFeaturedChannelOpen: false
                });
            });
    }

    closeModal = (modalState) => {
        this.setState({
            [modalState]: false,
            featuredChannelSelection: this.state.featuredChannel,
            accountToUnlink: null
        });
    }

    renderAccountsList = () => {
        return (
            <ul className="youtube-integration__list-accounts">
                {this.state.accounts.map((account) => {
                    return (
                        <li key={account.accountInfo.id}>
                            <p className="account-info">
                                <img src={account.accountInfo.profilePicture} alt="" />
                                <span>
                                    <b>{account.accountInfo.name}</b>
                                    {account.channelStats.map((channel, index) => {
                                        getGradeValues(channel.score);
                                        return <span key={channel.name}>{channel.name}{index < account.channelStats.length - 1 && ', '}</span>;
                                    })}
                                </span>
                            </p>
                            <LlamaButton classes={['unlink']} onClick={() => { return this.unlinkAccountConfirm(account.accountInfo.id); }}>Revoke Access</LlamaButton>
                        </li>
                    );
                })}
            </ul>
        );
    }

    renderTopVideos = (type, videos) => {
        let sortedVideos = JSON.parse(JSON.stringify(videos));
        let sortKey = '';
        const typeSingular = type.substring(0, (type.length - 1));

        switch (type) {
            case 'views':
                sortKey = 'viewCount';
                break;

            case 'comments':
                sortKey = 'commentCount';
                break;

            case 'likes':
                sortKey = 'likeCount';
                break;

            default:
                break;
        }

        if (sortKey) {
            // sort by sortKey descending. if sortKey values are the same, sort by publishedAt descending
            sortedVideos = videos.sort((a, b) => {
                if (a.statistics[sortKey] > b.statistics[sortKey]) {
                    return -1;
                }
                if (a.statistics[sortKey] < b.statistics[sortKey]) {
                    return 1;
                }
                if (a.statistics[sortKey] === b.statistics[sortKey]) {
                    if (a.publishedAt > b.publishedAt) {
                        return -1;
                    }
                    if (a.publishedAt < b.publishedAt) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            });
        }

        // return the first 4 videos, the 4th will be hidden most of the time, but for some mobile versions we'll use a 2 column grid for a better fit, so we need an extra video to make it an even number
        if (sortedVideos.length >= 4) {
            sortedVideos = sortedVideos.slice(0, 4);
        }

        return (
            <ul>
                {sortedVideos.map((video) => {
                    return (
                        <li key={video.id}>
                            <a href={`https://youtube.com/watch?v=${video.id}`} target="_blank" rel="nofollow noreferrer noopener">
                                <img src={video.thumbnail} alt="" />
                                <b>{video.title}</b>
                            </a>
                            {numberFormat(video.statistics[sortKey])} {video.statistics[sortKey] === 1 ? typeSingular : type}
                        </li>
                    );
                })}
            </ul>
        );
    }

    render() {
        if (this.state && (this.state.isLoading || this.state.refreshingAccount)) {
            return <CenteredSpinner />;
        }

        if (this.state && this.state.accounts && Array.isArray(this.state.accounts) && this.state.accounts.length === 0) {
            return <EmptyComponentState config={this.emptyStateConfig} socialAuthData={this.state.socialAuthData} {...this.props} />;
        }

        let numChannels = 0;
        const featuredChannelInfo = this.state.accounts.reduce((acc, account) => {
            numChannels += account.channelStats.length;
            if (!acc.name) {
                const featuredChannel = account.channelStats.find((channel) => {
                    return channel.id === this.state.featuredChannel;
                });
                if (featuredChannel) {
                    return featuredChannel;
                }
            }
            return acc;
        }, {});

        const performanceChannelVideos = [];
        let performanceChannelIcon = '';
        this.state.accounts.forEach((account) => {
            const performanceChannelInfo = account.channelStats.find((channel) => {
                return channel.id === this.state.performanceChannel;
            });

            if (performanceChannelInfo && performanceChannelInfo.channelPhoto) {
                performanceChannelIcon = performanceChannelInfo.channelPhoto;
            }

            if (account.videos && Array.isArray(account.videos) && account.videos.length > 0) {
                account.videos.forEach((video) => {
                    if (video.channelId === this.state.performanceChannel ) {
                        performanceChannelVideos.push(video);
                    }
                });
            }
        });

        return (
            <div className="social__profile__details__card">
                <Modal
                    title="Are you sure you want to revoke our access to this account?"
                    open={this.state.unlinkConfirmationOpen}
                    onClose={() => { return this.closeModal('unlinkConfirmationOpen'); }}
                    primaryAction={{
                        content: 'Revoke Access',
                        onAction: () => { return this.unlinkAccount(this.state.accountToUnlink); },
                        loading: this.state.unlinking
                    }}
                >
                    <Modal.Section>
                        <p className="youtube-integration__revoke-message">This will remove our connection to your YouTube account and we will no longer have access to your Google name and photo, nor videos from any of this account&rsquo;s YouTube channels you&rsquo;ve authorized Llama to use.</p>
                        <p>Your data will be removed only from our servers. Revoking our access does not, in any way, affect data stored by YouTube. In other words, your channels, videos, and other data will <strong>not</strong> be deleted from from YouTube.</p>
                    </Modal.Section>
                </Modal>

                <Modal
                    title="Set your featured channel"
                    open={this.state.setFeaturedChannelOpen}
                    onClose={() => { return this.closeModal('setFeaturedChannelOpen'); }}
                    primaryAction={{
                        content: 'Set As Featured Channel',
                        onAction: this.setFeaturedChannel,
                        loading: this.state.savingFeaturedChannel
                    }}
                    secondaryActions={[{
                        content: 'Cancel',
                        onAction: () => { return this.closeModal('setFeaturedChannelOpen'); }
                    }]}
                >
                    <Modal.Section>
                        <p>Which channel would you like to feature on your profile?</p>
                        <ChoiceList
                            selected={[this.state.featuredChannelSelection]}
                            onChange={this.handleFeaturedChannelChange}
                            choices={this.state.featuredChannelChoices}
                        />
                    </Modal.Section>
                </Modal>

                <div className="youtube-integration__account-select">
                    <div className="youtube-integration__header">Linked YouTube Accounts</div>
                    {this.renderAccountsList()}
                    {this.state.socialError
                        && (
                            <Banner
                                status="warning"
                                title="Unable to connect channel"
                                action={{
                                    content: 'Retry',
                                    onAction: this.startOauthFlow
                                }}
                            >
                                {this.state.socialError}
                            </Banner>
                        )
                    }
                    <button type="button" className="youtube-integration__button" onClick={this.startOauthFlow}>
                        Link Another Channel
                    </button>
                    { this.state.isAuthWindowOpen
                        && (
                            <SocialAuthComponent
                                responseParentSocialAccounts={this.responseParentSocialAccounts}
                                data={{
                                    platform: 'youtube',
                                    isAuthWindowOpen: this.state.isAuthWindowOpen,
                                    socialAuthData: this.state.socialAuthData,
                                    isLoading: this.state.isLoading
                                }}
                                {...this.props}
                            />
                        )
                    }
                </div>
                {/* TODO: uncomment later numChannels > 1
                    && (
                        <div className="social-settings__featured-page">
                            <h2>Featured Channel</h2>
                            <p>This channel will be shown first on your profile</p>
                            <div className="page">
                                <p className="image"><img src={featuredChannelInfo.channelPhoto} alt="featured channel icon" /></p>
                                <p className="info">
                                    <b>{featuredChannelInfo.name}</b>
                                    {/* <span>{featuredChannelInfo.keywords}</span> *}
                                </p>
                                <LlamaButton classes={['edit']} onClick={() => { return this.setState({ setFeaturedChannelOpen: true }); }}>Change</LlamaButton>
                            </div>
                        </div>
                    )
                */}

                <div className="youtube-integration__top-videos">
                    <div className="channel-select">
                        <p className="photo"><img src={performanceChannelIcon} alt="" /></p>
                        <div>
                            <h3>Top performing videos for <Select
                                options={this.state.performanceChannelChoices}
                                value={this.state.performanceChannel}
                                onChange={this.handlePerformanceChannelChange}
                            /></h3>
                            <p>Based on your 50 most recent public uploads</p>
                        </div>
                    </div>
                    <section>
                        <h4>Most Commented Videos</h4>
                        {this.renderTopVideos('comments', performanceChannelVideos)}
                    </section>
                    <section>
                        <h4>Most Liked Videos</h4>
                        {this.renderTopVideos('likes', performanceChannelVideos)}
                    </section>
                    <section>
                        <h4>Most Watched Videos</h4>
                        {this.renderTopVideos('views', performanceChannelVideos)}
                    </section>
                </div>

                <Frame>
                    {this.state.toastVerbiage
                        ? <Toast content={this.state.toastVerbiage} onDismiss={() => { return this.setState({ toastVerbiage: null }); }} />
                        : null
                    }
                </Frame>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        createYoutubeAffData: (data, id) => dispatch(createYoutubeAffData(data, id)),
        updateAffiliate: (data, id) => dispatch(updateAffiliate(data, id)),
        unlinkYoutubeAffiliate: (data, id) => dispatch(unlinkYouTubeAffiliate(data, id)),
        getSocialRedirectUrl: (platform) => dispatch(getSocialRedirectUrl(platform)),
        setFeaturedChannel: (cognito_id, featuredChannelId) => dispatch(setFeaturedChannel(cognito_id, featuredChannelId))
    };
};

YoutubeComponent.propTypes = {
    socialData: PropTypes.array,
    selectedSocialAccounts: PropTypes.array,
    affiliate: PropTypes.object,
    history: PropTypes.any,
    getAffiliateData: PropTypes.any,
    createYoutubeAffData: PropTypes.any,
    updateAffiliate: PropTypes.any,
    unlinkYoutubeAffiliate: PropTypes.any,
    refreshingAccount: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchtoProps)(YoutubeComponent);
