/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React from 'react';

import {Page} from '@shopify/polaris';
import { getAffiliateData } from '../store/actions/action_getAffiliate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SocialSettings from '../components/affiliate-social/affiliate-social';
import '../components/affiliate-dashboard/affiliate-dashboard.css';

class SocialNetworks extends React.Component{
	constructor(props){
		super(props)

		this.state = {

		}
	}

	render(){
		return (
			<Page>
				<SocialSettings {...this.props} />
			</Page>
		);
	}
}

const mapStateToProps = (state) => {
	return {...state};
}

const mapDispatchToProps = (dispatch) => {

	return bindActionCreators({
		get_affiliate: getAffiliateData
	})

}

export default connect(mapStateToProps, mapDispatchToProps)(SocialNetworks);