import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonDisplayText } from '@shopify/polaris';

import { ApplicationStatus } from '../../utils/types';
import './applications.css';

const ApplicationsHeader = ({ applications }) => {

    // Display placeholder if applications are not loaded yet.
    if (!applications || !Array.isArray(applications)) {
        return (
            <h3 className="Applications__TopStatus" data-test="applications-header-empty">
                <SkeletonDisplayText />
            </h3>
        );
    }

    // Calculate the number of active and pending applications.
    const { activeCount, pendingCount } = applications.reduce((acc, next) => {
        if (next.advertiser_status === ApplicationStatus.APPROVED) {
            acc.activeCount = acc.activeCount + 1;
        }
        
        if (next.advertiser_status === ApplicationStatus.PENDING) {
            acc.pendingCount = acc.pendingCount + 1;
        }

        return acc;

    }, { activeCount: 0, pendingCount: 0 })

    return (
        <h3 className="Applications__TopStatus" data-test="applications-header">
            You have <span className="Applications__TopStatusActive" data-test="applications-active-count">{activeCount} approved</span> offers and <span className="Applications__TopStatusPending" data-test="applications-pending-count">{pendingCount} pending</span>
        </h3>
    )               

}

ApplicationsHeader.propTypes = {
    applications: PropTypes.arrayOf(PropTypes.object),
}

export default ApplicationsHeader;