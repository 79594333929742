import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Frame,
    Toast,
    Banner,
    Modal,
    ChoiceList,
    Badge
} from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';
import { connect } from 'react-redux';

import { createFacebookAffData } from '../../../store/actions/social/create-facebook';
import { unlinkFacebookAffiliate } from '../../../store/actions/action_unlinkFacebookAffiliate';
import { unlinkFacebookSubscription } from '../../../store/actions/action_unlinkFacebookSubscription';
import { updateAffiliate } from '../../../store/actions/action_updateAffiliate';
import { getSocialRedirectUrl } from '../../../store/actions/social/getSocialRedirect';
import { setFeaturedPage } from '../../../store/actions/social/set-featured-page';

import CenteredSpinner from '../../llama/centered-spinner';
import EmptyComponentState from '../../EmptyComponent/emptyState';
import SocialAuthComponent from '../../affiliate-auth/social-auth';
import EngagementScore, { getGradeValues } from '../engagement-score/engagement-score';

import '../affiliate-social.css';
import './facebook.css';

class FacebookComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accounts: this.props.affiliate.facebookData,
            refreshingAccount: false,
            isLoading: false,
            socialAuthData: {},
            isAuthWindowOpen: false,
            engagementScore: 0,

            accountToUnlink: null,
            unlinkConfirmationOpen: false,
            unlinking: false,

            setFeaturedPageOpen: false,
            featuredPageChoices: [],
            featuredPageSelection: '',
            featuredPage: '',
            savingFeaturedPage: false
        };

        this.emptyStateConfig = {
            platform: 'facebook',
            heading: 'Link Your Facebook Page',
            description: 'We’ll analyze audience engagement from your most recent Page posts and show you just how interested your followers are in what you have to say.',
            linkSocialAccount: true
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.getSocialRedirectUrl('facebook')
            .then((result) => {
                this.setState({
                    socialAuthData: {
                        redirectUrl: result.value.data.location
                    }
                });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                console.log('Error: Unable to get redirect URL for facebook', error);
            });

        this.setState({
            featuredPageSelection: this.state.featuredPage
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log('state change running');
        let engagementScore = 0;
        const featuredPageChoices = [];

        if (nextProps.affiliate && nextProps.affiliate.facebookData && nextProps.affiliate.facebookData[0] && nextProps.affiliate.facebookData[0].score) {
            engagementScore = nextProps.affiliate.facebookData[0].score;
            if (nextProps.affiliate.facebookData.length > 1) {
                nextProps.affiliate.facebookData.forEach((account) => {
                    engagementScore += account.score;
                });
                engagementScore = (engagementScore / nextProps.affiliate.facebookData.length);
            }

            nextProps.affiliate.facebookData.forEach((account) => {
                account.pageStats.forEach((page) => {
                    featuredPageChoices.push({
                        label: page.name,
                        value: page.id
                    });
                });
            });
        }

        return {
            accounts: (nextProps.affiliate.facebookData) ? nextProps.affiliate.facebookData : [],
            refreshingAccount: nextProps.gettingAccounts,
            engagementScore,
            featuredPageChoices,
            featuredPage: nextProps.affiliate.facebook
        };
    }

    // runs when oauth flow completes
    responseParentSocialAccounts = (response) => {
        this.setState({ isAuthWindowOpen: false });
        let isResponseEmpty = true;

        if (response.code) {
            this.props.createFacebookAffData(response.code, this.props.affiliate.cognito_id)
                .then((result) => {
                    if (result && result.value && result.value.data && result.value.data.data && result.value.data.data.createFacebookUser && result.value.data.data.createFacebookUser.pages && result.value.data.data.createFacebookUser.pages.length > 0) {
                        return this.props.refreshParentComponent();
                    }
                    this.setState({
                        socialError: 'You need to select at least one public Page to connect. Due to Facebook privacy restrictions, we are unable to calculate engagement scores from just your personal profile.'
                    });
                    return '';
                });
        } else {
            isResponseEmpty = false;
        }

        if (isResponseEmpty) {
            this.setState({ linkingAccount: false });
        }
    }

    // open unlink account confirmation modal and remember id to unlink
    unlinkAccountConfirm = (accountId) => {
        this.setState({
            accountToUnlink: accountId,
            unlinkConfirmationOpen: true
        });
    }

    // unlink the account
    unlinkAccount = (accountId) => {
        this.setState({ unlinking: true });
        this.props.unlinkFacebookAffiliate(this.props.affiliate.cognito_id, accountId)
            .then((result) => {
                if (result && result.value && result.value.data && result.value.data.unlinkFacebookAccount && result.value.data.unlinkFacebookAccount.facebook_id) {
                    this.props.refreshParentComponent();
                    this.setState({
                        unlinkConfirmationOpen: false,
                        accountToUnlink: null,
                        unlinking: false
                    });
                } else {
                    this.setState({
                        unlinking: false,
                        toastVerbiage: 'Oops, something went wrong. Please try again later.',
                        unlinkConfirmationOpen: false,
                        accountToUnlink: null
                    });
                }
            })
            .catch(() => {
                this.setState({
                    unlinking: false,
                    toastVerbiage: 'Oops, something went wrong. Please try again later.',
                    unlinkConfirmationOpen: false,
                    accountToUnlink: null
                });
            });
    }

    // add new account, manage pages
    startOauthFlow = () => {
        this.setState({ isAuthWindowOpen: true });
    }

    // select featured page in the modal
    handleFeaturedPageChange = (value) => {
        this.setState({ featuredPageSelection: value[0] });
    }

    // change the featured page
    setFeaturedPage = () => {
        this.setState({ savingFeaturedPage: true });
        // save this.state.featuredPage to mongo
        this.props.setFeaturedPage(this.props.affiliate.cognito_id, this.state.featuredPageSelection)
            .then((result) => {
                this.props.refreshParentComponent();
                this.setState({
                    setFeaturedPageOpen: false
                });
            })
            .catch(() => {
                this.setState({
                    savingFeaturedPage: false,
                    toastVerbiage: 'Oops, something went wrong. Please try again later.',
                    setFeaturedPageOpen: false
                });
            });
    }

    closeModal = (modalState) => {
        this.setState({
            [modalState]: false,
            featuredPageSelection: this.state.featuredPage,
            accountToUnlink: null
        });
    }

    renderAccountsList = () => {
        return (
            <ul className="facebook-integration__list-accounts">
                {this.state.accounts.map((account) => {
                    return (
                        <li key={account.fb_general_info[0].id}>
                            <p className="account-info">
                                <img src={account.fb_general_info[0].profilePhoto} alt="" />
                                <span>
                                    <b>{account.fb_general_info[0].name}</b>
                                    {account.pageStats.map((page, index) => {
                                        const pageGrade = getGradeValues(page.score);
                                        return <span key={page.name}>{page.name} <Badge status={pageGrade.badgeStatus}>{pageGrade.letterGrade}</Badge>{index < account.pageStats.length - 1 && ', '}</span>;
                                    })}
                                </span>
                            </p>
                            <LlamaButton classes={['edit']} onClick={this.startOauthFlow}>Manage Pages</LlamaButton>
                            <LlamaButton classes={['unlink']} onClick={() => { return this.unlinkAccountConfirm(account.fb_general_info[0].id); }}>Unlink</LlamaButton>
                        </li>
                    );
                })}
            </ul>
        );
    }

    render() {
        if (this.state && (this.state.isLoading || this.state.refreshingAccount)) {
            return <CenteredSpinner />;
        }

        if (this.state && this.state.accounts && Array.isArray(this.state.accounts) && this.state.accounts.length === 0) {
            return <EmptyComponentState config={this.emptyStateConfig} socialAuthData={this.state.socialAuthData} {...this.props} />;
        }

        let numPages = 0;
        const featuredPageInfo = this.state.accounts.reduce((acc, account) => {
            numPages += account.pageStats.length;
            if (!acc.name) {
                const featuredPage = account.pageStats.find((page) => {
                    return page.id === this.state.featuredPage;
                });
                if (featuredPage) {
                    return featuredPage;
                }
            }
            return acc;
        }, {});

        return (
            <div className="social__profile__details__card">
                <Modal
                    title="Are you sure you want to unlink this account?"
                    open={this.state.unlinkConfirmationOpen}
                    onClose={() => { return this.closeModal('unlinkConfirmationOpen'); }}
                    primaryAction={{
                        content: 'Unlink',
                        onAction: () => { return this.unlinkAccount(this.state.accountToUnlink); },
                        loading: this.state.unlinking
                    }}
                    secondaryActions={[{
                        content: 'Manage Pages',
                        onAction: this.startOauthFlow
                    }]}
                >
                    <Modal.Section>
                        <p>This will unlink your profile account and all of the pages associated with it. To unlink specific pages instead, click Manage Pages.</p>
                    </Modal.Section>
                </Modal>

                <Modal
                    title="Set your featured page"
                    open={this.state.setFeaturedPageOpen}
                    onClose={() => { return this.closeModal('setFeaturedPageOpen'); }}
                    primaryAction={{
                        content: 'Set As Featured Page',
                        onAction: this.setFeaturedPage,
                        loading: this.state.savingFeaturedPage
                    }}
                    secondaryActions={[{
                        content: 'Cancel',
                        onAction: () => { return this.closeModal('setFeaturedPageOpen'); }
                    }]}
                >
                    <Modal.Section>
                        <p>Which page would you like to feature on your profile?</p>
                        <ChoiceList
                            selected={[this.state.featuredPageSelection]}
                            onChange={this.handleFeaturedPageChange}
                            choices={this.state.featuredPageChoices}
                        />
                    </Modal.Section>
                </Modal>

                <div className="facebook-integration__account-select">
                    <div className="facebook-integration__header">Linked Facebook Accounts &amp; Pages</div>
                    {this.renderAccountsList()}
                    {this.state.socialError
                        && (
                            <Banner
                                status="warning"
                                title="Unable to connect page"
                                action={{
                                    content: 'Retry',
                                    onAction: this.startOauthFlow
                                }}
                            >
                                {this.state.socialError}
                            </Banner>
                        )
                    }
                    <button type="button" className="facebook-integration__button" onClick={this.startOauthFlow}>
                        Link Another Page
                    </button>
                    { this.state.isAuthWindowOpen
                        && (
                            <SocialAuthComponent
                                responseParentSocialAccounts={this.responseParentSocialAccounts}
                                data={{
                                    platform: 'facebook',
                                    isAuthWindowOpen: this.state.isAuthWindowOpen,
                                    socialAuthData: this.state.socialAuthData,
                                    isLoading: this.state.isLoading
                                }}
                                {...this.props}
                            />
                        )
                    }
                </div>
                {numPages > 1
                    && (
                        <div className="social-settings__featured-page">
                            <h2>Featured Page</h2>
                            <p>This page will be shown first on your profile</p>
                            <div className="page">
                                <p className="image"><img src={featuredPageInfo.profilePhoto} /></p>
                                <p className="info">
                                    <b>{featuredPageInfo.name}</b>
                                    <span>{featuredPageInfo.category}</span>
                                </p>
                                <LlamaButton classes={['edit']} onClick={() => { return this.setState({ setFeaturedPageOpen: true }); }}>Change</LlamaButton>
                            </div>
                        </div>
                    )
                }
                <EngagementScore data={[{ score: this.state.engagementScore }]} />
                <Frame>
                    {this.state.toastVerbiage
                        ? <Toast content={this.state.toastVerbiage} onDismiss={() => { return this.setState({ toastVerbiage: null }); }} />
                        : null
                    }
                </Frame>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        createFacebookAffData: (data, id) => dispatch(createFacebookAffData(data, id)),
        updateAffiliate: (data, id) => dispatch(updateAffiliate(data, id)),
        unlinkFacebookAffiliate: (data, id) => dispatch(unlinkFacebookAffiliate(data, id)),
        unlinkFacebookSubscription: (data, id) => dispatch(unlinkFacebookSubscription(data, id)),
        getSocialRedirectUrl: (platform) => dispatch(getSocialRedirectUrl(platform)),
        setFeaturedPage: (cognito_id, featuredPageId) => dispatch(setFeaturedPage(cognito_id, featuredPageId))
    };
};

FacebookComponent.propTypes = {
    socialData: PropTypes.array,
    selectedSocialAccounts: PropTypes.array,
    affiliate: PropTypes.object,
    history: PropTypes.any,
    getAffiliateData: PropTypes.any,
    createFacebookAffData: PropTypes.any,
    updateAffiliate: PropTypes.any,
    unlinkFacebookAffiliate: PropTypes.any,
    unlinkFacebookSubscription: PropTypes.any,
    refreshingAccount: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchtoProps)(FacebookComponent);
