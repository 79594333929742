const offerFragment = `
  fragment OfferData on Offer {
    offer_id
    offer_type
    preview_url
    offer_url
    expiration_date
    active
    interim_status
    interim_status_date
    name
    bannerImage
    advertiser_id
    create_date_utc
    category
    description
    tags
    ltv
    long_term_rate{
      ltv
      avg_cart_value
    }
    product_info {
      product_id
      title
      handle
      body_html
      vendor
      product_type
      image{
        src
      }
      variants{
        id
        price
      }
      clicks
      quantity_sold
    }
    reports{
      cartAvg
      productData{
        product_id
        product{
          product_id
          body_html
          clicks
          EPC
          title
          handle
          variants {
            title
            price
          }
          image{
            src
          }
        }
      }

    }
    rate {
      amount
      type
    }
    longterm_rate {
      amount
      type
    }
    creatives {
      name
      size
      type
      url
    }
    target {
      gender
      age_low
      age_high
      interests
      countries
    }
    stats {
      epc
      clicks
      revenue
    }
  }
`;

const activeOfferFragment = `
fragment OfferData on Offer {
  offer_id
  offer_type
  preview_url
  offer_url
  expiration_date
  active
  name
  bannerImage
  advertiser_id
  create_date_utc
  category
  description
  tags
  ltv
    rate {
      amount
      type
    }
    longterm_rate {
      amount
      type
    }
  }
`;

const searchOfferFragment = `
fragment OfferData on Offer {
  offer_id
  offer_type
  preview_url
  offer_url
  expiration_date
  active
  name
  bannerImage
  advertiser_id
  create_date_utc
  category
  description
  tags
  eligibleProducts{
      products{
          image{
              src
          }
          title
      }
      numExtraProducts
  }
  rate {
    amount
    type
  }
  longterm_rate {
      type
      amount
  }
  creatives {
    name
    size
    type
    url
  }
  stats{
    epc
    clicks
    revenue
  }
}
`;

exports.offerFragment = offerFragment;
exports.activeOfferFragment = activeOfferFragment;
exports.searchOfferFragment = searchOfferFragment;
