import {sendGraphQL} from './sendRequest'

export const getTransactionReport = (advertiser_id, filters, options) => {
    if (!advertiser_id) {
        return Promise.reject(new Error(`Unable to pull transaction report with advertiser_id of ${advertiser_id}.`));
    }

    const query = `
        query getTransactionReports(
            $advertiser_id: String,
            $start_date: String,
            $end_date: String,
            $filter: ReportsFilter
            $options: QueryOptions
        ) {
            advertiserById(advertiser_id: $advertiser_id) {
                reports(start_date: $start_date, end_date: $end_date, filter: $filter, options: $options) {
                    orders {
                        edges {
                            order_id
                            name
                            created_at
                            subtotal_price
                            customer {
                                first_name
                                last_name
                            }
                            connection {
                                offer {
                                    offer_id
                                    name
                                }
                                advertiser {
                                    advertiser_id
                                    name
                                }
                                affiliate {
                                    affiliate_id
                                    name
                                }
                                expired_timeline
                                commission_amount
                            }
                            invoice {
                                invoice_id
                                status
                            }
                            discount_codes {
                                code
                                amount
                                type
                            }
                        }
                        pageInfo {
                            pages
                            hasNextPage
                            hasPreviousPage
                            currentPage
                            count
                        }
                    }
                }
            }
        }
    `

    const { startDate, endDate, ...filterEntries } = filters;

    const reportFilters = Object.entries(filterEntries)
        .map(([key, value]) => {
            if (value) {
                return [key, value];
            }
            return null;
        })
        .filter((item) => {return item !== null})
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {})

    const variables = {
        advertiser_id,
        filter: reportFilters,
        start_date: startDate,
        end_date: endDate,
    }

    if (options) {
        variables.options = options;
    }

    const payload = {
        query,
        variables,
    }

    return sendGraphQL(payload)
        .then(response => {return response.data.data.advertiserById})
}
