/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: April 25, 2019
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from "./sendRequest"

export const getSmartLinksForOfferDetail = (id) => {
    const query = `
        query getSmartLinks($congito_id: String) {
            getSmartLinks(cognito_id: $congito_id) {
                displayMode
                manualLinks {
                    id
                    title
                    url
                    offerId
                    offerName
                    company
                }
                automaticLinks {
                    id
                    title
                    url
                    offerId
                    offerName
                    company
                }
                links {
                    id
                    title
                    url
                    offerId
                }
                limits {
                    free {
                        automatic
                        manual
                    }
                    pro {
                        automatic
                        manual
                    }
                }
                settings {
                    subscription {
                        plan
                        expires
                    }
                }
            }
        }
    `;

    const variables = {
        congito_id: id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; })
        .catch((error) => { return error; });
};

export const getSmartLinks = (id, filter) => {
    const query = `query getSmartLinks($congito_id: String, $filter: String) {
        getSmartLinks(cognito_id: $congito_id, filter: $filter) {
            unique_id
            custom_id
            current_handle
            affiliate_id
            cognito_id
            displayMode
            manualLinks {
                id
                title
                url
                offerId
                offerName
                company
            }
            automaticLinks {
                id
                title
                url
                offerId
                offerName
                company
            }
            limits {
                free {
                    automatic
                    manual
                }
                pro {
                    automatic
                    manual
                }
            }
            lastUpdated
            settings {
                subscription {
                    plan
                    expires
                }
            }
            headline
            full_name
            bio
            nsfwType
            socialProfiles {
                facebook
                twitter
                instagram
                youtube
                tiktok
                soundcloud
            }
            customizations {
                theme
                avatarUrl
                font
                pageBg
                headlineText
                buttonBg
                buttonBorder
                buttonText
                buttonRoundness
            }
            gaTrackingId
            fbPixelId
            utm {
                source
                medium
                campaign
            }
            mailingList {
                platform
                listId
                apiKey
                apiPrefix
                formHeading
                formButtonText
                recentError
            }
            domains
            bioLink
            images
            firstInstall
        }
    }`;

    const variables = {
        congito_id: id,
        filter
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'GET_SMARTLINKS',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch((error) => { return error; })
    };

    /* let headers = {
    "Content-Type": 'application/json'
  }

  return {
    type: 'GET_SMARTLINKS',
    payload: axios.post(NEXT_APP_API_URL, payload, { headers })
      .then(response => response.data)
      .catch((error) => { return error })
  } */
}

export const getBillingDetails = (id) => {
    const query = `query getBillingDetails($congito_id: String) {
        getBillingDetails(cognito_id: $congito_id) {
            first_name
            last_name
            company
            address1
            address2
            city
            state
            zip
            country
            phone
            email
        }
    }`;

    const variables = {
        congito_id: id
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'GET_AFFILIATE_BILLING_DETAILS',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch((error) => { return error; })
    };

    /* let headers = {
    "Content-Type": 'application/json'
  }

  return {
    type: 'GET_AFFILIATE_BILLING_DETAILS',
    payload: axios.post(NEXT_APP_API_URL, payload, { headers })
      .then(response => response.data)
      .catch((error) => { return error })
  } */
};

export const startOnboarding = () => {
    return {
        type: 'CHANGE_ONBOARDING_STATUS',
        payload: true
    };
};

export const finishOnboarding = () => {
    return {
        type: 'CHANGE_ONBOARDING_STATUS',
        payload: false
    };
};
