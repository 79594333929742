import { sendGraphQL } from './sendRequest';

const switchAccountType = (cognito_id, account_type) => {
    const query = `
        mutation switchAccountTypeAffiliate($cognito_id: String!, $account_type: AffiliateAccountType!){
            switchAccountTypeAffiliate(cognito_id: $cognito_id, account_type: $account_type){
                account_type
            }
        }
    `;

    const variables = {
        cognito_id,
        account_type
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'SWITCH_ACCOUNT_TYPE',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch((err) => { console.error(err); })
    };
};

export default switchAccountType;
