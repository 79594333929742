import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
    Icon,
    Frame,
    Toast,
    Spinner,
    Scrollable
} from '@shopify/polaris';
import { ClipboardMinor, ExternalSmallMinor } from '@shopify/polaris-icons';

import { LlamaButton, PlaceholderPhoto, LlamaToast } from 'llama-library/components';
import { defaultBanners } from 'llama-library/utils';

import { Tag } from '../tag';
import ApplyToOfferButton from './apply-to-offer-button';
import OfferLinkParamsModal from './offer-link-parameters';
import SmartLinksRanking from './smartlinks-ranking';
import DiscoverMerchantsLink from '../DiscoverMerchants/DiscoverMerchantsLink';

import { generateTrackingLink } from '../../store/actions/generate-link';
import { recordOfferBoostMetric } from '../../store/actions/action_getDasboardData';

import { moneyFormat } from '../../utils/number-format';
import { RateTypes } from '../../utils/types';
import copyToClipboard from '../../utils/copy-to-clipboard';
import llamaImage from '../../assets/llama-head-01.png';

import './offer-detail-sidebar.css';

// if they applied right after clicking the offer, record it for the merchant
const afterApply = (sponsoredClick, offer_id) => {
    if (sponsoredClick) {
        recordOfferBoostMetric('applicants', offer_id);
    }
};

/* Child Components */
const ProductGiveawayApply = ({ status, isNew, offer, affiliate, sponsoredClick }) => {
    const [toastVerbiage, setToastVerbiage] = useState(null);

    switch (status) {
        case 'NEVER_APPLIED':
            return (
                <>
                    {isNew
                        ? (
                            <>
                                <p>Want to try these products? Apply now to <strong>be one of the first ambassadors to get one or more of them free</strong>!</p>
                                <p>If {offer.advertiser.company} approves your application, they&rsquo;ll select your free product(s) and give you a coupon code for them.</p>
                            </>
                        )

                        : (
                            <p>Want to try these products? <strong>Apply to get one or more of them free</strong> and if {offer.advertiser.company} approves, they&rsquo;ll select your free product(s) and give you a coupon code for them.</p>
                        )
                    }
                    <ApplyToOfferButton
                        offer={offer}
                        affiliate={affiliate}
                        setToastVerbiage={setToastVerbiage}
                        onSuccess={() => afterApply(sponsoredClick, offer.offer_id)}
                    />
                    <LlamaToast toastVerbiage={toastVerbiage} setToastVerbiage={setToastVerbiage} />
                </>
            );

        case 'PENDING':
            return <p><strong>Your application to get one or more of these free products is currently pending approval</strong>. If {offer.advertiser.company} approves you and creates a coupon code, we&rsquo;ll show it on this page.</p>;

        default:
            return <p>Oops, something went wrong here.</p>;
    }
};

const Promote = ({ status, isNew, offer, affiliate, offerURL, trackingLink, linkLoading, trackingLinkError, sponsoredClick }) => {
    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [linkOptionsOpen, setLinkOptionsOpen] = useState(false);

    const handleCopyLink = () => {
        copyToClipboard(trackingLink, setToastVerbiage, 'Link');
    };

    switch (status) {
        case 'NEVER_APPLIED':
            return (
                <>
                    {isNew
                        ? (
                            <>
                                <p>Got an audience who&rsquo;d love these products? Apply now to <strong>be one of the first ambassadors to promote this offer</strong>!</p>
                                <p>If {offer.advertiser.company} thinks you&rsquo;re a good fit, we&rsquo;ll give you a referral link so <strong>you can help boost their sales and get paid for it</strong>. It&rsquo;s a win win!</p>
                            </>
                        )

                        : (
                            <p>Got an audience who&rsquo;d love these products? <strong>Apply to promote this offer</strong> and if {offer.advertiser.company} approves, we&rsquo;ll give you a referral link.</p>
                        )
                    }
                    <ApplyToOfferButton
                        offer={offer}
                        affiliate={affiliate}
                        setToastVerbiage={setToastVerbiage}
                        onSuccess={() => afterApply(sponsoredClick, offer.offer_id)}
                    />
                </>
            );

        case 'PENDING':
            return <p><strong>Your application to promote this offer is currently pending approval</strong>. If {offer.advertiser.company} approves you, we&rsquo;ll update this space with your referral link.</p>;

        case 'APPROVED':
            return (
                <>
                    <p>Share your referral link on social media and earn commission when your {offer.offer_id.includes('LLAMA') ? 'referred ambassador joins Llama and earns their first $50' : 'referred customer makes a qualifying purchase'}.</p>
                    {linkLoading
                        ? <Spinner size="small" />
                        : (
                            <>
                                <div className="link-box">
                                    <p><b>Your Referral Link:</b></p>
                                    <p>{trackingLink}</p>
                                </div>
                                {!trackingLinkError
                                    && (
                                        <>
                                            <div className="actions">
                                                <LlamaButton classes={['copy']} onClick={handleCopyLink} data-test="offer-sidebar-copy-link"><Icon source={ClipboardMinor} color="white" />Copy Link</LlamaButton>
                                                <LlamaButton classes={['options']} secondary onClick={() => { return setLinkOptionsOpen(true); }}>Link Options</LlamaButton>
                                            </div>
                                            <OfferLinkParamsModal
                                                modalOpen={linkOptionsOpen}
                                                onClose={() => { return setLinkOptionsOpen(false); }}
                                                currentLink={trackingLink}
                                                offerURL={offerURL}
                                                setToastVerbiage={setToastVerbiage}
                                                offer_id={offer.offer_id}
                                                cognito_id={affiliate.cognito_id}
                                            />
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                    <Frame>{toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => { return setToastVerbiage(''); }} /> : null}</Frame>
                </>
            );

        default:
            return <p>Oops, something went wrong here.</p>;
    }
};

const Stats = ({ offer, isNew }) => {
    let epc = 0.00;
    let volume = 0.00;
    if (offer.stats) {
        epc = offer.stats.epc;
        volume = offer.stats.revenue;
    }

    let ltv = 0.00;
    if (offer.long_term_rate && offer.long_term_rate.ltv) {
        ltv = parseFloat(offer.long_term_rate.ltv) / 100;
    }

    let avgCart = 0.00;
    if (offer.long_term_rate && offer.long_term_rate.avg_cart_value) {
        avgCart = parseFloat(offer.long_term_rate.avg_cart_value) / 100;
    }

    if (!isNew || epc > 0 || volume > 0) {
        return (
            <>
                <p>Here&rsquo;s a quick look at how this offer&rsquo;s been performing with Llama ambassadors.</p>
                <dl>
                    <dt>LTV</dt>
                    <dd>{moneyFormat(ltv, true)}</dd>
                    <dt>EPC</dt>
                    <dd>{moneyFormat(epc, true)}</dd>
                    <dt>Avg. Cart</dt>
                    <dd>{moneyFormat(avgCart, true)}</dd>
                    <dt>Revenue</dt>
                    <dd>{moneyFormat(volume, true)}</dd>
                </dl>
            </>
        );
    }

    return <p>{offer.advertiser.company} just created this offer so there&rsquo;s not much to see here, yet.</p>;
};

/* Main Component */
const OfferDetailSidebar = ({ offer, applicationStatus, history, affiliate, sponsoredClick }) => {
    const [showAllTags, setShowAllTags] = useState(false);

    const [linkLoading, setLinkLoading] = useState(true);
    const [trackingLink, setTrackingLink] = useState('');
    const [offerURL, setOfferURL] = useState('');
    const [trackingLinkError, setTrackingLinkError] = useState(false);

    useEffect(() => {
        if (applicationStatus === 'APPROVED') {
            setOfferURL(offer.offer_url.replace('{affiliate_id}', affiliate.tracking_id).replace('{offer_id}', offer.offer_id));
            if (offer.offer_id.includes('LLAMA')) {
                setLinkLoading(false);
                setTrackingLink(offer.offer_url.replace('{affiliate_id}', affiliate.tracking_id).replace('{offer_id}', offer.offer_id));
            } else {
                generateTrackingLink(offer.offer_id, affiliate.tracking_id)
                    .then((result) => {
                        if (result && result.generateTrackingLink && result.generateTrackingLink.click_url) {
                            setLinkLoading(false);
                            setTrackingLink(result.generateTrackingLink.click_url);
                        } else {
                            const err = new Error('Invalid result');
                            err.result = result;
                            throw err;
                        }
                    })
                    .catch(() => {
                        console.log('Something went wrong. Unable to generate tracking link.');
                        setLinkLoading(false);
                        setTrackingLink('Oops, something went wrong. Unable to load link.');
                        setTrackingLinkError(true);
                    });
            }
        }
    }, [applicationStatus]);

    const monthDiff = moment().diff(moment(offer.create_date_utc), 'months');
    const isNew = (monthDiff < 1);

    const formatRate = ({ type, amount }) => {
        switch (type) {
            case RateTypes.FLAT_REFERRAL:
                return `${moneyFormat(amount, true)} per referral`;
            case RateTypes.FLAT_ITEM:
                return `${moneyFormat(amount, true)} per item`;
            case RateTypes.FLAT_ORDER:
                return `${moneyFormat(amount, true)} per order`;
            case RateTypes.PERCENT_REVENUE:
            default:
                return `${amount}% of revenue`;
        }
    };

    const formatStatus = () => {
        switch (applicationStatus) {
            case 'APPROVED': {
                let displayStatus = 'You’re approved to promote this offer';

                if (offer.active && offer.interim_status
                    && offer.interim_status.toUpperCase() === 'ACTIVE') {
                    displayStatus = 'This offer has been deactived';
                }
                return displayStatus;
            }
            case 'PENDING':
                return 'Your application is pending approval';
            default:
                break;
        }
        if (isNew) {
            return 'New offer! Apply now to beat the crowd.';
        }
        return null;
    };

    const isLlamaOffer = offer.offer_id.includes('LLAMA');
    let isInactive = false;

    let bannerImage = (defaultBanners[offer.category]) ? defaultBanners[offer.category][0] : defaultBanners.default[0];
    let bannerImage2x = (defaultBanners[offer.category]) ? defaultBanners[offer.category][1] : defaultBanners.default[1];
    if (offer.bannerImage) {
        bannerImage = offer.bannerImage;
        bannerImage2x = null;
    } else if (offer.creatives && offer.creatives[0] && offer.creatives[0].url) { // only for legacy data; phase this out in favor of offer.bannerImage
        bannerImage = offer.creatives[0].url;
        bannerImage2x = null;
    }
    if (isLlamaOffer) {
        [bannerImage, bannerImage2x] = defaultBanners['Llama Partner'];
    }

    if (isLlamaOffer) {
        offer.advertiser.avatar_image = llamaImage;
    }

    if (applicationStatus.toUpperCase() === 'APPROVED'
        && offer.active && offer.interim_status
        && offer.interim_status.toUpperCase() === 'ACTIVE') {
        isInactive = true;
    }

    return (
        <aside className="offer-details__sidebar">
            {!/Trident\/|MSIE /.test(window.navigator.userAgent) // favor img + object-fit for everything that's not IE
                ? (
                    <p className="banner">
                        <img src={bannerImage} srcSet={bannerImage2x ? `${bannerImage} 1x, ${bannerImage2x} 2x` : null} alt="" />
                    </p>
                )
                : <div className="banner" style={{ backgroundImage: `url(${bannerImage})` }} />
            }

            <section className="offer-info">
                <div className="offer-name">
                    <p><PlaceholderPhoto photo={offer.advertiser.avatar_image} alt={`${offer.advertiser.company}’s logo`} height="36" width="36" /></p>
                    <div>
                        <h1>{offer.name}</h1>
                        <p className="category">{offer.category}</p>
                        {offer.offer_type === 'product_giveaway'
                            && <a href={`//${offer.advertiser.domain}`} target="_blank" rel="noreferrer noopener">View Store<Icon source={ExternalSmallMinor} /></a>
                        }
                    </div>
                </div>
                <p className="description">
                    {offer.description}
                    {offer.description && applicationStatus !== 'APPROVED' && <br />}
                    {applicationStatus !== 'APPROVED' && offer.offer_type !== 'product_giveaway'
                        && <a href={offer.preview_url} target="_blank" rel="noreferrer noopener">Preview Offer Page<Icon source={ExternalSmallMinor} /></a>
                    }
                </p>
                {!isLlamaOffer && offer.offer_type !== 'product_giveaway'
                    && (
                        <div className="commission-rate">
                            <p className="heading">Commission Rate:</p>
                            <ul>
                                <li>Initial: <span>{formatRate(offer.rate)}</span></li>
                                <li>Long-Term: <span>{formatRate(offer.longterm_rate)}</span></li>
                            </ul>
                        </div>
                    )
                }
                {(applicationStatus !== 'NEVER_APPLIED' || isNew)
                    && (
                        <p
                            className="application-status"
                            data-new={isNew}
                            data-status={isInactive ? 'INACTIVE-OFFER' : applicationStatus}
                        >
                            <span>{formatStatus()}</span>
                        </p>
                    )
                }
                {offer.tags && offer.tags.length > 0
                    && (
                        <ul className="tags">
                            {offer.tags.map((tag, index) => {
                                if (showAllTags || (!showAllTags && index < 3)) {
                                    return <li key={index}><Tag history={history}>{tag}</Tag></li>;
                                }
                                return '';
                            })}
                            {!showAllTags && offer.tags.length > 3
                                && <li className="tag-disclosure" onClick={() => setShowAllTags(true)}>+ {offer.tags.length - 2} more</li>
                            }
                        </ul>
                    )
                }
            </section>
            {offer.offer_type === 'product_giveaway' && applicationStatus !== 'APPROVED' && offer.advertiser.status !== 'INACTIVE'
                && (
                    <section className="promote">
                        <h2>Get Free Products</h2>
                        <ProductGiveawayApply
                            status={applicationStatus}
                            isNew={isNew}
                            offer={offer}
                            affiliate={affiliate}
                            sponsoredClick={sponsoredClick}
                        />
                    </section>
                )
            }
            {offer.offer_type !== 'product_giveaway' && offer.advertiser.status !== 'INACTIVE'
                && (
                    <section className="promote">
                        <h2>Promote and Earn</h2>
                        <Promote
                            status={applicationStatus}
                            isNew={isNew}
                            offer={offer}
                            affiliate={affiliate}
                            trackingLink={trackingLink}
                            offerURL={offerURL}
                            linkLoading={linkLoading}
                            trackingLinkError={trackingLinkError}
                            sponsoredClick={sponsoredClick}
                        />
                    </section>
                )
            }

            {offer.offer_type !== 'product_giveaway'
                && applicationStatus === 'APPROVED'
                && offer.advertiser.status !== 'INACTIVE'
                && offer.active
                && (!offer.interim_status || offer.interim_status.toUpperCase() !== 'ACTIVE')
                && (
                    <section className="smartlinks-rank">
                        <h2>SmartLinks Ranking</h2>
                        <SmartLinksRanking
                            offer={offer}
                            affiliate={affiliate}
                            history={history}
                            linkLoading={linkLoading}
                            trackingLink={trackingLink}
                            trackingLinkError={trackingLinkError}
                        />
                    </section>
                )
            }

            {!offer.offer_id.includes('LLAMA')
                && (
                    <>
                        {offer.offer_type !== 'product_giveaway'
                            && (
                                <section className="stats">
                                    <h2>Llama Stats</h2>
                                    <Stats
                                        isNew={isNew}
                                        offer={offer}
                                    />
                                </section>
                            )
                        }

                        {applicationStatus === 'APPROVED'
                            && (
                                <section className="coupons">
                                    <h3>Your Coupon Codes</h3>
                                    {offer.discounts && Array.isArray(offer.discounts) && offer.discounts.length > 0
                                        ? (
                                            <Scrollable shadow style={{ height: '300px' }} focusable>
                                                <ul>
                                                    {offer.discounts.map((item) => {
                                                        return <li className="coupon">{item.code}</li>;
                                                    })}
                                                </ul>
                                            </Scrollable>
                                        )
                                        : <p>{offer.advertiser.company} has not created any coupons for you yet.</p>
                                    }
                                </section>
                            )
                        }

                        <section className="about">
                            <h3>About the Brand</h3>
                            <div className="brand-wrapper">
                                <p><PlaceholderPhoto photo={offer.advertiser.avatar_image} alt={`${offer.advertiser.company}’s logo`} height="30" width="30" /></p>
                                <div>
                                    <p className="brand-name">{offer.advertiser.company}</p>
                                    <p className="description">
                                        {offer.advertiser.description}
                                        {offer.advertiser.description && <br />}
                                    </p>
                                    <DiscoverMerchantsLink
                                        totalOffers={offer.advertiser.totalOffers}
                                        history={history}
                                        advertiser_id={offer.advertiser_id}
                                    />
                                    {offer.advertiser.totalOffers === 1 && (
                                        <a href={`//${offer.advertiser.domain}`} target="_blank" rel="noreferrer noopener">View Store<Icon source={ExternalSmallMinor} /></a>
                                    )}
                                </div>
                            </div>
                        </section>
                    </>
                )
            }
        </aside>
    );
};

OfferDetailSidebar.propTypes = {
    offer: PropTypes.shape({
        create_date_utc: PropTypes.string.isRequired,
        offer_id: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        bannerImage: PropTypes.string,
        creatives: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
        name: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        interim_status: PropTypes.string,
        description: PropTypes.string,
        preview_url: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
        rate: PropTypes.shape({
            type: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired
        }).isRequired,
        longterm_rate: PropTypes.shape({
            type: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired
        }).isRequired,
        advertiser: PropTypes.shape({
            company: PropTypes.string.isRequired,
            avatar_image: PropTypes.string,
            description: PropTypes.string,
            domain: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired
        }).isRequired,
        discounts: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string }))
    }).isRequired,
    applicationStatus: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    affiliate: PropTypes.shape({
        tracking_id: PropTypes.string.isRequired,
        cognito_id: PropTypes.string.isRequired
    }).isRequired,
    sponsoredClick: PropTypes.bool
};

OfferDetailSidebar.defaultProps = {
    sponsoredClick: false
};

Promote.propTypes = {
    status: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    offer: PropTypes.shape({
        offer_id: PropTypes.string.isRequired,
        offer_url: PropTypes.string.isRequired,
        advertiser: PropTypes.shape({
            company: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    affiliate: PropTypes.shape({
        tracking_id: PropTypes.string.isRequired
    }).isRequired,
    offerURL: PropTypes.string.isRequired,
    trackingLink: PropTypes.string.isRequired,
    linkLoading: PropTypes.bool.isRequired,
    trackingLinkError: PropTypes.bool.isRequired,
    sponsoredClick: PropTypes.bool
};

Promote.defaultProps = {
    sponsoredClick: false
};

Stats.propTypes = {
    isNew: PropTypes.bool.isRequired,
    offer: PropTypes.shape({
        tracking: PropTypes.shape({
            Stat: PropTypes.shape({
                epc: PropTypes.string,
                revenue: PropTypes.string
            })
        }),
        long_term_rate: PropTypes.shape({
            ltv: PropTypes.string,
            avg_cart_value: PropTypes.string
        }),
        advertiser: PropTypes.shape({
            company: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};

export default OfferDetailSidebar;
