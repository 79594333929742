import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withFormik} from 'formik';
import {isEqual} from 'lodash';
import {
    Page
} from '@shopify/polaris';

import SettingsNavigation from '../components/settings-navigation/settings-navigation';
import { getAffiliateData } from '../store/actions/action_getAffiliate';
import { updateAffiliate } from '../store/actions/action_updateAffiliate';
import { changeHeaderTitle } from '../store/actions/header';
import { uploadFileToS3 } from '../store/actions/upload-s3-file';
import { getOffers } from '../store/actions/action_getOffers';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savedAffiliate: props.affiliate,
            files: [],
            uploading: false,
            showSideDrawer: null,
            selectedCategories: [],
            categories: []
        }
    }

    componentDidMount() {
        if (this.props.affiliate.cognito_id) {
            this.props.getAffiliate(this.props.affiliate.cognito_id);
        }

        this.props.changeHeaderTitle('Settings');
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState !== null && !isEqual(prevState.savedAffiliate, nextProps.affiliate)
            && (null !== nextProps.affiliate || null !== nextProps.affiliate.account)) {
            return {
                ...prevState,
                savedAffiliate: nextProps.affiliate,
                selectedCategories: nextProps.affiliate.account.categories
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.savedAffiliate, this.props.affiliate)
            && (null !== this.props.affiliate || null !== this.props.affiliate.account)) {

            let { photo, email_preference } = this.props.affiliate;
            let { personal, billing, categories } = this.props.affiliate.account;

            let newData = {
                photo,
                email_preference,
                personal,
                billing,
                categories
            };

            sessionStorage.setItem('username', this.props.affiliate.cognito_id);
            this.props.setValues(newData);
        }
    }

    selectAll = () => {
        let preFilter = [];
        this.props.categories.forEach((item) => {
            preFilter.push(item.name)
        });
        this.props.setFieldValue("categories", preFilter, true);
    };

    selectNone = () => {
        this.props.setFieldValue("categories", [], true);
    };

    handleCategorySelection = (category) => {
        const selections = this.state.selectedCategories;

        const selectionIndex = selections.indexOf(category)

        if(selections.length < 3 || selectionIndex !== -1){
            //if the category selected is in selections, remove it, otherwise add it
            if(selectionIndex !== -1){
                selections.splice(selectionIndex, 1);
            }else{
                selections.push(category)
                selections.sort();
            }

            this.setState({
                selectedCategories: selections
            })
        }

        this.props.setFieldValue("categories", selections, true);
    };

    setShowSideDrawer = (showSideDrawer) => {
        this.setState({
            showSideDrawer: showSideDrawer
        })
    }

    saveFile = (file) => {
        this.setState({
            uploading: true
        })

        let creatives = [];
        const creativesInputFields = [
            'name',
            'size',
            'type',
            'url'
        ];

        return uploadFileToS3(file, `affiliates/${this.props.affiliate.cognito_id}/profile`)
            .then((result) => {
                const url = new URL(result);
                const fileURL = url.origin + url.pathname;
                file.url = fileURL;
                const newFile = creativesInputFields.reduce((acc, key) => {
                    if (creativesInputFields.includes(key)) {
                        acc[key] = file[key];
                    }
                    return acc;
                }, {});
                return newFile;
            }).then((result) => {

                let payload = {
                    photo: result.url
                };
                this.props.updateAffiliate(payload, this.props.affiliate.cognito_id);
                this.props.setFieldValue("photo", result.url, true);

                this.setState({
                    uploading: false
                })
                this.setShowSideDrawer(null);
            })

    };


    render() {

        return (
            <Page>
                <SettingsNavigation
                    selectAll={this.selectAll}
                    selectNone={this.selectNone}
                    handleCategorySelection={this.handleCategorySelection}
                    handleNotificationSelection={this.handleNotificationSelection}
                    saveFile={this.saveFile}
                    selectedCategories={this.state.selectedCategories}
                    files={this.state.files}
                    setShowSideDrawer={this.setShowSideDrawer}
                    showSideDrawer={this.state.showSideDrawer}
                    uploading={this.state.uploading}
                    {...this.props}
                />
            </Page>
        )
    }
}

const initialValues = {
    photo: "",
    categories: [],
    personal: {
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: ""
    },
    billing: {
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: ""
    }

};

const submitUpdateAffiliate = (values, {props}) => {
    let data = {
        "photo": values.photo,
        "email_preference": values.email_preference,
        "account": {
            "personal": values.personal,
            "billing": values.billing,
            "categories": values.categories
        }
    };
    props.updateAffiliate(data, props.affiliate.cognito_id);
};

const MyForm = withFormik({
    mapPropsToValues: (props) => {
        return {...initialValues, ...props.affiliate.photo, ...props.affiliate.account ,...props.affiliate}
    },
    handleSubmit: submitUpdateAffiliate
})(Settings);

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate,
        categories: state.app.categories,
        countries: state.app.countries,
        offers: state.offers,
        ...state
    }

};

const mapDispatchtoProps = (dispatch) => {

    return {
        getAffiliate: (id) => dispatch(getAffiliateData(id)),
        updateAffiliate: (data, id) => dispatch(updateAffiliate(data, id)),
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
        getOffers: (...params) => dispatch(getOffers(...params)),
    }

};


export default connect(mapStateToProps, mapDispatchtoProps)(MyForm);

