import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
    Page
} from '@shopify/polaris';
import {EmptyState} from 'llama-library/components';

import {changeHeaderTitle} from '../store/actions/header';
import {getOffers} from '../store/actions/action_getOffers';
import useReduxAction from '../hooks/use-redux-action';

import OfferDetailSidebar from '../components/OfferDetail/offer-detail-sidebar'
import OfferPromotionDetails from '../components/OfferDetail/offer-promotion-details'
import OfferDetailSkeleton from '../components/OfferDetail/offer-detail-skeleton'

import './styles/offer-detail.css';

/**
 * This is the version of offer detail page where the ambassador is approved. It's utilizing a custom hook to get the offer info from redux instead of graphql which is why it's in a different file
 * If you're looking for the non-approved version, it's in ./offer-detail-discover.js
 * Both of these containers call the same child components
 *
 * @link /offer/:id
 */
const OfferDetail = (props) => {
    const {history} = props;

    //persist the sort order from the your offers page
    let offerListState = {};
    if(window.history.state && window.history.state.state){
        offerListState = window.history.state.state;
    }
    const [persistedState] = useState(offerListState);

    useEffect(() => {
        props.changeHeaderTitle("Offer Details");
    }, [])

    // console.log(props);

    // Call getOffers within Redux to update global state with current offers,
    // those offers are used to render these pages.
    const offer_id = props.match.params.id;
    const [filters, setFilters] = useState({ offer_id });
    const [links] = useState({ things: "stuff"});
    const reduxResult = useReduxAction(props.getOffers, [props.affiliate, filters, links]);

    const [isLoadingLocal, setIsLoadingLocal] = useState(true);
    const [applicationStatus, setApplicationStatus] = useState('NEVER_APPLIED');
    const [offer, setOffer] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        // Find the current offer.
        const offerData = props.offers.length > 0 && props.offers.find((item) => {
            return item.offer.offer_id === offer_id;
        })

        if(offerData){
            setIsEmpty(false)
            const { offer, application, advertiser } = offerData;
            offer.advertiser = advertiser;

            //if they're not approved, redirect them to the discover version
            if(application.advertiser_status !== 'APPROVED') {
                props.history.push('/discover/offer/'+offer_id);
            }


            setApplicationStatus(application.advertiser_status);
            setOffer(offer);
            if(offer.product_info || offer.target){
                setIsLoadingLocal(false);
            }
        }else{
            setIsEmpty(true)
        }
    }, [props.offers, offer_id])

    const goToOffers = () => {
        history.push('/offers', persistedState);
    }

    if(isEmpty && reduxResult.value !== null){
        return <EmptyState
            message="Sorry, I couldn’t find that offer"
            paragraph="Try selecting a different offer"
            ctaText="Your Offers"
            ctaAction={goToOffers}
        />
    }

    if(isLoadingLocal){
        return <OfferDetailSkeleton />
    }

    return (
        <Page
            breadcrumbs={[{
                content: 'Your Offers',
                onAction: goToOffers
            }]}
        >
            <div className="offer-detail__offer-wrapper">
                <OfferDetailSidebar
                    from="OFFERS"
                    offer={offer}
                    applicationStatus={applicationStatus}
                    history={history}
                    affiliate={props.affiliate}
                />
                <OfferPromotionDetails
                    from="OFFERS"
                    offer={offer}
                    applicationStatus={applicationStatus}
                    history={history}
                    affiliate={props.affiliate}
                    {...props}
                />
            </div>
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate,
        offers: state.offers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
        getOffers: (...params) => dispatch(getOffers(...params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetail);