const absoluteUrl = `https://${process.env.REACT_APP_ENV}.llama.app/1.120`;

const config = {
    LAMBDA_GENERAL: `llama-g50next-backend-${process.env.REACT_APP_ENV}-general`,
    LAMBDA_MERCHANT: `llama-g50next-backend-${process.env.REACT_APP_ENV}-merchant`,
    LAMBDA_COGNITO: `llama-g50next-backend-${process.env.REACT_APP_ENV}-cognito-presignup`,
    NEXT_APP_API_URL: absoluteUrl + '/general/graph',
    NEXT_APP_SMARTLINK_URL: process.env.REACT_APP_SMARTLINK_URL,
    NEXT_APP_PAYPAL_AUTH_URL: absoluteUrl + '/merchant/getUrl',
    NEXT_APP_PAYPAL_AUTH_TOKEN: absoluteUrl + '/merchant/createAuthToken',
    NEXT_APP_COGNITO_VALIDATION: absoluteUrl + '/general/cognito-user',
    NEXT_APP_SOCIAL_REDIRECT_URL: absoluteUrl + '/general/social-request',
    NEXT_APP_API_TWITTER_AUTH_URL: absoluteUrl + '/twitter-auth',
    NEXT_APP_API_TWITTER_GET_TOKEN_URL: absoluteUrl + '/twitter-getToken',
    NEXT_S3_DOWNLOAD_URL: absoluteUrl + `/general/downloadCreative`,
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    PAYPAL_IDENTITY_URL: process.env.REACT_APP_PAYPAL_IDENTITY_URL,
    W9_WIDGET_URL: `https://secure.na1.echosign.com/public/esignWidget?wid=${process.env.REACT_APP_W9_WIDGET_KEY}&hosted=false`,
    HAS_OFFERS_URL: process.env.REACT_APP_HAS_OFFERS_URL,
    CHAT_SOCKET_URI: process.env.REACT_APP_CHAT_SOCKET_URI,
    PER_PAGE: {
        OFFERS: 12,
        CHAT_MESSAGES: 20
    }
};

module.exports = config;
