import { sendBackendRequest } from './sendRequest';

export const USER_VALIDATION_PENDING = 'USER_VALIDATION_PENDING';
export const USER_VALIDATION_FULFILLED = 'USER_VALIDATION_FULFILLED';
export const USER_VALIDATION_REJECTED = 'USER_VALIDATION_REJECTED';

export const cognitoUserValidation = (token) => {
    const payload = {
        'AccessToken': token
    }

    return {
        type: 'USER_VALIDATION',
        payload: sendBackendRequest('general', 'cognito-user', payload)
    }
}
