import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { PlaceholderPhoto } from 'llama-library/components';

import { RateTypes } from '../../utils/types';
import { moneyFormat } from '../../utils/number-format';

import './featured-offers.css';

const OfferSnippet = ({ offer }) => {
    const formatRate = ({ type, amount }) => {
        switch (type) {
            case RateTypes.FLAT_ITEM:
                return <strong>{`${moneyFormat(amount, true)} per item`}</strong>;
            case RateTypes.FLAT_ORDER:
                return <strong>{`${moneyFormat(amount, true)} per order`}</strong>;
            case RateTypes.PERCENT_REVENUE:
            default:
                return <strong>{`${amount}% of revenue`}</strong>;
        }
    };

    const toOfferDetail = (offer.sponsored && offer.sponsored === 'ACTIVE')
        ? {
            pathname: `/discover/offer/${offer.offer_id}`,
            state: { recordSponsoredClick: true }
        }
        : `/discover/offer/${offer.offer_id}`;

    return (
        <>
            <p className="merchant-logo"><Link to={toOfferDetail}><PlaceholderPhoto photo={offer.advertiser.avatar_image} alt={offer.advertiser.company} /></Link></p>
            <div className="offer-info">
                <p className="category">{offer.category}</p>
                <p className="name">
                    <Link to={toOfferDetail}>{offer.name}</Link>
                    {
                        offer.sponsored && offer.sponsored === 'ACTIVE' &&
                        <>&nbsp;<span className="sponsored" aria-label="Sponsored listing">Ad</span></>
                    }
                </p>

                <p className="commission-rate">
                    Earn {formatRate(offer.rate)} on
                    {offer.rate.amount === offer.longterm_rate.amount && offer.rate.type === offer.longterm_rate.type
                        ? ' all orders.'
                        : <> initial orders and {formatRate(offer.longterm_rate)} on future orders.</>
                    }
                </p>

                <p className="details"><Link to={toOfferDetail}>View Details</Link></p>
            </div>
        </>
    );
};

const FeaturedOffers = ({ offers }) => {
    return (
        <section className="dashboard--featured-offers" data-test="featured-offers-container">
            <h2 data-test="featured-offers-title">Featured Offers</h2>
            <p className="info" data-test="featured-offers-info">Not happy with how your current offers are performing? Try applying for one of these offers.</p>
            {offers.length > 0
                && (
                    <ul className="offers" data-test="featured-offers-list">
                        {offers.map((offer) => {
                            return (
                                <li key={offer.offer.offer_id} className="offer-detail-snippet">
                                    <OfferSnippet offer={offer.offer} />
                                </li>
                            );
                        })}
                    </ul>
                )
            }
            <p className="more-offers">Find more offers in <Link to="/discover">Discover</Link></p>
        </section>
    );
};

const offerProps = {
    offer_id: PropTypes.string.isRequired,
    advertiser: PropTypes.shape({
        avatar_image: PropTypes.string,
        company: PropTypes.string.isRequired
    }).isRequired,
    category: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rate: PropTypes.shape({
        amount: PropTypes.string,
        type: PropTypes.oneOf(Object.values(RateTypes))
    }),
    longterm_rate: PropTypes.shape({
        amount: PropTypes.string,
        type: PropTypes.oneOf(Object.values(RateTypes))
    })
};

OfferSnippet.propTypes = {
    offer: PropTypes.shape(offerProps).isRequired
};

FeaturedOffers.propTypes = {
    offers: PropTypes.arrayOf(PropTypes.shape({
        offer: PropTypes.shape(offerProps)
    })).isRequired
};

export default React.memo(FeaturedOffers);
