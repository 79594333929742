import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import config from '../../config';

/**
 * Sends a post body or query string to a backend function using lambda invoke. User must be logged in for this to work
 * @param {String} handler A short name for the lambda function name (for now this only works with general and merchant handlers but more can be added in the future as needed)
 * @param {String} path The proxy path (use the case value in the switch statement in the handler file)
 * @param {Object} params The post body or query string parameters
 * @param {Boolean} isAsync Set to true if a response is not relied on
 * @param {Enum (POST, GET, PUT)} method 
 */
export const sendBackendRequest = (handler, path, params, isAsync = false, method = 'POST') => {
    let functionName = '';
    switch (handler) {
        case 'general':
            functionName = config.LAMBDA_GENERAL
            break;

        case 'merchant':
            functionName = config.LAMBDA_MERCHANT;
            break;

        case 'cognito-presignup':
            functionName = config.LAMBDA_COGNITO;
            break;

        default:
            console.log('No handler defined');
            break;
    }
    console.log('sendBackendRequest :: CONFIG:', functionName);
    if (!functionName && !path) return false;

    const payload = {
        httpMethod: method,
        body: JSON.stringify(params),
        pathParameters: { proxy: path }
    }
    if (method === 'GET') {
        payload.queryStringParameters = params;
    }
    const lambdaParams = {
        FunctionName: functionName,
        InvocationType: (isAsync) ? 'Event' : 'RequestResponse',
        Payload: JSON.stringify(payload)
    }

    return new Promise((resolve, reject) => {
        //console.time('AUTH GET CREDENTIALS TIME' + JSON.stringify(params))
        return Auth.currentCredentials()
            .then(credentials => {
                //console.timeEnd('AUTH GET CREDENTIALS TIME' + JSON.stringify(params))
                //console.time('LAMBDA INVOKE TIME' + JSON.stringify(params))

                //this is the same as Auth.essentialCredentials(credentials), but it's a tiny bit faster
                const cr = {
                    accessKeyId: credentials.accessKeyId,
                    authenticated: credentials.authenticated,
                    identityId: credentials.identityId,
                    secretAccessKey: credentials.secretAccessKey,
                    sessionToken: credentials.sessionToken
                }

                const lambda = new AWS.Lambda({
                    credentials: cr,
                    region: 'us-east-1'
                })

                return lambda.invoke(lambdaParams, (err, data) => {
                    //console.timeEnd('LAMBDA INVOKE TIME' + JSON.stringify(params))
                    //console.log('>>>LAMBDA INVOKE', err, data);
                    if (err) reject(err);
                    if (isAsync) resolve(data)
                    if (data && data.Payload) {
                        const payload = JSON.parse(data.Payload);
                        if (payload.body) {
                            const response = JSON.parse(payload.body);
                            console.log(payload.body);
                            resolve({ data: response });
                        } else {
                            reject(payload)
                        }
                    } else {
                        reject(data)
                    }
                });
            })
    })
}

/**
 * Shortcut function for graphql specific requests
 * @param {Object} params {query, variables}
 * @param {Boolean} isAsync Set to true if a response is not relied on
 */
export const sendGraphQL = (params, isAsync = false) => {
    if (params && params.variables && params.variables.data) {
        // alert("IN send GraphQL request");
        // alert(JSON.stringify(params.variables.data));
    }
    return sendBackendRequest('general', 'graph', params, isAsync)
}