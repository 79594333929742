import React from 'react';
import PropTypes from 'prop-types';

import './DiscoverMerchantsLink.css';

const DiscoverMerchantsLink = ({ totalOffers, history, advertiser_id }) => {

    if (totalOffers && totalOffers > 1) {
        const navigateToMerchantOffers = () => {
            history.push('/');
            history.replace(`discover/brand/${advertiser_id}`);
        };

        return (
            <div className="DiscoverMerchantsLink">
                <p>{`This brand has ${totalOffers} offers`}</p>
                <p className="offers-page-link" onClick={navigateToMerchantOffers}>View All</p>
            </div>
        );
    }
    return null;
};

DiscoverMerchantsLink.propTypes = {
    totalOffers: PropTypes.number.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
    }).isRequired,
    advertiser_id: PropTypes.string.isRequired
};

export default DiscoverMerchantsLink;
