/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 * */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Applications from '../components/Applications';
import './styles/applications.css';
import { getApplications } from '../store/actions/action_getApplications';
import { changeHeaderTitle } from '../store/actions/header';
import useApplications from '../hooks/use-applications';

const ApplicationsContainer = (props) => {
    useEffect(() => {
        props.changeHeaderTitle('Your Offers');
    }, []);

    const [searchTerm, setSearchTerm] = useState('');
    const { affiliate } = props;

    let cognito_id;
    if (affiliate && affiliate.cognito_id) {
        cognito_id = affiliate.cognito_id;
    }

    const { isLoadingApplications } = useApplications(cognito_id, props.getApplications);

    if (!affiliate) {
        return null;
    }

    const { applications } = affiliate;

    let filteredApplications = applications && applications
        .filter((item) => {
            if (!item.advertiser) {
                return false;
            }

            const offerName = item.offer.name;
            const caseSensitiveName = offerName.toLowerCase();

            const advertiserName = item.advertiser.name;
            const caseSenstiveAdvertiser = advertiserName.toLowerCase();

            if (searchTerm === null) {
                return true;
            }

            if (offerName.includes(searchTerm)) {
                return true;
            }

            if (caseSensitiveName.includes(searchTerm)) {
                return true;
            }

            if (advertiserName.includes(searchTerm)) {
                return true;
            }

            if (caseSenstiveAdvertiser.includes(searchTerm)) {
                return true;
            }

            return false;
        }).sort((a, b) => {
            console.log(typeof a.created_at, typeof b.created_at);
            if (new Date(a.created_at) < new Date(b).created_at) {
                return 1;
            }
            if (new Date(b.created_at) < new Date(a.created_at)) {
                return -1;
            }
            return 0;
        });

    return (
        <>
            <Applications
                isLoading={isLoadingApplications || !filteredApplications}
                applications={filteredApplications}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                history={props.history}
            />
        </>
    );
};

const mapStateToProps = ({ affiliate }) => {
    return { affiliate };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getApplications: (id) => dispatch(getApplications(id)),
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsContainer);
