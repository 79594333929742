import React, { useState } from 'react';
import { TextField, Spinner } from '@shopify/polaris';
import PropTypes from 'prop-types';

import hypePhoneScreen from '../../assets/hypescreen_iphoneX.png';
import checkIcon from '../../assets/check-pink.svg';
import rightCarot from '../../assets/right-carot-pink.svg';

import './hype-upsell.css';

const hypeHighlights = [
    'Full breakdown of your audience',
    'Evaluation of your true reach',
    'Branded post analysis',
    'Follow/unfollow patterns analysis',
    'Organic growth check',
    'Full engagement analysis'
];

const HypeUpsell = (props) => {
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const {
        cognito_id,
        addUser,
        hasInactiveInstagram,
        setIsAddUserClicked,
        setSelectedAccount,
        setNextUserAccount,
        initiateInstagramV2Trial,
        toggleUpgrade
    } = props;

    const handleUsernameChange = (value) => {
        setErrorMessage('');
        setUsername(value.trim());

        if (addUser || hasInactiveInstagram) {
            setNextUserAccount(value.trim());
        }
    };

    const navigateToDemo = () => {
        props.history.push('/instaAnalyticslocked');
    };

    const handleCreateAccount = () => {
        initiateInstagramV2Trial(username, cognito_id)
            .then((result) => {
                setIsLoading(false);
                // Make sure we're getting a response
                // if there are no errors on the response
                // show HypeUpsell__Success div
                if (
                    result.value.data.createInstagramUserV2Trial
                    && !result.value.data.createInstagramUserV2Trial.error
                    && result.value.data.createInstagramUserV2Trial !== null
                ) {
                    setSelectedAccount(username);
                } else if (
                    result.value.data.createInstagramUserV2Trial !== null
                    && result.value.data.createInstagramUserV2Trial.error
                    && result.value.data.createInstagramUserV2Trial.error.message
                ) {
                    setErrorMessage(result.value.data.createInstagramUserV2Trial.error.message);
                } else {
                    setErrorMessage('Error. Problem with the request');
                }
            })
            .catch((err) => {
                console.log(err);
                setErrorMessage('Error. Unable to create trial');
                setIsLoading(false);
            });
    };

    const validate = () => {
        let validationResult = true;

        if (!cognito_id) {
            setErrorMessage('Oops, something went wrong.');
            validationResult = false;
        }

        if (!username || username === '') {
            setErrorMessage('Please enter your username.');
            validationResult = false;
        }

        const { affiliate: { instagramDataV2 } } = props;

        if (instagramDataV2 && Array.isArray(instagramDataV2) && instagramDataV2.length > 0) {
            const accountIndex = instagramDataV2.findIndex(data => data.username === username
                && (data.account_type.toUpperCase() === 'TRIAL'
                    || data.account_type.toUpperCase() === 'ACTIVE'));

            if (accountIndex !== -1) {
                let accountType = instagramDataV2[accountIndex].account_type.toLowerCase();
                accountType = accountType.charAt(0).toUpperCase() + accountType.slice(1);

                validationResult = false;
                setErrorMessage(`${accountType} subscription already present for this user!`);
            }
        }

        return validationResult;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        setIsLoading(true);

        if (addUser) {
            toggleUpgrade();
        } else if (hasInactiveInstagram) {
            setIsAddUserClicked(true);
            toggleUpgrade();
        } else {
            handleCreateAccount();
        }
    };

    if (!cognito_id) {
        return null;
    }

    const submitButtonLabel = (addUser || hasInactiveInstagram)
        ? 'Link Account'
        : 'Link Account and Start Free Trial';

    return (
        <div className="HypeUpsell__Card">
            <div className="HypeUpsell__TextWrapper">
                <h1 className="HypeUpsell__Section-Header">Link Your Instagram Account</h1>
                <h3 className="HypeUpsell__SubHeader">Enhanced with InstaAnalytics</h3>
                <p className="HypeUpsell__P">We'll automatically apply your follower count and engagement stats to your Llama profile. We'll also add deep analytics to your profile free for 30 days*:</p>
                {/* <p className="HypeUpsell__P">Show prospective clients that your audience is organic and engaged. Get more collaborations with top brands.</p> */}
            </div>
            <div className="HypeUpsell__FlexSection">
                <div className="HypeUpsell__ListWrapper">
                    {hypeHighlights.map((item, index) => {
                        return (
                            <div key={index} className="HypeUpsell__ListItem">
                                <img src={checkIcon} className="HypeUpsell__CheckIcon" />
                                <p className="HypeUpsell__ListItemName">{item}</p>
                            </div>
                        );
                    })}
                    <div className="HypeUpsell__SecondaryCTA" onClick={navigateToDemo}>
                        <p className="HypeUpsell__SecondaryCTAText">View Demo</p>
                        <img src={rightCarot} className="HypeUpsell__RightCarot" />
                    </div>
                </div>
                <img src={hypePhoneScreen} className="HypeUpsell__Phone" />
            </div>
            <div className="HypeUpsell__PlanSelection">
                No credit card required for InstaAnalytics. We’ll ask if you want to continue at the end of the 30-day period for $0.99/month &#40;or $0.60/month if paid annually&#41;, but there’s no obligation.
            </div>
            <div className="HypeUpsell__CTASection">
                <div className="HypeUpsell__CTAInput">
                    <TextField
                        label="Instagram Username"
                        value={username}
                        onChange={handleUsernameChange}
                        error={errorMessage}
                    />
                </div>
                {isLoading
                    ? <Spinner />
                    : (
                        <div className="HypeUpsell__CTA_Wrapper">
                            <div id="insta-submit" className="HypeUpsell__CTA" onClick={handleSubmit}>
                                {submitButtonLabel}
                            </div>
                        </div>
                    )}
                <p className="HypeUpsell__PlanDisclaimerFooter">
                    *Your card will not be charged during this 30-day free trial period. After the trial is over you will be
                    given the option to upgrade to the paid plan in order to keep displaying your most
                    current Instagram stats. If you decline, you will no longer have access to your Instagram Analytics stats.
                </p>
            </div>
        </div>
    );
};

HypeUpsell.propTypes = {
    cognito_id: PropTypes.string.isRequired,
    addUser: PropTypes.bool.isRequired,
    hasInactiveInstagram: PropTypes.bool.isRequired,
    setIsAddUserClicked: PropTypes.func.isRequired,
    setSelectedAccount: PropTypes.func.isRequired,
    setNextUserAccount: PropTypes.func.isRequired,
    initiateInstagramV2Trial: PropTypes.func.isRequired,
    toggleUpgrade: PropTypes.func.isRequired
};

export default HypeUpsell;
