/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from './sendRequest';

const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATIONS_PENDING = `${GET_APPLICATIONS}_PENDING`;
export const GET_APPLICATIONS_FULFILLED = `${GET_APPLICATIONS}_FULFILLED`;
export const GET_APPLICATIONS_REJECTED = `${GET_APPLICATIONS}_REJECTED`;

export function getApplications(cognito_id) {
    if (!cognito_id) {
        return null;
    }

    const query = `
        query affiliates($cognito_id: String) {
            affiliateById(cognito_id: $cognito_id) {
                applications {
                    application_id
                    affiliate_status
                    advertiser_status
                    advertiser {
                        advertiser_id
                        status
                        name
                        email
                        avatar_image
                    }
                    offer {
                        offer_id
                        name
                        interim_status
                        interim_status_date
                        rate {
                            type
                            amount
                        }
                        longterm_rate {
                            type
                            amount
                        }
                    }
                    affiliate_favorite
                    created_at
                }
            }
        }
    `;

    const variables = { cognito_id };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_APPLICATIONS,
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
    };

    /* const headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: GET_APPLICATIONS,
        payload: axios.post( NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */

}
