import { useState, useEffect } from 'react';

import { getTransactionReport } from '../store/actions/get-transaction-report';

/**
 * @hook useTransactionReport
 *
 * Retrieves a transaction report to display detailed order information.  Manages
 * the loading state, error state, and pagination information.
 *
 * @param {String} userID - Advertiser or Affilaite ID for user
 * @param {Object} filters - Query object for API Call
 * @param {Number} ordersPerPage - Number of orders to get from API Call
 *
 * @example
 * ```javascript
 *  const userID = '123';
 *  const ordersPerPage = 10;
 *
 *  const now = new Date();
 *  now.setHours(12, 0, 0);
 *  const startDate = new Date('2019-01-01T12:00:00');
 *
 *  const filters = {
 *      offer_id: '456',
 *      start_date: startDate.toISOString(),
 *      end_date: now.toISOString(),
 *  }
 *
 *  const {
 *      pageInfo,
 *      onNext,
 *      onPrevious,
 *      orders,
 *      isLoading,
 *      errorMessage,
 *  } = useTransactionReport(userID, filters, ordersPerPage);
 * ```
 */
const useTransactionReport = (userID, filters, ordersPerPage = 10) => {

    const defaultPageOptions = {
        skip: 0,
        limit: ordersPerPage,
        sortBy: null,
    }

    const [orders, setOrders] = useState(null);
    const [pageInfo, setPageInfo] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [pageOptions, setPageOptions] = useState(defaultPageOptions);
    const [isLoading, setIsLoading] = useState(false);

    const makeAPICall = (userIDParam, filtersParam, pageOptionsParam) => {
        if (!isLoading && userIDParam) {
            setIsLoading(true);
            getTransactionReport(userIDParam, filtersParam, pageOptionsParam)
                .then(({ reports }) => {
                    setIsLoading(false);
                    if (reports && reports.orders && Array.isArray(reports.orders.edges)) {
                        setOrders(reports.orders.edges);
                        setPageInfo(reports.orders.pageInfo);
                        setErrorMessage(null);
                    }
                    return null;
                })
                .catch((error) => {
                    setIsLoading(false);
                    const { message } = error;
                    if (message === 'Network Error') {
                        setErrorMessage("Oh no! It looks likes something is wrong with your connection.")
                        return;
                    }

                    if (message) {
                        setErrorMessage(message);
                        return;
                    }

                    setErrorMessage('OH NO! Something went wrong.');
                })
        }
    }

    // Makes initial API call.
    // Refetches when userID or pageOptions are changed.
    useEffect(() => {
        if (filters) {
            makeAPICall(userID, filters, pageOptions);
        }
    }, [userID, pageOptions]);

    const { pages } = pageInfo;

    const onNext = (currentPage) => {
        if (currentPage > pages) {
            const newPage = currentPage + 1;
            const skip = newPage * ordersPerPage;
            setPageOptions({ ...pageOptions, skip });
            setPageInfo({
                ...pageInfo,
                hasNextPage: false,
                hasPreviousPage: false,
                currentPage: pageInfo.currentPage + 1
            })
        }
    }

    const onPrevious = (currentPage) => {
        if (currentPage !== 0) {
            const newPage = currentPage - 1;
            const skip = newPage * ordersPerPage;
            setPageOptions({ ...pageOptions, skip });
            setPageInfo({
                ...pageInfo,
                hasNextPage: false,
                hasPreviousPage: false,
                currentPage: pageInfo.currentPage - 1
            })
        }
    }

    return {
        pageInfo: { ...pageInfo, ordersPerPage },
        onNext,
        onPrevious,
        orders,
        isLoading,
        errorMessage,
    }

}

export default useTransactionReport;

