import React from 'react';
import { Link } from 'react-router-dom';
import { Select, TextField } from '@shopify/polaris';

const platforms = [
    {
        type: 'instagram',
        name: 'Instagram',
        helpPhrase: 'Instagram account'
    },
    {
        type: 'facebook',
        name: 'Facebook Page',
        helpPhrase: 'Facebook Page'
    },
    {
        type: 'twitter',
        name: 'Twitter',
        helpPhrase: 'Twitter account'
    },
    {
        type: 'youtube',
        name: 'YouTube Channel',
        helpPhrase: 'YouTube channel'
    },
    {
        type: 'tiktok',
        name: 'TikTok',
        helpPhrase: 'TikTok account'
    }
];

const SmartlinksSocialSkeleton = () => {
    return (
        <>
            <fieldset>
                <legend>Social Accounts</legend>
                <ul id="smartlink-customize-social-list">
                    {platforms.map((platform) => {
                        return (
                            <li key={platform.type} className={platform.type}>
                                <Select
                                    label={platform.name}
                                    options={[{ value: 'loading', label: 'loading...' }]}
                                    value="loading"
                                    disabled
                                    helpText={<>To connect a new {platform.helpPhrase}, go to your <Link to="/settings/social">Settings</Link></>}
                                />
                            </li>
                        );
                    })}

                    <li className="soundcloud">
                        <TextField
                            label="SoundCloud"
                            prefix="https://soundcloud.com/"
                            value="loading..."
                            disabled
                        />
                    </li>
                </ul>
            </fieldset>
        </>
    );
};

export default SmartlinksSocialSkeleton;
