/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: April 25, 2019
 * */

import { sendGraphQL } from './sendRequest';

export const getAffliateApprovedOffers = (id, filterInput) => {
    const query = `query getOffersForAffiliate($congito_id: String, $filters: AffiliateOfferFilterInput) {
        affiliateById(cognito_id: $congito_id){
            tracking_id
            offers(filters: $filters) {
                application {
                    tracking_link
                }
                offer {
                    offer_id
                    name
                    category
                    offer_url
                }
                advertiser{
                    company
                }
            }
        }
    }`;

    const variables = {
        congito_id: id,
        filters: filterInput
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'GET_AFFILIATE_OFFERS',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

export default getAffliateApprovedOffers;
