import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'qs';


const DEFAULT_OPTIONS = `toolbar='no', location='no', directories='no', status='no', menubar='no', scrollbars='yes', resizable='yes', width=530, height=500, top=(o, w) => (w.innerHeight - o.height) / 2 + w.screenY, left=(o, w) => (w.innerWidth - o.width) / 2 + w.screenX`;
var newWindow;
var authCodeInterval;

class SocialAuthComponent extends Component {
  state = {
    isPoppedOut: false,
    socialAuthData: {},
    activePlatform: '',
    responseParentSocialAccounts: this.props.responseParentSocialAccounts
  };
  
  constructor(props) {
      super(props);
      console.log('STATE', this.state);
    this.state.socialAuthData = this.getAuthData();
    this.state.activePlatform = this.getActivePlatform();
  }

  getActivePlatform = () => {
      let retPlatform = '';

      if (this.props.match && this.props.match.params) {
          retPlatform = this.props.match.params.type;
      } else if (this.props.data) {
          retPlatform = this.props.data.platform;
      } else {
          retPlatform = '';
      }
      console.log('getActivePlatform retPlatform', retPlatform);
      return retPlatform;
  }

  getAuthCode() {
    let queryString = qs.parse(this.props.location.search);
    let code;

    switch (this.getActivePlatform()) {
      case 'facebook':
      case 'instagram':
      case 'youtube':
        code = '';
        if (queryString.code || queryString['?code']) {
          if (queryString.code) {
            code = queryString.code
            console.log('getAuthCode code:', code);
          } else {
            code = queryString['?code']
            console.log('getAuthCode code:', code);
          }
        }
        break;
      case 'twitter':
        code = {
          oauth_token: '',
          oauth_verifier: ''
        };
        if ((queryString.oauth_token || queryString['?oauth_token'])
          && (queryString.oauth_verifier || queryString['oauth_verifier'])) {
          if (queryString.oauth_token) {
            code.oauth_token = queryString.oauth_token
          } else {
            code.oauth_token = queryString['?oauth_token']
          }

          if (queryString.oauth_verifier) {
            code.oauth_verifier = queryString.oauth_verifier
          } else {
            code.oauth_verifier = queryString['oauth_verifier']
          }
        }
        break;
    }
    console.log('getAuthCode code:', code);
    return code;
  }

  isAuthCodeAvailable = () => {
      let authValue = JSON.parse(localStorage.getItem('socialAuthData'));
      if (authValue) {
          this.props.responseParentSocialAccounts(authValue);
      }
  }

  componentDidMount() {
    if (window.location.href.includes('callback')) {
      let authData = {};

      if (window.location.href.includes('?code=')) {
        authData = {
          platform: this.state.activePlatform,
          code: this.getAuthCode()
        };
        console.log('componentDidMount: authData', authData);
      } else if (window.location.href.includes('oauth_token=')
        && window.location.href.includes('oauth_verifier=')) {
        authData = {
          platform: this.state.activePlatform,
          code: this.getAuthCode()
        };
        console.log('componentDidMount: authData', authData);
      }
      console.log('componentDidMount: authData', authData);
      localStorage.setItem('socialAuthData', JSON.stringify(authData));
    }
    else if (!this.state.isPoppedOut) {
      authCodeInterval = setInterval(this.isAuthCodeAvailable, 2000);
      this.popout();
    }
  }

  componentWillUnmount() {
      console.log('componentWillUnmount');
      this.popoutClosed();
      clearInterval(authCodeInterval);
      newWindow.close();
      localStorage.removeItem('socialAuthData');
  }

  getAuthData = () => {
      return this.props.data ? (this.props.data.socialAuthData ? this.props.data.socialAuthData : {}) : {};
  }

  popout = () => {
      let w = 530;
      let h = 500;

      if (this.props.data.platform === 'facebook') {
          w = 610;
          h = 760;
      }
      newWindow = window.open(this.state.socialAuthData.redirectUrl, "_blank", DEFAULT_OPTIONS.replace(/width=\d+, height=\d+/, `width=${w}, height=${h}`));
      console.log('popout redirectUrl', this.state.socialAuthData.redirectUrl);
      this.setState({ isPoppedOut: true });
  }

  popoutClosed = () => {
      console.log('popoutClosed')
      this.setState({ isPoppedOut: false });
  }

  render() {
      return null;
  }
}

const mapStateToProps = (state) => {
  return { ...state };
}

const mapDispatchtoProps = (dispatch) => {
  return {}
}

SocialAuthComponent.propTypes = {};

export default connect(mapStateToProps, mapDispatchtoProps)(SocialAuthComponent);
