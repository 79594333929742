/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 * */

import { GET_AFFILIATE_FULFILLED } from '../actions/action_getAffiliate';
import { GET_APPLICATIONS_FULFILLED } from '../actions/action_getApplications';
import { APPLY_TO_ADVERTISER_FULFILLED } from '../actions/action_applyToAdvertiser';
import { GET_EMAIL_PREFERENCE_FULFILLED } from '../actions/action_getEmailPreference';
import { UPDATE_NOTIFICATIONS_VIEW_STATUS } from '../actions/action_getNotifications';
import { UPDATE_AFFILIATE_FULFILLED } from '../actions/types';
import { GET_INSTAGRAM_V2_FULFILLED, INITIATE_INSTAGRAM_V2_TRIAL_FULFILLED, UPGRADE_INSTAGRAM_V2_FULFILLED, CANCEL_INSTAGRAM_V2_FULFILLED } from '../actions/instagram-v2';
import { GET_TIKTOK_FULFILLED, INITIATE_TIKTOK_TRIAL_FULFILLED, UPGRADE_TIKTOK_FULFILLED, CANCEL_TIKTOK_FULFILLED, VERIFY_TIKTOK_USER_FULFILLED } from '../actions/tiktok';

const defaultState = {
    cognito_id: null,
    account: {},
    facebookData: {},
    youTubeData: {},
    instagramData: '',
    tikTokData: '',
    token: {},
    tracking_id: null,
    twitterData: {},
    applications: null,
    email_preference: null
};

const getAffiliateReducer = (state = defaultState, action) => {

    const { type, payload } = action;

    switch (type) {

        case GET_AFFILIATE_FULFILLED:
        case GET_EMAIL_PREFERENCE_FULFILLED:
        case GET_INSTAGRAM_V2_FULFILLED:
        case GET_TIKTOK_FULFILLED:
        case GET_APPLICATIONS_FULFILLED: {
            if (
                !payload
                || !payload.data
                || !payload.data.affiliateById
            ) {
                return state;
            }

            return { ...state, ...action.payload.data.affiliateById };
        }

        case INITIATE_INSTAGRAM_V2_TRIAL_FULFILLED: {

            const { data = {} } = payload;
            const { createInstagramUserV2Trial } = data;

            if (!createInstagramUserV2Trial || createInstagramUserV2Trial.error) {
                return state;
            }

            const { instagramDataV2 = [] } = state;
            const newInstagramDataV2 = instagramDataV2.slice();

            const accountIndex = newInstagramDataV2.findIndex((newData) => {
                return newData.username === createInstagramUserV2Trial.username;
            });

            if (accountIndex !== -1) {
                newInstagramDataV2[accountIndex] = createInstagramUserV2Trial;
            } else {
                newInstagramDataV2.push(createInstagramUserV2Trial);
            }

            return { ...state, instagramDataV2: newInstagramDataV2 };

        }

        case INITIATE_TIKTOK_TRIAL_FULFILLED: {

            const { data = {} } = payload;
            const { createTikTokUser } = data;

            if (!createTikTokUser || createTikTokUser.error) {
                return state;
            }

            const { tikTokData = [] } = state;
            const newTikTokData = tikTokData.slice();

            const accountIndex = newTikTokData.findIndex((newData) => {
                return newData.username === createTikTokUser.username;
            });

            if (accountIndex !== -1) {
                newTikTokData[accountIndex] = createTikTokUser;
            } else {
                newTikTokData.push(createTikTokUser);
            }

            return { ...state, tikTokData: newTikTokData };

        }

        case CANCEL_INSTAGRAM_V2_FULFILLED:
        case UPGRADE_INSTAGRAM_V2_FULFILLED: {

            const { data = {} } = payload;
            const { upgradeInstagramUserV2, cancelInstagramUserV2 } = data;

            const updateInstagramV2Account = (account) => {

                if (!account || !account.username || account.error) {
                    return state;
                }

                if (!Array.isArray(state.instagramDataV2) || state.instagramDataV2.length === 0) {
                    return state;
                }

                const activeAccountIndex = state.instagramDataV2.findIndex((item) => {
                    return item.username === account.username;
                });

                if (activeAccountIndex === -1) {
                    return state;
                }

                const { instagramDataV2 = [] } = state;
                const newInstagramDataV2 = instagramDataV2.slice();
                newInstagramDataV2[activeAccountIndex] = { ...newInstagramDataV2[activeAccountIndex], ...account };

                return { ...state, instagramDataV2: newInstagramDataV2 };

            };

            if (upgradeInstagramUserV2) {
                return updateInstagramV2Account(upgradeInstagramUserV2);
            }

            if (cancelInstagramUserV2) {
                return updateInstagramV2Account(cancelInstagramUserV2);
            }

            return state;
        }

        case CANCEL_TIKTOK_FULFILLED:
        case UPGRADE_TIKTOK_FULFILLED: {

            const { data = {} } = payload;
            const { upgradeTiktokUser, cancelTikTokSubscription } = data;

            const upgradeTiktokUserAccount = (account) => {

                if (!account || !account.username || account.error) {
                    return state;
                }

                if (!Array.isArray(state.tikTokData) || state.tikTokData.length === 0) {
                    return state;
                }

                const activeAccountIndex = state.tikTokData.findIndex((item) => {
                    return item.username === account.username;
                });

                if (activeAccountIndex === -1) {
                    return state;
                }

                const { tikTokData = [] } = state;
                const newTikTokData = tikTokData.slice();
                newTikTokData[activeAccountIndex] = { ...newTikTokData[activeAccountIndex], ...account };

                return { ...state, tikTokData: newTikTokData };

            };

            if (upgradeTiktokUser) {
                return upgradeTiktokUserAccount(upgradeTiktokUser);
            }

            if (cancelTikTokSubscription) {
                return upgradeTiktokUserAccount(cancelTikTokSubscription);
            }

            return state;
        }

        case UPDATE_NOTIFICATIONS_VIEW_STATUS: {
            return {
                ...state,
                notifications: payload
            };
        }

        case APPLY_TO_ADVERTISER_FULFILLED: {

            if (
                !payload
                || !payload.data
                || !payload.data.applyToAdvertiser
            ) {
                return state;
            }

            const application = payload.data.applyToAdvertiser;

            // Create new copy of current applications in state, or 
            // create an empty array if none are present.
            let newApplications = Array.isArray(state.applications)
                ? [...state.applications]
                : [];

            // Remove old version of application if present.
            newApplications = newApplications.filter((item) => {
                return item.application_id !== application.application_id;
            });

            // Add new application to array.
            newApplications.push(application);

            return { ...state, applications: newApplications };
        }

        case UPDATE_AFFILIATE_FULFILLED: {
            if (
                !payload
                || !payload.data
                || !payload.data.updateAffiliate
                || !payload.data.updateAffiliate.account
                || !payload.data.updateAffiliate.account.personal
            ) {
                return state;
            }

            const account = (state.account) ? { ...state.account } : {};
            const personal = (account.personal) ? { ...account.personal } : {};
            const accountData = {
                ...account,
                personal: {
                    ...personal,
                    first_name: payload.data.updateAffiliate.account.personal.first_name,
                    last_name: payload.data.updateAffiliate.account.personal.last_name
                }
            };
            return {
                ...state,
                account: accountData,
                account_type: payload.data.updateAffiliate.account_type,
                name: payload.data.updateAffiliate.name
            };
        }

        case VERIFY_TIKTOK_USER_FULFILLED: {
            const { data = {} } = payload;
            const { verifyTikTokUser: account } = data;

            if (!account || !account.username || !account.account_verified || account.error) {
                return state;
            }

            if (!Array.isArray(state.tikTokData) || state.tikTokData.length === 0) {
                return state;
            }

            const activeAccountIndex = state.tikTokData.findIndex((item) => {
                return item.username === account.username;
            });

            if (activeAccountIndex === -1) {
                return state;
            }

            const { tikTokData = [] } = state;
            const newTikTokData = tikTokData.slice();
            newTikTokData[activeAccountIndex].account_verified = true;

            return { ...state, tikTokData: newTikTokData };
        }

        case 'SWITCH_ACCOUNT_TYPE_FULFILLED':
            if (!payload.data?.switchAccountTypeAffiliate?.account_type) {
                return state;
            }
            return {
                ...state,
                account_type: payload.data.switchAccountTypeAffiliate.account_type
            };

        default:
            return state;
    }
};

export default getAffiliateReducer;
