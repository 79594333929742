import { useState, useEffect } from 'react';

const useResponsive = () => {
    const [pageWidth, setPageWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (pageWidth !== window.innerWidth) {
                setPageWidth(window.innerWidth);
            }
        });
    }, []);

    return pageWidth;
};

export default useResponsive;
