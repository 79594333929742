import React, { useState } from 'react';
import { Modal, Heading, TextStyle, SkeletonThumbnail } from '@shopify/polaris';
import {SaveMinor} from '@shopify/polaris-icons'

import { downloadFileFromS3 } from '../../store/actions/upload-s3-file';

import usePages from '../../hooks/use-pages';
import { useToggle } from '../../hooks/use-toggle';

import {LlamaPagination, EmptyState} from 'llama-library/components';

import './offer-creatives.css';
import expandIcon from '../../assets/expand-icon-white.svg';

const OfferCreative = ({ item }) => {

    const imgUrl = item.url;
    const imgName = item.name;

    const [activeModal, setActiveModal] = useToggle(false);

    const handleDownloadFile = (file) => {
        let parsedUrl = file.split("/").slice(4).join("/");

        downloadFileFromS3(parsedUrl)
            .then((response) => {
                let link = document.createElement('a');
                link.download = 'creative';
                link.href = 'data:' + response.data.ContentType + ';base64' + ',' + response.data.Body;
                link.click();
        });

    };

    return (

        <React.Fragment>
            <div className="OfferCreatives__CreativeItemWrapper" onClick={setActiveModal}>
                <div className="OfferCreatives__ExpandOverlay">
                    <div className="OfferCreatives__ExpandWrapper">
                        <img src={expandIcon} className="OfferCreatives__ExpandIcon" />
                    </div>
                </div>
                <div className="OfferCreatives__CreativeItem">
                    <img src={imgUrl} className="OfferCreatives__CreativeImage" />
                </div>
            </div>
            <div style={{ height: '100%'}}>
                <Modal
                    open={activeModal}
                    onClose={setActiveModal}
                    title="Download this Banner"
                    primaryAction={{
                        onAction: () => handleDownloadFile(item.url),
                        content: 'Download Banner',
                        icon: SaveMinor
                    }}
                >
                    <Modal.Section>
                        <img src={imgUrl} className="OfferCreatives__ImageExpanded" />
                    </Modal.Section>
                </Modal>
            </div>
        </React.Fragment>
    )
}

const OfferCreatives = (props) => {
    let creatives;
    if (props.offer && props.offer.creatives) {
        creatives = props.offer.creatives;
    }

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, 9, creatives);

    // Empty state if creatives are not found
    if (!Array.isArray(props.offer.creatives) || props.offer.creatives.length === 0) {
        return (
            <EmptyState message={`${props.offer.advertiser.company} hasn’t uploaded any promotional image banners.`} />
        );
    }

    return (

        <div className="OfferCreatives__Wrapper">
            <div className="OfferCreatives__Heading">
                <Heading>PROMOTIONAL IMAGE BANNERS</Heading>
                <TextStyle variation="subdued"><span
                    className="subtitle">If you&rsquo;d like to visually promote this offer on social media, you can use the following image banners:</span></TextStyle>
            </div>
            <div className="OfferCreatives">
            {posts.map((item, index) => {

                const creativeIndex = `${item.name}--${index}`;

                return <div key={creativeIndex} className="OfferCreatives__ItemCompWrapper">
                            <OfferCreative key={creativeIndex} item={item} />
                        </div>
            })}
            </div>
            {maxPages > 0 && 
            <div className="OfferCreatives__Pagination">
                <LlamaPagination
                    hasNext={hasNext}
                    hasPrevious={hasPrevious}
                    onNext={nextPage}
                    onPrevious={previousPage}
                    currentPage={currentPage}
                    maxPages={maxPages} 
                />
            </div>
            }
        </div>

    )
}

export default OfferCreatives;