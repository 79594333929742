import { useState, useEffect } from 'react';

const useReduxAction = (reduxAction, params) => {

    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {

        const hasParams = Array.isArray(params) && params.find((item) => item);

        if (
            !isLoading 
            && (hasParams || (Array.isArray(params) && params.length === 0))
        ) {
            const action = reduxAction(...params)
            
            if (action instanceof Promise) {
                setIsLoading(true);
                action.then((result) => {
                        setIsLoading(false);
                        setHasLoaded(true);
                        setValue(result);
                    })
                    .catch(() => setIsLoading(false));
            }

        }

    }, params);

    return { isLoading, hasLoaded, value };

}

export default useReduxAction;