import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Frame, ProgressBar } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { startOnboarding, finishOnboarding } from '../../../store/actions/action_getSmartLinks';

import SmartLinkOfferForm from '../offers/smart-link-offer-form';
import SmartLinkNameAndPhoto from './smart-link-name-and-photo';
import SmartLinkThemes from '../settings/smart-link-themes';
import SmartLinksURLForm from '../smartlinks-url-form';
import SmartLinkPlans from './smart-link-plans';

import leohead from '../../../assets/smartlink-img/leo-smartlinks.png';
import './smartlinks-onboarding.css';

const SmartLinksOnboarding = ({ affiliate, links, manualLinks, offers, updateLinks, dispatchStartOnboarding, dispatchFinishOnboarding }) => {
    const steps = [
        {
            title: 'Set up your SmartLinks page',
            intro: 'I’ll help you get started with SmartLinks in just a few easy steps',
            button: 'Let’s Do This'
        },
        {
            title: 'I’m Leo, who are you?',
            intro: 'Let your audience know this is your page by adding your name and photo.',
            button: 'Next: Choose a theme'
        },
        {
            title: `👋 Nice to meet you, ${affiliate.account?.personal?.first_name}. What’s your vibe?`,
            intro: 'Choose the theme that best fits your style',
            info: 'Get access to even more themes and full customization with a PRO account.',
            button: 'Next: Add a link'
        },
        {
            title: 'Beautiful! Now, what do you want to promote first?',
            intro: 'Add your first link',
            info: 'You’ll be able to add unlimited links for anything you want once we’re done.',
            button: 'Next: Claim your URL'
        },
        {
            title: 'Almost done, let’s personalize your page URL',
            intro: 'Claim your custom SmartLinks Page URL to help your audience easily find your page',
            info: <>If you already have your own domain, you can also point your CNAME record to this url. Check out our <a href="https://help.llama.app/article/50-custom-domains-and-cnames" target="_blank" rel="nofollow noopener noreferrer">help guide</a> if you’re not sure how to do this.</>,
            button: 'Finish'
        },
        {
            title: 'Awesome! You’re all set.',
            intro: <>The basic setup of your page is complete, but check out the <Link to="/smartlinks/customize/general">Customize</Link> page and consider <Link to="/smartlinks/plans">upgrading to our PRO plan</Link> if you want even more features.</>,
            button: 'Take me to my links'
        }
    ];

    const lastStep = steps.length - 2;

    const [step, setStep] = useState(0);
    const [saving, setSaving] = useState(false);

    const linkSubmitRef = useRef(null);
    const namePhotoSubmitRef = useRef(null);
    const urlSubmitRef = useRef(null);

    const increaseStep = () => {
        if (step === 0) {
            dispatchStartOnboarding();
        }
        if (step < steps.length - 1) {
            setStep((currentStep) => {
                return currentStep + 1;
            });
        } else {
            // set firstInstall to false via redux to cause a side effect in SmartLinkOffers that unmounts this onboarding component and shows the links list instead
            dispatchFinishOnboarding();
        }
    };

    const onNextClick = async () => {
        if (step === 1) {
            namePhotoSubmitRef.current();
        } else if (step === 3) {
            linkSubmitRef.current();
        } else if (step === 4) {
            urlSubmitRef.current();
        } else {
            increaseStep();
        }
    };

    steps[1].content = (
        <SmartLinkNameAndPhoto
            submitRef={namePhotoSubmitRef}
            setSaving={setSaving}
            onDone={increaseStep}
        />
    );

    steps[2].content = (
        <div className="themes">
            <SmartLinkThemes
                fromOnboarding
                setToastVerbiage={() => {}}
            />
        </div>
    );

    steps[3].content = (
        <SmartLinkOfferForm
            affiliate={affiliate}
            links={links}
            manualLinks={manualLinks}
            updateLinks={updateLinks}
            onLinkSubmit={increaseStep}
            setSaving={setSaving}
            offers={offers}
            action="add"
            submitRef={linkSubmitRef}
        />
    );

    steps[4].content = (
        <SmartLinksURLForm
            setSaving={setSaving}
            fromOnboarding
            submitRef={urlSubmitRef}
            onDone={increaseStep}
            setUrl={() => {}}
        />
    );

    steps[5].content = (
        <SmartLinkPlans />
    );

    return (
        <div id="smartlinks-onboarding" data-step={step}>
            <Frame>
                <ProgressBar progress={step * (100 / lastStep)} size="small" className="progress_bar" data-test="progressbar-container" />
                <div className="inner-content">
                    <header>
                        <p className="leohead"><img src={leohead} alt="Leo the Llama, our mascot" /></p>
                        {step > 0 && step <= lastStep
                            && <p className="step">Step {step} of {lastStep}</p>
                        }
                        <h3 className="title">{steps[step].title}</h3>
                        <p className="intro">{steps[step].intro}</p>
                    </header>
                    {steps[step].content}
                    <footer>
                        {steps[step].info && <p className="info">{steps[step].info}</p>}
                        <p>
                            <LlamaButton onClick={onNextClick} loading={saving} disabled={saving}>{steps[step].button}</LlamaButton>
                        </p>
                    </footer>
                </div>
            </Frame>
        </div>
    );
};

SmartLinksOnboarding.propTypes = {
    affiliate: PropTypes.shape({
        account: PropTypes.shape({
            personal: PropTypes.shape({
                first_name: PropTypes.string
            })
        })
    }).isRequired,
    links: PropTypes.arrayOf().isRequired,
    manualLinks: PropTypes.arrayOf().isRequired,
    offers: PropTypes.arrayOf().isRequired,
    updateLinks: PropTypes.func.isRequired,
    dispatchStartOnboarding: PropTypes.func.isRequired,
    dispatchFinishOnboarding: PropTypes.func.isRequired
};

const mapDispatchtoProps = (dispatch) => {
    return {
        dispatchStartOnboarding: () => { return dispatch(startOnboarding()); },
        dispatchFinishOnboarding: () => { return dispatch(finishOnboarding()); }
    };
};

export default connect(null, mapDispatchtoProps)(SmartLinksOnboarding);
