/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React, {Component} from 'react';
import './affiliate-1099.css';
import {
    Heading,
    Modal,
    Button
} from '@shopify/polaris';
import { W9_WIDGET_URL } from '../../config';

export class Affiliate1099 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }
    }

    launchW9() {
        this.setState({
            modalOpen: true
        })
    }

    closeW9() {
        this.setState({
            modalOpen: false
        })
    }

    render() {
        return (
            <div className="settings-1099__wrapper">
                <Heading>W-9 Form</Heading>
                <p className="w9">
                    <Button id="load-form" onClick={this.launchW9.bind(this)} className="w9">Load Form</Button>
                </p>
                <Modal large open={this.state.modalOpen} onClose={this.closeW9.bind(this)} title="Sign W-9">
                    <Modal.Section>
                        <iframe 
                            className="settings-1099__iframe"
                            title="1099Form"
                            src={`${W9_WIDGET_URL}`}
                            width="100%" 
                            height="100%" 
                            frameBorder="0" 
                        />
                    </Modal.Section>
                </Modal>
            </div>
        )
    }
}

export default Affiliate1099;