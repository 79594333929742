/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAffiliateData } from '../store/actions/action_getAffiliate';
import { changeHeaderTitle } from '../store/actions/header';
import { EmptyState, LlamaPagination } from 'llama-library/components';
import {moneyFormat} from '../utils/number-format';
import {
    Page,
    Card,
    DataTable,
    Tabs,
    Badge,
    Pagination,
    SkeletonDisplayText,
    SkeletonBodyText
} from '@shopify/polaris';
import './styles/invoices.css';

export class Invoices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
            affiliateData: [],
            currentPage: 0,
            itemsPerPage: 10,
            totalMade: 0,
            totalOwed: 0
        }
    }

    componentDidMount() {
        this.props.changeHeaderTitle('Invoices');
    }

    nextPage = () => {
        const newPageNumber = this.state.currentPage + 1;
        this.setState({ currentPage: newPageNumber });
    };

    previousPage = () => {
        if (this.state.currentPage === 0) { return }
        const newPageNumber = this.state.currentPage - 1;
        this.setState({ currentPage: newPageNumber });
    };

    handleTabChange = (selectedTabIndex) => {
        this.setState({ selected: selectedTabIndex, currentPage: 0 });
    };

    calculateInvoiceTotals = () => {
        let total = {
            totalMade: 0,
            totalOwed: 0
        }

        if (this.props.affiliate.invoices) {
            for (var i = 0; i < this.props.affiliate.invoices.length; i++) {

                let amount = parseFloat(this.props.affiliate.invoices[i].invoice.total_amount.value);

                if (this.props.affiliate.invoices[i].invoice.status === "PAID") {
                    total.totalMade += this.state.totalMade + amount;
                } else if (this.props.affiliate.invoices[i].invoice.status === "SENT") {
                    total.totalOwed += this.state.totalOwed + amount;
                }
            }
        }

        return total;
    }

    renderInvoiceTotals() {
        let { totalMade, totalOwed } = this.calculateInvoiceTotals();

        return (

            <div className="invoice_total_wrapper">
                <div className="invoice_total_title">
                    <div className="invoice_total_title_label">
                        <span className="invoice_total_made" />
                        Total Made
                        </div>
                    <span className="invoice_total">{moneyFormat(totalMade, true)}</span>
                </div>
                <div className="invoice_total_title">
                    <div className="invoice_total_title_label">
                        <span className="invoice_total_owed" />
                        Total Owed
                        </div>
                    <span className="invoice_total">{moneyFormat(totalOwed, true)}</span>
                </div>
            </div>

        )
    }

    renderInvoices() {

        let items = [];
        let invoiceUrl = "https://www.paypal.com/invoice/details/";
        if (this.props.affiliate.invoices) {
            for (var i = 0; i < this.props.affiliate.invoices.length; i++) {

                switch (this.state.selected) {
                    case 0:
                        let badge = <Badge status="attention">{this.props.affiliate.invoices[i].invoice.status}</Badge>;
                        if (this.props.affiliate.invoices[i].invoice.status === "PAID") {
                            badge = <div className="invoice_custom_badge_success"> <Badge status="success">Paid</Badge></div>;
                        } else if (this.props.affiliate.invoices[i].invoice.status === "SENT") {
                            badge = <div className="invoice_custom_badge_warning"> <Badge status="warning">Owed</Badge> </div>;
                        }

                        items.push([
                            this.props.affiliate.invoices[i].advertiser_company,
                            badge,
                            ("$" + this.props.affiliate.invoices[i].invoice.total_amount.value),
                            <a href={invoiceUrl + this.props.affiliate.invoices[i].invoice.id} target="_blank" rel="noopener noreferrer"> view </a>
                        ]);
                        break;

                    case 1:
                        if (this.props.affiliate.invoices[i].invoice.status === "PAID") {
                            items.push([
                                this.props.affiliate.invoices[i].advertiser_company,
                                <div className="invoice_custom_badge_success"> <Badge status="success">Paid</Badge></div>,
                                ("$" + this.props.affiliate.invoices[i].invoice.total_amount.value),
                                <a href={invoiceUrl + this.props.affiliate.invoices[i].invoice.id} target="_blank" rel="noopener noreferrer"> view </a>
                            ]);
                        }
                        break;
                    case 2:
                        if (this.props.affiliate.invoices[i].invoice.status === "SENT") {
                            items.push([
                                this.props.affiliate.invoices[i].advertiser_company,
                                <div className="invoice_custom_badge_warning"> <Badge status="warning">Owed</Badge> </div>,
                                ("$" + this.props.affiliate.invoices[i].invoice.total_amount.value),
                                <a href={invoiceUrl + this.props.affiliate.invoices[i].invoice.id} target="_blank" rel="noopener noreferrer"> view </a>
                            ]);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return items;
    }

    render() {
        // let invoices = this.renderInvoices();
        // let totals = this.renderInvoiceTotals();
        // const { selected } = this.state;
        const tabs = [
            {
                id: 'all-invoices',
                content: 'All',
                panelID: 'all-invoices',
            },
            {
                id: 'paid-invoices',
                content: 'Paid',
                panelID: 'paid-invoices',
            },
            {
                id: 'owed-invoices',
                content: 'Owed',
                panelID: 'owed-invoices',
            }
        ];

        const loadingRows = [
            [
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
            ],
            [
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
            ],
            [
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
                <SkeletonBodyText lines={1} />,
            ],
        ]

        const navigateToPayment = () => {
            this.props.history.push('/settings/payment')
        }

        if (!this.props.affiliate.cognito_id || this.props.affiliate.cognito_id === null) {
            return (
                <div className="invoice_table" data-test="component-loading-invoices">
                    <Page>
                        <div className="invoice_total_wrapper">
                            <div className="invoice_total_title">
                                <div className="invoice_total_title_label">
                                    <span className="invoice_total_made" />
                                    Total Made
                            </div>
                                <div style={{ width: '6rem' }}><SkeletonDisplayText size="small" /></div>
                            </div>
                            <div className="invoice_total_title">
                                <div className="invoice_total_title_label">
                                    <span className="invoice_total_owed" />
                                    Total Owed
                            </div>
                                <div style={{ width: '6rem' }}><SkeletonDisplayText size="small" /></div>
                            </div>
                        </div>
                        <Tabs tabs={tabs} data-test='invoice-tabs-loading' />
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'numeric',
                                'numeric',
                                'numeric'
                            ]}
                            headings={[
                                'Company',
                                'Status',
                                'Amount',
                                'Link'
                            ]}
                            rows={loadingRows}
                            footerContent={`Showing 1 of 1 pages`}
                        />
                    </Page>
                </div>
            )
        }
        else if (
            this.props.affiliate.account === null || (
            this.props.affiliate.account &&
            !this.props.affiliate.account.paypal
            || this.props.affiliate.account.paypal === null
            || this.props.affiliate.account.paypal === "")
        ) {
            return (
                <Page>
                    <div data-test="component-empty-invoices">
                        <EmptyState
                            message={`Connect your Paypal Account`}
                            paragraph={`We can’t pay you if we don’t know where to send your commissions. Click the button below to connect your PayPal account to Llama.`}
                            ctaText={`Connect in Settings`}
                            ctaAction={navigateToPayment}
                        />
                    </div>
                </Page>
            )
        } else {

            let invoices = this.renderInvoices();
            let totals = this.renderInvoiceTotals();
            const { selected } = this.state;

            let hasNext = true;
            let hasPrevious = true;
            const { itemsPerPage, currentPage } = this.state;
            const totalMatches = invoices.length;
            const totalPages = Math.ceil(totalMatches / itemsPerPage);
            if (totalMatches > itemsPerPage) {
                const start = currentPage * itemsPerPage;
                const end = (currentPage + 1) * itemsPerPage;
                invoices = invoices.slice(start, end);
            }

            if (invoices.length === 0) {
                hasNext = false;
                hasPrevious = true;
            }

            if (itemsPerPage * (currentPage + 1) >= totalMatches) {
                hasNext = false;
            }

            if (currentPage === 0) {
                hasPrevious = false;
            }

            return (
                <div className="invoice_table" data-test="component-invoices">
                    <Page>
                        {totals}
                        <Tabs tabs={tabs} selected={selected} onSelect={this.handleTabChange} data-test="invoice-tabs" />
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'numeric',
                                'numeric',
                                'numeric'
                            ]}
                            headings={[
                                'Company',
                                'Status',
                                'Amount',
                                'Link'
                            ]}
                            rows={invoices}
                            footerContent={ !invoices || invoices.length === 0 || !Array.isArray(invoices) ?
                                `There are no invoices to display.` : `Showing ${currentPage + 1} of ${totalPages} pages` }
                        />
                        {totalPages > 1 &&
                            <div className="application-pagination" data-test='invoice-pagination'>
                                <LlamaPagination
                                    hasNext={hasNext}
                                    hasPrevious={hasPrevious}
                                    onNext={this.nextPage}
                                    onPrevious={this.previousPage}
                                    currentPage={currentPage}
                                    maxPages={totalPages - 1}
                                />
                            </div>
                        }
                    </Page>
                </div>
            );

        }

    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_affiliate: (id) => dispatch(getAffiliateData(id)),
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
