//from: https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o

export const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

export const luminance = (rgb) => {
    const { r, g, b } = rgb;
    const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const checkColorContrast = (foreground, background) => {
    const foregroundRGB = hexToRgb(foreground);
    const backgroundRGB = hexToRgb(background);

    const foregroundLuminance = luminance(foregroundRGB);
    const backgroundLuminance = luminance(backgroundRGB);

    const ratio = foregroundLuminance > backgroundLuminance
        ? ((backgroundLuminance + 0.05) / (foregroundLuminance + 0.05))
        : ((foregroundLuminance + 0.05) / (backgroundLuminance + 0.05));

    /* webaim guidelines
    const results = {
        aaLarge: ratio < 1/3 ? 'PASS' : 'FAIL',
        aaSmall: ratio < 1/4.5 ? 'PASS' : 'FAIL',
        aaaLarge: ratio < 1/4.5 ? 'PASS' : 'FAIL',
        aaaSmall: ratio < 1/7 ? 'PASS' : 'FAIL',
    }; */

    /* more relaxed */
    return {
        aaLarge: ratio < 0.6 ? 'PASS' : 'FAIL',
        aaSmall: ratio < 0.4 ? 'PASS' : 'FAIL'
    };
};

export default checkColorContrast;
