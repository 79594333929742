import React from 'react';
import './engagement-score.css';
import { SkeletonBodyText, SkeletonThumbnail, SkeletonDisplayText } from '@shopify/polaris';

/**
 * @function getGradeValues
 * Takes score as param, returns scoreValues Object
 * with letter grade, title, description, and badge status of
 * user engagement score evaluation.
 * @param {Int} score - engagement score Integer value 0 - 100
 */
export const getGradeValues = (score) => {
    let letterGrade = '';
    let title = '';
    let desc = '';
    let badgeStatus = '';

    if (score >= 81) {
        letterGrade = 'A';
        title = 'Great';
        desc = 'Keep up the great work! You’re doing an amazing job keeping your audience engaged.';
        badgeStatus = 'success';
    } else if (score <= 80 && score >= 61) {
        letterGrade = 'B';
        title = 'Good';
        desc = 'You’re starting to capture some attention, but there’s still room for  improvement. Try focusing on posting more content your audience can’t help but share.';
        badgeStatus = 'info';
    } else if (score <= 60 && score >= 41) {
        letterGrade = 'C';
        title = 'Average';
        desc = 'Not too bad, but your audience could use some nurturing. Try asking some questions or posting some fun polls to improve your engagement.';
        badgeStatus = 'attention';
    } else if (score <= 40 && score >= 21) {
        letterGrade = 'D';
        title = 'Below Average';
        desc = 'Your engagement has been looking a little low. Try posting content that will make more people hit that like button to boost your score.';
        badgeStatus = 'warning';
    } else {
        letterGrade = 'F';
        title = 'Poor';
        desc = 'Your audience hasn’t been very interested in your posts lately. Try varying your content by posting images, videos, collaborations, or even some personal stories.';
        badgeStatus = 'warning';
    }

    return { letterGrade, title, desc, badgeStatus };
};

/**
 * @function EngagementScore Component
 * Accepts data as props, renders engagement grade evaluation
 * based on engagement score(s) for user's social media account
 * 
 * @param {Array} data - Array of Objects representing user social media accounts
 */
const EngagementScore = (data) => {
    console.log('DATA:', data);
    const socialData = data.data;
    console.log('SOCIAL DATA:', socialData);
    let scoreValues;

    // Loading State
    if (!data || !socialData) {
        return (
            <div className="EngagementScore__GradeSection">
                <h1 className="EngagementScore__GradeHeading"><SkeletonDisplayText size="small" /></h1>
                <p className="EngagementScore__GradeSubheading"><SkeletonBodyText lines={2} /></p>
                <div className="EngagementScore__GradeWrapper">
                    <div className="EngagementScore__Grade EngagementScore__Grade">
                        <SkeletonThumbnail size="medium" />
                    </div>
                    <div className="EngagementScore__GradeText">
                        <h3 className="EngagementScore__GradeTitle"><SkeletonBodyText lines={1} /></h3>
                        <p className="EngagementScore__GradeDesc"><SkeletonBodyText lines={2} /></p>
                    </div>
                </div>
            </div>
        )
    }

    // If user has multiple accounts, get the average
    // score of total accounts.
    if (Array.isArray(socialData) && socialData.length > 1) {
        const scoreArray = socialData.map((item) => {
            return item.score;
        });
        const scoreAvg = scoreArray.reduce((total, acc, index, array) => {
            total += acc;
            if (index === array.length - 1) {
                return total / array.length;
            }
            return total;
        });
        scoreValues = getGradeValues(scoreAvg);
    } else {
        // Otherwise grab score from the single account
        const singleAccount = socialData[0];
        const singleScore = singleAccount.score;

        scoreValues = getGradeValues(singleScore);
    }


    const { letterGrade, title, desc } = scoreValues;

    return (
        <div className="EngagementScore__GradeSection">
            <h1 className="EngagementScore__GradeHeading">Engagement Score</h1>
            <p className="EngagementScore__GradeSubheading">Find out how well your audience is engaging with your posts.</p>
            <div className="EngagementScore__GradeWrapper">
                <div className={`EngagementScore__Grade EngagementScore__Grade--${letterGrade}`}>{letterGrade}</div>
                <div className="EngagementScore__GradeText">
                    <h3 className="EngagementScore__GradeTitle">{title}</h3>
                    <p className="EngagementScore__GradeDesc">{desc}</p>
                </div>
            </div>
        </div>
    );
};

export default EngagementScore;
