import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Frame,
    Toast,
    Banner,
    Modal,
    ChoiceList,
    Badge
} from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';
import { connect } from 'react-redux';

import { createTwitterAffData } from '../../../store/actions/social/create-twitter';
import { unlinkTwitterAffiliate } from '../../../store/actions/action_unlinkTwitterAffiliate';
import { updateAffiliate } from '../../../store/actions/action_updateAffiliate';
import { getSocialRedirectUrl } from '../../../store/actions/social/getSocialRedirect';
import { setFeaturedAccount } from '../../../store/actions/social/set-featured-page';

import CenteredSpinner from '../../llama/centered-spinner';
import EmptyComponentState from '../../EmptyComponent/emptyState';
import SocialAuthComponent from '../../affiliate-auth/social-auth';
import EngagementScore, { getGradeValues } from '../engagement-score/engagement-score';

import '../affiliate-social.css';
import './twitter.css';

class TwitterComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accounts: this.props.affiliate.twitterData,
            refreshingAccount: false,
            isLoading: false,
            socialAuthData: {},
            isAuthWindowOpen: false,

            accountToUnlink: null,
            unlinkConfirmationOpen: false,
            unlinking: false,

            setFeaturedAccountOpen: false,
            featuredAccountChoices: [],
            featuredAccountSelection: '',
            featuredAccount: '',
            savingFeaturedAccount: false
        };

        this.emptyStateConfig = {
            platform: 'twitter',
            heading: 'Link Your Twitter Account',
            description: 'We’ll analyze audience engagement from your most recent Tweets and show you just how interested your followers are in what you have to say.',
            linkSocialAccount: true
        };

        this.errMsg = 'Oops, something went wrong. Please try again later.';
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.getSocialRedirectUrl('twitter')
            .then((result) => {
                this.setState({
                    socialAuthData: {
                        redirectUrl: result.value.data.location
                    }
                });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                console.log('Error: Unable to get redirect URL for twitter', error);
            });

        this.setState({
            featuredAccountSelection: this.state.featuredAccount
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const featuredAccountChoices = [];

        // get a new oauth link to avoid the expired token warning if previously opened
        if (nextProps.isAuthWindowOpen === false && nextProps.isAuthWindowOpen !== prevState.isAuthWindowOpen) {
            this.props.getSocialRedirectUrl('twitter')
                .then((result) => {
                    this.setState({
                        socialAuthData: {
                            redirectUrl: result.value.data.location
                        }
                    });
                })
                .catch((error) => {
                    console.log('Error: Unable to get redirect URL for twitter', error);
                });
        }

        if (nextProps.affiliate && nextProps.affiliate.twitterData && nextProps.affiliate.twitterData[0] && nextProps.affiliate.twitterData[0].user) {
            nextProps.affiliate.twitterData.forEach((account) => {
                featuredAccountChoices.push({
                    label: account.user.name,
                    value: account.user.id
                });
            });
        }

        return {
            accounts: nextProps.affiliate.twitterData,
            refreshingAccount: nextProps.gettingAccounts,
            featuredAccountChoices,
            featuredAccount: nextProps.affiliate.twitter
        };
    }

    // runs when oauth flow completes
    responseParentSocialAccounts = (response) => {
        this.setState({ isAuthWindowOpen: false, isLoading: true });

        if (response.code && response.code.oauth_token && response.code.oauth_verifier) {
            this.props.createTwitterAffData(response.code, this.props.affiliate.cognito_id)
                .then((result) => {
                    this.setState({ isLoading: false });
                    if (result && result.value && result.value.data
                        && result.value.data.createTwitterUser
                        && result.value.data.createTwitterUser.user
                        && result.value.data.createTwitterUser.user.id) {
                        return this.props.refreshParentComponent();
                    }
                    this.setState({
                        socialError: 'Something went wrong. We were unable to connect your Twitter account.'
                    });
                    return '';
                });
        } else {
            this.setState({ isLoading: false });
        }
    }

    // open unlink account confirmation modal and remember id to unlink
    unlinkAccountConfirm = (accountId) => {
        this.setState({
            accountToUnlink: accountId,
            unlinkConfirmationOpen: true
        });
    }

    // unlink the account
    unlinkAccount = (accountId) => {
        this.setState({ unlinking: true });
        this.props.unlinkTwitterAffiliate(this.props.affiliate.cognito_id, accountId)
            .then((result) => {
                if (result && result.value && result.value.data && result.value.data.unlinkTwitterAccount && result.value.data.unlinkTwitterAccount.twitter_id) {
                    this.props.refreshParentComponent();
                    this.setState({
                        unlinkConfirmationOpen: false,
                        accountToUnlink: null,
                        unlinking: false
                    });
                } else {
                    this.setState({
                        unlinking: false,
                        toastVerbiage: this.errMsg,
                        unlinkConfirmationOpen: false,
                        accountToUnlink: null
                    });
                }
            })
            .catch(() => {
                this.setState({
                    unlinking: false,
                    toastVerbiage: this.errMsg,
                    unlinkConfirmationOpen: false,
                    accountToUnlink: null
                });
            });
    }

    // add new account
    startOauthFlow = () => {
        this.setState({ isAuthWindowOpen: true });
    }

    // select featured account in the modal
    handleFeaturedAccountChange = (value) => {
        this.setState({ featuredAccountSelection: value[0] });
    }

    // change the featured account
    setFeaturedAccount = () => {
        this.setState({ savingFeaturedAccount: true });
        // save this.state.featuredPage to mongo
        this.props.setFeaturedAccount(this.props.affiliate.cognito_id, this.state.featuredAccountSelection)
            .then((result) => {
                this.props.refreshParentComponent();
                this.setState({
                    setFeaturedAccountOpen: false
                });
            })
            .catch(() => {
                this.setState({
                    savingFeaturedAccount: false,
                    toastVerbiage: this.errMsg,
                    setFeaturedAccountOpen: false
                });
            });
    }

    closeModal = (modalState) => {
        this.setState({
            [modalState]: false,
            featuredAccountSelection: this.state.featuredAccount,
            accountToUnlink: null
        });
    }

    renderAccountsList = () => {
        return (
            <ul className="twitter-integration__list-accounts">
                {this.state.accounts.map((account) => {
                    const accountGrade = getGradeValues(account.score);
                    return (
                        <li key={account.user.id}>
                            <p className="account-info">
                                <img src={account.user.profilePicture} alt="" />
                                <span>
                                    <b>{account.user.name} <Badge status={accountGrade.badgeStatus}>{accountGrade.letterGrade}</Badge></b>
                                    @{account.user.screenName}
                                </span>
                            </p>
                            <LlamaButton classes={['unlink']} onClick={() => { return this.unlinkAccountConfirm(account.user.id); }}>Unlink</LlamaButton>
                        </li>
                    );
                })}
            </ul>
        );
    }

    render() {
        if (this.state && (this.state.isLoading || this.state.refreshingAccount)) {
            return <CenteredSpinner />;
        }

        if (this.state && this.state.accounts && Array.isArray(this.state.accounts) && this.state.accounts.length === 0) {
            return <EmptyComponentState config={this.emptyStateConfig} socialAuthData={this.state.socialAuthData} {...this.props} />;
        }

        const featuredAccountInfo = this.state.accounts.find((account) => {
            return account.user.id === this.state.featuredAccount;
        });

        return (
            <div className="social__profile__details__card">
                <Modal
                    title="Are you sure you want to unlink this account?"
                    open={this.state.unlinkConfirmationOpen}
                    onClose={() => { return this.closeModal('unlinkConfirmationOpen'); }}
                    primaryAction={{
                        content: 'Unlink',
                        onAction: () => { return this.unlinkAccount(this.state.accountToUnlink); },
                        loading: this.state.unlinking
                    }}
                >
                    <Modal.Section>
                        <p>This will unlink your Twitter account from your Llama profile.</p>
                    </Modal.Section>
                </Modal>

                <Modal
                    title="Set your featured account"
                    open={this.state.setFeaturedAccountOpen}
                    onClose={() => { return this.closeModal('setFeaturedAccountOpen'); }}
                    primaryAction={{
                        content: 'Set As Featured Account',
                        onAction: this.setFeaturedAccount,
                        loading: this.state.savingFeaturedAccount
                    }}
                    secondaryActions={[{
                        content: 'Cancel',
                        onAction: () => { return this.closeModal('setFeaturedAccountOpen'); }
                    }]}
                >
                    <Modal.Section>
                        <p>Which account would you like to feature on your profile?</p>
                        <ChoiceList
                            selected={[this.state.featuredAccountSelection]}
                            onChange={this.handleFeaturedAccountChange}
                            choices={this.state.featuredAccountChoices}
                        />
                    </Modal.Section>
                </Modal>

                <div className="twitter-integration__account-select">
                    <div className="twitter-integration__header">Linked Twitter Accounts</div>
                    {this.renderAccountsList()}
                    {this.state.socialError
                        && (
                            <Banner
                                status="warning"
                                title="Unable to connect account"
                                action={{
                                    content: 'Retry',
                                    onAction: this.startOauthFlow
                                }}
                            >
                                {this.state.socialError}
                            </Banner>
                        )
                    }
                    <button type="button" className="twitter-integration__button" onClick={this.startOauthFlow}>
                        Link Another Account
                    </button>
                    { this.state.isAuthWindowOpen
                        && (
                            <SocialAuthComponent
                                responseParentSocialAccounts={this.responseParentSocialAccounts}
                                data={{
                                    platform: 'twitter',
                                    isAuthWindowOpen: this.state.isAuthWindowOpen,
                                    socialAuthData: this.state.socialAuthData,
                                    isLoading: this.state.isLoading
                                }}
                                {...this.props}
                            />
                        )
                    }
                </div>
                {this.state.accounts.length > 1
                    && (
                        <div className="social-settings__featured-page">
                            <h2>Featured Account</h2>
                            <p>This account will be shown first on your profile</p>
                            <div className="page">
                                <p className="image"><img src={featuredAccountInfo.user.profilePicture} /></p>
                                <p className="info">
                                    <b>{featuredAccountInfo.user.name}</b>
                                    <span>{featuredAccountInfo.user.screenName}</span>
                                </p>
                                <LlamaButton classes={['edit']} onClick={() => { return this.setState({ setFeaturedAccountOpen: true }); }}>Change</LlamaButton>
                            </div>
                        </div>
                    )
                }
                <EngagementScore data={this.state.accounts} />
                <Frame>
                    {this.state.toastVerbiage
                        ? <Toast content={this.state.toastVerbiage} onDismiss={() => { return this.setState({ toastVerbiage: null }); }} />
                        : null
                    }
                </Frame>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        createTwitterAffData: (code, id) => dispatch(createTwitterAffData(code, id)),
        updateAffiliate: (data, id) => dispatch(updateAffiliate(data, id)),
        unlinkTwitterAffiliate: (data, id) => dispatch(unlinkTwitterAffiliate(data, id)),
        getSocialRedirectUrl: (platform) => dispatch(getSocialRedirectUrl(platform)),
        setFeaturedAccount: (cognito_id, featuredAccountId) => dispatch(setFeaturedAccount(cognito_id, featuredAccountId))
    };
};

TwitterComponent.propTypes = {
  socialData: PropTypes.array,
  selectedSocialAccounts: PropTypes.array,
  affiliate: PropTypes.object,
  history: PropTypes.any,
  getAffiliateData: PropTypes.any,
  createTwitterAffData: PropTypes.any,
  updateAffiliate: PropTypes.any,
  unlinkTwitterAffiliate: PropTypes.any,
  refreshingAccount: PropTypes.bool,
  refreshParentComponent: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchtoProps)(TwitterComponent);
