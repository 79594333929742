/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 * */

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */

import { sendGraphQL } from './sendRequest';

export const GET_AFFILIATE_PENDING = 'GET_AFFILIATE_PENDING';
export const GET_AFFILIATE_FULFILLED = 'GET_AFFILIATE_FULFILLED';
export const GET_AFFILIATE_REJECTED = 'GET_AFFILIATE_REJECTED';

export function getAffiliateData(cognito_id, promoStatus) {
    if (!cognito_id) {
        return null;
    }
    const query = `
        query affiliateById($cognito_id:String!, $promo_status: Boolean) {
            affiliateById(cognito_id:$cognito_id, promo_status: $promo_status) {
                _id
                cognito_id
                tracking_id
                facebook
                instagram
                twitter
                photo
                youtube
                sign_up_flow
                start_page
                account_type
                email
                email_preference {
                    application_general,
                    application_alerts,
                    application_advertiser_messages,
                    application_weekly_summary,
                    newsletter_general,
                    newsletter_marketing,
                    newsletter_updates,
                    email_unsubscribe
                }
                sales {
                    advertiser_id
                    affiliate_id
                    customer_id
                    offer_id
                    offers{
                        rate{
                            type
                            amount
                        }
                    }
                    orders {
                        created_at
                        updated_at
                        total_price
                        total_discounts
                        total_line_items_price
                        line_items {
                            title
                            variant_id
                            variant_title
                            price
                            quantity
                        }
                    }
                    advertiser {
                        company
                        description
                        domain
                        myshopify_domain
                        email
                    }
                }
                account {
                    categories,
                    payouts,
                    audience_gender,
                    audience_country,
                    audience_age_range
                    billing {
                        first_name,
                        last_name,
                        address1,
                        address2,
                        city,
                        state,
                        phone,
                        zip,
                        company
                    }
                    personal {
                        first_name,
                        last_name,
                        address1,
                        address2,
                        city,
                        state,
                        phone,
                        email,
                        zip,
                        company,
                        country
                    }
                    paypal {
                        refresh
                        access_token
                        id_token
                        user
                        logout
                    }
                    cognito {
                        exp,
                        email,
                        at_hash,
                        auth_time,
                        cognito_username,
                        cognito_rolls,
                        cognito_groups
                    }
                }
                tracking {
                    Affiliate {
                        company
                        ref_id
                    }
                    Stat {
                        profit
                        epc
                        revenue
                        revenue_type
                        conversions
                        clicks
                        cpa
                        cpc
                        cpm
                        ctr
                        date
                        erpc
                        gross_clicks
                        unique_ctr
                        impressions
                        ltr
                        offer_count
                        offer_id
                        payout
                        payout_type
                        rpa
                        rpc
                        rpm
                        unique_clicks
                    }
                }
                facebookData {
                    access_token,
                    fb_general_info {
                        id
                        name
                        profilePhoto
                    }
                    facebook_id
                    pageStats {
                        id
                        engagement {
                            count
                            social_sentence
                        }
                        fan_count
                        name
                        category
                        profilePhoto
                    }
                    statistics {
                        totalPages
                        totalPageEngagement
                        totalPageFans
                    }
                    groups {
                        id
                        name
                        privacy
                    }
                    score
                }
                twitterData {
                    id
                    affiliate_id
                    twitter_id
                    user {
                        id
                        name
                        screenName
                        description
                        followers
                        profilePicture
                    }
                    tags
                    statistics {
                        avgRetweets
                        avgFavorites
                    }
                    score
                }
                instagramData {
                    instagram_id,
                    username,
                    name,
                    cognito_id,
                    profilePicture,
                    statistics {
                        followers,
                        mediaCount,
                        avgLikes,
                        avgComments
                    }
                }
                instagramDataV2 {
                    username
                    cognito_id
                    account_type
                    full_name
                    is_private
                    about
                    photo_url
                    posts_count
                    followers_count
                    followings_count
                    avg_likes
                    avg_comments
                    global_rank
                    aqs
                    aqs_name
                    aqs_description
                    er {
                        value
                        avg
                        title
                    }
                    audience_interests {
                        name
                        value
                    }
                    demography_by_age{
                        gender
                        value
                        by_age_group {
                            group
                            value
                        }
                    }
                    audience_geography {
                        countries {
                            name
                            code
                            value
                        }
                        cities {
                            name
                            value
                        }
                        states {
                            name
                            value
                        }
                    }
                    advertising_data {
                        avg_ad_er
                        avg_er
                        avg_er_display
                        ad_posts{
                            count
                            display
                        }
                        all_posts{
                            count
                            display
                        }
                        brands_mentions_count
                        brands_categories
                        brands_categories_html
                        regular_posts{
                            count
                            display
                        }
                    }
                    likes_spread {
                        value
                        avg
                        title
                    }
                    likes_comments_ratio {
                        value
                        avg
                        title
                    }
                    audience_reachability {
                        value
                        avg
                        title
                    }
                    audience_languages{
                        code
                        value
                    }
                    audience_authenticity {
                        value
                        avg
                        title
                    }
                    audience_type {
                        real
                        susp
                        infs
                        mass
                    }
                    audience_ethnicity {
                        name
                        value
                    }
                    likes_comments_ratio_chart {
                        x
                        y
                    }
                    followers_chart {
                        date
                        count
                    }
                    following_chart {
                        date
                        count
                    }
                    demography {
                        gender
                        value
                    }
                }
                youTubeData {
                    _id
                    accountInfo {
                        id
                        name
                        profilePicture
                    }
                    youtube_id
                    channelStats {
                        id
                        name
                        channelPhoto
                        score
                    }
                    videos {
                        id
                        channelId
                        publishedAt
                        title
                        thumbnail
                        statistics {
                            viewCount
                            likeCount
                            commentCount
                        }
                    }
                    score
                }
                tikTokData {
                    _id
                    cognito_id
                    username
                    account_type
                    account_verified
                    account_hash
                    trial_expiration
                    aqs
                    aqs_name
                    basic {
                      id
                      username
                      title
                      avatar_url
                      description
                    }
                    metrics {
                      subscribers_count {
                        value
                        performance {
                          last_30d {
                            value
                          }
                          last_90d {
                            value
                          }
                          last_180d {
                            value
                          }
                        }
                      }
                      views_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      subscribers_growth_prc {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      er {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      alikes_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      comments_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      shares_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      comments_likes_ratio {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      post_frequency {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                    }
                    features {
                      audience_age_gender {
                        data {
                          age13_17 {
                            male
                            female
                          }
                          age18_24 {
                            male
                            female
                          }
                          age25_34 {
                            male
                            female
                          }
                          age45_54 {
                            male
                            female
                          }
                          age55_64 {
                            male
                            female
                          }
                          age65 {
                            male
                            female
                          }
                        }
                      }
                      audience_geo {
                        data {
                          countries {
                            id
                            code
                            prc
                            name
                          }
                        }
                      }
                    }                    
                    account_type
                    trial_expiration
                }
                notifications {
                    _id
                    recipient_type
                    recipient_id
                    target_type
                    target_id
                    view_status
                    title
                    note
                    destination_link
                    ttl{
                        start
                        end
                    }
                    message
                    image {
                        icon
                        source
                        type
                    }
                }
                invoices {
                    affiliate_id,
                    advertiser_id,
                    create_time,
                    advertiser_company,
                    invoice {
                        id,
                        status,
                        billing_info{
                            email
                        }
                        total_amount {
                            value
                            currency
                        }
                    }
                }
                onboarding_complete
            }
        }
    `;

    const variables = { cognito_id };
    if (promoStatus) {
        variables.promo_status = promoStatus;
    }

    const payload = {
        query,
        variables
    };

    return {
        type: 'GET_AFFILIATE',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: "GET_AFFILIATE",
        payload: axios.post(NEXT_APP_API_URL, payload, {
            headers
        })
            .then(response => {response.data})
    } */
}
