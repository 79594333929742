import React from 'react';
import { Checkbox, TextField } from '@shopify/polaris';

const SmartlinksIntegrationsSkeleton = () => {
    return (
        <>
            <fieldset>
                <legend>Google Analytics</legend>
                <ul>
                    <li>
                        <TextField
                            label="Tracking ID"
                            prefix="UA-"
                            value="loading..."
                            disabled
                            helpText={<>To find your tracking ID, login to <a href="https://analytics.google.com/" target="_blank" rel="nofollow noopener noreferrer">Google Analytics</a> and select your desired account. Then in the <strong>Property</strong> column, click <strong>Tracking Info</strong> then <strong>Tracking Code</strong>. Your &ldquo;UA-&rdquo; ID appears in the upper left portion of the panel.</>}
                        />
                    </li>
                    <li>
                        <Checkbox
                            label="Add UTM tracking parameters to all links"
                            checked={false}
                            disabled
                        />
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>Facebook Ad Events</legend>
                <ul>
                    <li>
                        <TextField
                            label="Pixel ID"
                            value="loading..."
                            disabled
                            helpText={<>To find your pixel ID, go to <a href="https://facebook.com/adsmanager" target="_blank" rel="nofollow noopener noreferrer">Facebok Ads Manager</a>, open the menu, and click <strong>Pixel</strong> in the <strong>Measure &amp; Report -&gt; Events Manager</strong> column. Create a pixel if you haven&rsquo;t already, then copy the <strong>Pixel ID</strong> from the upper left portion of the pixel details panel.</>}
                        />
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>Mailing List</legend>
                <ul>
                    <li>
                        <Checkbox
                            label="Allow people to join my newsletter/email list"
                            checked={false}
                            disabled
                        />
                    </li>
                </ul>
            </fieldset>
        </>
    );
};

export default SmartlinksIntegrationsSkeleton;
