import React, { useState } from 'react';
import { Modal, DisplayText, Spinner, Popover, Icon, ActionList, TextField, InlineError } from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';
import { LlamaButton } from 'llama-library/components';
import PropTypes from 'prop-types';

const hypeSocialPlatforms = {
    instagram: {
        name: 'Instagram',
        analytics: 'InstaAnalytics'
    },
    tiktok: {
        name: 'TikTok',
        analytics: 'TikTokAnalytics'
    }
};

/**
 * @function createOptions
 * Returns a style object that can be passed into Stripe's
 * Elements component.
 *
 * @param {String} fontSize - Size of font for input fields
 * @param {String} padding - Padding for input fields
 * @return {Object}
 */
const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': { color: '#aab7c4' },
                padding
            },
            invalid: { color: '#9e2146' }
        }
    };
};

const pricing = {
    yearly: {
        perMonth: '$0.60',
        billed: '$7.20 yearly'
    },
    monthly: {
        perMonth: '$0.99',
        billed: '$0.99 monthly'
    }
};

const HypeAuditorAccountSettings = ({ account, toggleUpgrade, toggleCancel }) => {

    const [displayPopover, setDisplayPopover] = useState(false);
    const actionList = [{
        content: 'Cancel Subscription',
        onAction: () => toggleCancel(account.username)
    }];

    if (!account) {
        return null;
    }

    const isActive = account.account_type !== 'ACTIVE';

    return (
        <div className="HypeAuditor__AccountSettings" key={account.username}>
            <div className="HypeAuditor__AccountUsername">{account.username}</div>
            <div className="HypeAuditor__AccountStatus">{account.account_type}</div>
            <div className="HypeAuditor__AccountAction">
                {isActive
                    ? (
                        <LlamaButton onClick={() => toggleUpgrade(account.username)}>Upgrade</LlamaButton>
                    )
                    : (
                        <Popover
                            active={displayPopover}
                            activator={<div onClick={() => setDisplayPopover(true)}><Icon source={HorizontalDotsMinor} color="inkLightest" /></div>}
                            onClose={() => { return setDisplayPopover(false) }}
                            preferredAlignment="right"
                        >
                            <ActionList items={actionList} />
                        </Popover>
                    )}
            </div>
        </div>
    );
};

const CardModal = (props) => {
    const [name, setName] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('monthly');
    const [isLoading, setIsLoading] = useState(false);

    const { username, cognito_id, socialPlatform, paymentHandler, stripe, close } = props;
    const socialPlatformName = hypeSocialPlatforms[socialPlatform.toLowerCase()].name;

    const handleNameChange = (event) => {
        if (!event || !event.target || !event.target.value) {
            setName('');
            return;
        }

        setName(event.target.value);
    };

    const submitCardInfo = async () => {
        const response = await stripe.createToken({ name });

        if (!response || !response.token || !response.token.id
            || !username || !cognito_id) {
            return null;
        }

        setIsLoading(true);
        // paymentHandler = initiateTikTokTrial(): When payment is required for connecting additional accounts
        // paymentHandler = upgradeUserAccount(): When payment is required for upgrading an account

        return paymentHandler(username, cognito_id, response.token.id, selectedPlan.toUpperCase())
            .then(res => {
                setIsLoading(false);
                close(res);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                close();
            });
    };

    let monthlyClasses = ["HypeUpsell__PlanType", "HypeUpsell__PlanType--Inactive"];
    let annualClasses = ["HypeUpsell__PlanType", "HypeUpsell__PlanType--Inactive"];

    if (selectedPlan === 'monthly') {
        monthlyClasses = monthlyClasses.filter((item) => item !== "HypeUpsell__PlanType--Inactive");
    }
    if (selectedPlan === 'yearly') {
        annualClasses = annualClasses.filter((item) => item !== "HypeUpsell__PlanType--Inactive");
    }

    return (
        <Modal
            open
            title="Please provide payment info"
            onClose={close}
            primaryAction={{
                content: 'Complete Upgrade',
                onAction: submitCardInfo
            }}
        >
            <Modal.Section>
                <label className="HypeAuditor__Label">Items</label>
                <div className="HypeAuditor__Product">
                    <div>
                        <DisplayText size="small">Enhanced {socialPlatformName} - {username}</DisplayText>
                        <p className="HypeAuditor__ProductDescription">Real time analytics of your {socialPlatformName} Profile.</p>
                    </div>
                    <div className="HypeAuditor__ProductDetail">
                        <div className="HypeAuditor__ProductSelect">
                            <h3 className={monthlyClasses.join(' ')} onClick={() => setSelectedPlan('monthly')} >Monthly</h3>
                            <h3 className={annualClasses.join(' ')} onClick={() => setSelectedPlan('yearly')}>Yearly</h3>
                        </div>
                        <h1 className="HypeUpsell__PlanPrice">{pricing[selectedPlan] && pricing[selectedPlan].perMonth} / month</h1>
                        <p className="HypeUpsell__PlanDisclaimer">*Billed {pricing[selectedPlan] && pricing[selectedPlan].billed}</p>
                    </div>
                </div>
            </Modal.Section>
            <Modal.Section>
                {isLoading
                    ? (
                        <div className="HypeUpsell__ModalSpinner">
                            <Spinner size="large" color="inkLightest" />
                        </div>
                    )
                    : (
                        <>
                            <label className="HypeAuditor__Label">
                                Name on Card
                                <input
                                    className="HypeAuditor__Input"
                                    onChange={handleNameChange}
                                    placeholder="e.g. Bruce Wayne"
                                    value={name}
                                />
                            </label>
                            <label className="HypeAuditor__Label">
                                Card Details
                                <CardElement
                                    {...createOptions('18px')}
                                />
                            </label>
                        </>
                    )}
            </Modal.Section>
        </Modal>
    );
};

const CardModalWithStripe = injectStripe(CardModal);

const HAPaymentModal = (props) => {
    const { username, cognito_id, paymentHandler, close, socialPlatform } = props;

    return (
        <Elements
            className="App__Main"
        >
            <CardModalWithStripe
                socialPlatform={socialPlatform}
                close={close}
                username={username}
                cognito_id={cognito_id}
                paymentHandler={paymentHandler}
            />
        </Elements>
    );
};

const HASettingsModal = (props) => {
    const { socialPlatform, data, toggleUpgrade, toggleCancel, close } = props;

    return (
        <Modal
            open
            title={`${hypeSocialPlatforms[socialPlatform.toLowerCase()].analytics} Settings`}
            onClose={close}
        >
            <div className="HypeAuditor__SettingsWrapper">
                <div className="HypeAuditor__AccountSettings HypeAuditor__AccountSettingsHeader">
                    <div className="HypeAuditor__AccountUsername">Username</div>
                    <div className="HypeAuditor__AccountStatus">Status</div>
                    <div className="HypeAuditor__AccountAction" />
                </div>
                <div className="HypeAuditor__Settings">
                    {Array.isArray(data) && (
                        data.map((item) => {
                            return (
                                <HypeAuditorAccountSettings
                                    key={item.username}
                                    account={item}
                                    toggleUpgrade={toggleUpgrade}
                                    toggleCancel={toggleCancel}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        </Modal>
    );
};

const HACancelModal = (props) => {
    const { username, cognito_id, cancelUserAccount, close } = props;

    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleReasonChange = (value) => {
        if (!value) {
            setReason('');
            return;
        }
        setReason(value);
    };

    const submitCancelAccount = async () => {
        if (!username || !cognito_id) {
            return null;
        }

        setIsLoading(true);
        return cancelUserAccount(username, cognito_id, reason)
            .then(() => {
                setIsLoading(false);
                close();
            })
            .catch(() => {
                setIsLoading(false);
                close();
            });
    };

    return (
        <Modal
            open
            title="Are you sure you want to cancel?"
            onClose={close}
        >
            {isLoading
                ? (
                    <div className="HypeUpsell__ModalSpinner">
                        <Spinner size="large" color="inkLightest" />
                    </div>
                )
                : (
                    <div className="HypeAuditor__CancelWrapper">
                        <TextField
                            label="Reason for cancellation"
                            value={reason}
                            onChange={handleReasonChange}
                        />
                        <div className="HypeAuditor__CancelSettings">
                            <p className="HypeAuditor__CancelDescription">Cancel subscription for {username}'s Enhanced Profile?</p>
                            <div className="HypeAuditor__CancelActions">
                                <LlamaButton secondary onClick={() => close()}>No</LlamaButton>
                                <LlamaButton onClick={() => submitCancelAccount()}>Yes</LlamaButton>
                            </div>
                        </div>
                    </div>
                )}
        </Modal>
    );
};

const VerifyAccountModal = (props) => {
    const {
        socialPlatform,
        username,
        cognito_id,
        verifyUserAccount,
        close,
        hashCode,
        setIsVerifyUserClicked
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const submit = async () => {
        if (!username || !cognito_id) {
            return null;
        }

        setMessage('');
        setIsLoading(true);
        verifyUserAccount(username, cognito_id)
            .then((res) => {
                const result = res.value.data.verifyTikTokUser
                    ? res.value.data.verifyTikTokUser
                    : res.value.data.verifyInstagramUser;

                if (result.error) {
                    setMessage(result.error.message);
                    setIsLoading(false);
                    setIsVerifyUserClicked(false);

                    return;
                }
                setIsLoading(false);
                setIsVerifyUserClicked(false);
                close();
            })
            .catch(() => {
                setIsLoading(false);
                setIsVerifyUserClicked(false);
                close();
            });
    };

    return (
        <Modal
            open
            title="Verify Account Ownership"
            onClose={close}
        >
            <Modal.Section>
                <div className="HypeAuditor__VerifyWrapper">
                    <p className="HypeAuditor__VerificationText">
                        To share these analytics publicly with any merchant who views your Llama profile, you&rsquo;ll need to <span className="HypeAuditor__VerificationText HypeAuditor__VerificationText--bold">verify your account by copying the code below into your {` ${hypeSocialPlatforms[socialPlatform.toLowerCase()].name} `} bio</span>. This proves to merchants that you own this account and helps them accurately understand your audience engagement.
                    </p>
                    <p className="HypeAuditor__VerificationText HypeAuditor__VerificationText--centered">Copy this code into your Instagram bio and click verify</p>
                    <div className="HypeAuditor__VerificationHeader">
                        <div className="HypeAuditor__VerificationCode">{hashCode}</div>
                    </div>
                    <div className="HypeAuditor__VerificationFooter">
                        <div className="HypeAuditor__AccountAction">
                            <LlamaButton classes={['HypeAuditor__VerificationButton']} onClick={submit}>Verify Ownership</LlamaButton>
                        </div>
                        <p className="HypeAuditor__VerificationTextSubdued">
                            You may remove the code after verifying
                        </p>
                    </div>
                </div>
                {message !== '' && <InlineError message={`${message} Please try again!`} />}
            </Modal.Section>
        </Modal>
    );
};

// propTypes
CardModal.propTypes = {
    username: PropTypes.string.isRequired,
    cognito_id: PropTypes.string.isRequired,
    socialPlatform: PropTypes.string.isRequired,
    stripe: PropTypes.shape({ createToken: PropTypes.func.isRequired }).isRequired,
    close: PropTypes.func.isRequired,
    paymentHandler: PropTypes.func
};

HAPaymentModal.propTypes = {
    username: PropTypes.string.isRequired,
    cognito_id: PropTypes.string.isRequired,
    socialPlatform: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    paymentHandler: PropTypes.func
};

HASettingsModal.propTypes = {
    socialPlatform: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({ username: PropTypes.string.isRequired })
    ).isRequired,
    close: PropTypes.func.isRequired,
    toggleUpgrade: PropTypes.func.isRequired,
    toggleCancel: PropTypes.func.isRequired
};

HACancelModal.propTypes = {
    username: PropTypes.string.isRequired,
    cognito_id: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    cancelUserAccount: PropTypes.func.isRequired
};

VerifyAccountModal.propTypes = {
    socialPlatform: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    cognito_id: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    hashCode: PropTypes.string.isRequired,
    setIsVerifyUserClicked: PropTypes.func.isRequired,
    verifyUserAccount: PropTypes.func.isRequired
};

export {
    HAPaymentModal,
    HASettingsModal,
    HACancelModal,
    VerifyAccountModal
};
