import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner, Banner } from '@shopify/polaris';
import { getAffiliateData } from '../../store/actions/action_getAffiliate';
import { createFacebookAffData } from '../../store/actions/social/create-facebook';
import { createTwitterAffData } from '../../store/actions/social/create-twitter';
import { createInstagramAffData } from '../../store/actions/social/create-instagram';
import { getSocialRedirectUrl } from '../../store/actions/social/getSocialRedirect';
import { updateAffiliate } from '../../store/actions/action_updateAffiliate';
import SocialAuthComponent from '../affiliate-auth/social-auth';
import './emptyState.css';

class EmptyComponentState extends Component {
    constructor(props) {
        super(props);
        this.state = {
            platform: this.props.config.platform,
            isAuthWindowOpen: false,
            socialAuthData: {},
            isLoading: false
        };

        this.state.linkingAccount = false;
        this.refreshParentComponent = this.props.refreshParentComponent;
        this.responseParentSocialAccounts = this.responseSocialAccounts.bind(this);
    }

    componentDidMount() {
        if (this.props.socialAuthData && this.props.socialAuthData.redirectUrl) {
            this.setState({ socialAuthData: this.props.socialAuthData });
        } else {
            this.getRedirectUrl();
        }
    }

    getRedirectUrl = () => {
        let platformAuthData = {};
        this.setState({ isLoading: true });

        this.props.getSocialRedirectUrl(this.state.platform)
            .then((result) => {
                platformAuthData.redirectUrl = result.value.data.location;
                this.setState({ socialAuthData: platformAuthData });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                console.log(`Error: Unable to get redirect URL for ${this.state.platform}`, error);
            })
    }

    responseSocialAccounts = (response) => {
        var id = sessionStorage.getItem("username");
        var isResponseEmpty = true;

        this.handleSocialAuthWindow();
        switch (response.platform) {
            case 'facebook':
                console.log('RESPONSE', response);
                if (response.code) {
                    this.props.createFacebookAffData(response.code, id)
                        .then((result) => {
                            console.log(result);
                            if (result && result.value && result.value.data && result.value.data.data && result.value.data.data.createFacebookUser && result.value.data.data.createFacebookUser.pages && result.value.data.data.createFacebookUser.pages.length > 0) {
                                return this.refreshParentComponent();
                            } else {
                                this.setState({
                                    socialError: 'You need to select at least one public Page to connect. Due to Facebook privacy restrictions, we are unable to calculate engagement scores from just your personal profile.'
                                });
                                return '';
                            }
                        });
                } else {
                    isResponseEmpty = false;
                }
                break;
            case 'twitter':
                if (response.code && (response.code.oauth_token && response.code.oauth_verifier)) {
                    this.props.createTwitterAffData(response.code, id)
                        .then(() => this.refreshParentComponent())
                }
                else { isResponseEmpty = false; }
                break;
            case 'instagram':
                if (response.code) {
                    this.props.createInstagramAffData(response.code, id)
                        .then(() => this.refreshParentComponent())
                }
                else { isResponseEmpty = false; }
                break;
            case 'youtube':
                if (response.code) {
                    this.props.createYoutubeAffData(response.code, id)
                        .then(() => this.refreshParentComponent())
                } else { isResponseEmpty = false; }
                break;
            default:
                break;
        }

        if (isResponseEmpty) { this.setState({ linkingAccount: false }); }
    }

    handleSocialAuthWindow = () => {
        this.setState({ isAuthWindowOpen: !this.state.isAuthWindowOpen });
    }

    renderEmpyStateView = () => {
        let oauthConnectButton = '';

        switch (this.state.platform) {
            case 'facebook':
                oauthConnectButton = <button className="empty-social__button facebook" type="button" onClick={this.handleSocialAuthWindow}>Continue with Facebook</button>;
                break;
            case 'twitter':
                oauthConnectButton = <button className="empty-social__button twitter" type="button" onClick={this.handleSocialAuthWindow}>Link Account</button>;
                break;
            case 'instagram':
                oauthConnectButton = <button className="empty__social__button instagram__button" type="button" onClick={this.handleSocialAuthWindow}>Link Account</button>;
                break;
            case 'youtube':
                oauthConnectButton = <button className="empty-social__button youtube" type="button" onClick={this.handleSocialAuthWindow}>Link Channel</button>;
                break;
            default:
                break;
        }

        if (this.state.linkingAccount) {
            return (
                <div className='empty__state__content__loading'>
                    <p className='empty__state__header'>Linking to your account</p>
                    <p className='empty__state__description'>Please wait... <Spinner size="small" color="teal"></Spinner></p>
                </div>
            )
        }

        if (this.state.isLoading) {
            return (
                <div className='empty__state__content__loading'>
                    <p className='empty__state__description'>Loading... <Spinner size="small" color="teal"></Spinner></p>
                </div>
            )
        }

        return (
            <div>
                <p className="empty__state__header">{this.props.config.heading}</p>
                <p className="empty__state__description">{this.props.config.description}</p>
                {this.props.config.linkSocialAccount && !this.state.socialError && oauthConnectButton}
                {this.state.platform === 'facebook'
                    && <p className="empty__state__disclaimer"><strong>Please note:</strong> Your Facebook profile name, and photo will only be used to connect or disconnect your Facebook account and will never be displayed on your Llama profile. We don&rsquo;t obtain engagement stats from your personal profile feed, so you&rsquo;ll need select at least one of the Facebook Pages you manage when asked.</p>
                }
                {this.state.platform === 'youtube'
                    && <p className="empty__state__disclaimer"><strong>Please note:</strong> Your Google profile account name and photo will only be used to reference your connected YouTube accounts and will never be displayed on your Llama profile. You&rsquo;ll need to have at least one public YouTube channel with at least one public video to connect.</p>
                }
                {this.state.socialError
                    && (
                        <Banner
                            status="warning"
                            title="Unable to connect"
                            action={{
                                content: 'Retry',
                                onAction: this.handleSocialAuthWindow
                            }}
                        >
                            {this.state.socialError}
                        </Banner>
                    )
                }
            </div>
        )
    }

    render() {
        return (
            <div className="empty__state__container">
                { this.renderEmpyStateView() }
                { this.state.isAuthWindowOpen
                    && (
                        <SocialAuthComponent
                            responseParentSocialAccounts={this.responseParentSocialAccounts}
                            data={this.state}
                            {...this.props}
                        />
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { ...state };
}

const mapDispatchtoProps = (dispatch) => {
    return {
        get_affiliate: (id) => dispatch(getAffiliateData(id)),
        createFacebookAffData: (data, id) => dispatch(createFacebookAffData(data, id)),
        createTwitterAffData: (oauth_token, oauth_token_secret, cognito_id, screen_name) => dispatch(createTwitterAffData(oauth_token, oauth_token_secret, cognito_id, screen_name)),
        createInstagramAffData: (data, id) => dispatch(createInstagramAffData(data, id)),
        updateAffiliate: (data, id) => dispatch(updateAffiliate(data, id)),
        getSocialRedirectUrl: (platform) => dispatch(getSocialRedirectUrl(platform))
    }
}

EmptyComponentState.propTypes = {
    config: PropTypes.object,
    refreshParentComponent: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchtoProps)(EmptyComponentState);
