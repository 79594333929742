import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Frame, Toast, ChoiceList, SkeletonBodyText } from '@shopify/polaris';
import { v4 as uuidv4 } from 'uuid';
import { LlamaButton } from 'llama-library/components';

import { getSmartLinksForOfferDetail } from '../../store/actions/action_getSmartLinks';
import { getAffliateApprovedOffers } from '../../store/actions/action_getApprovedOffers';
import { updateSmartLink } from '../../store/actions/action_updateSmartlink';

import getSmartlinkAutomaticOffers from '../../utils/get-smartlink-automatic-offers';

import './smartlinks-ranking.css';

const SmartLinksRanking = ({ offer, affiliate, history, trackingLink, linkLoading, trackingLinkError, dispatchGetAffliateApprovedOffers, dispatchUpdateSmartLink }) => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [actionType, setActionType] = useState(null);

    const [linkPosition, setLinkPosition] = useState(null);
    const [manualLinkPosition, setManualLinkPosition] = useState(-1);
    const [displayMode, setDisplayMode] = useState(null);
    const [plan, setPlan] = useState(null);
    const [maxLinks, setMaxLinks] = useState(null);
    const [manualLinks, setManualLinks] = useState(null);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [primaryAction, setPrimaryAction] = useState(null);
    const [secondaryAction, setSecondaryAction] = useState(null);
    const [message, setMessage] = useState(null);

    const [linkToReplace, setLinkToReplace] = useState([]);

    // get current links on mount
    useEffect(() => {
        // get manual links
        getSmartLinksForOfferDetail(affiliate.cognito_id)
            .then(async (result) => {
                if (result && result.data && result.data.getSmartLinks && result.data.getSmartLinks) {
                    const smartlinks = result.data.getSmartLinks;
                    const planName = smartlinks.settings.subscription.plan === 'plan_pro' ? 'pro' : 'free';
                    const linkLimit = smartlinks.limits[planName][smartlinks.displayMode];
                    let currentLinks = smartlinks.manualLinks;

                    // if they're in automatic mode, get their offers formatted as smartlinks
                    if (smartlinks.displayMode === 'automatic') {
                        const offers = await getSmartlinkAutomaticOffers(dispatchGetAffliateApprovedOffers, affiliate.cognito_id, affiliate.tracking_id);
                        currentLinks = offers;

                        // check if the offer is also in the manual list and store the position
                        const manualLinkIndex = smartlinks.manualLinks.findIndex((link) => {
                            return link.offerId === offer.offer_id;
                        });
                        setManualLinkPosition(manualLinkIndex);
                    }

                    // get the link's current position
                    const linkIndex = currentLinks.findIndex((link) => {
                        return link.offerId === offer.offer_id;
                    });
                    setLinkPosition(linkIndex);

                    // set button to Add, Boost, or hidden based on current link position
                    if (linkIndex === -1 /* || linkIndex >= linkLimit */) { // not on the list or past the limit (hidden)
                        setActionType('add');
                    } else if (linkIndex !== 0) { // not at #1, but still within the limit
                        setActionType('move');
                    } else { // at #1
                        setActionType(null);
                    }

                    setDisplayMode(smartlinks.displayMode);
                    setPlan(planName);
                    setMaxLinks(linkLimit);
                    setManualLinks(smartlinks.manualLinks);

                    setLoading(false);
                }
            });
    }, []);

    const goToSmartLinksPro = () => {
        history.push('/smartlinks/plans');
    };

    const closeModal = () => {
        setConfirmationModalOpen(false);
    };

    const runUpdateSmartLink = (newManualLinks) => {
        const payload = {
            displayMode: 'manual',
            links: newManualLinks
        };
        if (displayMode === 'automatic') {
            payload.switched = true;
        }

        dispatchUpdateSmartLink(affiliate.cognito_id, payload)
            .then(() => {
                setManualLinks(newManualLinks);
                setDisplayMode('manual');
                setActionType(null);
                setLinkPosition(0);
                setSaving(false);
                closeModal();
                if (actionType === 'add') {
                    setToastVerbiage('Offer added to the top of your SmartLinks page');
                } else {
                    setToastVerbiage('Offer moved to top of your SmartLinks page');
                }
            })
            .catch((err) => {
                console.error(err);
                setToastVerbiage('Oops, something went wrong. Unable to add offer to your SmartLinks page.');
                setSaving(false);
            });
    };

    // pluck out the replacement and insert the current offer at the top
    const replaceLink = () => {
        setSaving(true);

        // check if the current offer is already in the manual list and store the index
        const manualIndex = manualLinks.findIndex((link) => {
            return offer.offer_id === link.offerId;
        });

        // get the position of the replacement so we can pluck it out
        const replacementIndex = manualLinks.findIndex((link) => {
            return linkToReplace[0] === link.id;
        });

        // copy the current list to avoid mutating
        const newManualLinks = [...manualLinks];

        // pluck the replacement from the list
        const replacement = newManualLinks.splice(replacementIndex, 1);

        // if the offer doesn't exist in the manual list, add it to the top...
        if (manualIndex === -1) {
            newManualLinks.unshift({
                id: uuidv4(),
                title: `${offer.category} Products`,
                url: trackingLink,
                offerId: offer.offer_id,
                offerName: offer.name,
                company: offer.advertiser.company
            });
        // ...if it does, move it to the top
        } else {
            const linkData = newManualLinks.splice(manualIndex - 1, 1);
            newManualLinks.unshift(linkData[0]);
        }

        // if they're on the free plan, place the replacement at the bottom so it's available if they decide to upgrade later on
        if (plan === 'free') {
            newManualLinks.push(replacement[0]);
        }

        const payload = {
            displayMode: 'manual',
            links: newManualLinks
        };
        if (displayMode === 'automatic') {
            payload.switched = true;
        }

        runUpdateSmartLink(newManualLinks);
    };

    // build a modal for choosing which manual link to replace
    const setupReplaceLinkModal = () => {
        setModalTitle('Replace a Link');
        setModalContent(
            <>
                <p className="add-to-smartlinks-modal-paragraph">
                    {plan === 'pro'
                        ? <>You currently have the maximum amount of manual links on your SmartLinks page. To {actionType} {offer.name} to the top of the list, you&rsquo;ll need to choose one your current links to replace.</>
                        : 'Which link would you like to replace?'
                    }
                </p>
                <ChoiceList
                    choices={manualLinks.reduce((acc, link) => {
                        // if (acc.length < maxLinks) {
                        acc.push({
                            label: link.title,
                            value: link.id
                        });
                        // }
                        return acc;
                    }, [])}
                    onChange={(value) => { return setLinkToReplace(value); }}
                    selected={linkToReplace}
                    allowMultiple={false}
                />
            </>
        );
        setPrimaryAction({
            content: 'Replace Link',
            onAction: replaceLink,
            disabled: linkToReplace.length === 0
        });
        setSecondaryAction({
            content: 'Cancel',
            onAction: closeModal
        });
    };

    // since the modal props are being stored in state, they won't rerender when an inner state value changes, so we need to force the replace link modal to rerender when linkToReplace changes
    useEffect(() => {
        if (linkToReplace.length > 0) {
            setupReplaceLinkModal();
        }
    }, [linkToReplace]);

    // build a modal that tells the user they're at max links and that they need to upgrade or replace a link to continue
    const setupUpgradeModal = () => {
        setModalTitle(`Your SmartLinks page ${displayMode === 'manual' ? 'is currently at' : 'will exceed'} maximum length`);
        setModalContent(<p>As a SmartLinks FREE user, your page is limited to a <strong>maximum of {maxLinks} manual links</strong>. Before you can {actionType} this offer, you&rsquo;ll need to either <strong>choose a link to replace</strong> or <strong>upgrade to the PRO plan</strong>.</p>);
        setPrimaryAction({
            content: 'Upgrade to PRO',
            onAction: goToSmartLinksPro
        });
        setSecondaryAction({
            content: 'Replace a Link',
            onAction: setupReplaceLinkModal
        });
    };

    // add or reposition the offer in the manual links list, then save
    const addMoveOfferToSmartLinks = () => {
        setSaving(true);

        const manualIndex = manualLinks.findIndex((link) => {
            return offer.offer_id === link.offerId;
        });

        const newManualLinks = [...manualLinks];
        // if the offer doesn't exist in manual, add it to the top
        if (manualIndex === -1) {
            newManualLinks.unshift({
                id: uuidv4(),
                title: `${offer.category} Products`,
                url: trackingLink,
                offerId: offer.offer_id,
                offerName: offer.name,
                company: offer.advertiser.company
            });
        // if it does, move it to the top
        } else {
            const linkData = newManualLinks.splice(manualIndex, 1);
            newManualLinks.unshift(linkData[0]);
        }

        runUpdateSmartLink(newManualLinks);
    };

    // only runs if user is in automatic mode and clicks the switch to manual mode button
    // determine whether to show a subsequent modal or start the database query
    const attemptAddFromModalButton = () => {
        // if their current manual links are maxed out, and the link isn't alredy in the manual list
        if (manualLinks.length >= maxLinks && (manualLinkPosition === -1 || manualLinkPosition >= maxLinks)) {
            // if they're on the free plan, show the upgrade-or-replace modal
            if (plan === 'free') {
                setupUpgradeModal();
            // if they're on the pro plan go just show the replace modal
            } else {
                setupReplaceLinkModal();
            }
        } else {
            addMoveOfferToSmartLinks();
        }
    };

    // build the initial confirmation modal and current status message after links are done loading, whenever the modal is closed, and when the link position changes
    useEffect(() => {
        if (!loading) {
            // set the current link position message so the user knows where they're link is if it's visible
            if (actionType === 'add') {
                setMessage('This offer is not currently displayed on your SmartLinks page.');
            } else {
                setMessage(`This offer is currently in position #${linkPosition + 1} on your SmartLinks page.`);
            }

            // determine and build the initial confirmation modal for manual mode, if needed
            /* if (displayMode === 'manual' && (linkPosition >= maxLinks || (linkPosition === -1 && manualLinks.length >= maxLinks))) {
                if (plan === 'free') {
                    setupUpgradeModal();
                } else {
                    setupReplaceLinkModal();
                }
            } */

            // build a confirmation modal about switching from automatic to manual mode
            if (displayMode === 'automatic') {
                let introText = '';

                // if the link is positioned past the limit, tell them it's lower performance and is hidden
                if (linkPosition >= maxLinks) {
                    introText = <>{offer.name} isn&rsquo;t doing as well as your other offers, so it&rsquo;ll stay hidden until it outperforms another offer. If you really want to show it now,</>;
                // general intro for visible links
                } else {
                    introText = 'To move this to the top of the list';
                }

                setModalTitle(`To ${actionType} this offer, switch to manual mode`);
                setModalContent(
                    <>
                        <p className="add-to-smartlinks-modal-paragraph">Your SmartLinks page is currently set to <strong>automatic mode</strong>. This means your approved offers are displayed in order of how well they&rsquo;re performing with your audience. {introText} you&rsquo;ll need to <strong>switch to manual mode</strong>.</p>
                        <p><strong>Please note: Offers currently displayed in automatic mode will not be moved over when you switch modes unless you&rsquo;ve previously added them in manual mode</strong>. You can switch between automatic and manual mode at any time in your SmartLinks settings.</p>
                    </>
                );
                setPrimaryAction({
                    content: `Switch to manual mode & ${actionType} to top`,
                    onAction: addMoveOfferToSmartLinks //attemptAddFromModalButton
                });
                setSecondaryAction({
                    content: `Don’t ${actionType}, stay in automatic mode`,
                    onAction: closeModal
                });
            }
        }
    }, [loading, confirmationModalOpen, linkPosition]);

    // if we built a modal, open it, otherwise run the database query to add/move it to their manual links
    const attemptAddMoveFromSidebarButton = () => {
        if (modalContent) {
            setConfirmationModalOpen(true);
        } else {
            addMoveOfferToSmartLinks();
        }
    };

    if (loading || linkLoading) {
        return <SkeletonBodyText lines="2" />;
    }
    return (
        <>
            <Modal
                title={modalTitle}
                open={confirmationModalOpen}
                onClose={closeModal}
                primaryAction={{
                    ...primaryAction,
                    loading: saving
                }}
                secondaryActions={[secondaryAction]}
            >
                <Modal.Section>
                    {modalContent}
                </Modal.Section>
            </Modal>

            <p>{message}</p>
            {actionType !== null && !trackingLinkError
                && (
                    <>
                        <LlamaButton
                            onClick={attemptAddMoveFromSidebarButton}
                            disabled={saving}
                            loading={saving}
                            classes={[actionType]}
                        >
                            {actionType === 'add' ? 'Add to SmartLinks' : 'Move to Top'}
                        </LlamaButton>
                        <Frame>{toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => { return setToastVerbiage(''); }} /> : null}</Frame>
                    </>
                )
            }
        </>
    );
};

SmartLinksRanking.propTypes = {
    offer: PropTypes.shape({
        offer_id: PropTypes.string.isRequired,
        offer_url: PropTypes.string.isRequired,
        advertiser: PropTypes.shape({
            company: PropTypes.string.isRequired
        }).isRequired,
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired
    }).isRequired,
    affiliate: PropTypes.shape({
        cognito_id: PropTypes.string.isRequired,
        tracking_id: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    trackingLink: PropTypes.string.isRequired,
    linkLoading: PropTypes.bool.isRequired,
    trackingLinkError: PropTypes.bool.isRequired,
    dispatchGetAffliateApprovedOffers: PropTypes.func.isRequired,
    dispatchUpdateSmartLink: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetAffliateApprovedOffers: (cognito_id) => { return dispatch(getAffliateApprovedOffers(cognito_id)); },
        dispatchUpdateSmartLink: (id, data) => { return dispatch(updateSmartLink(id, data)); }
    };
};

export default connect(null, mapDispatchToProps)(SmartLinksRanking);
