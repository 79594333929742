import React from 'react';

import x from '../assets/x.svg';
import './tag.css'

export const Tag = ({ children, removeAction, background = '#5c6ac4', history }) => {
    let xStyle = {
        height: '8px',
        width: '8px',
        opacity: '.4',
        marginLeft: '4px',
        cursor: 'pointer'
    }

    const goToDiscoverWithTag = () => {
        if(!history) return;
        history.push('/discover?tagged=' + children.replace(/\s/g, '+'))
    }

    return (
        <span className="offer-tag" onClick={goToDiscoverWithTag}>
            {children}
            {removeAction &&<img style={xStyle} src={x} alt={`Remove ${children}`} onClick={() => removeAction(children)} />}
        </span>
    )

}