import { sendGraphQL } from './sendRequest';

export const GET_ACHIEVEMENT_NOTIFICATIONS = 'GET_ACHIEVEMENT_NOTIFICATIONS';
export const GET_ACHIEVEMENT_NOTIFICATIONS_FULFILLED = 'GET_ACHIEVEMENT_NOTIFICATIONS_FULFILLED';

export const UPDATE_ACHIEVEMENTS_VIEW_STATUS = 'UPDATE_ACHIEVEMENTS_VIEW_STATUS';

export const getAchievementNotifications = (recipient_id) => {
    const query = `
        query getAchievementNotifications($recipient_id: String) {
            getAchievementNotifications(recipient_id: $recipient_id) {
                _id
                badge_id
                view_status
                date_earned
                badge_img
                badge_title
                badge_type
                notification_type
            }
        }
    `;

    const variables = { recipient_id };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_ACHIEVEMENT_NOTIFICATIONS,
        payload: sendGraphQL(payload, false, true)
            .then((response) => response.data)
    };
};

export const markAchievementAsSeen = (notification_ids, recipient_id) => {
    if (!notification_ids || !recipient_id) {
        return null;
    }
    const query = `
        mutation markAchievementAsSeen($notification_ids: [String], $recipient_id: String) {
            markAchievementAsSeen(notification_ids: $notification_ids, recipient_id: $recipient_id) {
                _id
                view_status
            }
        }
    `;

    const variables = {
        notification_ids,
        recipient_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => response.data);
};

export const updateAchievementViewStatus = (achievementNotifications) => {
    return {
        type: UPDATE_ACHIEVEMENTS_VIEW_STATUS,
        payload: achievementNotifications
    };
};

export const createNewNotification = (recipient_id) => {
    const query = `
        mutation createNewNotification($recipient_id: String) {
            createNewNotification(recipient_id: $recipient_id) {
                _id
                badge_id
                view_status
                date_earned
                badge_img
                badge_title
                badge_type
                notification_type
            }
        }
    `;

    const variables = { recipient_id };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => response.data);
}