import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    Tabs,
    Toast,
    Frame
} from '@shopify/polaris';

import { connect } from 'react-redux';
import { changeHeaderTitle } from '../store/actions/header';
import { getAffliateApprovedOffers } from '../store/actions/action_getApprovedOffers';
import { getSmartLinks } from '../store/actions/action_getSmartLinks';
import { updateSmartLink } from '../store/actions/action_updateSmartlink';
import { updateCustomization } from '../store/actions/action_updateSmartlinkCustomization';
import { updateSocialProfile } from '../store/actions/action_updateSmartlinkSocialProfile';
import { checkSmartLinkCustomId } from '../store/actions/action_checkSmartLinkCustomId';
import { cancelSmartlinkSubscription } from '../store/actions/action_createSmartlinkSubscription';

import getSmartlinkAutomaticOffers from '../utils/get-smartlink-automatic-offers';
import { themes } from '../utils/smart-link-themes';

import SmartLinkOffers from '../components/SmartLink/smart-link-offers';
import SmartLinkAnalytics from '../components/SmartLink/smart-link-analytics';
import SmartLinkSettings from '../components/SmartLink/smart-link-settings';
import SmartLinkPlans from '../components/SmartLink/smart-link-plans';
import SmartLinkBilling from '../components/SmartLink/smart-link-billing';
import SmartLinkMobile from '../components/SmartLink/smart-link-mobile';
import SmartLinksURLActions from '../components/SmartLink/smartlinks-url-actions';

import './styles/smart-link.css';

// corresponds to the tab index values for Polaris Tabs to make conditionals easier to understand
const tabNames = {
    LINKS: 0,
    ANALYTICS: 1,
    CUSTOMIZE: 2,
    PLANS: 3,
    BILLING: 4
};
const tabLinks = {
    0: '',
    1: 'analytics',
    2: 'customize/general',
    3: 'plans',
    4: 'billing'
};

class SmartLink extends Component {
    mobilePreviewBreakpoint = 980;

    defaultTheme = 'theme-default';

    constructor(props) {
        super(props);

        this.state = {
            currentTab: (props.currentTab && tabNames[props.currentTab]) ? tabNames[props.currentTab] : tabNames.LINKS,
            toastVerbiage: null,
            links: [],
            hiddenLinks: [],
            linkSelectionMode: 'automatic',
            linkLimits: {
                free: {
                    automatic: 0,
                    manual: 0
                },
                pro: {
                    automatic: 0,
                    manual: 0
                }
            },
            offers: [],
            uniqueId: '',
            customId: '',
            currentHandle: '',
            headline: '',
            full_name: '',
            plan: 'free',
            linksLoading: true,
            saving: false,
            previewVisible: (window.innerWidth > this.mobilePreviewBreakpoint),
            miniSidebar: (window.innerWidth <= this.mobilePreviewBreakpoint),

            themeData: {
                ...themes[this.defaultTheme],
                theme: this.defaultTheme
            },
            customThemePreview: { active: false },
            socialProfiles: {
                facebook: '',
                twitter: '',
                instagram: '',
                tiktok: '',
                youtube: '',
                soundcloud: ''
            },
            pageWidth: window.innerWidth
        };
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            if (window.innerWidth <= this.mobilePreviewBreakpoint && this.state.miniSidebar === false) {
                this.setState({
                    miniSidebar: true,
                    previewVisible: false
                });
            } else if (window.innerWidth > this.mobilePreviewBreakpoint && this.state.miniSidebar === true) {
                this.setState({
                    miniSidebar: false,
                    previewVisible: true
                });
            }
            this.setState({ pageWidth: window.innerWidth });
        });

        this.props.changeHeaderTitle('SmartLinks');
        this.getSmartLinks('');
    }

    componentDidUpdate(prevProps, prevState) {
        // don't let shopify polaris tabs scroll the tabs out of view
        if (prevState.currentTab !== this.state.currentTab) {
            window.scrollTo(0, 0);
        }
    }

    setToastVerbiage = (toastVerbiage) => {
        this.setState({ toastVerbiage });
    }

    getSmartLinks(mode) {
        this.props.getSmartLinks(this.props.affiliate.cognito_id, mode, 'editor')
            .then((result) => {
                console.log(result);
                if (
                    !result
                    || !result.value
                    || !result.value.data
                    || !result.value.data.getSmartLinks
                    || !Array.isArray(result.value.data.getSmartLinks.manualLinks)
                ) {
                    this.setState({
                        linksLoading: false
                    });
                    return;
                }

                const { manualLinks, automaticLinks, domains, bioLink, lastUpdated } = result.value.data.getSmartLinks;
                const plan = (result.value.data.getSmartLinks.settings.subscription.plan === 'trial') ? 'free' : 'pro';
                const { displayMode, limits } = result.value.data.getSmartLinks;
                let { socialProfiles } = result.value.data.getSmartLinks;
                if (!socialProfiles) {
                    socialProfiles = this.state.socialProfiles;
                }

                const truncatedLinks = this.truncateLinks(manualLinks, limits, plan, displayMode);
                const truncatedOffers = this.truncateLinks(automaticLinks, limits, plan, displayMode);

                let themeData = result.value.data.getSmartLinks.customizations;
                if (themeData.theme !== 'custom') {
                    themeData = {
                        ...themeData,
                        ...themes[themeData.theme]
                    };
                } else {
                    this.setState({
                        customThemePreview: {
                            ...themeData,
                            active: false
                        }
                    });
                }

                this.setState({
                    manualLinks,
                    automaticLinks,
                    links: (displayMode === 'manual')
                        ? truncatedLinks.links
                        : truncatedOffers.links,
                    hiddenLinks: (displayMode === 'manual')
                        ? truncatedLinks.hiddenLinks
                        : truncatedOffers.hiddenLinks,
                    linkLimits: limits,
                    plan,
                    expiresAt: result.value.data.getSmartLinks.settings.subscription.expires
                        ? result.value.data.getSmartLinks.settings.subscription.expires
                        : null,
                    linkSelectionMode: displayMode,
                    uniqueId: result.value.data.getSmartLinks.unique_id,
                    customId: result.value.data.getSmartLinks.custom_id,
                    currentHandle: result.value.data.getSmartLinks.current_handle,
                    headline: (plan === 'pro')
                        ? result.value.data.getSmartLinks.headline
                        : result.value.data.getSmartLinks.full_name,
                    fullName: result.value.data.getSmartLinks.full_name,
                    linksLoading: false,
                    themeData,
                    socialProfiles,
                    domains,
                    bioLink,
                    lastUpdated
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    togglePreview = () => {
        if (window.innerWidth <= this.mobilePreviewBreakpoint) {
            this.setState({ previewVisible: !this.state.previewVisible });
        }
    }

    handleTabChange = (selectedTabIndex) => {
        this.props.history.push(`/smartlinks/${tabLinks[selectedTabIndex]}`);
        this.setState({ currentTab: selectedTabIndex });
    }

    goToPlans = () => {
        this.setState({ currentTab: tabNames.PLANS });
    }

    // ===OFFERS/LINKS=== //

    // save new links from add/edit/remove interactions
    updateLinks = (links, manualLinks) => {
        const payload = {
            displayMode: this.state.linkSelectionMode,
            links: manualLinks
        };

        return this.props.updateSmartLink(this.props.authData.username, payload)
            .then(() => {
                this.setState({
                    links,
                    manualLinks
                });
            })
            .catch((err) => { console.error(err); });
    }

    // auto vs manual
    switchMode = (mode) => {
        this.setState({ linksLoading: true });

        // swap out current links for auto/manual links and truncate based on current plan
        let newLinks = [];
        if (mode === 'automatic') {
            newLinks = this.truncateLinks(this.state.automaticLinks, this.state.linkLimits, this.state.plan, mode);
            this.setState({
                links: newLinks.links,
                hiddenLinks: newLinks.hiddenLinks
            });
        } else {
            newLinks = this.truncateLinks(this.state.manualLinks, this.state.linkLimits, this.state.plan, mode);
            this.setState({
                links: newLinks.links,
                hiddenLinks: newLinks.hiddenLinks
            });
        }

        // save new mode
        const payload = {
            displayMode: mode,
            links: (mode === 'manual') ? this.state.manualLinks : this.state.automaticLinks,
            switched: true
        };
        console.log(payload);

        this.props.updateSmartLink(this.props.authData.username, payload)
            .then((result) => {
                this.setState({
                    linkSelectionMode: mode,
                    linksLoading: false
                });

                if (mode === 'automatic' && result.value && result.value.data && result.value.data.updateSmartLink && result.value.data.updateSmartLink.automaticLinks) {
                    newLinks = this.truncateLinks(result.value.data.updateSmartLink.automaticLinks, this.state.linkLimits, this.state.plan, mode);
                    this.setState({
                        links: newLinks.links,
                        hiddenLinks: newLinks.hiddenLinks
                    });
                }
            })
            .catch((error) => console.error(error));
    }

    onSaveURL = (custom_id) => {
        this.setState({
            customId: custom_id,
            currentHandle: custom_id,
            toastVerbiage: 'Saved'
        });
    }

    // ===PLANS=== //

    downgrade = () => {
        return this.props.cancelSmartlinkSubscription(this.props.authData.username)
            .then((cancelResponse) => {
                if (!cancelResponse
                || !cancelResponse.value
                || !cancelResponse.value.data
                || !cancelResponse.value.data.cancelSmartlinkSubscription
                || !cancelResponse.value.data.cancelSmartlinkSubscription.status
                || cancelResponse.value.data.cancelSmartlinkSubscription.status !== 'canceled') {
                    this.setState({ toastVerbiage: 'Oops, there was a problem cancelling your subscription. Please try again.' });
                    return false;
                }

                // set state values to what they would be in the free version
                if (this.state.themeData.theme === 'custom') {
                    this.setState({
                        themeData: {
                            ...this.state.themeData,
                            ...themes[this.defaultTheme],
                            theme: this.defaultTheme
                        }
                    });
                }

                const truncatedLinks = this.truncateLinks(this.state.manualLinks, this.state.linkLimits, 'free', this.state.linkSelectionMode);
                const truncatedOffers = this.truncateLinks(this.state.automaticLinks, this.state.linkLimits, 'free', this.state.linkSelectionMode);

                this.setState({
                    toastVerbiage: 'Downgraded successfully',
                    links: (this.state.linkSelectionMode === 'manual') ? truncatedLinks.links : truncatedOffers.links,
                    hiddenLinks: (this.state.linkSelectionMode === 'manual') ? truncatedLinks.hiddenLinks : truncatedOffers.hiddenLinks,
                    currentHandle: this.state.uniqueId,
                    customId: '',
                    headline: this.state.fullName,
                    plan: 'free',
                    customThemePreview: { active: false }
                });
                return true;
            });
    }

    // split links and offers into current and hidden if on free plan
    // eslint-disable-next-line class-methods-use-this
    truncateLinks(links, limits, plan, displayMode) {
        return {
            links,
            hidden: []
        };

        /* if (plan === 'pro') {
            return {
                links: links.slice(0, limits.pro[displayMode]), // slice just in case the limits decrease in the future
                hidden: []
            };
        }

        const hiddenLinks = links.slice(limits.free[displayMode]);
        // fill with example data for user to see
        const fillAmount = limits.pro[displayMode] - links.length - hiddenLinks.length;
        const exampleTitle = (displayMode === 'manual') ? 'Example Link' : 'Example Offer';
        for (let i = 0; i < fillAmount; i++) {
            hiddenLinks.push({
                id: uuidv4(),
                title: exampleTitle,
                url: 'https://example.com'
            });
        }

        return {
            links: links.slice(0, limits.free[displayMode]),
            hiddenLinks
        }; */
    }

    renderTab() {
        switch (this.state.currentTab) {
            case tabNames.LINKS:
                return (
                    <SmartLinkOffers
                        linkSelectionMode={this.state.linkSelectionMode}
                        links={this.state.links}
                        manualLinks={this.state.manualLinks}
                        hiddenLinks={this.state.hiddenLinks}
                        linksLoading={this.state.linksLoading}
                        lastUpdated={this.state.lastUpdated}
                        saving={this.state.saving}
                        plan={this.state.plan}
                        switchMode={this.switchMode}
                        updateLinks={this.updateLinks}
                        linkLimits={this.state.linkLimits}
                        goToPlans={this.goToPlans}
                        history={this.props.history}
                    />
                );
            case tabNames.ANALYTICS:
                // coming soon
                return (
                    <SmartLinkAnalytics
                        plan={this.state.plan}
                        goToPlans={this.goToPlans}
                        links={this.state.links}
                        linksLoading={this.state.linksLoading}
                    />
                );
            case tabNames.CUSTOMIZE:
                console.log(this.props);
                return (
                    <SmartLinkSettings
                        plan={this.state.plan}
                        goToPlans={this.goToPlans}
                        linksLoading={this.state.linksLoading}
                        integrationsError={this.props.smartlinks && this.props.smartlinks.mailingList && this.props.smartlinks.mailingList.recentError}
                    />
                );
            case tabNames.PLANS:
                let extraLinks = 0;
                if (this.state.plan === 'pro' && this.state.linkSelectionMode === 'manual') {
                    extraLinks = this.state.manualLinks.length - this.state.linkLimits.free.manual;
                }
                return (
                    <SmartLinkPlans
                        plan={this.state.plan}
                        expiresAt={this.state.expiresAt}
                        downgrade={this.downgrade}
                        linksLoading={this.state.linksLoading}
                        history={this.props.history}
                        numExtraLinks={extraLinks}
                        freeLinkCount={this.state.linkLimits.free.manual}
                        proLinkCount={this.state.linkLimits.pro.manual}
                        theme={this.state.themeData.theme}
                    />
                );
            case tabNames.BILLING:
                return (
                    <SmartLinkBilling
                        plan={this.state.plan}
                        linksLoading={this.state.linksLoading}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const toastMarkup = this.state.toastVerbiage
            ? <Toast content={this.state.toastVerbiage} duration="3000" onDismiss={() => { this.setState({ toastVerbiage: null }); }} />
            : null;

        let proTabText = 'Current Plan';
        let proBadge = '';
        if (!this.state.linksLoading && this.state.plan === 'free') {
            proTabText = 'Upgrade to PRO';
            proBadge = <span className="pro-feature" aria-label="Smart links pro feature">pro</span>;
        }

        const tabs = [
            {
                id: 'smartlinks',
                content: 'Links',
                accessibilityLabel: 'Manage Links',
                panelID: 'links-content'
            },
            {
                id: 'smartlink-analytics',
                content: <>Analytics {proBadge}</>,
                accessibilityLabel: 'View Analytics',
                panelID: 'analytics-content'
            },
            {
                id: 'smartlink-settings',
                content: 'Customize',
                accessibilityLabel: 'Customize Your Page',
                panelID: 'settings-content'
            },
            {
                id: 'smartlink-plans',
                content: proTabText,
                accessibilityLabel: proTabText,
                panelID: 'plans-content'
            },
            {
                id: 'smartlink-billing',
                content: 'Billing',
                accessibilityLabel: 'Manage Your Billing Information',
                panelID: 'billing-content'
            }
        ];

        return (
            <>
                <div className="smartlinks">
                    <Tabs tabs={tabs} selected={this.state.currentTab} onSelect={this.handleTabChange}>
                        {/* tab content */}
                        <div className="tab-content">
                            {(this.state.currentTab !== tabNames.PLANS && this.state.currentTab !== tabNames.BILLING && !this.props.smartlinks.firstInstall)
                                && (
                                    <SmartLinksURLActions
                                        currentHandle={this.state.currentHandle}
                                        loading={this.state.linksLoading}
                                        plan={this.state.plan}
                                        useModalPreview={this.state.miniSidebar}
                                        goToPlans={this.goToPlans}
                                        setToastVerbiage={this.setToastVerbiage}
                                        togglePreview={this.togglePreview}
                                        onSaved={this.onSaveURL}
                                    />
                                )
                            }

                            {this.renderTab()}
                        </div>

                        {/* preview sidebar */}
                        {// show if not on plans or billing tab, or on plans tab with page width > 1330
                            (
                                (this.state.currentTab !== tabNames.PLANS && this.state.currentTab !== tabNames.BILLING)
                                || (this.state.pageWidth > 1330 && this.state.currentTab === tabNames.PLANS)
                            )
                            && (
                                <aside className="smartlink-preview">
                                    <div className="inner-content">
                                        {!this.state.miniSidebar
                                            && <p className="smartlinks-sidebar--heading">Live Preview</p>
                                        }
                                        <SmartLinkMobile
                                            linksLoading={this.state.linksLoading}
                                            links={this.state.links}
                                            currentTab={this.state.currentTab}
                                            // themeData={this.state.themeData}
                                            // headline={this.state.headline}
                                            plan={this.state.plan}
                                            // socialProfiles={this.state.socialProfiles}
                                            // customThemePreview={this.state.customThemePreview}
                                            visible={this.state.previewVisible}
                                            togglePreview={this.togglePreview}
                                            affiliateId={this.props.affiliate.tracking_id}
                                        />
                                    </div>
                                </aside>
                            )
                        }
                    </Tabs>
                </div>
                <Frame>
                    {toastMarkup}
                </Frame>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        affiliate: state.affiliate
    };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        changeHeaderTitle: (title) => { return dispatch(changeHeaderTitle(title)); },
        getAffliateApprovedOffers: (id, filters) => { return dispatch(getAffliateApprovedOffers(id, filters)); },
        getSmartLinks: (id, filter) => { return dispatch(getSmartLinks(id, filter)); },
        updateSmartLink: (id, data) => { return dispatch(updateSmartLink(id, data)); },
        updateCustomization: (id, customization) => { return dispatch(updateCustomization(id, customization)); },
        updateSocialProfile: (id, network, handle) => { return dispatch(updateSocialProfile(id, network, handle)); },
        checkSmartLinkCustomId: (id, customId) => { return dispatch(checkSmartLinkCustomId(id, customId)); },
        cancelSmartlinkSubscription: (cognitoId) => { return dispatch(cancelSmartlinkSubscription(cognitoId)); }
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLink);
