/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import {sendGraphQL} from './sendRequest'
import { UPDATE_AFFILIATE } from './types';

export function unlinkFacebookSubscription(subscriptionData, id) {
    const query = `mutation revokePermission($id: String, $data: [String]){ 
        revokePermission(id: $id, data: $data){ 
          facebook_id 
          cognito_id 
        } 
      }`;

    const variables = {
        id: id,
        data: subscriptionData.scope
    };

    let payload = {
        query,
        variables
    };

    return {
        type: UPDATE_AFFILIATE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    };

    return {
        type: UPDATE_AFFILIATE,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}