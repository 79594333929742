import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Banner } from '@shopify/polaris';
import _ from 'lodash';
import { LlamaButton } from 'llama-library/components';
import llamaHead from '../assets/llama-head-01.png';
import checkIcon from '../assets/check-pink.svg';

import { changeHeaderTitle } from '../store/actions/header';
import HypeUpsell from '../components/HypeUpsell/hype-upsell';
import HypeAuditor from '../components/HypeAuditor/hype-auditor';
import HypeLocked from '../components/HypeLocked/hype-locked';
import HypeLoading from '../components/HypeLoading/hype-loading';
import { getInstagramV2Data, initiateInstagramV2Trial, upgradeInstagramV2, cancelInstagramUserV2, verifyInstagramUser } from '../store/actions/instagram-v2';
import {
    HAPaymentModal,
    HASettingsModal,
    HACancelModal,
    VerifyAccountModal
} from '../components/HypeModal/hype-modal';

import './styles/hype-auditor.css';

// Social Platform
const socialPlatform = 'instagram';

export const HypeAuditorContainer = (props) => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [currentModal, setCurrentModal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerifyUserClicked, setIsVerifyUserClicked] = useState(false);
    const [isAddUserClicked, setIsAddUserClicked] = useState(false);
    const [nextUserAccount, setNextUserAccount] = useState(false);
    const [banner, setBanner] = useState(null);

    // Update InstagramV2 data on ComponentDidMount
    // Doesn't get called again unless the value cognito_id changes
    useEffect(() => {
        // setIsLoading(true);

        if (!props.affiliate.cognito_id) {
            return;
        }

        props.getInstagramV2Data(props.affiliate.cognito_id)
            .then((result) => {
                if (Array.isArray(props.affiliate.instagramDataV2)
                    && props.affiliate.instagramDataV2.length > 0) {
                    setSelectedAccount(props.affiliate.instagramDataV2[0].username);
                    setIsLoading(false);
                } else if (props.affiliate.instagramDataV2.length === 0) {
                    setIsLoading(false);
                }

            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, [props.affiliate.cognito_id]);

    // Update header title.
    useEffect(() => {
        const title = props.title ? props.title : 'InstaAnalytics';
        props.changeHeaderTitle(title);
    }, []);

    // Loading State
    if (!props.affiliate || !props.affiliate.cognito_id
        || !props.affiliate.instagramDataV2 || isLoading) {
        return (
            <div data-test="component-hypeLoading">
                <HypeLoading />
            </div>
        );
    }

    const handleAccountChange = (value) => {
        setSelectedAccount(value);
    };

    const handleVerifyAccount = () => {
        setIsVerifyUserClicked(true);
        toggleModal('verify');
    };

    const handleAddAccount = () => {
        setIsAddUserClicked(true);
    };

    const toggleModal = (value, username) => {

        if (_.isPlainObject(value)) {
            setBanner(value.message);
            setCurrentModal(null);
        }

        if (!value) {
            setCurrentModal(null);
        }

        if (username) {
            setSelectedAccount(username);
        }

        // Handle state if modals are manually closed
        if (isAddUserClicked && currentModal === 'upgrade') {
            setIsAddUserClicked(false);
        }
        if (isVerifyUserClicked && currentModal === 'verify') {
            setIsVerifyUserClicked(false);
        }

        setCurrentModal(value);
    };

    const now = new Date();

    // Determine if user is in the signup flow
    const isOnboarding = props.location.pathname.includes('signUp');

    // Get ambassador's instagram accounts
    const instagramAccounts = Array.isArray(props.affiliate.instagramDataV2)
        ? props.affiliate.instagramDataV2.filter((item) => {
            return item.account_type === 'ACTIVE' || item.account_type === 'TRIAL'
        })
        : [];

    // Determine there is an array of at least one instagram account for the ambassador
    const hasActiveInstagram = instagramAccounts && Array.isArray(instagramAccounts)
        && instagramAccounts.length > 0;

    // Get ambassador's instagram accounts
    const instagramAccountsInactive = Array.isArray(props.affiliate.instagramDataV2)
        ? props.affiliate.instagramDataV2.filter((item) => {
            return item.account_type === 'INACTIVE';
        })
        : [];

    // Determine there is an array of at least one instagram account for the ambassador
    const hasInactiveInstagram = instagramAccountsInactive && Array.isArray(instagramAccountsInactive)
        && instagramAccountsInactive.length > 0;

    // Creating new affiliate object with the existing props
    // Setting instagramDataV2 prop to instagramAccounts array from previous step.
    const updatedAffiliate = { ...props.affiliate, instagramDataV2: instagramAccounts };

    // Searching instagramAccounts array for the username that matches selectedAccount value
    const activeAccount = instagramAccounts && instagramAccounts.find((item) => item.username === selectedAccount);

    // Determine if the activeAccount account_type is 'TRIAL'
    const isTrial = activeAccount && activeAccount.account_type === 'TRIAL';

    // Get trial_expiration from activeAccount and format the date
    const expyDate = activeAccount && activeAccount.trial_expiration;
    const formattedExpy = new Date(expyDate);

    // Determine if activeAccount is expired
    const isExpired = activeAccount && activeAccount.account_type === 'TRIAL' && (formattedExpy <= now);

    const handleModalClose = res => {
        if (res && res.value && res.value.data && res.value.data.createInstagramUserV2Trial && res.value.data.createInstagramUserV2Trial.error
            && res.value.data.createInstagramUserV2Trial.error.message.length > 0) {
            return toggleModal({ error: true, message: res.value.data.createInstagramUserV2Trial.error.message });
        }

        return toggleModal(null);
    }

    const renderTrialBanner = () => {

        // TRIAL BANNER
        if (!isLoading && isTrial && expyDate && !isExpired) {
            return (
                <div className="HypeAuditor__TrialBanner">
                    <Banner
                        title="You are currently on a trial membership."
                    >
                        <p className="HypeAuditor__TrialParagraph">
                            Always show prospective clients that your audience is organic and engaged. Get more collaborations with top brands.
                        </p>
                        <LlamaButton onClick={() => toggleModal('upgrade')}>Upgrade Now</LlamaButton>
                    </Banner>
                </div>
            );
        }

        // EXPIRED TRIAL BANNER
        if (!isLoading && isTrial && expyDate && isExpired) {
            return (
                <div className="HypeAuditor__TrialBanner">
                    <Banner
                        title="Your trial has expired."
                    >
                        <p className="HypeAuditor__TrialParagraph">
                            You must upgrade your account to access your HypeAuditor profile.
                        </p>
                        <LlamaButton onClick={() => toggleModal('upgrade')}>Upgrade Now</LlamaButton>
                    </Banner>
                </div>
            );
        }

        return null;
    };

    const renderHypeAuditorTrialSuccess = () => {
        return (
            <div className="HypeUpsell__Success">
                <img src={llamaHead} className="HypeUpsell__SuccessLlama" />
                <div className="HypeUpsell__SuccessRow">
                    <p className="HypeUpsell__SuccessMessage">Success! Your free trial has been created</p>
                    <img src={checkIcon} className="HypeUpsell__SuccessCheck" />
                </div>
            </div>
        );
    };

    const renderHypeAuditor = () => {
        return (
            <div data-test="component-hypeAccount">
                <div className="hype-account__add-account">
                    <p className="hype-account__add-text">
                        Analyze your competition
                    </p>
                    <LlamaButton loading={isAddUserClicked} onClick={handleAddAccount}>Get Another Report</LlamaButton>
                </div>
                <HypeAuditor
                    {...props}
                    affiliate={updatedAffiliate}
                    isVerifyUserClicked={isVerifyUserClicked}
                    handleVerifyAccount={handleVerifyAccount}
                    handleAccountChange={handleAccountChange}
                    viewSettings={() => toggleModal('settings')}
                />
            </div>
        );
    };

    return (
        <div data-test="component-hypeContainer">
            {/* Error Banner */}
            {
                banner && banner.length > 0 && (
                    <Banner title="Transaction Terminated" onDismiss={() => { setBanner(null) }} status="critical">
                        <p>{banner}</p>
                    </Banner>
                )
            }
            {/* RENDER BANNER */}
            {renderTrialBanner()}

            {/* EXPIRED TRIAL STATE */}
            {!isLoading && hasActiveInstagram && isExpired && isTrial && (
                <div data-test="component-hypeExpired">
                    <HypeLocked
                        {...props}
                        hasActiveInstagram={hasActiveInstagram}
                        isExpired={isExpired}
                    />
                </div>
            )}

            {/* TRIAL STATE */}
            {isOnboarding && hasActiveInstagram && renderHypeAuditorTrialSuccess()}

            {/* SHOW HYPE AUDITOR DATA */}
            {!isLoading && hasActiveInstagram && !isExpired && !isAddUserClicked && renderHypeAuditor()}

            {/* UPSELL STATE */}
            {
                !isLoading
                && (isAddUserClicked || (!hasActiveInstagram && !isTrial))
                && (
                    <div data-test="component-hypeUpsell">
                        <HypeUpsell
                            {...props}
                            addUser={isAddUserClicked}
                            toggleUpgrade={(username) => toggleModal('upgrade', username)}
                            cognito_id={props.affiliate && props.affiliate.cognito_id}
                            hasInactiveInstagram={hasInactiveInstagram}
                            setIsAddUserClicked={setIsAddUserClicked}
                            setSelectedAccount={setSelectedAccount}
                            setNextUserAccount={setNextUserAccount}
                            initiateInstagramV2Trial={props.initiateInstagramV2Trial}
                        />
                    </div>
                )
            }

            {/* UPGRADE STATE */}
            {currentModal === 'upgrade' && (
                <HAPaymentModal
                    socialPlatform={socialPlatform}
                    close={handleModalClose}
                    username={(isAddUserClicked) ? nextUserAccount : selectedAccount}
                    cognito_id={props.affiliate && props.affiliate.cognito_id}
                    paymentHandler={(isAddUserClicked) ? props.initiateInstagramV2Trial : props.upgradeInstagramV2}
                />
            )}

            {/* SETTINGS STATE */}
            {currentModal === 'settings' && (
                <HASettingsModal
                    socialPlatform={socialPlatform}
                    close={() => toggleModal(null)}
                    data={props.affiliate.instagramDataV2}
                    toggleUpgrade={(username) => toggleModal('upgrade', username)}
                    toggleCancel={(username) => toggleModal('cancel', username)}
                />
            )}

            {/* CANCEL STATE */}
            {currentModal === 'cancel' && (
                <HACancelModal
                    socialPlatform={socialPlatform}
                    close={() => toggleModal(null)}
                    username={selectedAccount}
                    cognito_id={props.affiliate && props.affiliate.cognito_id}
                    cancelUserAccount={props.cancelInstagramUserV2}
                />
            )}

            {/* VERIFY ACCOUNT STATE */}
            {currentModal === 'verify' && (
                <VerifyAccountModal
                    socialPlatform={socialPlatform}
                    close={() => toggleModal(null)}
                    username={selectedAccount}
                    cognito_id={props.affiliate && props.affiliate.cognito_id}
                    hashCode={props.affiliate && props.affiliate.instagramDataV2[0]
                        && props.affiliate.instagramDataV2[0].account_hash}
                    verifyUserAccount={props.verifyInstagramUser}
                    setIsVerifyUserClicked={setIsVerifyUserClicked}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { affiliate: state.affiliate };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInstagramV2Data: (cognito_id) => dispatch(getInstagramV2Data(cognito_id)),
        initiateInstagramV2Trial: (username, cognito_id, stripe_token, interval) => dispatch(initiateInstagramV2Trial(username, cognito_id, stripe_token, interval)),
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
        upgradeInstagramV2: (username, cognito_id, stripe_token, interval) => dispatch(upgradeInstagramV2(username, cognito_id, stripe_token, interval)),
        cancelInstagramUserV2: (username, cognito_id, reason) => dispatch(cancelInstagramUserV2(username, cognito_id, reason)),
        verifyInstagramUser: (username, cognito_id) => dispatch(verifyInstagramUser(username, cognito_id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HypeAuditorContainer);
