/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 * */

const awsConfig = {
    aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    storage: sessionStorage
};
if (!window.location.href.includes('/social/callback')) {
    awsConfig.oauth = {
        domain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
        scope: [
            'phone',
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNIN,
        responseType: 'code',
        clientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
    };
}

export default awsConfig;
