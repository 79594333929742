import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Spinner, Modal, Banner } from '@shopify/polaris';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import moment from 'moment';

import { EmptyState, LlamaButton } from 'llama-library/components';

import { getAffliateApprovedOffers } from '../../store/actions/action_getApprovedOffers';
import getSmartlinkAutomaticOffers from '../../utils/get-smartlink-automatic-offers';

import SmartLinkOfferForm from './offers/smart-link-offer-form';
import SmartLinksOnboarding from './onboarding/smart-link-onboarding-container';

import './smart-link-offers.css';

/*
 * Tab content for managing offers and links
*/
const SmartLinkOffers = (props) => {
    console.log('OFFER PROPS', props);
    // open statuses for add, edit, and remove modals
    const [modals, setModals] = useState({
        remove: false,
        add: false,
        edit: false
    });

    const [linkToRemove, setLinkToRemove] = useState('');
    const [currentLinkId, setCurrentLinkId] = useState('');
    const [saving, setSaving] = useState(false);
    const [sortSaving, setSortSaving] = useState(false);

    // if in manual mode, allow list to be sortable
    const sortable = (props.linkSelectionMode === 'manual');
    const [offers, setOffers] = useState([]);

    const submitRef = useRef(null);

    useEffect(() => {
        getSmartlinkAutomaticOffers(props.getAffliateApprovedOffers, props.affiliate.cognito_id, props.affiliate.tracking_id)
            .then((result) => {
                console.log(result);
                setOffers(result);
            });
    }, []);

    // open or close modal
    const handleModal = (type) => {
        switch (type) {
            case 'remove':
                // reset states on close
                if (modals.remove === true) {
                    setLinkToRemove('');
                }
                setModals({ remove: !modals.remove, add: false, edit: false });
                break;
            case 'add':
                setModals({ remove: false, add: !modals.add, edit: false });
                break;
            case 'edit':
                setCurrentLinkId('');
                setModals({ remove: false, add: false, edit: !modals.edit });
                break;
            default:
                break;
        }
    };

    // open remove modal
    const deleteConfirm = (linkId) => {
        setLinkToRemove(linkId);
        handleModal('remove');
    };

    // open edit modal
    const edit = (linkId) => {
        setCurrentLinkId(linkId);
        handleModal('edit');
    };

    // open add modal
    const add = () => {
        handleModal('add');
    };

    const goToAmbassadorLp = () => {
        props.history.push('/become-an-ambassador');
    };

    const removeLink = (linkId) => {
        setSaving(true);
        const newLinkList = props.links.slice();
        const allManualLinks = props.manualLinks.slice();

        const linkIndex = newLinkList.findIndex((link) => {
            return link.id === linkId;
        });
        newLinkList.splice(linkIndex, 1);
        const manualLinkIndex = allManualLinks.findIndex((link) => {
            return link.id === linkId;
        });
        allManualLinks.splice(manualLinkIndex, 1);

        // save and close modal
        props.updateLinks(newLinkList, allManualLinks).then(() => {
            setSaving(false);
            handleModal('remove');
        });
    };

    const onSubmitted = (action) => {
        handleModal(action);
    };

    // sorting for manual links
    const handleLinkSort = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) {
            return;
        }

        setSortSaving(true);
        const sortedLinks = arrayMove(props.links, oldIndex, newIndex);
        const sortedManualLinks = arrayMove(props.manualLinks, oldIndex, newIndex);
        props.updateLinks(sortedLinks, sortedManualLinks).then(() => {
            setSortSaving(false);
        });
    };

    const LinkData = SortableElement((link) => {
        return (
            <li className={sortable ? 'smartlinks--sortable-link' : ''} data-editing={currentLinkId === link.value.id} data-removing={linkToRemove === link.value.id}>
                <span className="offer-name"><Link to={`/offer/${link.value.offerId}`}>{link.value.offerName}</Link></span>
                <span className="title">{link.value.company && `${link.value.company}: `}{link.value.title}</span>
                <span className="url">{link.value.url}</span>
                {props.linkSelectionMode === 'manual'
                    && (
                        <span className="actions">
                            <button type="button" className="edit" onClick={() => { return edit(link.value.id); }}>Edit</button>
                            <button type="button" className="remove" onClick={() => { return deleteConfirm(link.value.id); }}>Remove</button>
                        </span>
                    )}
            </li>
        );
    });
    const LinkList = SortableContainer((links) => {
        return (
            <ol className="smartlinks-links" data-is-sortable={sortable} data-loading={sortSaving}>
                {links.items.map((link, index) => {
                    return (
                        <LinkData key={link.id} index={index} value={link} disabled={!sortable} />
                    );
                })}
                {sortSaving
                    && <li className="loading"><Spinner /></li>}
            </ol>
        );
    });

    // add/edit modal
    const renderLinkFormModal = (title, action) => {
        return (
            <Modal
                title={title}
                open={modals[action]}
                onClose={() => { return handleModal(action); }}
                primaryAction={{
                    content: 'Save',
                    onAction: () => {
                        submitRef.current();
                    },
                    loading: saving
                }}
            >
                <Modal.Section>
                    <SmartLinkOfferForm
                        affiliate={props.affiliate}
                        links={props.links}
                        manualLinks={props.manualLinks}
                        updateLinks={props.updateLinks}
                        onLinkSubmit={onSubmitted}
                        setSaving={setSaving}
                        offers={offers}
                        action={action}
                        currentLinkId={currentLinkId}
                        submitRef={submitRef}
                    />
                </Modal.Section>
            </Modal>
        );
    };

    /* let upgradeTextFragment = '';
    if (props.plan === 'free') {
        upgradeTextFragment = (props.linkSelectionMode === 'automatic')
            ? `Want to display more offers here? Upgrade to SmartLinks PRO to display up to ${props.linkLimits.pro.automatic} offers`
            : `Want to add more links? Upgrade to SmartLinks PRO to add up to ${props.linkLimits.pro.manual} links`;
    } */

    const renderHeadingAndIntro = () => {
        if (props.affiliate.account_type === 'smartlinks') {
            return (
                <>
                    <p className="heading">Your Links</p>
                    <p className="info">Add unlimited links for your brand deals, videos, blogs, podcasts, playlists, or anything else you want your audience to find.</p>
                </>
            );
        }

        return (
            <>
                <p className="heading">{props.linkSelectionMode} Mode</p>
                {props.linkSelectionMode === 'automatic'
                    ? <p className="info">In automatic mode, your SmartLinks page will <strong>display the most popular products of your top performing offers and keep them sorted automatically</strong> with the best ones at the top. To choose your own offers, change the order of them, or add links from brands outside of Llama, <button type="button" className="switch-mode" onClick={() => { return props.switchMode('manual'); }}>switch to manual mode</button>.</p>
                    : <p className="info">In manual mode, <strong>you control which offers appear</strong> on your SmartLinks page and in what order. You can even <strong>add your own links</strong> from other referral programs outside of Llama. To display your best performing Llama offers automatically, <button type="button" className="switch-mode" onClick={() => { return props.switchMode('automatic'); }}>switch to automatic mode</button>.</p>
                }
            </>
        );
    };

    console.log(props);

    if (props.linksLoading) {
        return <Spinner />;
    }

    // if they don't have a name, show onboarding
    if (!props.affiliate.name || props.smartlinks.firstInstall) {
        return (
            <SmartLinksOnboarding
                affiliate={props.affiliate}
                links={props.links}
                manualLinks={props.manualLinks}
                updateLinks={props.updateLinks}
                offers={offers}
            />
        );
    }

    // content
    return (
        <>
            {/* delete confirmation modal */}
            <Modal
                title="Remove Link"
                open={modals.remove}
                onClose={() => { return handleModal('remove'); }}
                primaryAction={{
                    content: 'Confirm Delete',
                    onAction: () => { return removeLink(linkToRemove); },
                    loading: saving
                }}
            >
                <Modal.Section>
                    <p>Are you sure you want to remove this link from your SmartLinks page? This action cannot be undone.</p>
                </Modal.Section>
            </Modal>

            {renderLinkFormModal('Add Link', 'add')}
            {renderLinkFormModal('Edit Link', 'edit')}

            {/* heading and intro text */}
            {renderHeadingAndIntro()}

            {/* link list */}
            {props.links
                ? (
                    <>
                        <LinkList items={props.links} onSortEnd={handleLinkSort} />
                        {props.linkSelectionMode === 'manual' /* && props.links.length < props.linkLimits[props.plan][props.linkSelectionMode] */
                            && <p className="smartlinks--add-link"><LlamaButton onClick={add}>Add Link</LlamaButton></p>
                        }
                        {props.linkSelectionMode === 'automatic' && props.lastUpdated
                            && <p className="smartlinks--last-updated">Last Updated: {moment(props.lastUpdated).fromNow()}</p>
                        }

                        {props.affiliate.account_type === 'smartlinks' && props.links.length > 0
                            && (
                                <div className="smartlinks--account-switch-banner">
                                    <Banner status="info" title="Make your SmartLinks page smarter" action={{ content: 'Become an Ambassador', onAction: goToAmbassadorLp }}>
                                        <p>Discover and apply to the <strong>most relevant brand deals</strong> for your audience <em>and</em> keep your <strong>best performing deals at the top of your page</strong>. Just upgrade your SmartLinks account to an <strong>always free Llama Ambassador</strong> account and get the most out of your referral links.</p>
                                    </Banner>
                                </div>
                            )
                        }

                        {/* show free users the links they could have if they upgrade to pro */}
                        {/* {props.plan === 'free' && props.links.length >= props.linkLimits[props.plan][props.linkSelectionMode]
                            && (
                                <div className="smartlinks--upgrade-notice">
                                    <Banner status="warning" title={`${upgradeTextFragment}, plus get access to stats, customize your color theme, and more.`} action={{ content: 'Upgrade to PRO', onAction: props.goToPlans }} />

                                    {props.hiddenLinks && props.hiddenLinks.length > 0
                                        && (
                                            <ol className="smartlinks-links smartlinks--hidden-content" data-is-sortable={props.linkSelectionMode === 'manual'}>
                                                {props.hiddenLinks.map((link) => {
                                                    return (
                                                        <li key={link.id}>
                                                            <span className="title">{link.company && `${link.company}: `}{link.title}</span>
                                                            <span className="url">{link.url}</span>
                                                        </li>
                                                    );
                                                })}
                                            </ol>
                                        )
                                    }
                                </div>
                            )
                        } */}
                    </>
                )
                : (
                    <EmptyState
                        message={'You haven\'t created any SmartLinks yet'}
                        ctaText="Add Link"
                        ctaAction={() => { return props.history.push('/discover'); }}
                    />
                )}

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        affiliate: state.affiliate
    };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        getAffliateApprovedOffers: (id, filters) => { return dispatch(getAffliateApprovedOffers(id, filters)); }
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLinkOffers);
