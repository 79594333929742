import { useState, useEffect, useRef } from 'react';

/**
 * @function useApplications - Hook that grabs the Affiliate's application data
 * @param {String} cognito_id - Affiliate identifier
 * @param {Function} getApplications - API Call
 * @returns {Object} - { isLoadingApplications: Boolean, applicationsLoaded: Boolen }
 */
const useApplications = (cognito_id, getApplications) => {
    const isCancelled = useRef(false);
    const [isLoadingApplications, setIsLoading] = useState(false);
    const [applicationsLoaded, setApplicationsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoadingApplications && cognito_id) {
            setIsLoading(true);
            getApplications(cognito_id)
                .then(() => {
                    if (!isCancelled.current) {
                        setIsLoading(false);
                        setApplicationsLoaded(true);
                    }
                })
                .catch(() => setIsLoading(false));
        }
    }, [cognito_id]);

    // prevent unmounted component error during onboarding redirect
    useEffect(() => {
        return () => { isCancelled.current = true; };
    }, []);

    return { isLoadingApplications, applicationsLoaded };
};

export default useApplications;
