import { v4 as uuidv4 } from 'uuid';
import { generateTrackingLink } from '../store/actions/generate-link';
import { addTrackingLink } from '../store/actions/add-tracking-link';

const formatOffers = async (offers, trackingId) => {
    return Promise.all(
        offers.map(async (offer) => {
            let trackingLink = '';
            if (offer.application.tracking_link) {
                trackingLink = offer.application.tracking_link;
            } else {
                // if a tracking link was never created, generate it and save to application
                if (offer.offer.offer_id.includes('LLAMA')) {
                    trackingLink = offer.offer.offer_url.replace('{affiliate_id}', trackingId).replace('{offer_id}', offer.offer.offer_id);
                } else {
                    trackingLink = await generateTrackingLink(offer.offer.offer_id, trackingId)
                        .then((result) => {
                            if (result.generateTrackingLink) {
                                return result.generateTrackingLink.click_url;
                            }
                            return '';
                        })
                        .catch(() => {
                            return '';
                        });
                }
                await addTrackingLink(offer.application.application_id, trackingLink);
            }

            // default title is the offer category
            let title = `${offer.offer.category} Products`;

            // if llama offer, use a call to action
            if (offer.offer.offer_id === 'LLAMA_001') {
                title = 'Become a Llama Ambassador and Earn More Often';
            }

            return {
                id: uuidv4(),
                title,
                offerName: offer.offer.name,
                url: trackingLink,
                offerId: offer.offer.offer_id,
                company: offer.advertiser.company
            };
        })
    );
};

const getSmartlinkAutomaticOffers = (getAffliateApprovedOffers, cognitoId, trackingId) => {
    const filters = {
        advertiser_status: 'APPROVED',
        affiliate_status: 'APPROVED',
        active: true,
        interim_status: { not: 'ACTIVE' },
        offer_type: { not: 'product_giveaway' }
    };

    return getAffliateApprovedOffers(cognitoId, filters)
        .then(async (result) => {
            if (
                !result
                || !result.value
                || !result.value.data
                || !result.value.data.affiliateById
                || !Array.isArray(result.value.data.affiliateById.offers)
            ) {
                return [];
            }

            const offers = result.value.data.affiliateById.offers

            return formatOffers(offers, trackingId);
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
};

export default getSmartlinkAutomaticOffers;
