import { GET_UNREAD_CHAT_MESSAGES_FULFILLED, MARK_CHAT_MESSAGES_AS_READ_FULFILLED, ADD_MESSAGE_ID_TO_UNREAD } from '../actions/chat/action_chatMessages';

const defaultState = {
    unreadChatMessages: []
};

const chatReducer = (state = defaultState, action) => {
    const { type } = action;

    switch (type) {
        case GET_UNREAD_CHAT_MESSAGES_FULFILLED: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                unreadChatMessages: action.payload.data.getUnreadChatMessages
            };
        }

        case MARK_CHAT_MESSAGES_AS_READ_FULFILLED: {
            if (!action.payload) {
                return state;
            }

            const unreadChatMessagesCopy = JSON.parse(JSON.stringify(state.unreadChatMessages));

            const newUnreadChatMessages = unreadChatMessagesCopy.reduce((acc, message) => {
                const newMessageIds = message.message_ids.filter((id) => {
                    return !action.payload.includes(id);
                });

                if (newMessageIds.length > 0) {
                    return [
                        ...acc,
                        {
                            sender_id: message.sender_id,
                            message_ids: newMessageIds
                        }
                    ];
                }

                return acc;
            }, []);

            console.log('MARK AS READ FULFILLED', newUnreadChatMessages, action.payload);

            return {
                ...state,
                unreadChatMessages: newUnreadChatMessages
            };
        }

        case ADD_MESSAGE_ID_TO_UNREAD: {
            if (!action.payload || !action.payload.sender_id || !action.payload.message_id) {
                return state;
            }

            const newUnreadChatMessages = JSON.parse(JSON.stringify(state.unreadChatMessages));
            console.log(newUnreadChatMessages, state);
            if (newUnreadChatMessages.length > 0) {
                const senderIndex = newUnreadChatMessages.findIndex((sender) => {
                    console.log(sender, action.payload);
                    return sender.sender_id === action.payload.sender_id;
                });

                console.log(senderIndex);

                if (senderIndex !== -1) {
                    newUnreadChatMessages[senderIndex].message_ids = [
                        ...newUnreadChatMessages[senderIndex].message_ids,
                        action.payload.message_id
                    ];
                    console.log('returning early');
                    return {
                        ...state,
                        unreadChatMessages: newUnreadChatMessages
                    };
                }
            }

            newUnreadChatMessages.push({
                sender_id: action.payload.sender_id,
                message_ids: [action.payload.message_id]
            });

            console.log('returning later');

            return {
                ...state,
                unreadChatMessages: newUnreadChatMessages
            };
        }

        default: {
            return state;
        }
    }
};

export default chatReducer;
