import React, {useState} from "react";
import {Caption, DropZone, Stack, Thumbnail, Modal, Banner} from "@shopify/polaris";

const GeneralSettingsSideDrawer = ({saveFile, showSideDrawer, setShowSideDrawer, files, uploading}) => {
    const[uploadedFile, setFile] = useState(null);
    const[rejectedFiles, setRejectedFiles] = useState([]);
    const hasError = rejectedFiles.length > 0;

    const handleFileUpload = (files, acceptedFiles) => {
        //if image is not a square, crop to a square
        if(acceptedFiles[0]){
            const fileReader = new FileReader();
            fileReader.onloadend = function(){
                const tempImg = new Image();
                tempImg.src = fileReader.result;
                tempImg.onload = function(){
                    if(tempImg.width != tempImg.height){
                        let newWidth, newHeight;
                        let offsetX = 0;
                        let offsetY = 0;
                        if(tempImg.width > tempImg.height){
                            newWidth = tempImg.height;
                            newHeight = tempImg.height;
                            offsetX = (tempImg.width / 2) - (newWidth / 2)
                        }else{
                            newWidth = tempImg.width;
                            newHeight = tempImg.width;
                            offsetY = (tempImg.height / 2) - (newHeight / 2)
                        }

                        const canvas = document.createElement('canvas');
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(this, offsetX, offsetY, newWidth, newHeight, 0, 0, newWidth, newHeight);

                        canvas.toBlob((blob) => {
                            blob.name = acceptedFiles[0].name;
                            setFile(blob)
                        }, acceptedFiles[0].type, 1)
                    }else{
                        setFile(acceptedFiles[0])
                    }
                }
            }
            fileReader.readAsDataURL(acceptedFiles[0]);
        }

        setRejectedFiles(rejectedFiles);
     }

    const sideDrawerClosedHandler = () => setShowSideDrawer(null);
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
    const fileUpload = !uploadedFile && <DropZone.FileUpload actionTitle="Add Photo" actionHint="or drop image files to upload" />;

    const renderUploadedFile = uploadedFile && (
        <Stack vertical>
            <Stack alignment="center">
                <Thumbnail
                    size="large"
                    alt={uploadedFile.name}
                    source={
                        validImageTypes.indexOf(uploadedFile.type) > 0
                            ? window.URL.createObjectURL(uploadedFile)
                            : ''
                    }
                />
                <div>
                    {uploadedFile.name} <Caption>{uploadedFile.size} bytes</Caption>
                </div>
            </Stack>
        </Stack>
    );

    const fileErrorMessage = hasError && (
        <Banner title="The following images couldn't be uploaded:" status="critical">
            <ul>
                {rejectedFiles.map((file, index) => (
                    <li key={index}>
                        {`"${file.name}" is not supported. File type must be .jpg, .png, or .gif`}
                    </li>
                ))}
            </ul>
        </Banner>
    )

    return (
        <div>
            <Modal
                large
                open={showSideDrawer}
                onClose={sideDrawerClosedHandler}
                title="Upload a Photo"
                primaryAction={{
                    content: 'Upload Photo',
                    onAction: () => saveFile(uploadedFile),
                    loading: uploading,
                    disabled: !uploadedFile
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: sideDrawerClosedHandler,
                    },
                ]}
            >
                <Modal.Section>
                    <DropZone
                        onDrop={handleFileUpload} dropOnPage={false}
                        type="image"
                        accept="image/png,image/jpg,image/jpeg"
                    >
                        {renderUploadedFile}
                        {fileUpload}
                    </DropZone>
                </Modal.Section>
            </Modal>
        </div>
    )
};

export default GeneralSettingsSideDrawer;