import { sendGraphQL } from './sendRequest';

export const getDashboardData = (cognito_id) => {
    const query = `
        query getDashboard($cognito_id: String) {
            affiliateById(cognito_id: $cognito_id) {
                cognito_id
                name
                email
                tracking_id
                offerStats {
                    clicks
                    conversions
                    payout
                    epc
                }
                ltvStats {
                    advertiser_id
                    name
                    ltv
                    oneTimeCustomers
                    repeatedCustomers
                    numOffers
                }
                featuredOffers {
                    offer {
                        offer_id
                        category
                        advertiser_id
                        advertiser{
                            name
                            company
                            avatar_image
                        }
                        name
                        rate{
                            amount
                            type
                        }
                        longterm_rate {
                            type
                            amount
                        }
                        sponsored
                    }
                }
            }
        }
    `;

    const variables = {
        cognito_id
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'GET_DASHBOARD_DATA',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
    };
};

export const recordOfferBoostMetric = (metric, offer_id) => {
    const query = `
        mutation recordOfferBoostMetric($metric: OfferBoostMetric!, $offer_id: String!){
            recordOfferBoostMetric(metric: $metric, offer_id: $offer_id)
        }
    `;

    const variables = {
        metric,
        offer_id
    };

    const payload = {
        query,
        variables
    }

    return sendGraphQL(payload)
        .then((response) => { return response.data; });
};

export default getDashboardData;
