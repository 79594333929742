/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import { sendGraphQL } from './sendRequest';

const GET_INSTAGRAM_V2 = 'GET_INSTAGRAM_V2';
export const GET_INSTAGRAM_V2_PENDING = `${GET_INSTAGRAM_V2}_PENDING`;
export const GET_INSTAGRAM_V2_FULFILLED = `${GET_INSTAGRAM_V2}_FULFILLED`;
export const GET_INSTAGRAM_V2_REJECTED = `${GET_INSTAGRAM_V2}_REJECTED`;

export function getInstagramV2Data(cognito_id) {

    if (!cognito_id) return null;
    const query = `
        query affiliateById($cognito_id:String!) {
            affiliateById(cognito_id:$cognito_id) {
                cognito_id
                tracking_id
                instagramDataV2 {
                    username
                    cognito_id
                    account_type
                    account_verified
                    account_hash
                    trial_expiration
                    full_name
                    is_private
                    about
                    photo_url
                    posts_count
                    followers_count
                    followings_count
                    avg_likes
                    avg_comments
                    global_rank
                    aqs
                    aqs_name
                    aqs_description
                    er {
                        value
                        avg
                        title
                    }
                    audience_interests {
                        name
                        value
                    }
                    demography_by_age{
                        gender
                        value
                        by_age_group {
                            group
                            value
                        }
                    }
                    audience_geography {
                        countries {
                            name
                            code
                            value
                        }
                        cities {
                            name
                            value
                        }
                        states {
                            name
                            value
                        }
                    }
                    advertising_data {
                        avg_ad_er
                        avg_er
                        avg_er_display
                        ad_posts{
                        count
                        display
                    }
                    all_posts{
                        count
                        display
                    }
                    brands_mentions_count
                    brands_categories
                    brands_categories_html
                    regular_posts{
                        count
                        display
                    }
                    }
                    likes_spread {
                        value
                        avg
                        title
                    }
                    likes_comments_ratio {
                        value
                        avg
                        title
                    }
                    audience_reachability {
                        value
                        avg
                        title
                    }
                    audience_languages{
                        code
                        value
                    }
                    audience_authenticity {
                        value
                        avg
                        title
                    }
                    audience_type {
                        real
                        susp
                        infs
                        mass
                    }
                    audience_ethnicity {
                        name
                        value
                    }
                    likes_comments_ratio_chart {
                        x
                        y
                    }
                    followers_chart {
                        date
                        count
                    }
                    following_chart {
                        date
                        count
                    }
                    demography {
                        gender
                        value
                    }
                    growth {
                        title
                        description
                    }
                }
            }
        }
    `

    const variables = {
        cognito_id
    }

    let payload = {
        query,
        variables
    }

    return {
        type: GET_INSTAGRAM_V2,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: GET_INSTAGRAM_V2,
        payload: axios.post(NEXT_APP_API_URL, payload, {
                headers
            })
            .then(response => response.data)
    } */
};


const INITIATE_INSTAGRAM_V2_TRIAL = 'INITIATE_INSTAGRAM_V2_TRIAL'
export const INITIATE_INSTAGRAM_V2_TRIAL_PENDING = `${INITIATE_INSTAGRAM_V2_TRIAL}_PENDING`;
export const INITIATE_INSTAGRAM_V2_TRIAL_FULFILLED = `${INITIATE_INSTAGRAM_V2_TRIAL}_FULFILLED`;
export const INITIATE_INSTAGRAM_V2_TRIAL_REJECTED = `${INITIATE_INSTAGRAM_V2_TRIAL}_REJECTED`;

export const initiateInstagramV2Trial = (username, cognito_id, stripe_token, interval) => {

    if (!username || !cognito_id) {
        return null;
    }

    const query = `
    mutation createInstagramUserV2Trial($username: String, $cognito_id: String, $stripe_token: String, $interval: HypeAuditorAccountType) {
        createInstagramUserV2Trial(username: $username, cognito_id: $cognito_id, stripe_token: $stripe_token, interval: $interval) {
                username
                cognito_id
                account_type
                account_verified
                account_hash
                trial_expiration
                full_name
                is_private
                about
                photo_url
                posts_count
                followers_count
                followings_count
                avg_likes
                avg_comments
                global_rank
                aqs
                aqs_name
                aqs_description
                er {
                    value
                    avg
                    title
                }
                audience_interests {
                    name
                    value
                }
                demography_by_age {
                    gender
                    value
                    by_age_group {
                        group
                        value
                    }
                }
                audience_geography {
                    countries {
                        name
                        code
                        value
                    }
                    cities {
                        name
                        value
                    }
                    states {
                        name
                        value
                    }
                }
                advertising_data {
                    avg_ad_er
                    avg_er
                    avg_er_display
                    ad_posts {
                        count
                        display
                    }
                    all_posts {
                        count
                        display
                    }
                    brands_mentions_count
                    brands_categories
                    brands_categories_html
                    regular_posts {
                        count
                        display
                    }
                }
                likes_spread {
                    value
                    avg
                    title
                }
                likes_comments_ratio {
                    value
                    avg
                    title
                }
                audience_reachability {
                    value
                    avg
                    title
                }
                audience_languages {
                    code
                    value
                }
                audience_authenticity {
                    value
                    avg
                    title
                }
                audience_type {
                    real
                    susp
                    infs
                    mass
                }
                audience_ethnicity {
                    name
                    value
                }
                likes_comments_ratio_chart {
                    x
                    y
                }
                followers_chart {
                    date
                    count
                }
                following_chart {
                    date
                    count
                }
                demography {
                    gender
                    value
                }
                error {
                    message
                }
                growth {
                    title
                    description
                }
            }
        }
    `

    const variables = {
        username,
        cognito_id,
        stripe_token,
        interval
    }

    let payload = {
        query,
        variables
    }

    return {
        type: INITIATE_INSTAGRAM_V2_TRIAL,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: INITIATE_INSTAGRAM_V2_TRIAL,
        payload: axios.post(NEXT_APP_API_URL, payload, {
                headers
            })
            .then(response => response.data)
    } */
}


const UPGRADE_INSTAGRAM_V2 = 'UPGRADE_INSTAGRAM_V2'
export const UPGRADE_INSTAGRAM_V2_PENDING = `${UPGRADE_INSTAGRAM_V2}_PENDING`;
export const UPGRADE_INSTAGRAM_V2_FULFILLED = `${UPGRADE_INSTAGRAM_V2}_FULFILLED`;
export const UPGRADE_INSTAGRAM_V2_REJECTED = `${UPGRADE_INSTAGRAM_V2}_REJECTED`;

export const upgradeInstagramV2 = (username, cognito_id, stripe_token, interval) => {

    console.log(username, cognito_id, stripe_token, interval);
    if (!username || !cognito_id || !stripe_token || !interval) {
        return null;
    }

    const query = `
        mutation upgradeInstagramV2($username: String, $stripe_token: String, $interval: HypeAuditorAccountType, $cognito_id: String) {
            upgradeInstagramUserV2(username: $username, stripe_token: $stripe_token, interval: $interval, cognito_id: $cognito_id) {
                username
                account_type
                error {
                    message
                }
            }
        }
    `

    const variables = {
        username,
        cognito_id,
        stripe_token,
        interval,
    }

    let payload = {
        query,
        variables,
    }

    return {
        type: UPGRADE_INSTAGRAM_V2,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: UPGRADE_INSTAGRAM_V2,
        payload: axios.post(NEXT_APP_API_URL, payload, {
                headers
            })
            .then(response => response.data)
    } */
}



const CANCEL_INSTAGRAM_V2 = 'CANCEL_INSTAGRAM_V2'
export const CANCEL_INSTAGRAM_V2_PENDING = `${CANCEL_INSTAGRAM_V2}_PENDING`;
export const CANCEL_INSTAGRAM_V2_FULFILLED = `${CANCEL_INSTAGRAM_V2}_FULFILLED`;
export const CANCEL_INSTAGRAM_V2_REJECTED = `${CANCEL_INSTAGRAM_V2}_REJECTED`;

export const cancelInstagramUserV2 = (username, cognito_id, reason) => {

    if (!username || !cognito_id) {
        return null;
    }

    const query = `
        mutation cancelInstagramUserV2($username: String, $cognito_id: String, $reason: String) {
            cancelInstagramUserV2(username: $username, cognito_id: $cognito_id, reason: $reason) {
                _id
                cognito_id
                username
                account_type
                error {
                    message
                }
            }
        }
    `

    const variables = {
        username,
        cognito_id,
        reason,
    }

    let payload = {
        query,
        variables,
    }

    return {
        type: CANCEL_INSTAGRAM_V2,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: CANCEL_INSTAGRAM_V2,
        payload: axios.post(NEXT_APP_API_URL, payload, {
                headers
            })
            .then(response => response.data)
    } */
}

const VERIFY_INSTAGRAM_USER = 'VERIFY_INSTAGRAM_USER';
export const VERIFY_INSTAGRAM_USER_PENDING = `${VERIFY_INSTAGRAM_USER}_PENDING`;
export const VERIFY_INSTAGRAM_USER_FULFILLED = `${VERIFY_INSTAGRAM_USER}_FULFILLED`;
export const VERIFY_INSTAGRAM_USER_REJECTED = `${VERIFY_INSTAGRAM_USER}_REJECTED`;

export const verifyInstagramUser = (username, cognito_id) => {
    if (!username || !cognito_id) {
        return null;
    }

    const query = `
        mutation verifyInstagramUser($username: String, $cognito_id: String) {
            verifyInstagramUser(username: $username, cognito_id: $cognito_id) {
                username
                account_verified
                error {
                  message
                }
            }
        }
    `;

    const variables = {
        username,
        cognito_id
    };

    const payload = {
        query,
        variables
    };

    return {
        type: VERIFY_INSTAGRAM_USER,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};
