import React from 'react';
import { TikTokAccount } from 'llama-library/components';
import PropTypes from 'prop-types';

const HypeAuditorTikTok = (props) => {
    const {
        affiliate,
        isVerifyUserClicked,
        handleAccountChange,
        handleVerifyAccount
    } = props;

    if (!affiliate) {
        return null;
    }

    const { tikTokData } = affiliate;
    if (!tikTokData || !Array.isArray(tikTokData) || tikTokData.length === 0) {
        return null;
    }

    // Config to show/hide the buttons on analytics page
    const showPageActions = {
        verifyAccount: true,
        addAccount: true
    };

    return (
        <>
            <TikTokAccount
                {...props}
                isVerifyUserClicked={isVerifyUserClicked}
                handleAccountChange={handleAccountChange}
                handleVerifyAccount={handleVerifyAccount}
                showPageActions={showPageActions}
            />
        </>
    );
};

HypeAuditorTikTok.propTypes = {
    affiliate: PropTypes.object.isRequired,
    isVerifyUserClicked: PropTypes.bool.isRequired,
    handleAccountChange: PropTypes.func.isRequired,
    handleVerifyAccount: PropTypes.func.isRequired
};

export default HypeAuditorTikTok;
