/**
 * Author: Natalie Kappele-Miller (natalie@mobooka.com)
 * App: LLAMA APP
 * Date: November 1, 2021
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris';

import './styles/achievements.css';

import { connect } from 'react-redux';

import { Badges } from 'llama-library/components';

import { changeHeaderTitle } from '../store/actions/header';
import { getAffAchievements } from '../store/actions/action_achievements';

/**
 * @function Achievements
 * Container component for the ambassador achievements page.
 * Displays ambassador achievements and their states of completion.
 * @returns {Component}
 */
const Achievements = (props) => {
    // Update this to include new badge group titles
    const badgeGroupTitles = { 'affiliate-onboard': 'Onboarding Bonus' };
    // Update this to include new badge group messages
    const badgeMessages = { 'affiliate-onboard': 'Complete all four badges to earn the Onboarding bonus.' };
    // Update this to include new badge group success messages
    const badgeSuccessMessages = { 'affiliate-onboard': 'You earned the Onboarding Bonus for completing all four onboarding badges.' };
    const [achievements, setAchievements] = useState([]);
    const [achievementsLoading, setAchievementsLoading] = useState(false);
    const [group, setGroup] = useState('affiliate-onboard');

    useEffect(() => {
        props.changeHeaderTitle('Achievements');
    }, []);

    useEffect(() => {
        setAchievementsLoading(true);
        props.getAffAchievements(props.affiliate.cognito_id, group)
            .then((response) => {
                if (response) {
                    console.log('getOnboardingAchievements response:', response);
                    setAchievements(response.value.data.getAffAchievements);
                    setAchievementsLoading(false);
                }
            })
            .catch((error) => {
                console.log('error getOnboardingAchievements:', error);
                setAchievementsLoading(false);
            });
    }, []);

    /**
     * @function displayMessage
     * Compares the length of achievements to array of achievements
     * with a value for badge_earned. If they are the same
     * return the success message.
     *
     * @returns @String
     */
    const displayMessage = () => {
        let message = badgeMessages[group];
        if (achievements && Array.isArray(achievements) && achievements.length > 0) {
            const badgesEarned = achievements.filter((achievement) => {
                return achievement.badge_earned !== null;
            });
            if (badgesEarned.length === achievements.length) {
                message = badgeSuccessMessages[group];
            }
        }
        return message;
    };

    /**
     * @function renderAchievements
     * Returns user's achievement Badges, or loading state
     * while fetching the data for getAffAchievements.
     * @returns @div
     */
    const renderAchievements = () => {
        const emptyArray = new Array(4);
        emptyArray.fill(' ');
        if (
            achievements
            && Array.isArray(achievements)
            && achievements.length > 0
            && !achievementsLoading
        ) {
            return (
                <div className="Achievments__Wrapper--badges">
                    <Badges badgesList={achievements} />
                </div>
            );
        }
        return (
            <div className="Achievements__Wrapper--loading">
                <div className="loading-row">
                    {emptyArray.map((item, index) => {
                        return (
                            <div className="loading-badge" key={index}>
                                <SkeletonThumbnail />
                                <h1><SkeletonBodyText lines={2} /></h1>
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="Achievements__Wrapper" data-test="achievements-container">
            <h1>{badgeGroupTitles[group]}</h1>
            <p className="subtitle">{displayMessage()}</p>
            {renderAchievements()}
        </div>
    );
};

Achievements.propTypes = {
    changeHeaderTitle: PropTypes.func.isRequired,
    getAffAchievements: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        achievements: state.achievements.achievements,
        ...state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeaderTitle: (title) => { return dispatch(changeHeaderTitle(title)); },
        getAffAchievements: (id, group) => { return dispatch(getAffAchievements(id, group)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Achievements);
