/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_PAYPAL_AUTH_TOKEN } from '../../../config'; */
import {sendBackendRequest} from '../sendRequest';

export const PAYPAL_TOKEN_PENDING = 'PAYPAL_TOKEN_PENDING';
export const PAYPAL_TOKEN_FULFILLED = 'PAYPAL_TOKEN_FULFILLED';
export const PAYPAL_TOKEN_REJECTED = 'PAYPAL_TOKEN_REJECTED';

export const createAuthToken = (data) => {
    console.log("data in createAuthToken",data);
    let payload = {
        "code": data
    }

    return {
        type: 'PAYPAL_TOKEN',
        payload: sendBackendRequest('merchant', 'createAuthToken', payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'PAYPAL_TOKEN',
        payload:axios.post(NEXT_APP_PAYPAL_AUTH_TOKEN, payload, { headers })
        .then(response => response.data)
    } */
    
}
