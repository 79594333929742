/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import { SAVE_PAYPAL_INFO } from './types';

export function savePaypalInfo(paypalInfo) {
    console.log("savePaypalInfo",paypalInfo);
    return {
        type: SAVE_PAYPAL_INFO,
        payload: paypalInfo
    };
}