const copyToClipboard = (text, setToastVerbiage = null, toastPrefix = '', onSuccess = null, onError = null) => {
    // For versions of Safari older than 13
    let copyToClipboardPromise;
    if (navigator.clipboard) {
        copyToClipboardPromise = navigator.clipboard.writeText(text);
    } else {
        // Create text area element and assign text as its value
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        copyToClipboardPromise = new Promise((resolve, reject) => {
            const copySuccess = document.execCommand('copy');
            if (copySuccess) {
                resolve('Resolved');
            } else {
                reject(Error('Unable to execute copy command'));
            }
        });

        document.body.removeChild(textArea);
    }

    return copyToClipboardPromise
        .then(() => {
            if (setToastVerbiage && toastPrefix) {
                setToastVerbiage(`${toastPrefix} copied to clipboard`);
            } else if (setToastVerbiage && !toastPrefix) {
                setToastVerbiage('Copied to clipboard');
            }
            if (onSuccess) {
                onSuccess();
            }
        })
        .catch((err) => {
            console.log(`Error copying ${toastPrefix}: `, err);
            if (setToastVerbiage && toastPrefix) {
                setToastVerbiage(`Oops, something went wrong. Unable to copy ${toastPrefix}.`);
            } else if (setToastVerbiage && !toastPrefix) {
                setToastVerbiage('Oops, something went wrong. Unable to copy text.');
            }

            if (onError) {
                onError(err);
            }
        });
}

export default copyToClipboard;
