import React, { useState, useEffect } from 'react';
import './settings-general.css';
import {
    FormLayout,
    Avatar,
    Toast,
    Frame,
    Select,
    TextField
} from '@shopify/polaris';
import _ from 'lodash';
import { LlamaButton } from 'llama-library/components';
// import { countryList } from 'llama-library/utils';

import useReduxAction from '../../hooks/use-redux-action';
import { generateTrackingLink } from '../../store/actions/generate-link';

import { startPages } from '../../utils/dropdown-options';

import ChangePassword from '../../authentiction/change-password';
import ProductCategories from '../affiliate-product-categories/affiliate-product-categories';
import GeneralSettingsSideDrawer from './side-drawer';

import referralGraphic from '../../assets/referral-graphic.svg';

export const EditableField = ({ id, value, label, labelHint, handleChange, saveChange, options, currentEdit, setCurrentEdit, setOrigValue }) => {
    const [fieldFocus, setFieldFocus] = useState(false);
    const [saving, setSaving] = useState(false);

    const toggleEditMode = () => {
        setCurrentEdit(id);
        setFieldFocus(true);
        setOrigValue(value);
    };

    const inputField = options
        ? (
            <Select
                id={`user-${id}`}
                value={value || ''}
                options={options}
                onChange={(val) => { return handleChange(val, id); }}
                className="settings-general__select"
                focused={fieldFocus}
            />
        )
        : (
            <TextField
                id={`user-${id}`}
                value={value || ''}
                onChange={(val) => { return handleChange(val, id); }}
                className="settings-general__input"
                type="text"
                focused={fieldFocus}
            />
        );
    let selectedOption = options
        ? options.find((option) => option.value === value)
        : null;
    if (selectedOption) {
        selectedOption = selectedOption.label;
    }

    const handleSave = async () => {
        setSaving(true);
        const result = await saveChange();
        if (result === 'success') {
            setSaving(false);
            setFieldFocus(false);
            setCurrentEdit(null);
            setOrigValue(null);
        }
    };

    return (
        <div className="settings-general__input-row">
            <p className="settings-general--input-label">
                {label}
                {labelHint && <span className="hint">{labelHint}</span>}
            </p>
            <div className="settings-general__edit">
                {currentEdit === id
                    ? (
                        <>
                            {inputField}
                            <LlamaButton
                                onClick={handleSave}
                                loading={saving}
                                disabled={saving}
                            >
                                Save
                            </LlamaButton>
                        </>
                    )
                    : (
                        <>
                            <p className="settings-general__display">{options ? selectedOption : value}</p>
                            <button type="button" className="settings-general__edit-text" onClick={toggleEditMode}>Edit</button>
                        </>
                    )
                }
            </div>
        </div>
    );
};

const SettingsGeneral = (props) => {
    const { values, offers } = props;
    let renderOfferOptions;

    const [filters] = useState({ affiliate_status: 'APPROVED', advertiser_status: 'APPROVED' });

    const { isLoading, hasLoaded } = useReduxAction(props.getOffers, [props.affiliate, filters]);

    if (_.isPlainObject(offers)) {
        delete offers.offers;
        // Map through our offer array to get the name and offer_id
        renderOfferOptions = Object.values(offers).map(({ offer }) => {
            return { label: offer.name, value: offer.offer_id }
        })
    } else {
        // Map through our offer array to get the name and offer_id
        renderOfferOptions = offers.map(({ offer }) => {
            return { label: offer.name, value: offer.offer_id }
        })
    }

    const [toastVerbiage, setToastVerbiage] = useState(null);

    const [linkIsLoading, setLinkIsLoading] = useState(false);
    const [selectedOfferId, setSelectedOfferId] = useState('LLAMA_001');
    const [currentEdit, setCurrentEdit] = useState(null);
    const [origValue, setOrigValue] = useState(null);

    useEffect(() => {
        const clickOutside = (e) => {
            if (e.target.id !== `user-${currentEdit}` && e.target.className !== 'settings-general__edit-text' && e.target.className !== 'llama-button' && e.target.parentNode.className !== 'llama-button') {
                props.setFieldValue(currentEdit, origValue, true);
                setCurrentEdit(null);
                document.removeEventListener('click', clickOutside);
            }
        };
        if (currentEdit) {
            document.addEventListener('click', clickOutside);
        } else {
            document.removeEventListener('click', clickOutside);
        }
        return () => document.removeEventListener('click', clickOutside);
    }, [currentEdit]);

    useEffect(() => {
        if (renderOfferOptions.length > 0) {
            let llamaOffer = renderOfferOptions.find((item) => {
                return item.value === 'LLAMA_001';
            });

            if (!llamaOffer && selectedOfferId === 'LLAMA_001') {
                setSelectedOfferId(renderOfferOptions[0].value);
            }
        }
    }, [renderOfferOptions])

    // Llama Offer Boolean
    const isLlamaOffer = selectedOfferId && selectedOfferId.includes('LLAMA');
    let llamaOfferData;

    if (_.isPlainObject(offers)) {
        llamaOfferData = Object.values(offers).find(({ offer }) => {
            return offer.offer_id === "LLAMA_001";
        })

    } else {
        // Llama Offer Data
        llamaOfferData = offers.find(({ offer }) => {
            return offer.offer_id === "LLAMA_001";
        })

    }

    // Link generation and copy functionality
    const handleCopyLink = () => {
        setLinkIsLoading(true);

        // Llama Offer URL
        const llamaOfferUrl = llamaOfferData.offer.offer_url.replace('{affiliate_id}', props.affiliate.tracking_id).replace('{offer_id}', selectedOfferId);

        // Let Llama Offer URL be default
        let trackingLink = llamaOfferUrl;

        // Handle link generation
        if (!isLlamaOffer) {
            generateTrackingLink(selectedOfferId, props.affiliate.tracking_id)
                .then(result => {
                    if (result && result.generateTrackingLink && result.generateTrackingLink.click_url) {
                        setLinkIsLoading(false);
                        return trackingLink = result.generateTrackingLink.click_url;
                    }
                })
                .catch(err => {
                    setLinkIsLoading(false);
                    console.log("There was an error generating tracking link:", err);
                })
        }
        let copyToClipboard;
        if (navigator.clipboard) {
            copyToClipboard = navigator.clipboard.writeText(trackingLink)
        } else {
            // For versions of Safari older than 13
            const textArea = document.createElement("textarea");
            textArea.value = trackingLink;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            copyToClipboard = new Promise((resolve, reject) => {
                const copySuccess = document.execCommand('copy');
                if (copySuccess) {
                    resolve("Resolved")
                } else {
                    reject("Unable to execute copy command")
                }
            })

            document.body.removeChild(textArea);
        }

        copyToClipboard
            .then(() => {
                setToastVerbiage("Link copied to clipboard");
                setLinkIsLoading(false);
            })
            .catch(err => {
                console.log("Error copying link:", err);
                setToastVerbiage("Oops, something went wrong. Unable to copy link.");
                setLinkIsLoading(false);
            })
    }

    const handleChange = (value, field) => {
        props.setFieldValue(field, value, true);
    };

    const saveChange = () => {
        const payload = {
            name: `${values.personal.first_name} ${values.personal.last_name}`,
            photo: values.photo,
            account: { personal: values.personal },
            start_page: values.start_page
        };
        return props.updateAffiliate(payload, props.affiliate.cognito_id)
            .then(() => {
                setToastVerbiage('Your settings have been saved');
                return 'success';
            });
    };

    const handleOfferSelect = (offerId) => {
        setSelectedOfferId(offerId);
    };

    const profileFields = [
        {
            id: 'personal.first_name',
            label: 'First Name',
            value: values.personal ? values.personal.first_name : ''
        },
        {
            id: 'personal.last_name',
            label: 'Last Name',
            value: values.personal ? values.personal.last_name : ''
        }
    ];

    let accountFields = [];
    if (!props.affiliate.account_type === 'smartlinks') {
        accountFields = [
            {
                id: 'start_page',
                label: 'Start Page',
                labelHint: 'Choose which page you want to see first when you sign in to Llama.',
                value: values.start_page || 'discover',
                options: startPages
            }
        ];
    }

    accountFields = [
        ...accountFields,
        {
            id: 'personal.email',
            label: 'Account Email',
            value: values.personal ? values.personal.email : ''
        },
        {
            id: 'personal.phone',
            label: 'Phone',
            value: values.personal ? values.personal.phone : ''
        }
        /* ,
        {
            id: 'personal.address1',
            label: 'Address 1',
            value: values.personal ? values.personal.address1 : ''
        },
        {
            id: 'personal.address2',
            label: 'Address2',
            value: values.personal ? values.personal.address2 : ''
        },
        {
            id: 'personal.city',
            label: 'City',
            value: values.personal ? values.personal.city : ''
        },
        {
            id: 'personal.state',
            label: 'State / Province',
            value: values.personal ? values.personal.state : ''
        },
        {
            id: 'personal.zip',
            label: 'Zip',
            value: values.personal ? values.personal.zip : ''
        },
        {
            id: 'personal.country',
            label: 'Country',
            value: values.personal ? values.personal.country : '',
            options: countryList
        } */
    ];

    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)} /> : null;

    return (
        <div className="settings-general__wrapper">
            <Frame>
                <GeneralSettingsSideDrawer
                    {...props}
                    saveFile={props.saveFile}
                    files={props.files}
                    affiliate={props.affiliate}
                    setShowSideDrawer={props.setShowSideDrawer}
                    showSideDrawer={props.showSideDrawer !== null}
                    uploading={props.uploading}
                />

                <FormLayout>
                    <div className="settings-general">
                        <div className="settings-general__basic-info">
                            <div className="settings-general__referral-section">
                                <img src={referralGraphic} className="settings-general__referral-graphic" alt="" />
                                <div className="settings-general__referral-link">
                                    <h1 className="settings-general__referral-title">Refer Friends &amp; Earn Money</h1>
                                    <div className="settings-general__referral-form-row">
                                        <div className="settings-general__referral-textfield">
                                            <Select
                                                options={renderOfferOptions}
                                                value={selectedOfferId}
                                                onChange={handleOfferSelect}
                                            />
                                        </div>
                                        <LlamaButton
                                            disabled={linkIsLoading}
                                            loading={linkIsLoading}
                                            onClick={handleCopyLink}
                                        >
                                            Copy Link
                                        </LlamaButton>

                                    </div>
                                </div>
                            </div>
                            <div className="settings-general__section-label">
                                <h3 className="settings-general__section-title">
                                    Profile
                                </h3>
                            </div>

                            <div className="settings-general__input-row">
                                <p className="settings-general--input-label">
                                    Photo
                                </p>
                                <div className="settings-general__edit">
                                    <Avatar customer name={values.first_name} source={values.photo} size="small" />
                                    <button type="button" className="settings-general__edit-text" onClick={() => props.setShowSideDrawer(true)}>
                                        Edit
                                    </button>
                                </div>
                            </div>

                            {profileFields.map((item) => {
                                return (
                                    <EditableField
                                        key={item.id}
                                        id={item.id}
                                        label={item.label}
                                        labelHint={item.labelHint}
                                        value={item.value}
                                        setOrigValue={setOrigValue}
                                        handleChange={handleChange}
                                        saveChange={saveChange}
                                        options={item.options}
                                        setCurrentEdit={setCurrentEdit}
                                        currentEdit={currentEdit}
                                    />
                                );
                            })}

                            <div className="settings-general__section-label">
                                <h3 className="settings-general__section-title">
                                    Account
                                </h3>
                            </div>

                            <div className="settings-general__input-row">
                                <p className="settings-general--input-label">
                                    Password
                                </p>
                                <ChangePassword affiliate={props.affiliate} />
                            </div>

                            {accountFields.map((item) => {
                                return (
                                    <EditableField
                                        key={item.id}
                                        id={item.id}
                                        label={item.label}
                                        labelHint={item.labelHint}
                                        value={item.value}
                                        setOrigValue={setOrigValue}
                                        handleChange={handleChange}
                                        saveChange={saveChange}
                                        options={item.options}
                                        setCurrentEdit={setCurrentEdit}
                                        currentEdit={currentEdit}
                                    />
                                );
                            })}

                        </div>
                    </div>
                </FormLayout>
                {(props.affiliate.account_type !== 'smartlinks')
                    && <ProductCategories {...props} />
                }
                {toastMarkup}
            </Frame>
        </div>

    );
};

export default SettingsGeneral;
