import React from 'react';
import { Spinner } from '@shopify/polaris';

import './centered-spinner.css';

const CenteredSpinner = () => {
    return <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem', width: '100%' }} className="llama-centered-spinner">
        <Spinner />
    </div> 
}

export default CenteredSpinner;