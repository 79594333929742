import React from 'react';
import { 
    Page, 
    SkeletonBodyText, 
    SkeletonDisplayText
} from '@shopify/polaris';

const OfferDetailSkeleton = () => {
    return (
        <Page>
            <div className="offer-detail__offer-wrapper">
                <aside className="affiliate-offer__sidebar">
                    <div className="affiliate-offer__sidebar-header">
                        <div className="discover-offer__title" style={{ marginBottom: '2rem' }}>
                            <SkeletonDisplayText size="large" />
                        </div>
                        <h3 className="discover-offer__subtitle">
                            <SkeletonBodyText lines={1} />
                        </h3>
                    </div>

                    <div className="discover-offer__description">
                        <div className="discover-offer__info">
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div className="discover-offer__info">
                            <SkeletonBodyText lines={1} />
                        </div>
                        <div className="discover-offer__domain">
                            <SkeletonBodyText lines={1} />
                        </div>
                    </div>

                    <div className="discover-offer__stats-stats">
                        <div className="discover-offer__stat" style={{ margin: '1.75rem' }}>
                            <SkeletonDisplayText size="small" />
                        </div>
                        <div className="discover-offer__stat" style={{ margin: '1.75rem' }}>
                            <SkeletonDisplayText size="small" />
                        </div>
                        <div className="discover-offer__stat" style={{ margin: '1.75rem' }}>
                            <SkeletonDisplayText size="small" />
                        </div>
                        <div className="discover-offer__stat" style={{ margin: '1.75rem' }}>
                            <SkeletonDisplayText size="small" />
                        </div>
                    </div>

                    <div className="affiliate-offer__apply" style={{ margin: '2rem' }}>
                    </div>
                </aside>

                <div style={{ flex: '1', width: '100%' }}>
                    <div className="OfferView__Tabs" style={{ padding: '2.5rem' }}>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default OfferDetailSkeleton;