import React from 'react';
import { SkeletonBodyText, Banner, Badge } from '@shopify/polaris';
import { LlamaPagination, EmptyState } from 'llama-library/components';

import {moneyFormat} from '../../utils/number-format';
import moment from 'moment';

import './transaction-report.css';

/**
 * @enum ReportColumns
 * Allowed columns within TransactionReports component.
 */
export const ReportColumns = {
    ORDER_NUMBER: 'ORDER_NUMBER',
    DATE: 'DATE',
    ADVERTISER: 'ADVERTISER',
    OFFER: 'OFFER',
    CUSTOMER: 'CUSTOMER',
    COMMISSION: 'COMMISSION',
    STATUS: 'STATUS',
    SUBTOTAL: 'SUBTOTAL',
    LOOK_FORWARD: 'LOOK_FORWARD',
    COUPON: 'COUPON'
}

const defaultColumns = [
    ReportColumns.ORDER_NUMBER,
    ReportColumns.DATE,
    ReportColumns.ADVERTISER,
    ReportColumns.CUSTOMER,
    ReportColumns.COMMISSION,
    ReportColumns.STATUS,
    ReportColumns.SUBTOTAL,
    ReportColumns.LOOK_FORWARD,
    ReportColumns.COUPON
]
const TransactionReports = ({
    orders,
    isLoading,
    errorMessage,
    columns = defaultColumns,
    onNext,
    onPrevious,
    pageInfo,
    discounts
}) => {

    // Function that filteres out expired ambassador coupons
    // Will return an array of valid coupons that are on the offer object
    const validCoupons = discounts && Array.isArray(discounts) && discounts.length > 0 && discounts.filter((discount) => {
        if(moment(discount.ends_at).isBefore(moment())) {
            return false;
        }
        return true
    });


/**
 * Specific cell information based on the column
 */
const cellData = {

    [ReportColumns.ORDER_NUMBER]: {
        key: "order_number",
        headerTitle: "Order",
        class: "TransactionReports__DataNumber",
        value: ({ name }) => name,
    },

    [ReportColumns.DATE]: {
        key: "date",
        headerTitle: "Date",
        class: "TransactionReports__DataDate",
        value: ({ created_at }) => {
            const createDate = new Date(created_at);
            return moment(createDate).format('MM/DD/YY');
        },
    },

    [ReportColumns.ADVERTISER]: {
        key: "advertiser",
        headerTitle: "Advertiser",
        class: "TransactionReports__DataName",
        value: ({ connection }) => {
            return connection && connection.advertiser && connection.advertiser.name
        },
    },

    [ReportColumns.CUSTOMER]: {
        key: "customer",
        headerTitle: "Customer",
        class: "TransactionReports__DataName",
        value: ({ customer }) => `${customer && customer.first_name} ${customer && customer.last_name}`,
    },

    [ReportColumns.COMMISSION]: {
        key: "commission",
        headerTitle: "Commission",
        class: "TransactionReports__DataCurrency",
        value: ({ connection }) => moneyFormat(connection && connection.commission_amount),
    },

    [ReportColumns.STATUS]: {
        key: "status",
        headerTitle: "Status",
        class: "TransactionReports__DataBadge",
        value: ({ invoice }) => {
            return invoice && invoice.status === 'PAID' ? <Badge status="success">Paid</Badge>: <Badge status="attention">Unpaid</Badge>;
        },
    },

    [ReportColumns.SUBTOTAL]: {
        key: "subtotal",
        headerTitle: "Subtotal",
        class: "TransactionReports__DataCurrency",
        value: ({ subtotal_price }) => moneyFormat(subtotal_price),
    },

    [ReportColumns.LOOK_FORWARD]: {
        key: "look_forward",
        headerTitle: "Earn Until",
        class: "TransactionReports__DataLookForward",
        value: ({ connection }) => {
            const formattedDate = new Date(connection && connection.expired_timeline);
            return moment(formattedDate).format('MM/DD/YY');
        },
    },

    [ReportColumns.COUPON]: {
        key: "coupon",
        headerTitle: "Coupon",
        class: "TransactionReports__DataCoupon",
        value: ({ discount_codes }) => {
            // Check if coupons exist on order AND on offer
            // If no discount codes are found in either place return 'None Assigned'
            // since the merchant did not create a coupon for this offer
            if (
                discount_codes
                && Array.isArray(discount_codes)
                && discount_codes.length > 0
                && validCoupons && Array.isArray(validCoupons)
                && validCoupons.length > 0
            ) {
                // Filter out the ambassador coupon that matches the order coupon
                // If a match is not found we return 'None Used'
                const couponMatch = validCoupons.filter((discount) => {
                    return discount.code === discount_codes[0].code;
                })
                if (couponMatch && Array.isArray(couponMatch) && couponMatch.length > 0) {
                    return (
                        <div className="TransactionReports__CouponCode">
                            {couponMatch[0].code}
                        </div>
                    )
                }
                return `None Used`
            }
            return `None Assigned`
        }
    }
}

/**
 * @Component LoadingRows
 * Displays a series of fake rows to simulate what the rows will look like once actual
 * data has loaded.
 * @prop {Int} rows - Number of rows to 'fake'
 * @prop {Array<String>} columns - Array of columns that are included
 * @prop {Object} cellData - Data with information on how to render each cell based on column
 */
const LoadingRows = ({ loadingRows, loadingColumns, loadingCellData }) => {

    if (!loadingCellData || !loadingRows || !loadingColumns) {
        return null;
    }

    const loadingArray = [loadingRows];
    loadingArray.fill('');

    const emptyCell = (
        <div style={{ width: '80%' }}>
            <SkeletonBodyText lines={1} />
        </div>
    )

    return (
        <React.Fragment>
            {loadingArray.map((_, index) => {
                const style = index % 2 !== 0 ? { background: "#F4F6F8", height: '40px' } : { height: '40px' };
                return (
                    <div className="TransactionReports__Row" key={`${index}`} style={style}>
                        {columns.map((item) => {
                            const cellInfo = cellData[item];
                            return <div className={`TransactionReports__Cell ${cellInfo.class}`} key={`loading_${cellInfo.key}`}>{emptyCell}</div>;
                        })}
                    </div>
                )
            })}
        </React.Fragment>
    )

}


    const { pages, hasNextPage, hasPreviousPage, currentPage, ordersPerPage = 10 } = pageInfo;

    return (
        <React.Fragment>

            <div className="TransactionReports__Table">

                {/* REPORT HEADER */}
                <div className="TransactionReports__Row TransactionReports__TableHeader">
                    {columns.map((column) => {
                        const cellInfo = cellData[column];
                        return (
                            <div
                                key={`header__${cellInfo.key}`}
                                className={`TransactionReports__Cell ${cellInfo.class}`}
                            >
                                {cellInfo.headerTitle}
                            </div>
                        )
                    })}
                </div>

                {/* LOADING STATE */}
                {isLoading && <LoadingRows loadingRows={ordersPerPage} loadingColumns={columns} loadingCellData={cellData}/>}

                {/* ERROR STATE */}
                {!isLoading && errorMessage && (
                    <div className="TransactionReports__Row" style={{ padding: '2rem', display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Banner>{errorMessage}</Banner>
                    </div>
                )}

                {/* EMPTY STATE */}
                {Array.isArray(orders) && orders.length === 0 && !isLoading && !errorMessage && (
                    <EmptyState message="I couldn’t find any sales" paragraph="Looks like you didn’t have any sales during this time period." />
                )}

                {/* DATA ROWS */}
                {Array.isArray(orders) && orders.length > 0 && !isLoading && !errorMessage && (
                    orders.map((order, index) => {

                        const { order_id } = order;
                        const style = index % 2 !== 0 ? { background: "#F4F6F8" } : {};

                        return (
                            <div className="TransactionReports__Row" key={`${index}__${order_id}`} style={style}>
                                {columns.map((column) => {

                                    // Creates a cell for each column specified in the component using
                                    // the cellData object for each column.

                                    // Get cell data object for the column.
                                    const cellInfo = cellData[column];

                                    return (
                                        <div key={`${order_id}__${cellInfo.key}`} className={`TransactionReports__Cell ${cellInfo.class}`}>
                                            {cellInfo.value(order)}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                )}
            </div>

            {/* PAGINATION */}
            {pages > 1 && <LlamaPagination
                hasNext={hasNextPage}
                hasPrevious={hasPreviousPage}
                onNext={onNext}
                onPrevious={onPrevious}
                currentPage={currentPage - 1}
                maxPages={pages - 1}
            />}

        </React.Fragment>
    )

}

export default TransactionReports;
