/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import {NEXT_APP_API_URL} from '../../config'; */
import {sendGraphQL} from './sendRequest';

export const GET_CATAGORIES_PENDING = 'GET_CATAGORIES_PENDING';
export const GET_CATAGORIES_FULFILLED = 'GET_CATAGORIES_FULFILLED';
export const GET_CATAGORIES_REJECTED = 'GET_CATAGORIES_REJECTED';

export const getCatagories = () => {
    const query = `
        query {
            app {
              categories {
                name
                value
              }
            }
          }
        `
        const variables = {

        }

        let payload = {
            query,
            variables
        }

        return {
          type: 'GET_CATAGORIES',
          payload: sendGraphQL(payload)
            .then(response => response.data)
        }

        /* let headers = {
            "Content-Type": 'application/json'
        }

        return {
            type: 'GET_CATAGORIES',
            payload:axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
        } */
}