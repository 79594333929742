import React, { useState } from 'react';
import { Modal, Spinner } from '@shopify/polaris';
import moment from 'moment';

import { LlamaButton } from 'llama-library/components';

import './smart-link-plans.css';

const SmartLinkPlans = (props) => {
    const [downgradeModalOpen, setDowngradeModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDowngradeModal = () => {
        setDowngradeModal(!downgradeModalOpen)
    }

    const downgrade = () => {
        setLoading(true)
        props.downgrade().then((result) => {
            setLoading(false)
            if (result === true) {
                handleDowngradeModal()
            }
        })
    }

    const goToUpgradeForm = () => {
        props.history.push('/smartlinks/upgrade/pro')
    }

    if (props.linksLoading) {
        return <Spinner />
    }

    const renderExtraAccessLoss = () => {
        if (props.theme === 'custom' || props.numExtraLinks > 0) {
            const customThemeLoss = 'Additionally, your theme will revert to the default blue and white theme.';

            let linksToHide = [];
            for (let i = props.freeLinkCount + 1; i <= props.freeLinkCount + props.numExtraLinks; i++) {
                linksToHide.push(i)
            }

            linksToHide = linksToHide.map(linkNumber => {
                if (linkNumber == 2) return linkNumber + 'nd'
                if (linkNumber == 3) return linkNumber + 'rd'
                return linkNumber + 'th'
            })
            const linksToHideStr = linksToHide.join(', ').replace(/, ([0-9nrdth]+)$/, ' and $1')
            console.log(linksToHide)
            const extraLinksLoss = ' We won\'t delete your ' + linksToHideStr + ' manual link' + (linksToHide.length > 1 ? 's' : '') + ' in case you change your mind later, but ' + (linksToHide.length > 1 ? 'they' : 'it') + ' will no longer be displayed on your page.';

            return (
                <p>
                    {props.theme === 'custom' && customThemeLoss}
                    {props.numExtraLinks > 0 && extraLinksLoss}
                </p>
            )
        } else {
            return '';
        }
    }

    // smartlinks plan expiry date
    const expiryDate = props.expiresAt ? moment(props.expiresAt).format('LL') : null;

    // links, current theme
    return (
        <>
            {props.plan === 'pro'
                && (
                    <Modal
                        open={downgradeModalOpen}
                        onClose={handleDowngradeModal}
                        primaryAction={{
                            content: 'Downgrade to FREE',
                            loading,
                            onAction: downgrade
                        }}
                        secondaryActions={{
                            content: 'Stay on PRO',
                            onAction: handleDowngradeModal
                        }}
                        title="Are you sure you want to downgrade?"
                    >
                        <Modal.Section>
                            <p>By clicking &ldquo;Downgrade to FREE&rdquo; <strong>your subscription will be cancelled and you&rsquo;ll lose access to the following</strong>:</p>
                            <ul>
                                {/* <li>Displaying more than {props.freeLinkCount} link{props.freeLinkCount !== 1 && 's'}</li> */}
                                <li>Full theme customization</li>
                                <li>Custom Page URL Handle</li>
                                <li>Custom Headline</li>
                                <li>Google Analytics</li>
                                <li>Email List Signup Form</li>
                                <li>Link Analytics</li>
                                {/* add these in once the features get created
                                    <li>Referral Link Images</li>
                                */}
                            </ul>
                            {renderExtraAccessLoss()}
                        </Modal.Section>
                    </Modal>
                )
            }
            <div className="smartlink--plans">
                <p className="heading">
                    {props.plan === 'pro' ? 'SmartLinks Plans' : 'Upgrade to Pro'}
                </p>
                <p className="info">
                    {props.plan === 'pro' ? 'You are currently on the SmartLinks PRO plan, ' : 'SmartLinks PRO is '}
                    a supercharged package that helps you get the most out of your SmartLinks page. {/* Add up to {props.proLinkCount} links or offers, t */}Track performance, fully customize your theme, capture emails for your mailing list, and more. <strong>Upgrade or downgrade at any time</strong>.
                </p>
                <div className="smartlink--plan-definitions">
                    <figure className="smartlink--plan-free">
                        <figcaption>SmartLinks FREE {props.plan === 'free' && <span>Your Current Plan</span>}</figcaption>
                        <p className="plan-info">The quickest way to display your referral links on one page.</p>
                        <p className="price"><span className="currency-symbol">$</span>0.00</p>
                        <p className="feature-intro">Basic features include:</p>
                        <ul>
                            <li>Add unlimited referral links from your approved Llama offers and any other programs you&rsquo;re a part of</li>
                            <li>Use automatic mode to keep your page up-to-date with all your Llama offers, sorted by performance.</li>
                            <li>Choose one of six premade color themes.</li>
                            <li>Add a bio without character limits.</li>
                            <li>Add your social media links.</li>
                            <li>Customize your URL</li>
                            <li>Upload your own profile picture.</li>
                            <li>Add your own UTM parameters to all links.</li>
                        </ul>
                        {props.plan === 'pro' && <p className="cta"><LlamaButton onClick={handleDowngradeModal}>Downgrade to FREE</LlamaButton></p>}
                    </figure>

                    <figure className="smartlink--plan-pro">
                        <figcaption>SmartLinks PRO {props.plan === 'pro' && <span>Your Current Plan</span>}</figcaption>
                        {(props.plan === 'pro' && expiryDate !== null)
                            && <p className="smartlink--plan-expiry">Your subscription will expire on {expiryDate}</p>
                        }
                        <p className="plan-info">Take your referral links to the next level and maintain your brand identity.</p>
                        <p className="price"><span className="currency-symbol">$</span>0.99 <span className="currency">USD / month</span></p>
                        <p className="feature-intro">PRO features include everything in FREE, plus:</p>
                        <ul>
                            <li>Choose one of ten premade color themes.</li>
                            <li>Fully customize your theme&rsquo;s colors, font, and button styles to better reflect your current branding.</li>
                            <li>Learn which links perform best by tracking how many clicks and views your links get.</li>
                            <li>Connect your Google Analytics Tracking ID for more advanced stats</li>
                            <li>Add an email sign up form for your mailing list</li>
                            <li>Remove the &ldquo;powered by Llama&rdquo; footer.</li>
                            <li>Add images to your referral links (Coming Soon).</li>
                        </ul>
                        {props.plan === 'free' && <p className="cta"><LlamaButton onClick={goToUpgradeForm}>Upgrade to PRO</LlamaButton></p>}
                    </figure>
                </div>
            </div>
        </>
    );
};

export default SmartLinkPlans;
