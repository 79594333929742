import { sendGraphQL } from '../sendRequest';

const getChatAdvertisers = (cognito_id) => {
    const query = `
        query getChatAdvertisers($cognito_id: String!){
            getChatAdvertisers(cognito_id: $cognito_id){
                id
                unread
                name
                photo
                is_online
                latest_message{
                    text
                    date
                }
            }
        }
    `;

    const variables = {
        cognito_id
    };

    const payload = {
        query,
        variables
    };

    return sendGraphQL(payload)
        .then((response) => { return response.data; })
        .catch((err) => { console.error(err); });
};

export default getChatAdvertisers;
