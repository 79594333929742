import React, {useState} from 'react';
import {Route, Switch} from 'react-router-dom';

// import OfferDashboard from '../OfferDetail/offer-dashboard';
import OfferCoupons from './offer-coupons';
import OfferProducts from './offer-products';
import OfferCreatives from './offer-creatives';
import OfferReferrals from './offer-referrals';
import OfferReports from './offer-reports';

import './offer-promotion-details.css';

import dropdownArrow from '../../assets/dropdown-arrow.svg';

const OfferPromotionDetails = (props) => {
    const {offer, applicationStatus, history} = props;
    const [tabListOpen, setTabListOpen] = useState(false);
    console.log({offer});

    let offerTabs = {
        /* dashboard: {
            key: 'dashboard',
            name: 'DASHBOARD',
            link: '',
        }, */
        products: {
            key: 'products',
            name: 'PRODUCTS',
            link: '/products',
        },
        coupons: {
            key: 'coupons',
            name: 'COUPONS',
            link: '/coupons',
        }
    };

    if (offer.offer_type !== 'product_giveaway') {
        offerTabs = {
            ...offerTabs,
            creatives: {
                key: 'creatives',
                name: 'PROMO BANNERS',
                link: '/creatives'
            },
            reports: {
                key: 'reports',
                name: 'REPORTS',
                link: '/reports'
            }
        };
    }

    //if the tab doesn't exist in the url, default to products tab
    const tabKey = window.location.pathname.match(/\/offer\/[\d]+\/(products|coupons|creatives)/)
    let startTab = '';
    if(!tabKey || !tabKey[1]){
        startTab = offerTabs.products;
    }else{
        startTab = offerTabs[tabKey[1]]
    }
    const [currentTab, setTab] = useState(startTab);

    if(applicationStatus !== 'APPROVED'){
        return <OfferProducts offer={offer} />
    }else{
        const renderTabs = (tab, history, offer_id) => {
            const isActive = currentTab.key === tab.key;

            const handleClick = () => {
                if (!offer_id || !history) {
                    return null;
                }

                setTab(tab);
                const scrollTop = (document.querySelector('.OfferView__CurrentTabName')) ? document.querySelector('.OfferView__CurrentTabName').offsetTop : 0;
                history.push(`/offer/${offer_id}${tab.link}`, {scrollTop: scrollTop});
            };

            return (
                <div key={tab.key} className="OfferView__Tab" data-active={isActive} onClick={handleClick}>
                    <div className="OfferView__TabName">{tab.name}</div>
                </div>
            )
        }

        const toggleTabList = () => {
            setTabListOpen(!tabListOpen);
        }

        console.log(offer);

        const isLlamaOffer = (offer.offer_id.includes('LLAMA')) ? true : false;

        return (
            <div className="OfferView">
                {!isLlamaOffer &&
                    <>
                        <div className="OfferView__Tabs">
                            {Object.values(offerTabs).map((tab) => renderTabs(tab, history, offer.offer_id))}
                        </div>
                        <div className="OfferView__TabCurrent" onClick={toggleTabList}>
                            <h3 className="OfferView__CurrentTabName">{currentTab.name}</h3>
                            <img src={dropdownArrow} className={tabListOpen ? "OfferView__Arrow OfferView__Arrow--flipped" : "OfferView__Arrow"} />
                        </div>
                    </>
                }
                {tabListOpen &&
                    <div className="OfferView__Tabs--mobile" onClick={toggleTabList}>
                        {Object.values(offerTabs).map((tab) => renderTabs(tab, history, offer.offer_id))}
                    </div>
                }

                <Switch>
                    {!isLlamaOffer
                        ? <>
                            {/* <Route path="/offer/:id" render={(routeProps) => <OfferDashboard offer={offer} {...routeProps} {...props}/>} /> */}
                            <Route path="/offer/:id" exact render={(routeProps) => <OfferProducts offer={offer} {...routeProps} {...props}/>} />
                            <Route path="/offer/:id/products" render={(routeProps) => <OfferProducts offer={offer} {...routeProps} {...props}/>} />
                            <Route path="/offer/:id/coupons" render={(routeProps) => <OfferCoupons offer={offer} {...routeProps} {...props}/>} />
                            {offer.offer_type !== 'product_giveaway'
                                && (
                                    <>
                                        <Route path="/offer/:id/creatives" render={(routeProps) => <OfferCreatives offer={offer} {...routeProps} {...props}/>} />
                                        <Route path="/offer/:id/reports" render={(routeProps) => <OfferReports offer={offer} {...routeProps} {...props} />} />
                                    </>
                                )
                            }
                        </>
                        : <Route render={(routeProps) => <OfferReferrals offer={offer} { ...routeProps } { ...props } />} />
                    }
                </Switch>
            </div>
        )
    }
}

export default OfferPromotionDetails;
