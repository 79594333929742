import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Icon } from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { LlamaButton } from 'llama-library/components';

import './style/social-auth-buttons.css';

const SocialAuthButtons = ({ authType, params }) => {
    const fbLabel = (authType === 'SIGNIN') ? 'Login' : 'Continue';
    const googleLabel = (authType === 'SIGNIN') ? 'Sign in' : 'Sign up';

    const startSocialOAuth = (provider) => {
        const federatedParams = {
            provider
        };

        if (authType === 'SIGNUP' && params) {
            // convert params to a string and pass as a custom state
            const customStatesArr = ['fromOauth=true'];

            Object.entries(params).forEach(([key, value]) => {
                if (value) {
                    customStatesArr.push(`${key}=${value}`);
                }
            });

            federatedParams.customState = customStatesArr.join(',');
        }

        Auth.federatedSignIn(federatedParams);
    };

    return (
        <ul id="social-auth-buttons">
            <li>
                <button
                    type="button"
                    className="facebook"
                    onClick={() => { startSocialOAuth('Facebook'); }}
                >
                    {fbLabel} with Facebook
                </button>
            </li>
            <li>
                <button
                    type="button"
                    className="google"
                    onClick={() => { startSocialOAuth('Google'); }}
                >
                    {googleLabel} with Google
                </button>
            </li>
        </ul>
    );
};

SocialAuthButtons.propTypes = {
    authType: PropTypes.oneOf(['SIGNIN', 'SIGNUP']).isRequired,
    params: PropTypes.shape({
        shop: PropTypes.string,
        offer_id: PropTypes.string,
        aff_id: PropTypes.string,
        advertiser_id: PropTypes.string,
        promo: PropTypes.string,
        from: PropTypes.string
    })
};

SocialAuthButtons.defaultProps = {
    params: null
};

const Separator = () => {
    return <p className="social-auth-separator">or</p>;
};

const EmailToggle = ({ authType, onClick }) => {
    return <LlamaButton classes={['amplify-button']} onClick={onClick}>Sign {authType === 'SIGNIN' ? 'in' : 'up'} with email</LlamaButton>;
};

EmailToggle.propTypes = {
    authType: PropTypes.oneOf(['SIGNIN', 'SIGNUP']).isRequired,
    onClick: PropTypes.func.isRequired
};

const Breadcrumb = ({ onClick }) => {
    return (
        <button type="button" className="Polaris-Breadcrumbs__Breadcrumb social-auth-breadcrumb" onClick={onClick}>
            <span className="Polaris-Breadcrumbs__ContentWrapper">
                <Icon source={ChevronLeftMinor} />
                <span className="Polaris-Breadcrumbs__Content">Back</span>
            </span>
        </button>
    );
};

Breadcrumb.propTypes = {
    onClick: PropTypes.func.isRequired
};

SocialAuthButtons.Separator = Separator;
SocialAuthButtons.EmailToggle = EmailToggle;
SocialAuthButtons.Breadcrumb = Breadcrumb;

export default SocialAuthButtons;
