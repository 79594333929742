/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from './sendRequest';
import { UPDATE_AFFILIATE } from './types';

export function unlinkYouTubeAffiliate(cognito_id, youtubeUserId) {
    const query = `mutation unlinkYoutubeAccount($cognito_id: String, $youtubeUserId: String){
        unlinkYoutubeAccount(cognito_id: $cognito_id, youtubeUserId: $youtubeUserId){
          youtube_id
          cognito_id
        }
      }`;

    const variables = {
        cognito_id,
        youtubeUserId
    };

    let payload = {
        query,
        variables
    };
    
    return {
        type: UPDATE_AFFILIATE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    };

    return {
        type: UPDATE_AFFILIATE,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}