import React from 'react';
import './settings-payment.css';
import  Affiliate1099  from '../affiliate-1099/affiliate-1099';
import Payment from '../affiliate-payment/affiliate-payment';
const SettingsPayment = (props) => {

    return (

        <div className="settings-shop__primary">
            <div className="settings-billing__section-label">
                <h3 className="settings-billing__section-title">
                    Payment Settings
                </h3>
            </div>
            <div className="settings-shop-payments">
                <Payment />
                <Affiliate1099 />
            </div>

        </div>
    )
};

export default  SettingsPayment;