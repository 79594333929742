import { UPDATE_THEME_CONFIG } from '../actions/action_changeSamartLinkTheme';

const defaultState = {
    theme: '',
    avatar: ''
};

const updateTheme = (state = defaultState, action) => {
    const themeConfig = { ...state };
    const { type } = action;

    switch (type) {
        case UPDATE_THEME_CONFIG:
            themeConfig.theme = action.theme;
            themeConfig.avatar = action.avatar;
            return themeConfig;
        default:
            return state;
    }
};

export default updateTheme;