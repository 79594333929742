import React from 'react';
import { Card, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';

import lockIcon from '../../assets/lock-icon.svg';

import '../HypeLocked/hype-locked.css';

const HypeLoading = () => {
    return (
        <>
        <div className="affiliate-ig__hype-wrapper-locked">
            <Card.Section>
                <div className="affiliate-tab__row-locked">
                    <div className="affiliate-tab__stats">
                        <div className="affiliate-tab__stat-item">
                            <h2 className="affiliate-tab__stat-num">
                                <SkeletonDisplayText size="small" />
                            </h2>
                            <div className="affiliate-tab__stat-label" style={{ marginTop: '0.6rem' }}>
                                <SkeletonBodyText lines={1} />
                            </div>
                        </div>
                        <div className="affiliate-tab__stat-item">
                            <h2 className="affiliate-tab__stat-num">
                                <SkeletonDisplayText size="small" />
                            </h2>
                            <div className="affiliate-tab__stat-label" style={{ marginTop: '0.6rem' }}>
                                <SkeletonBodyText lines={1} />
                            </div>
                        </div>
                        <div className="affiliate-tab__stat-item">
                            <h2 className="affiliate-tab__stat-num">
                                <SkeletonDisplayText size="small" />
                            </h2>
                            <div className="affiliate-tab__stat-label" style={{ marginTop: '0.6rem' }}>
                                <SkeletonBodyText lines={1} />
                            </div>
                        </div>
                    </div>
                    <div className="hype-locked__disabled-button">
                        
                    </div>
                </div>
            </Card.Section>
            <Card.Section>
                <div className="affiliate-hype__layout">
                    <div className="affiliate-hype__section-audience affiliate-hype__section-audience--flex">
                        <div className="affiliate-hype__locked-col">
                            <div className="affiliate-hype__section-wrapper">
                                <h3 className="affiliate-hype__section-heading"><SkeletonDisplayText size="small" /></h3>
                                <div className="affiliate-hype__locked-section">
                                    <div className="affiliate-hype__locked-top">
                                        <h3 className="affiliate-hype__locked-top-h" style={{ marginLeft: '2rem', width: '140px'}}><SkeletonBodyText lines={1} /></h3>
                                    </div>
                                    <div className="affiliate-hype__locked-items">
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </div>
                                </div>

                                <div className="affiliate-hype__locked-section">
                                    <div className="affiliate-hype__locked-top">
                                        <h3 className="affiliate-hype__locked-top-h" style={{ marginLeft: '2rem', width: '140px'}}><SkeletonBodyText lines={1} /></h3>
                                    </div>
                                    <div className="affiliate-hype__locked-items">
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="affiliate-hype__section-wrapper">
                                <h3 className="affiliate-hype__section-heading"><SkeletonDisplayText size="small" /></h3>
                                <div className="affiliate-hype__locked-section">
                                    <div className="affiliate-hype__locked-top">
                                        <h3 className="affiliate-hype__locked-top-h" style={{ marginLeft: '2rem', width: '140px'}}><SkeletonBodyText lines={1} /></h3>
                                    </div>
                                    <div className="affiliate-hype__locked-items">
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End col 1*/}

                        <div className="affiliate-hype__locked-col">
                            <div className="affiliate-hype__section-wrapper">
                                <h3 className="affiliate-hype__section-heading"><SkeletonDisplayText size="small" /></h3>
                                <div className="affiliate-hype__locked-section">
                                    <div className="affiliate-hype__locked-top">
                                        <h3 className="affiliate-hype__locked-top-h" style={{ marginLeft: '2rem', width: '140px'}}><SkeletonBodyText lines={1} /></h3>
                                    </div>
                                    <div className="affiliate-hype__locked-items">
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </div>
                                </div>

                                <div className="affiliate-hype__locked-section">
                                    <div className="affiliate-hype__locked-top">
                                        <h3 className="affiliate-hype__locked-top-h" style={{ marginLeft: '2rem', width: '140px'}}><SkeletonBodyText lines={1} /></h3>
                                    </div>
                                    <div className="affiliate-hype__locked-items">
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} /> 
                                        </div>
                                        <div className="affiliate-hype__locked" style={{ margin: '0.5rem 2rem' }}>
                                            <SkeletonBodyText lines={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Section>
        </div>
    </>
    )
}

export default HypeLoading;