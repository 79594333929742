import React from 'react';
import { TextField, Checkbox } from '@shopify/polaris';

import { NEXT_APP_SMARTLINK_URL } from '../../../config';

const SmartlinksGeneralSkeleton = () => {
    return (
        <>
            <fieldset>
                <legend>Profile</legend>
                <ul>
                    <li>
                        <p className="photo">
                            <img src="https://dummyimage.com/300/e4e5e7/000&amp;text=loading..." alt="loading..." />
                        </p>
                    </li>
                    <li>
                        <TextField
                            label="Name/Headline"
                            value="loading..."
                            disabled
                        />
                    </li>
                    <li>
                        <TextField
                            label="Bio"
                            multiline={4}
                            value="loading..."
                            disabled
                        />
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>Page Settings</legend>
                <ul>
                    <li>
                        <TextField
                            label="Custom URL Handle"
                            prefix={`${NEXT_APP_SMARTLINK_URL}/`}
                            value="loading..."
                            disabled
                        />
                    </li>
                    <li>
                        <Checkbox
                            label="Display an NSFW content warning"
                            disabled
                        />
                    </li>
                </ul>
            </fieldset>
        </>
    );
};

export default SmartlinksGeneralSkeleton;
