/**
 * Author: Natalie Kappele-Miller (natalie@mobooka.com)
 * App: LLAMA APP
 * Date: November 1, 2021
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './achievement-notification-menu.css';
import { SkeletonBodyText } from '@shopify/polaris';

import { getAchievementNotifications, markAchievementAsSeen, updateAchievementViewStatus } from '../../../store/actions/action_achievement-notifications';

import AchievementNotificationItem from '../items/achievement-notification-item';

/**
 * @function AchievementNotificationMenu
 * Returns affiliate's achievement notfications if they have any in mongo.
 * User also can mark notifications as seen once they close the flyout menu.
 *
 * @param {Object} props - achievementNotifications: [], open: Boolean, affiliate: Object, getAchievementNotifications: Function
 * @returns @Component
 */
const AchievementNotificationMenu = (props) => {
    const [notifications, setNotifications] = useState(props.achievementNotifications);
    const [notificationsLoading, setNotificationsLoading] = useState(false);

    let classes = ['AchievementNotificationMenu', 'AchievementNotificationMenu--closed'];
    if (props.open) {
        classes = ['AchievementNotificationMenu', 'AchievementNotificationMenu--open'];
    }

    useEffect(() => {
        setNotificationsLoading(true);
        props.getAchievementNotifications(props.affiliate.cognito_id)
            .then((response) => {
                setNotifications(response.value.data.getAchievementNotifications);
                setNotificationsLoading(false);
            })
            .catch((error) => {
                console.log('getAchievementNotifications error:', error);
                setNotificationsLoading(false);
            });
    }, []);

    /**
     * @function renderAchievmentNotifications
     * Returns loading state, empty state or AchievementNotificationItems
     * based on notifications, whose state is updated with
     * getAchievementNotifications result
     * @returns @div
     */
    const renderAchievmentNotifications = () => {
        const emptyArray = new Array(4);
        emptyArray.fill(' ');
        if (notificationsLoading) {
            return (
                <div className="AchievementNotificationMenu--loading">
                    {emptyArray.map((item, index) => {
                        return (
                            <div className="AchievementNotificationMenu--loading-notification" key={index}>
                                <h1><SkeletonBodyText lines={1} /></h1>
                                {item}
                            </div>
                        );
                    })}
                </div>
            );
        }
        if (notifications && Array.isArray(notifications) && notifications.length === 0) {
            return (
                <div className="AchievementNotificationMenu--empty">
                    <p>You haven&rsquo;t earned any badges yet.</p>
                </div>
            );
        }
        return (
            <div className="AchievementNotificationMenu__List">
                <ul>
                    {notifications.map((notification) => {
                        return (
                            <AchievementNotificationItem
                                notification={notification}
                                key={notification._id}
                                history={props.history}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    };

    /**
     * @function handleClose
     * Grabs ids of all notifications with 'UNSEEN' as view_status
     * Passes ids and cognito_id to markAchievementAsSeen mutation
     * which will set those notifications to 'SEEN' and close the menu
     */
    const handleClose = () => {
        // Mark notifications as 'SEEN' once the drawer is closed
        const { cognito_id } = props.affiliate;
        if (props.open && cognito_id && props.achievementNotifications) {
            const notification_ids = props.achievementNotifications
                .filter((item) => item.view_status === 'UNSEEN')
                .map((item) => { return item._id });

            if (Array.isArray(notification_ids) && notification_ids.length > 0) {
                // Make the API call to make notifications as SEEN
                markAchievementAsSeen(notification_ids, cognito_id)
                    .then((result) => {
                        const updatedNotifications = [...props.achievementNotifications];
                        result.data.markAchievementAsSeen.forEach((updatedNotification) => {
                            const notificationIndex = updatedNotifications.findIndex(notification => notification._id === updatedNotification._id);
                            updatedNotifications[notificationIndex].view_status = updatedNotification.view_status;
                        });
                        props.updateAchievementViewStatus(updatedNotifications);
                    });
            }

            props.closed(false);
        }
    };

    return (
        <div className={classes.join(' ')}>
            <div className="title-row">
                <h5>Achievements</h5>
                <p className="close"><button onClick={handleClose}>Close</button></p>
            </div>
            {renderAchievmentNotifications()}
        </div>
    );
};

AchievementNotificationMenu.defaultProps = { achievementNotifications: [] };

AchievementNotificationMenu.propTypes = {
    achievementNotifications: PropTypes.arrayOf({
        _id: PropTypes.string,
        badge_img: PropTypes.string,
        badge_title: PropTypes.string,
        date_earned: PropTypes.string,
        badge_type: PropTypes.string,
        view_status: PropTypes.string,
        notification_type: PropTypes.string
    }),
    getAchievementNotifications: PropTypes.func.isRequired,
    closed: PropTypes.bool.isRequired,
    updateAchievementViewStatus: PropTypes.bool.isRequired,
    affiliate: PropTypes.shape({ cognito_id: PropTypes.string }).isRequired
};

const mapStateToProps = (state) => {
    return {
        achievementNotifications: state.achievementNotifications.achievementNotifications
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAchievementNotifications: (id) => { return dispatch(getAchievementNotifications(id)) },
        updateAchievementViewStatus: (notifications) => dispatch(updateAchievementViewStatus(notifications))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AchievementNotificationMenu);
