import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '@shopify/polaris';

import { ChatModule } from 'llama-library/components';

import { changeHeaderTitle } from '../store/actions/header';
import { reconnectIfClosed } from '../store/actions/websocket';
import getChatAdvertisers from '../store/actions/chat/action_getChatAdvertisers';
import getChatMessages, { markChatMessagesAsRead } from '../store/actions/chat/action_chatMessages';
import getChatAdvertiserInfo from '../store/actions/chat/action_getChatAdvertiserInfo';

const Chat = ({ openSocket, affiliate, history, unreadChatMessages, dispatchMarkChatMessagesAsRead, dispatchChangeHeaderTitle, dispatchReconnectIfClosed, match }) => {
    useEffect(() => {
        dispatchChangeHeaderTitle('Chat with Brands');
    }, []);

    return (
        <Page fullWidth>
            <Router>
                <ChatModule
                    senderType="affiliate"
                    sender={{
                        id: affiliate?._id,
                        dbId: affiliate?.cognito_id,
                        photo: affiliate?.photo
                    }}
                    unreadChatMessages={unreadChatMessages}
                    urlRecipient={match?.params?.advertiser_id}
                    history={history}
                    recipientType="advertiser"

                    graphQLActions={{
                        getChatRecipients: {
                            action: getChatAdvertisers,
                            dataObjectName: 'getChatAdvertisers'
                        },
                        getChatMessages,
                        dispatchMarkChatMessagesAsRead,
                        getChatRecipientInfo: {
                            action: getChatAdvertiserInfo,
                            dataObjectName: 'getChatAdvertiserInfo'
                        }
                    }}
                    openSocket={openSocket}
                    reconnectIfClosed={dispatchReconnectIfClosed}
                />
            </Router>
        </Page>
    );
};

Chat.propTypes = {
    unreadChatMessages: PropTypes.arrayOf(PropTypes.shape({
        sender_id: PropTypes.string,
        message_ids: PropTypes.arrayOf(PropTypes.string)
    })).isRequired,
    openSocket: PropTypes.shape({
        addEventListener: PropTypes.func.isRequired,
        send: PropTypes.func.isRequired
    }),
    affiliate: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        cognito_id: PropTypes.string.isRequired,
        photo: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    dispatchChangeHeaderTitle: PropTypes.func.isRequired,
    dispatchMarkChatMessagesAsRead: PropTypes.func.isRequired,
    dispatchReconnectIfClosed: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            advertiser_id: PropTypes.string.isRequired
        }).isRequired
    })
};

Chat.defaultProps = {
    openSocket: null,
    match: null
};

const mapStateToProps = (state) => {
    return {
        openSocket: state.app.openSocket,
        affiliate: state.affiliate,
        unreadChatMessages: state.chat.unreadChatMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchChangeHeaderTitle: (title) => { return dispatch(changeHeaderTitle(title)); },
        dispatchMarkChatMessagesAsRead: (messageIds) => { return dispatch(markChatMessagesAsRead(messageIds)); },
        dispatchReconnectIfClosed: (socket, affiliate_id) => { return dispatch(reconnectIfClosed(socket, affiliate_id)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
