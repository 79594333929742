import axios from 'axios';

import { NEXT_APP_API_URL } from '../../config';

export function getBrandedSignUp(slug) {

    if (!slug) {
        return null;
    }

    const query = `
        query advertiserByBrandedSlug($slug: String!) {
            advertiserByBrandedSlug(slug: $slug) {
                advertiser_id
                name
                terms
                domain
                avatar_image
                timeline_settings {
                    lookback {
                        time
                    }
                    lookforward {
                    time
                    }
                }
                branded_sign_up {
                    slug
                    display_offer_data
                    body
                    banner_image
                    brand_color
                    offer_id
                    offer {
                        name
                        offer_id
                        category
                        rate {
                            type
                            amount
                        }
                        tracking {
                            Stat {
                            epc
                            clicks
                            revenue
                            sale_amount
                            conversions
                            }
                        }
                        long_term_rate{
                            ltv
                            avg_cart_value
                        }
                        reports {
                            ltv
                            cartAvg
                        }
                        products {
                            includeAll
                            includeSome
                            includeProducts
                        }
                        longterm_rate {
                            type
                            amount
                        }
                    }
                }
            }
        }
    `;

    const payload = {
        query,
        variables: { slug }
    };

    const headers = {
        'Content-Type': 'application/json'
    };

    return axios.post(NEXT_APP_API_URL, payload, { headers })
        .then((response) => response.data);
}

export default getBrandedSignUp;
