import { sendGraphQL } from './sendRequest';

export const GET_ACHIEVEMENTS = 'GET_ACHIEVEMENTS';
export const GET_ACHIEVEMENTS_FULFILLED = 'GET_ACHIEVEMENTS_FULFILLED';

export const UPDATE_ACHIEVEMENT_STATUS = 'UPDATE_ACHIEVEMENT_STATUS';
export const UPDATE_ACHIEVEMENT_STATUS_FULFILLED = 'UPDATE_ACHIEVEMENT_STATUS_FULFILLED';

export const getAffAchievements = (cognito_id, group) => {
    const query = `
        query getAffAchievements($cognito_id: String, $group: String) {
            getAffAchievements(cognito_id: $cognito_id, group: $group) {
                _id
                type
                title
                description
                badge_img
                goal_title
                goal_desc
                success_text
                button_text
                button_link
                group
                step
                name
                badge_earned
            }
        }
    `;

    const variables = {
        cognito_id,
        group
    };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_ACHIEVEMENTS,
        payload: sendGraphQL(payload)
            .then((response) => response.data)
    };
};
