import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SkeletonBodyText, Modal, SkeletonThumbnail } from '@shopify/polaris';

import { BadgesGoalList } from 'llama-library/components';

import { getProductSales } from '../store/actions/action_getProductSales';
import { getDashboardData } from '../store/actions/action_getDasboardData';
import { changeHeaderTitle } from '../store/actions/header';
import { getAffAchievements } from '../store/actions/action_achievements';
import { createNewNotification } from '../store/actions/action_achievement-notifications';

import LlamaSpinner from '../components/llama/centered-spinner';

import FeaturedOffers from '../components/dashboard/featured-offers';
import LTVStats from '../components/dashboard/ltv-stats';
import ProductSales from '../components/dashboard/product-sales';

import numberFormat, { moneyFormat } from '../utils/number-format';

import celebrateIcon from '../assets/celebrate-emoji.png';

import './styles/dashboard.css';

export class Dashboard extends Component {
    productSalesPerPage = 5;

    constructor(props) {
        super(props);

        this.state = {
            hasProductSales: false,
            numProductSales: 0,
            loading: true,

            offerStats: {},
            ltvStats: null,
            ltvChartData: [
                {
                    id: 'Repeat Customers',
                    label: 'Repeat Customers',
                    value: 0,
                    color: '#fd2a54'
                },
                {
                    id: 'One-Time Customers',
                    label: 'One-Time Customers',
                    value: 0,
                    color: '#fda63d'
                }
            ],
            featuredOffers: null,
            productSales: null,
            curProductSalesPage: 1,
            productSalesLoading: false,

            chartOptions: {
                legendHeight: 25,
                margin: { bottom: 25 },
                legendItemHeight: 15,
                legendDirection: 'row',
                legendAnchor: 'bottom'
            },

            group: 'affiliate-onboard',
            currentAchievements: null,
            achievementsLoading: false,
            isModalActive: false,
            hasEarnedAllBadges: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.adjustForRepsonsive);

        const { affiliate } = this.props;
        const { cognito_id } = affiliate;

        const productSales = this.props.getProductSales(cognito_id, 1, this.productSalesPerPage);
        const dashboardData = this.props.getDashboardData(cognito_id);

        Promise.all([productSales, dashboardData])
            .then((result) => {
                const {
                    productData,
                    pageInfo,
                    totalSales
                } = result[0].value.data.affiliateById.recentProductSales;
                const {
                    ltvStats,
                    featuredOffers
                } = result[1].value.data.affiliateById;

                let { offerStats } = result[1].value.data.affiliateById;
                if (!offerStats) {
                    offerStats = {
                        clicks: 0,
                        conversions: 0,
                        payout: 0
                    };
                }
                offerStats.revenue = totalSales;
                offerStats.epc = (offerStats.clicks > 0) ? totalSales / offerStats.clicks : 0;

                const chartData = [...this.state.ltvChartData];
                ltvStats.forEach((stat) => {
                    chartData[0].value += parseInt(stat.repeatedCustomers, 10);
                    chartData[1].value += parseInt(stat.oneTimeCustomers, 10);
                });

                this.setState({ achievementsLoading: true });

                // Get onboarding achievements from mongo
                this.props.getAffAchievements(cognito_id, this.state.group)
                    .then((achievementResult) => {
                        if (achievementResult) {
                            console.log('getAffAchievements achievementResult:', achievementResult)
                            this.setState({
                                achievementsLoading: false,
                                currentAchievements: achievementResult.value.data.getAffAchievements
                            });
                            this.getBadgeCompletionStatus();
                            this.getModalSeenStatus();
                        }
                    })
                    .catch((error) => {
                        this.setState({ achievementsLoading: false });
                        console.log('getAffAchievements error:', error)
                    });

                this.setState({
                    productSales: productData,
                    hasProductSales: (pageInfo.count > 0),
                    numProductSales: pageInfo.count,
                    offerStats,
                    ltvStats,
                    ltvChartData: chartData,
                    featuredOffers,
                    loading: false
                }, () => {
                    this.adjustForRepsonsive();
                });
            });

        this.props.changeHeaderTitle('Dashboard');
    }

    adjustForRepsonsive = () => {
        let chartOptions = {
            legendHeight: 0,
            legendItemHeight: 20,
            legendDirection: 'column',
            legendAnchor: 'right'
        };

        if (window.innerWidth <= 375) {
            chartOptions = {
                ...chartOptions,
                margin: { left: -120 }
            };
        } else if (window.innerWidth <= 1020) {
            chartOptions = {
                ...chartOptions,
                margin: { left: -160 }
            };
        } else if (window.innerWidth <= 1190) {
            chartOptions = {
                ...chartOptions,
                margin: { bottom: 50 },
                legendHeight: 50,
                legendAnchor: 'bottom'
            };
        } else {
            chartOptions = {
                margin: { bottom: 25 },
                legendHeight: 25,
                legendItemHeight: 15,
                legendDirection: 'row',
                legendAnchor: 'bottom'
            };
        }

        this.setState({ chartOptions });
    }

    // handle product sales pagination
    loadProductSales = (page) => {
        // scroll to top of product sales when changing page
        const section = document.getElementsByClassName('dashboard--product-sales')[0];
        window.scrollTo(0, section.offsetTop - 30);

        this.setState({
            productSalesLoading: true
        });
        const { cognito_id } = this.props.affiliate;

        this.props.getProductSales(cognito_id, page, this.productSalesPerPage)
            .then((result) => {
                this.setState({
                    productSales: result.value.data.affiliateById.recentProductSales.productData,
                    productSalesLoading: false,
                    curProductSalesPage: page
                });
            });
    }

    goToOffer = (offerId, discoverMode = false) => {
        if (discoverMode) {
            this.props.history.push(`/discover/offer/${offerId}`);
        } else {
            this.props.history.push(`/offer/${offerId}`);
        }
    }

    // Determine if user has completed all badges.
    getBadgeCompletionStatus = () => {
        if (
            this.state.currentAchievements
            && Array.isArray(this.state.currentAchievements)
            && this.state.currentAchievements.length > 0
        ) {
            const badgesEarned = this.state.currentAchievements.filter((achievement) => {
                return achievement.badge_earned !== null;
            });
            if (badgesEarned.length === this.state.currentAchievements.length) {
                this.setState({ hasEarnedAllBadges: true, isModalActive: true });
                createNewNotification(this.props.affiliate.cognito_id)
                    .then((result) => {
                        console.log('createNewNotification result:', result);
                    });
            }
        }
    }

    // Once user has SEEN onboarding complete notification,
    // hide the Congrats Modal
    getModalSeenStatus = () => {
        if (
            this.props.achievementNotifications.achievementNotifications 
            && this.props.achievementNotifications.achievementNotifications.length > 0
        ) {
            const hasSeenComplete = this.props.achievementNotifications.achievementNotifications.find((notification) => {
                return notification.notification_type === 'group_complete' && notification.view_status === 'SEEN';
            });
            if (hasSeenComplete) {
                this.setState({ isModalActive: false });
            }
        }
    }

    // Success Modal toggle
    handleModal = () => {
        this.setState({ isModalActive: !this.state.isModalActive });
    }

    // Loading achievements
    renderLoadingAchievements = () => {
        const emptyArray = new Array(4);
        emptyArray.fill(' ');
        return (
            <div className="dashboard--badges-loading">
                <div className="loading-row">
                    {emptyArray.map((item, index) => {
                        return (
                            <div className="loading-badge" key={index}>
                                <SkeletonThumbnail />
                                <h1><SkeletonBodyText lines={2} /></h1>
                                {item}
                            </div>
                        );
                    })}
                </div>
                <div className="loading-steps">
                    {emptyArray.map((item, index) => {
                        return (
                            <div className="loading-step" key={index}>
                                <SkeletonBodyText lines={2} />
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    render() {
        const { offerStats, currentAchievements, achievementsLoading, group, isModalActive, hasEarnedAllBadges } = this.state;
        const { affiliate } = this.props;

        // Object that will contain all success messages
        const successMessages = { 'affiliate-onboard': `Nice job, ${affiliate.name}! You earned the Onboarding Bonus for completing all four badges.` };

        return (
            <main className="dashboard" data-test="dashboard-container" data-has-product-sales={this.state.hasProductSales}>
                {!hasEarnedAllBadges && (
                    <section className="dashboard--badges">
                        <h2>Get the most from Llama by completing your profile</h2>
                        <p className="info">Unlock all four onboarding badges to <strong>earn a special reward</strong>.</p>
                        {achievementsLoading ? (
                            this.renderLoadingAchievements()
                        ) : (
                            currentAchievements && <BadgesGoalList badgeGoalListData={currentAchievements} />
                        )}
                    </section>
                )}
                <section className="dashboard--offer-stats" data-test="offer-stats-container">
                    <h2 data-test="offer-stats-title">Offer Stats</h2>
                    <p className="info" data-test="offer-stats-info">Here&rsquo;s a quick look at how all your offers have performed in the past 7 days.</p>
                    <dl data-test="offer-stats-attributes">
                        <dt className="metric">Clicks</dt>
                        <dd className="metric-value">{
                            offerStats && offerStats.clicks !== undefined ?
                                numberFormat(offerStats.clicks) :
                                <SkeletonBodyText lines="1" />
                        }</dd>
                        <dt className="metric">Conversions</dt>
                        <dd className="metric-value">{
                            offerStats && offerStats.conversions !== undefined ?
                                numberFormat(offerStats.conversions) :
                                <SkeletonBodyText lines="1" />
                        }</dd>
                        <dt className="metric">Revenue</dt>
                        <dd className="metric-value">{
                            offerStats && offerStats.payout !== undefined ?
                                moneyFormat(offerStats.revenue, true) :
                                <SkeletonBodyText lines="1" />
                        }</dd>
                        <dt className="metric">EPC</dt>
                        <dd className="metric-value">{
                            offerStats && offerStats.epc !== undefined ?
                                moneyFormat(offerStats.epc, true) :
                                <SkeletonBodyText lines="1" />
                        }</dd>
                    </dl>
                </section>

                {this.state.loading && <LlamaSpinner />}

                {!this.state.loading && this.state.ltvStats && this.state.ltvStats.length > 0
                    && (
                        <LTVStats
                            chartData={this.state.ltvChartData}
                            chartOptions={this.state.chartOptions}
                            stats={this.state.ltvStats}
                        />
                    )
                }
                {!this.state.loading && this.state.productSales && this.state.hasProductSales
                    && (
                        <ProductSales
                            goToOffer={this.goToOffer}
                            sales={this.state.productSales}
                            numSales={this.state.numProductSales}
                            curPage={this.state.curProductSalesPage}
                            loadSales={this.loadProductSales}
                            loading={this.state.productSalesLoading}
                            perPage={this.productSalesPerPage}
                        />
                    )
                }
                {!this.state.loading && this.state.featuredOffers && this.state.featuredOffers.length > 0
                    && <FeaturedOffers offers={this.state.featuredOffers} />
                }
                <Modal
                    open={isModalActive}
                    onClose={this.handleModal}
                >
                    <Modal.Section>
                        <div className="dashboard--success-modal">
                            <img alt="celebrate" src={celebrateIcon} />
                            <h1>Congratulations!</h1>
                            <p>{successMessages[group]}</p>
                        </div>
                    </Modal.Section>
                </Modal>
            </main>
        );
    }
}

Dashboard.propTypes = {
    affiliate: PropTypes.shape({
        cognito_id: PropTypes.string,
        name: PropTypes.string
    }).isRequired,
    getProductSales: PropTypes.func.isRequired,
    getDashboardData: PropTypes.func.isRequired,
    changeHeaderTitle: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

const mapStateToProps = (state) => {
    return {
        ...state,
        achievements: state.achievements.achievements
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDashboardData: (id) => { return dispatch(getDashboardData(id)); },
        getProductSales: (id, page, per_page) => { return dispatch(getProductSales(id, page, per_page)); },
        changeHeaderTitle: (title) => { return dispatch(changeHeaderTitle(title)); },
        getAffAchievements: (id, group) => { return dispatch(getAffAchievements(id, group)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
