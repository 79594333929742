import React from 'react';

import {
    AuthPiece,
    FormSection,
    SectionHeader,
    SectionBody,
    Button,
} from 'aws-amplify-react';

class LlamaInitalLogin extends AuthPiece {

    render() {

        const { theme, email } = this.props;

        return (
            // TODO: Remove this component? Currently not being used by the application.
            <FormSection theme={theme}>
                <SectionHeader theme={theme}>Thank you for installing Llama!</SectionHeader>
                <SectionBody theme={theme}>
                    <p className="amplify-paragraph">
                        Our herd of llamas are working hard preparing your account.
                    </p>
                    <p className="amplify-paragraph">
                        Your username and password have been sent to the email address associated with Shopify: <br />
                        <span style={{ fontWeight: 'bold' }} className="amplify-accent">{` ${email}`}</span>
                    </p>
                    <div className="amplify-button-wrapper">
                        <Button theme={theme} onClick={() => window.location.href = `https://merchants.llama.app/?email=${email}`}>Sign In Now</Button>
                    </div>
                </SectionBody>
            </FormSection>
        )
    }

}

export default LlamaInitalLogin;