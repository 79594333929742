import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SkeletonBodyText } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { NEXT_APP_SMARTLINK_URL } from '../../config';

import SmartLinksURLForm from './smartlinks-url-form';

import './smartlinks-url-actions.css';
import defaultImage from '../../assets/smartlink-img/user-icon.png';

const SmartLinksURLActions = (props) => {
    const {
        smartlinks, currentHandle, loading, useModalPreview,
        setToastVerbiage, togglePreview, onSaved
    } = props;

    const avatar = smartlinks.customizations.avatarUrl || defaultImage;
    const { domains, bioLink } = smartlinks;

    const [editMode, toggleEditMode] = useState(false);
    const [saving, setSaving] = useState(false);
    const [url, setUrl] = useState(bioLink);

    useEffect(() => {
        if (!loading) {
            if (bioLink) {
                setUrl(bioLink);
            } else {
                setUrl(`${NEXT_APP_SMARTLINK_URL}/${currentHandle}`);
            }
        }
    }, [loading]);

    const copyURL = () => {
        let copyToClipboard;
        if (navigator.clipboard) {
            copyToClipboard = navigator.clipboard.writeText(url);
        } else {
            // For versions of Safari older than 13
            const textArea = document.createElement('textarea');
            textArea.value = url;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            copyToClipboard = new Promise((resolve, reject) => {
                const copySuccess = document.execCommand('copy');
                if (copySuccess) {
                    resolve('Resolved');
                } else {
                    reject(Error('Unable to execute copy command'));
                }
            });

            document.body.removeChild(textArea);
        }

        copyToClipboard
            .then(() => {
                setToastVerbiage('Copied to clipboard.');
            })
            .catch(() => {
                setToastVerbiage('Oops, something went wrong. Unable to copy link.');
            });
    };

    const saveURL = (customId) => {
        toggleEditMode(false);
        onSaved(customId);
    };

    const renderLink = () => {
        if (editMode) {
            return (
                <SmartLinksURLForm
                    currentHandle={currentHandle}
                    domains={domains}
                    bioLink={bioLink}
                    saving={saving}
                    setSaving={setSaving}
                    onDone={saveURL}
                    setUrl={setUrl}
                />
            );
        }

        return (
            <>
                <p className="url">
                    <span className="connected">
                        <a target="_blank" rel="noreferrer noopener" href={url}>{url}</a>

                        <span className="button-group">
                            <LlamaButton
                                onClick={() => { return toggleEditMode(true); }}
                                classes={['edit']}
                            >
                                Edit
                            </LlamaButton>
                            <LlamaButton
                                onClick={copyURL}
                                classes={['copy']}
                            >
                                Copy
                            </LlamaButton>
                        </span>
                    </span>
                </p>
            </>
        );
    };

    return (
        <aside className="smartlinks-url-actions">
            <p className="avatar"><img src={avatar} alt="" /></p>
            <div className="url-wrapper">
                <p className="url-heading">Your SmartLinks Page</p>
                {loading
                    ? <SkeletonBodyText lines="1" />
                    : renderLink()
                }
            </div>
            {!loading && !editMode && useModalPreview
                && (
                    <p className="load-preview">
                        <LlamaButton
                            className="secondary"
                            onClick={togglePreview}
                        >
                            Preview
                        </LlamaButton>
                    </p>
                )
            }
        </aside>
    );
};

SmartLinksURLActions.propTypes = {
    smartlinks: PropTypes.shape({
        customizations: PropTypes.shape({
            avatarUrl: PropTypes.string
        }),
        domains: PropTypes.arrayOf(PropTypes.string),
        bioLink: PropTypes.string
    }).isRequired,
    currentHandle: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    useModalPreview: PropTypes.bool.isRequired,
    setToastVerbiage: PropTypes.func.isRequired,
    togglePreview: PropTypes.func.isRequired,
    onSaved: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        smartlinks: state.smartlinks
    };
};

export default connect(mapStateToProps)(SmartLinksURLActions);
