import { GET_ACHIEVEMENTS_FULFILLED } from '../actions/action_achievements';

const defaultState = { achievements: [] };

const achievementsReducer = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ACHIEVEMENTS_FULFILLED: {
            if (
                !payload
                || !payload.data
                || !payload.data.getAffAchievements
                || !Array.isArray(payload.data.getAffAchievements)
            ) {
                return state;
            }
            return {
                ...state,
                achievements: payload.data.getAffAchievements
            };
        }

        default: {
            return state;
        }
    }
};

export default achievementsReducer;
