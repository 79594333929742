import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NotificationsMenuItem from '../items/notifications-menu-items';
import { markNotificationsAsSeen, updateNotificationsViewStatus } from '../../../store/actions/action_getNotifications';
import './notifications-sideMenu.css';
import { EmptyState } from 'llama-library/components';

export const NotificationsMenu = (props) => {

    let attachedClasses = ["NotificationsSideDrawer", "NotificationsSideDrawer--close"];
    let backgroundClasses = ["NotificationsSideDrawer__Overlay"]
    if (props.open) {
        attachedClasses = ["NotificationsSideDrawer", "NotificationsSideDrawer--open"]
        backgroundClasses = ["NotificationsSideDrawer__Overlay", "NotificationsSideDrawer__Overlay--show"]
    }

    const [showNotifications, setShowNotifications] = useState(props.open);
    const [animationTimeout, setAnimationTimeout] = useState(null);

    useEffect(() => {

        // Display notifications if side drawer is open, no need to delay rendering
        // like we do on close. (see below)
        if (props.open) {
            //prevent blank screen if someone clicks faster than the timeout on mobile
            clearTimeout(animationTimeout)
            setShowNotifications(true);
        }

        // Stop rendering of notifications after the animation of the side drawer
        // has completed.
        if (!props.open) {
            const animTimeout = setTimeout(() => {
                setShowNotifications(false);
                clearTimeout(animationTimeout)
            }, 300)

            setAnimationTimeout(animTimeout)
        }

    }, [props.open, props.affiliate.cognito_id])

    const handleClose = () => {
        // Mark notifications as 'SEEN' once the drawer has been closed.
        const { cognito_id } = props.affiliate;

        if (props.open && cognito_id && props.affiliate.notifications) {
            const notifications_ids = props.affiliate.notifications
                .filter((item) => item.view_status === 'UNSEEN')
                .map((item) => { return item._id })

            // Don't send API call if there are no notifications to update as 'SEEN'.
            if (Array.isArray(notifications_ids) && notifications_ids.length > 0) {
                const recipient = {
                    recipient_type: "AFFILIATE",
                    recipient_id: cognito_id,
                }

                //update the ui when done
                markNotificationsAsSeen(notifications_ids, recipient)
                    .then(result => {
                        //update the redux store so the ui can reflect the changes
                        const updatedNotifications = [...props.affiliate.notifications];
                        result.data.markNotificationsAsSeen.forEach(updatedNotification => {
                            const notificationIndex = updatedNotifications.findIndex(notification => notification._id === updatedNotification.notification_id);
                            updatedNotifications[notificationIndex].view_status = updatedNotification.view_status
                        })
                        props.updateNotificationsViewStatus(updatedNotifications)
                    })
            }            

            props.closed(false)
        }
    }

    const renderNotificationItems = () => {

        if (!showNotifications) {
            return null;
        }

        if (props.affiliate.notifications) {
            return (
                <ul>
                    {props.affiliate.notifications.map((item) => {
                        return <NotificationsMenuItem
                            notification={item}
                            history={props.history}
                            key={item._id}
                            handleClose={handleClose}
                        />
                    })}
                </ul>
            )
        }else{
            return (
                <EmptyState
                    message={"You don't have any notifications."}
                />
            )
        }
    }

    return (
        <div data-test="component-notificationsmenu">
            {!props.isMobile && <div className={backgroundClasses.join(" ")} onClick={handleClose}> </div>}
            <div className={attachedClasses.join(" ")}>
                <div className="NotificationsSideDrawer__Header">
                    <h5>Notifications</h5>
                    <p className="close"><button onClick={handleClose}>Close</button></p>
                </div>
                {renderNotificationItems()}
            </div>
        </div>
    )
}

const mapStateToProps = ({ affiliate }) => {
    return { affiliate }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateNotificationsViewStatus: (notifications) => dispatch(updateNotificationsViewStatus(notifications))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMenu);
