import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Spinner, Select } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { checkSmartLinkCustomId } from '../../store/actions/action_checkSmartLinkCustomId';
import { updateSmartLink } from '../../store/actions/action_updateSmartlink';

import { NEXT_APP_SMARTLINK_URL } from '../../config';

import './smartlinks-url-form.css';

const SmartLinksURLForm = (props) => {
    console.log('SmartLinksURLForm PROPS', props);
    const { affiliate, smartlinks, dispatchCheckCustomId, dispatchUpdateSmartLink, setSaving, saving, onDone, setUrl, submitRef, fromOnboarding } = props;

    const { current_handle, domains, bioLink } = smartlinks;

    const [customIdAvailability, setCustomIdAvailability] = useState(null);
    const [call, setCall] = useState(null);
    const [customIdWait, setCustomIdWait] = useState('');
    const [selectedDomain, setSelectedDomain] = useState('');
    const [customId, setCustomId] = useState(current_handle);

    useEffect(() => {
        if (bioLink) {
            const domainMatch = domains.find((domain) => {
                const regex = new RegExp(`^https?://${domain}`);
                return regex.test(bioLink);
            });
            setSelectedDomain(domainMatch);
        } else {
            setSelectedDomain(NEXT_APP_SMARTLINK_URL.replace('https://', ''));
        }
    }, []);

    const handleDomainChange = (domain) => {
        const domainName = domain.replace(/^https:\/\/(.+)\//, '$1');
        setSelectedDomain(domainName);
    };

    // https://stackoverflow.com/a/56474891
    const makeCancelable = (promise) => {
        let rejectFn;

        const wrappedPromise = new Promise((resolve, reject) => {
            rejectFn = reject;

            Promise.resolve(promise)
                .then(resolve)
                .catch(reject);
        });

        wrappedPromise.cancel = () => {
            rejectFn({ canceled: true });
        };

        return wrappedPromise;
    };

    const handleCustomIdChange = (value) => {
        const cleanValue = value.replace(/[^\w-]+/g, '');
        setCustomId(cleanValue);

        // if the user presses too slowly and a database promise was attempted, cancel it
        if (call != null) {
            call.cancel();
        }
        if (customIdWait !== '') {
            clearTimeout(customIdWait);
        }

        // give the user half a second to finish typing before checking the database for availability
        setCustomIdWait(setTimeout(() => {
            setCustomIdWait('');
            setCustomIdAvailability('loading');

            setCall(makeCancelable(
                dispatchCheckCustomId(affiliate.cognito_id, cleanValue).then((result) => {
                    setCustomIdAvailability(result.value.data.checkCustomId.is_available);
                    clearTimeout(customIdWait);
                })
            ));
        }, 500));
    };

    const saveCustomId = async () => {
        setSaving(true);
        const payload = { custom_id: customId };

        if (selectedDomain) {
            payload.bioLink = `https://${selectedDomain}/${customId}`;
        }
        await dispatchUpdateSmartLink(affiliate.cognito_id, payload);
        setUrl(`https://${selectedDomain}/${customId}`);

        onDone(customId);
        setCustomIdAvailability(null);
        setSaving(false);
    };

    if (submitRef) {
        submitRef.current = saveCustomId;
    }

    const handleSaveCustomId = (e) => {
        e.preventDefault();
        saveCustomId();
    };

    return (
        <form className="smartlinks-url-form" onSubmit={handleSaveCustomId}>
            <div className="textfield-wrapper">
                <TextField
                    label={fromOnboarding ? 'SmartLinks Page URL' : null}
                    value={customId}
                    onChange={handleCustomIdChange}
                    disabled={saving}
                    connectedLeft={(
                        <Select
                            options={domains.map((domain) => { return `https://${domain}/`; })}
                            labelInline
                            value={`https://${selectedDomain}/`}
                            onChange={handleDomainChange}
                        />
                    )}
                />
                {customIdAvailability !== null && customIdAvailability !== 'loading'
                    && <span className="availability" data-is-available={customIdAvailability}>{customIdAvailability ? 'Available' : 'Taken'}</span>
                }
                {customIdAvailability === 'loading' && <Spinner size="small" />}
            </div>
            {customIdAvailability !== 'loading' && <LlamaButton type="submit" disabled={saving} loading={saving}>Save</LlamaButton>}
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate,
        smartlinks: state.smartlinks
    };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        dispatchCheckCustomId: (cognito_id, custom_id) => { return dispatch(checkSmartLinkCustomId(cognito_id, custom_id)); },
        dispatchUpdateSmartLink: (cognito_id, data) => { return dispatch(updateSmartLink(cognito_id, data)); }
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLinksURLForm);
