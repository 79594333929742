export const UPDATE_THEME_CONFIG = 'UPDATE_THEME_CONFIG';

export function updateTheme(theme, avatar) {
    const action = {
        type: UPDATE_THEME_CONFIG,
        theme,
        avatar
    }
    return action;
}

