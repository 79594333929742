/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */

import {sendGraphQL} from "./sendRequest";

export function getProductSales(cognito_id, page, per_page){
    const options = {
        skip: (page - 1) * per_page,
        limit: per_page
    }
    console.log(page, per_page, options);

    const query = `
        query getRecentProductSales($cognito_id: String, $options: AffiliateQueryOptions) {
            affiliateById(cognito_id: $cognito_id) {
                cognito_id
                name
                email
                tracking_id
                recentProductSales(options: $options) {
                    productData{
                        product_id
                        product_name
                        product_image
                        affiliate_id
                        advertiser_id
                        company
                        offer_id
                        offer_name
                        quantity
                        total_price
                    }
                    totalSales
                    pageInfo{
                        count
                    }
                }
            }
        }
    `;

    const variables = {
        cognito_id,
        options
    }

    const payload = {
        query,
        variables
    }

    return {
        type: 'GET_PRODUCT_SALES',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* const headers = {
        'Content-Type': 'application/json'
    }

    return {
        type: 'GET_PRODUCT_SALES',
        payload: axios.post(NEXT_APP_API_URL, payload, {headers})
            .then(response => response.data)
    } */
}