import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Polaris Components
import { Icon } from '@shopify/polaris';

// Polaris Icons
import { ExternalSmallMinor } from '@shopify/polaris-icons';

// Llama Library Components
import { LlamaPagination, EmptyState, PlaceholderPhoto } from 'llama-library/components';

// Config
import { PER_PAGE } from '../../config';

// Hooks
import usePagesMerchantOffers from '../../hooks/use-merchant-offers';

// Components
import LlamaSpinner from '../llama/centered-spinner';
import OfferPreviewCard from '../DiscoverOffers/offer-preview-card';

import './DiscoverMerchants.css';

const renderMerchantInfo = (merchantOffers) => {
    const advertiserOffer = merchantOffers[0];
    const { advertiser } = advertiserOffer;
    const { avatar_image, description, domain, name } = advertiser;
    return (
        <div className="DiscoverMerchants__TopBanner">
            <div className="DiscoverMerchants__MerchantInfo">
                <div className="DiscoverMerchants__MerchantLogo">
                    <PlaceholderPhoto photo={avatar_image} alt={`${name}’s logo`} />
                </div>
                <div className="DiscoverMerchants__MerchantContent">
                    <p className="DiscoverMerchants__MerchantName">{name}</p>
                    <p className="DiscoverMerchants__MerchantDesc">{description}</p>
                    <a href={`//${domain}`} target="_blank" rel="noreferrer noopener">View Store<Icon source={ExternalSmallMinor} /></a>
                </div>
            </div>
            <h1 className="DiscoverMerchants__Header">{`${name}'s current offers`}</h1>
        </div>
    );
};

const DiscoverMerchants = ({ affiliate, initialPage, applicationsLoaded, history, ...props }) => {

    const [isOfferLoaded, setIsOfferLoaded] = useState(false);
    const [toastVerbiage, setToastVerbiage] = useState(null);

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        offers
    } = usePagesMerchantOffers(initialPage, PER_PAGE.OFFERS, props, setIsOfferLoaded);

    props.setInitialPage(currentPage);

    if (!isOfferLoaded) {
        // Scroll back up to the top of the page
        // whenever something triggers new offers to load in
        window.scrollTo(0, 0);
        return (
            <div className="discover-offer-loading">
                <div className="discover-offer__loading">
                    <LlamaSpinner />
                </div>
            </div>
        );
    }

    // If filtered offers array is zero return empty state
    if (offers.length <= 0 && isOfferLoaded) {
        return (
            <EmptyState
                message="Sorry, I couldn’t find offers for that brand"
                paragraph="Try finding a brand from a different offer"
                ctaText="Discover Offers"
                ctaAction={() => history.push('/discover')}
            />
        );
    }

    return (
        <div className="DiscoverMerchants__Wrapper" data-test="component-discoverMerchants">
            {renderMerchantInfo(offers)}
            <ul className="discover-offers__main-wrapper">
                {offers.map((offer) => {
                    const { totalOffers } = offer;
                    const application = Array.isArray(affiliate.applications)
                        ? affiliate.applications.find((affiliateApplication) => {
                            return affiliateApplication.offer.offer_id === offer.offer_id;
                        })
                        : null;

                    return (
                        <li key={offer.offer_id}>
                            <OfferPreviewCard
                                offer={offer}
                                totalOffers={totalOffers}
                                application={application}
                                applicationsLoaded={applicationsLoaded}
                                history={history}
                                setToastVerbiage={setToastVerbiage}
                            />
                        </li>
                    );
                })}
            </ul>
            {maxPages > 1 && (
                <div className="discover-offer__pagination">
                    <LlamaPagination
                        hasNext={hasNext}
                        hasPrevious={hasPrevious}
                        onNext={nextPage}
                        onPrevious={previousPage}
                        currentPage={currentPage - 1}
                        maxPages={maxPages - 1}
                        data-test="discover-pagination"
                    />
                </div>
            )}
        </div>
    );
};

DiscoverMerchants.propTypes = {
    affiliate: PropTypes.shape({
        applications: PropTypes.arrayOf(PropTypes.shape({
            offer: PropTypes.shape({
                offer_id: PropTypes.string })
        }))
    }).isRequired,
    applicationsLoaded: PropTypes.bool.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    initialPage: PropTypes.number.isRequired
};

export default DiscoverMerchants;
