export const RateTypes = {
    FLAT_ORDER: 'FLAT_ORDER',
    FLAT_ITEM: 'FLAT_ITEM',
    PERCENT_REVENUE: 'PERCENT_REVENUE'
};

export const ApplicationStatus = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    DENIED: 'DENIED'
};

export const NotificationViewStatus = {
    SEEN: 'SEEN',
    UNSEEN: 'UNSEEN'
};

export const AdvertiserStatus = {
    ACTIVE: 'ACTIVE',
    TRIAL: 'TRIAL',
    EXPIRED: 'EXPIRED',
    UNINSTALLED: 'UNINSTALLED',
    PENDING: 'PENDING',
    INACTIVE: 'INACTIVE'
};

export const AchievementViewStatus = {
    SEEN: 'SEEN',
    UNSEEN: 'UNSEEN'
};
