import React from 'react';
import moment from 'moment';
import { NotificationViewStatus } from '../../../utils/types';

import smartlinksIcon from '../../../assets/smartlinks-icon.jpg';
import llamaIcon from '../../../assets/llama-icon.png';
import twitterIcon from '../../../assets/notifications/twitter-icon.svg';
import facebookIcon from '../../../assets/notifications/facebook-icon.svg';
import instagramIcon from '../../../assets/notifications/instagram-icon.png';
import youtubeIcon from '../../../assets/notifications/youtube-icon.svg';
import tiktokIcon from '../../../assets/notifications/tiktok-icon.svg';

import './notifications-menu-items.css';

const notificationPhoto = (type, initialImage) => {
    if (type === 'smartlinks') {
        return smartlinksIcon;
    }
    if (type === 'llama') {
        return llamaIcon;
    }
    if (type === 'twitter') {
        return twitterIcon;
    }
    if (type === 'facebook') {
        return facebookIcon;
    }
    if (type === 'instagram') {
        return instagramIcon;
    }
    if (type === 'youtube') {
        return youtubeIcon;
    }
    if (type === 'tiktok') {
        return tiktokIcon;
    }
    return initialImage;
};

const NotificationsMenuItem = (props) => {

    const { notification } = props;

    if (!notification) {
        return null;
    }

    const navigateToDestination = notification.destination_link
        ? () => { 
            props.handleClose()
            props.history.push(notification.destination_link) 
        }
        : () => null;

    const notificationClasses = ['NotificationMenuItem'];
    const indicatorClasses = ['NotificationMenuItem__Indicator']
    if (notification.view_status === NotificationViewStatus.UNSEEN) {
        notificationClasses.push('NotificationMenuItem--active');
        indicatorClasses.push('NotificationMenuItem__Indicator--active')
    }

    let timeAgo = null;
    if (notification.ttl && notification.ttl.start) {
        timeAgo = new Date(notification.ttl.start).getTime();
        timeAgo = moment(timeAgo).fromNow();
    }
    const message = notification.message.replace(/<(?!\/?strong).+?>/g); // remove any tags that are not strong tags
    let image = notificationPhoto(notification.image.type, notification.image.source);
    if (notification.image.id === 'LLAMA') {
        image = llamaIcon;
    }

    return (
        <li data-test="component-notificationMenuItem" data-unread={notification.view_status === NotificationViewStatus.UNSEEN} data-clickable={!notification.destination_link ? false : true} onClick={navigateToDestination}>
            <span className="notification--image" data-icon={notification.image.icon}>
                <img src={image} alt=""/>
            </span>
            <span className="notification--message" data-test="span-notificationMenuItemMessage">
                <span dangerouslySetInnerHTML={{__html: message}} />
                <time data-test="span-notificationMenuItemTime" dateTime={notification.ttl.start}>{timeAgo}</time>
            </span>
        </li>
    )
}
export default NotificationsMenuItem;
