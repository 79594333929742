import React from 'react';
import { connect } from 'react-redux';
import { CardElement, injectStripe } from 'react-stripe-elements';
import {
    Form, FormLayout, TextField, Modal, TextContainer, Checkbox, Button, RadioButton, Banner, InlineError, Toast, Frame
} from '@shopify/polaris';
import { getSmartLinks } from '../../store/actions/action_getSmartLinks';
import { createSmartlinkSubscription, reapplySmartlinksCustomTheme } from '../../store/actions/action_createSmartlinkSubscription';
import { applyPromoCode } from '../../store/actions/smartlinks/action_updatePromoCode';
import LlamaTos from '../llama-tos/llama-tos';
import { LlamaButton } from 'llama-library/components';

//import './smart-link-setting.css';
import './smart-link-subscription.css';

import defaultImage from '../../assets/smartlink-img/user-icon.png';


class SmartLinkSubscription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stripe: null,
            plan: '',
            errors: {},
            termsAgreement: false,
            showConfirmation: false,
            displayTos: false,
            submitAttempted: false,
            generalError: null,
            loading: false,
            toastVerbiage: null,
            promoCode: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.location.plan) {
            let plan = (props.location.plan !== '') ? props.location.plan : state.plan;

            return {
                plan: plan
            };
        }

        return null;
    }

    componentDidMount() {
        let bodyClass = (this.props.location)
            ? (this.props.location.pathname === '/smartlinks/upgrade/pro' ? 'subscription-fullpage' : 'body-scroll')
            : 'body-scroll';

        this.getSmartLinks('');
        document.body.classList.add(bodyClass);
    }

    componentWillUnmount() {
        document.body.classList.remove('subscription-fullpage');
    }

    getSmartLinks = (filter) => {
        this.props.getSmartLinks(this.props.authData.username, filter)
            .then((result) => {
                if (
                    !result
                    || !result.value
                    || !result.value.data
                    || !result.value.data.getSmartLinks
                    || !Array.isArray(result.value.data.getSmartLinks.links)
                ) {
                    return;
                }

                this.setState({
                    plan: result.value.data.getSmartLinks.settings.subscription.plan
                });

            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    handleSubmit = async (event) => {
        try {
            event.preventDefault();

            this.setState({submitAttempted: true})
            const hasFormErrors = this.validateForm();

            if(!hasFormErrors){
                if (!this.props.authData.username || !this.state.plan) {
                    this.setState({
                        generalError: 'There was a problem submitting the form. Don\'t worry, your card has not been charged yet.'
                    })
                    return;
                }else{
                    this.setState({loading: true})
                }
                const stripeTokenData = await this.props.stripe.createToken({});

                if (stripeTokenData.error && (!stripeTokenData.token || !stripeTokenData.token.id)) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            card: stripeTokenData.error.message
                        },
                        loading: false
                    })
                    return;
                }
                if(this.state.plan && this.state.plan !== 'trial'){
                    this.setState({
                        generalError: 'You\'re already subscribed to SmartLinks PRO.',
                        loading: false
                    })
                    return;
                }

                const last4 = (stripeTokenData.token.card && stripeTokenData.token.card.last4) ? stripeTokenData.token.card.last4 : '';
                const response = await this.props.createSmartlinkSubscription(this.props.authData.username, 'plan_pro', stripeTokenData.token.id, last4);

                if(response.value.errors && response.value.errors[0].message){
                    this.setState({
                        generalError: response.value.errors[0].message,
                        loading: false
                    })
                    return;
                }

                if (response.value.data.createSmartlinkSubscription && response.value.data.createSmartlinkSubscription.status === 'active') {
                    //at this point, their pro plan status should have been saved to the database. when the user goes back to smartlinks main page it will load the plan from the database and change state, which is why i'm not changing it here.
                    this.setState({ 
                        showConfirmation: true,
                        hadCustomTheme: response.value.data.createSmartlinkSubscription.had_custom_theme,
                        loading: false
                    });
                }else if(response.value.data.createSmartlinkSubscription && response.value.data.createSmartlinkSubscription.status === 'incomplete'){
                    this.setState({
                        generalError: 'Unable to complete payment',
                        loading: false
                    })
                    return;
                }
            }
        } catch (error) {
            throw error;
        }
    }

    validateForm = () => {
        let isFormValid = true;
        let errors = {};
        const nameOnCard = this.state.nameOnCard;

        if (!nameOnCard || nameOnCard.trim().length === 0) {
            isFormValid = false;
            errors.nameOnCard = "Name is required.";
        }else{
            const pattern = new RegExp(/^[A-Za-z '\-\.]+$/);
            if (!pattern.test(nameOnCard)) {
                isFormValid = false;
                errors.nameOnCard = "Please enter valid name.";
            }
        }

        const termsAgreement = this.state.termsAgreement;
        if (!termsAgreement) {
            isFormValid = false;
            errors.termsAgreement = "Terms & Conditions Agreement is required.";
        }

        this.setState({ errors: errors });

        return !isFormValid;
    }

    handleSubscriptionConfirmationModal = () => {
        this.setState({ showConfirmation: !this.state.showConfirmation });
    }

    goToSmartLinks = () => {
        if(!this.state.loading){
            this.props.history.push('/smartlinks');
        }
    }

    reapplySmartlinksCustomTheme = () => {
        this.setState({
            applyThemeLoading: true
        })

        this.props.reapplySmartlinksCustomTheme(this.props.authData.username)
            .then((result) => {
                if(result.value.data.reapplySmartlinksCustomTheme && result.value.data.reapplySmartlinksCustomTheme.themeApplied){
                    this.setState({
                        applyThemeLoading: false
                    })
                    this.goToSmartLinks()
                }else{
                    this.setState({
                        applyThemeLoading: false,
                        applyThemeError: 'Sorry, an error occured and we weren\'t able to reapply your custom theme.'
                    })
                }
            })
            .catch(() => {
                this.setState({
                    applyThemeLoading: false,
                    applyThemeError: 'Sorry, an error occured and we weren\'t able to reapply your custom theme.'
                })
            })
    }

    renderSubscriptionConfirmationModal = () => {
        let modalActions = {
            primaryAction: {
                content: 'OK',
                onAction: this.goToSmartLinks,
            }
        }
        
        if(this.state.hadCustomTheme){
            modalActions.secondaryActions = [{
                ...modalActions.primaryAction,
                content: 'No thanks, keep my current theme'
            }];

            modalActions.primaryAction = {
                content: 'Yes, reapply my custom theme',
                onAction: this.reapplySmartlinksCustomTheme,
                loading: this.state.applyThemeLoading
            }
        }

        return (
            <Modal
                title="SmartLinks PRO Activated"
                open={this.state.showConfirmation}
                onClose={this.goToSmartLinks}
                {...modalActions}
            >
                <Modal.Section>
                    {this.state.applyThemeError && !this.state.applyThemeLoading &&
                        <Banner status="critical" title={this.state.applyThemeError} />
                    }
                    <TextContainer>
                        <p>Your subscription to SmartLinks PRO has been successfully activated.</p>
                        {this.state.hadCustomTheme && 
                            <p>We noticed you made a custom theme last time. Would you like to re-apply it?</p>
                        }
                    </TextContainer>
                </Modal.Section>
            </Modal>
        );
    }

    renderLlamaTosModal = () => {
        return (
            <Modal
                open={this.state.displayTos}
                onClose={this.toggleLlamaTosModal}
                primaryAction={{
                    content: 'Close',
                    onAction: this.toggleLlamaTosModal
                }}
            >
                <Modal.Section>
                    <LlamaTos inModal={true} />
                </Modal.Section>
            </Modal>
        )

    }

    toggleLlamaTosModal = () => {
        this.setState({ displayTos: !this.state.displayTos });
    }

    handleNameChange = (value) => {
        this.setState({
            nameOnCard: value
        }, () => {
            if(this.state.submitAttempted){
                this.validateForm()
            }
        })
    }

    handleTermsChange = (value) => {
        this.setState({
            termsAgreement: value
        }, () => {
            if(this.state.submitAttempted){
                this.validateForm()
            }
        })
    }

    handlePromoChange = (value) => {
        this.setState({
            promoCode: value
        })
    }

    applyPromoCodeClick = () => {
        this.props.applyPromoCode(this.props.affiliate.cognito_id, this.state.promoCode)
        .then(res => {
            if (res.value.errors) {
                this.setState({
                    toastVerbiage: res.value.errors[0].message,
                })
            }
            if (res.value.data && res.value.data.updateSmartLinkPromo && res.value.data.updateSmartLinkPromo.success) {
                this.props.history.push('/smartlinks/plans');
            }
        })
        .catch((error) => {
            console.error(error);
            this.setState({
                toastVerbiage: 'Oops, something went wrong. Please try again later.'
            });
        });
    }

    render() {
        const toastMarkup = this.state.toastVerbiage
            ? <Toast content={this.state.toastVerbiage} onDismiss={() => { this.setState({ toastVerbiage: null }); }} />
            : null;

        return(
            <div className="smartlink-subscription">
                {this.state.displayTos && this.renderLlamaTosModal()}
                {this.state.showConfirmation && this.renderSubscriptionConfirmationModal()}

                <h2>Upgrade to SmartLinks PRO</h2>
                <p className="info">Almost there. Just enter your payment information to subscribe to SmartLinks PRO and get full access to our premium features.</p>
                <h3>Plan</h3>
                <div className="plan">
                    <RadioButton id="plan" checked="checked" />
                    <p className="plan-name">
                        SmartLinks PRO
                        <span className="plan-type">Subscription</span>
                    </p>
                    <p className="price">
                        $0.99
                        <span className="plan-currency">USD/month</span>
                    </p>
                </div>
                <h3>Promo code</h3>
                <div className="promo-code">
                    <TextField
                        value={this.state.promoCode}
                        onChange={this.handlePromoChange}
                        placeholder="Enter Promo code"
                    />
                    <LlamaButton onClick={this.applyPromoCodeClick}>Apply</LlamaButton>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    {this.state.generalError && !this.state.loading &&
                        <Banner status="critical" title={this.state.generalError} />
                    }
                    <h3>Payment</h3>
                    <ul>
                        <li>
                            <TextField
                                value={this.state.nameOnCard}
                                label="Cardholder Name"
                                type="text"
                                onChange={this.handleNameChange}
                                error={this.state.errors.nameOnCard}
                            />
                        </li>
                        <li>
                            <label htmlFor="card-details">Card Number and Expiration Date</label>
                            <CardElement id="card-details" />
                            {this.state.errors.card && <InlineError message={this.state.errors.card} fieldID="card-details" />}
                        </li>
                        <li className="terms-agreement">
                            <Checkbox
                                checked={this.state.termsAgreement}
                                onChange={this.handleTermsChange}
                                id="terms-agreement"
                                label="I agree to the SmartLinks PRO"
                                error={this.state.errors.termsAgreement}
                            />
                            <Button plain onClick={this.toggleLlamaTosModal}>Terms of Service</Button>
                        </li>
                    </ul>
                    <p className="actions">
                        <Button className="btn btn-primary paypalbtn" primary submit loading={this.state.loading}>Upgrade To PRO</Button>
                        <Button plain onClick={this.goToSmartLinks}>Cancel</Button>
                    </p>
                </Form>
                <Frame>
                    {toastMarkup}
                </Frame>
            </div>
        )





        console.log(this.props);
        const username = this.props.authData.username;

        return (
            (this.state.displayTos)
                ? this.showLlamaTos()
                : (
                    (this.state.showConfirmation)
                        ? this.subscriptionStatusAcknowledgement()
                        : (
                            <div className="smartlink-subscription">

                                <div className="content-area">
                                    <h2 className="heading">Upgrade to SmartLinks PRO</h2>
                                    <p>Fill in your payment information to to get access to the full suite of SmartLinks PRO features.</p>
                                </div>

                                <div className="plan">
                                    <h3>Plan</h3>
                                    <div className="tree">
                                        <div className="tree-sidebar">
                                            <span className="img-circle">
                                                <img src={this.state.defaultAvatar} alt="Defaultuploaderimg" className="imgstyle" />
                                            </span>
                                        </div>
                                        <div className="tree-section tree-section-account">
                                            <span className="username">{username}</span>
                                        </div>
                                        <div className="tree-section tree-section-details">
                                            <div className="name">
                                                {(this.state.plan === 'plan_pro') ? 'FREE' : 'PRO'}
                                            </div>
                                            <div className="price">
                                                $0.99<span className="frequency">USD/month</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Form onSubmit={this.handleSubmit}>
                                    <FormLayout>
                                        <div className="form-wrap">
                                            <h3>Payment</h3>
                                            {/* <div className="section-inner"> */}
                                                <TextField
                                                    value={this.state.nameOnCard}
                                                    label="Name on card"
                                                    type="text"
                                                    onChange={(val) => {
                                                        this.setState({ nameOnCard: val }, () => {
                                                            if (this.validateForm()) {
                                                                this.setState({ isSubmitEnable: false });
                                                            }
                                                        });
                                                    }}
                                                />
                                                <div className="errorMsg">{this.state.errors.nameOnCard}</div>

                                                <div className="">
                                                    <h5>Card Details</h5>
                                                    <div className="form-lastrow">
                                                        <div>
                                                            <CardElement />
                                                        </div>
                                                    </div>
                                                </div>
                                            {/* </div> */}
                                        </div>
                                        <div className="customecheckbox">
                                            <Checkbox
                                                checked={this.state.checked}
                                                onChange={(val) => {
                                                    this.setState({ checked: val }, () => {
                                                        if (this.validateForm()) {
                                                            this.setState({ isSubmitEnable: false });
                                                        }
                                                    });
                                                }}
                                            />
                                            <label>I agree to the SmartLinks PRO
                                            <Button plain onClick={() => { this.toggleLlamaTos() }}>&nbsp;Terms of Service</Button>
                                            </label>
                                            <div className="errorMsg">{this.state.errors.checked}</div>
                                        </div>
                                        <div className="submitBTN ">
                                            <Button disabled={this.state.isSubmitEnable} className="btn btn-primary paypalbtn" primary submit>Subscribe</Button>
                                        </div>
                                    </FormLayout>
                                </Form>
                            </div>
                        )
                )
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchtoProps = (dispatch) => {
    return {
        getSmartLinks: (id) => dispatch(getSmartLinks(id)),
        applyPromoCode: (cognitoId, promoCode) => dispatch(applyPromoCode(cognitoId, promoCode)),
        createSmartlinkSubscription: (cognitoId, plan, cardToken, last4) => dispatch(createSmartlinkSubscription(cognitoId, plan, cardToken, last4)),
        reapplySmartlinksCustomTheme: (cognitoId) => dispatch(reapplySmartlinksCustomTheme(cognitoId))
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(injectStripe(SmartLinkSubscription));