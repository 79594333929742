import React from 'react';
import { Heading, TextStyle, Banner } from '@shopify/polaris';

import { moneyFormat } from '../../utils/number-format';
import formatPercent from '../../utils/format-percent';
import CenteredSpinner from '../llama/centered-spinner';
import { EmptyState } from 'llama-library/components';
import moment from 'moment';

import './offer-coupons.css';

/**
 * Returns the number of decimals on a number value
 * @param {Int || Float || String} value
 */

let now = new Date();
now.setHours(0, 0, 0);
now = new Date(now);

/**
 * @enum Value Types as defined by Shopify
 */
const valueTypes = {
    FIXED_AMOUNT: "FIXED_AMOUNT",
    PERCENTAGE: "PERCENTAGE"
};

/**
 * @enum Target Types as defined by Shopify
 */
const targetTypes = {
    LINE_ITEM: "LINE_ITEM",
    SHIPPING_LINE: "SHIPPING_LINE"
};

const discountTypes = {
    [`${targetTypes.SHIPPING_LINE}-${valueTypes.PERCENTAGE}`]: 'Free Shipping',
    [`${targetTypes.LINE_ITEM}-${valueTypes.FIXED_AMOUNT}`]: 'Fixed Amount',
    [`${targetTypes.LINE_ITEM}-${valueTypes.PERCENTAGE}`]: 'Percentage',
}

const OfferCoupons = (props) => {

    const { offer } = props;

    if (!offer || !offer.offer_id) {
        return <CenteredSpinner />
    }

    return (
        <div className="OfferCoupons__Wrapper">
            {Array.isArray(offer.discounts) && offer.discounts.length > 0
                ? <>
                    <div className="OfferCoupons__Heading">
                        <Heading>YOUR COUPON CODES</Heading>
                        {
                            (offer && offer.offer_type === '')
                                ? (
                                    <TextStyle variation="subdued">
                                        <span className="subtitle">
                                            Share the following coupon codes with your referred customers so they can get a one-time discount at checkout:
                                        </span>
                                    </TextStyle>
                                )
                                : (
                                    <TextStyle variation="subdued">
                                        <span className="subtitle">
                                            Below coupon has been issued exclusively to you by the merchant and is intended for your sole use.
                                        </span>
                                    </TextStyle>
                                )
                        }
                    </div>

                    <table className="OfferCoupons__Codes">
                        <thead>
                            <tr>
                                <th className="code">Code</th>
                                <th className="type">Discount Type</th>
                                <th className="value">Value</th>
                                <th className="valid-thru">Active Dates</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offer.discounts.map(discount => {
                                const typeKey = `${discount.target_type}-${discount.value_type}`;
                                const type = (discountTypes[typeKey]) ? discountTypes[typeKey] : 'Unknown';

                                // Format value for display.
                                const value = Math.abs(discount.value);
                                let displayValue = ''
                                if (discount.target_type === targetTypes.SHIPPING_LINE) {
                                    displayValue = '—'
                                } else {
                                    displayValue = (discount.value_type === valueTypes.PERCENTAGE)
                                        ? formatPercent(value)
                                        : moneyFormat(value);
                                }

                                let expired = false;
                                let validThru = '';
                                if (discount.ends_at) {
                                    validThru = moment(discount.starts_at).format('MMM D, YYYY') + '–' + moment(discount.ends_at).format('MMM D, YYYY')
                                    if (moment(discount.ends_at).isBefore(moment())) expired = true;
                                } else {
                                    validThru = 'From ' + moment(discount.starts_at).format('MMM D, YYYY')
                                }

                                return (
                                    <tr key={discount.discount_id} data-expired={expired} data-type={type}>
                                        <td className="code">{discount.code}</td>
                                        <td className="type">{type}</td>
                                        <td className="value">{displayValue}</td>
                                        <td className="valid-thru">{validThru}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
                : <EmptyState message={`${offer.advertiser.company} hasn’t created any coupon codes for you`} />
            }
        </div>
    )

};

export default OfferCoupons;