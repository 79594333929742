import { GET_OFFERS_FULFILLED, SEARCH_OFFERS_FULFILLED } from '../actions/action_getOffers';

const defaultState = [];

const offersReducer = (state = defaultState, action) => {

    const { type, payload } = action;

    switch(type) {

        case GET_OFFERS_FULFILLED: {

            if (
                !payload
                || !payload.data
                || !payload.data.affiliateById
                || !Array.isArray(payload.data.affiliateById.offers)
            ) {
                return state;
            }

            // Copies the current array of offers, checks if the offer in the payload
            // is currently listed in the array, if it is then that object will be
            // replaced.  If not it will be added to the array.

            // Copy of current state.
            const newOffers = Array.isArray(state) ? state.slice() : [];

            // New offers to update state with.
            const { offers } = payload.data.affiliateById;

            // Array of offer_ids from current state, used to get index of current
            // offers in state.
            const offerIndexes = newOffers.map((item) => item.offer.offer_id);

            // Replace or add offers to state copy.
            offers.filter((item) => item.offer.offer_id)
                .forEach((item) => {
                    const offerIndex = offerIndexes.indexOf(item.offer.offer_id);

                    if (offerIndex > -1) {
                        newOffers.splice(offerIndex, 1, item);
                        return;
                    }

                    newOffers.push(item);
                })

            return newOffers;

        }

        case SEARCH_OFFERS_FULFILLED: {
            if (!Array.isArray(action.payload.data.searchOffers.offers)) {
                return state;
            }

            return { ...state, offers: action.payload.data.searchOffers.offers};
        }

        default: {
            return state;
        }
    }
}

export default offersReducer;