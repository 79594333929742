import React from 'react';
import { Auth } from 'aws-amplify';
import {
    AuthPiece,
    SectionBody,
    Button,
    Link,
    InputRow
} from 'aws-amplify-react';
import CenteredSpinner from '../components/llama/centered-spinner';

import llamaHead from '../assets/llama-head.png';
import loginGraphic from '../assets/ambassador-login-graphic.svg';

const logger = {
    debug: (msg) => {
        if (process.env.NODE_ENV === "development") {
            console.log(msg)
        }
    }
};

export default class LlamaConfirmSignUp extends AuthPiece {

    signUpFields = [
        {
            required: true,
            label: 'Email',
            placeholder: 'Email',
            type: 'text',
            key: 'username',
            autocomplete: 'off',
            hidden: true
        }, {
            required: true,
            label: 'Code',
            placeholder: 'Enter Verification Code',
            type: 'code',
            key: 'code',
            autocomplete: 'off'
        }
    ];

    constructor(props) {
        super(props);

        const initalValues = this.signUpFields.reduce((acc, item) => {
            acc[item.key] = "";
            return acc;
        }, {})

        this.state = {
            values: {...initalValues},
            isLoading: false
        };

        this._validAuthStates = ['confirmSignUp'];
        this.confirm = this.confirm.bind(this);
        this.resend = this.resend.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.keyCode !== 13) {
            return;
        }

        if (this.props.authState === 'confirmSignUp') {
            this.confirm();
        }
    }

    confirm() {
        this.setState({ isLoading: true });
        const username = this.usernameFromAuthData() || this.inputs.username;
        const {code} = this.inputs;
        if (!Auth || typeof Auth.confirmSignUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        Auth.confirmSignUp(username.toLowerCase(), code)
            .then(() => { 
                this.setState({ isLoading: false });
                this.changeState('signedUp') }
            )
            .catch(err => {
                this.error(err);
                this.setState({ isLoading: false });
            });
    }

    resend() {
        const username = this.usernameFromAuthData() || this.inputs.username;
        if (!Auth || typeof Auth.resendSignUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.resendSignUp(username.toLowerCase())
            .then(() => {
                this.error('Ok, we just sent you a new verification code')
                logger.debug('code resent')
            })
            .catch(err => this.error(err));
    }


    onChange(event) {
        const {name, value} = event.target;
        const newValues = {...this.state.values};
        newValues[name] = value;

        this.setState({values: newValues});
        this.handleInputChange(event)
    }

    showComponent(theme) {
        const username = this.usernameFromAuthData();
        console.log(username);

        return (
            this.state.isLoading
                ? <CenteredSpinner />
                : (
                    <div className="signin-amplify__wrapper">
                        <div className="signin-amplify__form-wrapper signin-amplify__form-wrapper--signup">
                            <div className="signin-amplify__signin-form">
                                <div className="signin-amplify__mobile-wrapper">
                                    <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                                    <div className="signin-amplify__mobile-text">
                                        <h1 className="signin-amplify__header">Confirm Your Account</h1>
                                        <p className="signin-amplify__subheader">We just sent a six-digit verification code to <span className="amplify-paragraph__username">{username}</span></p>
                                    </div>
                                </div>
                                <SectionBody theme={theme}>
                                    {this.signUpFields.map((field) => {
                                        if (field.hidden) {
                                            return null;
                                        }
                                        return (
                                            <div className="amplify-form-item" key={field.key}>
                                                <InputRow
                                                    placeholder={field.placeholder}
                                                    theme={theme}
                                                    type={field.type}
                                                    name={field.key}
                                                    key={field.key}
                                                    onChange={this.onChange}
                                                    value={this.state.values[field.key]}
                                                />
                                            </div>
                                        );
                                    })}
                                </SectionBody>
                                <div className="amplify__custom-footer">
                                    <div className="amplify__button-wrapper">
                                        <Button theme={theme} onClick={this.confirm}>
                                            {'Confirm Account'}
                                        </Button>
                                    </div>
                                    <div className="amplify-section-footer_links-code">
                                        {'Didn\'t receive your code? Check your spam folder or '}
                                        <Link theme={theme} onClick={this.resend}>
                                            {'click here to resend the code.'}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="signup-amplify__graphic-wrapper">
                            <img alt="Leo the Llama Ambassador and his network" src={loginGraphic} className="signin-amplify__login-graphic signin-amplify__login-graphic--signup" />
                        </div>
                    </div>
                )
        );
    }
}