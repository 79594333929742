import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import {
    Page,
    Layout,
    ProgressBar,
    ChoiceList,
    Frame,
    Toast,
    Select,
    Checkbox,
    SkeletonBodyText,
    TextField
} from '@shopify/polaris';
import ImageDrop, { saveImage } from '../components/llama/image-drop';

import { getCatagories } from '../store/actions/action_getCatagories';
import { getCountries } from '../store/actions/action_getCountries';
import { savePaypalInfo } from '../store/actions/action_savePayPalInfo';
import { getAffiliateData } from '../store/actions/action_getAffiliate';
import { updateAffiliate } from '../store/actions/action_updateAffiliate';
import { changeHeaderTitle } from '../store/actions/header';

import SocialSettings from '../components/affiliate-social/affiliate-social';
import Payment from '../components/affiliate-payment/affiliate-payment';

import arrow from '../assets/right-arrow.svg';
import maleIcon from '../assets/male-gender-icon.png';
import femaleIcon from '../assets/female-gender-icon.png';
import allGenders from '../assets/all-gender-icon.png';

import './styles/onboarding.css';

export class Onboarding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            affiliate: {},
            step: 1,
            categories: [],
            countries: [],
            selectedCategories: [],
            firstName: null,
            lastName: null,
            active: false,
            showToast: false,
            showErrorToast: false,
            errorToastMessage: '',
            selectedGender: null,
            selectedCountry: 'US',
            selectedAge: [],
            propsLoaded: false,
            photo: null
        };

        this.trackStepInc = this.trackStepInc.bind(this);
        this.trackStepDec = this.trackStepDec.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleAgeChange = this.handleAgeChange.bind(this);

        this.props.getCatagories()
            .then((result) => {
                this.setState({
                    categories: result.value.data.app.categories
                });
            })
            .catch((error) => { return console.error(error); });

        this.props.getCountries().then((result) => {
            const c = [];
            result.value.data.app.countries.forEach((item) => {
                c.push({
                    label: item.name,
                    value: item.country_code
                });
            });
            this.setState({ countries: c });
        }).catch((error) => { return console.error(error); });
    }

    componentDidMount() {
        this.setState({ step: parseInt(this.props.match.params.id, 10) });
        this.props.changeHeaderTitle('Profile Setup');
    }

    componentDidUpdate(prevProps) {
        if (this.props.affiliate.account && this.props.affiliate.account.personal && this.props.affiliate.account.personal.first_name && !this.state.propsLoaded) {
            this.setState({
                firstName: this.props.affiliate.account.personal.first_name,
                lastName: this.props.affiliate.account.personal.last_name,
                propsLoaded: true
            });
        }

        if (this.props.affiliate && this.props.affiliate.account && this.props.affiliate.account !== null
            && !isEqual(this.props.affiliate, prevProps.affiliate)) {
            const {
                categories,
                audience_gender,
                audience_country,
                audience_age_range
            } = this.props.affiliate.account;

            if (categories !== this.state.selectedCategories) {
                this.setState({ selectedCategories: categories });
            }

            if (audience_gender !== this.state.selectedGender) {
                this.setState({ selectedGender: audience_gender });
            }

            if (audience_country !== this.state.selectedCountry) {
                let stringCountry = audience_country;
                if (Array.isArray(audience_country)) {
                    stringCountry = audience_country[0];
                }
                this.setState({ selectedCountry: stringCountry });
            }

            if (audience_age_range !== this.state.selectedAge) {
                this.setState({ selectedAge: audience_age_range });
            }
        }
    }

    toggleToast = () => {
        this.setState(({ showToast }) => {
            return { showToast: !showToast };
        });
    };

    toggleErrorToast = (message) => {
        this.setState(({ showErrorToast }) => {
            return { showErrorToast: !showErrorToast };
        });
        this.setState({ errorToastMessage: message });
    };

    moveForward() {
        let step = parseInt(this.props.match.params.id, 10);
        step++;
        this.props.history.push(`/signUp/${step}`);
        this.setState({ step });
    }

    trackStepInc = () => {
        const payload = {
            status: 'ACTIVE',
            sign_up_flow: this.props.match.params.id
        };

        if (this.state.step === 1 || this.state.step === 3 || this.state.step === 5 || this.state.step === 9) {
            this.moveForward();
        }

        if (this.state.step === 2) {
            if (!this.state.firstName && !this.state.lastName) {
                this.toggleErrorToast('Please enter your name');
                return;
            }
            if (!this.state.firstName) {
                this.toggleErrorToast('Please enter your first name');
                return;
            }
            if (!this.state.lastName) {
                this.toggleErrorToast('Please enter your last name');
                return;
            }
            this.saveName(payload);
        }

        if (this.state.step === 3) {
            this.savePhoto();
        }

        if (this.state.step === 4) {
            if (!this.state.selectedCategories || (Array.isArray(this.state.selectedCategories) && this.state.selectedCategories.length === 0)) {
                this.toggleErrorToast('Please select at least one category');
                return;
            }

            this.moveForward();
            this.saveCategorySelection();
        }

        if (this.state.step === 6) {
            if (!this.state.selectedGender) {
                this.toggleErrorToast('Please select a gender.');
                return;
            }
            this.moveForward();
            this.saveAudienceGenderSelection();
        }

        if (this.state.step === 7) {
            this.moveForward();
            this.saveAudienceCountrySelection();
        }

        if (this.state.step === 8) {
            if (this.state.selectedAge && this.state.selectedAge.length === 0) {
                this.toggleErrorToast('Please select at least one age range');
                return;
            }
            this.moveForward();
            this.saveAudienceAgeSelection();
        }

        if (this.state.step !== 2) {
            this.props.updateAffiliate(payload, this.props.affiliate.cognito_id);
        }
    };

    trackStepDec = () => {
        if (this.state.step > 1) {
            let step = parseInt(this.props.match.params.id, 10);
            step--;
            this.props.history.push(`/signUp/${step}`);
            this.setState({ step });
        }
    };

    handleNameChange = (nameType, value) => {
        this.setState({ [`${nameType}Name`]: value });
    };

    handleCategorySelection = (category) => {
        const selections = this.state.selectedCategories;

        if (Array.isArray(selections)) {
            const selectionIndex = selections.indexOf(category);

            if (selections.length < 3 || selectionIndex !== -1) {
                // if the category selected is in selections, remove it, otherwise add it
                if (selectionIndex !== -1) {
                    selections.splice(selectionIndex, 1);
                } else {
                    selections.push(category);
                    selections.sort();
                }

                this.setState({ selectedCategories: selections });
            }
        } else {
            this.setState({ selectedCategories: [category] });
        }
    };

    handleGenderChange = (value) => {
        this.setState({
            selectedGender: value
        });
    };

    // needs to be a bound function so the test doesn't fail
    handleCountryChange(value) {
        this.setState({
            selectedCountry: value
        });
    }

    // needs to be a bound function so the test doesn't fail
    handleAgeChange(value) {
        this.setState({
            selectedAge: value
        });
    }

    saveName = (payload) => {
        if (this.props.affiliate.account !== null) {
            payload.account = {
                personal: {
                    ...this.props.affiliate.account.personal,
                    first_name: this.state.firstName.trim(),
                    last_name: this.state.lastName.trim()
                }
            };
        } else {
            payload.account = {
                personal: {
                    first_name: this.state.firstName.trim(),
                    last_name: this.state.lastName.trim()
                }
            };
        }

        payload.name = `${this.state.firstName.trim()} ${this.state.lastName.trim()}`;

        this.props.updateAffiliate(payload, this.props.affiliate.cognito_id).then(() => {
            this.moveForward();
            this.toggleToast();
        }).catch((error) => { return console.error(error); });
    }

    saveCategorySelection = () => {
        const payload = {
            account: {
                categories: this.state.selectedCategories
            }
        };

        this.props.updateAffiliate(payload, this.props.affiliate.cognito_id)
            .then(this.toggleToast())
            .catch((error) => { return console.error(error); });
    };

    saveAudienceGenderSelection = () => {
        const payload = {
            account: {
                audience_gender: this.state.selectedGender
            }
        };
        this.props.updateAffiliate(payload, this.props.affiliate.cognito_id)
            .then(this.toggleToast())
            .catch((error) => { return console.error(error); });
    };

    saveAudienceCountrySelection = () => {
        const payload = {
            account: {
                audience_country: this.state.selectedCountry
            }
        };

        this.props.updateAffiliate(payload, this.props.affiliate.cognito_id)
            .then(this.toggleToast())
            .catch((error) => { return console.error(error); });
    };

    saveAudienceAgeSelection = () => {
        const payload = {
            account: {
                audience_age_range: this.state.selectedAge
            }
        };
        this.props.updateAffiliate(payload, this.props.affiliate.cognito_id)
            .then(this.toggleToast())
            .catch((error) => { return console.error(error); });
    };

    setPhoto = (photo) => {
        this.setState({ photo });
    }

    savePhoto = async () => {
        if (this.state.photo) {
            const saveResult = await saveImage(this.state.photo, this.props.affiliate.cognito_id, 'profile', null);
            const payload = { photo: saveResult.url };
            this.props.updateAffiliate(payload, this.props.affiliate.cognito_id)
                .then(this.toggleToast())
                .catch((error) => {
                    return console.error(error);
                });
        }
    }

    updateOnboardingComplete = () => {
        const payload = { onboarding_complete: true };
        this.props.updateAffiliate(payload, this.props.affiliate.cognito_id)
            .then(this.toggleToast())
            .catch((error) => {
                return console.error(error);
            });
    }

    renderFinalStep = () => {
        let ctaLink = '/discover';
        let ctaText = 'Find an Offer';
        let subText = 'find your first offer';

        if (this.props.affiliate.start_page) {
            switch (this.props.affiliate.start_page) {
                case 'smartlinks':
                    ctaLink = '/smartlinks';
                    ctaText = 'Go to SmartLinks';
                    subText = 'create your SmartLinks page';
                    break;
                case 'instaAnalytics':
                    ctaLink = '/instaAnalytics';
                    ctaText = 'View InstaAnalytics';
                    subText = 'view your InstaAnalytics';
                    break;
                default:
                    break;
            }
        }

        return (
            <>
                <h1 className="Onboarding__Header">Nice Work! Your profile has been created.</h1>
                <p className="Onboarding__Paragraph">Now it&rsquo;s time to {subText}.</p>
                <p className="Onboarding__MainCTA"><Link onClick={this.updateOnboardingComplete} to={ctaLink}>{ctaText}</Link></p>
            </>
        );
    }

    renderCurrentStep = () => {
        if (this.props.match.params.id === '1') {
            return (
                <>
                    <h1 className="Onboarding__Header">Hey there! I&rsquo;m Leo the Llama</h1>
                    <p className="Onboarding__Paragraph">I&rsquo;ll help you set up your ambassador profile in minutes. Ready to get started?</p>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc} data-test="start-button">Let&rsquo;s Do This</a>
                </>
            );
        }

        if (this.props.match.params.id === '2') {
            return (
                <>
                    <h1 className="Onboarding__Header">First things first, what&rsquo;s your name?</h1>
                    <p className="Onboarding__Paragraph">Let&rsquo;s get to know each other and I&rsquo;ll guide you along this process.</p>
                    <div className="Onboarding__Name">
                        <TextField
                            label="First Name"
                            placeholder="John"
                            value={this.state.firstName}
                            onChange={(value) => { return this.handleNameChange('first', value); }}
                            data-test="first-name-textfield"
                        />
                        <TextField
                            label="Last Name"
                            placeholder="Doe"
                            value={this.state.lastName}
                            onChange={(value) => { return this.handleNameChange('last', value); }}
                            data-test="last-name-textfield"
                        />
                    </div>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                </>
            );
        }

        if (this.props.match.params.id === '3') {
            return (
                <>
                    <h1 className="Onboarding__Header">Upload a profile photo</h1>
                    <p className="Onboarding__Paragraph">A photo boosts your profile’s authenticity to brands.</p>
                    <div className="Onboarding__DropZone">
                        <ImageDrop
                            buttonText="Upload a profile photo"
                            setImage={this.setPhoto}
                            crop="square"
                            data-test="component-imageDrop"
                        />
                    </div>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                    <a className="hello-llama-skip" onClick={this.trackStepInc}>
                        Skip for now, I&rsquo;ll set this up later
                    </a>
                </>
            );
        }

        if (this.props.match.params.id === '4') {
            const maxSelected = this.state.selectedCategories && this.state.selectedCategories.length === 3;

            return (
                <>
                    <h1 className="Onboarding__Header">Nice to meet you, {this.state.firstName}.<br />Tell me, what are you most interested in promoting?</h1>
                    <p className="Onboarding__Paragraph">Select 1&ndash;3 categories that are most relevant to you and your audience.</p>
                    <ul className="Onboarding__CategoriesChoiceList" data-loading={this.state.categories.length === 0}>
                        {this.state.categories.length > 0
                            ? this.state.categories.map((category) => {
                                const checked = this.state.selectedCategories && this.state.selectedCategories.length > 0 && this.state.selectedCategories.includes(category.name);
                                return (
                                    <li key={category.name}>
                                        <Checkbox
                                            label={category.name}
                                            checked={checked}
                                            disabled={!checked && maxSelected}
                                            onChange={() => { return this.handleCategorySelection(category.name); }}
                                            data-test="category-checkbox"
                                        />
                                    </li>
                                );
                            })
                            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => {
                                return (
                                    <li key={index}>
                                        <SkeletonBodyText lines={1} />
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                </>
            );
        }

        if (this.props.match.params.id === '5') {
            return (
                <>
                    <h1 className="Onboarding__Header">Connect a social media account</h1>
                    <p className="Onboarding__Paragraph">We recommend adding at least one. You can always connect more later.</p>
                    <div className="Onboarding__SocialWrapper">
                        <SocialSettings data-test="social-component"/>
                    </div>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                    <a className="hello-llama-skip" onClick={this.trackStepInc}>
                        Skip for now, I&rsquo;ll set this up later
                    </a>
                </>
            );
        }

        if (this.props.match.params.id === '6') {
            const { selectedGender } = this.state;

            return (
                <>
                    <h1 className="Onboarding__Header">Which gender does most of your audience identify with?</h1>
                    <p className="Onboarding__Paragraph">The more accurate your answer, the more relevant your brand matches will be.</p>
                    <div className="Onboarding__GenderLabels">
                        <div onClick={() => { return this.handleGenderChange('MALE'); }} className={selectedGender === 'MALE' ? 'Onboarding__GenderLabel Onboarding__GenderLabel--active' : 'Onboarding__GenderLabel'} data-test="male-option">
                            <img src={maleIcon} className="Onboarding__GenderImage" alt="male" />
                            Male
                        </div>
                        <div onClick={() => { return this.handleGenderChange('FEMALE'); }} className={selectedGender === 'FEMALE' ? 'Onboarding__GenderLabel Onboarding__GenderLabel--active' : 'Onboarding__GenderLabel'} data-test="female-option">
                            <img src={femaleIcon} className="Onboarding__GenderImage" alt="female" />
                            Female
                        </div>
                        <div onClick={() => { return this.handleGenderChange('ALL'); }} className={selectedGender === 'ALL' ? 'Onboarding__GenderLabel Onboarding__GenderLabel--active' : 'Onboarding__GenderLabel'} data-test="allgenders-option">
                            <img src={allGenders} className="Onboarding__GenderImage" alt="all genders" />
                            All
                        </div>
                    </div>
                    <div className="Onboarding__SubParGender-Wrapper">
                        <p className="Onboarding__SubParagraph">
                            We support all gender identities here at Llama, but some brands sell gender-specific products (such as feminine hygiene) that might not be as relevant to some people. As trans rights gain support, you may see more genders listed here in the future.
                        </p>
                    </div>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                </>
            );
        }

        if (this.props.match.params.id === '7') {
            let countries = [
                {
                    label: 'loading...',
                    value: 'loading...'
                }
            ];

            if (this.state.countries.length > 1) {
                countries = this.state.countries;
            }

            return (
                <>
                    {/* <div className="hello-message-wrapper"> */}
                    <h1 className="Onboarding__Header">Which country does most of your audience reside in?</h1>
                    <p className="Onboarding__Paragraph">The more accurate your answer, the more relevant your brand matches will be.</p>
                    {/* </div> */}

                    <div className="hello-llama-countrySelect">
                        <Select
                            options={countries}
                            onChange={this.handleCountryChange}
                            value={this.state.selectedCountry}
                            data-test="country-select"
                        />
                    </div>
                    <div className="Onboarding__SubParGender-Wrapper">
                        <p className="Onboarding__SubParagraph">
                            If you have an international audience, we recommend choosing the country that is most culturally appropriate to the type of audience you typically share.
                        </p>
                    </div>
                    {/* <div className="hello-llama-text-chat-wrapper">
                        <div className="hello-llama-text-chat-container">
                            <img className="hello-llama-text-chat-icon"
                                    src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png"/>
                            <div className="hello-llama-text-chat-background">
                                <p className="hello-llama-text-chat-message">
                                    I know it's hard to pick just one, but
                                    we really just need to know your main audience. (You can always update
                                    later)
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                </>
            );
        }

        if (this.props.match.params.id === '8') {
            const { selectedAge } = this.state;
            return (
                <>
                    <h1 className="Onboarding__Header">How old is most of your audience?</h1>
                    <p className="Onboarding__Paragraph">The more accurate your answer, the more relevant your brand matches will be.
                    </p>
                    <div className="Onboarding__AgeChoiceList">
                        <ChoiceList
                            choices={[
                                {
                                    label: '13-17',
                                    value: '13-17'
                                },
                                {
                                    label: '45-54',
                                    value: '45-54'
                                },
                                {
                                    label: '18-24',
                                    value: '18-24'
                                },
                                {
                                    label: '55-64',
                                    value: '55-64'
                                },
                                {
                                    label: '25-34',
                                    value: '25-34'
                                },
                                {
                                    label: '65+',
                                    value: '65+'
                                },
                                {
                                    label: '35-44',
                                    value: '35-44'
                                }
                            ]}
                            allowMultiple
                            selected={selectedAge || []}
                            onChange={this.handleAgeChange}
                            data-test="age-ranges"
                        />
                    </div>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                </>
            );
        }

        if (this.props.match.params.id === '9') {
            return (
                <>
                    <h1 className="Onboarding__Header">Where should we send your payments?</h1>
                    <p className="Onboarding__Paragraph">Connect your paypal account and get ready to earn your commissions.
                    </p>
                    <div className="Onboarding__PaypalWrapper">
                        <Payment data-test="payment-component" />
                    </div>
                    <a className="Onboarding__MainCTA" onClick={this.trackStepInc}>Next</a>
                    <a className="hello-llama-skip" onClick={this.trackStepInc}>
                        Skip for now, I&rsquo;ll set this up later
                    </a>
                </>
            );
        }

        if (this.props.match.params.id === '10') {
            return this.renderFinalStep();
        }

        return '';
    };

    render() {
        const steps = this.renderCurrentStep();
        const stepNumber = parseInt(this.props.match.params.id, 10);
        const { showToast, showErrorToast } = this.state;
        const toastMarkup = showToast
            ? (<Toast duration={3000} className="toastySuccess" content="Saved" onDismiss={this.toggleToast} />)
            : null;
        const toastErrorMarkup = showErrorToast
            ? (<Toast duration={3000} error className="toastyError" content={this.state.errorToastMessage} onDismiss={this.toggleErrorToast} />)
            : null;

        return (
            <div className="onboarding__wrapper">
                <Frame data-test="frame-container">
                    <ProgressBar progress={(stepNumber * 12.5)} size="small" className="progress_bar" data-test="progressbar-container" />
                    <Page separator data-test="page-container">
                        <Layout>
                            <div className="Onboarding__Card" data-test={`onboarding-step-${stepNumber}`}>
                                {stepNumber > 1
                                    && (
                                        <div className="Onboarding__StepTracker">
                                            <img className="Onboarding__BackButton" src={arrow} onClick={this.trackStepDec} alt="back" />
                                            Step {parseInt(stepNumber, 10) - 1} of 9
                                        </div>
                                    )
                                }
                                <img className="Onboarding__Llama" src="https://s3.amazonaws.com/llama.creatives/llama-cut-out.png" alt="Leo the Llama" />
                                {steps}
                            </div>
                        </Layout>
                    </Page>
                    <div className="toasty">
                        {toastMarkup}
                        {toastErrorMarkup}
                    </div>
                </Frame>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate,
        ...state
    };
};

const mapDispatchtoProps = (dispatch) => {
    return bindActionCreators({
        updateAffiliate,
        save_paypal: savePaypalInfo,
        getCatagories,
        getCountries,
        get_affiliate: getAffiliateData,
        changeHeaderTitle
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchtoProps)(Onboarding);
