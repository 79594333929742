import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { Pie } from '@nivo/pie';
import { SkeletonBodyText, SkeletonDisplayText, Banner } from '@shopify/polaris';
import LlamaDatePicker from '../llama/date-picker';

import getSmartlinksAnalytics, { mockAnalytics } from '../../store/actions/action_getSmartlinksAnalytics';

import numberFormat from '../../utils/number-format';
import findTopTick, { formatBottomTickValues } from '../../utils/graph-ticks';

import './smart-link-analytics.css';

const dateFormat = 'YYYY-MM-DD';

const DateGraph = ({ data, ticks, startDate, endDate }) => {
    // list all the days
    const days = moment(endDate).diff(moment(startDate), 'days');
    let nextDay = moment(startDate);
    const graphData = [];
    for (let i = 0; i <= days; i++) {
        const nextDayFormatted = nextDay.format(dateFormat);

        const match = data.find((stat) => {
            const statDateWithoutTime = stat.date.substr(0, 15);
            return moment(statDateWithoutTime).format(dateFormat) === nextDayFormatted;
        });
        graphData.push({
            Clicks: (match && match.clicks) ? match.clicks : 0,
            'Page Views': (match && match.views) ? match.views : 0,
            date: nextDayFormatted
        });
        nextDay = nextDay.add(1, 'day');
    }
    let highestValue = 0;

    graphData.forEach((stat) => {
        if (stat.Clicks > highestValue) {
            highestValue = stat.Clicks;
        }
        if (stat['Page Views'] > highestValue) {
            highestValue = stat['Page Views'];
        }
    });

    const topTick = findTopTick(highestValue);
    const tickDivisor = ticks - 1;
    const leftTickValues = [];
    let numTicks = ticks;
    if (numTicks > highestValue) {
        numTicks = highestValue;
    }
    if (numTicks === 0) {
        numTicks = 1;
    }
    for (let i = 0; i < numTicks; i++) {
        if (i === 0) {
            leftTickValues.push(0);
        } else if (i === numTicks - 1) {
            leftTickValues.push(topTick);
        } else {
            leftTickValues.push(Math.round((topTick / tickDivisor) * i));
        }
    }

    const bottomTickValues = formatBottomTickValues(graphData.map((item) => {
        return item.date;
    }));
    const axisBottom = {
        tickValues: bottomTickValues,
        format: (date) => {
            return moment(date).format('MM/DD');
        }
    };

    const axisLeft = { tickValues: leftTickValues };

    return (
        <ResponsiveBar
            data={graphData}
            keys={['Page Views', 'Clicks']}
            indexBy="date"
            enableLabel={false}
            colors={['#FD2856', '#FFA72C']}
            groupMode="grouped"
            maxValue={topTick}
            minValue={0}
            axisLeft={axisLeft}
            axisBottom={axisBottom}
            gridYValues={leftTickValues}
            tooltip={({ id, value, color, data: itemData }) => {
                const date = moment(itemData.day).format('MMM D, YYYY');
                return (
                    <div className="nivo-graph-tooltip-wrapper">
                        <span className="nivo-graph-tooltip-date">{date}</span>
                        <span className="nivo-graph-tooltip-item">
                            <span className="nivo-graph-tooltip-color-block" style={{ background: color }} />
                            {id}: <span style={{ fontWeight: 'bold' }}>{numberFormat(value)}</span>
                        </span>
                    </div>
                );
            }}
            margin={{
                top: 10,
                right: 10,
                bottom: 100,
                left: 30
            }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    itemWidth: 100,
                    itemHeight: 20,
                    translateY: 70
                }
            ]}
            padding={0.3}
        />
    );
};

const SmartLinksAnalytics = (props) => {
    const { affiliate, links, linksLoading, plan, goToPlans, dispatchGetSmartlinksAnalytics } = props;

    const defaultStartDate = moment().subtract(6, 'days').format(dateFormat);
    const defaultEndDate = moment().format(dateFormat);

    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [summary, setSummary] = useState({
        totalViews: 0,
        totalClicks: 0,
        totalCTR: 0,
        days: []
    });
    const [linkStats, setLinkStats] = useState([]);
    const [linkGraph, setLinkGraph] = useState(null);

    useEffect(() => {
        if (!linksLoading) {
            if (!loading) {
                setLoading(true);
            }

            if (plan === 'pro') {
                dispatchGetSmartlinksAnalytics(affiliate.cognito_id, startDate, endDate, links)
                    .then((result) => {
                        if (result && result.value && result.value.data && result.value.data.getSmartLinksAnalytics && result.value.data.getSmartLinksAnalytics.summary) {
                            setSummary(result.value.data.getSmartLinksAnalytics.summary);

                            // map to current links
                            const linksWithStats = links.map((link) => {
                                const linkMatch = result.value.data.getSmartLinksAnalytics.links.find((linkStat) => {
                                    return link.url === linkStat.url;
                                });

                                if (linkMatch) {
                                    return {
                                        ...linkMatch,
                                        name: link.title
                                    };
                                }

                                return {
                                    name: link.title,
                                    url: link.url,
                                    company: link.company,
                                    clicks: 0,
                                    views: 0,
                                    days: []
                                };
                            });

                            // if there's extra links that aren't a part of the current set, add to the bottom
                            result.value.data.getSmartLinksAnalytics.links.forEach((link) => {
                                const linkMatch = linksWithStats.find((linkStat) => {
                                    return link.url === linkStat.url;
                                });

                                if (!linkMatch) {
                                    linksWithStats.push(link);
                                }
                            });

                            setLinkStats(linksWithStats);
                        }

                        setLoading(false);
                    });
            } else {
                const sampleData = mockAnalytics(links);
                setSummary(sampleData.summary);
                setLinkStats(sampleData.links);
                setLoading(false);
            }
        }
    }, [linksLoading, `${startDate}_${endDate}`]);

    const toggleLinkGraph = (linkId) => {
        if (plan === 'pro') {
            if (linkId !== linkGraph) {
                setLinkGraph(linkId);
            } else {
                setLinkGraph(null);
            }
        }
    };

    const handleDateChange = (newDateRange) => {
        setStartDate(moment(newDateRange.startDate).format(dateFormat));
        setEndDate(moment(newDateRange.endDate).format(dateFormat));
    };

    return (
        <div className={!loading && plan === 'free' ? 'smartlinks--upgrade-notice' : ''}>
            {!loading && plan === 'free'
                && <Banner status="warning" title="Track your page views and clicks with SmartLinks PRO. Plus, get access to full customization options and third-party integrations." action={{ content: 'Upgrade to PRO', onAction: goToPlans }} />
            }
            <div className={`analytics${(!loading && plan === 'free') ? ' smartlinks--hidden-content' : ''}`}>
                <p className="heading">Analytics</p>
                <p className="info">Measure how well your links are performing</p>

                <div className="overall-stats">
                    <h3>Clicks and Page Views{(!loading && plan === 'free') && ' (Sample Data)'}</h3>
                    <div className="summary-and-date">
                        {loading
                            ? <SkeletonBodyText lines={1} />
                            : <p><b>{numberFormat(summary.totalViews)}</b> total page view{summary.totalViews !== 1 ? 's' : ''}, <b>{numberFormat(summary.totalClicks)}</b> total click{summary.totalClicks !== 1 ? 's' : ''}, <abbr title="Click-through rate">CTR:</abbr> <b>{numberFormat(summary.totalCTR, 1, false)}%</b></p>
                        }
                        <LlamaDatePicker
                            onDateChange={handleDateChange}
                            defaultDates={{
                                startDate: new Date(`${defaultStartDate}T05:00:00Z`),
                                endDate: new Date(`${defaultEndDate}T05:00:00Z`)
                            }}
                            currentDates={{
                                startDate: new Date(`${startDate}T05:00:00Z`),
                                endDate: new Date(`${endDate}T05:00:00Z`)
                            }}
                            disabled={loading}
                            src={1}
                        />
                    </div>
                    <div className="day-breakdown">
                        {loading
                            ? (
                                <div className="skeleton-graph">
                                    <div className="group">
                                        <SkeletonDisplayText />
                                        <SkeletonDisplayText />
                                    </div>
                                    <div className="group">
                                        <SkeletonDisplayText />
                                        <SkeletonDisplayText />
                                    </div>
                                    <div className="group">
                                        <SkeletonDisplayText />
                                        <SkeletonDisplayText />
                                    </div>
                                    <div className="group">
                                        <SkeletonDisplayText />
                                        <SkeletonDisplayText />
                                    </div>
                                    <div className="group">
                                        <SkeletonDisplayText />
                                        <SkeletonDisplayText />
                                    </div>
                                    <div className="group">
                                        <SkeletonDisplayText />
                                        <SkeletonDisplayText />
                                    </div>
                                    <div className="group">
                                        <SkeletonDisplayText />
                                        <SkeletonDisplayText />
                                    </div>
                                </div>
                            )
                            : (
                                <DateGraph
                                    data={summary.days}
                                    ticks={6}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            )
                        }
                    </div>
                </div>

                <div className="clicks">
                    <div className="heading-and-date">
                        <h3>Clicks Per Link{(!loading && plan === 'free') && ' (Sample Data)'}</h3>
                        <LlamaDatePicker
                            onDateChange={handleDateChange}
                            defaultDates={{
                                startDate: new Date(`${defaultStartDate}T05:00:00Z`),
                                endDate: new Date(`${defaultEndDate}T05:00:00Z`)
                            }}
                            currentDates={{
                                startDate: new Date(`${startDate}T05:00:00Z`),
                                endDate: new Date(`${endDate}T05:00:00Z`)
                            }}
                            disabled={loading}
                            src={2}
                        />
                    </div>
                    <ul className="linklist">
                        {loading
                            ? [1, 2, 3].map((key) => {
                                return (
                                    <li key={key}>
                                        <div className="link-info">
                                            <SkeletonBodyText lines={2} />
                                        </div>
                                        <figure className="total">
                                            <SkeletonDisplayText size="small" />
                                            <figcaption>
                                                <SkeletonBodyText lines={2} />
                                            </figcaption>
                                        </figure>
                                    </li>
                                );
                            })
                            : linkStats.map((linkStat) => {
                                const linkId = `${linkStat.name}${linkStat.url}`;

                                return (
                                    <li key={linkId}>
                                        <p className="link-info">
                                            <b>{linkStat.company && `${linkStat.company}: `}{linkStat.name}</b> {linkStat.url}
                                        </p>

                                        {linkStat.totalClicks > 0
                                            ? (
                                                <figure className="total">
                                                    <Pie
                                                        data={[
                                                            {
                                                                id: 'clicks',
                                                                label: 'Clicks',
                                                                value: linkStat.totalCTR,
                                                                color: '#FFA72C'
                                                            },
                                                            {
                                                                id: 'views',
                                                                label: 'Page Views',
                                                                value: 100 - linkStat.totalCTR,
                                                                color: '#FD2856'
                                                            }
                                                        ]}
                                                        width={34}
                                                        height={34}
                                                        colors={['#FFA72C', '#FD2856']}
                                                        tooltip={() => {
                                                            return (
                                                                <div className="nivo-graph-tooltip-wrapper">
                                                                    <span className="nivo-graph-tooltip-item">
                                                                        <span className="nivo-graph-tooltip-color-block" style={{ background: '#FFA72C' }} />
                                                                        Clicks: <span style={{ fontWeight: 'bold' }}>{numberFormat(linkStat.totalClicks)}</span>
                                                                    </span>
                                                                </div>
                                                            );
                                                        }}
                                                        valueFormat={() => {
                                                            return '';
                                                        }}
                                                        enableRadialLabels={false}
                                                        enableSlicesLabels={false}
                                                    />
                                                    <figcaption>
                                                        <span>{numberFormat(linkStat.totalClicks)} total click{linkStat.totalClicks !== 1 ? 's' : ''}</span> <span>{numberFormat(linkStat.totalCTR)}% <abbr title="Click-through rate">CTR</abbr></span>
                                                    </figcaption>
                                                </figure>
                                            )
                                            : <p className="empty">No clicks</p>
                                        }

                                        {linkGraph === linkId
                                            && (
                                                <div className="day-breakdown">
                                                    <DateGraph
                                                        data={linkStat.days}
                                                        ticks={3}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                    />
                                                </div>
                                            )
                                        }

                                        {linkStat.days.length > 0
                                            && <p><button type="button" className="toggle-graph" onClick={() => { return toggleLinkGraph(linkId); }}>{linkGraph !== linkId ? 'Show' : 'Hide'} detailed stats</button></p>
                                        }
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

DateGraph.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        clicks: PropTypes.number,
        views: PropTypes.number
    })).isRequired,
    ticks: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired
};

SmartLinksAnalytics.propTypes = {
    affiliate: PropTypes.shape({
        cognito_id: PropTypes.string.isRequired
    }).isRequired,
    linksLoading: PropTypes.bool,
    links: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string
    })).isRequired,
    plan: PropTypes.string.isRequired,
    goToPlans: PropTypes.func.isRequired,
    dispatchGetSmartlinksAnalytics: PropTypes.func.isRequired
};
SmartLinksAnalytics.defaultProps = {
    linksLoading: false
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSmartlinksAnalytics: (cognito_id, startDate, endDate, links) => { return dispatch(getSmartlinksAnalytics(cognito_id, startDate, endDate, links)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartLinksAnalytics);
