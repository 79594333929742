import React from 'react';

import './smart-link-plans.css';

const SmartLinkPlans = () => {
    return (
        <div className="plans">
            <div className="free">
                <p className="plan-heading">SmartLinks FREE</p>
                <ul className="features">
                    <li>Custom page URL</li>
                    <li>Bio without character limits</li>
                    <li>Six premade themes</li>
                    <li>UTM tracking parameters</li>
                    <li>Facebook Ad Events pixel</li>
                    <li>Dedicated area for social media links</li>
                </ul>
            </div>

            <div className="pro">
                <p className="plan-heading">SmartLinks PRO &ndash; $0.99/month <span>Everything in FREE, plus:</span></p>
                <ul className="features">
                    <li>Ten premade themes</li>
                    <li>Full customization of colors, fonts, and button styles</li>
                    <li>Analytics (clicks and views)</li>
                    <li>Google Analytics integration</li>
                    <li>Email sign up form</li>
                    <li>No &ldquo;Powered by Llama&rdquo; footer</li>
                    <li>Link images (Coming Soon)</li>
                </ul>
            </div>
        </div>
    );
};

export default React.memo(SmartLinkPlans);
