import { UPDATE_OFFERS } from '../actions/action_updateSmartLinkOffer';

const defaultState = {
    offers: []
};

const updateOffers = (state = defaultState, action) => {
    const updatedOffers = { ...state };
    const { type } = action;

    switch (type) {
        case UPDATE_OFFERS:
            updatedOffers.offers = action.offers;
            return updatedOffers;
        default:
            return state;
    }
};

export default updateOffers;