import React, { useState } from 'react';
import './affiliate-product-categories.css';
import {Button, ButtonGroup,  Checkbox, Toast, Frame, SkeletonBodyText} from "@shopify/polaris";

const ProductCategories = (props) => {
    const {values} = props;

    const saveChange = () => {
        if(values.categories.length > 0){
            let payload = {
                "account": {"categories": values.categories}
            };

            props.updateAffiliate(payload, props.affiliate.cognito_id)
            setToastVerbiage("Categories Saved")
        }else{
            setToastVerbiage("Please select at least one category")
        }
    };

    const [toastVerbiage, setToastVerbiage] = useState(null);

    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)}/> : null;

    const maxSelected = values.categories && values.categories.length === 3;

    return (

        <div className="settings-shop__primary">
            <Frame>
                <div className="settings-general__section-label">
                    <h3 className="settings-general__section-title">
                        Interests
                    </h3>
                    <h5 className="settings-categories-subtitle">Select 1–3 categories that are most relevant to you and your audience.</h5>
                </div>
                    <div className="choices">
                        <ul className="settings-categories">
                            {props.categories.length > 0 ?
                                props.categories.map(category => {
                                    const checked = values.categories && values.categories.length > 0 && values.categories.includes(category.name);
                                    return (
                                        <li key={category.name}>
                                            <Checkbox label={category.name} checked={checked} disabled={!checked && maxSelected} onChange={() => props.handleCategorySelection(category.name)} data-test="category-checkbox" />
                                        </li>
                                    )
                                })
                                : 
                                [1,2,3,4,5,6,7,8,9,10,11,12].map((index) => {
                                    return (
                                        <li key={index} className="category-loading">
                                            <SkeletonBodyText lines={1} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="footer">
                        <ButtonGroup>
                            <Button id="product-categories-clear" secondary onClick={props.selectNone}>Clear</Button>
                            <Button id="product-categories-submit" primary onClick={saveChange}>Save Categories</Button>
                        </ButtonGroup>
                    </div>
            {toastMarkup}
            </Frame>
        </div>

    )
}

export default ProductCategories;