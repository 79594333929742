/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { StripeProvider } from 'react-stripe-elements';
import { STRIPE_KEY } from './config';
import { unregister } from './registerServiceWorker';

import reducer from './store/reducers';
import { NO_API_CALL } from './store/actions/types';
import './index.css';

// Amplify
import Auth from './Auth';

let middleware = {};
const logger = createLogger({
    predicate: (getState, action) => { return action.type !== NO_API_CALL; },
    collapsed: true
});

if (process.env.NODE_ENV === 'development') {
    middleware = applyMiddleware(promise(), logger);
} else {
    middleware = applyMiddleware(promise());
}

const store = createStore(reducer, middleware);

ReactDOM.render(
    <Provider store={store}>
        <StripeProvider apiKey={`${STRIPE_KEY}`}>
            <Router>
                <AppProvider i18n={enTranslations}>
                    <Auth />
                </AppProvider>
            </Router>
        </StripeProvider>
    </Provider>,
    document.getElementById('root')
);
unregister();
