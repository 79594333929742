import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from '@shopify/polaris';

import { updateAffiliate } from '../../../store/actions/action_updateAffiliate';
import { updateSmartLinkPreview } from '../../../store/actions/action_updateSmartlink';
import { updateCustomization } from '../../../store/actions/action_updateSmartlinkCustomization';

import ImageDrop, { saveImage } from '../../llama/image-drop';

import './smart-link-name-and-photo.css';

const SmartLinkNameAndPhoto = (props) => {
    const {
        submitRef,
        setSaving,
        onDone,

        affiliate,
        smartlinkProps,
        dispatchUpdateCustomization,
        dispatchUpdateSmartLinkPreview,
        dispatchUpdateAffiliate
    } = props;

    const [photo, setPhoto] = useState();
    const [name, setName] = useState({
        first: affiliate.account?.personal.first_name || '',
        last: affiliate.account?.personal.last_name || ''
    });
    const [errors, setErrors] = useState({
        first: '',
        last: '',
        image: ''
    });

    const onSetImage = (image) => {
        setPhoto(image);
        dispatchUpdateSmartLinkPreview({
            full_name: smartlinkProps.preview?.full_name || smartlinkProps.full_name,
            photo: image.url
        });
    };

    const handleNameChange = (nameType, value) => {
        const newName = {
            ...name,
            [nameType]: value.replace(/[<>{}]+/g, '')
        };
        setName(newName);

        const full_name = `${newName.first} ${newName.last}`;
        dispatchUpdateSmartLinkPreview({
            full_name,
            photo: smartlinkProps.preview?.photo || smartlinkProps.customizations.avatarUrl
        });
    };

    const save = async () => {
        // validation
        let hasErrors = false;
        const errorMessages = {
            first: '',
            last: '',
            image: ''
        };
        if (!name.first.trim()) {
            errorMessages.first = 'First name is required';
            hasErrors = true;
        }
        if (!name.last.trim()) {
            errorMessages.last = 'Last name is required';
            hasErrors = true;
        }

        setErrors(errorMessages);
        console.log('im a saving..');
        if (!hasErrors) {
            setSaving(true);

            if (affiliate.account?.personal?.first_name !== name.first.trim()
                && affiliate.account?.personal?.last_name !== name.last.trim()
            ) {
                const payload = {
                    account: {
                        personal: {
                            ...affiliate.account?.personal,
                            first_name: name.first.trim(),
                            last_name: name.last.trim()
                        }
                    }
                };

                payload.name = `${name.first.trim()} ${name.last.trim()}`;
                await dispatchUpdateAffiliate(payload, affiliate.cognito_id);
            }

            if (photo) {
                const customizations = { ...smartlinkProps.customizations };

                const saveResult = await saveImage(photo, smartlinkProps.unique_id, 'avatars', null, true);
                customizations.avatarUrl = saveResult.url;

                await dispatchUpdateCustomization(affiliate.cognito_id, customizations);
            }
            setSaving(false);
            onDone();
        }
    };

    submitRef.current = save;

    const onSubmit = (e) => {
        e.preventDefault();
        save();
    };

    return (
        <form className="smartlink-name-and-photo" onSubmit={onSubmit}>
            <ImageDrop
                buttonText="Upload a profile photo"
                setImage={onSetImage}
                currentImage={smartlinkProps.customizations.avatarUrl}
                crop="square"
                resize={300}
            />
            <div className="name">
                <TextField
                    label="First Name"
                    placeholder="John"
                    value={name.first}
                    onChange={(value) => { return handleNameChange('first', value); }}
                    error={errors.first}
                />
                <TextField
                    label="Last Name"
                    placeholder="Doe"
                    value={name.last}
                    onChange={(value) => { return handleNameChange('last', value); }}
                    error={errors.last}
                />
            </div>
            <button type="submit" style={{ display: 'none' }}>Save</button>
        </form>
    );
};

SmartLinkNameAndPhoto.propTypes = {
    
};

const mapStateToProps = (state) => {
    console.log('MAP STATE TO PROPS', state);
    return {
        affiliate: state.affiliate,
        smartlinkProps: state.smartlinks
    };
};

const mapDispatchtoProps = (dispatch) => {
    return {
        dispatchUpdateAffiliate: (data, congnito_id) => { return dispatch(updateAffiliate(data, congnito_id)); },
        dispatchUpdateSmartLinkPreview: (data) => { return dispatch(updateSmartLinkPreview(data)); },
        dispatchUpdateCustomization: (cognito_id, data) => { return dispatch(updateCustomization(cognito_id, data)); }
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLinkNameAndPhoto);
