import moment from 'moment';
import { sendGraphQL } from './sendRequest';

export const mockAnalytics = (links) => {
    const dateFormat = 'YYYY-MM-DD';

    return {
        summary: {
            totalViews: 1000,
            totalClicks: 500,
            totalCTR: 0.50,
            days: [
                {
                    date: moment().subtract(6, 'days').format(dateFormat),
                    views: Math.floor(Math.random() * 40),
                    clicks: Math.floor(Math.random() * 25)
                },
                {
                    date: moment().subtract(5, 'days').format(dateFormat),
                    views: Math.floor(Math.random() * 40),
                    clicks: Math.floor(Math.random() * 25)
                },
                {
                    date: moment().subtract(4, 'days').format(dateFormat),
                    views: Math.floor(Math.random() * 40),
                    clicks: Math.floor(Math.random() * 25)
                },
                {
                    date: moment().subtract(3, 'days').format(dateFormat),
                    views: Math.floor(Math.random() * 40),
                    clicks: Math.floor(Math.random() * 25)
                },
                {
                    date: moment().subtract(2, 'days').format(dateFormat),
                    views: Math.floor(Math.random() * 40),
                    clicks: Math.floor(Math.random() * 25)
                },
                {
                    date: moment().subtract(1, 'days').format(dateFormat),
                    views: Math.floor(Math.random() * 40),
                    clicks: Math.floor(Math.random() * 25)
                },
                {
                    date: moment().format(dateFormat),
                    views: Math.floor(Math.random() * 40),
                    clicks: Math.floor(Math.random() * 25)
                }
            ]
        },
        links: links.map((link) => {
            return {
                name: link.title,
                url: link.url,
                totalClicks: Math.floor(Math.random() * 200),
                totalCTR: Math.random() * 1,
                days: [
                    {
                        date: moment().subtract(7, 'days').format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    },
                    {
                        date: moment().subtract(6, 'days').format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    },
                    {
                        date: moment().subtract(5, 'days').format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    },
                    {
                        date: moment().subtract(4, 'days').format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    },
                    {
                        date: moment().subtract(3, 'days').format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    },
                    {
                        date: moment().subtract(2, 'days').format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    },
                    {
                        date: moment().subtract(1, 'days').format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    },
                    {
                        date: moment().format(dateFormat),
                        views: Math.floor(Math.random() * 40),
                        clicks: Math.floor(Math.random() * 25)
                    }
                ]
            };
        })
    };
};

const getSmartlinksAnalytics = (cognito_id, startDate, endDate) => {
    const query = `query getSmartLinksAnalytics($cognito_id: String!, $filters: SmartLinksAnalyticsInput) {
        getSmartLinksAnalytics(cognito_id: $cognito_id, filters: $filters) {
            summary{
                totalViews
                totalClicks
                totalCTR
                days {
                    date
                    views
                    clicks
                }
            }
            links{
                name
                url
                totalClicks
                totalCTR
                days{
                    date
                    views
                    clicks
                }
            }
        }
    }`;

    const variables = {
        cognito_id,
        filters: {
            fromDate: startDate,
            toDate: endDate
        }
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'GET_SMARTLINKS_ANALYTICS',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch((error) => { return error; })
    };
};

export default getSmartlinksAnalytics;
