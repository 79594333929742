import axios from 'axios';

import parseGraph from '../../utils/parse-graph';
//import { NEXT_APP_API_URL, NEXT_S3_DOWNLOAD_URL } from '../../config';
import { sendGraphQL, sendBackendRequest } from './sendRequest';

export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING';
export const UPLOAD_FILE_FULFILLED = 'UPLOAD_FILE_FULFILLED';
export const UPLOAD_FILE_REJECTED = 'UPLOAD_FILE_REJECTED';


export const uploadFileToS3 = async (file, path, progress) => {

    if (!file) return null;

    const query = `
        query retrieveS3UploadURL($name: String!, $type: String!) { 
            retrieveS3UploadURL(name: $name, type: $type) 
        }
    `

    const variables = {
        type: file.type,
        name: `${path}/${file.name}`
    }

    let payload = {
        query,
        variables
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(NEXT_APP_API_URL, payload, { headers }) */
    return sendGraphQL(payload)
        .then(parseGraph)
        .then(data => {

            if (!data || !data.retrieveS3UploadURL) {
                return Promise.reject(new Error(`Unable to upload ${file.name}`));
            }

            return data.retrieveS3UploadURL;
        })
        .then((uploadURL) => {
            const headers = {
                "Content-Type": file.type
            }

            const onUploadProgress = (progressEvent) => {
                const completePercent = Math.round( (progressEvent.loaded * 100) / progressEvent.total);
                if (progress) {
                    progress(completePercent);
                }
            }

            return axios.put(uploadURL, file, { headers, onUploadProgress })
                .then(() => uploadURL);
        })

}

export const downloadFileFromS3 = (path) => {
    let payload = {
        path
    };
    return sendBackendRequest('general', 'downloadCreative', payload)
        .then(response => response);
    /* return axios.post(NEXT_S3_DOWNLOAD_URL, payload)
        .then(data => {
            return data;
        }) */
};
