/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from "./sendRequest";

export function createSmartlinkSubscription(cognitoId, plan, cardToken, last4) {
    console.log('createSmartlinkSubscription::', cognitoId, plan, cardToken, last4);
    const query = `
    mutation createSmartlinkSubscription($cognito_id: String!, $plan: String!, $card_token: String!, $last4: String) {
        createSmartlinkSubscription(cognito_id: $cognito_id, plan: $plan, card_token: $card_token, last4: $last4) {
            subscription_id
            status
            latest_invoice
            had_custom_theme
        }
    }
    `
    const variables = {
        cognito_id: cognitoId,
        plan,
        card_token: cardToken,
        last4
    }

    let payload = {
        query,
        variables
    }

    return {
        type: 'CREATE_SMARTLINK_SUBSCRIPTION',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'CREATE_SMARTLINK_SUBSCRIPTION',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}

export function reapplySmartlinksCustomTheme(cognitoId){
    const query = `
    mutation reapplySmartlinksCustomTheme($cognito_id: String!) {
        reapplySmartlinksCustomTheme(cognito_id: $cognito_id) {
            themeApplied
        }
    }
    `
    const variables = {
        cognito_id: cognitoId
    }

    let payload = {
        query,
        variables
    }

    return {
        type: 'REAPPLY_SMARTLINKS_CUSTOM_THEME',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'REAPPLY_SMARTLINKS_CUSTOM_THEME',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}


export function cancelSmartlinkSubscription(cognitoId) {
    const query = `
        mutation cancelSmartlinkSubscription($cognito_id: String!) {
            cancelSmartlinkSubscription(cognito_id: $cognito_id) {
                subscription_id
                status
            }
        }
    `
    const variables = {
        cognito_id: cognitoId
    }

    let payload = {
        query,
        variables
    }

    return {
        type: 'CANCEL_SMARTLINK_SUBSCRIPTION',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'CANCEL_SMARTLINK_SUBSCRIPTION',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}