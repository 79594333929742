import React, { useState } from 'react';
import { Page, TextStyle, TextField, Spinner, Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons'

import ApplicationItem from './application-item.component';
import { LlamaPagination, LlamaSpinner } from '../llama';
import usePages from '../../hooks/use-pages';
import { ApplicationStatus } from '../../utils/types';
import ApplicationsHeader from './applications-header.component';
import './applications.css';

/**
 * Tabs for applications page.
 * - key: Should match the name on the parent object.
 * - name: Displayed value for the tab.
 * - filter: Function to apply as the param for filtering the applications.
 */
export const applicationTabs = {
    all: {
        key: 'all',
        name: 'All',
        filter: () => true,
    },
    approved: {
        key: 'approved',
        name: 'Approved',
        filter: (item) => item.advertiser_status === ApplicationStatus.APPROVED,
    },
    pending: {
        key: 'pending',
        name: 'Pending',
        filter: (item) => item.advertiser_status === ApplicationStatus.PENDING,
    },
}

export const MAX_APPLICATIONS_PER_PAGE = 10;

/**
 * @component Applications
 *
 * @prop {Array<Application>} applications
 * @prop {Boolean} isLoading
 */
const Applications = ({ applications, searchTerm, setSearchTerm, isLoading, history }) => {

    const [currentTab, setTab] = useState(applicationTabs.all.key);
    // Filter applications based on current tab selection.
    const filteredApplications = Array.isArray(applications)
        ? applications.filter(applicationTabs[currentTab].filter)
        : null;

    // Get pagination values.
    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, MAX_APPLICATIONS_PER_PAGE, filteredApplications);

    const sortedApplications = applications && applications
        .sort((a, b) => {
            if (a.created_at < b.created_at) return 1;
            if (b.created_at < a.created_at) return -1;
            return 0;
        });



    if (posts && posts.length > 0) {
        posts.sort((a, b) => {
            const parsedDateA = new Date(Date.parse(a.created_at));
            const parsedDateB = new Date(Date.parse(b.created_at));

            const aDay = parsedDateA.getDate();
            const bDay = parsedDateB.getDate();

            const aMonth = parsedDateA.getMonth();
            const bMonth = parsedDateB.getMonth();

            const aYear = parsedDateA.getFullYear();
            const bYear = parsedDateB.getFullYear();

            // If a's year is older
            if (aYear < bYear) {
                return 1
            }

            // If both are in same year
            if (aYear === bYear) {
                // If a is older month
                if (aMonth < bMonth) {
                    return 1
                }

                // If a and b are in same month
                if (aMonth === bMonth) {
                    if (aDay < bDay) {
                        return 1;
                    }
                    if (bDay < aDay) {
                        return -1
                    }
                }

                // If b is older Month
                if (bMonth < aMonth) {
                    return -1
                }
            }

            // If b's year is older
            if (bYear < aYear) {
                return -1
            }

            return 0;
        })
    }

    const searchBarSuffix = isLoading
        ? <div style={{ transform: 'scale(0.4)', marign: '0', height: '1rem', position: 'relative', top: '-0.6rem', left: '1rem' }}><Spinner /></div>
        : <Icon source={SearchMinor} color="inkLightest" />
    return (
        <div data-test="component-applications">
            <Page separator={true}>

                <ApplicationsHeader applications={sortedApplications} data-test="applications-header" />

                <div className='Applications__SearchSection'>
                    <TextField
                        value={searchTerm}
                        onChange={(value) => { setSearchTerm(value) }}
                        prefix={searchBarSuffix}
                        placeholder="Search"
                    />
                </div>

                {/* Tabs for selecting filters */}
                <div className="Applications__Tabs">
                    {Object.entries(applicationTabs).map(([key, tab]) => {

                        let classes = ['Applications__Tab'];
                        const activeTab = currentTab === tab.key;
                        if (activeTab) {
                            classes.push('Applications__Tab--Active');
                        }

                        const selectTab = () => {
                            setTab(tab.key);
                        }

                        return (
                            <div
                                className={classes.join(' ')}
                                onClick={selectTab}
                                key={tab.key}
                                data-test="application-tab"
                                data-test-tabkey={key}
                                data-test-tabstate={activeTab ? 'active' : 'inactive'}
                            >
                                {tab.name}
                            </div>
                        )
                    })}
                </div>

                <table className="Applications__Table">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Offer</th>
                            <th>Date Applied</th>
                            <th>Application Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading && (!posts || posts.length === 0) && (
                            <tr><td colSpan="3"><LlamaSpinner data-test="applications-loading-state" /></td></tr>
                        )}
                        {posts && (
                            posts.length > 0 ? (
                                posts.map((application, index) => (
                                    <ApplicationItem
                                        application={application}
                                        key={application.application_id}
                                        index={index}
                                        history={history}
                                        data-test="application-item"
                                    />
                                ))
                            ) : (
                                <tr className="Applications__EmptyState" data-test="applications-empty-state">
                                    <td colSpan="3"><TextStyle variation="subdued">No applications to display.</TextStyle></td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>

                {/*
                    {/* Table Header Row *}
                    <div className="Applications__Header Applications__Row">
                        <div className="Applications__HeaderItem Applications__Store">Merchant</div>
                        <div className="Applications__HeaderItem Applications__Offer">Offer</div>
                        <div className="Applications__HeaderItem Applications__Status">Status</div>
                    </div>

                    {/* Render Spinner if applications are still being loaded. *}
                    {isLoading && (!posts || posts.length === 0) && (
                        <LlamaSpinner data-test="applications-loading-state"/>
                    )}

                    {/* Render Applications, or Empty State if array is empty. *}
                    {posts && (
                        <section className="Applications__Body">
                            {posts.length > 0 ? (
                                posts.map((application, index) => (
                                    <ApplicationItem
                                        application={application}
                                        key={application.application_id}
                                        index={index}
                                        data-test="application-item"
                                    />
                                ))
                            ) : (
                                <div
                                    className="Applications__EmptyState" data-test="applications-empty-state"
                                >
                                    <TextStyle variation="subdued">No applications to display.</TextStyle>
                                </div>
                            )}
                        </section>
                    )}
                    */}
                {maxPages > 0 &&
                    <LlamaPagination
                        data-test="applications-pagination"
                        hasNext={hasNext}
                        hasPrevious={hasPrevious}
                        onNext={nextPage}
                        onPrevious={previousPage}
                        currentPage={currentPage}
                        maxPages={maxPages}
                    />
                }
            </Page>
        </div>
    );
}

export default Applications;