import React from 'react';
import { I18n, Auth } from 'aws-amplify';
import { PasswordRequirements, LlamaButton } from 'llama-library/components';
import {
    AuthPiece,
    SectionBody,
    InputRow,
    Link
} from 'aws-amplify-react';

import llamaHead from '../assets/llama-head.png';
import loginGraphic from '../assets/ambassador-login-graphic.svg';
import llamaBgMobile from '../assets/login-graphic-mobile.png';

const logger = {
    debug: (msg) => {
        if (process.env.NODE_ENV === "development") {
            console.log(msg)
        }
    }
};

export default class LlamaPassword extends AuthPiece {
	constructor(props) {
        super(props);
        
        this.state = {
            values: {
                username: '',
                code: '',
                password: ''
            },
            dataSet: false,
            sendCodeLoading: false,
            changePasswordLoading: false,
            delivery: null
        }

        this._validAuthStates = ['forgotPassword'];

        this.onChange = this.onChange.bind(this);
        this.sendCode = this.sendCode.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.keyCode !== 13) {
            return;
        }

        if (this.props.authState === 'forgotPassword') {
            if (!this.state.delivery) {
                this.sendCode();
            } else {
                this.changePassword();
            }
        }
    }

	sendCode() {
		const {username} = this.inputs;
		if (!username || username === '') {
			this.error('Email is required');
			return;
        }
        this.setState({sendCodeLoading: true})
		Auth.forgotPassword(username.toLowerCase())
			.then(data => {
                if(this.state.delivery){
                    this.error('Ok, we just sent a new code to '+ this.state.delivery.Destination)
                }
                this.setState({sendCodeLoading: false})
				logger.debug(data);
				this.setState({ delivery: data.CodeDeliveryDetails });
			})
			.catch(err => {
                this.setState({sendCodeLoading: false})
                if(err.code === "InvalidParameterException" && err.message.indexOf('username') !== -1){
                    this.error('Invalid email address format')
                }else if(err.code === 'UserNotFoundException'){
                    const safeEmail = username.replace('/[<>/:]+/g')
                    this.error('Sorry, we couldn\'t find an account for '+safeEmail+'. Please enter the email you signed up to Llama with.')
                }else{
                    this.error(err)
                }
            });
	}

	changePassword() {
        if(!this.inputs.code && !this.inputs.password){
            return this.error('Verification Code and Password are required.');
        }
        if(this.inputs.code && !this.inputs.password){
            return this.error('Password is required.');
        }
        if(!this.inputs.code && this.inputs.password){
            return this.error('Verification Code is required.');
        }
        this.setState({changePasswordLoading: true})
        const { username, code, password } = this.inputs;

		Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
			.then(data => {
				logger.debug(data);
                this.changeState('signIn');
			})
			.catch(err => {
                this.setState({changePasswordLoading: false})
                this.error(err)
            });
	}

	onChange(event) {
        const { name, value } = event.target;
        const newValues = { ...this.state.values };
        newValues[name] = value;

        this.setState({ values: newValues });
        this.handleInputChange(event)
    }

    showComponent(theme) {
        const applyButtonClass = ['amplify-button'];

        if (!this.state.delivery) {
            return (
                <div className="signin-amplify__wrapper">
                    <div className="signin-amplify__form-wrapper">
                        <div className="signin-amplify__signin-form">
                            <div className="signin-amplify__mobile-wrapper">
                                <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                                <div className="signin-amplify__mobile-text">
                                    <h1 className="signin-amplify__header">Reset Your Password</h1>
                                    <p className="signup-amplify__subheader">Enter your email and we'll send you a verification code you can use to reset your password.</p>
                                </div>
                            </div>
                            <SectionBody theme={theme}>
                                <div className="amplify-form-item">
                                    <div className="amplify-input-wrapper">
                                        <InputRow
                                            autoFocus
                                            placeholder={I18n.get('Email')}
                                            theme={theme}
                                            key="username"
                                            name="username"
                                            required="true"
                                            onChange={this.onChange}
                                            value={this.state.values['username']}
                                        />
                                    </div>
                                </div>
                            </SectionBody>
                            <div className="amplify__custom-footer">
                                <div className="amplify__button-wrapper">
                                    <LlamaButton
                                        background="rgb(253, 42, 84)"
                                        classes={applyButtonClass}
                                        theme={theme} 
                                        onClick={this.sendCode} 
                                        loading={this.state.sendCodeLoading}
                                    >
                                        {I18n.get('Send Code')}
                                    </LlamaButton>
                                </div>
                                <div className="amplify-section-footer_links">
                                    <div className="amplify__custom-link">
                                        <Link theme={theme} onClick={() => this.changeState('signIn')}>
                                            {I18n.get('Back to Sign In')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="signin-amplify__graphic-outer">
                        <img src={llamaBgMobile} className="signin-amplify__graphic-mobile" /> 
                        <img alt="Leo the Llama Merchant and his shop" src={loginGraphic} className="signin-amplify__login-graphic" />
                    </div>
                </div>
            );
        }

        return (
            <div className="signin-amplify__wrapper">
                <div className="signin-amplify__form-wrapper">
                    <div className="signin-amplify__signin-form">
                        <div className="signin-amplify__mobile-wrapper">
                            <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                            <div className="signin-amplify__mobile-text">
                                <h1 className="signin-amplify__header">Reset Your Password</h1>
                                <p className="signup-amplify__subheader">Please enter the verification code we just sent you and create your new password.</p>
                            </div>
                        </div>
                        <SectionBody theme={theme}>
                            <div className="amplify-form-item">
                                <div className="amplify-input-wrapper">
                                    <InputRow
                                        placeholder={I18n.get('Verification Code')}
                                        theme={theme}
                                        key="code"
                                        name="code"
                                        required="true"
                                        autocomplete="false"
                                        onChange={this.onChange}
                                        value={this.state.values['code']}
                                    />
                                </div>
                                <div className="amplify-label__wrapper">
                                    <div className="amplify-email__label">
                                        Haven't received a code? Check your spam folder or <Link theme={theme} onClick={this.sendCode}>
                                            {I18n.get('click here to resend the code.')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="amplify-form-item">
                                <div className="amplify-input-wrapper">
                                    <InputRow
                                        placeholder={I18n.get('New Password')}
                                        theme={theme}
                                        key="password"
                                        name="password"
                                        type="password"
                                        required="true"
                                        autocomplete="false"
                                        onChange={this.onChange}
                                        value={this.state.values['password']}
                                    />
                                </div>
                                <PasswordRequirements password={this.inputs.password}/>
                            </div>
                        </SectionBody>
                        <div className="amplify__custom-footer">
                            <div className="amplify__button-wrapper">
                                <LlamaButton
                                    background="rgb(253, 42, 84)"
                                    classes={applyButtonClass}
                                    theme={theme} 
                                    onClick={this.changePassword}
                                    loading={this.state.changePasswordLoading}
                                >
                                    {I18n.get('Change Password')}
                                </LlamaButton>
                            </div>
                            <div className="amplify-section-footer_links">
                                <div className="amplify__custom-link">
                                    <Link theme={theme} onClick={() => this.changeState('signIn')}>
                                        {I18n.get('Back to Sign In')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signin-amplify__graphic-outer">
                    <img src={llamaBgMobile} className="signin-amplify__graphic-mobile" /> 
                    <img alt="Leo the Llama Ambassador and his network" src={loginGraphic} className="signin-amplify__login-graphic" />
                 </div>
            </div>
        );
    }
}
