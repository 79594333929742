/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Layout
} from '@shopify/polaris';

import { changeHeaderTitle } from '../../store/actions/header';
import './llama-privacy.css';

const LlamaPrivacy = (props) => {

    useEffect(() => {
		props.changeHeaderTitle("Privacy Policy");
	}, [])

    return (
        <Page>
            <Layout>

                <Layout.Section>
                    <div className="help_container">
                        <p className="help_content">
                            <b> PRIVACY POLICY
                            </b>
                            <br/><br/>
                            <b>LLAMA. LLC </b>
                            <br/><br/>
                            Last Updated on March 29, 2018
                            This privacy policy (the <b>“Policy”</b>) describes the treatment of information
                            provided or collected on the
                            website where this privacy policy is posted. The purpose of this Policy is to describe
                            how the LLAMA,
                            .LLC (the <b>“Company”, “we”</b> or <b> “us”</b>) website (the <b> “Website” </b>)
                            collects, shares and secures
                            information provided by you. Therefore, before you provide the Company with any personal
                            information,
                            you should read through this Policy in its entirety and understand its terms. We may
                            update the Policy
                            from time to time, so please check in occasionally. <br/><br/>
                            1. INFORMATION WE COLLECT <br/><br/>
                            2. HOW WE COLLECT YOUR INFORMATION <br/><br/>
                            3. WHAT WE DO WITH YOUR INFORMATION <br/><br/>
                            4. SHARING YOUR INFORMATION <br/><br/>
                            5. YOUR CONTROLS AND CHOICES <br/><br/>
                            6. SECURITY MEASURES <br/><br/>
                            7. HOW WE COMMUNICATE WITH YOU <br/><br/>
                            8. CHILDREN <br/><br/>
                            9. CHANGES TO THIS POLICY <br/><br/>
                            10. NOTICE TO CALIFORNIA RESIDENTS <br/><br/>
                            11. CALIFORNIA DO NOT TRACK DISCLOSURES <br/><br/>

                            <b>1. INFORMATION WE COLLECT: <br/><br/> </b>
                            At the Website, you may choose to provide the Company with information about yourself
                            which is
                            “personally identifiable information.” The term “personally identifiable information”
                            means information
                            that is collected in connection with your name such as your address, e-mail, phone
                            number, credit card
                            information and shipment information (<b>“Personal Information”</b>). Your use of the
                            Website may also
                            entail you providing information that is not personal information, meaning that such
                            information is not
                            connected to your name and is, therefore, anonymous information and which does not
                            identify and cannot
                            reasonably used to identify an individual Website user. It is your choice whether to
                            provide us with
                            Personal Information and the provision of such Personal Information is not required when
                            visiting the
                            Website. Therefore, if you provide us with Personal Information, such as when you
                            register on the
                            Website, we may contact you from time to time.
                            <br/><br/>
                            Some examples of the types of information we collect are:
                            <br/><br/>

                            <li> Registration information you provide when you create an account, including your
                                first name and
                                surname, country of residence, gender, date of birth, email address, username and
                                password.
                            </li>
                            <li>Transaction information you provide when you request information or disclose
                                information
                                relating to your Paypal account for purposes of commission payment.
                            </li>
                            <li>Information you provide in public forums on our Website by blogging, providing
                                testimonials,
                                posting pictures and the like.
                            </li>
                            <li>Information you provide us when you use our Website or when you link your profile on
                                a third-
                                party site or platform with your registration account.
                            </li>
                            <li>Location information when you visit our Website, including location information
                                either provided
                                by a mobile device interacting with our Website (including through beacon
                                technologies) or
                                associated with your IP address, where we are permitted by law to process this
                                information.
                            </li>
                            <li>Usage, viewing and technical data, including your device identifier or IP address,
                                when you visit
                                our Website or open emails we may send you.
                            </li>

                            <br/><br/>
                            <b>
                                2. HOW WE COLLECT YOUR INFORMATION AND COOKIES: <br/><br/>
                            </b>

                            <li> We collect information you provide to us when you request products, services or
                                information
                                from us, register with us, participate in public forums or other activities on our
                                Website and any
                                platforms which permit us to interact directly with you, respond to customer surveys
                                or otherwise
                                interact with us. Please keep in mind that when you provide information to us on a
                                third-party site
                                or platform (e.g., Facebook, Instagram, and so forth), that the information you
                                provide may be
                                separately collected by the third-party site or platform. The information we collect
                                is covered by
                                this Policy and the information the third-party site or platform collects is subject
                                to the third-party
                                site or platform’s privacy practices and, therefore, you should familiarize yourself
                                with any
                                applicable third-party privacy policy as needed.
                            </li>
                            <li>We collect information through technology such as cookies (which are information
                                stored on
                                your computer by your browser to save your preferences while you are visiting a
                                particular site)
                                and web beacons (small pieces of data that are embedded in images on the pages of
                                websites)
                                when you visit our Website. This tracking data is used for many purposes including,
                                for example,
                                to provide useful features to simplify your experience when you return to our
                                Website (e.g.,
                                remembering your shipping information for future purchases); deliver relevant
                                content based on
                                your preferences, usage patterns and location; monitor and evaluate the use and
                                operation of our
                                Website; and to analyze traffic on our Website and the sites of third parties. We
                                may associate
                                this tracking data with your registration account, in which case we will treat it as
                                personal
                                information. Service providers that collect tracking data on our behalf may provide
                                an
                                opportunity for you to choose not to be tracked online. For more information about
                                cookies and
                                web beacons, please visit: www.allaboutcookies.org .
                            </li>
                            <li>Advertisers and third parties may also collect information about your activity on
                                our Website and
                                on third-party sites and applications using tracking technologies. Tracking data
                                collected by these
                                advertisers and third parties is used to decide which ads you see both on our
                                Website and on
                                third-party sites and applications, but does not identify you personally and is not
                                associated with
                                your registration account.
                            </li>
                            <li>If you choose, you can set your browser to reject cookies or you can manually delete
                                individual or
                                all of the cookies on your computer by following your browser’s help file
                                directions. In addition,
                                you can always opt-out of receiving certain ads or communications.
                            </li>
                            <li>As you enter our Website, we capture and retain the IP address of the device you are
                                using, such
                                as a personal computer or a mobile device.
                            </li>
                            <br/><br/>
                            <b>3. WHAT WE DO WITH YOUR INFORMATION: </b>
                            <br/><br/>
                            The Company may use information provided by you on the Website for the following
                            purposes:
                            <li>Provide you information relating to the app and any shops that may be pertinent to
                                your interests. </li>
                            <li>Communicate with you about your account or transactions with us and send you
                                information
                                about features on our Website or changes to our policies.
                            </li>
                            <li>Consistent with local law and choices and controls that may be available to you:
                                - Send you offers and promotions for our products and services or third-party
                                products and
                                services.
                            </li>
                            - Personalize content and experiences. <br/><br/>
                            - Provide you with advertising based on your activity on our Website and on third-party
                            sites and applications. <br/><br/>

                            -Optimize or improve our products, services and operations.
                            -Detect, investigate and prevent activities that may violate our policies or be illegal.
                            <br/><br/>
                            <b>4. SHARING YOUR INFORMATION: </b>

                            Information about our customers is important to us and we are not in the business of
                            selling this
                            information to others. We share customer information only as set forth below and with
                            subsidiaries that
                            the Company controls or its parent company and that are either subject to this Policy or
                            that follow
                            practices that are at least as restrictive as those described in this Policy.
                            <li>Service Providers. We may share information with third party companies or
                                individuals that
                                provide us with services. These services may include, among other things, helping us
                                to provide
                                services that you request, to create or maintain our databases, to research and
                                analyze the people
                                who request products, services or information from us, the shipment of products to
                                you, to
                                prepare and distribute communications, or to respond to inquiries. </li>
                            <li>Business Transactions. In the event that we, or any portion of our assets, are
                                acquired or we
                                undergo another transaction in our business, your personal information may be
                                transferred to the
                                acquiring company or other entity surviving such transaction.
                            </li>
                            <li>Partner Companies. We may share your personal information with another company when
                                you
                                allow us to, in situations such as:
                                o Electing to share your personal information with other companies so that they can
                                send
                                you offers and promotions about their products and services; and
                                o Directing us to share your personal information with third-party sites or
                                platforms, such
                                as social networking sites.
                            </li>

                            <li>Law Enforcement. We may report to law enforcement agencies any activities that we
                                reasonably
                                believe to be unlawful, or that we reasonably believe may aid a law enforcement
                                investigation
                                into unlawful activity. In addition, we reserve the right to release your
                                information to law
                                enforcement agencies if we determine, in our sole judgment, that either you have
                                violated our
                                policies, or the release of your information may protect the rights, property, or
                                safety of Kind
                                Courier or another person.
                            </li>
                            <li>Legal Process. We may share your information with others as required by, or
                                permitted by, law.
                                This may include sharing your information with government entities, or third parties
                                in response
                                to subpoenas, court orders, other legal process, or as we believe is necessary to
                                exercise our legal
                                rights, to defend against legal claims that have been brought against us, or to
                                defend against
                                possible legal claims that we determine in our sole discretion might be brought
                                against us.
                            </li>
                            <li>Blogs and other Online Postings. We may decide to allow users to share comments,
                                postings,
                                testimonials, or other information. If you choose to submit such information to us,
                                the
                                information you submit may be available generally to the public. Information that
                                you provide in
                                these areas may be read, collected, and used by others who access them.
                            </li>
                            <br/><br/>
                            <b>5. YOUR CONTROLS AND CHOICES:
                            </b>
                            <br/><br/>
                            We provide you the ability to exercise certain controls and choices regarding our
                            collection, use and
                            sharing of your information. In accordance with local law, your controls and choices may
                            include:
                            <li> You may correct, update and delete your registration account.</li>
                            <li> You may change your choices for subscriptions, newsletters and alerts.</li>
                            <li> You may choose whether to receive from offers from us and promotions for our
                                products.
                            </li>
                            <li>You may choose whether we share your personally identifiable information with other
                                companies
                                so they can you offers and promotions about their products and services.
                            </li>
                            <li> You may choose whether to receive targeted advertising from many ad networks, data
                                exchanges,
                                marketing analytics and other service providers.
                            </li>
                            <li> You may request access to the personal information we hold about you and that we
                                amend or
                                delete such information.
                            </li>
                            <br/><br/>
                            You may exercise your controls and choices, or request access to your personal
                            information, by
                            contacting us at the phone number or email set forth in Section 7, by updating your user
                            profile or by
                            following instructions provided in communications sent to you. Please be aware that if
                            you do not allow
                            us to collect personal information from you, we may not be able to deliver certain
                            products to you and
                            some of our services may not be able to take account of your interests and preferences.
                            If you have
                            questions regarding the specific personal information about you that we process or
                            retain, please contact
                            us at the phone number or email in Section 7.
                            <br/><br/>
                            <b>6. SECURITY MEASURES:</b>
                            <br/><br/>
                            The security, integrity and confidentiality of your information are extremely important
                            to us. We take all
                            reasonable and appropriate measures to secure your personal information once we receive
                            it, and to help
                            prevent it from being disclosed to individuals who are not described in this Policy.
                            Please be aware
                            though that, despite our best efforts, no security measures are perfect or impenetrable
                            and, therefore, we
                            cannot guarantee that your information will remain secure in all circumstances.
                            <br/><br/>
                            <b>
                                7. HOW WE COMMUNICATE WITH YOU: </b>
                            <br/><br/>
                            If you have any questions about our Policy, you can contact us at info@llama.app or
                            1.800.689.1260.
                            <br/><br/>
                            If we need, or are required, to contact you concerning any event that involves your
                            personal information,
                            we may do so by email, telephone or mail.
                            <br/><br/>
                            <b>8. CHILDREN:</b>
                            <br/><br/>
                            We are committed to protecting the online privacy of children and making the internet
                            safe. We do not
                            provide products and services to children, or knowingly collect or solicit personal
                            information from
                            children under 18 years of age. Any communication we get that is identified as being
                            from a child under
                            18 will not be kept by us. We encourage parents or guardians of children under 18 to
                            regularly check and
                            monitor their children’s use of email and other activities online.

                            <br/><br/>
                            <b>9. CHANGES TO THIS POLICY: </b>
                            <br/><br/>
                            From time to time we may change this Policy to accommodate new technologies, industry
                            practices,
                            regulatory requirements or for other purposes. We will provide notice to you if these
                            changes are material
                            and, where required by applicable law, we will obtain your consent.
                            <br/><br/>
                            <b>
                                10. NOTICE TO CAILFORNIA RESIDENTS:
                            </b>
                            <br/><br/>
                            If you are a California resident, California Civil Code Section 1798.83 permits you to
                            request a notice
                            regarding the disclosure of your personal information by the Company to partners for the
                            partners’ direct
                            marketing purpose. If you are a California resident and would like a copy of this
                            notice, please contact us
                            at the phone number or email in Section 7.
                            <br/><br/>
                            <b>
                                11. CALIFORNIA DO NOT TRACK DISCLOSURES:
                            </b>
                            <br/><br/>
                            We honor do not track signals and do not track, plant cookies, or use advertising when a
                            Do Not Track (DNT) browser mechanism is in place.

                            <br/><br/>

                        </p>
                    </div>
                </Layout.Section>
            </Layout>
        </Page>
    )
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
	}
}

export default connect(null, mapDispatchToProps)(LlamaPrivacy);