/**
 * Author: Rakesh P (jake@convert2media.com)
 * App: LLAMA APP
 * Date: January 06, 2021
 * */

import { sendGraphQL } from './sendRequest';

const GET_TIKTOK = 'GET_TIKTOK';
export const GET_TIKTOK_PENDING = `${GET_TIKTOK}_PENDING`;
export const GET_TIKTOK_FULFILLED = `${GET_TIKTOK}_FULFILLED`;
export const GET_TIKTOK_REJECTED = `${GET_TIKTOK}_REJECTED`;

export const getTikTokData = (cognito_id) => {
    if (!cognito_id) {
      return null;
    }

    const query = `
        query affiliateById($cognito_id:String!) {
            affiliateById(cognito_id:$cognito_id) {
                cognito_id
                tracking_id
                tikTokData {
                    _id
                    cognito_id
                    username
                    account_type
                    account_hash
                    account_verified
                    trial_expiration
                    aqs
                    aqs_name
                    basic {
                      id
                      username
                      title
                      avatar_url
                      description
                    }
                    metrics {
                      subscribers_count {
                        value
                        performance {
                          last_30d {
                            value
                          }
                          last_90d {
                            value
                          }
                          last_180d {
                            value
                          }
                        }
                      }
                      views_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      subscribers_growth_prc {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      er {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      alikes_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      comments_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      shares_avg {
                        value
                        performance {
                          last_30d {
                            value
                            min
                            max
                          }
                          last_90d {
                            value
                            min
                            max
                          }
                          last_180d {
                            value
                            min
                            max
                          }
                        }
                      }
                      comments_likes_ratio {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                      post_frequency {
                        value
                        performance {
                          last_30d {
                            value
                            mark
                            similar
                          }
                          last_90d {
                            value
                            mark
                            similar
                          }
                          last_180d {
                            value
                            mark
                            similar
                          }
                        }
                      }
                    }
                    features {
                      audience_age_gender {
                        data {
                          age13_17 {
                            male
                            female
                          }
                          age18_24 {
                            male
                            female
                          }
                          age25_34 {
                            male
                            female
                          }
                          age45_54 {
                            male
                            female
                          }
                          age55_64 {
                            male
                            female
                          }
                          age65 {
                            male
                            female
                          }
                        }
                      }
                      audience_geo {
                        data {
                          countries {
                            id
                            code
                            prc
                            name
                          }
                        }
                      }
                    }                    
                }
            }
        }
    `;

    const variables = { cognito_id };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_TIKTOK,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

const INITIATE_TIKTOK_TRIAL = 'INITIATE_TIKTOK_TRIAL';
export const INITIATE_TIKTOK_TRIAL_PENDING = `${INITIATE_TIKTOK_TRIAL}_PENDING`;
export const INITIATE_TIKTOK_TRIAL_FULFILLED = `${INITIATE_TIKTOK_TRIAL}_FULFILLED`;
export const INITIATE_TIKTOK_TRIAL_REJECTED = `${INITIATE_TIKTOK_TRIAL}_REJECTED`;

export const initiateTikTokTrial = (username, cognito_id, stripe_token, interval ) => {
    if (!username || !cognito_id) {
        return null;
    }

    const query = `mutation createTikTokUser($username: String, $cognito_id: String, $stripe_token: String, $interval: HypeAuditorAccountType) {
        createTikTokUser(username: $username, cognito_id: $cognito_id, stripe_token: $stripe_token, interval: $interval) {
          _id
          cognito_id
          username
          account_type
          account_hash
          account_verified
          trial_expiration
          aqs
          aqs_name          
          error {
            message
          }
          basic {
            id
            username
            title
            avatar_url
            description
          }
          metrics {
            subscribers_count {
              value
              performance {
                last_30d {
                  value
                }
                last_90d {
                  value
                }
                last_180d {
                  value
                }
              }
            }
            views_avg {
              value
              performance {
                last_30d {
                  value
                  min
                  max
                }
                last_90d {
                  value
                  min
                  max
                }
                last_180d {
                  value
                  min
                  max
                }
              }
            }
            subscribers_growth_prc {
              value
              performance {
                last_30d {
                  value
                  mark
                  similar
                }
                last_90d {
                  value
                  mark
                  similar
                }
                last_180d {
                  value
                  mark
                  similar
                }
              }
            }
            er {
              value
              performance {
                last_30d {
                  value
                  mark
                  similar
                }
                last_90d {
                  value
                  mark
                  similar
                }
                last_180d {
                  value
                  mark
                  similar
                }
              }
            }
            alikes_avg {
              value
              performance {
                last_30d {
                  value
                  min
                  max
                }
                last_90d {
                  value
                  min
                  max
                }
                last_180d {
                  value
                  min
                  max
                }
              }
            }
            comments_avg {
              value
              performance {
                last_30d {
                  value
                  min
                  max
                }
                last_90d {
                  value
                  min
                  max
                }
                last_180d {
                  value
                  min
                  max
                }
              }
            }
            shares_avg {
              value
              performance {
                last_30d {
                  value
                  min
                  max
                }
                last_90d {
                  value
                  min
                  max
                }
                last_180d {
                  value
                  min
                  max
                }
              }
            }
            comments_likes_ratio {
              value
              performance {
                last_30d {
                  value
                  mark
                  similar
                }
                last_90d {
                  value
                  mark
                  similar
                }
                last_180d {
                  value
                  mark
                  similar
                }
              }
            }
            post_frequency {
              value
              performance {
                last_30d {
                  value
                  mark
                  similar
                }
                last_90d {
                  value
                  mark
                  similar
                }
                last_180d {
                  value
                  mark
                  similar
                }
              }
            }
          }
          features {
            audience_age_gender {
              data {
                age13_17 {
                  male
                  female
                }
                age18_24 {
                  male
                  female
                }
                age25_34 {
                  male
                  female
                }
                age45_54 {
                  male
                  female
                }
                age55_64 {
                  male
                  female
                }
                age65 {
                  male
                  female
                }
              }
            }
            audience_geo {
              data {
                countries {
                  id
                  code
                  prc
                  name
                }
              }
            }
          }
          
          
          
        }
      }
      `;
    const variables = {
        username,
        cognito_id,
        stripe_token,
        interval
    };

    const payload = {
        query,
        variables
    };

    return {
        type: INITIATE_TIKTOK_TRIAL,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

const UPGRADE_TIKTOK = 'UPGRADE_TIKTOK';
export const UPGRADE_TIKTOK_PENDING = `${UPGRADE_TIKTOK}_PENDING`;
export const UPGRADE_TIKTOK_FULFILLED = `${UPGRADE_TIKTOK}_FULFILLED`;
export const UPGRADE_TIKTOK_REJECTED = `${UPGRADE_TIKTOK}_REJECTED`;

export const upgradeTikTokUser = (username, cognito_id, stripe_token, interval) => {
    console.log(username, cognito_id, stripe_token, interval);
    if (!username || !cognito_id || !stripe_token || !interval) {
        return null;
    }

    const query = `
        mutation upgradeTiktokUser($username: String, $stripe_token: String, $interval: HypeAuditorAccountType, $cognito_id: String) {
            upgradeTiktokUser(username: $username, stripe_token: $stripe_token, interval: $interval, cognito_id: $cognito_id) {
                username
                account_type
                error {
                    message
                }
            }
        }
    `;

    const variables = {
        username,
        cognito_id,
        stripe_token,
        interval
    };

    const payload = {
        query,
        variables
    };

    return {
        type: UPGRADE_TIKTOK,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

const CANCEL_TIKTOK = 'CANCEL_TIKTOK';
export const CANCEL_TIKTOK_PENDING = `${CANCEL_TIKTOK}_PENDING`;
export const CANCEL_TIKTOK_FULFILLED = `${CANCEL_TIKTOK}_FULFILLED`;
export const CANCEL_TIKTOK_REJECTED = `${CANCEL_TIKTOK}_REJECTED`;

export const cancelTikTokUser = (username, cognito_id, reason) => {
    if (!username || !cognito_id) {
        return null;
    }

    const query = `
        mutation cancelTikTokSubscription($username: String, $cognito_id: String, $reason: String) {
            cancelTikTokSubscription(username: $username, cognito_id: $cognito_id, reason: $reason) {
                _id
                cognito_id
                username
                account_type
                error {
                  message
                }
            }
        }
    `;

    const variables = {
        username,
        cognito_id,
        reason
    };

    const payload = {
        query,
        variables
    };

    return {
        type: CANCEL_TIKTOK,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};

const VERIFY_TIKTOK_USER = 'VERIFY_TIKTOK_USER';
export const VERIFY_TIKTOK_USER_PENDING = `${VERIFY_TIKTOK_USER}_PENDING`;
export const VERIFY_TIKTOK_USER_FULFILLED = `${VERIFY_TIKTOK_USER}_FULFILLED`;
export const VERIFY_TIKTOK_USER_REJECTED = `${VERIFY_TIKTOK_USER}_REJECTED`;

export const verifyTikTokUser = (username, cognito_id) => {
    if (!username || !cognito_id) {
        return null;
    }

    const query = `
        mutation verifyTikTokUser($username: String, $cognito_id: String) {
            verifyTikTokUser(username: $username, cognito_id: $cognito_id) {
                username
                account_verified
                error {
                  message
                }
            }
        }
    `;

    const variables = {
        username,
        cognito_id
    };

    const payload = {
        query,
        variables
    };

    return {
        type: VERIFY_TIKTOK_USER,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
};
