import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Checkbox } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { updateSmartlinkIntegrations, updateSmartLinkPreview } from '../../../store/actions/action_updateSmartlink';

import SmartlinksIntegrationsSkeleton from './smart-link-integrations-skeleton';
import SmartLinkMailingList from './smart-link-mailing-list';

const utmParams = [
    { key: 'source', fieldKey: 'utmSource', label: 'Source (utm_source)' },
    { key: 'medium', fieldKey: 'utmMedium', label: 'Medium (utm_medium)' },
    { key: 'campaign', fieldKey: 'utmCampaign', label: 'Campaign Name (utm_campaign)' }
];

const SmartLinkIntegrations = (props) => {
    const { smartlinks: smartlinkProps, affiliate, dispatchUpdateSmartlinkIntegrations, setToastVerbiage, attemptUpgradeModal } = props;

    const [gaTrackingId, setGaTrackingId] = useState('');
    const [utm, setUtm] = useState({
        source: '',
        medium: '',
        campaign: ''
    });
    const [utmEnabled, setUtmEnabled] = useState(false);
    const [fbPixelId, setFbPixelId] = useState('');

    const [fieldLoading, setFieldLoading] = useState('');
    const [saveButton, setSaveButton] = useState('');

    useEffect(() => {
        setGaTrackingId(smartlinkProps.gaTrackingId);
        setUtm(smartlinkProps.utm);
        if (smartlinkProps.utm && (smartlinkProps.utm.source || smartlinkProps.utm.medium || smartlinkProps.utm.campaign)) {
            setUtmEnabled(true);
        }

        setFbPixelId(smartlinkProps.fbPixelId);
    }, [smartlinkProps.loaded]);

    const saveChanges = (field, payload) => {
        setFieldLoading(field);
        dispatchUpdateSmartlinkIntegrations(affiliate.cognito_id, payload)
            .then(() => {
                setFieldLoading(null);
                setSaveButton('');
                setToastVerbiage('Saved');
            })
            .catch((err) => { console.error(err); });
    };

    /* google analytics */
    const handleGaTrackingIdChange = (value) => {
        if (saveButton !== 'gaTrackingId') {
            setSaveButton('gaTrackingId');
        }

        // remove the leading "UA-" if the user typed it in or copied it that way
        let cleanValue = value.replace(/^UA-/, '');
        // only allow letters, numbers, and hyphens
        cleanValue = cleanValue.replace(/[^\w\d-]/g, '');

        setGaTrackingId(cleanValue);
    };

    const saveGaTrackingId = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (smartlinkProps.settings.subscription.plan === 'plan_pro' && smartlinkProps.gaTrackingId !== gaTrackingId) {
            return saveChanges('gaTrackingId', { gaTrackingId });
        }

        setSaveButton('');
        return null;
    };

    /* utm params */
    const handleUtmEnabledChange = (isChecked) => {
        // if the user unchecked it, hide the utm fields, and set params to empty string
        if (!isChecked) {
            setUtmEnabled(false);
            setUtm({ source: '', medium: '', campaign: '' });
            // if any utm was previously saved, save all as empty strings
            if (smartlinkProps.utm.source || smartlinkProps.utm.medium || smartlinkProps.utm.campaign) {
                return saveChanges('utm', { utm: { source: '', medium: '', campaign: '' } });
            }
            setSaveButton('');
        // if the user checked it, show the utm fields
        } else {
            setUtmEnabled(true);
        }

        return null;
    };

    const handleUtmParamChange = (value, param) => {
        if (saveButton !== param.fieldKey) {
            setSaveButton(param.fieldKey);
        }

        // replace spaces with hyphens
        let cleanValue = value.replace(/\s/g, '-');
        // only allow letters, numbers, and some special characters
        cleanValue = cleanValue.replace(/[^\w\d-_:+.$#@!*|/,~]/g, '');

        setUtm({
            ...utm,
            [param.key]: cleanValue
        });
    };

    const saveUtmParam = (e, param) => {
        if (e) {
            e.preventDefault();
        }

        if (smartlinkProps.utm[param.key] !== utm[param.key]) {
            return saveChanges(param.fieldKey, { utm: { [param.key]: utm[param.key] } });
        }

        setSaveButton('');
        return null;
    };

    /* facebook ads */
    const handleFbPixelIdChange = (value) => {
        if (saveButton !== 'fbPixelId') {
            setSaveButton('fbPixelId');
        }

        // only allow letters, numbers, and hyphens
        const cleanValue = value.replace(/[^\w\d-]/g, '');

        setFbPixelId(cleanValue);
    };

    const saveFbPixelId = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (smartlinkProps.fbPixelId !== fbPixelId) {
            return saveChanges('fbPixelId', { fbPixelId });
        }

        setSaveButton('');
        return null;
    };

    const cancelChanges = (field) => {
        switch (field) {
            case 'gaTrackingId':
                setGaTrackingId(smartlinkProps.gaTrackingId);
                break;
            case 'utmMedium':
            case 'utmSource':
            case 'utmCampaign':
                const key = field.substr(3).toLowerCase();
                setUtm({
                    ...utm,
                    [key]: smartlinkProps.utm[key]
                });
                break;
            case 'fbPixelId':
                setFbPixelId(smartlinkProps.fbPixelId);
                break;
            default:
                break;
        }
        setSaveButton('');
    };

    const handleFocus = (field) => {
        if (saveButton !== field) {
            cancelChanges(saveButton);
        }
    };

    if (!smartlinkProps.loaded) {
        return <SmartlinksIntegrationsSkeleton />;
    }

    return (
        <>
            <fieldset>
                <legend>Google Analytics</legend>
                <ul>
                    <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => { return attemptUpgradeModal('gaTrackingId'); }}>
                        <form onSubmit={saveGaTrackingId}>
                            <TextField
                                label="Tracking ID"
                                prefix="UA-"
                                value={gaTrackingId}
                                onFocus={() => { handleFocus('gaTrackingId'); }}
                                onChange={handleGaTrackingIdChange}
                                disabled={fieldLoading === 'gaTrackingId' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                helpText={<>To find your tracking ID, login to <a href="https://analytics.google.com/" target="_blank" rel="nofollow noopener noreferrer">Google Analytics</a> and select your desired account. Then in the <strong>Property</strong> column, click <strong>Tracking Info</strong> then <strong>Tracking Code</strong>. Your &ldquo;UA-&rdquo; ID appears in the upper left portion of the panel.</>}
                            />
                            {saveButton === 'gaTrackingId'
                                && (
                                    <div className="button-group">
                                        <button type="button" className="cancel" onClick={() => { return cancelChanges('gaTrackingId'); }}>Cancel</button>
                                        <LlamaButton
                                            type="submit"
                                            loading={fieldLoading === 'gaTrackingId'}
                                            disabled={fieldLoading === 'gaTrackingId'}
                                            onClick={saveGaTrackingId}
                                        >
                                            Save
                                        </LlamaButton>
                                    </div>
                                )
                            }
                        </form>
                    </li>
                    <li>
                        <Checkbox
                            label="Add UTM tracking parameters to all links"
                            checked={utmEnabled}
                            onChange={handleUtmEnabledChange}
                            disabled={fieldLoading === 'utmSource'}
                        />
                    </li>
                    {utmEnabled
                        && (
                            <>
                                {utmParams.map((param) => {
                                    return (
                                        <li key={param.key}>
                                            <form onSubmit={(e) => { return saveUtmParam(e, param); }}>
                                                <TextField
                                                    label={param.label}
                                                    value={utm[param.key]}
                                                    onFocus={() => { handleFocus(param.key); }}
                                                    onChange={(value) => { return handleUtmParamChange(value, param); }}
                                                    disabled={fieldLoading === param.fieldKey}
                                                    helpText={(param.key === 'campaign')
                                                        ? <>Enter &ldquo;AUTO&rdquo; to automatically fill this parameter with the link name</>
                                                        : null
                                                    }
                                                />
                                                {saveButton === param.fieldKey
                                                    && (
                                                        <div className="button-group">
                                                            <button type="button" className="cancel" onClick={() => { return cancelChanges(param.fieldKey); }}>Cancel</button>
                                                            <LlamaButton
                                                                type="submit"
                                                                loading={fieldLoading === param.fieldKey}
                                                                disabled={fieldLoading === param.fieldKey}
                                                                onClick={() => { return saveUtmParam(null, param); }}
                                                            >
                                                                Save
                                                            </LlamaButton>
                                                        </div>
                                                    )
                                                }
                                            </form>
                                        </li>
                                    );
                                })}
                            </>
                        )
                    }
                </ul>
            </fieldset>

            <fieldset>
                <legend>Facebook Ad Events</legend>
                <form onSubmit={saveFbPixelId}>
                    <ul>
                        <li>
                            <TextField
                                label="Pixel ID"
                                value={fbPixelId}
                                onFocus={() => { handleFocus('fbPixelId'); }}
                                onChange={handleFbPixelIdChange}
                                disabled={fieldLoading === 'fbPixelId'}
                                helpText={<>To find your pixel ID, go to <a href="https://facebook.com/adsmanager" target="_blank" rel="nofollow noopener noreferrer">Facebok Ads Manager</a>, open the menu, and click <strong>Pixel</strong> in the <strong>Measure &amp; Report -&gt; Events Manager</strong> column. Create a pixel if you haven&rsquo;t already, then copy the <strong>Pixel ID</strong> from the upper left portion of the pixel details panel.</>}
                            />
                            {saveButton === 'fbPixelId'
                                && (
                                    <div className="button-group">
                                        <button type="button" className="cancel" onClick={() => { return cancelChanges('fbPixelId'); }}>Cancel</button>
                                        <LlamaButton
                                            type="submit"
                                            loading={fieldLoading === 'fbPixelId'}
                                            disabled={fieldLoading === 'fbPixelId'}
                                            onClick={saveFbPixelId}
                                        >
                                            Save
                                        </LlamaButton>
                                    </div>
                                )
                            }
                        </li>
                    </ul>
                </form>
            </fieldset>

            <SmartLinkMailingList
                setToastVerbiage={setToastVerbiage}
                attemptUpgradeModal={attemptUpgradeModal}
            />
        </>
    );
};

SmartLinkIntegrations.propTypes = {
    
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchUpdateSmartlinkIntegrations: (id, data) => { return dispatch(updateSmartlinkIntegrations(id, data)); },
        dispatchUpdateSmartLinkPreview: (data) => { return dispatch(updateSmartLinkPreview(data)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartLinkIntegrations);
