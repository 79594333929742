//adds commas every three digits from the right
const addCommas = (number) => {
    const wholeNumberArray = number.split('');

    let digitCount = 0;
    let wholeNumber = '';
    wholeNumberArray.reduceRight((curDigit, nextDigit) => {
        digitCount++;
        if(digitCount < 3){
            wholeNumber = curDigit + wholeNumber;
        }else{
            digitCount = 0;
            wholeNumber = ',' + curDigit + wholeNumber;
        }
        return nextDigit;
    })

    return wholeNumberArray[0] + wholeNumber;
}

//general function to round and add separation commas to a number
const numberFormat = (number, decimalPlaces = 0, keepZeros = true) => {
    if(typeof(number) === 'string') number = parseFloat(number)
    const roundedNumber = number.toFixed(decimalPlaces);
    const roundedNumberArray = roundedNumber.split('.');
    
    let decimals = '';
    if(!keepZeros){
        if(roundedNumberArray.length > 1){
            const decimalArray = roundedNumberArray[1].split('');
            decimalArray.reduceRight((curDigit, nextDigit) => {
                if(decimals.length == 0 && curDigit !== '0'){
                    decimals = curDigit + decimals;
                }else if(decimals.length != 0){
                    decimals = curDigit + decimals;
                }
                return nextDigit;
            })
            const firstDecimal = (decimalArray[0] !== '0') ? '.' + decimalArray[0] : '';
            decimals = firstDecimal + decimals;
        }
    }else{
        if(roundedNumberArray.length > 1) decimals = '.' + roundedNumberArray[1];
    }

    const wholeNumber = addCommas(roundedNumberArray[0])

    return wholeNumber + decimals;
}

//if flexibleDollar is true drop the decimals if they are 00
export const moneyFormat = (number, flexibleDollar = false, currency = '$') => {
    if(typeof(number) === 'string') number = parseFloat(number)
    if(currency === '$'){
        let roundedNumber = number;
        if(!flexibleDollar || (flexibleDollar && number.toString().indexOf('.') !== -1)){
            roundedNumber = number.toFixed(2);
        }else{
            roundedNumber = number.toString();
        }

        let sign = '';
        if(parseFloat(roundedNumber) < 0){
            sign = '−';
            roundedNumber = roundedNumber.replace('-', '');
        }

        const roundedNumberArray = roundedNumber.split('.');
        let decimals = '';
        if(roundedNumberArray.length > 1) decimals = '.' + roundedNumberArray[1];
        const wholeNumber = addCommas(roundedNumberArray[0]);
        
        return sign + currency + wholeNumber + decimals;
    }
}

export default numberFormat;