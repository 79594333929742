import React from 'react';
import { Pagination } from '@shopify/polaris';

import './pagination.css';

const LlamaPagination = ({ hasNext, hasPrevious, onNext, onPrevious, currentPage, maxPages, style }) => {

    return (
        <div className="llama-pagination" style={style} >
            {currentPage !== null && maxPages !== null &&
                <div className="llama-pagination__info">
                    Page {currentPage + 1} of {maxPages <= 0 ? 1 : maxPages + 1}
                </div>
            }
            <Pagination
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                onNext={onNext}
                onPrevious={onPrevious}
            ></Pagination>
        </div>
    )

}

export default LlamaPagination;