/**
 * Do not rely on these functions for browser- or os-based feature detection. User Agent Strings can easily be spoofed and could return an inaccurate result.
 */
const userAgent = navigator.userAgent.toLowerCase();

export const getOS = () => {
    if(userAgent.indexOf('cpu iphone') !== -1) return 'iPhone'
    if(userAgent.indexOf('ipad; cpu') !== -1) return 'iPad'
    if(userAgent.indexOf('android') !== -1) return 'Android Phone'
    if(userAgent.indexOf('os x') !== -1) return 'Mac'
    if(userAgent.indexOf('cros') !== -1) return 'Chrome OS'
    if(userAgent.indexOf('playstation') !== -1) return 'PlayStation'
    if(userAgent.indexOf('silk') !== -1) return 'Kindle Fire'
    if(userAgent.indexOf('symbianos') !== -1) return 'Symbian'
    if(userAgent.indexOf('symbos') !== -1) return 'Symbian'
    if(userAgent.indexOf('windows') !== -1) return 'Windows'
    if(userAgent.indexOf('gnu') !== -1) return 'GNU/Linux'
    if(userAgent.indexOf('linux') !== -1) return 'Linux'
    return null;
}

export const getBrowser = () => {
    // Opera 8.0+
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    if(isOpera) return 'Opera'
    if(isFirefox) return 'Firefox'
    if(isSafari) return 'Safari'
    if(isIE) return 'Internet Explorer'
    if(isEdge) return 'Edge'
    if(isChrome) return 'Chrome'
    if(userAgent.indexOf('silk') !== -1) return 'Silk'
    if(userAgent.indexOf('fban') !== -1) return 'Facebook Browser'
    if(userAgent.indexOf('instagram') !== -1) return 'Instagram Browser'
    return null;
}