/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from './sendRequest';
import { NO_API_CALL } from './types';
import discountFragment from './fragments/discount.fragment';
import { offerFragment, activeOfferFragment, searchOfferFragment } from './fragments/offer.fragment';

const SEARCH_OFFERS = 'SEARCH_OFFERS';
export const SEARCH_OFFERS_FULFILLED = `${SEARCH_OFFERS}_FULFILLED`;
export const SEARCH_OFFERS_PENDING = `${SEARCH_OFFERS}_PENDING`;
export const SEARCH_OFFERS_REJECTED = `${SEARCH_OFFERS}_REJECTED`;

export function searchOffers(term, filters, options, affiliate_id) {
    const query = `
        query searchOffers($affiliate_id: String, $term: String!, $filters: OfferSearchFilterInput, $options: QueryOptions) {
            searchOffers(affiliate_id: $affiliate_id, term: $term, filters: $filters, options: $options) {
                offers {
                    ...OfferData
                    epcValue
                    clicks
                    revenue
                    sale_amount
                    long_term_rate {
                        ltv
                        avg_cart_value
                    }
                    advertiser {
                        status
                        company
                        avatar_image
                        name
                        domain
                        description
                        email
                        email_preference {
                            application_general
                            application_alerts
                            application_affiliate_messages
                        }
                        totalOffers
                    }
                    totalOffers
                    pinned
                }
                pageInfo {
                    count
                }
            }
        }

        ${searchOfferFragment}
    `;

    const variables = {
        term,
        affiliate_id,
        filters,
        options
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'SEARCH_OFFERS',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };


  /* let headers = {
    "Content-Type": 'application/json'
  }

  return {
    type: "SEARCH_OFFERS",
    payload: axios.post(NEXT_APP_API_URL, payload, { headers })
      .then(response => response.data)
  } */
}

export function searchAdvertiserOffers(advertiser_id, options) {
    const query = `
        query searchAdvertiserOffers($advertiser_id: String!, $options: AdvertiserQueryOptions) {
            searchAdvertiserOffers(advertiser_id: $advertiser_id, options: $options) {
                offers {
                    ...OfferData
                    epcValue
                    clicks
                    revenue
                    sale_amount
                    long_term_rate {
                        ltv
                        avg_cart_value
                    }
                    advertiser {
                        status
                        company
                        avatar_image
                        name
                        domain
                        description
                        email
                        email_preference {
                            application_general
                            application_alerts
                            application_affiliate_messages
                        }
                        totalOffers
                    }
                }
                pageInfo {
                    count
                }
            }
        }

        ${searchOfferFragment}
    `;

    const variables = {
        advertiser_id,
        options
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'SEARCH_MERCHANT_OFFERS',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };
}


export function getOfferById(variables) {

  if (!variables.offer_id || !variables.cognito_id) {
    return null;
  }

  const query = `
    query affiliates($offer_id: String!, $cognito_id: String) {
      offerById(offer_id: $offer_id) {
        ...OfferData
        advertiser {
          status
          company
          name
          domain
          description
          avatar_image
          email
          email_preference{
            application_general
            application_alerts
            application_affiliate_messages
          }
          totalOffers
        }
      }
      affiliateById(cognito_id: $cognito_id) {
        name
        applications(offer_id: $offer_id) {
          offer_id
          advertiser_status
          affiliate_status
        }
      }
    }

    ${offerFragment}
  `

  const payload = {
    query,
    variables,
  }

  return sendGraphQL(payload)
    .then((response) => response.data);

  /* const headers = {
    "Content-Type": 'application/json'
  }

  return axios.post(NEXT_APP_API_URL, payload, { headers })
    .then((response) => response.data); */

}

const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_FULFILLED = `${GET_OFFERS}_FULFILLED`;
export const GET_OFFERS_PENDING = `${GET_OFFERS}_PENDING`;
export const GET_OFFERS_REJECTED = `${GET_OFFERS}_REJECTED`;

export function getOffers(affiliate, filters) {
 
  if (!affiliate.cognito_id || !affiliate.tracking_id) {
    console.log("NO CALL");
    return { type: NO_API_CALL };
  }

  const query = `
    query getOffersForAffiliate(
      $cognito_id: String, 
      $filters: AffiliateOfferFilterInput,
      $tracking_id: String
    ) {
      affiliateById(cognito_id: $cognito_id){
        name
        offers(filters: $filters) {
          application {
            application_id
            affiliate_status
            advertiser_status
          }
          offer {
            ...OfferData
            discounts(affiliate_id: $tracking_id) {
              ...DiscountData
            }
            referral_target {
              type
              amount
            }
          }
          advertiser {
            status
            company
            name
            domain
            description
            avatar_image
            email
            email_preference{
              application_general
              application_alerts
              application_affiliate_messages
            }
            totalOffers
          }
        }
      }
    }

    ${offerFragment}

    ${discountFragment}
`

  
  const variables = {
    cognito_id: affiliate.cognito_id,
    tracking_id: affiliate.tracking_id,
    filters,
  }

  const payload = {
    query,
    variables
  }

  return {
    type: GET_OFFERS,
    payload: sendGraphQL(payload)
      .then(response => response.data)
  }

  /* const headers = {
    "Content-Type": 'application/json'
  }

  return {
    type: GET_OFFERS,
    payload: axios.post(NEXT_APP_API_URL, payload, { headers })
      .then((response) => response.data)
  } */

}

export function getActiveOffers(affiliate, filters) {
  
    if (!affiliate.cognito_id || !affiliate.tracking_id) {
      console.log("NO CALL");
      return { type: NO_API_CALL };
    }
  
    const query = `
      query getOffersForAffiliate(
        $cognito_id: String, 
        $filters: AffiliateOfferFilterInput
      ) {
        affiliateById(cognito_id: $cognito_id){
          name
          offers(filters: $filters) {
            application {
              application_id
              affiliate_status
              advertiser_status
            }
            offer {
              ...OfferData              
              referral_target {
                type
                amount
              }
            }
            advertiser {
              status
              company
              name
              domain
              description
              email
              email_preference{
                application_general
                application_alerts
                application_affiliate_messages
              }
              totalOffers
            }
          }
        }
      }
  
      ${activeOfferFragment}
    `

    const variables = {
        cognito_id: affiliate.cognito_id,
        tracking_id: affiliate.tracking_id,
        filters
    };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_OFFERS,
        payload: sendGraphQL(payload)
            .then((response) => response.data)
    };
    /* const headers = {
      "Content-Type": 'application/json'
    }

    return {
      type: GET_OFFERS,
      payload: axios.post(NEXT_APP_API_URL, payload, { headers })
        .then((response) => response.data)
    } */
}
