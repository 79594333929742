import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LlamaButton } from 'llama-library/components';

import { changeHeaderTitle } from '../store/actions/header';
import switchAccountType from '../store/actions/action_switchAccountType';

import './styles/become-ambassador.css';

import video from '../assets/become-ambassador/ambassador-features-min.mp4';
import videoThumbnail from '../assets/become-ambassador/video-thumbnail.png';
import ltvCharts from '../assets/become-ambassador/ltv-charts.png';
import ltvCharts2x from '../assets/become-ambassador/ltv-charts-2x.png';
import ltvChartsSm from '../assets/become-ambassador/ltv-charts-sm.png';
import ltvChartsSm2x from '../assets/become-ambassador/ltv-charts-sm-2x.png';
import searchGraphic from '../assets/become-ambassador/search-filters-illustration.png';
import searchGraphic2x from '../assets/become-ambassador/search-filters-illustration-2x.png';
import smartlinksGraphic from '../assets/become-ambassador/auto-smartlinks-illustration.png';
import smartlinksGraphic2x from '../assets/become-ambassador/auto-smartlinks-illustration-2x.png';

const BecomeAmbassador = ({ affiliate, dispatchSwitchAccountType, dispatchChangeHeaderTitle, history }) => {
    const [loading, setLoading] = useState(false);
    const videoElement = useRef(null);

    useEffect(() => {
        dispatchChangeHeaderTitle('Become an Ambassador');
    }, []);

    const switchToAmbassadorAccount = () => {
        setLoading(true);
        dispatchSwitchAccountType(affiliate.cognito_id, 'ambassador')
            .then((response) => {
                if (response?.value?.data?.switchAccountTypeAffiliate?.account_type === 'ambassador') {
                    history.push('/signUp/1');
                }
            });
    };

    const playPause = () => {
        if (videoElement.current.paused) {
            videoElement.current.play();
        } else {
            videoElement.current.pause();
        }
    };

    return (
        <div id="become-ambassador">
            <section className="intro">
                <h1>Get paid more often for your&nbsp;referrals <span className="subheading">when you switch to a free Llama ambassador&nbsp;account</span></h1>
                <video src={video} poster={videoThumbnail} onClick={playPause} ref={videoElement} />
                <p>Llama is different than traditional ambassador networks. Our <strong>long-term value (LTV) model</strong> lets you earn commission for <strong>every purchase</strong> your referrals make, not just&nbsp;their&nbsp;first&nbsp;ones.</p>
                <p>
                    <LlamaButton onClick={switchToAmbassadorAccount} loading={loading} disabled={loading}>Make me an ambassador</LlamaButton>
                </p>
            </section>

            <section className="ltv">
                <div className="content">
                    <h2>Earn more with LTV</h2>
                    <p className="charts">
                        <picture>
                            <source media="(max-width: 730px)" srcSet={`${ltvChartsSm} 1x, ${ltvChartsSm2x} 2x`} />
                            <source media="(min-width: 731px)" srcSet={`${ltvCharts} 1x, ${ltvCharts2x} 2x`} />
                            <img src={ltvCharts} alt="With the traditional commission model, you earn from only the first order your referrals make. With Llama's commission model, you earn from every order your referrals make" />
                        </picture>
                    </p>
                    <div className="description">
                        <p>With most ambassador networks, how much you earn is based on how many followers you have, and <strong>we don&rsquo;t think that&rsquo;s&nbsp;fair</strong>.</p>
                        <p>If you have a smaller following but can keep your audience buying on repeat, <strong>you deserve to get paid on repeat</strong>.</p>
                        <p>Brand offers on Llama have <strong>commission rates for initial purchases and additional purchases</strong>, so you&rsquo;ll know how much and how long you can earn before you&nbsp;apply.</p>
                    </div>
                </div>
            </section>

            <section className="search subfeature">
                <p className="graphic"><img src={searchGraphic} alt="" srcSet={`${searchGraphic} 1x, ${searchGraphic2x} 2x`} /></p>
                <div className="content">
                    <h3>Find better brands</h3>
                    <p>If you&rsquo;re going to promote something on repeat, you&rsquo;ll be more successful if both you and your audience are interested in it. Our smart search lets you <strong>find and apply to offers from the most relevant brands for your community</strong>.</p>
                    <p>Brands can search for ambassadors, too. So if you fit a brand&rsquo;s goals and demographic, they may invite you to promote their&nbsp;offers.</p>
                </div>
            </section>

            <section className="smartlinks subfeature">
                <p className="graphic"><img src={smartlinksGraphic} alt="" srcSet={`${smartlinksGraphic} 1x, ${smartlinksGraphic2x} 2x`} /></p>
                <div className="content">
                    <h3>Automate your SmartLinks&nbsp;page</h3>
                    <p>Your SmartLinks page integrates seamlessly with your ambassador account. All your current settings will stay intact and you can <strong>add new links for your approved offers with a few&nbsp;clicks</strong>.</p>
                    <p>You can also switch to automatic mode to <strong>promote your Llama offers exclusively</strong> and always keep your <strong>best performing ones on top</strong>. We&rsquo;ll remember your non-Llama links, too if you want to switch back&nbsp;and&nbsp;forth.</p>
                </div>
            </section>

            <section className="outro">
                <h3>Become an ambassador&nbsp;today</h3>
                <p>If you&rsquo;re interested, click the button below and we&rsquo;ll immediately <strong>upgrade your SmartLinks account to an ambassador account</strong> for free. If you need help with anything, just click on Leo&rsquo;s head in the bottom corner and we&rsquo;ll be happy&nbsp;to&nbsp;assist&nbsp;you.</p>
                <p>
                    <LlamaButton onClick={switchToAmbassadorAccount} loading={loading} disabled={loading}>Make me an ambassador</LlamaButton>
                </p>
            </section>
        </div>
    );
};

BecomeAmbassador.propTypes = {
    affiliate: PropTypes.shape({
        cognito_id: PropTypes.string.isRequired
    }).isRequired,
    dispatchSwitchAccountType: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    dispatchChangeHeaderTitle: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        ...state,
        affiliate: state.affiliate
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchChangeHeaderTitle: (title) => { return dispatch(changeHeaderTitle(title)); },
        dispatchSwitchAccountType: (cognito_id, type) => { return dispatch(switchAccountType(cognito_id, type)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BecomeAmbassador);
