import { useState, useEffect, useRef } from 'react';
const _ = require('lodash');

const usePagesOffers = (initalPage, offersPerPage, props, setIsOfferLoaded, currentActiveTab = false) => {
    //console.log("props.skip", props.skip)
    const defaultPageOptions = {
        skip: props.skip,
        limit: offersPerPage,
        sortBy: undefined
    }

    const defaultFilters = {
        category: [],
        commissionType: [],
        commissionRange: [0, 100],
        offerType: []
    }

    const isCancelled = useRef(false);
    const [affiliate_id, setAffiliateId] = useState(props.affiliate.cognito_id);
    const [maxPages, setMaxPages] = useState(null);
    const [offers, setOffers] = useState([]);
    const [pageOptions, setPageOptions] = useState(defaultPageOptions);
    const [pageFilters, setPageFilters] = useState(defaultFilters);
    const [currentPage, setCurrentPage] = useState(initalPage);
    const [currentTab, setCurrentTab] = useState(currentActiveTab);
    const [applyFilterStatus, setApplyFilterStatus] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [offersLoading, setOffersLoading] = useState(false); //used to prevent duplicate api calls during latency
    const [pageNavigation, setPageNavigation] = useState(false);

    const makeAPICall = (searchTerm, pageFilters, pageOptions) => {
        setIsOfferLoaded(false);
        setOffersLoading(true);
        console.log('MAKE API CALL :: ', searchTerm, pageFilters, pageOptions);

        let savedFilterData = null;
        let filterData;
        let shouldCallAPI = false;

        try {
            savedFilterData = JSON.parse(sessionStorage.getItem('filterData'));
        } catch (error) {
            console.log(error)
        }

        if (savedFilterData && !_.isEmpty(savedFilterData)) {
            const selectedFiltersObj = {
                commissionRange: pageFilters.commissionRange,
                selectedCategories: pageFilters.category,
                searchTerm,
                commissionType: pageFilters.commissionType,
                offerType: pageFilters.offerType,
                sortBy: pageOptions.sortBy,
                currentPage
            }
            const oldFiltersObj = {
                commissionRange: savedFilterData.commissionRange,
                selectedCategories: savedFilterData.selectedCategories,
                searchTerm: savedFilterData.searchTerm,
                commissionType: savedFilterData.commissionType,
                offerType: savedFilterData.offerType,
                sortBy: savedFilterData.sortBy,
                currentPage: savedFilterData.currentPage
            }

            !_.isEqual(selectedFiltersObj, oldFiltersObj) ? shouldCallAPI = true : shouldCallAPI = false;

        } else {
            shouldCallAPI = true;
        }

        if (!shouldCallAPI) {
            setOffers(savedFilterData.offers);
            setIsOfferLoaded(true);
            setInitialLoad(false);
            setOffersLoading(false);
            setPageNavigation(false);
            setApplyFilterStatus(false);
            props.setSearchButton(false);
            // setFilterStatus(true);
            props.setCommissionRange(savedFilterData.commissionRange);
            props.setSelectedCategories(savedFilterData.selectedCategories);
            props.setSearchTerm(savedFilterData.searchTerm);
            props.setCommissionType(savedFilterData.commissionType);
            props.setOfferType(savedFilterData.offerType);
            props.setSelectedSort(savedFilterData.sortBy.field);
            // props.enableApplyFiltersBtn(false);
            setMaxPages(savedFilterData.maxPages);
            setCurrentPage(savedFilterData.currentPage);
        } else {
            props.getOffers(searchTerm, pageFilters, pageOptions, affiliate_id)
                .then((reports) => {

                    const fetchedOffers = reports.value.data.searchOffers.offers;
                    if (!isCancelled.current) {
                        setOffers(fetchedOffers);
                        setIsOfferLoaded(true);
                        setInitialLoad(false);
                        setOffersLoading(false);
                        setPageNavigation(false);
                        setApplyFilterStatus(false);
                        props.setSearchButton(false);
                        // setFilterStatus(true);

                        const pageCount = reports.value.data.searchOffers.pageInfo.count % offersPerPage === 0
                            ? (reports.value.data.searchOffers.pageInfo.count / offersPerPage) - 1
                            : Math.ceil(reports.value.data.searchOffers.pageInfo.count / offersPerPage);
                        setMaxPages(pageCount);

                        /**
                         * const to pass filter data to next component
                         */

                        filterData = {
                            commissionRange: pageFilters.commissionRange,
                            selectedCategories: pageFilters.category,
                            searchTerm: searchTerm,
                            commissionType: pageFilters.commissionType,
                            offerType: pageFilters.offerType,
                            sortBy: pageOptions.sortBy,
                            currentPage,
                            maxPages: pageCount,
                            offers: fetchedOffers
                        }

                        try {
                            sessionStorage.setItem('filterData', JSON.stringify(filterData));
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }).catch((error) => {
                    setOffersLoading(false);
                    setIsOfferLoaded(true);
                    console.log('usePagesOffers:makeAPICall:searchOffersInfo: Error', error)
                })
        }
    }
    const newPageFilters = {
        category: props.selectedCategories,
        commissionType: props.selectedCommissionType,
        commissionRange: props.commissionRange,
        offerType: props.offerType
    }

    const newPageOptions = {
        skip: pageOptions.skip,
        limit: offersPerPage,
        sortBy: { "field": props.selectedSort }
    }

    if (!(_.isEqual(pageOptions, newPageOptions))) {
        setPageOptions({ ...pageOptions, skip: pageOptions.skip, sortBy: { 'field': props.selectedSort } });
        props.setSkip(pageOptions.skip)
    }

    if (!(_.isEqual(pageFilters, newPageFilters))) {
        console.log('pagefilters::', pageFilters);
        console.log('isEqual::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
        setPageFilters({ category: props.selectedCategories, commissionType: props.selectedCommissionType, commissionRange: props.commissionRange, offerType: props.offerType })
    }

    /* useEffect(() => {
        if(!initialLoad){
            setInitialLoad(true);
        }
        console.log('FIRST TIME CALL::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
    }, []) */

    /*useEffect(() => {
        // !initialLoad && setApplyFilterStatus(true);
        //!initialLoad && props.enableApplyFiltersBtn(false);

        //If all filters are cleared manually disable the Reset button
        if (!initialLoad) {
            if (
                props.selectedCategories.length === 0
                && props.selectedCommissionType.length === 0
                && (props.commissionRange.length === 0 || !props.commissionRange[0] || !props.commissionRange[1])
            ) {
                //props.enableResetFilterBtn(true);
            } else {
                //props.enableResetFilterBtn(false);
            }
        }

        console.log('SUBSEQUENT CALLS::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
    }, [pageFilters])*/

    //set page options to load from page 1
    const backToPage1 = () => {
        setCurrentPage(1);
        setPageOptions({ ...pageOptions, skip: 0 });
        props.setSkip(0);
    }

    //these next 4 useEffect calls were split to each look at a single dependency because combining them triggered more than one api call

    //load new offers when apply filters button is clicked
    useEffect(() => {
        if (!props.applyFiltersBtnEnabled) {
            console.log('Apply Filters BUTTON CALLS::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
            backToPage1();
        }
    }, [props.applyFiltersBtnEnabled])

    //load new offers when search button is clicked
    useEffect(() => {
        if (props.searchButton) {
            console.log('Search CALLS::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
            backToPage1()
        }
    }, [props.searchButton])

    //load new offers when sort by is changed
    useEffect(() => {
        backToPage1();
    }, [props.selectedSort])

    //reset filters to default and load new offers when reset filters button is clicked
    useEffect(() => {
        if (!props.resetFiltersBtnEnabled) {
            console.log('Reset BUTTON CALLS::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
            props.setSelectedCategories([]);
            props.setCommissionType(['PERCENT_REVENUE']);
            props.setCommissionRange([0, 100]);
            props.setSearchTerm('');
            backToPage1();
        }
        /*if (!initialLoad && props.resetFiltersBtnEnabled) {
            console.log('Reset BUTTON CALLS::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
        } else {
            console.log('Reset BUTTON CALLS ELSE::', initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled);
            props.setSelectedCategories([]);
            props.setCommissionType([]);
            props.setCommissionRange([0, 100]);
            props.setSearchTerm('');
            setPageFilters(defaultFilters);
            setApplyFilterStatus(true);

            // State preservance: on click of reset maintain the default state of filters.
            // setPageOptions({ ...pageOptions, skip: 0, sortBy: { 'field': props.selectedSort } });
            // props.setSkip(0);
            // setCurrentPage(1);
            // props.setSelectedSort('ltv');
        }*/
    }, [props.resetFiltersBtnEnabled])

    //set page navigation when page options change
    useEffect(() => {
        // !initialLoad && setPageNavigation(true);
        setPageNavigation(true);
        console.log('PAGE OPTIONS CALL::', pageOptions, initialLoad, applyFilterStatus, props.applyFiltersBtnEnabled, props.resetFiltersBtnEnabled, pageNavigation);
    }, [pageOptions])

    //prevent unmounted component error during onboarding redirect
    useEffect(() => {
        return () => { isCancelled.current = true; }
    }, [])

    //load new offers on initial load or when the page changes 
    //this covers filter changes too since filter changes trigger the page to go back to 1
    useEffect(() => {
        console.log('page navigation / affiliate id use effect ::', initialLoad, pageNavigation, affiliate_id);
        if (!offersLoading) { //if we already made an api call and it's still loading, don't make another one
            if (
                (affiliate_id && initialLoad) || //first load
                (pageNavigation) //page change or apply filters or search or sort change
            ) {
                makeAPICall(props.searchTerm, pageFilters, pageOptions, affiliate_id)
            }
        }
        /* if ((affiliate_id && initialLoad) || (affiliate_id && applyFilterStatus) || pageNavigation || props.searchButton) {
            console.log('CONDITIONAL affiliate_id & initialLoad OR affiliate_id & applyFilterStatus OR pageNavigation OR searchButton exists ::', affiliate_id && initialLoad, affiliate_id && applyFilterStatus, pageNavigation, props.searchButton);
            makeAPICall(props.searchTerm, pageFilters, pageOptions, affiliate_id)
        } */
    }, [pageNavigation, affiliate_id])

    //i don't know what this does
    useEffect(() => {
        if (currentTab != currentActiveTab) {
            setCurrentPage(initalPage);
            setCurrentTab(currentActiveTab);
        }
    });

    //pagination handlers
    let hasNext = true;
    let hasPrevious = true;

    const nextPage = () => {
        console.log('nextPage:', currentPage, pageOptions);

        if (currentPage >= maxPages) {
            setCurrentPage(maxPages);
        }
        else {
            const skip = currentPage * offersPerPage;
            setCurrentPage(currentPage + 1);
            setPageOptions({ ...pageOptions, skip, sortBy: { "field": props.selectedSort } });
            props.setSkip(skip);
        }
    }

    const previousPage = () => {
        console.log('previousPage:', currentPage, pageOptions);

        if (currentPage <= 1) {
            setCurrentPage(1);
        }
        else {
            const skip = (currentPage - 2) * offersPerPage;
            setCurrentPage(currentPage - 1);
            setPageOptions({ ...pageOptions, skip, sortBy: { "field": props.selectedSort } });
            props.setSkip(skip);
        }
    }

    if (currentPage <= 1) {
        hasPrevious = false;
    }

    if (currentPage >= maxPages) {
        hasNext = false;
    }

    return {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        offers
    }
}

export default usePagesOffers;