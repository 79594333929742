export const themes = {
    'theme-default': {
        "font": "Helvetica",
        "pageBg": "#fff",
        "headlineText": "#34135D",
        "buttonBg": "#34135D",
        "buttonBorder": "#34135D",
        "buttonText": "#fff",
        "buttonRoundness": 4
    },
    'theme-steel': {
        "font": "Helvetica",
        "pageBg": "#535256",
        "headlineText": "#fff",
        "buttonBg": "#688aa4",
        "buttonBorder": "#688aa4",
        "buttonText": "#fff",
        "buttonRoundness": 4
    },
    'theme-camo': {
        "font": "Helvetica",
        "pageBg": "#5d8c6a",
        "headlineText": "#fff",
        "buttonBg": "#992c23",
        "buttonBorder": "#992c23",
        "buttonText": "#fff",
        "buttonRoundness": 4
    },
    'theme-snow': {
        "font": "Helvetica",
        "pageBg": "#fff",
        "headlineText": "#3d3b3c",
        "buttonBg": "#3d3b3c",
        "buttonBorder": "#3d3b3c",
        "buttonText": "#fff",
        "buttonRoundness": 4
    },
    'theme-punk-rock': {
        "font": "Helvetica",
        "pageBg": "#19191a",
        "headlineText": "#fff",
        "buttonBg": "#a40e4a",
        "buttonBorder": "#8a0739",
        "buttonText": "#fff",
        "buttonRoundness": 4
    },
    'theme-lemon-lime': {
        "font": "Helvetica",
        "pageBg": "#dfd51b",
        "headlineText": "#1d591e",
        "buttonBg": "#1d591e",
        "buttonBorder": "#1d591e",
        "buttonText": "#fdf88d",
        "buttonRoundness": 4
    },

    'theme-bloom': {
        "font": "Helvetica",
        "pageBg": "",
        "headlineText": "#fff",
        "buttonBg": "",
        "buttonBorder": "#fff",
        "buttonText": "#fff",
        "buttonRoundness": 4
    },
    'theme-spray': {
        "font": "Helvetica",
        "pageBg": "",
        "headlineText": "#fff",
        "buttonBg": "",
        "buttonBorder": "#fff",
        "buttonText": "#fff",
        "buttonRoundness": 4
    },
    'theme-gold': {
        "font": "Helvetica",
        "pageBg": "",
        "headlineText": "#7b5115",
        "buttonBg": "",
        "buttonBorder": "#7b5115",
        "buttonText": "#7b5115",
        "buttonRoundness": 4
    },
    'theme-pretty-in-pink': {
        "font": "Helvetica",
        "pageBg": "#cc1a74",
        "headlineText": "#fff",
        "buttonBg": "#fdc2d9",
        "buttonBorder": "#fdc2d9",
        "buttonText": "#a4115c",
        "buttonRoundness": 4
    },
}

export const proThemes = [
    'theme-bloom',
    'theme-spray',
    'theme-gold',
    'theme-pretty-in-pink'
]