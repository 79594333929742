import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {Page} from '@shopify/polaris';
import {EmptyState} from 'llama-library/components';

import { changeHeaderTitle } from '../store/actions/header';
import { getOfferById } from '../store/actions/action_getOffers';
import { recordOfferBoostMetric } from '../store/actions/action_getDasboardData';

import OfferDetailSidebar from '../components/OfferDetail/offer-detail-sidebar'
import OfferPromotionDetails from '../components/OfferDetail/offer-promotion-details'
import OfferDetailSkeleton from '../components/OfferDetail/offer-detail-skeleton'

import './styles/offer-detail.css';

/**
 * This is the version of offer detail page where the ambassador is NOT approved yet. It's utilizing graphql to get the offer instead of a custom hook using redux which is why it's in a different file
 * If you're looking for the approved version, it's in ./offer-detail.js
 * Both of these containers call the same child components
 * 
 * @link /discover/offer/:id
 */
const OfferDetailDiscover = (props) => {
    const {history, affiliate} = props;

    const [isLoading, setIsLoading] = useState(true);
    const [applicationStatus, setApplicationStatus] = useState('NEVER_APPLIED');
    const [offer, setOffer] = useState(null);
    const [sponsoredClick, setSponsoredClick] = useState(false);

    useEffect(() => {
        props.changeHeaderTitle('Offer Details');

        // if they clicked from featured offers, record it for the merchant
        const historyState = history.location.state;
        if (historyState && historyState.recordSponsoredClick) {
            setSponsoredClick(true);
            recordOfferBoostMetric('clicks', props.match.params.id)
        }
        // don't persist the featured offers click state
        history.replace(`/discover/offer/${props.match.params.id}`, {});
    }, []);

    const goToDiscover = () => {
        history.push('/discover')
    }

    useEffect(() => {
        if(!props.affiliate.cognito_id){
            goToDiscover();
            return;
        }

        const offerParams = {
            offer_id: props.match.params.id,
            cognito_id: affiliate.cognito_id
        }

        getOfferById(offerParams)
            .then(result => {
                console.log('OFFER RESULT', result);
                //404
                if(!result.data || !result.data.offerById){
                    return <EmptyState 
                        message="Sorry, I couldn’t find that offer" 
                        paragraph="Try searching for a different offer" 
                        ctaText="Discover Offers"
                        ctaAction={goToDiscover}
                    />
                }

                //if they applied, 
                if (
                    result.data.affiliateById &&
                    result.data.affiliateById.applications &&
                    Array.isArray(result.data.affiliateById.applications) &&
                    result.data.affiliateById.applications.length > 0
                ) {
                    const application = result.data.affiliateById.applications.find((item) => {
                        return item.offer_id === props.match.params.id
                    })

                    //if they're already approved, redirect them to the non-discover version
                    if(application.advertiser_status === 'APPROVED') props.history.push('/offer/'+application.offer_id)

                    setApplicationStatus(application.advertiser_status);
                }

                //add offer info to state and stop loading
                setOffer(result.data.offerById);
                setIsLoading(false);
            })
    }, [props.affiliate])

    if(isLoading){
        return <OfferDetailSkeleton />
    }

    return (
        <Page
            breadcrumbs={[{
                content: 'Discover Offers', 
                onAction: goToDiscover
            }]}
        >
            <div className="offer-detail__offer-wrapper">
                <OfferDetailSidebar
                    from="DISCOVER"
                    offer={offer}
                    applicationStatus={applicationStatus}
                    history={history}
                    sponsoredClick={sponsoredClick}
                />
                <OfferPromotionDetails
                    from="DISCOVER"
                    offer={offer}
                    applicationStatus={applicationStatus}
                />
            </div>
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailDiscover);