import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Select, RangeSlider, Modal } from '@shopify/polaris';
import { ChromePicker as ColorPicker } from 'react-color';
import { LlamaButton } from 'llama-library/components';

import { updateSmartLinkPreview } from '../../../store/actions/action_updateSmartlink';
import { updateCustomization } from '../../../store/actions/action_updateSmartlinkCustomization';

import checkColorContrast from '../../../utils/check-color-contrast';

import './smart-link-custom-theme.css';

const colorPickers = {
    pageBg: false,
    headlineText: false,
    buttonBg: false,
    buttonBorder: false,
    buttonText: false
};

const colorFields = [
    { label: 'Page Background Color', value: 'pageBg' },
    { label: 'Headline/Bio Color', value: 'headlineText' },
    { label: 'Button Background Color', value: 'buttonBg' },
    { label: 'Button Border Color', value: 'buttonBorder' },
    { label: 'Button Text Color', value: 'buttonText' }
];

Object.keys(colorPickers);

// taken from most popular system and google fonts
const fonts = [
    'Helvetica Neue',
    'Arial',
    'Tahoma',
    'Verdana',
    'Times',
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Oswald',
    'Raleway',
    'Poppins',
    'Noto Sans',
    'Roboto Slab',
    'Merriweather',
    'PT Sans',
    'Ubuntu',
    'Playfair Display',
    'Muli',
    'PT Serif',
    'Lora',
    'Titillium Web',
    'Slabo 27px',
    'Nunito',
    'Rubik',
    'Noto Serif',
    'Fira Sans',
    'Nanum Gothic',
    'Work Sans',
    'Noto Sans KR',
    'Quicksand',
    'Heebo',
    'Inconsolata',
    'Nunito Sans',
    'Arimo',
    'Dosis',
    'Karla',
    'Oxygen',
    'Anton',
    'Libre Baskerville'
];

const SmartLinkCustomTheme = (props) => {
    const {smartlinks: smartlinkProps, affiliate, dispatchUpdateSmartLinkPreview, dispatchUpdateCustomization, setToastVerbiage, setCurrentTheme, savingPreset, attemptUpgradeModal} = props;

    const [activeColorPicker, setActiveColorPicker] = useState(colorPickers);
    const [customThemeStarted, setCustomThemeStarted] = useState(false);
    const [savingCustomTheme, setSavingCustomTheme] = useState(null);
    const [themeData, setThemeData] = useState({
        font: 'Helvetica Neue',
        pageBg: '',
        headlineText: '',
        buttonBg: '',
        buttonBorder: '',
        buttonText: '',
        buttonRoundness: 4
    });
    const [changeHistory, setChangeHistory] = useState([]);
    const [historyPointer, setHistoryPointer] = useState(0);

    const [contrastModalOpen, setContrastModalOpen] = useState(false);
    const [contrastErrors, setContrastErrors] = useState([]);

    // fallback for browsers without e.path
    const buildNodePath = (element, pathArray) => {
        pathArray.push({
            id: element.id,
            className: element.className
        });
        if (element.parentElement) {
            return buildNodePath(element.parentElement, pathArray);
        }
        return pathArray;
    };

    // close color picker by clicking outside of it
    const handleClickOutside = (e) => {
        console.log(e);
        let nodePath = [];
        if (e.path) {
            nodePath = e.path;
        } else {
            nodePath = buildNodePath(e.target, nodePath);
        }

        const colorPickerClicked = nodePath.find((node) => {
            const elementClass = (node.className && typeof (node.className) === 'string') ? node.className.trim() : '';
            return elementClass === 'Polaris-TextField__Input'
                || elementClass === 'hue-horizontal'
                || elementClass === 'chrome-picker';
        });

        if (!colorPickerClicked) {
            setActiveColorPicker(colorPickers);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setThemeData(smartlinkProps.customizations);
        setChangeHistory([smartlinkProps.customizations]);
    }, [smartlinkProps.loaded, smartlinkProps.customizations]);

    const handleHistoryChange = (type) => {
        let newHistoryPointer;
        if (type === 'undo') {
            newHistoryPointer = (historyPointer > 0) ? historyPointer - 1 : 0;
        } else {
            newHistoryPointer = (historyPointer < changeHistory.length - 1) ? historyPointer + 1 : changeHistory.length - 1;
        }

        setHistoryPointer(newHistoryPointer);
        setThemeData({
            ...themeData,
            ...changeHistory[newHistoryPointer]
        });
        dispatchUpdateSmartLinkPreview({
            ...themeData,
            ...changeHistory[newHistoryPointer],
            customTheme: true
        });
    };

    const closeContrastModal = () => {
        setSavingCustomTheme(false);
        setContrastModalOpen(false);
    };

    const toggleColorPicker = (type) => {
        setActiveColorPicker({
            ...colorPickers,
            [type]: true
        });
    };

    const addToChangeHistory = (change) => {
        const newChangeHistory = [...changeHistory];
        if (newChangeHistory.length - 1 > historyPointer) {
            // remove everything after the current pointer
            newChangeHistory.splice(historyPointer + 1, newChangeHistory.length - historyPointer + 1);
        }

        newChangeHistory.push({
            ...themeData,
            ...change
        });

        setChangeHistory(newChangeHistory);
        setHistoryPointer(newChangeHistory.length - 1);
    };

    const handleChange = (value, type, fromColorPicker) => {
        if (smartlinkProps.settings.subscription.plan === 'plan_pro') {
            if (!customThemeStarted) {
                setCustomThemeStarted(true);
                setCurrentTheme('custom');
            }

            setThemeData({
                ...themeData,
                [type]: (value.hex) ? value.hex : value
            });
            if (!fromColorPicker) {
                addToChangeHistory({ [type]: (value.hex) ? value.hex : value });
            }
            dispatchUpdateSmartLinkPreview({
                ...themeData,
                [type]: (value.hex) ? value.hex : value,
                customTheme: true
            });
        }
    };

    const handleColorTextChange = (value, type) => {
        if (smartlinkProps.settings.subscription.plan === 'plan_pro') {
            let cleanValue = value.replace('#', '').replace(/[^abcdefABCDEF0-9]/g, '');
            cleanValue = cleanValue.substr(0, 6);
            handleChange({ hex: `#${cleanValue}` }, type);
        }
    };

    const cancelChanges = () => {
        setThemeData(smartlinkProps.customizations);
        setChangeHistory([smartlinkProps.customizations]);
        setHistoryPointer(0);
        dispatchUpdateSmartLinkPreview(null);
        setCustomThemeStarted(false);
        if (smartlinkProps.customizations.theme !== 'custom') {
            setCurrentTheme(smartlinkProps.customizations.theme);
        }
    };

    const saveCustomTheme = (checkContrast = true) => {
        if (checkContrast) {
            const errors = [];
            const headlineColorCheck = checkColorContrast(themeData.headlineText, themeData.pageBg);
            const buttonBgColorCheck = checkColorContrast(themeData.buttonBg, themeData.pageBg);
            const buttonBorderColorCheck = checkColorContrast(themeData.buttonBorder, themeData.pageBg);
            const buttonTextColorCheck = checkColorContrast(themeData.buttonText, themeData.buttonBg);

            if (headlineColorCheck.aaSmall === 'FAIL') {
                errors.push('Headline or Page Background');
            }
            if (buttonBgColorCheck.aaLarge === 'FAIL' && buttonBorderColorCheck.aaLarge === 'FAIL') {
                errors.push('Button Background or Page Background');
            }
            if (buttonTextColorCheck.aaSmall === 'FAIL') {
                errors.push('Button Text or Button Background');
            }
            if (errors.length > 0) {
                setContrastErrors(errors);
                setContrastModalOpen(true);
                return null;
            }
        }

        setSavingCustomTheme(true);

        if (smartlinkProps.settings.subscription.plan === 'plan_pro') {
            const payload = {
                ...themeData,
                theme: 'custom'
            };

            return dispatchUpdateCustomization(affiliate.cognito_id, payload)
                .then(() => {
                    setCustomThemeStarted(false);
                    setSavingCustomTheme(false);
                    closeContrastModal();
                    setToastVerbiage('Saved');
                });
        }

        return null;
    };

    return (
        <>
            <Modal
                open={contrastModalOpen}
                onClose={closeContrastModal}
                title="Low Contrast Notice"
                primaryAction={{
                    content: 'I understand, save anyway',
                    onAction: () => { return saveCustomTheme(false); },
                    loading: savingCustomTheme
                }}
                secondaryActions={[{
                    content: 'Make changes',
                    onAction: closeContrastModal
                }]}
            >
                <Modal.Section>
                    <p>Hmm... A few of your color choices don&rsquo;t have enough contrast and <strong>may be hard to read or see</strong> for some people. We recommend making the following colors <strong>lighter or darker</strong> for better visibility:</p>
                    <ul>
                        {contrastErrors.map((error) => {
                            return <li key={error}>{error}</li>;
                        })}
                    </ul>
                </Modal.Section>
            </Modal>

            <fieldset className="smartlinks--custom-theme" data-saving={savingPreset} onClick={() => attemptUpgradeModal('customTheme')}>
                <div className="custom-theme-heading">
                    <legend>Customize Theme</legend>
                    {customThemeStarted
                        && (
                            <p className="history-buttons">
                                <button type="button" className="undo" onClick={() => { return handleHistoryChange('undo'); }} disabled={!historyPointer}>Undo</button>
                                <button type="button" className="redo" onClick={() => { return handleHistoryChange('redo'); }} disabled={historyPointer === changeHistory.length - 1}>Redo</button>
                            </p>
                        )
                    }
                </div>

                <ul>
                    <li>
                        <label htmlFor="fonts" data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'}>Font</label>
                        <Select
                            value={themeData.font}
                            options={fonts}
                            onChange={(value) => { return handleChange(value, 'font'); }}
                            disabled={savingCustomTheme || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                            onFocus={() => { return attemptUpgradeModal('customTheme'); }}
                            id="fonts"
                        />
                    </li>
                    {colorFields.map((field) => {
                        return (
                            <li key={field.value}>
                                <label htmlFor={field.value} data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'}>{field.label}</label>
                                <div className="color-picker-wrap">
                                    <span className="color-swatch" style={{ backgroundColor: themeData[field.value] }} />
                                    <TextField
                                        value={themeData[field.value]}
                                        pattern="[abcdef0-9]{0,6}"
                                        onFocus={() => { return toggleColorPicker(field.value); }}
                                        onChange={(value) => { return handleColorTextChange(value, field.value); }}
                                        id={field.value}
                                        disabled={savingCustomTheme || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                    />
                                    {activeColorPicker[field.value]
                                        && (
                                            <ColorPicker
                                                color={themeData[field.value]}
                                                disableAlpha
                                                onChange={(value) => { return handleChange(value, field.value, true); }}
                                                onChangeComplete={(value) => { return addToChangeHistory({ [field.value]: value.hex }); }}
                                            />
                                        )
                                    }
                                </div>
                            </li>
                        );
                    })}
                    <li>
                        <label htmlFor="button-roundness" data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'}>Button Roundness</label>
                        <RangeSlider
                            min="0"
                            max="30"
                            value={themeData.buttonRoundness}
                            onChange={(value) => { return handleChange(value, 'buttonRoundness'); }}
                            disabled={savingCustomTheme || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                            onFocus={() => { return attemptUpgradeModal('customTheme'); }}
                            id="button-roundness"
                        />
                    </li>
                </ul>

                {customThemeStarted
                    && (
                        <p className="button-group">
                            <button type="button" className="cancel" onClick={cancelChanges}>Cancel</button>
                            <LlamaButton loading={savingCustomTheme} disabled={savingCustomTheme} onClick={saveCustomTheme}>Save Theme Changes</LlamaButton>
                        </p>
                    )
                }
            </fieldset>
        </>
    );
};

SmartLinkCustomTheme.propTypes = {

};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchtoProps = (dispatch) => {
    return {
        dispatchUpdateSmartLinkPreview: (data) => { return dispatch(updateSmartLinkPreview(data)); },
        dispatchUpdateCustomization: (id, data) => { return dispatch(updateCustomization(id, data)); }
    };
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLinkCustomTheme);
