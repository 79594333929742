import React from 'react';
import PropTypes from 'prop-types';
import { Badge, TextStyle } from '@shopify/polaris';
import { displayCampaignRate } from 'llama-library/utils';

import { ApplicationStatus, AdvertiserStatus } from '../../utils/types';

export const badgeStatuses = {
    [ApplicationStatus.APPROVED]: 'success',
    [ApplicationStatus.PENDING]: 'attention',
    [ApplicationStatus.DENIED]: 'warning'
};

const offerBadgeName = {
    [AdvertiserStatus.EXPIRED]: 'INACTIVE',
    [AdvertiserStatus.UNINSTALLED]: 'INACTIVE',
    [AdvertiserStatus.INACTIVE]: 'PAUSED'
};

const offerBadgeStatus = {
    [AdvertiserStatus.EXPIRED]: 'warning',
    [AdvertiserStatus.UNINSTALLED]: 'warning',
    [AdvertiserStatus.INACTIVE]: 'critical'
};

const ApplicationItem = ({ application, history }) => {
    if (!application || !application.advertiser_status) {
        return null;
    }

    // Change the color of the status badge based on the `advertiser_status`
    const badgeStatus = badgeStatuses[application.advertiser_status]
        ? badgeStatuses[application.advertiser_status]
        : null;

    // Format the badge verbiage to be uppercase on only the first letter.
    const badgeVerbiage = application.advertiser_status.charAt(0).toUpperCase() + application.advertiser_status.substr(1).toLowerCase();

    // Get verbiage for the commission type and amount based on `offer.rate`.
    const commissionVerbiage = displayCampaignRate(application.offer);

    let dateFormatted = new Date(application.created_at).toLocaleDateString('en-US');
    let dateStyle = null;

    // If merchant has requested to deactivated the offer
    if (application.offer && application.offer.interim_status
        && application.offer.interim_status.toUpperCase() === 'ACTIVE') {
        // format style for inactive offer
        dateStyle = { color: 'red' };
        dateFormatted = `(pausing on 
            ${new Date(application.offer.interim_status_date).toLocaleDateString('en-US')})`;
    }

    return (
        <tr
            className="Applications__Item Applications__Row"
            key={application.application_id}
            data-test="component-application-item"
            onClick={() => { return history.push(`/offer/${application.offer.offer_id}`); }}
        >
            <td className="Applications__Merchant">{application.advertiser && application.advertiser.name}</td>
            <td className="Applications__Offer">
                <div>{application.offer && application.offer.name}</div>
                <TextStyle variation="subdued">{commissionVerbiage}</TextStyle>
                {(application.advertiser.status === AdvertiserStatus.INACTIVE
                    || application.advertiser.status === AdvertiserStatus.EXPIRED
                    || application.advertiser.status === AdvertiserStatus.UNINSTALLED)
                    && (
                        <div>
                            <Badge
                                status={offerBadgeStatus[application.advertiser.status]}
                            >
                                {offerBadgeName[application.advertiser.status]}
                            </Badge>
                        </div>
                    )}
            </td>
            <td className="Applications__Date" style={dateStyle}>{dateFormatted}</td>
            <td className="Applications__Status">
                <Badge
                    status={badgeStatus}
                    data-test="application-badge"
                >
                    {badgeVerbiage}
                </Badge>
            </td>
        </tr>
    );
};

ApplicationItem.propTypes = {
    application: PropTypes.shape({
        application_id: PropTypes.string.isRequired,
        advertiser_status: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        advertiser: PropTypes.shape({
            status: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        }).isRequired,
        offer: PropTypes.shape({
            name: PropTypes.string.isRequired,
            offer_id: PropTypes.string.isRequired,
            interim_status: PropTypes.string,
            interim_status_date: PropTypes.string
        }).isRequired
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default ApplicationItem;
