import { useState } from 'react';

const usePages = (initalPage, postsPerPage, posts, scrollTo) => {
    let [ currentPage, setCurrentPage ] = useState(initalPage);

    if (!posts || !Array.isArray(posts)) {
        return { posts: null, currentPage: 0, maxPages: 0 };
    }

    let startNum = currentPage * postsPerPage;
    let endNum = startNum + postsPerPage;
    let hasNext = true;
    let hasPrevious = true;

    let maxPages = posts.length % postsPerPage === 0 
        ? (posts.length / postsPerPage) - 1 
        : Math.floor(posts.length / postsPerPage);

    posts = posts.slice(startNum, endNum);

    const scrollUp = () => {
        if(!scrollTo){
            window.scrollTo(0, 0)
        }else{
            window.scrollTo(0, scrollTo.offsetTop - 10)
        }
    };

    const previousPage = () => {
        scrollUp();

        if (currentPage <= 0) {
            setCurrentPage(0);
        }
        else {
            setCurrentPage(currentPage - 1);
        }
    }

    const nextPage = () => {
        scrollUp();
        
        if(currentPage >= maxPages) {
            setCurrentPage(maxPages);
        }
        else {
            setCurrentPage(currentPage + 1);
        }
    }

    if (currentPage <= 0) {
        hasPrevious = false;
    }

    if (currentPage >= maxPages) {
        hasNext = false;
    }

    return {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    }

}

export default usePages;