/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/
import axios from 'axios';

import { NEXT_APP_API_URL } from '../../../config';
console.log('CREATE INSTAGRAM');
const INSTAGRAM = 'CREATE_INSTAGRAM_USER';

export const createInstagramAffData = (code, id) => {
    const query = `mutation createInstagramUser($code: String, $cognito_id: String){
        createInstagramUser(code: $code, cognito_id: $cognito_id){
            cognito_id
            username
        }
    }`;

    const variables = {
        cognito_id: id,
        code: code
    };

    const payload = {
        query,
        variables
    };

    let headers = {
        "Content-Type": 'application/json'
    };

    return {
        type: INSTAGRAM,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }
}