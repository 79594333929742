import React from 'react';
import { HypeAccount } from 'llama-library/components';
import PropTypes from 'prop-types';

const HypeAuditor = (props) => {
    if (!props.affiliate.instagramDataV2
        || !Array.isArray(props.affiliate.instagramDataV2) && !props.affiliate.instagramDataV2.length > 0
    ) {
        return null;
    }

    const {
        affiliate,
        isVerifyUserClicked,
        handleAccountChange,
        handleVerifyAccount
    } = props;

    if (!affiliate) {
        return null;
    }

    const { instagramDataV2 } = affiliate;
    if (!instagramDataV2 || !Array.isArray(instagramDataV2) || instagramDataV2.length === 0) {
        return null;
    }

    // Config to show/hide the buttons on analytics page
    const showPageActions = {
        verifyAccount: true,
        addAccount: true
    };

    return (
        <>
            <HypeAccount
                {...props}
                isVerifyUserClicked={isVerifyUserClicked}
                handleAccountChange={handleAccountChange}
                handleVerifyAccount={handleVerifyAccount}
                showPageActions={showPageActions}
            />
        </>
    );
};

HypeAuditor.propTypes = {
    affiliate: PropTypes.object.isRequired,
    isVerifyUserClicked: PropTypes.bool.isRequired,
    handleAccountChange: PropTypes.func.isRequired,
    handleVerifyAccount: PropTypes.func.isRequired
};

export default HypeAuditor;
