/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from './sendRequest';

export function updateSocialProfile(cognitoID, socialProfile) {
    const query = `
        mutation updateSmartlinkSocialProfile($cognito_id: String, $data: SLSocialProfileInput!) {
            updateSmartlinkSocialProfile(cognito_id: $cognito_id, data: $data) {
                ${socialProfile.network}
            }
        }
    `

    const variables = {
        cognito_id: cognitoID,
        data: socialProfile
    }

    let payload = {
        query,
        variables
    }

    return {
        type: 'UPDATE_SMARTLINK_SOCIAL_PROFILE',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'UPDATE_SMARTLINK_SOCIAL_PROFILE',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}