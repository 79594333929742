import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Page,
    Layout
} from '@shopify/polaris';

import { changeHeaderTitle } from '../../store/actions/header';
import './llama-dmca.css';

const LlamaDmca = (props) => {

	useEffect(() => {
		props.changeHeaderTitle("DMCA");
	}, [])

    return (
        <Page>
            <Layout>
                <Layout.Section>
                    <div className="llama-dmca__wrapper">
                        <div className="llama-dmca__content">
                            <h1 className="llama-dmca__header">DMCA</h1>
                            <h1 className="llama-dmca__header">Llama LLC </h1>
                            <p className="llama-dmca__body">
                                It is Llama’s policy to respond to notices alleging that any material uploaded by users of the
                                Services or the Llama Platform
                                infringes another persons’ copyright. If you believe any material accessible on or from the Site
                                or the Services infringes your
                                copyright, you may request removal of those materials (or access thereto) from this web site by
                                contacting the Llama, LLC
                                copyright agent (identified below) and providing the following information:
                            </p>
                                <ol>
                                    <li>Identification of the copyrighted work that you believe to be infringed. Please describe
                                        the work, and where possible include a copy or the location (URL) of an authorized
                                        version of the work.
                                    </li>
                                    <li>Identification of the material that you believe to be infringing and its location.
                                        Please describe the material, and provide us with its URL or any other pertinent
                                        information that will allow us to locate the material.
                                    </li>
                                    <li>Your name, address, telephone number and (if available) e-mail address.</li>
                                    <li>A statement that you have a good faith belief that the use of the materials is not
                                        authorized by the copyright owner, its agent, or the law.
                                    </li>
                                    <li>A statement that the information that you have supplied is accurate, and indicating that
                                        “under penalty of perjury,” you are the copyright owner or are authorized to act on the
                                        copyright owner’s behalf.
                                    </li>
                                    <li>A signature or the electronic equivalent from the copyright holder or authorized
                                        representative.
                                    </li>
                                </ol>
                                Our agent for copyright issues relating to the Site or Services is:

                            <p className="llama-dmca__body">Llama Legal  <br />Llama LLC<br />6052 Turkey Lake Road, <br /> Suite 200 <br />
                                Orlando, Florida 32819 <br/>Email: dmca@llama.app</p>

                            <p className="llama-dmca__body">
                                For all email submissions please include the subject line: DMCA Takedown Request.
                            </p>
                            <p className="llama-dmca__body">
                                Llama has adopted a policy of terminating, in appropriate circumstances, accounts of users of
                                the Services or Llama Platform who are deemed to have repeatedly uploaded content that infringes
                                the intellectual property rights of others.
                            </p>
                        </div>
                    </div>
                </Layout.Section>
            </Layout>
        </Page>
    )
}

const mapDispatchToProps = (dispatch) => {
	return {
		changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
	}
}

export default connect(null, mapDispatchToProps)(LlamaDmca);