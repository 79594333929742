/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import {sendGraphQL} from './sendRequest';

const APPLY_TO_ADVERTISER = 'APPLY_TO_ADVERTISER';
export const APPLY_TO_ADVERTISER_PENDING = `${APPLY_TO_ADVERTISER}_PENDING`;
export const APPLY_TO_ADVERTISER_FULFILLED = `${APPLY_TO_ADVERTISER}_FULFILLED`;
export const APPLY_TO_ADVERTISER_REJECTED = `${APPLY_TO_ADVERTISER}_REJECTED`;

export function applyToAdvertiser (data) {
    const query = `
        mutation applyToAdvertiser ($advertiser_id: String, $affiliate_id: String, $offer_id: String) {
            applyToAdvertiser (advertiser_id: $advertiser_id, affiliate_id: $affiliate_id, offer_id: $offer_id) {
                application_id
                affiliate_status
                advertiser_status
                advertiser_id
                affiliate_id
                advertiser {
                    advertiser_id
                    name
                    email
                    avatar_image
                }
                target_id
                target_type
                offer_id
                offer {
                    offer_id
                    name
                    rate {
                        type
                        amount
                    }
                    longterm_rate {
                        type
                        amount
                    }
                }
                affiliate_favorite
                created_at
            }
        }
    `
    
    const variables = {
            affiliate_id: data.affiliate_id,
            offer_id: data.offer_id,
            advertiser_id: data.advertiser_id
    }
    
    const payload = {
        query,
        variables
    }

    return {
        type: APPLY_TO_ADVERTISER,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }
    
    /* let headers = {
        "Content-Type": 'application/json'
    }
    
    return {
        type: APPLY_TO_ADVERTISER,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
        .then(response => response.data)
    } */
};


