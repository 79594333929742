import React from 'react';
import {Button, Spinner} from '@shopify/polaris';
import './settings-notifications.css';

const SettingsNotifications = (props) => {
    if (!props.isLoading) {
        return (

            <div className="settings-notifications__wrapper">
                <div className="settings-notifications__card-wrapper">

                    <div className="settings-notifications__header-section">
                        <h1 className="settings-notifications__h1">
                            How do you like your email?
                        </h1>

                        <p className="settings-notifications__p">
                            Tell us what you want to hear by checking the options below. No matter your preferences -
                            you'll
                            always receive emails
                            about transactions, account information, and product/service updates.
                        </p>
                    </div>
                    <div className="settings-notifications__marketing-section">
                        <h2 className="settings-notifications__h2">
                            Application Preferences
                        </h2>
                        <div className="settings-notifications__input-selection">
                            <label className="settings-notifcations__input-wrapper">
                                <input
                                    id="application_general"
                                    value={props.email.application_general}
                                    checked={props.email.application_general}
                                    onChange={props.handleChange}
                                    type="checkbox"
                                    name="selectAllMarketing"
                                />
                                <span className="settings-notifications__input-checkmark">
                                </span>
                            </label>
                            <div className="settings-notifications--input-label">
                                Keep me updated with Llama App
                            </div>
                        </div>

                        <div className="settings-notifications__marketing-selections">
                            <div className="settings-notifications__input-selection">
                                <label className="settings-notifcations__input-wrapper">
                                    <input
                                        id="application_alerts"
                                        value={props.email.application_alerts}
                                        checked={props.email.application_alerts}
                                        onChange={props.handleChange}
                                        type="checkbox"
                                        name="selectAllMarketing"
                                    />
                                    <span className="settings-notifications__input-checkmark">
                                    </span>
                                </label>

                                <div className="settings-notifications__input-col">
                                    <div className="settings-notifications--label-title">
                                        Application Alert
                                    </div>
                                    <div className="settings-notifications--label-note">
                                        Get a note with the status of your applications (Approved, Pending, etc...).
                                    </div>
                                </div>
                            </div>

                            <div className="settings-notifications__input-selection">
                                <label className="settings-notifcations__input-wrapper">
                                    <input
                                        id="application_advertiser_messages"
                                        value={props.email.application_advertiser_messages}
                                        checked={props.email.application_advertiser_messages}
                                        onChange={props.handleChange}
                                        type="checkbox"
                                        name=""
                                    />
                                    <span className="settings-notifications__input-checkmark">
                                    </span>
                                </label>

                                <div className="settings-notifications__input-col">
                                    <div className="settings-notifications--label-title">
                                        Advertiser messages
                                    </div>
                                    <div className="settings-notifications--label-note">
                                        Accept messages from Advertisers
                                    </div>
                                </div>
                            </div>

                            <div className="settings-notifications__input-selection">
                                <label className="settings-notifcations__input-wrapper">
                                    <input
                                        id="application_weekly_summary"
                                        value={props.email.application_weekly_summary}
                                        checked={props.email.application_weekly_summary}
                                        onChange={props.handleChange}
                                        type="checkbox"
                                        name=""
                                    />
                                    <span className="settings-notifications__input-checkmark">
                                    </span>
                                </label>

                                <div className="settings-notifications__input-col">
                                    <div className="settings-notifications--label-title">
                                        Weekly Summary
                                    </div>
                                    <div className="settings-notifications--label-note">
                                        Stay on top of your game with weekly performance updates.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="settings-notifications__newsletter-section">
                            <h2 className="settings-notifications__h2">
                                Newsletters Preferences
                            </h2>
                            <div className="settings-notifications__input-selection">
                                <label className="settings-notifcations__input-wrapper">
                                    <input
                                        id="newsletter_general"
                                        value={props.email.newsletter_general}
                                        checked={props.email.newsletter_general}
                                        onChange={props.handleChange}
                                        type="checkbox"
                                        name="selectAllNewsletters"
                                    />
                                    <span className="settings-notifications__input-checkmark">
                                    </span>
                                </label>
                                <div className="settings-notifications--input-label">
                                    Sign me up for newsletters from LlamaApp
                                </div>
                            </div>
                            <div className="settings-notifications__newsletter-selections">
                                <div className="settings-notifications__input-selection">
                                    <label className="settings-notifcations__input-wrapper">
                                        <input
                                            id="newsletter_marketing"
                                            value={props.email.newsletter_marketing}
                                            checked={props.email.newsletter_marketing}
                                            onChange={props.handleChange}
                                            type="checkbox"
                                            name="selectAllMarketing"
                                        />
                                        <span className="settings-notifications__input-checkmark">
                                        </span>
                                    </label>

                                    <div className="settings-notifications__input-col">
                                        <div className="settings-notifications--label-title">
                                            Marketing and Promotional Materials
                                        </div>
                                        <div className="settings-notifications--label-note">
                                            Seriously, you'll regret checking this box.
                                        </div>
                                    </div>
                                </div>

                                <div className="settings-notifications__input-selection">
                                    <label className="settings-notifcations__input-wrapper">
                                        <input
                                            id="newsletter_updates"
                                            value={props.email.newsletter_updates}
                                            checked={props.email.newsletter_updates}
                                            onChange={props.handleChange}
                                            type="checkbox"
                                            name=""
                                        />
                                        <span className="settings-notifications__input-checkmark">
                                        </span>
                                    </label>

                                    <div className="settings-notifications__input-col">
                                        <div className="settings-notifications--label-title">
                                            Application Updates and Features
                                        </div>
                                        <div className="settings-notifications--label-note">
                                            Get notified about big updates and new features.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="settings-notifications__unsubscribe-section">
                            <h2 className="settings-notifications__h2">
                                Unsubscribe me from all marketing and subscription emails
                            </h2>
                            <div className="settings-notifications__input-selection">
                                <label className="settings-notifcations__input-wrapper">
                                    <input
                                        id="email_unsubscribe"
                                        value={props.email.email_unsubscribe}
                                        checked={props.email.email_unsubscribe}
                                        onChange={props.handleChange}
                                        type="checkbox"
                                        name="selectAllNewsletters"
                                    />
                                    <span className="settings-notifications__input-checkmark">
                                    </span>
                                </label>
                                <div className="settings-notifications--input-label">
                                    Please do not send me any emails for now
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="settings-notifications__submit_btn">
                        <Button id="settings-notification-submit" primary onClick={props.submitChanges}
                        >Save</Button>
                    </div>

                </div>
            </div>
        )
    } else {
        return (
            <p className="loading"><Spinner/></p>
        )
    }
};

export default SettingsNotifications;