/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import {sendGraphQL} from './sendRequest';

export function updateSmartLink(cognitoID, smartLinkInput, source) {
    const query = `
        mutation updateSmartLink($cognito_id: String, $source: String, $data: SmartLinkInput!) {
            updateSmartLink(cognito_id: $cognito_id, source: $source, data: $data) {
                cognito_id
                sendToLinksStatus
                headline
                full_name
                bio
                nsfwType
                socialProfiles {
                    facebook
                    twitter
                    instagram
                    tiktok
                    youtube
                    soundcloud
                }
                automaticLinks{
                    id
                    title
                    url
                    offerId
                    offerName
                    company
                }
            }
        }
    `;

    const variables = {
        cognito_id: cognitoID,
        source,
        data: smartLinkInput
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'UPDATE_SMARTLINK',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'UPDATE_SMARTLINK',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}

export const updateSmartlinkIntegrations = (cognito_id, data, testApi = false) => {
    const query = `
        mutation updateSmartlinkIntegrations($cognito_id: String, $data: SLIntegrationsInput!, $testApi: Boolean) {
            updateSmartlinkIntegrations(cognito_id: $cognito_id, data: $data, testApi: $testApi) {
                gaTrackingId
                fbPixelId
                utm {
                    source
                    campaign
                    medium
                }
                mailingList {
                    platform
                    listId
                    apiKey
                    apiPrefix
                    formHeading
                    formButtonText
                }
                mailingListError
            }
        }
    `;

    const variables = {
        cognito_id,
        data,
        testApi
    };

    const payload = {
        query,
        variables
    };

    return {
        type: 'UPDATE_SMARTLINK_INTEGRATIONS',
        payload: sendGraphQL(payload)
            .then((response) => { return response.data; })
            .catch((err) => {
                console.log(err);
                return err;
            })
    };
};

/**
 * Updates the live preview component via redux as the user makes changes, does not interact with the database
 */
export const updateSmartLinkPreview = (payload) => {
    return {
        type: 'UPDATE_SMARTLINKS_PREVIEW',
        payload
    };
};

export function updateBillingDetails(cognitoID, data) {
    const query = `
        mutation updateBillingDetails($cognito_id: String, $data: AffiliateBillingInfoInput!) {
                updateBillingDetails(cognito_id: $cognito_id, data: $data) {
                    cognito_id
                }
        }
    `

    const variables = {
        cognito_id: cognitoID,
        data: data
    }

    let payload = {
        query,
        variables
    }

    return {
        type: 'UPDATE_AFFILIATE_BILLING_DETAILS',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'UPDATE_AFFILIATE_BILLING_DETAILS',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}