/* import axios from 'axios';
import {NEXT_APP_API_URL} from '../../config' */
import { sendGraphQL } from "./sendRequest";

export const checkSmartLinkCustomId = (id, custom_id) => {
    const query = `query checkCustomId($cognito_id: String, $custom_id: String){
        checkCustomId(cognito_id: $cognito_id, custom_id: $custom_id){
            is_available
        }
    }
    `;

    const variables = {
        cognito_id: id,
        custom_id
    }

    const payload = {
        query,
        variables
    }

    return {
        type: 'CHECK_SMARTLINK_CUSTOM_ID',
        payload: sendGraphQL(payload)
            .then(response => response.data)
            .catch(error => {return error})
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }
    
    return {
        type: 'CHECK_SMARTLINK_CUSTOM_ID',
        payload: axios.post(NEXT_APP_API_URL, payload, {headers})
            .then(response => response.data)
            .catch(error => {return error})
    } */
}