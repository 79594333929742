/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_SOCIAL_REDIRECT_URL } from '../../../config'; */
import { sendBackendRequest } from "../sendRequest";

const SOCIAL_REDIRECT = 'GET_SOCIAL_REDIRECT_URL';

export const getSocialRedirectUrl = (platform) => {
    //const payload = `platform=${platform}`;
    const payload = {
        platform
    }

    return {
        type: SOCIAL_REDIRECT,
        payload: sendBackendRequest('general', 'social-request', payload, false, 'GET')
            .then(response => response)
    }
    /* let headers = { "Content-Type": 'application/json' };
    let url = `${NEXT_APP_SOCIAL_REDIRECT_URL}?platform=${platform}`;

    return {
        type: SOCIAL_REDIRECT,
        payload: axios.get(url, headers)
            .then((response) => {
                return response;
            })
    } */
}