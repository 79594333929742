import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeHeaderTitle } from '../store/actions/header';
import NavigationSidebar from '../components/navigation-sidebar/navigation-sidebar';
import NotificationsMenu from '../components/notifications/menu/notifications-sideMenu';
import Header from '../components/header/header';
import './styles/layout.css';

class Layout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isOpen: true,
            popoverActive: false,
            showSideDrawer: false,
        };

        this.toggleMobile = this.toggleMobile.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
    }

    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false});
    };

    sideDrawerToggleHandler = (newState) => {
        if (newState !== undefined && newState !== null) {
            this.setState({ 
                showSideDrawer: newState,
                isMobile: false,
            });
        }
        this.setState({ 
            showSideDrawer: !this.state.showSideDrawer,
            isMobile: false, 
        });
    };
    

    toggleMobile(newState) {
        const isMobile = !newState
            ? false
            : !this.state.isMobile;

        this.setState({
            isMobile,
            showSideDrawer: false,
        });
    }

    togglePopover() {
        this.setState({
            popoverActive: !this.state.popoverActive
        });
    }

    render() {


        return (
            <div className="layout">
                <div className="layout__sidebar">
                    {!this.props.showContainerOnly &&
                        (
                            <NavigationSidebar
                                {...this.state}
                                {...this.props}
                                toggleMobile={this.toggleMobile}
                                togglePopover={this.togglePopover}
                                sideDrawerToggleHandler={this.sideDrawerToggleHandler}
                                signOut={this.props.signOut}
                            />
                        )}
                </div>
                <div className="layout__main">
                    <div className="layout__header">
                        {!this.props.showContainerOnly &&
                            (
                                <Header
                                    {...this.props}
                                    {...this.state}
                                    toggleSideDrawer={this.sideDrawerToggleHandler}
                                />
                            )}
                    </div>
                    {this.props.children}
                </div>
                <div className="layout__notifications">
                    {!this.props.showContainerOnly &&
                        (
                            <NotificationsMenu
                                {...this.props}
                                {...this.state}
                                open={this.state.showSideDrawer}
                                closed={() => this.sideDrawerToggleHandler(false)}
                            />
                        )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({affiliate, authData}) => {
    return {affiliate, authData}
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

