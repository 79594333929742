export const SAVE_PERSONAL_INFO = 'save_personal_info';
export const SAVE_BILLING_INFO = 'save_billing_info';
export const SAVE_PAYPAL_INFO = 'save_paypal_info';
export const SAVE_CATEGORIES = 'save_categories';
export const GET_AFFILIATE = 'get_affiliate';
export const GET_PRODUCTS = 'get_products';
export const UPDATE_EMAIL_PREF = "update_email_pref";
export const UPDATE_AFFILIATE = 'UPDATE_AFFILIATE';
export const UPDATE_AFFILIATE_FULFILLED = 'UPDATE_AFFILIATE_FULFILLED';

// Can use this when missing params and an API call isn't made.
export const NO_API_CALL = 'NO_API_CALL';

