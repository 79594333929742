import React, { useState, useEffect } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { Frame, Toast, Modal, Banner } from '@shopify/polaris';

import SmartLinkGeneral from './settings/smart-link-general';
import SmartLinkThemes from './settings/smart-link-themes';
import SmartLinkIntegrations from './settings/smart-link-integrations';
import SmartLinkSocial from './settings/smart-link-social';

import './smart-link-settings.css';

const basePath = '/smartlinks/customize';
const categories = [
    {
        name: 'General',
        key: 'general',
        link: `${basePath}/general`
    },
    {
        name: 'Themes',
        key: 'themes',
        link: `${basePath}/themes`
    },
    {
        name: 'Integrations',
        key: 'integrations',
        link: `${basePath}/integrations`
    },
    {
        name: 'Social Media',
        key: 'social',
        link: `${basePath}/social-media`
    }
];
const mobileWidth = 780;

const SmartLinkSettings = (smartlinkProps) => {
    const [toastVerbiage, setToastVerbiage] = useState(null);
    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
    const [proFeatureSnippet, setProFeatureSnippet] = useState('');

    const [currentCategory, setCurrentCategory] = useState({ key: 'general', name: 'General' });
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [navOpen, setNavOpen] = useState(true);

    console.log('SETTINGS PROPS', smartlinkProps);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (pageWidth !== window.innerWidth) {
                setPageWidth(window.innerWidth);
            }

            // show/hide dropdown nav for mobile (on resize)
            if (window.innerWidth <= mobileWidth) {
                setNavOpen(false);
            } else {
                setNavOpen(true);
            }
        });

        // show/hide dropdown nav for mobile (on load)
        if (window.innerWidth <= mobileWidth) {
            setNavOpen(false);
        } else {
            setNavOpen(true);
        }

        // set current category if the page was loaded on a different page then general
        if (window.location.pathname !== '/smartlinks/customize/general') {
            const categoryKey = window.location.pathname.replace('/smartlinks/customize/', '');
            const category = categories.find((categoryDetails) => {
                return categoryDetails.key === categoryKey;
            });

            setCurrentCategory(category);
        }
    }, []);

    useEffect(() => {
        // hide dropdown nav for mobile (on page switch)
        if (window.innerWidth <= mobileWidth) {
            setNavOpen(false);
        }
    }, [currentCategory]);

    const toastMarkup = toastVerbiage
        ? <Toast content={toastVerbiage} duration="3000" onDismiss={() => { setToastVerbiage(null); }} />
        : null;

    // determine whether to launch a modal about upgrading to pro
    const attemptUpgradeModal = (field) => {
        if (smartlinkProps.plan !== 'pro') {
            setUpgradeModalOpen(true);

            switch (field) {
                case 'customId':
                    setProFeatureSnippet('Customizing your URL handle');
                    break;
                case 'headline':
                    setProFeatureSnippet('Changing your name/headline outside of your Llama profile');
                    break;
                case 'customTheme':
                    setProFeatureSnippet('Advanced theme customization');
                    break;
                case 'mailingList':
                    setProFeatureSnippet('Letting people join your newsletter/email list');
                    break;
                case 'nsfwType':
                    setProFeatureSnippet('Adding an NSFW content warning');
                    break;
                case 'gaTrackingId':
                    setProFeatureSnippet('Adding your Google Analytics Tracking ID');
                    break;
                default:
                    break;
            }
        }
    };

    const closeModal = () => {
        setUpgradeModalOpen(false);
    };

    return (
        <>
            <p className="heading">Customize Your Page</p>
            <p className="info">Upload a profile picture, set your color theme, add a form to join your mailing list, and more.</p>

            <div id="smartlinks__customize-panel">
                {pageWidth <= mobileWidth
                    && <p><button type="button" className={`current-page ${currentCategory.key}`} onClick={() => { return setNavOpen(!navOpen); }}>{currentCategory.name}</button></p>
                }
                {navOpen
                    && (
                        <nav>
                            <ul>
                                {categories.map((category) => {
                                    return (
                                        <li key={category.key} className={category.key} data-alert={category.key === 'integrations' && !!smartlinkProps.integrationsError}>
                                            <NavLink to={category.link} activeClassName="active" onClick={() => { return setCurrentCategory(category); }}>{category.name}</NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                    )
                }

                <Modal
                    open={upgradeModalOpen}
                    title="You found a PRO feature"
                    onClose={closeModal}
                    primaryAction={{
                        content: 'Upgrade to PRO',
                        onAction: smartlinkProps.goToPlans
                    }}
                    secondaryActions={[{
                        content: 'Cancel',
                        onAction: closeModal
                    }]}
                >
                    <Modal.Section>
                        <p>{proFeatureSnippet} is <strong>only available in SmartLinks Pro</strong>. Get access to this feature and more when you upgrade.</p>
                    </Modal.Section>
                </Modal>

                <div className={`panel-content ${window.location.pathname.replace(/[^[\w/-]]+/g, '').replace(`${basePath}/`, '')}`}>
                    <Switch>
                        <Route path={`${basePath}/general`} exact render={(props) => { return <SmartLinkGeneral {...props} setToastVerbiage={setToastVerbiage} attemptUpgradeModal={attemptUpgradeModal} />; }} />
                        <Route path={`${basePath}/themes`} exact render={(props) => { return <SmartLinkThemes {...props} setToastVerbiage={setToastVerbiage} attemptUpgradeModal={attemptUpgradeModal} />; }} />
                        <Route path={`${basePath}/integrations`} exact render={(props) => { return <SmartLinkIntegrations {...props} setToastVerbiage={setToastVerbiage} attemptUpgradeModal={attemptUpgradeModal} />; }} />
                        <Route path={`${basePath}/social-media`} exact render={(props) => { return <SmartLinkSocial {...props} setToastVerbiage={setToastVerbiage} />; }} />
                    </Switch>
                    {!smartlinkProps.linksLoading && smartlinkProps.plan === 'free'
                        && (
                            <div className="pro-banner">
                                <Banner
                                    title="Do more with SmartLinks PRO"
                                    status="info"
                                    action={{ content: 'Upgrade to PRO', onAction: smartlinkProps.goToPlans }}
                                >
                                    <p>Fully customize your SmartLinks page, add third-party integrations, and track performance when you upgrade to SmartLinks PRO</p>
                                </Banner>
                            </div>
                        )
                    }
                </div>
            </div>
            <Frame>{toastMarkup}</Frame>
        </>
    );
};

export default SmartLinkSettings;
