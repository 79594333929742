const parseGraph = (response) => {

    if (!response) {
        return Promise.reject(new Error('No response from server'));
    }

    if (!response.status === 200) {
        if (response.statusText) {
            return Promise.reject(response.statusText);
        }

        return Promise.reject(new Error(`An error has occured. Status Code: ${response.status}`));
    }

    if (response.message) {
        return Promise.reject(new Error(response.message));
    }

    if (!response.data) {
        return Promise.reject(new Error('No data returned from server. Please try again.'));
    }

    const { data } = response;

    if (data.errors && data.errors.length > 0) {
        if (!Array.isArray(data.errors)) {
            data.errors = [data.errors];
        }

        data.errors.forEach((error) => {
            console.error(error.message);
        });

        return Promise.reject(new Error('An error has occured.'));
    }

    if (!data || !data.hasOwnProperty('data')) {
        return Promise.reject(new Error('No data returned from server. Please try again.'));
    }

    return data.data;

}

export default parseGraph;