import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popover, ActionList, Icon } from '@shopify/polaris';
import { LockMinor } from '@shopify/polaris-icons';

import logo from '../../assets/logo.svg';
import userlogo from '../../assets/llama-user-icon.png';
import notification from '../../assets/notification-icon.svg';
import settingsIcon from '../../assets/settings-icon.svg';
import helpIcon from '../../assets/help-icon.svg';
import signoutIcon from '../../assets/signout-icon.svg';

import { settingsLinks } from '../settings-navigation/settings-navigation';

import './navigation-sidebar.css';

const settingsNavLinks = settingsLinks.reduce((acc, linkInfo) => {
    acc.push(`/settings${linkInfo.link}`);
    return acc;
}, []);
settingsNavLinks.push('/settings');

const becomeAmbassadorPath = '/become-an-ambassador';

const MainNav = ({ nav, toggleMobile }) => {
    const { pathname } = useLocation();
    const isActive = (link) => {
        if (link.link === becomeAmbassadorPath) {
            return link.content === 'Become an Ambassador' && link.link === pathname;
        }
        if (link.activeLinks) {
            return link.activeLinks.includes(pathname);
        }
        return link.link === pathname;
    };

    return nav.map((section) => {
        return (
            <div className="NavigationSidebar__Section" key={section.heading}>
                <h2 className="NavigationSidebar__Heading">{section.heading}</h2>
                <ul className="NavigationSidebar__Links-List">
                    {section.links.map((link) => {
                        return (
                            <li key={link.link}>
                                <NavLink
                                    to={link.link}
                                    onClick={toggleMobile}
                                    className="NavigationSidebar__Link"
                                    activeClassName="NavigationSidebar__active"
                                    exact
                                    isActive={() => { return isActive(link); }}
                                >
                                    {link.content}
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    });
};

const SmartlinksOnlyLinks = ({ affiliate, toggleMobile, hasActiveInstagram, hasActiveTikTok }) => {
    const nav = [
        {
            heading: 'Profile',
            links: [
                {
                    link: '/instaAnalytics',
                    content: (
                        <span
                            className={affiliate.cognito_id && !hasActiveInstagram
                                ? 'NavigationSidebar__Gear-FlexRight NavigationSidebar__Inactive '
                                : 'NavigationSidebar__Gear-FlexRight'}
                        >
                            InstaAnalytics
                            {affiliate.cognito_id && !hasActiveInstagram && <Icon source={LockMinor} color="inkLightest" />}
                        </span>
                    )
                },
                {
                    link: '/tiktokAnalytics',
                    content: (
                        <span
                            className={affiliate.cognito_id && !hasActiveTikTok
                                ? 'NavigationSidebar__Gear-FlexRight NavigationSidebar__Inactive '
                                : 'NavigationSidebar__Gear-FlexRight'}
                        >
                            TikTokAnalytics
                            {affiliate.cognito_id && !hasActiveTikTok && <Icon source={LockMinor} color="inkLightest" />}
                        </span>
                    )
                }
            ]
        },
        {
            heading: 'Offers',
            links: [
                {
                    link: becomeAmbassadorPath,
                    content: 'Discover'
                },
                {
                    link: becomeAmbassadorPath,
                    content: 'Your Offers'
                },
                {
                    link: becomeAmbassadorPath,
                    content: 'Invoices'
                },
                {
                    link: '/smartlinks',
                    content: 'SmartLinks'
                },
                {
                    link: becomeAmbassadorPath,
                    content: 'Become an Ambassador'
                }
            ]
        }
    ];

    return <MainNav nav={nav} toggleMobile={toggleMobile} />;
};

const LlamaLinks = ({ affiliate, unreadChats, toggleMobile, hasActiveInstagram, hasActiveTikTok }) => {
    const nav = [
        {
            heading: 'Profile',
            links: [
                {
                    link: '/dashboard',
                    content: 'Dashboard'
                },
                {
                    link: '/instaAnalytics',
                    content: (
                        <span
                            className={affiliate.cognito_id && !hasActiveInstagram
                                ? 'NavigationSidebar__Gear-FlexRight NavigationSidebar__Inactive '
                                : 'NavigationSidebar__Gear-FlexRight'}
                        >
                            InstaAnalytics
                            {affiliate.cognito_id && !hasActiveInstagram && <Icon source={LockMinor} color="inkLightest" />}
                        </span>
                    )
                },
                {
                    link: '/tiktokAnalytics',
                    content: (
                        <span
                            className={affiliate.cognito_id && !hasActiveTikTok
                                ? 'NavigationSidebar__Gear-FlexRight NavigationSidebar__Inactive '
                                : 'NavigationSidebar__Gear-FlexRight'}
                        >
                            TikTokAnalytics
                            {affiliate.cognito_id && !hasActiveTikTok && <Icon source={LockMinor} color="inkLightest" />}
                        </span>
                    )
                }
            ]
        },
        {
            heading: 'Offers',
            links: [
                {
                    link: '/discover',
                    content: 'Discover'
                },
                {
                    link: '/offers',
                    content: 'Your Offers'
                },
                {
                    link: '/invoices',
                    content: 'Invoices'
                },
                {
                    link: '/smartlinks',
                    content: 'SmartLinks'
                }
            ]
        },
        {
            heading: 'Brands',
            links: [
                /* TODO uncomment if/when we make these pages {
                    link: '/discover-brands',
                    content: 'Discover'
                },
                {
                    link: '/brands',
                    content: 'Your Brands'
                }, */
                {
                    link: '/chat',
                    content: (
                        <>
                            Chat {unreadChats > 0 && <span className="alert">{unreadChats}</span>}
                        </>
                    )
                }
            ]
        }
    ];

    return <MainNav nav={nav} toggleMobile={toggleMobile} />;
};

const NavigationSidebar = (props) => {
    const { affiliate, togglePopover, popoverActive, isMobile, toggleMobile, sideDrawerToggleHandler, unreadChatMessages, signOut } = props;

    const [unreadChats, setUnreadChats] = useState(0);
    // Help nav state management.
    const [openHelpNav, setOpenHelpNav] = useState(false);
    const helpNavElement = useRef(null);

    // Click handler for outside events.
    const helpNavClickHandler = (e) => {
        if (helpNavElement.current.contains(e.target)) {
            return;
        }
        toggleHelpNav(false);
    };

    // Clean up click handler if component is unmounted.
    useEffect(() => {
        return () => {
            toggleHelpNav(false);
        };
    }, []);

    // get total number of unread chats
    useEffect(() => {
        console.log('UPDATING URNREAD CHATS...', unreadChatMessages);
        if (unreadChatMessages.length > 0) {
            const totalUnread = unreadChatMessages.reduce((acc, message) => {
                if (message?.message_ids) {
                    return acc + message.message_ids.length;
                }
                return acc;
            }, 0);
            setUnreadChats(totalUnread);
        } else {
            setUnreadChats(0);
        }
    }, [unreadChatMessages]);

    console.log('UNREAD CHAT MESSAGE PROPS', unreadChatMessages);

    // Open and close the help nav, sets up 'click' event listeners to see
    // if a click happens outside of the help nav element.
    const toggleHelpNav = useCallback((event) => {
        if (openHelpNav || !event) {
            document.removeEventListener('click', helpNavClickHandler, false);
            setOpenHelpNav(false);
        } else {
            document.addEventListener('click', helpNavClickHandler, false);
            setOpenHelpNav(true);
        }
    }, []);

    const activator = (
        <div onClick={togglePopover} className="NavigationSidebar__Popover-Activator">
            <img alt="" src={userlogo} className="NavigationSidebar__User-Mobile" />
        </div>
    );

    const { pathname } = useLocation();

    // Check for HypeAuditor.
    const instagramAccounts = Array.isArray(affiliate.instagramDataV2)
        ? affiliate.instagramDataV2.filter((item) => {
            return item.account_type === 'ACTIVE' || item.account_type === 'TRIAL';
        })
        : [];

    const hasActiveInstagram = Array.isArray(instagramAccounts)
        && instagramAccounts.length > 0;

    // Check for Instagram
    const tikTokAccounts = Array.isArray(affiliate.tikTokData)
        ? affiliate.tikTokData.filter((item) => {
            return item.account_type === 'ACTIVE' || item.account_type === 'TRIAL';
        })
        : [];

    const hasActiveTikTok = Array.isArray(tikTokAccounts)
        && tikTokAccounts.length > 0;

    return (
        <>
            <div
                className={isMobile === true ? 'NavigationSidebar__Main NavigationSidebar__Main--open' : 'NavigationSidebar__Main'}
            >
                <div className="NavigationSidebar__Nav-Flex">
                    <div className="NavigationSidebar__Nav-Top">
                        <div className="NavigationSidebar__Logo-Wrapper">
                            <img alt="" src={logo} className="NavigationSidebar__Logo" />
                            <div className="NavigationSidebar__Mobile-Menu">
                                <div className="NavigationSidebar__Ham-Wrapper">
                                    <div className="NavigationSidebar__Hamburger" onClick={toggleMobile}>
                                        <div
                                            className={isMobile === true ? 'NavigationSidebar__Ham-Ingredient NavigationSidebar__Ham-Ingredient--close01' : 'NavigationSidebar__Ham-Ingredient'}
                                        />
                                        <div
                                            className={isMobile === true ? 'NavigationSidebar__Ham-Ingredient NavigationSidebar__Ham-Ingredient--open' : 'NavigationSidebar__Ham-Ingredient'}
                                        />
                                        <div
                                            className={isMobile === true ? 'NavigationSidebar__Ham-Ingredient NavigationSidebar__Ham-Ingredient--close02' : 'NavigationSidebar__Ham-Ingredient'}
                                        />
                                    </div>
                                </div>
                                <div className="NavigationSidebar__Logo-Wrapper-Mobile">
                                    <img alt="" src={logo} className="NavigationSidebar__Logo-Mobile" />
                                </div>
                                <div className="NavigationSidebar__Popover-Wrapper">
                                    {/* <NavLink to="/alerts" onClick={() => toggleMobile(false)} exact> */}
                                    <img src={notification} onClick={sideDrawerToggleHandler} className="NavigationSidebar__Notification-Logo" />
                                    {/* </NavLink> */}
                                    <Popover
                                        active={popoverActive}
                                        activator={activator}
                                        onClose={togglePopover}
                                        preferredAlignment="right"
                                        preferredPosition="mostSpace"
                                    >
                                        <ActionList items={[{ content: 'Sign Out' }]} />
                                    </Popover>
                                </div>
                            </div>
                        </div>

                        <div className="NavigationSidebar__Profile">
                            {affiliate.account_type === 'smartlinks'
                                ? (
                                    <SmartlinksOnlyLinks
                                        affiliate={affiliate}
                                        toggleMobile={toggleMobile}
                                        hasActiveInstagram={hasActiveInstagram}
                                        hasActiveTikTok={hasActiveTikTok}
                                    />
                                )
                                : (
                                    <LlamaLinks
                                        affiliate={affiliate}
                                        unreadChats={unreadChats}
                                        toggleMobile={toggleMobile}
                                        hasActiveInstagram={hasActiveInstagram}
                                        hasActiveTikTok={hasActiveTikTok}
                                    />
                                )
                            }
                        </div>
                    </div>

                    {/* bottom links */}
                    <div className="NavigationSidebar__Nav-Bottom">
                        <div className="NavigationSidebar__Settings">
                            <div className="NavigationSidebar__Settings-Link">
                                <NavLink
                                    to="/settings"
                                    onClick={toggleMobile}
                                    className="NavigationSidebar__Link"
                                    activeClassName="NavigationSidebar__active"
                                    isActive={() => {
                                        return settingsNavLinks.includes(pathname);
                                    }}
                                    exact
                                >
                                    <span className="NavigationSidebar__Gear-Flex"><img
                                        alt=""
                                        src={settingsIcon}
                                        className="NavigationSidebar__Link-Icon"
                                    /> Settings
                                    </span>
                                </NavLink>

                                <div
                                    className={openHelpNav ? 'NavigationSidebar__Link NavigationSidebar__active' : 'NavigationSidebar__Link'}
                                    onClick={() => { return toggleHelpNav(!openHelpNav); }}
                                    ref={helpNavElement}
                                >
                                    <span className="NavigationSidebar__Gear-Flex"><img
                                        alt=""
                                        src={helpIcon}
                                        className="NavigationSidebar__Link-Icon"
                                    /> Policies
                                    </span>
                                    {openHelpNav
                                    && (
                                        <div className="NavigationSidebar__Popout">
                                            <div className="NavigationSidebar__PopoutArrow" />
                                            {/* <div className="NavigationSidebar__PopoutItem" onClick={startTutorial}>Start Tutorial</div> */}
                                            <NavLink to="/tos" className="NavigationSidebar__PopoutItemLink">
                                                <div onClick={() => { return toggleHelpNav(false); }} className="NavigationSidebar__PopoutItem">Terms of Service</div>
                                            </NavLink>
                                            <NavLink to="/privacy" className="NavigationSidebar__PopoutItemLink">
                                                <div onClick={() => { return toggleHelpNav(false); }} className="NavigationSidebar__PopoutItem">Privacy Policy</div>
                                            </NavLink>
                                            <NavLink to="/dmca" className="NavigationSidebar__PopoutItemLink">
                                                <div onClick={() => { return toggleHelpNav(false); }} className="NavigationSidebar__PopoutItem">DMCA</div>
                                            </NavLink>
                                        </div>
                                    )}
                                </div>

                                <div className="NavigationSidebar__Link">
                                    <span onClick={signOut} className="NavigationSidebar__Gear-Flex"><img src={signoutIcon} className="NavigationSidebar__Link-Icon" /> Sign Out</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* End NavigationSidebar__Nav-Botton */}

                </div>
                {/* End NavigationSidebar__Nav-Flex */}

            </div>
            {/* ----End NavigationSidebar__Main----*/}
            <div className="NavigationSidebar__Main NavigationSidebar__FixedSpacer" />
        </>
    );
};

MainNav.propTypes = {
    toggleMobile: PropTypes.func.isRequired,
    nav: PropTypes.arrayOf(PropTypes.shape({
        heading: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(PropTypes.shape({
            link: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            activeLinks: PropTypes.arrayOf(PropTypes.string)
        })).isRequired
    })).isRequired
};

SmartlinksOnlyLinks.propTypes = {
    affiliate: PropTypes.shape({
        instagramDataV2: PropTypes.arrayOf(PropTypes.shape({
            account_type: PropTypes.string
        })),
        tikTokData: PropTypes.arrayOf(PropTypes.shape({
            account_type: PropTypes.string
        })),
        cognito_id: PropTypes.string.isRequired
    }).isRequired,
    toggleMobile: PropTypes.func.isRequired,
    hasActiveTikTok: PropTypes.bool,
    hasActiveInstagram: PropTypes.bool
};

SmartlinksOnlyLinks.defaultProps = {
    hasActiveTikTok: false,
    hasActiveInstagram: false
};

LlamaLinks.propTypes = {
    affiliate: PropTypes.shape({
        instagramDataV2: PropTypes.arrayOf(PropTypes.shape({
            account_type: PropTypes.string
        })),
        tikTokData: PropTypes.arrayOf(PropTypes.shape({
            account_type: PropTypes.string
        })),
        cognito_id: PropTypes.string.isRequired
    }).isRequired,
    unreadChats: PropTypes.number,
    toggleMobile: PropTypes.func.isRequired,
    hasActiveTikTok: PropTypes.bool,
    hasActiveInstagram: PropTypes.bool
};

LlamaLinks.defaultProps = {
    unreadChats: 0,
    hasActiveTikTok: false,
    hasActiveInstagram: false
};

NavigationSidebar.propTypes = {
    unreadChatMessages: PropTypes.arrayOf(PropTypes.shape({
        sender_id: PropTypes.string,
        message_ids: PropTypes.arrayOf(PropTypes.string)
    })).isRequired,
    togglePopover: PropTypes.func.isRequired,
    affiliate: PropTypes.shape({
        instagramDataV2: PropTypes.arrayOf(PropTypes.shape({
            account_type: PropTypes.string
        })),
        tikTokData: PropTypes.arrayOf(PropTypes.shape({
            account_type: PropTypes.string
        })),
        cognito_id: PropTypes.string.isRequired,
        account_type: PropTypes.string
    }).isRequired,
    isMobile: PropTypes.bool.isRequired,
    toggleMobile: PropTypes.func.isRequired,
    sideDrawerToggleHandler: PropTypes.func.isRequired,
    popoverActive: PropTypes.bool.isRequired,
    signOut: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        unreadChatMessages: state.chat.unreadChatMessages
    }
};

export default connect(mapStateToProps)(NavigationSidebar);
