/**
 * Author: Natalie Kappele-Miller (natalie@mobooka.com)
 * App: LLAMA APP
 * Date: November 1, 2021
 */

import React from 'react';
import moment from 'moment';

import { AchievementViewStatus } from '../../../utils/types';

import './achievement-notification-item.css';

const AchievementNotificationItem = ({ notification, history }) => {
    const { _id, badge_img, badge_title, date_earned, badge_type, view_status, notification_type } = notification;

    const badgeStyle = `badge-background ${badge_type}`;

    let messageStart = '';
    let messageEnd = '';
    if (notification_type === 'single_badge') {
        messageStart = 'You earned the ';
        messageEnd = ' badge';
    } else {
        messageStart = 'You completed all of the ';
        messageEnd = ' badges';
    }

    let timeAgo = null;
    timeAgo = new Date(date_earned).getTime();
    timeAgo = moment(timeAgo).fromNow();

    const navigateToAchievements = () => {
        history.push('/achievements');
    };

    return (
        <li className="AchievementNotificationItem" data-unread={view_status === AchievementViewStatus.UNSEEN} onClick={navigateToAchievements} key={_id}>
            <span className={badgeStyle}>
                <img src={badge_img} alt={`${badge_title}`} />
            </span>
            <span className="message">
                <span className="message-text">{messageStart}</span>
                <span className="badge-title">{badge_title}</span>
                <span>{messageEnd}</span>
                <p className="date">{timeAgo}</p>
            </span>
        </li>
    );
};

export default AchievementNotificationItem;
