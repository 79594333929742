/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../../config'; */
import {sendGraphQL} from '../sendRequest';

const UPDATE_AFFILIATE = 'UPDATE_AFFILIATE';
export const UPDATE_AFFILIATE_PAYMENT_PENDING = 'UPDATE_AFFILIATE_PAYMENT_PENDING';
export const UPDATE_AFFILIATE_PAYMENT_FULFILLED = 'UPDATE_AFFILIATE_PAYMENT_FULFILLED';
export const UPDATE_AFFILIATE_PAYMENT_REJECTED = 'UPDATE_AFFILIATE_PAYMENT_REJECTED';

export const updateAffiliatePayments = (d, advertiser) => {
    
    const payload = {
        query: `mutation updateAffiliate($cognito_id:String, $data:AffiliateInput!) {
            updateAffiliate(cognito_id: $cognito_id,data: $data ) {
                account {
                    paypal {
                        urlCode
                        refresh
                        access_token
                        id_token
                        user
                        logout
                    }
                }   
            }
        }`,
        variables: {
            cognito_id: advertiser,
            data: d
        }
    }

    return {
        type: UPDATE_AFFILIATE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: UPDATE_AFFILIATE,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */

}

