/**
 * Author: Rakesh Pacharne (rakesh.pacharne@harbingergroup.com)
 * App: LLAMA APP
 * Date: June 02, 2020
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { createAuthToken } from '../../store/actions/payments/payment-authToken';
import { updateAffiliatePayments } from '../../store/actions/payments/payment-update';
import InitialPreloader from './../initial-preloader/initial-preloader';
import { PAYPAL_IDENTITY_URL } from '../../config';

import axios from 'axios';
const qs = require('querystring');


export class PaymentPayPal extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        if (sessionStorage.getItem('username')) {
            this.paypalAuth();
        }
    }

    paypalAuth() {
        let queryString = qs.parse(this.props.location.search);
        let paypalPayload;
        if (queryString.code || queryString['?code']) {

            let code = "";
            if (queryString.code) {
                code = queryString.code
            } else {
                code = queryString['?code']
            }


            this.props.createAuthToken(code)
                .then((response) => {
                    paypalPayload = response;
                    return this.getAmbassadorPayPalEmail(response.value.tokeninfo.access_token);
                }).then((user) => {
                    if (user && paypalPayload.action.type === "PAYPAL_TOKEN_FULFILLED") {
                        let payload = {
                            account: {
                                paypal: {
                                    urlCode: code,
                                    refresh: paypalPayload.value.tokeninfo.refresh_token,
                                    access_token: paypalPayload.value.tokeninfo.access_token,
                                    id_token: paypalPayload.value.tokeninfo.id_token,
                                    user: user,
                                    logout: paypalPayload.value.logout
                                }
                            }
                        };

                        this.props.updateAffiliatePayments(payload, sessionStorage.getItem('username')).then((res) => {
                            if (res.action.type === "UPDATE_AFFILIATE_FULFILLED") {
                                window.close();
                            }
                        })
                    }
                });
        } else {
            console.log("No query params");
        }
    }

    getAmbassadorPayPalEmail = async (access_token) => {
        return await axios({
            url: PAYPAL_IDENTITY_URL,
            headers: { 'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' }
        }).then((resp) => {
            return resp.data.email ? resp.data.email : null;
        }).catch((error) => {
            console.log('getAmbassadorPayPalEmail :: Error: ', error);
            return null;
        })
    }

    render() {
        return <InitialPreloader />;
    }

}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAuthToken: (code) => dispatch(createAuthToken(code)),
        updateAffiliatePayments: (payload, affiliate) => dispatch(updateAffiliatePayments(payload, affiliate))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentPayPal));