import { GET_APP_DATA_FULFILLED } from '../actions/action_getAppData';
import { CHANGE_HEADER_TITLE } from '../actions/header';
import { SET_OPEN_SOCKET } from '../actions/websocket';

const defaultState = {
    headerTitle: '',
    categories: [],
    countries: [],
    openSocket: null
};

const appReducer = (state = defaultState, action) => {
    const { type } = action;

    switch (type) {
        case GET_APP_DATA_FULFILLED: {
            const { payload } = action;
            const { data: { app } } = payload;

            if (!app || !Array.isArray(app.countries) || !Array.isArray(app.categories)) {
                return state;
            }

            return { ...state, ...app };
        }

        case CHANGE_HEADER_TITLE: {
            if (typeof action.payload !== 'string') {
                return state;
            }
            return { ...state, headerTitle: action.payload };
        }

        case SET_OPEN_SOCKET: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                openSocket: action.payload
            };
        }

        default: {
            return state;
        }
    }
};

export default appReducer;
