import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateCustomization } from '../../../store/actions/action_updateSmartlinkCustomization';

import { themes, proThemes } from '../../../utils/smart-link-themes';

import SmartLinkCustomTheme from './smart-link-custom-theme';
import SmartlinksThemesSkeleton from './smart-link-themes-skeleton';

import './smart-link-themes.css';

const themeNames = Object.keys(themes);

const SmartLinkThemes = (props) => {
    const { smartlinks: smartlinkProps, affiliate, dispatchUpdateCustomization, setToastVerbiage, attemptUpgradeModal, fromOnboarding } = props;

    const [customThemeStarted, setCustomThemeStarted] = useState(false);
    const [currentTheme, setCurrentTheme] = useState('default');
    const [savingTheme, setSavingTheme] = useState(false);

    useEffect(() => {
        setCurrentTheme(smartlinkProps.customizations.theme);
    }, [smartlinkProps.loaded]);

    const changeTheme = (theme) => {
        if (!savingTheme && theme !== 'custom' && theme !== smartlinkProps.customizations.theme) {
            setCurrentTheme(theme);
            setCustomThemeStarted(false);
            setSavingTheme(true);
            if (theme !== smartlinkProps.customizations.theme) {
                const themeData = {
                    ...smartlinkProps.customizations,
                    ...themes[theme],
                    theme
                };

                return dispatchUpdateCustomization(affiliate.cognito_id, themeData)
                    .then(() => {
                        setSavingTheme(false);
                        setToastVerbiage('Saved');
                    });
            }
        } else if (!savingTheme && theme === 'custom') {
            setCurrentTheme('custom');
            const customSection = document.getElementsByClassName('smartlinks--custom-theme')[0];
            window.scrollTo(0, customSection.offsetTop - 10);
        }

        return null;
    };

    if (!smartlinkProps.loaded) {
        return <SmartlinksThemesSkeleton />;
    }

    return (
        <>
            <fieldset data-saving={savingTheme}>
                <legend>Choose a theme</legend>
                <ul id="smartlink-themes-list">
                    {themeNames.map((theme) => {
                        if (smartlinkProps.settings.subscription.plan !== 'plan_pro' && proThemes.indexOf(theme) !== -1) {
                            return null;
                        }
                        return (
                            <li
                                key={theme}
                                className={theme}
                                data-selected={currentTheme === theme && !customThemeStarted}
                            >
                                <button className="theme-wrapper" disabled={savingTheme} type="button" onClick={() => { return changeTheme(theme); }}>
                                    <span className="accessibility">{theme.replace('theme-', '').replace(/-/g, ' ')}</span>
                                    <span className="avatar" />
                                    <span className="headline" />
                                    <span className="link">
                                        <span className="link-text" />
                                    </span>
                                    <span className="link">
                                        <span className="link-text" />
                                        <span className="link-text" />
                                    </span>
                                    <span className="link">
                                        <span className="link-text" />
                                    </span>
                                </button>
                            </li>
                        );
                    })}
                    {smartlinkProps.settings.subscription.plan === 'plan_pro'
                        && (
                            <li className="custom" data-selected={currentTheme === 'custom' || customThemeStarted}>
                                <button type="button" onClick={() => { return changeTheme('custom'); }}>
                                    <span className="theme-wrapper">
                                        <span>Custom</span>
                                    </span>
                                </button>
                            </li>
                        )
                    }
                </ul>
            </fieldset>
            {!fromOnboarding
                && (
                    <SmartLinkCustomTheme
                        setToastVerbiage={setToastVerbiage}
                        setCurrentTheme={setCurrentTheme}
                        savingPreset={savingTheme}
                        attemptUpgradeModal={attemptUpgradeModal}
                    />
                )
            }
        </>
    );
};

SmartLinkThemes.propTypes = {
    
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchtoProps = (dispatch) => {
    return {
        dispatchUpdateCustomization: (id, data) => { return dispatch(updateCustomization(id, data)); }
    }
};

export default connect(mapStateToProps, mapDispatchtoProps)(SmartLinkThemes);
