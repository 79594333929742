import React from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';

import LlamaTabs from '../llama/tabs';

import SettingsGeneral from '../settings-general/settings-general';
import SettingsBilling from '../settings-billing/settings-billing';
import PixelSettings from '../../containers/pixel-settings';
import SocialNetworks from '../../containers/socialNetworks';
import NotificationSettings from '../../containers/notifications-settings';
import './settings-navigation.css';

export const settingsLinks = [
    {
        key: 'general',
        name: 'General',
        link: '/general',
        otherLinks: ['']
    },
    {
        key: 'payment',
        name: 'Commission',
        link: '/payment'
    },
    {
        key: 'social',
        name: 'Social',
        link: '/social'
    },
    {
        key: 'pixels',
        name: 'Pixels',
        link: '/pixels'
    },
    {
        key: 'notifications',
        name: 'Notifications',
        link: '/notifications'
    }
];

const SettingsNavigation = (props) => {
    let tabs = JSON.parse(JSON.stringify(settingsLinks));
    if (props.affiliate.account_type === 'smartlinks') {
        tabs = tabs.filter((tabInfo) => {
            return ['general', 'social'].includes(tabInfo.key);
        });
    }

    return (
        <div className="settings-navigation">
            <LlamaTabs tabs={tabs} location={props.location} linkPrefix="/settings" />
            <Switch>
                <Route path="/settings/social" render={(renderProps) => { return <SocialNetworks {...renderProps} {...props} />; }} />
                <Route path="/settings/pixels" render={(renderProps) => { return <PixelSettings {...renderProps} {...props} />; }} />
                <Route path="/settings/payment/auth" render={(renderProps) => { return <SettingsBilling {...renderProps} {...props} />; }} />
                <Route path="/settings/payment" render={(renderProps) => { return <SettingsBilling {...renderProps} {...props} />; }} />
                <Route path="/settings/notifications" render={(renderProps) => { return <NotificationSettings {...renderProps} {...props} />; }} />
                <Route path="/settings" render={(renderProps) => { return <SettingsGeneral {...renderProps} {...props} />; }} />
            </Switch>
        </div>
    );
};

export default SettingsNavigation;
