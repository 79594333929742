import React from 'react';
import { Heading, TextStyle } from '@shopify/polaris';

import TransactionReports from '../TransactionReports/transaction-report';
import useTransactionReport from '../../hooks/use-transaction-report';

import moment from 'moment';

import './offer-reports.css';

/**
 * @enum ReportColumns
 * Allowed columns within TransactionReports component.
 */
export const ReportColumns = {
    ORDER_NUMBER: 'ORDER_NUMBER',
    DATE: 'DATE',
    CUSTOMER: 'CUSTOMER',
    COMMISSION: 'COMMISSION',
    STATUS: 'STATUS',
    SUBTOTAL: 'SUBTOTAL',
    LOOK_FORWARD: 'LOOK_FORWARD',
    COUPON: 'COUPON'
}

const OfferReports = (props) => {
    const { offer, history, affiliate } = props;
    const { discounts } = offer;

    const now = moment();
    const startDate = now.clone().subtract(1, 'year');

    let reportFilters;
    if (offer && offer.offer_id) {
        reportFilters = {
            startDate: startDate.toISOString(),
            endDate: now.toISOString(),
            offer_id: offer.offer_id,
            affiliate_id: affiliate.tracking_id
        }
    }

    const {
        pageInfo,
        onNext,
        onPrevious,
        orders,
        isLoading,
        errorMessage,
    } = useTransactionReport(offer.advertiser_id, reportFilters);

    if (!offer || !offer.offer_id) {
        return null;
    }


    /**
     * @enum ReportColumns
     * Allowed columns within TransactionReports component.
     */
    const reportColumns = [
        ReportColumns.ORDER_NUMBER,
        ReportColumns.DATE,
        ReportColumns.CUSTOMER,
        ReportColumns.COMMISSION,
        ReportColumns.STATUS,
        ReportColumns.SUBTOTAL,
        ReportColumns.LOOK_FORWARD,
        ReportColumns.COUPON
    ]

    return (
        <div className="OfferReports__Wrapper">
            <div className="OfferCoupons__Heading">
                <Heading>OFFER REPORTS</Heading>
                <TextStyle variation="subdued">
                    <span className="subtitle">A report of your recent orders on this offer.</span>
                </TextStyle>
            </div>
            <TransactionReports
                orders={orders}
                isLoading={isLoading}
                errorMessage={errorMessage}
                history={history}
                pageInfo={pageInfo}
                onNext={onNext}
                onPrevious={onPrevious}
                columns={reportColumns}
                discounts={discounts}
            />
        </div>
    )
}

export default OfferReports;
