import React from 'react';
import PropTypes from 'prop-types';
import { MerchantInviteCard } from 'llama-library/components';
import { Auth } from 'aws-amplify';

import { hexToRgb, luminance } from '../utils/check-color-contrast';

import Meta from './layout/Meta';

import llamaHead from '../assets/leo-branded-signup.svg';
import './style/branded-signup-wrapper.css';

const BrandedSignupWrapper = (props) => {
    const authenticationWidget = props.children;
    const { advertiserInfo } = props;
    //debugger
    // source: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
    const isLightColor = (color) => {
        const rgb = hexToRgb(color);
        const l = luminance(rgb);
        return l > Math.sqrt(1.05 * 0.05) - 0.05;
    };

    const brandColor = (advertiserInfo && advertiserInfo.branded_sign_up && advertiserInfo.branded_sign_up.brand_color) ? advertiserInfo.branded_sign_up.brand_color : '#421875';
    // determine text color white or black based on contrast
    const headingColor = (isLightColor(brandColor)) ? '#000' : '#fff';

    const scrollToForm = () => {
        window.scrollTo(0, document.querySelector('.form').offsetTop);
    };

    return (
        <div id="branded-signup" data-merchant={!!advertiserInfo}>
            <Meta { ...props.meta } />
            <header>
                <p className="logo">Llama.app</p>
                <h1 style={{ background: brandColor, color: headingColor }}>{advertiserInfo ? advertiserInfo.name : 'Llama'} Ambassador Sign Up</h1>
            </header>

            <div className="program">
                {advertiserInfo && <MerchantInviteCard data={advertiserInfo} /> }
                <div className="form">
                    <h3>Become a{advertiserInfo ? 'n' : ' Llama'} Ambassador</h3>
                    {advertiserInfo
                        ? <p>Join {advertiserInfo.name}&rsquo;s ambassador program to earn recurring commissions from your referrals.</p>
                        : <p>Find and join ambassador programs from relevant brands and earn recurring commissions when your referrals make purchases.</p>
                    }
                    {authenticationWidget}
                </div>
            </div>

            <p className="existing-user">Already have a Llama account? <a href="https://ambassadors.llama.app/">Sign In</a></p>

            <div className="about-content">
                <h4>What&rsquo;s Llama?</h4>
                <p>In case this is the first time you&rsquo;ve heard of us, we are a free platform that helps connect influencers like you to the most relevant brands. Whatever niche you gravitate toward, our large, diverse network of brands and advanced search filters means you can easily find the brands that best match your interests.</p>
                <p>And unlike most ambassador programs, Llama utilizes a Longterm Value (LTV) system, which means you&rsquo;ll earn a commision from <strong>every purchase*</strong> your friends and followers make, not just their first one.</p>
                <ul className="about-features">
                    <li>Promote brands with a referral link or coupon code</li>
                    <li>Earn commission from <strong>every</strong> purchase the customer makes</li>
                    <li>Easily find offers that are most relevant to you</li>
                </ul>
                <p className="sign-up"><button type="button" onClick={scrollToForm}>Sign Up</button></p>
                <p className="disclaimer">*Qualifying purchases must occur within the individual brand&rsquo;s Look Forward and Look Back periods.</p>
            </div>

            <footer>
                <div className="wave-container">
                    <svg viewBox="0 0 120 28" fill={brandColor}>
                        <defs>
                            <path id="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
                        </defs>
                        <use id="wave3" className="wave" xlinkHref="#wave" x="0" y="-2" />
                        <use id="wave2" className="wave" xlinkHref="#wave" x="0" y="0" />
                        <use id="wave1" className="wave" xlinkHref="#wave" x="0" y="1" />
                    </svg>
                </div>

                <p className="leo-mascot"><img src={llamaHead} alt="Leo the Llama, our mascot" /></p>
                <nav>
                    <ul>
                        <li><a href="https://llama.app/llama_tos.html" target="_blank" rel="nofollow noopener noreferrer">Llama Terms of Service</a></li>
                        <li><a href="https://llama.app/llama_privacy.html" target="_blank" rel="nofollow noopener noreferrer">Llama Privacy Policy</a></li>
                        <li><a href="https://llama.app/llama_dmca.html" target="_blank" rel="nofollow noopener noreferrer">DMCA</a></li>
                    </ul>
                </nav>
            </footer>
        </div>
    );
};

BrandedSignupWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    advertiserInfo: PropTypes.shape({
        branded_sign_up: PropTypes.shape({
            brand_color: PropTypes.string,
            offer: PropTypes.shape({
                offer_id: PropTypes.string
            })
        }),
        name: PropTypes.string,
        advertiser_id: PropTypes.string
    }),
    meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string
    })
};

BrandedSignupWrapper.defaultProps = {
    advertiserInfo: null,
    meta: null
};

export default BrandedSignupWrapper;
