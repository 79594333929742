import React from 'react';
import './initial-preloader.css'

const InitialPreloader = (props) => {
    return (
        <div id="llama-preloader">
            <h1>Llama.app</h1>
            <p className="initial-progress-bar">Loading...</p>
        </div>
    )
}

export default InitialPreloader;