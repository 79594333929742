import React, { useState } from 'react';

import './slider.css';

const LlamaSlider = ({ range, onMinChange, onMaxChange, customMinValue, customMaxValue }) => {

    if (!range) return null;

    customMinValue = customMinValue ? customMinValue : 0;
    customMaxValue = customMaxValue ? customMaxValue : range.length - 1;

    const minTick = customMinValue;
    const maxTick = customMaxValue;

    /* const [minTick, setMinTick] = useState(customMinValue);
    const [maxTick, setMaxTick] = useState(customMaxValue); */

    const onMinSliderChange = (event) => {
        const { value } = event.target;
        console.log("Min Event Value", value);
        if (parseInt(value) >= parseInt(maxTick)){
            console.log('min return from null', parseInt(maxTick));
            return null;
        } 
        //setMinTick(value);

        if (typeof onMinChange !== 'function') {
            console.warn("onMinChange is not a function.");
            return null;
        }
        onMinChange(range[value].value);
    }

    const onMaxSliderChange = (event) => {
        const { value } = event.target;
        console.log("Max Event Value", value);
        if (parseInt(value) <= parseInt(minTick)) {
            console.log('min return from null', parseInt(minTick));
            return null;
        }
        //setMaxTick(value);

        if (typeof onMaxChange !== 'function') {
            console.warn("onMaxChange is not a function.");
            return null;
        }
        onMaxChange(range[value].value);
    }

    console.log("minTick>>", minTick, "maxTick>>", maxTick)
    const minPercent = parseInt((100 / (range.length - 1)) * minTick);
    const maxPercent = parseInt((100 / (range.length - 1)) * maxTick);

    const minValue = range[minTick].name;
    const maxValue = range[maxTick].name;

    console.log("minValue>>", minValue, "maxValue>>", maxValue);

    return (
        <React.Fragment>
            <div className="llama-slider">
                <div className="llama-slider__fill-wrapper">
                    <div className="llama-slider__background" />
                    <div 
                        className="llama-slider__fill" 
                        style={{ left: `${minPercent}%`, width: `${maxPercent - minPercent}%` }}
                    />
                </div>
                <input 
                    type="range" 
                    className="llama-slider__slider" 
                    step="1" 
                    min="0" 
                    max={range.length - 1} 
                    value={minTick} 
                    onChange={onMinSliderChange} 
                    list="sliderrange"
                />
                <input 
                    type="range" 
                    className="llama-slider__slider" 
                    step="1" 
                    min="0" 
                    max={range.length - 1} 
                    value={maxTick} 
                    onChange={onMaxSliderChange} 
                    list="sliderrange"
                />
                <datalist id="sliderrange">
                    {range.map((item, index) => <option key={item.value || index}>{index}</option>)}
                </datalist>
            </div>
            <div className="llama-slider__value-display">{minValue} - {maxValue}</div>
        </React.Fragment>
    )

}

export default LlamaSlider;