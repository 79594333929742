import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pixel from '../components/pixels';
import { getGlobalPixel, saveGlobalPixel } from '../store/actions/action_getPixel';
import { Frame, Toast } from '@shopify/polaris';

import './styles/pixel-settings.css';

class PixelSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            affiliate: {},
            savedPixel: '',
            pixel: '',
            changesMade: false,
            loading: false,
            showToast: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.parseGlobalPixel = this.parseGlobalPixel.bind(this);
    }

    parseGlobalPixel(globalPixel) {
        console.log(globalPixel);
        if (!globalPixel) {
            this.setState({ affiliate: this.props.affiliate });
            return;
        }

        const pixel = globalPixel.html;

        this.setState({
            affiliate: this.props.affiliate,
            pixel,
            savedPixel: pixel,
            changesMade: false,
            loading: false
        });
    }

    componentDidMount() {
        if (this.props.affiliate.cognito_id) {
            this.props.getGlobalPixel(this.props.affiliate.cognito_id)
                .then(data => this.parseGlobalPixel(data.value));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.affiliate.cognito_id
            && (this.props.affiliate.cognito_id !== this.state.affiliate.cognito_id)) {
            this.props.getGlobalPixel(this.props.affiliate.cognito_id)
                .then(data => this.parseGlobalPixel(data.value))
        }
    }

    handleChange(value) {
        this.setState({ pixel: value, changesMade: true });
    }

    handleSubmit() {
        this.setState({ loading: true });
        this.props.saveGlobalPixel(this.props.affiliate.tracking_id, this.state.pixel)
            .then(data => this.parseGlobalPixel(data.value));
        this.setState({ showToast: true });
    }

    toggleToast = () => {
        this.setState(({ showToast }) => ({ showToast: !showToast }));
    };

    render() {
        const { showToast } = this.state;
        const toastMarkup = showToast ? (
            <Toast className="toasty" content="Pixel Saved" onDismiss={this.toggleToast} />) : null;

        return (
            <div className="pixel-settings__wrapper">
                <Frame>
                    <Pixel
                        {...this.props}
                        {...this.state}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                    />
                    {toastMarkup}
                </Frame>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getGlobalPixel: (id) => dispatch(getGlobalPixel(id)),
        saveGlobalPixel: (tracking_id, html) => dispatch(saveGlobalPixel(tracking_id, html))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PixelSettings);
