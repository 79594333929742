/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../../config'; */
import {sendGraphQL} from '../sendRequest';

const YOUTUBE = 'CREATE_YOUTUBE_USER';

export const createYoutubeAffData = (code, id) => {
    const query = `mutation createYouTubeUser($code: String, $cognito_id: String){
        createYouTubeUser(code: $code, cognito_id: $cognito_id){
            youtube_id
            cognito_id
            channels{
                id
                name
            }
        }
    }`;

    const variables = {
        code: code,
        cognito_id: id,
    };

    const payload = {
        query,
        variables
    };

    return {
        type: YOUTUBE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    };

    return {
        type: YOUTUBE,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    } */
}