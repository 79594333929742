import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import { TextField, Modal, Banner } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { applyToAdvertiser } from '../../store/actions/action_applyToAdvertiser';
import { updateAffiliate } from '../../store/actions/action_updateAffiliate';

import { ApplicationStatus } from '../../utils/types';

import './apply-to-offer-button.css';

const ApplicationButtonStatuses = {
    pending: {
        key: 'pending',
        name: 'Pending',
    },
    approved: {
        key: 'approved',
        name: 'Approved',
    },
}

/**
 * Renders a button for applying to an offer and handles the process of saving the application. If the affiliate doesn't have a name, it will ask for it first via modal window
 * Currently used on Discover, Offer Detail, and Dashboard pages
 * 
 * @param {Object} affiliate Pulled from redux, The affiliate's information; name and id are used
 * @param {Object} offer The offer's information, passed through component props; id and merchant's name and id are used
 * @param {Boolean} fromOfferCard Whether the button displays as part of an offer card or standalone; controls verbiage and background differences
 * @param {Function} setApplicationStatus Optional, used as a callback function that passes the applicationStatus as an argument after the application is saved; good for the offer card version
 * @param {String} applicationStatus Optional, Controls verbiage and background of the button; good for the offer card version
 * @param {Function} setToastVerbiage Sets the toast verbiage for the parent component
 * @param {Function} updateAffiliate Dispatched via redux, Saves the affiliate's name
 * @param {Function} applyToAdvertiser Dispatched via redux, Saves the affiliate's application
 * @param {Function} onSuccess Optional, callback for after the application is successfully submitted
 */
export const ApplyToOfferButton = ({affiliate, offer, fromOfferCard, setApplicationStatus, applicationStatus, setToastVerbiage, updateAffiliate, applyToAdvertiser, onSuccess}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [nameModalOpen, setNameModalOpen] = useState(false)
    const [hasName, setHasName] = useState(false)
    const [name, setName] = useState({
        first: '',
        last: '',
    })
    const [errors, setErrors] = useState({
        first_name: null,
        last_name: null
    })
    const [generalError, setGeneralError] = useState(null)
    const [savingName, setSavingName] = useState(false)

    //set affiliate data if it exists. it runs initially and will also run after they submit their name to prevent the modal from opening more times than it needs to
    useEffect(() => {
        if(affiliate.account && affiliate.account.personal){
            let firstName = ''
            let lastName = ''
            if(affiliate.account.personal.first_name && affiliate.account.personal.first_name !== ''){
                firstName = affiliate.account.personal.first_name
            }
            if(affiliate.account.personal.last_name && affiliate.account.personal.last_name !== ''){
                lastName = affiliate.account.personal.last_name
            }
            setName({
                first: firstName,
                last: lastName,
            })
            if(firstName !== '' && lastName !== ''){
                setHasName(true)
            }
        }
    }, [affiliate])

    const sendApplication = () => {
        const offer_id = offer.offer_id;
        const advertiser_id = offer.advertiser_id;
        const affiliate_id = affiliate.cognito_id;
        
        setIsLoading(true);
        applyToAdvertiser({ offer_id, advertiser_id, affiliate_id })
            .then((result) => {
                setIsLoading(false);
                if (
                    result
                    && result.value
                    && result.value.data
                    && result.value.data.applyToAdvertiser
                    && result.value.data.applyToAdvertiser.affiliate_status === ApplicationStatus.APPROVED
                ) {
                    if(setApplicationStatus) setApplicationStatus(ApplicationStatus.PENDING);
                    setToastVerbiage('Your application has been sent.')
                    if (onSuccess) {
                        onSuccess();
                    }
                }else{
                    setToastVerbiage('Oops, something went wrong when sending your application. Please try again later.')
                    setNameModalOpen(false);
                    setSavingName(false);
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setToastVerbiage('Oops, something went wrong when sending your application. Please try again later.')
                setNameModalOpen(false);
                setSavingName(false);
            })
    }
    
    const saveName = () => {
        //set error if a name field is empty, trim() is so we don't allow just spaces to get through
        if(!name.first.trim() || !name.last.trim()){
            setErrors({
                first_name: !name.first.trim() ? 'First name is required' : null,
                last_name: !name.last.trim() ? 'Last name is required' : null
            })
        }else{
            setSavingName(true)

            let payload = {}
            if(affiliate.account !== null) {
                payload.account = {
                    personal: {
                        ...affiliate.account.personal,
                        first_name: name.first,
                        last_name: name.last
                    }
                }
            } else {
                payload.account = {
                    personal: {
                        first_name: name.first,
                        last_name: name.last
                    }
                }
            }
    
            payload.name = name.first + ' ' + name.last;
            if(!affiliate.sign_up_flow || parseInt(affiliate.sign_up_flow) < 2){
                payload.sign_up_flow = '2';
            }
            
            updateAffiliate(payload, affiliate.cognito_id)
                .then(() => {
                    sendApplication()
                })
                .catch(() => {
                    setGeneralError('Oops, something went wrong when saving your name. Please try again later.')
                    setSavingName(false);
                });
        }
    }

    /**
     * Runs if the user submits the form using the enter button instead of clicking the modal's primary button, both perform the same action, this one just needs to preventDefault first
     */
    const saveNameEnter = (e) => {
        e.preventDefault();
        saveName()
    }

    /**
     * Manage form input via states
     */
    const handleNameChange = (type, value) => {
        //clear error message
        if(value.trim() !== ''){
            setErrors({
                ...errors,
                [`${type}_name`]: null
            })
        }

        setName({
            ...name,
            [type]: value
        })
    }

    const handleApply = () => {
        if (isLoading) return;

        if(!hasName){
            setNameModalOpen(true);
        }else{
            sendApplication();
        }
    }

    return (
        <>
            <Modal
                title="Hang on, I need your name first"
                open={nameModalOpen}
                onClose={() => setNameModalOpen(false)}
                primaryAction={{
                    content: 'Save Name and Apply',
                    onAction: saveName,
                    loading: savingName
                }}
            >
                <Modal.Section>
                    <p>Hmm, it seems we haven&rsquo;t been properly introduced, yet. <strong>Let me know your name</strong> so I can let {offer.advertiser.company} know who&rsquo;s applying to their offer.</p>
                    <form onSubmit={saveNameEnter}>
                        {generalError && !savingName &&
                            <Banner status="critical" title={generalError} />
                        }
                        <div className="name-required-modal-fields">
                            <TextField label="First Name" placeholder="John" value={name.first} onChange={(value) => handleNameChange('first', value)} error={errors.first_name} />
                            <TextField label="Last Name" placeholder="Doe" value={name.last} onChange={(value) => handleNameChange('last', value)} error={errors.last_name} />
                        </div>
                        <button type="submit" style={{visibility: 'hidden'}}>Submit</button>
                    </form>
                </Modal.Section>
            </Modal>
            
            {fromOfferCard ?
                <LlamaButton
                    onClick={handleApply}
                    loading={isLoading}
                >
                    {applicationStatus && ApplicationButtonStatuses[applicationStatus]
                        ? ApplicationButtonStatuses[applicationStatus].name
                        : 'Apply to Offer'
                    }
                </LlamaButton>
                : <LlamaButton onClick={handleApply} loading={isLoading}>{offer.offer_type === 'product_giveaway' ? 'Apply for a Coupon Code' : 'Apply to Promote Offer'}</LlamaButton>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        affiliate: state.affiliate
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        applyToAdvertiser: (data) => dispatch(applyToAdvertiser(data)),
        updateAffiliate: (data, id) => dispatch(updateAffiliate(data, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyToOfferButton);