import React from 'react';
import { Page, TextField, TextStyle, DataTable, Button } from '@shopify/polaris';

import './pixels.css';

const Pixels = ({ pixel, handleChange, handleSubmit }) => {

    const paramsTable = [
        ['Ambassador ID', '{{affId}}', '12345'],
        ['Customer ID', '{{customerId}}', '581290655827'],
        ['Offer ID', '{{offerId}}', '123'],
        ['Order ID', '{{orderId}}', '534590652499'],
        ['Shop', '{{shop}}', 'example.myshopify.com'],
        ['Subtotal Price', '{{subtotalPrice}}', '12.00'],
        ['External tracking parameters', '{{qs}}', '&s1=aaa&s2=bbb&r=ccc&aff_sub1=ddd&aff_sub2=eee']
    ]

    return (
        <div className="pixels">
            <Page
                separator={true}
            >
                    <p className="pixels__description">HTML that will be inserted into the confirmation page of all shopify stores that were purchased using your affiliate link.</p>  
                    <p className="pixels__description">Examples: (Facebook Pixels, Google Tags, etc.)</p>
                    <div className="pixels__textfield-wrapper">
                        <TextField 
                            label="HTML"
                            multiline
                            value={pixel}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pixels__heading">
                        <TextStyle variation="strong">Including Parameters</TextStyle>
                    </div>
                    <TextStyle>To dynamically insert parameters into your HTML code, wthe parameter is double brackets.  For Example: <span className="pixels__mono-type">{"{{orderId}}"}</span></TextStyle>
                    <div className="pixels__heading">
                        <TextStyle variation="strong">Available Parameters:</TextStyle>
                    </div>
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            "Name",
                            "Code",
                            "Example Value"
                        ]}
                        rows={paramsTable}
                    />
                <div className="footer">
                    <Button id="pixels-submit" primary onClick={handleSubmit}>Save</Button>
                </div>
            </Page>
        </div>
    )

}

export default Pixels;