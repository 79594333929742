import {sendGraphQL} from '../sendRequest';

export const applyPromoCode = (cognitoId, promoCode) => {
    const query = `mutation updateSmartLinkPromo($cognito_id: String, $promo_code: String!) {
        updateSmartLinkPromo(cognito_id: $cognito_id, promo_code: $promo_code ) {
        
         success
        }
      }`;

    const variables = {
        cognito_id: cognitoId,
        promo_code: promoCode
    }

    const payload = {
        query,
        variables
    }

    return {
        type: 'APPLY_PROMO_CODE',
        payload: sendGraphQL(payload)
            .then(response => response.data)
            .catch(err => console.error(err))
    }
}
