/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React, { useState } from 'react';
import { TextField, Select, ChoiceList, Icon, Frame, Toast } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons'
import { LlamaButton, LlamaPagination, EmptyState } from 'llama-library/components';

import { PER_PAGE } from '../../config';

import usePagesOffers from '../../hooks/use-offers-pages';
import { useToggle } from '../../hooks/use-toggle';

import LlamaSlider from '../llama/slider';
import LlamaSpinner from '../llama/centered-spinner';
import OfferPreviewCard from './offer-preview-card';

import filterIcon from '../../assets/icon_filter.svg';
import sortIcon from '../../assets/icon_sort.svg';

import './discover-offers.css';

const sortBy = {
    newest: {
        key: 'newest',
        apiOptions: {
            "sortBy": {
                "field": "create_date_utc",
                "order": "DESC"
            }
        },
        sortOffersBy: (a, b) => {
            if (a.create_date_utc > b.create_date_utc) return -1;
            if (a.create_date_utc < b.create_date_utc) return 1;
            return 0;
        }
    },
    oldest: {
        key: 'oldest',
        apiOptions: {
            "sortBy": {
                "field": "create_date_utc",
                "order": "ASC"
            }
        },
        sortOffersBy: (a, b) => {
            if (a.create_date_utc > b.create_date_utc) return 1;
            if (a.create_date_utc < b.create_date_utc) return -1;
            return 0;
        }
    },
    ltv: {
        key: 'ltv',
        sortOffersBy: (a, b) => {
            if (a.ltv > b.ltv) return -1;
            if (a.ltv < b.ltv) return 1;
            return 0;
        }
    },
    epc: {
        key: 'epc',
        sortOffersBy: (a, b) => {
            const aEPC = a.tracking && a.tracking.Stat && a.tracking.Stat.epc
                ? a.tracking.Stat.epc
                : 0;

            const bEPC = b.tracking && b.tracking.Stat && b.tracking.Stat.epc
                ? b.tracking.Stat.epc
                : 0;

            if (aEPC > bEPC) return -1;
            if (aEPC < bEPC) return 1;
            return 0;
        }
    }
};

const DiscoverOffersSidebar = ({
    searchTerm,
    setSearchTerm,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedSort,
    setSelectedSort,
    commissionRange,
    setCommissionRange,
    selectedCommissionType,
    setCommissionType,
    applyFiltersBtnEnabled,
    enableApplyFiltersBtn,
    resetFiltersBtnEnabled,
    //searchButton,
    setSearchButton,
    //setSkip,
    offerType,
    setOfferType,
    ...props
}) => {

    const [openSort, toggleSort] = useToggle(false);
    const [openFilter, toggleFilter] = useToggle(false);
    const [searchMode, setSearchMode] = useState(false);
    const [showCommissionFilters, setShowCommissionFilters] = useState(true);
    // const [isMobile, toggleMobile] = useToggle(false);
    // console.log("OPEN SORT", openSort);

    const handleSearchTermChange = (term) => {
        console.log('handleSearchTermChange:>', term, searchTerm);
        setSearchTerm(term);
        //if the user searched for something, then cleared the search, reset the offer results
        if (searchMode && term.length === 0 && searchTerm.length > 0) {
            handleSearchTerm('');
        }
    };

    const handleSearchTerm = (term) => {
        console.log('handleSearchTerm:>', term);
        if (term.length > 0) {
            setSearchMode(true);
        } else {
            setSearchMode(false);
        }
        setSearchButton(true);
    };

    // if the user types enter at the end of their query, trigger the search to happen
    const handleSearchTermEnter = (e, term) => {
        e.preventDefault();
        handleSearchTerm(term);
    };

    const handleCategorySelection = (selected) => {
        setSelectedCategories(selected);
    };

    const handleCommissionSelection = (selected) => {
        setCommissionType(selected);
    };

    const handleApplyFilter = (e) => {
        console.log('handleApplyFilter ::', applyFiltersBtnEnabled, e.target.value);
        enableApplyFiltersBtn(!applyFiltersBtnEnabled);
        // hide filter options on mobile
        toggleFilter(false);
    };

    const handleResetFilter = (e) => {
        setSelectedCategories([]);
        setCommissionRange([0, 100]);
        setCommissionType(['PERCENT_REVENUE']);
        //hide filter options on mobile
        toggleFilter(false);
    };

    const handleOfferType = (selected) => {
        if (selected.length === 1 && selected[0] === 'product_giveaway') {
            setShowCommissionFilters(false);
        } else {
            setShowCommissionFilters(true);
        }
        setOfferType(selected);
    };

    const categoriesChoices = categories.map(({ name }) => {
        return { label: name, value: name };
    });

    const commissionRangeValues = Array.apply(null, { length: 101 })
        .map(Number.call, Number)
        .map((num) => ({ name: String(num), value: num }));
    // console.log('**********commissionRangeValues>>>>>>', commissionRangeValues);

    const onMinChange = (value) => {
        const newArray = commissionRange.slice();
        newArray[0] = value;
        setCommissionRange(newArray);
    };

    const onMaxChange = (value) => {
        const newArray = commissionRange.slice();
        newArray[1] = value;
        setCommissionRange(newArray);
    };

    const handleSortByInput = (value) => {
        setSelectedSort(value);
    };

    const commissionChoices = [
        { label: 'Order Percentage', value: 'PERCENT_REVENUE' },
        { label: 'Amount Per Item', value: 'FLAT_ITEM' },
        { label: 'Amount Per Order', value: 'FLAT_ORDER' }
    ];

    const offerChoices = [
        { label: 'Commission-Based', value: 'commission' },
        { label: 'Product Giveaway', value: 'product_giveaway' }
    ];

    const sortOptions = [
        { label: 'Newest', value: 'newest' },
        { label: 'Oldest', value: 'oldest' },
        { label: 'LTV', value: 'ltv' },
        { label: 'EPC', value: 'epc' }
    ];

    const renderFilterButtons = () => {
        return (
            <div className={openFilter ? "discover-offers__sidebar-content expanded" : "discover-offers__sidebar-content"}>
                <div className="discover-offers__filter-group">
                    <LlamaButton
                        onClick={handleApplyFilter}
                        background="#341d5b"
                        color="#fcfcfc"
                        disabled={!applyFiltersBtnEnabled}
                    >
                        Apply Filters
                    </LlamaButton>
                    <p className="discover-offers__reset-filters" onClick={handleResetFilter} data-enabled={resetFiltersBtnEnabled}>Reset Filters</p>
                </div>
            </div>
        );
    };

    return (

        <div className="discover-offers__sidebar">
            <div className="discover-offers__search-section">
                <form onSubmit={(e) => handleSearchTermEnter(e, searchTerm)}>
                    <TextField
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        placeholder="Search"
                        data-test='discover-search'
                        connectedRight={
                            <button className="search-button" onClick={handleSearchTerm}><Icon source={SearchMinor} color="inkLighter" accessibilityLabel="Search" /></button>
                        }
                    />
                </form>
            </div>
            <div className="discover-offers__sidebar-section">
                <div className={openSort ? "discover-offers__sidebar-row mobile-tab discover-offers__sidebar--active" : "discover-offers__sidebar-row mobile-tab"} onClick={toggleSort}>
                    <h1 className="discover-offers__header">Sort</h1>
                    <div className="discover-offers__icon-wrapper">
                        <img alt="Sort" src={sortIcon} className="discover-offers__sidebar-icon" />
                    </div>
                </div>

                <div className={openSort ? "discover-offers__sidebar-content expanded" : "discover-offers__sidebar-content"}>
                    <h3 className="discover-offers__section-header">Campaign Sort</h3>
                    <Select
                        label="Sort by"
                        options={sortOptions}
                        labelInline
                        value={selectedSort}
                        onChange={handleSortByInput}
                    />
                </div>
            </div>

            <div className="discover-offers__sidebar-section">
                <div className={openFilter ? "discover-offers__sidebar-row mobile-tab discover-offers__sidebar--active" : "discover-offers__sidebar-row mobile-tab"} onClick={toggleFilter}>
                    <h1 className="discover-offers__header">Filter</h1>
                    <img alt="Filter" src={filterIcon} className="discover-offers__sidebar-icon" />
                </div>
                {renderFilterButtons()}
            </div>

            <div className={openFilter ? "discover-offers__sidebar-content expanded" : "discover-offers__sidebar-content"}>
                <div className="discover-offers__sidebar-section">
                    <h3 className="discover-offers__section-header">Offer Type</h3>
                    <ChoiceList
                        allowMultiple
                        choices={offerChoices}
                        onChange={handleOfferType}
                        selected={offerType}
                    />
                </div>
            </div>

            {showCommissionFilters
                && (
                    <>
                        <div className={openFilter ? 'discover-offers__sidebar-content expanded' : 'discover-offers__sidebar-content'}>
                            <div className="discover-offers__sidebar-section">
                                <h3 className="discover-offers__section-header">Commission Type</h3>
                                <ChoiceList
                                    allowMultiple
                                    choices={commissionChoices}
                                    onChange={handleCommissionSelection}
                                    selected={selectedCommissionType}
                                    data-test="commission-choiceList"
                                />
                            </div>
                        </div>

                        <div className={openFilter ? 'discover-offers__sidebar-content expanded' : 'discover-offers__sidebar-content'}>
                            {selectedCommissionType.includes('PERCENT_REVENUE')
                                && (
                                    <div className="discover-offers__sidebar-section">
                                        <h3 className="discover-offers__section-header">Commission Percentage</h3>
                                        <LlamaSlider
                                            range={commissionRangeValues}
                                            onMinChange={onMinChange}
                                            onMaxChange={onMaxChange}
                                            customMinValue={commissionRange[0]}
                                            customMaxValue={commissionRange[1]}
                                        />
                                    </div>
                                )}
                        </div>
                    </>
                )}

            <div className={openFilter ? "discover-offers__sidebar-content expanded" : "discover-offers__sidebar-content"}>
                <div className="discover-offers__sidebar-section">
                    <h3 className="discover-offers__section-header">Categories</h3>
                    <ChoiceList
                        allowMultiple
                        choices={categoriesChoices}
                        selected={selectedCategories}
                        onChange={handleCategorySelection}
                        data-test="discover-offers__category-choice-list"
                    />
                </div>
            </div>

            <div className="discover-offers__sidebar-section">
                {renderFilterButtons()}
            </div>

        </div>
    );

};

const DiscoverOffers = (props) => {

    const [isOfferLoaded, setIsOfferLoaded] = useState(false);
    const [toastVerbiage, setToastVerbiage] = useState(null);

    const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        offers
    } = usePagesOffers(props.initialPage, PER_PAGE.OFFERS, props, setIsOfferLoaded);

    props.setInitialPage(currentPage);

    // Check if offers is null, if so load spinner
    // TODO: Add skeleton page for discover offers
    if (!isOfferLoaded || (offers.length <= 0 && !isOfferLoaded)) {
        // scroll back up to the top of the page whenever something triggers new offers to load in
        window.scrollTo(0, 0)
        return (
            <div className="discover-offer-loading">
                <div className="discover-offer__loading">
                    <LlamaSpinner />
                </div>
            </div>
        );
    }

    // If filtered offers array is zero return empty state
    if (offers.length <= 0 && isOfferLoaded) {
        return <EmptyState message="Sorry, I couldn’t find what you’re looking for" paragraph="Try broadening your search filters to find more offers" />
    }

    return (
        <div className="discover-offers__main">
            <ul className="discover-offers__main-wrapper">
                {offers.map((offer) => {
                    const application = Array.isArray(props.affiliate.applications)
                        ? props.affiliate.applications.find((application) => {
                            if (!application.offer || !application.offer.offer_id) {
                                return false;
                            }
                            return application.offer.offer_id === offer.offer_id;
                        })
                        : null;

                    return (
                        <li key={offer.offer_id}>
                            <OfferPreviewCard
                                offer={offer}
                                application={application}
                                applicationsLoaded={props.applicationsLoaded || Array.isArray(props.affiliate.applications)}
                                history={props.history}
                                setToastVerbiage={setToastVerbiage}
                            />
                        </li>
                    );
                })}
            </ul>
            {maxPages > 1 && (
                <div className="discover-offer__pagination">
                    <LlamaPagination
                        hasNext={hasNext}
                        hasPrevious={hasPrevious}
                        onNext={nextPage}
                        onPrevious={previousPage}
                        currentPage={currentPage - 1}
                        maxPages={maxPages - 1}
                        data-test='discover-pagination'
                    />
                </div>
            )}
            <Frame>
                {toastVerbiage
                    ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)} />
                    : null}
            </Frame>
        </div>
    );
};

export {
    DiscoverOffers,
    DiscoverOffersSidebar
};
