import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './styles/discover-merchants.css';
import { Page, Toast, Frame } from '@shopify/polaris';

import { connect } from 'react-redux';

// Actions
import { changeHeaderTitle } from '../store/actions/header';
import { getApplications } from '../store/actions/action_getApplications';
import { searchAdvertiserOffers } from '../store/actions/action_getOffers';

// Components
import DiscoverMerchants from '../components/DiscoverMerchants/DiscoverMerchants';

// Hooks
import useApplications from '../hooks/use-applications';

const DiscoverMerchantsContainer = (props) => {
    const { affiliate, match, history } = props;
    const advertiserFromParam = match.params.id;

    useEffect(() => {
        props.changeHeaderTitle('Brand Offers');
    });

    const goToDiscover = () => {
        history.push('/discover');
    };

    const [toastVerbiage, setToastVerbiage] = useState(false);
    const [offers, setOffers] = useState(null);
    const [skip, setSkip] = useState(0);
    const [initialPage, setInitialPage] = useState(1);

    // Custom hook that calls getApplications with cognito_id and returns object
    // containing Boolean representing if the API call was successful
    const { applicationsLoaded } = useApplications(affiliate.cognito_id, props.getApplications);

    const toastMarkup = toastVerbiage ? <Toast content={toastVerbiage} onDismiss={() => setToastVerbiage(null)}/> : null;

    return (
        <div className="discover-merchants__wrapper">
            <Frame>
                <Page
                    breadcrumbs={[{
                        content: 'Discover Offers',
                        onAction: goToDiscover
                    }]}
                    fullWidth
                >
                    <DiscoverMerchants
                        {...props}
                        offers={offers}
                        setOffers={setOffers}
                        initialPage={initialPage}
                        setInitialPage={setInitialPage}
                        skip={skip}
                        setSkip={setSkip}
                        advertiser_id={advertiserFromParam}
                        applicationsLoaded={applicationsLoaded}
                    />
                    {toastMarkup}
                </Page>
            </Frame>
        </div>
    );
};

DiscoverMerchantsContainer.propTypes = {
    affiliate: PropTypes.shape({ cognito_id: PropTypes.string.isRequired }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }).isRequired,
    changeHeaderTitle: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return { affiliate: state.affiliate };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getApplications: (id) => dispatch(getApplications(id)),
        searchAdvertiserOffers: (advertiser_id, options) => dispatch(searchAdvertiserOffers(advertiser_id, options)),
        changeHeaderTitle: (title) => dispatch(changeHeaderTitle(title))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverMerchantsContainer);
