import React from 'react';
import { Auth } from 'aws-amplify';
import { LlamaButton, PasswordRequirements, PasswordMatch } from 'llama-library/components';
import {
    AuthPiece,
    FormSection,
    SectionBody,
    InputRow,
    Link
} from 'aws-amplify-react';
import CenteredSpinner from '../components/llama/centered-spinner';
import SocialAuthButtons from './social-auth-buttons';

import './amplify.css';
import countryDialCodes from './lib/country-dial-codes.js';

import llamaHead from '../assets/llama-head.png';
import loginGraphic from '../assets/ambassador-login-graphic.svg';

const logger = {
    debug: (msg) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(msg)
        }
    }
};

class LlamaSignUp extends AuthPiece {
    signUpFields = [
        {
            required: true,
            label: 'Email',
            placeholder: 'Email',
            type: 'text',
            key: 'username',
            autocomplete: 'off',
        }, {
            required: true,
            label: 'Password',
            placeholder: 'Password',
            type: 'password',
            key: 'password',
            autocomplete: 'off',
        }, {
            required: true,
            label: 'Confirm Password',
            placeholder: 'Confirm Password',
            type: 'password',
            key: 'confirm_password',
            autocomplete: 'off',
        }, {
            required: true,
            label: 'Phone Number',
            placeholder: 'Phone Number',
            type: 'tel',
            key: 'phone',
            autocomplete: 'off',
            custom: true,
        },
        {
            required: true,
            label: 'Email',
            placeholder: 'Email',
            type: 'email',
            key: 'email',
            autocomplete: 'off',
            hidden: true,
        }
    ];

    constructor(props) {
        super(props);

        const initalValues = this.signUpFields.reduce((acc, item) => {
            acc[item.key] = '';
            return acc;
        }, {});

        this.state = {
            values: { ...initalValues },
            dataSet: false,
            isLoading: false,
            signInLoading: false,
            showEmailForm: false
        };

        this._validAuthStates = ['signUp'];

        this.header = 'Sign Up';

        this.signUp = this.signUp.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e) {
        if (e.keyCode !== 13) {
            return;
        }

        if (this.props.authState === 'signUp') {
            this.signUp();
        }
    }

    componentDidUpdate() {
        if (this.props.authState === 'signedUp' && !this.state.signInLoading) {
            this.signIn();
        }
    }

    getDefaultDialCode() {
        return this.props.signUpConfig
        && this.props.signUpConfig.defaultCountryCode
        && countryDialCodes.includes(`+${this.props.signUpConfig.defaultCountryCode}`)
            ? `+${this.props.signUpConfig.defaultCountryCode}`
            : '+1'
    }

    needPrefix(key) {
        const field = this.signUpFields.find(e => e.key === key);
        if (key.indexOf('custom:') !== 0) {
            return field.custom;
        } else if (key.indexOf('custom:') === 0 && field.custom === false) {
            console.warn('Custom prefix prepended to key but custom field flag is set to false; retaining manually entered prefix');
        }
        return null;
    }

    validate() {

        // Copied from SignUp Amplify Component.
        const invalids = [];
        let invalidFormats = false;

        const validateKeys = {
            default: (el) => {
                if (el.required && !this.inputs[el.key]) {
                    el.invalid = true;
                    invalids.push(el.label);
                } else {
                    el.invalid = false;
                }
            },
            phone: (el) => {
                if (el.required && (!this.inputs.dial_code || !this.inputs.phone)) {
                    el.invalid = true;
                    invalids.push(el.label);
                } else {
                    el.invalid = false;
                }
            },
            'confirm_password': (el) => {
                if (el.required && !this.inputs[el.key]) {
                    el.invalid = true;
                    invalids.push(el.label);
                    return;
                }

                if (this.inputs[el.key] !== this.inputs['password']) {
                    this.error('Confirm Password does not match Password.  Please reenter and submit again.')
                    el.invalid = true;
                    invalidFormats = true;
                    return;
                }

                el.invalid = false;
            }
        };

        this.signUpFields.forEach((el) => {
            if(el.key !== 'username'){ //since email and username are the same field, we don't need to run validation on both
                const validationCheck = validateKeys[el.key] ? validateKeys[el.key] : validateKeys['default'];
                validationCheck(el);
            }

        });
        return invalidFormats || invalids;
    }

    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user)
            .then(data => {
                if(data.verified || !data.verified === ""){
                    user = Object.assign(user, data);
                }
                this.changeState('signedIn', user);
                // if (!data.verified || data.verified === "") {
                //     this.changeState('signedIn', user);
                // } else {
                //     user = Object.assign(user, data);
                //     this.changeState('signedIn', user);
                //     // this.changeState('verifyContact', user);
                // }
            });
    }

    async signIn() {
        const { username, password } = this.state.values;

        if (!username || !password) {
            return null;
        }

        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.setState({ signInLoading: true });
        try {
            const user = await Auth.signIn(username.toLowerCase(), password);
            logger.debug(user);
            if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                logger.debug('confirm user with ' + user.challengeName);
                this.changeState('confirmSignIn', user);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                logger.debug('require new password', user.challengeParam);
                this.changeState('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', user.challengeParam);
                this.changeState('TOTPSetup', user);
            } else {
                this.checkContact(user);
            }
        } catch (err) {
            if(err.code === "InvalidParameterException" && err.message.indexOf('userName') !== -1){
                return this.error('Invalid email address format')
            }
            if (err.code === 'UserNotConfirmedException') {
                logger.debug('the user is not confirmed');
                this.changeState('confirmSignUp', { username });
            } else if (err.code === 'PasswordResetRequiredException') {
                logger.debug('the user requires a new password');
                this.changeState('forgotPassword', { username });
            } else {
                this.error(err);
            }
        } finally {
            this.setState({ signInLoading: false })
        }
    }

    signUp() {
        try {
            sessionStorage.removeItem('shop');
            sessionStorage.removeItem('promo');
            sessionStorage.removeItem('offer_id');
            sessionStorage.removeItem('aff_id');
            sessionStorage.removeItem('from');
            sessionStorage.removeItem('socialUser');
        } catch (error) {
            console.log(error);
        }

        this.setState({ isLoading: true });
        this.inputs.email = this.inputs.username;

        // Add dial code if not present.
        if (!this.inputs.dial_code) {
            this.inputs.dial_code = this.getDefaultDialCode();
        }

        // Validate inputs.
        let invalidFields = this.validate();
        if (Array.isArray(invalidFields) && invalidFields.length > 0) {
            this.setState({ isLoading: false })
            return this.error(`The following fields are required: ${invalidFields.join(', ')}`);
        }
        if ((Array.isArray(invalidFields) && invalidFields.length > 0) || invalidFields === true) {
            this.setState({ isLoading: false })
            return null;
        }

        // Validate Auth is present.
        if (!Auth || typeof Auth.signUp !== 'function') {
            this.setState({ isLoading: false })
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        // Construct signup payload.
        let signup_info = {
            username: this.inputs.username.toLowerCase(),
            email: this.inputs.username.toLowerCase(),
            password: this.inputs.password,
            attributes: {}
        };

        // Add the promo custom attribute to send on Presignup trigger
        if (this.props.params.promo) {
            signup_info.attributes = { 'custom:promo': this.props.params.promo };
        }

        // PSA: avoid sending sign up values as custom attributes unless absolutely necessary. it's cumbersome to get working and manage in aws.
        // use sessionStorage and updateAffiliate via App.js instead
        if (this.props.params.from) {
            sessionStorage.setItem('landingPageRef', this.props.params.from);
        }

        const inputKeys = Object.keys(this.inputs);
        const inputVals = Object.values(this.inputs);

        inputKeys.forEach((key, index) => {
            if (!['username', 'password', 'checkedValue', 'dial_code'].includes(key)) {
                if (key !== 'phone' && key !== 'dial_code' && key !== 'error' && key !== 'confirm_password') {
                    const newKey = `${this.needPrefix(key) ? 'custom:' : ''}${key}`;
                    signup_info.attributes[newKey] = inputVals[index];
                } else if (inputVals[index]) {
                    signup_info.attributes['phone_number'] = `${this.inputs.dial_code}${this.inputs.phone.replace(/[-()]/g, '')}`
                }
            }
        });

        return Auth.signUp(signup_info).then((data) => {
                console.log("DATA", data);
                this.changeState('confirmSignUp', data.user.username)
                this.setState({ isLoading: false })
            })
            .catch(err => {
                if(err.code === "InvalidParameterException" && err.message.indexOf('username') !== -1){
                    this.error('Invalid email address format')
                }else{
                    this.error(err);
                }
                this.setState({ isLoading: false })
            });
    }

    onChange(event) {
        const { name, value } = event.target;
        const newValues = {...this.state.values};
        newValues[name] = value;

        this.setState({values: newValues});
        this.handleInputChange(event)
    }

    toggleEmailForm = () => {
        this.setState({
            showEmailForm: !this.state.showEmailForm
        });
    }

    changeToSignInView = () => {
        this.changeState('signIn');
        this.setState({ showEmailForm: false });
    }

    showComponent(theme) {
        if (this.state.signInLoading) {
            return (
                <FormSection theme={theme}>
                    <CenteredSpinner />
                </FormSection>
            );
        }
        const applyButtonClass = ['amplify-button'];

        return (
            <div className="signin-amplify__wrapper">
                <div className="signin-amplify__form-wrapper signin-amplify__form-wrapper--signup">
                    <div className="signin-amplify__signin-form">
                        <div className="signin-amplify__mobile-wrapper">
                            <img src={llamaHead} alt="Leo the Llama" className="signin-amplify__llama-head" />
                            <div className="signin-amplify__mobile-text">
                                <h1 className="signin-amplify__header">Llama {(this.props.params?.from === 'smartlinks') ? 'SmartLinks' : 'Ambassador'} Sign Up</h1>
                                <p className="signup-amplify__subheader">Create a new account.</p>
                            </div>
                        </div>
                        <SectionBody theme={theme}>
                            {!this.state.showEmailForm
                                && (
                                    <>
                                        <SocialAuthButtons
                                            authType="SIGNUP"
                                            params={{
                                                promo: this.props.params?.promo,
                                                from: (this.props.params?.from === 'insta-analytics') ? 'instaAnalytics' : this.props.params?.from
                                            }}
                                        />
                                        <SocialAuthButtons.Separator />
                                        <SocialAuthButtons.EmailToggle
                                            onClick={this.toggleEmailForm}
                                        />
                                    </>
                                )
                            }
                            {this.state.showEmailForm
                                && (
                                    <>
                                        <SocialAuthButtons.Breadcrumb
                                            onClick={this.toggleEmailForm}
                                        />
                                        {this.signUpFields.map((field) => {
                                            if (field.hidden) {
                                                return null;
                                            }

                                            return (
                                                <div className="amplify-form-item" key={field.key}>
                                                    <div className="amplify-input-wrapper">
                                                        {
                                                            field.key === 'phone' && (
                                                                <select
                                                                    className="amplify-select"
                                                                    name="dial_code"
                                                                    defaultValue={this.getDefaultDialCode()}
                                                                    onChange={this.handleInputChange}
                                                                >
                                                                    {countryDialCodes.map((dialCode) => {
                                                                        return <option key={dialCode} value={dialCode}>{dialCode}</option>
                                                                    })}
                                                                </select>
                                                            )
                                                        }

                                                        <InputRow
                                                            placeholder={field.placeholder}
                                                            theme={theme}
                                                            type={field.type}
                                                            name={field.key}
                                                            key={field.key}
                                                            onChange={this.onChange}
                                                            value={this.state.values[field.key]}
                                                        />
                                                    </div>
                                                    <div className="amplify-label__wrapper">
                                                        {
                                                            field.key === 'username'
                                                                && (
                                                                    <div className="amplify-email__label">
                                                                        We will send an email to confirm your account.
                                                                    </div>
                                                                )
                                                        }
                                                    </div>
                                                    {
                                                        field.key === 'password'
                                                        && <PasswordRequirements password={this.inputs.password}/>
                                                    }
                                                    {
                                                        field.key === 'confirm_password'
                                                        && <PasswordMatch password={this.inputs.password} confirm={this.inputs.confirm_password} />
                                                    }
                                                </div>
                                            );
                                        })}
                                    </>
                                )
                            }
                        </SectionBody>
                        <div className="amplify__custom-footer">
                            {this.state.showEmailForm
                                && (
                                    <div className="amplify__button-wrapper">
                                        <LlamaButton
                                            classes={applyButtonClass}
                                            onClick={this.signUp}
                                            loading={this.state.isLoading}
                                            disabled={this.state.isLoading}
                                        >
                                            Sign Up
                                        </LlamaButton>
                                    </div>
                                )
                            }
                            <div className="amplify-section-footer_links">
                                {'Have an account? '}
                                <div className="amplify__custom-link">
                                    <Link theme={theme} onClick={this.changeToSignInView}>Sign In</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signup-amplify__graphic-wrapper">
                    <img alt="Leo the Llama Ambassador and his network" src={loginGraphic} className="signin-amplify__login-graphic signin-amplify__login-graphic--signup" />
                </div>
            </div>
        );
    }
}

export default LlamaSignUp;
