import { CHAT_SOCKET_URI } from '../../config';

export const SET_OPEN_SOCKET = 'SET_OPEN_SOCKET';

let pingTimer;
const ping = (socket) => {
    pingTimer = setInterval(() => {
        socket.send(JSON.stringify({ action: 'pingme', message: 'ping!' }));
    }, 9 * 60 * 1000);
};

const connect = (affiliate_id) => {
    if (pingTimer) {
        clearInterval(pingTimer);
    }
    const newSocket = new WebSocket(CHAT_SOCKET_URI);
    newSocket.addEventListener('open', async () => {
        console.log('done connecting');
        await newSocket.send(JSON.stringify({
            action: 'map',
            affiliate_id
        }));
        console.log('mapped');
    });

    // connection auto-disconnects after 10 minutes, so send a ping every 9 minutes to keep it open
    ping(newSocket);

    return {
        type: SET_OPEN_SOCKET,
        payload: newSocket
    };
};

/**
 * Open a WebSocket connection and map the ambassador's id to it
 * @param {String} affiliate_id The affiliate's affiliate_id to map the socket to
 */
export const setOpenSocket = (affiliate_id) => {
    return connect(affiliate_id);
};

// fail-safe to reconnect if they still get disconnected
export const reconnectIfClosed = (socket, affiliate_id) => {
    if (socket.readyState !== 1) {
        return connect(affiliate_id);
    }
    return {
        type: 'ignore',
        payload: socket
    };
};
