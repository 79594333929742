import { sendGraphQL } from './sendRequest';

const GET_PIXEL = 'GET_PIXEL';
const SAVE_PIXEL = 'SAVE_PIXEL';

const getGlobalPixel = (cognito_id) => {
    const query = `
        query getGlobalPixel($cognito_id: String) {
            affiliateById(cognito_id: $cognito_id) {
                globalPixel {
                    pixel_id
                    html
                }
            }
        }
    `;

    const variables = {
        cognito_id
    };

    const payload = {
        query,
        variables
    };

    return {
        type: GET_PIXEL,
        payload: sendGraphQL(payload)
            .then(response => {
                return response.data.data.affiliateById.globalPixel;
            })
    };
};

const saveGlobalPixel = (tracking_id, html) => {
    const query = `
        mutation pixelCreate($data: AffiliatePixelInput) {
            createPixel(data: $data) {
                pixel_id
                affiliate_id
                html
                global
            }
        }
    `;

    const variables = {
        data: {
            tracking_id,
            global: true,
            html
        }
    };

    const payload = {
        query,
        variables
    };

    return {
        type: SAVE_PIXEL,
        payload: sendGraphQL(payload)
            .then(response => {
                return response.data.data.createPixel;
            })
    };
};

export {
    getGlobalPixel,
    saveGlobalPixel
};
