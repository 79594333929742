
import { Auth } from 'aws-amplify';

const GET_AUTH_DATA = 'GET_AUTH_DATA';
export const UPDATE_AUTH_DATA = 'UPDATE_AUTH_DATA';
export const GET_AUTH_DATA_FULFILLED = 'GET_AUTH_DATA_FULFILLED';

const getAuthData = () => {
    return {
        type: GET_AUTH_DATA,
        payload: Auth.currentAuthenticatedUser({ bypassCache: true })
    }
}

const updateAuthData = (newAuthData) => {
    return {
        type: UPDATE_AUTH_DATA,
        payload: newAuthData,
    }
}

export {
    getAuthData,
    updateAuthData,
}