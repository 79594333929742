import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Select, Checkbox, Banner } from '@shopify/polaris';
import { LlamaButton } from 'llama-library/components';

import { updateSmartlinkIntegrations, updateSmartLinkPreview } from '../../../store/actions/action_updateSmartlink';

import './smart-link-mailing-list.css';

const mailingListPlatforms = [
    {
        label: 'SendInBlue',
        value: 'sendinblue',
        oauth: false,
        listHelpText: <>SendInBlue account and click on <strong>Contacts</strong>, followed by <strong>Lists</strong></>,
        keyHelpText: <>SendInBlue account, click on the <strong>Account</strong> menu, and choose <strong>SMTP &amp; API</strong>. Or just <a href="https://account.sendinblue.com/advanced/api" rel="nofollow noopener noreferrer" target="_blank">click here</a>.</>
    },
    {
        label: 'MailChimp',
        value: 'mailchimp',
        oauth: true,
        listHelpText: <>MailChimp account and click the <strong>audience icon</strong>, followed by <strong>All contacts</strong>. Choose your audience and click <strong>Settings</strong>, then choose <strong>Audience name and defaults</strong></>,
        keyHelpText: <>MailChimp account and go to the <strong>Account</strong> page. Then go to <strong>Extras -&gt; API Keys</strong>. Or just <a href="https://us1.admin.mailchimp.com/account/api/" rel="nofollow noopener noreferrer" target="_blank">click here</a>.</>,
        listIdName: 'Audience ID'
    },
    {
        label: 'Constant Contact (Coming Soon)',
        value: 'constantcontact',
        disabled: true,
        oauth: true,
        listHelpText: null,
        keyHelpText: null
    },
    {
        label: 'AWeber (Coming Soon)',
        value: 'aweber',
        disabled: true,
        oauth: true,
        listHelpText: null,
        keyHelpText: null
    },
    {
        label: 'ConvertKit',
        value: 'convertkit',
        oauth: false,
        listHelpText: <>ConvertKit account and go to <strong>Landing Pages &amp; Forms</strong>, click on your form and look at the url in your browser&rsquo;s address bar. The numbers between &ldquo;https://app.convertkit.com/forms/designers/&rdquo; and &ldquo;/edit&rdquo; are your Form ID</>,
        keyHelpText: <>ConvertKit account and click on your profile in the menu bar, then choose <strong>Account Settings</strong> or just <a href="https://app.convertkit.com/account/edit" rel="nofollow noopener noreferrer" target="_blank">click here</a>.</>,
        listIdName: 'Form ID'
    },
    {
        label: 'GetResponse',
        value: 'getresponse',
        oauth: true,
        listHelpText: <>GetResponse account and click <strong>Lists</strong>. Click on your list, then click <strong>Menu</strong> followed by <strong>Settings</strong>. Your List Token is under the name of your list.</>,
        keyHelpText: <>GetResponse account and click the <strong>main menu icon</strong>, then choose <strong>Integrations and API</strong> followed by <strong>API</strong>. Or just <a href="https://app.getresponse.com/api" rel="nofollow noopener noreferrer" target="_blank">click here</a>.</>,
        listIdName: 'List Token'
    },
    {
        label: 'MailerLite',
        value: 'mailerlite',
        oauth: false,
        listHelpText: <>MailerLite account and click on Subscribers then click your group. Look at the url in your browser&rsquo;s address bar. The numbers after &ldquo;https://app.mailerlite.com/subscribers/view&rdquo; are your GroupID.</>,
        keyHelpText: <>MailerLite account and click on your <strong>profile photo</strong> and choose <strong>Integrations</strong> Then, click <strong>Developer api</strong>. Or just <a href="https://app.mailerlite.com/integrations/api/" rel="nofollow noopener noreferrer" target="_blank">click here</a>.</>,
        listIdName: 'GroupID'
    },
    {
        label: 'Drip',
        value: 'drip',
        oauth: false,
        listHelpText: <>Drip account and look at the url in your browser&rsquo;s address bar. The numbers between &ldquo;https://www.getdrip.com/&rdquo; and &ldquo;/dashboard&rdquo; are your Account ID.</>,
        keyHelpText: <>Drip account and go to <strong>Settings</strong>, then <strong>User Settings</strong>. Or just <a href="https://www.getdrip.com/user/edit" rel="nofollow noopener noreferrer" target="_blank">click here</a>.</>,
        listIdName: 'Account ID'
    },
    {
        label: 'ActiveCampaign',
        value: 'activecampaign',
        oauth: false,
        listHelpText: <>ActiveCampaign account and click <strong>Lists</strong>. Click on your list and look at the url in your browser&rsquo;s address bar. The numbers after &ldquo;https://[account-name].activehosted.com/app/contacts/?listid=&rdquo; are your List ID.</>,
        keyHelpText: <>ActiveCampaign account and click <strong>Settings</strong>, then <strong>Developer</strong>.</>
    },
    {
        label: 'HubSpot',
        value: 'hubspot',
        oauth: true,
        listHelpText: <>HubSpot account and click <strong>Contacts</strong>, followed by <strong>Lists</strong>. Click your list and look at the url in your browser&rsquo;s address bar. The numbers after &ldquo;https://app.hubspot.com/contacts/[account-id]/lists/&rdquo; are your List ID</>,
        keyHelpText: <>HubSpot account and click the <strong>Settings icon</strong>. Then go to <strong>Integrations -&gt; API Key</strong>. Or just <a href="https://app.hubspot.com/l/integrations-settings/api-key" rel="nofollow noopener noreferrer" target="_blank">click here</a>. Please Note: you will need to be have a Super Admin role to access API Keys.</>
    },
    {
        label: 'Moosend',
        value: 'moosend',
        oauth: false,
        listHelpText: <>Moosend account and click <strong>Mailing lists</strong>. Your List ID is displayed in the upper right corner.</>,
        keyHelpText: <>Moosend account and click the <strong>Settings icon</strong>, then click <strong>API Key</strong>.</>
    },
    {
        label: 'Omnisend',
        value: 'omnisend',
        oauth: false,
        listHelpText: null,
        keyHelpText: <>Omnisend account, click your <strong>profile photo</strong>, and choose <strong>Store Settings</strong>. Then go to <strong>Integrations &amp; API -&gt; API Key</strong>. Or just <a href="https://app.omnisend.com/#/my-account/integrations/api-keys" rel="nofollow noopener noreferrer" target="_blank">click here</a>.</>
    }
];

const mailingListPlatformOptions = mailingListPlatforms.map(({ label, value, disabled }) => {
    const option = { label, value };
    if (disabled) {
        option.disabled = disabled;
    }
    return option;
});

const defaultFormHeading = 'Subscribe to my newsletter';
const defaultButtonText = 'Subscribe';

const errorDefaults = {
    listId: '',
    apiKey: '',
    apiPrefix: '',
    formHeading: '',
    formButtonText: ''
};

const mailingListDefaults = {
    platform: '',
    listId: '',
    apiKey: '',
    apiPrefix: '',
    formHeading: defaultFormHeading,
    formButtonText: defaultButtonText
};

const SmartLinkMailingList = (props) => {
    const { smartlinks: smartlinkProps, affiliate, dispatchUpdateSmartLinkPreview, dispatchUpdateSmartlinkIntegrations, setToastVerbiage, attemptUpgradeModal } = props;

    const [mailingList, setMailingList] = useState({ ...mailingListDefaults });
    const [mailingListPlatformDetails, setMailingListPlatformDetails] = useState(mailingListPlatforms[0]);
    const [mailingListEnabled, setMailingListEnabled] = useState(false);

    const [fieldLoading, setFieldLoading] = useState('');
    const [saveButton, setSaveButton] = useState('');
    const [errors, setErrors] = useState({ ...errorDefaults });
    const [bannerMessage, setBannerMessage] = useState(null);

    // if mailing list is null, set default object params
    useEffect(() => {
        let mailingListFilled = smartlinkProps.mailingList;
        if (!mailingListFilled) {
            mailingListFilled = { ...mailingListDefaults };
        }
        console.log(mailingListFilled);
        setMailingList(mailingListFilled);

        if (smartlinkProps.mailingList && smartlinkProps.mailingList.platform) {
            setMailingListEnabled(true);

            const listIdName = (mailingListFilled.listIdName) ? mailingListFilled.listIdName : 'List ID';
            if (smartlinkProps.mailingList.recentError && smartlinkProps.mailingList.recentError.indexOf('not_found') !== -1) {
                setBannerMessage(`The ${listIdName} you entered doesn${String.fromCharCode(8217)}t seem to exist.`);
            }
            if (smartlinkProps.mailingList.recentError && smartlinkProps.mailingList.recentError.indexOf('unauthorized') !== -1) {
                const omnisendClause = (smartlinkProps.mailingList.platform === 'omnisend') ? `If your key is only allowed to add new subscribers, you${String.fromCharCode(8217)}ll need to allow the Contacts permission. This is just to ensure you entered a valid key; we don${String.fromCharCode(8217)}t store any extended data and you can switch the permissions back after saving.` : '';
                setBannerMessage(`The API Key you entered didn${String.fromCharCode(8217)}t seem to work.${omnisendClause}`);
            }
        }
    }, [smartlinkProps.loaded]);

    // change the mailing list platform details when the platform changes
    useEffect(() => {
        const details = mailingListPlatforms.find((platform) => {
            return platform.value === mailingList.platform;
        });
        if (details) {
            setMailingListPlatformDetails(details);
        }
    }, [mailingList, mailingList.platform]);

    useEffect(() => {
        if (!saveButton) {
            dispatchUpdateSmartLinkPreview(null);
        }
    }, [saveButton]);

    const saveChanges = (field, payload) => {
        let testApi = false;
        if (!smartlinkProps.mailingList
        || payload.mailingList.platform !== smartlinkProps.mailingList.platform
        || payload.mailingList.apiKey !== smartlinkProps.mailingList.apiKey
        || (payload.mailingList.apiPrefix && payload.mailingList.apiPrefix !== smartlinkProps.mailingList.apiPrefix)
        || payload.mailingList.listId !== smartlinkProps.mailingList.listId) {
            testApi = true;
        }

        console.log(testApi, smartlinkProps, payload);

        setFieldLoading(field);
        dispatchUpdateSmartlinkIntegrations(affiliate.cognito_id, payload, testApi)
            .then((result) => {
                if (result.value.data.updateSmartlinkIntegrations && result.value.data.updateSmartlinkIntegrations.mailingListError) {
                    const apiError = result.value.data.updateSmartlinkIntegrations.mailingListError;
                    const listIdName = (mailingListPlatformDetails.listIdName) ? mailingListPlatformDetails.listIdName : 'List ID';
                    if (apiError === 'not_found') {
                        setBannerMessage(`The ${listIdName} you entered doesn${String.fromCharCode(8217)}t seem to exist.`);
                    }
                    if (apiError === 'unauthorized') {
                        const omnisendClause = (payload.mailingList.platform === 'omnisend') ? `If your key is only allowed to add new subscribers, you${String.fromCharCode(8217)}ll need to allow the Contacts permission. This is just to ensure you entered a valid key; we don${String.fromCharCode(8217)}t store any extended data and you can switch the permissions back after saving.` : '';
                        setBannerMessage(`The API Key you entered didn${String.fromCharCode(8217)}t seem to work.${omnisendClause}`);
                    }
                    if (apiError === 'unknown') {
                        setBannerMessage(`Oops, something went wrong and we weren${String.fromCharCode(8217)}t able to verify your API Key or ${listIdName}. Please try again later.`);
                    }
                } else {
                    setSaveButton('');
                    setToastVerbiage('Saved');
                    setBannerMessage(null);
                }
                setFieldLoading(null);
            })
            .catch((err) => { console.error(err); });
    };

    const handleMailingListEnabledChange = (isChecked) => {
        // toggle off
        if (!isChecked) {
            setMailingList({ ...mailingListDefaults });
            setMailingListEnabled(false);
            document.querySelector('.preview-area-scroll').scrollTop = 0;
            // if any mailing list props were previously saved, save all as empty strings
            if (smartlinkProps.mailingList
                && (smartlinkProps.mailingList.platform
                || smartlinkProps.mailingList.listId
                || smartlinkProps.mailingList.apiKey
                || smartlinkProps.mailingList.apiPrefix
                || smartlinkProps.mailingList.formHeading)
            ) {
                return saveChanges('mailingListDetails', { mailingList: { ...mailingListDefaults, formHeading: '', formButtonText: '' } });
            }
            setSaveButton('');
        // toggle on
        } else {
            setMailingList({ platform: 'mailchimp', formHeading: defaultFormHeading, formButtonText: defaultButtonText });
            setMailingListEnabled(true);
            dispatchUpdateSmartLinkPreview({ emailForm: { heading: defaultFormHeading, buttonText: defaultButtonText } });
            setSaveButton('mailingListDetails');
        }
        return null;
    };

    const handleMailingListPlatformChange = (value) => {
        if (saveButton !== 'mailingListDetails') {
            setSaveButton('mailingListDetails');
        }
        setErrors({ ...errorDefaults });

        setMailingList({
            ...mailingList,
            platform: value,
            listId: '',
            apiKey: '',
            apiPrefix: ''
        });
    };

    const handleMailingListListIdChange = (value) => {
        if (saveButton !== 'mailingListDetails') {
            setSaveButton('mailingListDetails');
        }

        // only allow letters, numbers, and hyphens
        const cleanValue = value.replace(/[^\w\d-]/g, '');
        if (cleanValue && errors.listId) {
            setErrors({
                ...errors,
                listId: ''
            });
        }

        setMailingList({
            ...mailingList,
            listId: cleanValue
        });
    };

    const handleMailingListAPIKeyChange = (value) => {
        if (saveButton !== 'mailingListDetails') {
            setSaveButton('mailingListDetails');
        }

        if (value && errors.apiKey) {
            setErrors({
                ...errors,
                apiKey: ''
            });
        }

        const mailingListObj = {
            ...mailingList,
            apiKey: value
        };

        // if mailchimp is selected and they haven't filled in the prefix autodetect it from the key
        if (mailingList.platform === 'mailchimp' && !mailingList.apiPrefix) {
            const apiPrefix = value.match(/-([\w]+\d)$/);
            if (apiPrefix && apiPrefix[1]) {
                mailingListObj.apiPrefix = apiPrefix[1];
            }
        }

        setMailingList(mailingListObj);
    };

    const handleMailingListApiPrefixChange = (value, platform) => {
        if (saveButton !== 'mailingListDetails') {
            setSaveButton('mailingListDetails');
        }

        let invalidError = false;

        // only allow letters, numbers, hyphens, dots, and underscores
        let cleanValue = value.replace(/[^\w\d_\-.]/g, '');

        if (platform === 'activecampaign') {
            // remove the protocol if they pasted the url in
            cleanValue = cleanValue.replace(/^https/, '');
            cleanValue = cleanValue.replace(/\.api-us1\.com.*?$/, '');
            if (!(/^[\w\d_-]+$/).test(cleanValue)) {
                invalidError = true;
            }
        }

        if (cleanValue && errors.apiPrefix && !invalidError) {
            setErrors({
                ...errors,
                apiPrefix: ''
            });
        }

        if (cleanValue && errors.apiPrefix && invalidError) {
            setErrors({
                ...errors,
                apiPrefix: 'Not a valid API URL'
            });
        }

        setMailingList({
            ...mailingList,
            apiPrefix: cleanValue
        });
    };

    const handleEmailFormHeadingChange = (value) => {
        if (saveButton !== 'mailingListDetails') {
            setSaveButton('mailingListDetails');
        }

        // don't allow angle brackets or braces to prevent html/jsx injection
        const cleanValue = value.replace(/[<>{}]+/g, '');
        if (cleanValue && errors.formHeading) {
            setErrors({
                ...errors,
                formHeading: ''
            });
        }

        setMailingList({
            ...mailingList,
            formHeading: cleanValue
        });
        dispatchUpdateSmartLinkPreview({ emailForm: { heading: cleanValue, buttonText: mailingList.formButtonText } });
    };

    const handleEmailFormButtonTextChange = (value) => {
        if (saveButton !== 'mailingListDetails') {
            setSaveButton('mailingListDetails');
        }

        // don't allow angle brackets or braces to prevent html/jsx injection
        const cleanValue = value.replace(/[<>{}]+/g, '');
        if (cleanValue && errors.formButtonText) {
            setErrors({
                ...errors,
                formButtonText: ''
            });
        }

        setMailingList({
            ...mailingList,
            formButtonText: cleanValue
        });
        dispatchUpdateSmartLinkPreview({ emailForm: { heading: mailingList.formHeading, buttonText: cleanValue } });
    };

    const cancelMailingListDetailsChange = () => {
        setMailingList(smartlinkProps.mailingList);
        if (!smartlinkProps.mailingList) {
            setMailingList({ ...mailingListDefaults });
            setMailingListEnabled(false);
        }
        setErrors({ ...errorDefaults });
        setSaveButton('');
    };

    const saveMailingListDetails = (e) => {
        if (e) {
            e.preventDefault();
        }

        // check for errors
        const currentErrors = { ...errors };
        let hasErrors = false;
        if (!mailingList.listId && mailingList.platform !== 'omnisend') {
            currentErrors.listId = `${mailingListPlatformDetails.listIdName ? mailingListPlatformDetails.listIdName : 'List ID'} is required.`;
            hasErrors = true;
        }
        if (!mailingList.apiKey) {
            currentErrors.apiKey = 'API Key is required.';
            hasErrors = true;
        }
        if ((mailingList.platform === 'mailchimp' || mailingList.platform === 'activecampaign') && !mailingList.apiPrefix) {
            currentErrors.apiPrefix = `API ${mailingList.platform === 'mailchimp' ? 'Prefix' : 'URL'} is required.`;
            hasErrors = true;
        }
        if ((mailingList.platform === 'activecampaign') && mailingList.apiPrefix && !(/^[\w\d_-]+$/).test(mailingList.apiPrefix)) {
            currentErrors.apiPrefix = 'Not a valid API URL.';
            hasErrors = true;
        }
        if (!mailingList.formHeading) {
            currentErrors.formHeading = 'Form Heading is required.';
            hasErrors = true;
        }
        if (!mailingList.formButtonText) {
            currentErrors.formButtonText = 'Form Button Text is required.';
            hasErrors = true;
        }

        if (hasErrors) {
            console.log(currentErrors);
            setErrors(currentErrors);
        } else {
            if ((!smartlinkProps.mailingList)
            || smartlinkProps.mailingList.platform !== mailingList.platform
            || smartlinkProps.mailingList.listId !== mailingList.listId
            || smartlinkProps.mailingList.apiKey !== mailingList.apiKey
            || smartlinkProps.mailingList.apiPrefix !== mailingList.apiPrefix
            || smartlinkProps.mailingList.formHeading !== mailingList.formHeading
            || smartlinkProps.mailingList.formButtonText !== mailingList.formButtonText) {
                delete mailingList.recentError;
                return saveChanges('mailingListDetails', { mailingList });
            }

            setSaveButton('');
        }

        return null;
    };

    return (
        <fieldset>
            <legend>Mailing List</legend>
            <ul>
                <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                    <Checkbox
                        label="Allow people to join my newsletter/email list"
                        checked={mailingListEnabled}
                        onChange={handleMailingListEnabledChange}
                        disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                    />
                </li>
            </ul>
            {mailingListEnabled
                && (
                    <form onSubmit={saveMailingListDetails}>
                        {bannerMessage && <Banner status="critical" title="Something went wrong">{bannerMessage}</Banner>}
                        <ul className="smartlinks-mailing-list">
                            <li className="platform" data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                <Select
                                    label="Platform"
                                    options={mailingListPlatformOptions}
                                    value={mailingList.platform}
                                    onChange={handleMailingListPlatformChange}
                                    disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                />
                            </li>
                            {mailingList && mailingList.platform !== 'omnisend'
                                && (
                                    <li className="list-id" data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                        <TextField
                                            label={mailingListPlatformDetails.listIdName ? mailingListPlatformDetails.listIdName : 'List ID'}
                                            value={mailingList.listId}
                                            onChange={handleMailingListListIdChange}
                                            helpText={<>To find your {mailingListPlatformDetails.listIdName ? mailingListPlatformDetails.listIdName : 'List ID'}, login to your {mailingListPlatformDetails.listHelpText}</>}
                                            disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                            required="required"
                                            error={errors.listId}
                                        />
                                    </li>
                                )
                            }

                            {mailingList && mailingList.platform === 'activecampaign'
                                && (
                                    <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                        <TextField
                                            label="API URL"
                                            value={mailingList.apiPrefix}
                                            onChange={(value) => { return handleMailingListApiPrefixChange(value, 'activecampaign'); }}
                                            helpText={<>To find your API URL, login to your ActiveCampaign account and click <strong>Settings</strong>, then <strong>Developer</strong>.</>}
                                            prefix="https://"
                                            suffix=".api-us1.com"
                                            placeholder="youraccountname"
                                            disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                            required="required"
                                            error={errors.apiPrefix}
                                        />
                                    </li>
                                )
                            }

                            <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                <TextField
                                    type="password"
                                    label="API Key"
                                    value={mailingList.apiKey}
                                    onChange={handleMailingListAPIKeyChange}
                                    helpText={<>To find your API Key, login to your {mailingListPlatformDetails.keyHelpText}</>}
                                    disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                    required="required"
                                    error={errors.apiKey}
                                />
                            </li>

                            {mailingList && mailingList.platform === 'mailchimp'
                                && (
                                    <li className="server-prefix" data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                        <TextField
                                            label="Server Prefix"
                                            value={mailingList.apiPrefix}
                                            onChange={(value) => { return handleMailingListApiPrefixChange(value, 'mailchimp'); }}
                                            helpText={<>To find your Server Prefix, login to your MailChimp account and look at the url in your browser&rsquo;s address bar. The characters between &ldquo;https://&rdquo; and &ldquo;.admin.mailchimp.com/...&rdquo; are your Server Prefix</>}
                                            placeholder="eg. us1"
                                            disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                            required="required"
                                            error={errors.apiPrefix}
                                        />
                                    </li>
                                )
                            }

                            {/* TODO: prefer OAuth connectivity over keys as available if and when feature demand grows  */}
                            {/* mailingListPlatformDetails.oauth
                                ? <li><button type="button">Connect Account</button></li>
                                : (
                                    <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                        <TextField
                                            type="password"
                                            label="API Key"
                                            value={mailingList.apiKey}
                                            onChange={handleMailingListAPIKeyChange}
                                            helpText={<>To find your API Key, login to your {mailingListPlatformDetails.helpText}</>}
                                            disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                            required="required"
                                        />
                                    </li>
                                )
                            */}

                            <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                <TextField
                                    label="Form Heading"
                                    onChange={handleEmailFormHeadingChange}
                                    value={mailingList.formHeading}
                                    disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                    helpText={<>For best results, use a heading that&rsquo;s short and to the point, such as &ldquo;{defaultFormHeading}&rdquo;</>}
                                    required="required"
                                    error={errors.formHeading}
                                />
                            </li>
                            <li data-pro-locked={smartlinkProps.settings.subscription.plan !== 'plan_pro'} onClick={() => attemptUpgradeModal('mailingList')}>
                                <TextField
                                    label="Form Button Text"
                                    onChange={handleEmailFormButtonTextChange}
                                    value={mailingList.formButtonText}
                                    disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                    placeholder="eg. Subscribe, Join, Go"
                                    required="required"
                                    error={errors.formButtonText}
                                />
                            </li>
                        </ul>
                        {saveButton === 'mailingListDetails'
                            && (
                                <div className="button-group">
                                    <button type="button" className="cancel" onClick={cancelMailingListDetailsChange}>Cancel</button>
                                    <LlamaButton
                                        type="submit"
                                        loading={fieldLoading === 'mailingListDetails'}
                                        disabled={fieldLoading === 'mailingListDetails' || smartlinkProps.settings.subscription.plan !== 'plan_pro'}
                                        onClick={saveMailingListDetails}
                                    >
                                        Save
                                    </LlamaButton>
                                </div>
                            )
                        }
                    </form>
                )
            }
        </fieldset>
    );
};

SmartLinkMailingList.propTypes = {
    
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchUpdateSmartlinkIntegrations: (id, data, testApi) => { return dispatch(updateSmartlinkIntegrations(id, data, testApi)); },
        dispatchUpdateSmartLinkPreview: (data) => { return dispatch(updateSmartLinkPreview(data)); }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartLinkMailingList);
