/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { sendGraphQL } from "./sendRequest";

export function updateCustomization(cognitoID, customization) {
    const query = `
        mutation updateSmartlinkCustomization($cognito_id: String, $data: CustomizationInput!) {
                updateSmartlinkCustomization(cognito_id: $cognito_id, data: $data) {
                    theme
                    avatarUrl
                    font
                    pageBg
                    headlineText
                    buttonBg
                    buttonBorder
                    buttonText
                    buttonRoundness
                }
        }
    `

    const variables = {
        cognito_id: cognitoID,
        data: customization
    }

    let payload = {
        query,
        variables
    }

    return {
        type: 'UPDATE_SMARTLINK_CUSTOMIZATION',
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: 'UPDATE_SMARTLINK_CUSTOMIZATION',
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response.data)
    }; */
}