/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../../config'; */
import { sendGraphQL } from "../sendRequest";

const FACEBOOK = 'CREATE_FACEBOOK_USER';

export const createFacebookAffData = (code, id) => {
    const query = `mutation createFacebookUser($code: String,  $cognito_id: String){
        createFacebookUser(code: $code,  cognito_id: $cognito_id){
          facebook_id
          cognito_id
          pages{
              id
              name
          }
        }
      }`;

    const variables = {
        cognito_id: id,
        code: code
    }

    const payload = {
        query,
        variables
    }

    return {
        type: FACEBOOK,
        payload: sendGraphQL(payload)
            .then(response => response)
    }

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: FACEBOOK,
        payload: axios.post(NEXT_APP_API_URL, payload, { headers })
            .then(response => response)

    } */
}