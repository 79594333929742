/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

/* import axios from 'axios';
import {NEXT_APP_API_URL} from '../../config'; */
import {sendGraphQL} from './sendRequest'
import {UPDATE_EMAIL_PREF} from './types';

export const UPDATE_EMAIL_PREF_PENDING = 'UPDATE_EMAIL_PREF_PENDING';
export const UPDATE_EMAIL_PREF_FULFILLED = 'UPDATE_EMAIL_PREF_FULFILLED';
export const UPDATE_EMAIL_PREF_REJECTED = 'UPDATE_EMAIL_PREF_REJECTED';

export function updateEmailPref(emailInfo, cognitoID) {
    const query = `
    mutation updateAffiliate($cognito_id: String, $data: AffiliateInput!) {
        updateAffiliate(cognito_id: $cognito_id, data: $data){
            name
            email
            email_preference{
                application_general
                application_alerts
                application_advertiser_messages
                application_weekly_summary
                newsletter_general
                newsletter_marketing
                newsletter_updates
                email_unsubscribe
            }
        }
    }
    `

    const variables = {
        cognito_id: cognitoID,
        data: {
            "email_preference": emailInfo
        }
    };

    let payload = {
        query,
        variables
    }

    return {
        type: UPDATE_EMAIL_PREF,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: UPDATE_EMAIL_PREF,
        payload: axios.post(NEXT_APP_API_URL, payload, {headers})
            .then(response => response.data)
    }; */
}