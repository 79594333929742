/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React, { useState } from 'react';
import { Heading, TextStyle, ResourceList } from '@shopify/polaris';
import { LlamaPagination } from 'llama-library/components';
import usePages from '../../hooks/use-pages';
import {moneyFormat} from '../../utils/number-format'

import './offer-detail.css';

const Product = ({ item, offer_type }) => {

	// console.log("ITEM", item);

	
	const [showMore, setShowMore] = useState(false);
	const onShowToggle = () => {
		setShowMore(!showMore);
	};
	
	let image = "https://dummyimage.com/100x100/d8d8d8/34135d&text=No+image+provided";
	if(item && item.image && item.image.src){
		image = item.image.src
	}

	const quantitySold = item.quantity_sold ? item.quantity_sold : 0 ;

	const cleanHtml = item.body_html.replace(/<(?!\/?(strong|p|i|b|em|del|ins|br|dl|dt|dd|ul|li|q)).+?>/g, '').replace(/(<p>\s<\/p>|<p><\/p>)/g, '')

	return (
		<div className="offer-item" key={item.product_id}>
			<div className="offer-item__image">
				<img className="offer-item__image-style" src={image} alt={item.handle}/>
			</div>
			<div className="offer-item__general">
				<div className="offer-item__title">{item.title}</div>
				<div className={showMore === true ? "offer-item__description expanded" : "offer-item__description"} dangerouslySetInnerHTML={{ __html: cleanHtml }} />
				{showMore === true ?
					<p onClick={onShowToggle} className="offer-item__see-more">Show Less</p>
					:
					<p onClick={onShowToggle} className="offer-item__see-more">Show More</p>
				}
			</div>
			<div className="offer-item__results">
				<div className="offer-item__detail">{moneyFormat(item.variants[0].price, true)}</div>
				{offer_type !== 'product_giveaway'
                    && <div className="offer-item__detail">{quantitySold}</div>
                }
			</div>
		</div>
	)
}

const OfferProducts = (props) => {

	const { offer } = props;
	let product_info;
	if(offer && offer.product_info && Array.isArray(offer.product_info)){
		product_info = offer.product_info;
	}
	
	const {
        previousPage,
        nextPage,
        currentPage,
        hasNext,
        hasPrevious,
        maxPages,
        posts
    } = usePages(0, 7, product_info, document.querySelector('.affiliate-offer__products-heading'));

	if (!offer || !Array.isArray(offer.product_info)) {
		return null;
	}

	return (
		<div className="affiliate-offer__products">
			<div className="affiliate-offer__products-heading">
                <Heading>{offer.offer_type === 'product_giveaway' ? 'ELIGIBLE' : 'AVAILABLE'} PRODUCTS</Heading>
                <TextStyle variation="subdued">
                    <span className="subtitle">
                        {offer.offer_type === 'product_giveaway'
                            ? 'The following products are eligible for this giveaway:'
                            : 'Your referred customers must purchase one or more of the following products:'
                        }
                    </span>
                </TextStyle>
            </div>
			<div className="affiliate-offer__product-headers">
				<div className="affiliate-offer__header-left">
					<h3 className="affiliate-offer__header-item-lg">Product</h3>
				</div>
				<h3 className="affiliate-offer__header-item">{offer.offer_type === 'product_giveaway' && 'Retail '}Price</h3>
				{offer.offer_type !== 'product_giveaway'
                    && <h3 className="affiliate-offer__header-item">Quantity Sold</h3>
                }
			</div>
			<ResourceList items={posts} renderItem={(item) => <Product item={item} offer_type={offer.offer_type} />} />
			{maxPages > 0 &&
			<div className="affiliate-offer__product-pagination">
				<LlamaPagination 
					hasNext={hasNext}
					hasPrevious={hasPrevious}
					onNext={nextPage}
					onPrevious={previousPage}
					currentPage={currentPage}
					maxPages={maxPages}
				/>
			</div>
			}
		</div>
	)

}

export default OfferProducts;