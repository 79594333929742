/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 */

/* import axios from 'axios';
import { NEXT_APP_API_URL } from '../../config'; */
import { UPDATE_AFFILIATE } from './types';
import { sendGraphQL } from './sendRequest';

export const updateAffiliate = (affiliateData, cognitoID) => {
    const query = `
        mutation updateAffiliate($cognito_id: String, $data: AffiliateInput!) {
            updateAffiliate(cognito_id: $cognito_id, data: $data) {
                cognito_id
                account{
                    personal{
                        first_name
                        last_name
                    }
                }
                account_type
                name
                photo
                onboarding_complete
            }
        }
    `;

    const variables = {
        cognito_id: cognitoID,
        data: affiliateData
    };

    const payload = {
        query,
        variables
    };

    return {
        type: UPDATE_AFFILIATE,
        payload: sendGraphQL(payload)
            .then(response => response.data)
    };

    /* let headers = {
        "Content-Type": 'application/json'
    }

    return {
        type: UPDATE_AFFILIATE,
        payload:  axios.post(NEXT_APP_API_URL , payload, { headers })
        .then(response => response.data)
    }; */
};

export default updateAffiliate;
