/**
 * Author: Jacob Clark (jake@convert2media.com)
 * App: LLAMA APP
 * Date: March 21, 2018
 **/

import React, { Component } from 'react';
import {
	withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './affiliate-social.css';
import fbIconBlk from '../../assets/fb_blk.svg';
import igIconBlk from '../../assets/ig_blk.svg';
import twitterIconBlk from '../../assets/twitter_blk.svg';
import youtubeIconBlk from '../../assets/youtube_blk.svg';
import tiktokIconBlk from '../../assets/tiktok_blk.svg';
import FacebookComponent from './facebook/facebook-integration';
import InstagramComponent from '../../containers/hype-auditor';
import TikTokComponent from '../../containers/hype-auditor-tiktok';
import TwitterComponent from './twitter/twitter-integration';
import YoutubeComponent from './youtube/youtube-integration';
import EmptyComponentState from '../EmptyComponent/emptyState';
import { updateAffiliate } from '../../store/actions/action_updateAffiliate';
import { getAffiliateData } from '../../store/actions/action_getAffiliate';
import { Auth } from 'aws-amplify';
import { Card, Page, Heading } from '@shopify/polaris';

export class SocialSettings extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            affiliate: null,
            socialData: [],
            instagram: false,
            tiktok: false,
            twitter: false,
            facebook: false,
            youtube: false,
            selectedSocialAccounts: [],
            selectedSocialGroups: [],
            selectedTab: 0,
            gettingAccounts: false
        };

        this.socialPlatforms = ['facebook', 'twitter', 'instagram', 'youtube', 'tiktok'];
        this.state.instagram = true;
        this.refreshCurrentComponentCallback = this.refreshCurrentComponent.bind(this);
    }

    clickInsta = (el) => {
        if (window.location.search.startsWith('?code=')) {
            el.click();
            setTimeout(() => {
                this.props.history.push('/social');
            }, 3000);
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getAffiliate();
    }

    getAffiliate() {
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then((user) => {
            sessionStorage.setItem('username', user.username);
            this.props.getAffiliateData(user.username).then((result) => {
                if (this._isMounted) {
                    this.setState({
                        affiliate: result.value.data.affiliateById ? result.value.data.affiliateById : {},
                        gettingAccounts: false
                    }, () => {
                    })
                }
            });
        }).catch((err) => {
            throw (err);
        });
        this.setState({gettingAccounts: true});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    navigateToSocial(socialPlaytform) {
        let path = 'account/social/' + socialPlaytform;
        this.props.history.push(path);
    }

    setActiveSocialPlatform = (platform) => {
        this.setState({[`${platform}`]: true}, () => {
            this.socialPlatforms.map((item) => {
                if (item !== platform) {
                    this.setState({[`${item}`]: false});
                }
            });
        });
    };

    refreshCurrentComponent = () => {
        this.getAffiliate();
    };

    getActiveSocialPlatform = () => {
        for (let i = 0; i < this.socialPlatforms.length; i++) {
            if (this.state[`${this.socialPlatforms[i]}`]) {
                return `${this.socialPlatforms[i]}`;
            }
        }
    };

    facebookRender = () => {
        var activeClassStyle = this.state.facebook ? 'social__facebook social__active' : 'social__facebook';
        return (
            <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'facebook')}>
                <img src={fbIconBlk} alt="facebook icon" className="social__icon"/>
            </div>
        )
    };

    instaRender = () => {
        var activeClassStyle = this.state.instagram ? 'social__instagram social__active' : 'social__instagram';

        if (this.props.affiliate.instagram !== "") {
            return (
                <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'instagram')}
                     ref={this.clickInsta}>
                    <img src={igIconBlk} alt="instagram icon" className="social__icon"/>
                </div>
            )
        } else {
            return (
                <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'instagram')}>
                    <img src={igIconBlk} alt="instagram icon" className="social__icon"/>
                </div>
            )
        }
    };

    tiktokRender = () => {
        var activeClassStyle = this.state.tiktok ? 'social__instagram social__active' : 'social__instagram';

        if (this.props.affiliate.tiktok !== '') {
            return (
                <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'tiktok')}
                     ref={this.clickInsta}>
                    <img src={tiktokIconBlk} alt="tiktok icon" className="social__icon"/>
                </div>
            )
        } else {
            return (
                <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'tiktok')}>
                    <img src={tiktokIconBlk} alt="tiktok icon" className="social__icon"/>
                </div>
            )
        }
    }

    youtubeRender = () => {
        var activeClassStyle = this.state.youtube ? 'social__youtube social__active' : 'social__youtube';
        return (
            <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'youtube')}>
                <img src={youtubeIconBlk} alt="youtube icon" className="social__icon"/>
            </div>
        )
    };

    twitterRender = () => {
        var activeClassStyle = this.state.twitter ? 'social__twitter social__active' : 'social__twitter';
        if (this.props.affiliate.twitter !== "") {
            return (
                <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'twitter')}>
                    <img src={twitterIconBlk} alt="twitter icon" className="social__icon"/>
                </div>
            )
        } else {
            return (
                <div className={activeClassStyle} onClick={this.setActiveSocialPlatform.bind(this, 'twitter')}>
                    <img src={twitterIconBlk} alt="twitter icon" className="social__icon"/>
                </div>
            )
        }
    };

    loadActivePlatform = () => {
        var activePlatform = this.getActiveSocialPlatform();
        var platform = '';
        var emptyStateConfig = {
            platform: `${activePlatform}`,
            heading: `We did not find any linked account!`,
            description: `Please take the time to link an account.`
        };

        if (activePlatform === 'facebook') {
            platform = <FacebookComponent
                refreshParentComponent={this.refreshCurrentComponentCallback} {...this.state} {...this.props} />;
        } else if (activePlatform === 'twitter') {
            platform = <TwitterComponent
                refreshParentComponent={this.refreshCurrentComponentCallback} {...this.state} {...this.props} />;
        } else if (activePlatform === 'instagram') {
            platform = <InstagramComponent
                title="Profile Setup" refreshParentComponent={this.refreshCurrentComponentCallback} {...this.state} {...this.props} />;
        } else if (activePlatform === 'tiktok') {
            platform = <TikTokComponent
                title="Profile Setup" refreshParentComponent={this.refreshCurrentComponentCallback} {...this.state} {...this.props} />;
        } else if (activePlatform === 'youtube') {
            platform = <YoutubeComponent
                refreshParentComponent={this.refreshCurrentComponentCallback} {...this.state} {...this.props} />;
        } else {
            platform = <EmptyComponentState config={emptyStateConfig}/>;
        }
        return platform;
    };

    unlink = (platform) => {
        var id = sessionStorage.getItem("username");
        switch (platform) {
            case 'facebook':
                this.props.updateAffiliate({facebook: ""}, id)
                break;
            case 'twitter':
                this.props.updateAffiliate({twitter: ""}, id)
                break;
            case 'instagram':
                this.props.updateAffiliate({instagram: ""}, id)
                break;
            case 'youtube':
                this.props.updateAffiliate({youtube: ""}, id);
                break;
            default:
                break;
        }
        Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then((user) => {
            sessionStorage.setItem('username', user.username)
            this.props.getAffiliateData(user.username).then((result) => {
                this.setState({affiliate: result.value.data.affiliateById})
            });
        })
            .catch(err => {
                throw (err)
            });
    };

    render() {
        if (this.state && this.state.affiliate === null) {
            var emptyStateConfig = {
                platform: `instagram`,
                heading: `Loading social profiles`,
                description: `Please wait...`,
                linkSocialAccount: false
            };

            return (<EmptyComponentState config={emptyStateConfig} {...this.props} />);
        } else {
            return (
                <div className="social__flex__row">
                    <div className="social__profile__card">
                        <div className="social__wrapper">
                            {this.instaRender()}
                            {this.tiktokRender()}
                            {this.facebookRender()}
                            {this.twitterRender()}
                            {this.youtubeRender()}
                        </div>
                    </div>
                    <div className="affiliate-social__social-platform">
                        {this.loadActivePlatform()}
                    </div>
                </div>
            );
        }
    }

}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchtoProps = (dispatch) => {
    return {
        getAffiliateData: (id) => dispatch(getAffiliateData(id)),
        updateAffiliate: (data, id) => dispatch(updateAffiliate(data, id)),
    }
};

SocialSettings.propTypes = {
    getAffiliateData: PropTypes.any,
    history: PropTypes.object,
    affiliate: PropTypes.object,
    updateAffiliate: PropTypes.any
};

export default withRouter(connect(mapStateToProps, mapDispatchtoProps)(SocialSettings));
