import React, { Component } from 'react';
import Amplify, { Hub } from 'aws-amplify';
import {
    Authenticator,
    ConfirmSignIn,
    RequireNewPassword,
    VerifyContact,
    ForgotPassword,
    TOTPSetup,
    FormSection
} from 'aws-amplify-react';
import qs from 'qs';
import aws_exports from './aws.config';
import App from './App';
import BrandedSignupWrapper from './authentiction/branded-signup-wrapper';
import { getBrandedSignUp } from './store/actions/get-branded-sign-up';
import { LlamaSignIn, LlamaSignUp, LlamaConfirmSignUp, LlamaBrandedSignUp, LlamaForgotPassword } from './authentiction';
import './authentiction/amplify.css';
import logo from './assets/logo.svg';
import CenteredSpinner from './components/llama/centered-spinner';
// import { sendBackendRequest } from './store/actions/sendRequest';
import { updateAffiliate } from './store/actions/action_updateAffiliate';

const ErrorCode = {
    NO_SIGNUP_SLUG: 'NO_SIGNUP_SLUG',
}

const llamaTheme = {
    button: {
        backgroundColor: 'rgba(253,42,84,1)',
        borderRadius: '23px'
    },
    a: {
        color: 'rgba(253,42,84,1)'
    },
    formSection: {
        border: '3px solid #EBE8ED',
        borderRadius: '0.5rem',
        boxShadow: '0rem 1rem 2rem rgba(0, 0, 0, 0.1)',
        textAlign: 'left',
        display: 'inline-block',
        minWidth: '460px',
        background: '#FFFFFF',
        padding: '35px 40px'
    },
    sectionHeader: {
        background: '#FFFFFF',
        fontSize: '18px',
        fontWeight: '500',
        textAlign: 'left',
        display: 'inline-block',
    },
    toast: {
        display: 'absolute',
        top: 0,
        background: '#FB2956',
        color: '#FFFFFF'
    }
};

// eslint-disable-next-line
const search = location.search.replace('?', '');
const params = qs.parse(search);

const { email, create_user, aff_id, offer_id, shop, promo, from: landingPageRef, code } = params;

try {
    if (offer_id) {
        sessionStorage.setItem('offer_id', JSON.stringify(offer_id));
    }

    if (aff_id) {
        sessionStorage.setItem('aff_id', JSON.stringify(aff_id));
    }

    if (promo) {
        sessionStorage.setItem('promo', JSON.stringify(promo));
    }

    if (shop) {
        sessionStorage.setItem('shop', JSON.stringify(shop));
    }

    if (landingPageRef) {
        sessionStorage.setItem('from', JSON.stringify(landingPageRef));
    }
} catch (error) {
    console.log(error);
}

console.log('AUTH :: Query Params :', params);

Amplify.configure(aws_exports);

const Header = ({ authState }) => {
    if (
        authState === 'signedIn'
        || authState === 'brandedSignedUp'
        || authState === 'shopSignedUp'
    ) {
        return null;
    }

    return (
        <div
            style={{ height: '8rem', background: '#4C2A93', padding: '2rem', display: 'flex', justifyContent: 'center' }}>
            <img style={{ height: '4rem', display: 'block' }} src={logo} alt="Llama logo." />
        </div>
    );
};

class AuthWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeader: false,
            showReferralLayout: false,
            signInLoading: false,
            advertiserByBrandedSlug: null,
            amplifyAuthState: null,
            socialCognitoId: null
        };
    }

    enableDefaultLayout = () => {
        this.setState({ showReferralLayout: false });
    }

    componentDidMount() {
        if (code) {
            this.setState({
                signInLoading: true
            });
        }
        let offer_idSaved = '';
        let shopSaved = '';

        try {
            offer_idSaved = JSON.parse(sessionStorage.getItem('offer_id'));
            shopSaved = JSON.parse(sessionStorage.getItem('shop'));
        } catch (error) {
            console.log(error);
        }

        Hub.listen('auth', async ({ payload: { event, data } }) => {
            // alert('In hub');
            console.log('HUB Listener ::', event, data);

            try {
                switch (event) {
                    case 'signOut':
                        sessionStorage.removeItem('promo');
                        sessionStorage.removeItem('offer_id');
                        sessionStorage.removeItem('shop');
                        sessionStorage.removeItem('from');
                        sessionStorage.removeItem('socialUser');
                        sessionStorage.removeItem('aff_id');
                        break;
                    case 'cognitoHostedUI':
                        this.setState({
                            socialCognitoId: data.username
                        });
                        break;
                    // dispatched if a customState was added to the federatedSignIn options
                    case 'customOAuthState':
                        // alert('In custom OAuth');
                        if (data) {
                            // convert customState params to an object
                            const decodedData = decodeURIComponent(data);
                            const persistedParamsArr = decodedData.split(',');
                            const persistedParams = {};
                            persistedParamsArr.forEach((param) => {
                                if (param !== 'fromOauth=true') {
                                    const [key, value] = param.split('=');
                                    persistedParams[key] = value;
                                }
                            });

                            /* console.log('CONVERTED DATA', persistedParamsArr, persistedParams);
                            console.log('cognito_id', this.state.socialCognitoId); */

                            if (persistedParams?.promo
                                || persistedParams?.offer_id
                                || persistedParams?.shop
                                || persistedParams?.from
                                || persistedParams?.aff_id
                                || persistedParams?.advertiser_id
                            ) {
                                const updateAffiliateData = {
                                    socialSignInAff: true,
                                    signup_scenario: {
                                        offer_id: persistedParams?.offer_id,
                                        aff_id: persistedParams?.aff_id,
                                        advertiser_id: persistedParams?.advertiser_id,
                                        promo_code: persistedParams?.promo,
                                        shop: persistedParams?.shop,
                                        from: persistedParams?.from
                                    }
                                };

                                const result = await updateAffiliate(updateAffiliateData, this.state.socialCognitoId).payload;
                                console.log('AFFILIATE UPDATED', result);
                            }
                        }
                        break;
                    default:
                        this.setState({
                            signInLoading: false
                        });
                        console.log('HUB DEFAULT ::', event);
                }
            } catch (error) {
                this.setState({
                    signInLoading: false
                });
                console.log('HUB.LISTEN ERROR');
                console.error(error);
            }
        });

        const isLoggedIn = sessionStorage.getItem('username') !== null;

        if (!isLoggedIn && (shop || aff_id || offer_id)) {
            this.setState({ showReferralLayout: true });
        } else if (isLoggedIn && offer_id) {
            window.location = `/discover/offer/${offer_id || offer_idSaved}`;
        } else if (isLoggedIn && aff_id) {
            window.location = '/discover';
        } else if (isLoggedIn && landingPageRef === 'smartlinks') {
            window.location = '/smartlinks';
        } else if (isLoggedIn && landingPageRef === 'insta-analytics') {
            window.location = '/instaAnalytics';
        }

        if (shop || shopSaved) {
            // alert('In if shop condition');
            this.setState({ signInLoading: true });
            getBrandedSignUp(shop || shopSaved).then((response) => {
                try {
                    sessionStorage.removeItem('shop');
                } catch (error) {
                    console.log(error);
                }
                // alert('In if fecthed shop data');
                if (
                    !response.data
                    || !response.data.advertiserByBrandedSlug
                    || (response.data.error && response.data.error.code === ErrorCode.NO_SIGNUP_SLUG)
                ) {
                    this.changeState('SignIn');
                }

                //if they're already logged in as an ambassador, redirect them to the offer detail page
                if (localStorage['amplify-authenticator-authState'] && localStorage['amplify-authenticator-authState'] === 'signedIn') {
                    if (response.data.advertiserByBrandedSlug.branded_sign_up && response.data.advertiserByBrandedSlug.branded_sign_up.offer_id) {
                        window.location = '/discover/offer/' + response.data.advertiserByBrandedSlug.branded_sign_up.offer_id;
                    } else {
                        window.location = '/discover';
                    }
                } else {
                    this.setState({
                        ...this.state,
                        signInLoading: false,
                        advertiserByBrandedSlug: response.data.advertiserByBrandedSlug
                    });
                }
            });
        }
    }

    authStateChange = (authState) => {
        this.setState({ amplifyAuthState: authState });
        console.log('AUTH STATE CHANGED', authState);
        if (!window || !window.driftApi) {
            return;
        }

        if (authState === 'signedIn') {
            window.driftApi.widget.show();
        } else {
            window.driftApi.widget.hide();
        }
    }

    render() {
        const { signInLoading, advertiserByBrandedSlug } = this.state;

        if (shop && (signInLoading || !advertiserByBrandedSlug)) {
            return (
                <div className="amplify-loading-wrapper">
                    <CenteredSpinner />
                </div>
            );
        }

        let authState, meta;

        if (email || create_user || (!shop && promo)) {
            authState = 'signUp';
        } else if (aff_id || offer_id) {
            authState = 'brandedSignedUp';
            meta = { title: 'Llama Ambassador Sign Up', description: 'Llama Ambassador Sign Up' };
        } else if (shop || promo) {
            authState = 'shopSignedUp';

            meta = { title: 'Llama Ambassador Sign Up', description: 'Llama Ambassador Sign Up' };

            if (advertiserByBrandedSlug && advertiserByBrandedSlug.name) {
                meta.title = `Join ${advertiserByBrandedSlug.name}’s Ambassador Progam on Llama`;
            }
        } else {
            authState = 'signIn';
        }

        return this.state.showReferralLayout && (shop || aff_id || offer_id)
            // branded sign up
            ? (
                <>
                    <link href="https://fonts.googleapis.com/css?family=Roboto:500&amp;display=swap" rel="stylesheet" />
                    <BrandedSignupWrapper
                        meta={meta}
                        params={params}
                        authState={this.state.amplifyAuthState}
                        advertiserInfo={this.state.advertiserByBrandedSlug}
                    >
                        <Authenticator
                            theme={llamaTheme}
                            onStateChange={this.authStateChange}
                            hideDefault
                            authState={authState}
                        >
                            <App />
                            <LlamaSignIn override={['SignIn']} params={params} />
                            <ConfirmSignIn />
                            <RequireNewPassword />
                            <LlamaSignUp override={['SignUp']} params={params} />
                            <LlamaBrandedSignUp override={['brandedSignedUp']} params={params} enableDefaultLayout={this.enableDefaultLayout} aff_id={aff_id} offer_id={offer_id} data={this.state.advertiserByBrandedSlug} />
                            <LlamaConfirmSignUp override={['ConfirmSignUp']} />
                            <VerifyContact />
                            <LlamaForgotPassword override={['ForgotPassword']} />
                            <TOTPSetup />
                        </Authenticator>
                    </BrandedSignupWrapper>
                </>
            )
            // regular sign in/up
            : (
                <>
                    <link href="https://fonts.googleapis.com/css?family=Roboto:500&amp;display=swap" rel="stylesheet" />
                    <Authenticator
                        theme={llamaTheme}
                        onStateChange={this.authStateChange}
                        hideDefault
                        authState={authState}
                    >
                        <Header />
                        <App />
                        {/* <LlamaSignIn override={['SignIn']} federated={federated}/> */}
                        <LlamaSignIn override={['SignIn']} params={params} promo={promo} />
                        <ConfirmSignIn />
                        <RequireNewPassword />
                        <LlamaSignUp override={['SignUp']} params={params} />
                        <LlamaBrandedSignUp override={['brandedSignedUp']} aff_id={aff_id} offer_id={offer_id} data={this.state.advertiserByBrandedSlug} />
                        <LlamaConfirmSignUp override={['ConfirmSignUp']} />
                        <VerifyContact />
                        <LlamaForgotPassword override={['ForgotPassword']} />
                        <TOTPSetup />
                    </Authenticator>
                </>
            );
    }
}

export default AuthWrapper;
