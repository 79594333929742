/**
 * Page descriptions. Keys:
 * start with path url, if multiple paths refer to the same how-to, define a case in the switch in leo-help-widget.js
 *
 * name: The page name. Will show up as "What is {name}?"
 * plural: Boolean. Whether the name is plural. If true the button text will say "What are {name}?"
 * content: Written description. Keep it brief. HTML is accepted.  Wrap images in p tags if you need a line break after them. Images will be displayed at a max width of 100% and a max height of 170px, but the user can enlarge them by clicking.
 * kbLink: Link to corresponding knowledgebase page
 * kbText: Use if you want to be more specific than "Learn more"
 */
export const definitions = {
    '/dashboard': {
        name: 'LTV',
        content: '<p>LTV, or Long-Term Value, is the <strong>average amount of money each of your referrals spends at checkout</strong>. It shows how valuable each person can be to the brands you\'re promoting.</p><p>And when you look at how many of your referrals were repeat customers, you\'ll not only see how well you\'ve nurtured the relationship, but also <strong>how valuable your influence has been for each brand</strong>.</p>',
        kbLink: 'https://help.llama.app/article/44-how-ltv-look-back-and-look-forward-works'
    },
    '/instaAnalytics': {
        name: 'InstaAnalytics',
        content: 'InstaAnalytics is Llama\'s deep dive report of a given Instagram account.',
        kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
    },
    '/tiktokAnalytics': {
        name: 'TikTokAnalytics',
        content: 'TikTokAnalytics is Llama\'s deep dive report of a given TikTok account.',
        kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
    },
    '/discover': {
        name: 'Offers',
        plural: true,
        content: '<p>Offers are basically <strong>ambassador programs that are available to promote on Llama</strong>, and how you make money here.</p>',
        kbLink: 'https://help.llama.app/article/36-finding-offers-and-applying-to-run-them'
    },
    // not applied yet offer detail
    '/discover/offer/id': {
        name: 'Offer Detail',
        content: '',
        kbLink: ''
    },
    // pending/approved offer detail
    '/offer/id': {
        name: 'Offer Detail',
        content: '',
        kbLink: ''
    },
    '/offers': {
        name: 'Llama Partner Program',
        content: '<p>The Llama Partner Program allows you to <strong>earn $50 when your referred ambassadors earn their first $50</strong> on Llama.</p>',
        kbLink: 'https://help.llama.app/article/38-llama-50-50-referral-offer'
    },
    '/invoices': {
        name: 'Invoices',
        plural: true,
        content: 'Invoices are statements that summarize commissions earned from successful Offer promotions.',
        kbLink: 'https://help.llama.app/article/47-getting-paid'
    },
    '/smartlinks': {
        name: 'SmartLinks',
        content: `
            <p>SmartLinks is an easy way to curate and share your referral links from a <strong>single custom page<strong> with a <strong>tiny URL</strong>.</p>
            <p><strong>Automatic mode</strong> shows off your best performing Llama offers, while <strong>manual mode</strong> lets you pick and choose your own links and offers.
        `,
        kbLink: 'https://help.llama.app/article/41-llama-smartlinks'
    },
    '/settings': {
        name: 'General Settings',
        content: '',
        kbLink: ''
    },
    '/settings/social': {
        name: 'Social Media Settings',
        plural: true,
        content: '<p>Your Social Media Settings are the social media accounts you have chosen to connect on Llama. If you choose to connect, we use the data to calculate your <strong>Engagement Score</strong>.</p>',
        kbLink: ''
    },
    '/settings/pixels': {
        name: 'Pixel Settings',
        content: '',
        kbLink: ''
    },
    '/settings/payment': {
        name: 'Billing Settings',
        content: '',
        kbLink: ''
    },
    '/settings/notifications': {
        name: 'Notification Settings',
        content: '',
        kbLink: ''
    }
};

/**
 * How-to menu buttons. Keys:
 * start with path url, if multiple paths refer to the same how-to, define a case in the switch in leo-help-widget.js
 * each object in the path url array refers to a button grouping
 *
 * heading: Use only if you have multiple groupings of buttons, otherwise keep blank or omit
 * buttons: List of buttons in a grouping
 * buttons.label: The text inside the button
 * buttons.content: Written text instructions. HTML is accepted. Keep it brief. Use ordered lists when possible. Use strong tags for button names, page names, and other important words. Wrap images in p tags if you need a line break after them. Images will be displayed at a max width of 100% and a max height of 170px, but the user can enlarge them by clicking.
 * buttons.kbLink: Link to corresponding knowledgebase page
 * buttons.kbText: Use if you want to be more specific than "Learn more"
 * buttons.youtubeId: Use if there's a corresponding video. Fill with what comes after https://youtube.com/watch?v= To start at a specific timestamp, add "&t=" followed by the timestamp in seconds
 */
export const howtos = {
    '/dashboard': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Setup my ambassador profile',
                    content: `
                                <ol>
                                    <li>Go to your <strong>Settings</strong></li>
                                    <li>Fill out your first and last name</li>
                                    <li>Click the <strong>Billing</strong> tab</li>
                                    <li>Connect your Paypal account (<strong>Please Note: You cannot get paid without connecting a Paypal account</strong>)</li>
                                    <li>Click the <strong>Social</strong> tab</li>
                                    <li>Connect your social media accounts (It is recommended that you connect at least one)</li>
                                </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/35-account-setup-and-prerequisites',
                    kbText: 'Learn more about account setup'
                },
                {
                    label: 'Track orders and commissions',
                    content: `
                            <p>Here you can find your specfic Offer Stats, LTV(Long-Term Value) Stats, and Recent Product Sales.</p>
                            <p>For a more detailed breakdown:</p>
                            <ol>
                                <li>Select an offer from <strong>Recent Product Sales</strong></li>
                                <li>Click the <strong>Reports</strong> tab</li>
                                <li>View specific offer order details here such as Order Date, Commission, if a Coupon was used etc.</li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/39-tracking-orders-referrals-and-commissions',
                    kbText: 'Learn more about tracking'
                },
                {
                    label: 'Get help and support',
                    content: `
                            <p>Questions? Not sure what to do next?</p>
                            <img src="https://lh5.googleusercontent.com/qUlJIrYHQxSVJcin7Ltycz9jICWtPNV6hjUvzFhzS8tvqy1ceThifOt6Fl3svnvedEyERrVsnz0CDBduQMBbZ4HIc4ICxMK3QwnieLD8I3X83BiRZ_VKvwfqcHh4GGIJVCLK6qcU" alt="Leo Help Drift">
                            <p>Select <strong>View Guides & Tutorials</strong> to access our Knowledge Base where we have several articles to guide you through several processes on Llama.</p>
                            <p>Or if you prefer to ask a specific question select <strong>Chat with the support team</strong> to speak to a dedicated support team member.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/43-getting-support-from-llama'
                }
            ]
        }
    ],
    '/instaAnalytics': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Start my free trial',
                    content: `
                            <ol>
                                <li><strong>Enter</strong> your Instagram handle</li>
                                <li>Click <strong>Link Account and Start Free Trial</strong></li>
                                <li>You will now see your InstaAnalytics report, which will only be visible to you</li>
                                <li>To make your report visible to Merchants you will have to click <strong>Verify Ownership</strong> and go through the verification steps to prove you are the owner of this account</li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                },
                {
                    label: 'Upgrade my account',
                    content: `
                            <p>When your 30-day trial is up, to access your InstaAnalytics report you will have to upgrade your account</p>
                            <ol>
                                <li>Click <strong>Upgrade Now</strong></li>
                                <li>Select <strong>Monthly</strong> to pay only $0.99 per month or <strong>Yearly</strong> to pay only $7.20 for the whole year</li>
                                <li>Enter your payment info securely with Stripe</li>
                                <li>Click <strong>Complete Upgrade</strong></li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                },
                {
                    label: 'View another report',
                    content: `
                            <p>If you have more than one Instagram account or want to see how your competition measures up, simply click <strong>Get Another Report</strong> at the beginning of the report</p>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                },
                {
                    label: 'Cancel my subscription',
                    content: `
                            <p>Cancel your InstaAnalytics subscription at any time. All you have to do is:</p>
                            <ol>
                                <li>Click the <strong>Settings</strong> icon</li>
                                <li>Click the Ellipsis (...)</li>
                                <li>Select <strong>Cancel Subscription</strong></li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                }
            ]
        }
    ],
    '/tiktokAnalytics': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Start my free trial',
                    content: `
                            <ol>
                                <li><strong>Enter</strong> your TikTok username</li>
                                <li>Click <strong>Link Account and Start Free Trial</strong></li>
                                <li>You will now see your TikTokAnalytics report, which will only be visible to you</li>
                                <li>To make your report visible to Merchants you will have to click <strong>Verify Ownership</strong> and go through the verification steps to prove you are the owner of this account</li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                },
                {
                    label: 'Upgrade my account',
                    content: `
                            <p>When your 30-day trial is up, to access your TikTokAnalytics report you will have to upgrade your account</p>
                            <ol>
                                <li>Click <strong>Upgrade Now</strong></li>
                                <li>Select <strong>Monthly</strong> to pay only $0.99 per month or <strong>Yearly</strong> to pay only $7.20 for the whole year</li>
                                <li>Enter your payment info securely with Stripe</li>
                                <li>Click <strong>Complete Upgrade</strong></li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                },
                {
                    label: 'View another report',
                    content: `
                            <p>If you have more than one TikTok account or want to see how your competition measures up, simply click <strong>Get Another Report</strong> at the beginning of the report</p>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                },
                {
                    label: 'Cancel my subscription',
                    content: `
                            <p>Cancel your TikTokAnalytics subscription at any time. All you have to do is:</p>
                            <ol>
                                <li>Click the <strong>Settings</strong> icon</li>
                                <li>Click the Ellipsis (...)</li>
                                <li>Select <strong>Cancel Subscription</strong></li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/42-llama-instagram-tiktok-analytics'
                }
            ]
        }
    ],
    '/discover': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Find and apply to offers',
                    content: `
                        <ol>
                            <li>Use <strong>Search</strong> to find specific Offers by name or brand</li>
                            <li>Use <strong>Filters</strong> to limit Offer results to commission type, commission amount or specific categories. Make sure to click <strong>Apply Filters</strong>.
                            <li>Use <strong>Sort</strong> to order Offers by LTV, EPC or time of creation</li>
                            <li>To see more about an Offer click <strong>View Details</strong></li>
                            <li>To apply to an Offer click <strong>Apply to Offer</strong></li>
                            <li>To check the status of your application go to <strong>Your Offers</strong></li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/36-finding-offers-and-applying-to-run-them'
                },
                {
                    label: 'Check my approval status',
                    content: `
                            <p>Make sure you&rsquo;ve <strong>applied to run an Offer</strong>. When you do, a <strong>Pending</strong> badge will appear in the top right corner. You can then check the status of your Offer application on the <strong>Your Offers</strong> page</p>
                    `,
                    kbLink: ''
                },
                {
                    label: 'Increase approval chances',
                    content: `
                        <p>One of best ways to stand out among other ambassadors is to <strong>connect your social profiles</strong>. The more engaged you are with your audience, the more likely Merchants will approve you to run their Offers.</p>
                        <p>You can connect your social profiles in <strong>Settings</strong> under the <strong>Social</strong> tab.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/45-how-your-ambassador-profile-appears-to-brands'
                }
            ]
        }
    ],
    // not applied yet offer detail
    '/discover/offer/id': [],
    // pending/approved offer detail
    '/offer/id': [],
    '/offer/LLAMA_001': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Earn cash from referrals',
                    content: `
                        <ol>
                            <li>Copy your referral link</li>
                            <li>Share your link wherever with whoever you&rsquo;d like</li>
                            <li>Earn $50 whenever your referral earns their first $50 on the platform</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/38-llama-50-50-referral-offer'
                }
            ]
        }
    ],
    '/offers': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Get paid',
                    content: `
                            <p>Good news! Invoices are generated automatically on each successful offer promotion. To view them and check their statuses go to <strong>Invoices</strong>.</p>
                            <p><strong>Note</strong>: You cannot get paid until you connect a Paypal account. You can do so in <strong>Settings</strong> page under the <strong>Billing</strong> tab.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/47-getting-paid'
                },
                {
                    label: 'Track my commissions',
                    content: `
                            <ol>
                                <li><strong>Select</strong> one of your <strong>Approved</strong> offers</li>
                                <li>Click the <strong>Reports</strong> tab</li>
                                <li>View specific order details for the Offer here such as Order Date, Commission, if a Coupon was used etc.</li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/39-tracking-orders-referrals-and-commissions'
                },
                {
                    label: 'Refer other ambassadors',
                    content: `
                            <p>Know any Ambassadors? Invite them to join Llama with your referral link and you&rsquo;ll <strong>earn $50 when they earn their first $50 on Llama</strong>.</p>
                            <ol>
                                <li>Select <strong>Llama Partner Program</strong> to navigate to the Offer Detail</li>
                                <li>Click <strong>Copy Link</strong> to copy your referral link</li>
                                <li><strong>Share</strong> your link wherever and with whoever you like</li>
                            </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/38-llama-50-50-referral-offer'
                }
            ]
        }
    ],
    '/invoices': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Get paid',
                    content: `
                        <p>Good news! Invoices are generated automatically on each successful offer promotion. You can view Invoices and check their statuses here.</p>
                        <p><strong>Note</strong>: If you are seeing the message '<strong>Connect your Paypal Account</strong>' click <strong>Connect in Settings</strong>. You cannot get paid until you connect a Paypal account.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/47-getting-paid'
                }
            ]
        }
    ],
    '/smartlinks': [
        {
            heading: 'Links & Offers',
            buttons: [
                {
                    label: 'Automatically display offers',
                    content: `
                        <p>Your SmartLinks page starts in manual mode to give you complete control, but if you&rsquo;d prefer you can let us handle it for you.</p>
                        <p>Just click <strong>&ldquo;switch to automatic mode&rdquo;</strong> and we&rsquo;ll display your best performing offers and rank them by EPC.</p>
                        <p>If you offers have sales, we&rsquo;ll also display the name of your most ordered product for each offer and include the company name above it. Your list will be automatically updated hourly.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/41-llama-smartlinks',
                    kbText: 'Learn more about link management'
                },
                {
                    label: 'Change my offer link names',
                    content: `
                        <p>In automatic mode, your offer link names are the <strong>products your referrals have bought the most</strong>. If your referrals haven&rsquo;t bought anything yet, your link name is the <strong>offer&rsquo;s category</strong>.</p>
                        <p>To edit an offer's link name you'll need to <strong>switch to manual mode</strong>.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/41-llama-smartlinks',
                    kbText: 'Learn more about link management'
                },
                {
                    label: 'Add an offer/link',
                    content: `
                        <ol>
                            <li>First make sure you&rsquo;re in <strong>manual mode</strong>. If you aren&rsquo;t, click <strong>&ldquo;switch to manual mode&rdquo;</strong>.</li>
                            <li>Click the <strong>&ldquo;Add Link&rdquo;</strong> button. If you don&rsquo;t see that button, you may have hit the link limit for your current plan.</li>
                            <li>If you want to add your own link, choose <strong>&ldquo;Custom Link&rdquo;</strong>. If you want to add one of your Llama offers, choose <strong>&ldquo;Offer&rdquo;</strong>.</li>
                            <li>Fill in the form and click the <strong>&ldquo;Save&rdquo;</strong> button.</li>
                            <li>Your link is now at the bottom of your list. To change the order, click and drag on the move handle (left side of the link box) to move it to a new position.</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/41-llama-smartlinks',
                    kbText: 'Learn more about link management'
                },
                /* TODO {
                    label: 'Edit or remove an offer/link',
                    content: '',
                    kbLink: '',
                    kbText: 'Learn more about link management'
                },
                {
                    label: 'Add social media accounts',
                    content: '',
                    kbLink: '',
                    kbText: 'Learn more about page customization'
                },
                {
                    label: 'Share my page',
                    content: ''
                } */
            ]
        },
        {
            heading: 'Customization',
            buttons: [
                /* TODO {
                    label: 'Change colors',
                    content: '',
                    kbLink: ''
                },
                {
                    label: 'Change my photo',
                    content: '',
                    kbLink: ''
                },
                */
                {
                    label: 'Change the URL of my page',
                    content: `
                        <ol>
                            <li>Click the <strong>Edit</strong> icon next to your SmartLinks link.</li>
                            <li><strong>Select</strong> one of our predefined domains from the dropdown.</li>
                            <li>Enter in you desired SmartLinks URL handle, if it&rsquo;s available, you&rsquo;ll be able to reserve it.</li>
                            <li>Click <strong>Save</strong> to keep your changes.</li>
                            <li><strong>Share</strong> your newly customized link.</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/50-custom-domains-and-cnames'
                },
                {
                    label: 'Remove the Llama logo',
                    content: 'To remove the Llama logo, you\'ll need to upgrade to a PRO account.',
                    kbLink: ''
                }
            ]
        },
        /* TODO {
            heading: 'PRO',
            buttons: [
                {
                    label: 'Upgrade to PRO',
                    content: '',
                    kbLink: ''
                },
                {
                    label: 'Change my card number',
                    content: '',
                    kbLink: ''
                },
                {
                    label: 'Downgrade to FREE',
                    content: '',
                    kbLink: ''
                },
                {
                    label: 'View my billing history',
                    content: '',
                    kbLink: ''
                }
            ]
        } */
    ],
    '/settings': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Change my password',
                    content: `
                                <ol>
                                    <li>Click <strong>Change Password</strong> at the beginning of <strong>Profile Information</strong></li>
                                    <li>Fill out the form in the <strong>Change your password</strong> modal</li>
                                    <li>Click <strong>Change Password</strong> to save your changes</li>
                                </ol>
                    `,
                    kbLink: ''
                },
                {
                    label: 'Setup my ambassador profile',
                    content: `
                                <ol>
                                    <li>Fill out your first and last name</li>
                                    <li>Click the <strong>Billing</strong> tab</li>
                                    <li>Connect your Paypal account (<strong>Please Note: You cannot get paid without connecting a Paypal account</strong>)</li>
                                    <li>Click the <strong>Social</strong> tab</li>
                                    <li>Connect your social media accounts (It is recommended that you connect at least one)</li>
                                </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/35-account-setup-and-prerequisites'
                },
                {
                    label: 'Make a standout profile',
                    content: `
                        <p>One of best ways to stand out among other ambassadors is to <strong>connect your social profiles</strong>. The more engaged you are with your audience, the more likely Merchants will approve you to run their Offers.</p>
                        <p>You can connect your social profiles here in <strong>Settings</strong> under the <strong>Social</strong> tab.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/45-how-your-ambassador-profile-appears-to-brands'
                }
            ]
        }
    ],
    '/settings/general': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Change my password',
                    content: `
                                <ol>
                                    <li>Click <strong>Change Password</strong> at the beginning of <strong>Profile Information</strong></li>
                                    <li>Fill out the form in the <strong>Change your password</strong> modal</li>
                                    <li>Click <strong>Change Password</strong> to save your changes</li>
                                </ol>
                    `,
                    kbLink: ''
                },
                {
                    label: 'Setup my ambassador profile',
                    content: `
                                <ol>
                                    <li>Fill out your first and last name</li>
                                    <li>Click the <strong>Billing</strong> tab</li>
                                    <li>Connect your Paypal account (<strong>Please Note: You cannot get paid without connecting a Paypal account</strong>)</li>
                                    <li>Click the <strong>Social</strong> tab</li>
                                    <li>Connect your social media accounts (It is recommended that you connect at least one)</li>
                                </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/35-account-setup-and-prerequisites'
                },
                {
                    label: 'Make a standout profile',
                    content: `
                        <p>One of best ways to stand out among other ambassadors is to <strong>connect your social profiles</strong>. The more engaged you are with your audience, the more likely Merchants will approve you to run their Offers.</p>
                        <p>You can connect your social profiles here in <strong>Settings</strong> under the <strong>Social</strong> tab.</p>
                    `,
                    kbLink: 'https://help.llama.app/article/45-how-your-ambassador-profile-appears-to-brands'
                }
            ]
        }
    ],
    '/settings/social': [
        {
            heading: '',
            buttons: [
                {
                    label: 'Connect my social accounts',
                    content: `
                        <p>One of best ways to stand out among other ambassadors is to <strong>connect your social profiles</strong>. The more engaged you are with your audience, the more likely Merchants will approve you to run their Offers.</p>
                        <p><strong>For Instagram and TikTok:</strong></p>
                        <ol>
                            <li>Click <strong>Instagram</strong> or <strong>TikTok</strong> tab</li>
                            <li>Enter your Instagram or TikTok username</li>
                            <li>Click <strong>Link Account and Start Free Trial</strong></li>
                        </ol>
                        </br>
                        <p><strong>For Facebook:</strong></p>
                        <ol>
                            <li>Click the <strong>Facebook</strong> tab</li>
                            <li>Click <strong>Continue with Facebook</strong> to open Facebook Auth window</li>
                            <li>Follow the steps to connect your Facebook account with Llama</li>
                        </ol>
                        </br>
                        <p><strong>For Twitter:</strong></p>
                        <ol>    
                            <li>Click the <strong>Twitter</strong> tab</li>
                            <li>Click <strong>Link Account</strong> to open Twitter Auth window</li>
                            <li>Follow the steps to connect your Twitter account with Llama</li>
                        </ol>
                        </br>
                        <p><strong>For YouTube:</strong></p>
                        <ol>    
                            <li>Click the <strong>YouTube</strong> tab</li>
                            <li>Click <strong>Link Account</strong> to open YouTube Auth window</li>
                            <li>Follow the steps to connect your YouTube account with Llama</li>
                        </ol>
                    `,
                    kbLink: 'https://help.llama.app/article/46-how-your-social-profiles-impact-your-engagement-score'
                }
            ]
        }
    ],
    '/settings/pixels': [],
    '/settings/payment': [],
    '/settings/notifications': []
};
